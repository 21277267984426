import ComboBoxField from "components/controls/ComboBoxField";
import NumberInputField from "components/controls/NumberInputField";
import TextInputField from "components/controls/TextInputField";
import { vehicleFormClasses } from "pages/vehicle/VehicleForm";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import {
  isDisabledExternal,
  isDisabledExternalOrHasBeenActivated,
} from "../../../helpers";

const FinanceTempHorsePowerFields = ({
  formikProps,
  userRole,
  route,
  vehicle,
}) => {
  const { t } = useTranslation();
  const { setFieldValue, handleChange, values, errors } = formikProps;
  const taxonomy = useSelector((s) => s.taxonomy);

  const paymentMethodOptions = useMemo(() => {
    return taxonomy?.PaymentMethod?.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy?.PaymentMethod?.items]);

  // const paymentMeanOptions = useMemo(() => {
  //   return taxonomy?.PremiumPaymentMean?.items.map((item) => ({
  //     value: item.id,
  //     label: t(item.code),
  //   }));
  // }, [t, taxonomy?.PremiumPaymentMean?.items]);

  const excludeHorsePowerBasedTaxPaymentMeanOption = useMemo(() => {
    return taxonomy?.ReasonForMotorTaxExclusion?.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy?.ReasonForMotorTaxExclusion?.items]);

  return (
    <>
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternal({ userRole, route })}
          errors={errors}
          name="horsePowerBasedTaxPaymentMethod"
          label={"bfm.vehicleForm.paymentMethod.label"}
          setFieldValue={setFieldValue}
          options={paymentMethodOptions}
          defaultValue={values.horsePowerBasedTaxPaymentMethod}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "horsePowerBasedTaxPaymentMethod"
          )}
          confidentiality={null}
        />
      </div>

      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternal({ userRole, route })}
          errors={errors}
          name="excludeHorsePowerBasedTaxReason"
          label={"bfm.vehicleForm.horsePowerBasedTaxExcludeReason.label"}
          setFieldValue={setFieldValue}
          options={excludeHorsePowerBasedTaxPaymentMeanOption}
          defaultValue={values.excludeHorsePowerBasedTaxReason}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "excludeHorsePowerBasedTaxReason"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.horsePowerBasedTaxSuspensionDaysLimit}
          onChange={handleChange}
          errors={errors}
          name={"horsePowerBasedTaxSuspensionDaysLimit"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={t(
            "bfm.vehicleForm.horsePowerBasedTaxSuspensionDaysLimit.label"
          )}
          isNegativeAllowed={true}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "horsePowerBasedTaxSuspensionDaysLimit"
          )}
        />
      </div>
      <div className={vehicleFormClasses.fullWidthFormItem}>
        <TextInputField
          value={values.horsePowerBasedTaxComment}
          onChange={handleChange}
          errors={errors}
          name={"horsePowerBasedTaxComment"}
          multiline
          rows={4}
          disabled={isDisabledExternal({ userRole, route })}
          label={"bfm.vehicleForm.horsePowerBasedTaxComment.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "horsePowerBasedTaxComment"
          )}
        />
      </div>
    </>
  );
};

export default FinanceTempHorsePowerFields;

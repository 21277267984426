import { useMutation } from "react-query";
import { AddFleetPremiumParams, addFleetPremium } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const addFleetPremiumFn = async (params: AddFleetPremiumParams) => {
  return await addFleetPremium(params);
};

export function useAddFleetPremium() {
  return useMutation(addFleetPremiumFn, {
    mutationKey: MUTATION_KEYS.addFleetPremium,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import {
  TimelineLegendSegmentItem,
  TimelineLegendSegmentItemType,
  TimelineType,
} from "types/types";
import { invertColor } from "utils/utils";

interface IProps {
  x: number;
  y: number;
  item: TimelineLegendSegmentItem;
}
/* Component */
export const LegendSegment = ({ x, y, item }: IProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;
  const appSettings = useSelector((s) => s.timeline.timelineAppSettings);

  const isDarkMode = theme.isDark;
  const types = useSelector((s) => s.timeline.types);
  let segment = null;
  if (item.itemType === TimelineLegendSegmentItemType.PREMIUM_INSURER) {
    const width = 60;
    const height = 25;
    segment = (
      <>
        <g className={isDarkMode ? "tickdark" : "tick"} key={"segmentitem" + y}>
          <rect
            x={x + 100}
            width={width}
            y={y - height / 2}
            dy=".32em"
            height={height}
            fill={
              isDarkMode
                ? invertColor(item.itemAttributes.fillColor)
                : item.itemAttributes.fillColor
            }
            stroke={
              isDarkMode
                ? invertColor(item.itemAttributes.strokeColor)
                : item.itemAttributes.strokeColor
            }
            strokeWidth={3}
          ></rect>
        </g>
      </>
    );
  }
  // let circleClass =
  //     vehicleIssuingStatus === "VehicleIssuingStatus.INCORRECT"
  //       ? "bookingCircle booking-point-incorrect"
  //       : vehicleIssuingStatus === "VehicleIssuingStatus.CORRECT"
  //       ? "bookingCircle booking-point-correct"
  //       : "bookingCircle booking-point-notyetbooked";
  let strokeWidth = 2;

  if (item.itemType === TimelineLegendSegmentItemType.PREMIUM_BOOKING_CORRECT) {
    segment = (
      <>
        <g>
          <circle
            className={`bookingCircle booking-point-correct`}
            r={5.5}
            cx={x + 108}
            cy={y}
            strokeWidth={strokeWidth}
          />
        </g>
      </>
    );
  }
  if (
    item.itemType === TimelineLegendSegmentItemType.PREMIUM_BOOKING_INCORRECT
  ) {
    segment = (
      <>
        <g>
          <circle
            className={`bookingCircle booking-point-incorrect`}
            r={5.5}
            cx={x + 108}
            cy={y}
            strokeWidth={strokeWidth}
          />
        </g>
      </>
    );
  }
  if (
    item.itemType ===
    TimelineLegendSegmentItemType.PREMIUM_BOOKING_NOT_YET_BOOKED
  ) {
    segment = (
      <>
        <g>
          <circle
            className={`bookingCircle booking-point-notyetbooked`}
            r={5.5}
            cx={x + 108}
            cy={y}
            strokeWidth={strokeWidth}
          />
        </g>
      </>
    );
  }
  if (item.itemType === TimelineLegendSegmentItemType.PREMIUM_BOOKING_PENDING) {
    segment = (
      <>
        <g>
          <circle
            className={`bookingCircle booking-point-correct`}
            r={5.5}
            cx={x + 108}
            cy={y}
            strokeWidth={strokeWidth}
          />
          <circle
            className={`bookingCircle booking-point-correct`}
            r={2.5}
            cx={x + 108}
            cy={y}
            strokeWidth={0.5}
          />
        </g>
      </>
    );
  }
  if (
    item.itemType === TimelineLegendSegmentItemType.PREMIUM_BOOKING_PLUSMINUS
  ) {
    const xv = x + 108;
    const yv = y;
    const deltax = 7;
    const deltay = 6;

    segment = (
      <g>
        <circle
          className={`bookingCircle booking-point-correct`}
          r={5.5}
          cx={x + 108}
          cy={y}
          strokeWidth={strokeWidth}
        />
        <line
          className={`bookingLine redBookingLine`}
          x1={deltax + xv - 1.5}
          y1={deltay + yv}
          x2={deltax + xv + 1.5}
          y2={deltay + yv}
          stroke={"#bb0000"}
          strokeWidth={1.5}
        />

        <>
          <line
            className={`bookingLine greenBookingLine`}
            x1={deltax + xv - 2.5}
            y1={-deltay + yv}
            x2={deltax + xv + 2.5}
            y2={-deltay + yv}
            stroke={"#008800"}
            strokeWidth={1.5}
          />
          <line
            className={`bookingLine greenBookingLine`}
            x1={deltax + xv}
            y1={-deltay + yv - 2.5}
            x2={deltax + xv}
            y2={-deltay + yv + 2.5}
            stroke={"#008800"}
            strokeWidth={1.5}
          />
        </>
      </g>
    );
  }

  if (
    item.itemType ===
    TimelineLegendSegmentItemType.PREMIUM_BOOKING_ONETIMEPREMIUM
  ) {
    const xv = x + 108;
    const yv = y;
    const deltax = 7;
    const deltay = 6;
    const deltax1 = -2;
    const deltay1 = 0;
    const deltax2 = 20;
    const deltay2 = 0;
    const deltax3 = 40;
    const deltay3 = 0;

    segment = (
      <g>
        <polygon
          className={`bookingCircle booking-point-correct`}
          points={`${deltax1 + xv - 6.5},${deltay1 + yv} ${deltax1 + xv},${
            deltay1 + yv - 6.5
          } ${deltax1 + xv + 6.5},${deltay1 + yv} ${deltax1 + xv},${
            deltay1 + yv + 6.5
          }}`}
        />

        <polygon
          className={`bookingCircle booking-point-correct`}
          points={`${deltax2 + xv - 4.8},${deltay2 + yv - 4.8} ${
            deltax2 + xv + 4.8
          },${deltay2 + yv - 4.8} ${deltax2 + xv + 4.8},${deltay2 + yv + 4.8} ${
            deltax2 + xv - 4.8
          },${deltay2 + yv + 4.8}`}
        />

        <>
          <polygon
            className={`bookingCircle booking-point-correct`}
            points={`${deltax3 + xv - 7},${yv} ${deltax3 + xv},${yv - 7} ${
              deltax3 + xv + 7
            },${yv} ${deltax3 + xv},${yv + 7}`}
          />{" "}
          <polygon
            className={`bookingCircle booking-point-correct`}
            points={`${deltax3 + xv - 3.4},${yv - 3.4} ${deltax3 + xv + 3.4},${
              yv - 3.4
            } ${deltax3 + xv + 3.4},${yv + 3.4} ${deltax3 + xv - 3.4},${
              yv + 3.4
            }`}
          />
        </>

        <line
          className={`bookingLine redBookingLine`}
          x1={deltax + xv - 1.5}
          y1={deltay + yv}
          x2={deltax + xv + 1.5}
          y2={deltay + yv}
          stroke={"#bb0000"}
          strokeWidth={1.5}
        />

        <>
          <line
            className={`bookingLine greenBookingLine`}
            x1={deltax + xv - 2.5}
            y1={-deltay + yv}
            x2={deltax + xv + 2.5}
            y2={-deltay + yv}
            stroke={"#008800"}
            strokeWidth={1.5}
          />
          <line
            className={`bookingLine greenBookingLine`}
            x1={deltax + xv}
            y1={-deltay + yv - 2.5}
            x2={deltax + xv}
            y2={-deltay + yv + 2.5}
            stroke={"#008800"}
            strokeWidth={1.5}
          />
        </>
      </g>
    );
  }

  if (item.itemType === TimelineLegendSegmentItemType.STATUS) {
    const width = 58;
    const height = 14;
    segment = (
      <>
        <g className={isDarkMode ? "tickdark" : "tick"} key={"segmentitem" + y}>
          {item.label !== "VehicleStatusCode.PENDING" ? (
            <rect
              x={x + 100}
              width={width}
              y={y - height / 2}
              dy=".32em"
              height={height}
              fill={item.itemAttributes.fillColor}
              strokeWidth={2}
            ></rect>
          ) : null}
          {item.label !== "VehicleStatusCode.PENDING" ? (
            <circle
              className={"status-point"}
              style={{ cursor: "default" }}
              r="10"
              cx={x + 108}
              cy={y}
              fill={"white"}
              stroke={"black"}
              strokeWidth={2}
            />
          ) : (
            <circle
              className={"status-point"}
              r="10"
              style={{ cursor: "default" }}
              cx={x + 108}
              cy={y}
              fill={"white"}
              stroke={"black"}
              strokeDasharray={"5, 2"}
              strokeWidth={2}
            />
          )}
          <circle
            className={`status-point-${item.itemAttributes.pointColor}`}
            style={{ cursor: "default" }}
            r="5"
            cx={x + 108}
            cy={y}
            stroke={"black"}
            strokeWidth={0}
          />
        </g>
      </>
    );
  }
  if (item.itemType === TimelineLegendSegmentItemType.POLICY_STATUS) {
    const width = 58;
    const height = 14;
    segment = (
      <>
        <g className={isDarkMode ? "tickdark" : "tick"} key={"segmentitem" + y}>
          <rect
            x={x + 100}
            width={width}
            y={y - height / 2}
            dy=".32em"
            height={height}
            fill={item.itemAttributes.fillColor}
            strokeWidth={2}
          ></rect>
          <rect
            className={"status-point"}
            style={{ cursor: "default" }}
            width={appSettings?.VEHICLE_STATUS_BAR_HEIGHT}
            height={appSettings?.VEHICLE_STATUS_BAR_HEIGHT}
            x={x + 108 - appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2}
            y={y - appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2}
            fill={"white"}
            stroke={"black"}
            strokeWidth={2}
          />
          <rect
            className={`status-point-${item.itemAttributes.pointColor}`}
            style={{ cursor: "default" }}
            width={appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2}
            height={appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2}
            x={x + 108 - appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 4}
            y={y - appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 4}
            fill={item.itemAttributes.fillColor}
            strokeWidth={0}
          />
        </g>
      </>
    );
  }
  if (item.itemType === TimelineLegendSegmentItemType.LICENSE_PLATE) {
    const width = 60;
    const height = 18;
    segment = (
      <>
        <g className={isDarkMode ? "tickdark" : "tick"} key={"segmentitem" + y}>
          <rect
            x={x + 100}
            width={width}
            y={y - height / 2}
            dy=".32em"
            height={height}
            fill={
              isDarkMode
                ? invertColor(item.itemAttributes.fillColor)
                : item.itemAttributes.fillColor
            }
            stroke={
              isDarkMode
                ? invertColor(item.itemAttributes.strokeColor)
                : item.itemAttributes.strokeColor
            }
            strokeWidth={0}
          ></rect>
          <rect
            style={{ cursor: "default" }}
            className={"status-point"}
            x={x + 100}
            width={18}
            y={y - 9}
            height={18}
            fill={"white"}
            stroke={
              isDarkMode
                ? invertColor(item.itemAttributes.strokeColor)
                : item.itemAttributes.strokeColor
            }
            strokeWidth={2}
          />
        </g>
      </>
    );
  }
  if (
    item.itemType === TimelineLegendSegmentItemType.SHARED_LICENSE_PLATE &&
    types.includes(TimelineType.VEHICLE_PREMIUM)
  ) {
    const width = 60;
    const height = 18;
    segment = (
      <>
        <g className={isDarkMode ? "tickdark" : "tick"} key={"segmentitem" + y}>
          <rect
            x={x + 100}
            width={width}
            y={y - height / 2}
            dy=".32em"
            height={height}
            fill={
              isDarkMode
                ? invertColor(item.itemAttributes.fillColor)
                : item.itemAttributes.fillColor
            }
            stroke={
              isDarkMode
                ? invertColor(item.itemAttributes.strokeColor)
                : item.itemAttributes.strokeColor
            }
            strokeWidth={3}
          ></rect>
        </g>
      </>
    );
  }
  if (
    item.itemType === TimelineLegendSegmentItemType.SHARED_LICENSE_PLATE_VEHICLE
  ) {
    const width = 60;
    let height = 25;
    if (item.label.startsWith("VehicleStatusCode")) height = 18;
    segment = (
      <>
        <g className={isDarkMode ? "tickdark" : "tick"} key={"segmentitem" + y}>
          <rect
            x={x + 100}
            width={width}
            y={y - height / 2}
            dy=".32em"
            height={height}
            fill={
              item.label.startsWith("VehicleStatusCode")
                ? item.itemAttributes.fillColor
                : isDarkMode
                ? invertColor(item.itemAttributes.fillColor)
                : item.itemAttributes.fillColor
            }
            strokeWidth={3}
          ></rect>
        </g>
      </>
    );
  }

  // itemType: TimelineLegendSegmentItemType.PREMIUM_REGULAR,
  // itemType: TimelineLegendSegmentItemType.PREMIUM_SUSPENDED,
  // itemType: TimelineLegendSegmentItemType.PREMIUM_NOTHING_TO_PAY,
  // itemType: TimelineLegendSegmentItemType.PREMIUM_NOT_MAIN_SHARED_VEHICLE,

  if (item.itemType === TimelineLegendSegmentItemType.TEXT) {
    segment = (
      <>
        <g className={isDarkMode ? "tickdark" : "tick"} key={"segmentitem" + y}>
          <text
            className="boldText"
            style={{ textAnchor: "start", fontWeight: "bold" }}
            x={x + 100}
            dy=".32em"
            y={y}
          >
            {t(item.itemAttributes.main)}
          </text>{" "}
        </g>
      </>
    );
  }

  if (item.itemType === TimelineLegendSegmentItemType.PREMIUM_REGULAR) {
    const width = 60;
    const height = 25;
    segment = (
      <>
        <g className={isDarkMode ? "tickdark" : "tick"} key={"segmentitem" + y}>
          <rect
            x={x + 100}
            width={width}
            y={y - height / 2}
            dy=".32em"
            height={height}
            fill={item.itemAttributes.fillColor}
            stroke={item.itemAttributes.strokeColor}
            strokeWidth={3}
          ></rect>
        </g>
      </>
    );
  }
  if (item.itemType === TimelineLegendSegmentItemType.PREMIUM_SUSPENDED) {
    const width = 60;
    const height = 25;
    segment = (
      <>
        <g className={isDarkMode ? "tickdark" : "tick"} key={"segmentitem" + y}>
          <rect
            x={x + 100}
            width={width}
            y={y - height / 2}
            fill={item.itemAttributes.fillColor}
            dy=".32em"
            height={height}
          ></rect>
          <rect
            x={x + 100}
            width={width}
            y={y - height / 2}
            dy=".32em"
            height={height}
            fill="url(#pattern_stripes)"
            stroke={item.itemAttributes.strokeColor}
            strokeWidth={3}
          ></rect>
        </g>
      </>
    );
  }
  if (item.itemType === TimelineLegendSegmentItemType.PREMIUM_NOTHING_TO_PAY) {
    const width = 60;
    const height = 25;
    segment = (
      <>
        <g className={isDarkMode ? "tickdark" : "tick"} key={"segmentitem" + y}>
          <rect
            x={x + 100}
            width={width}
            y={y - height / 2}
            dy=".32em"
            height={height}
            fill={item.itemAttributes.fillColor}
            stroke={item.itemAttributes.strokeColor}
            strokeWidth={3}
            style={{ opacity: 0.2 }}
          ></rect>
        </g>
      </>
    );
  }
  if (
    item.itemType ===
    TimelineLegendSegmentItemType.PREMIUM_NOT_MAIN_SHARED_VEHICLE
  ) {
    const width = 60;
    const height = 25;
    segment = (
      <>
        <g className={isDarkMode ? "tickdark" : "tick"} key={"segmentitem" + y}>
          <rect
            x={x + 100}
            width={width}
            y={y - height / 2}
            fill={item.itemAttributes.fillColor}
            dy=".32em"
            height={height}
          ></rect>
          <rect
            x={x + 100}
            width={width}
            y={y - height / 2}
            dy=".32em"
            height={height}
            fill="url(#pattern_dashes)"
            stroke={item.itemAttributes.strokeColor}
            strokeWidth={3}
          ></rect>
        </g>
      </>
    );
  }
  return segment;
};

import { IFieldConfig } from "components/form/types";
import { useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "store/hooks";
import {
  PremiumType,
  TimelineSegment,
  TimelineSegmentType,
  TimelineType,
} from "../../../types/types";
import { useFields } from "../../form/useFields";
import FleetLegalFormContent from "../components/FleetLegalFormContent";
import { useAnnualNetPremium } from "../hooks/useAnnualNetPremium";
import { useDefaultSettings } from "../hooks/useDefaultSettings";
import { useEqualPaymentMethods } from "../hooks/useEqualPaymentMethods";
import { useInsurerSettings } from "../hooks/useInsurerSettings";
import { TSetDefaultSettingValues } from "./AddPremiumDialog/AddPremiumDialog";
import { addPremiumDialogConfig } from "./addPremiumDialogConfig";

interface IProps {
  premiumTypeKey: string;
  item: TimelineSegment;
  setDefaultSettingValues: ({
    setFieldValue,
    values,
  }: TSetDefaultSettingValues) => void;
}

export const AddPremiumDialogContent = ({
  premiumTypeKey,
  item,
  setDefaultSettingValues,
}: IProps) => {
  const vehicle = useSelector((s) => s.vehicle.vehicle);
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const appSettings = useSelector((s) => s.timeline.timelineAppSettings);

  const config = useMemo(() => {
    if (!appSettings) return;
    if (
      appSettings?.HAS_GREENCARD_EXPIRY_DATE === false ||
      premiumTypeKey !== PremiumType.MTPL
    ) {
      const filteredConfig = Object.keys(addPremiumDialogConfig).reduce(
        (acc, key) => {
          if (key !== "greenCardExpiryDate") {
            acc[key] = addPremiumDialogConfig[key];
          }
          return acc;
        },
        {} as IFieldConfig
      );
      return filteredConfig;
    }
    return addPremiumDialogConfig;
  }, [appSettings]);

  useEffect(() => {
    setDefaultSettingValues({
      setFieldValue,
      values,
      noToastMsg: true,
      isHullPremium: true,
      setFieldTouched,
    });
  }, []);

  useAnnualNetPremium(premiumTypeKey);
  useEqualPaymentMethods(premiumTypeKey);

  useDefaultSettings();
  useInsurerSettings();

  const suspensionDaysIncluded = appSettings?.SUSPENSION_DAYS_INCLUDED;
  const activationDaysIncluded = appSettings?.ACTIVATION_DAYS_INCLUDED;
  const suspensionDaysLimitIncluded =
    appSettings?.SUSPENSION_DAYS_LIMIT_INCLUDED;
  const garageRiskDiscountPercentIncluded =
    appSettings?.GARAGE_RISK_DISCOUNT_PERCENT_INCLUDED;

  let suspensionDaysFields = [];
  if (suspensionDaysIncluded) {
    suspensionDaysFields.push(config.suspensionDays);
  }
  let activationDaysFields = [];

  if (activationDaysIncluded) {
    activationDaysFields.push(config.activationDays);
  }

  let suspensionDaysLimitFields = [];
  if (suspensionDaysLimitIncluded) {
    suspensionDaysLimitFields.push(config.suspensionDaysLimit);
  }

  let garageRiskDiscountPercentFields = [];
  if (garageRiskDiscountPercentIncluded) {
    garageRiskDiscountPercentFields.push(config.garageRiskDiscountPercent);
  }

  let policyStatusFields = [];
  if (
    appSettings?.VEHICLE_TIMELINE_TYPES.includes(
      TimelineType.VEHICLE_POLICY_STATUSES
    )
  ) {
    policyStatusFields = [config.policyStatus];
  }

  let groupPolicyFields = [];
  if (appSettings?.INCLUDE_GROUP_POLICY) {
    groupPolicyFields = [config.insurerGroupPolicyNumber];
  }
  const { all: mtplOrHullFieldsLeft } = useFields([
    config.dueDate,
    config.insurerPartnerNumber,
    config.insurerBranchOffice,
    ...policyStatusFields,
    config.insurerPolicyNumber,
    ...groupPolicyFields,
    config.isPolicyIssued,
    config.documentDate,
  ]);
  const { all: leftFieldsAdd } = useFields([
    config.annualGrossPremium,
    config.annualNetPremium,
  ]);

  const { all: leftFieldsInsert } = useFields([
    config.annualGrossPremium,
    config.annualNetPremium,
  ]);

  const { all: mtplOrHullFieldsRightAdd } = useFields(
    [
      config.validFromDate,
      config.expiryDate,
      ...(config.greenCardExpiryDate ? [config.greenCardExpiryDate] : []),
      config.paymentMethod,
      config.paymentMean,
      ...suspensionDaysFields,
      ...activationDaysFields,
    ],
    undefined,
    setFieldTouched
  );

  const { all: mtplOrHullFieldsRightInsert } = useFields([
    config.validFromDate,
    config.validToDate,
    config.cancelationDocumentDate,
    config.paymentMethod,
    config.paymentMean,
    ...suspensionDaysFields,
    ...activationDaysFields,
  ]);

  let leftFields = leftFieldsAdd;
  if (
    item?.segmentType === TimelineSegmentType.VOID_BEFORE ||
    item?.segmentType === TimelineSegmentType.VOID_BETWEEN
  ) {
    leftFields = leftFieldsInsert;
  }

  let rightFieldsMtplOrHull = mtplOrHullFieldsRightAdd;
  if (
    item?.segmentType === TimelineSegmentType.VOID_BEFORE ||
    item?.segmentType === TimelineSegmentType.VOID_BETWEEN
  ) {
    rightFieldsMtplOrHull = mtplOrHullFieldsRightInsert;
  }

  const { all: rightFields } = useFields([...suspensionDaysLimitFields]);
  const { all: rightFieldsOtherAdd } = useFields([config.validFromDate]);
  const { all: rightFieldsOtherInsert } = useFields([
    config.validFromDate,
    config.validToDate,
  ]);

  let rightFieldsOther = rightFieldsOtherAdd;
  if (
    item?.segmentType === TimelineSegmentType.VOID_BEFORE ||
    item?.segmentType === TimelineSegmentType.VOID_BETWEEN
  ) {
    rightFieldsOther = rightFieldsOtherInsert;
  }

  const { all: motorTaxWithMtplLeft } = useFields([
    config.isMotorTaxIncluded,
    config.motorTaxExclusionReason,
  ]);
  const { all: motorTaxWithMtplRight } = useFields([
    config.motorTaxPaymentMethod,
    config.motorTaxSuspensionDaysLimit,
  ]);

  const { all: motorTaxWithMtplBottom } = useFields([
    config.motorTaxExclusionComment,
  ]);

  const { all: hullFields1 } = useFields([...garageRiskDiscountPercentFields]);
  const { all: hullFields2 } = useFields([config.hullIsBasic]);

  const { all: bottomFields } = useFields([
    config.reasonForModificationComment,
  ]);

  const { all: hullDeductibleFields } = useFields([
    ...(appSettings.SHOW_HULL_PRODUCT ? [config.hullProduct] : []),
    config.gap,
    config.replacementValue,
    config.grossNegligence,
    config.electroPlusPackage,
    config.hullDeductiblePercent,
    config.hullDeductibleMinAmount,
    config.hullDeductibleMaxAmount,
    config.hullDeductibleComment,
  ]);

  let left = (
    <>
      {premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC
        ? hullFields2
        : null}
      {premiumTypeKey === PremiumType.MTPL ||
      premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC
        ? mtplOrHullFieldsLeft
        : null}
      {leftFields}
    </>
  );
  let right = (
    <>
      {premiumTypeKey === PremiumType.MTPL ||
      premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC
        ? rightFieldsMtplOrHull
        : rightFieldsOther}
      {rightFields}
      {premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC
        ? hullFields1
        : null}
    </>
  );
  let bottom = <>{bottomFields}</>;

  if (
    premiumTypeKey === PremiumType.MTPL &&
    !vehicle.isTradeLicensePlate &&
    appSettings?.VEHICLE_PREMIUM_DOMAINS.includes(PremiumType.HORSE_POWER)
  ) {
    const mtplLeft = <>{motorTaxWithMtplLeft}</>;
    const mtplRight = <>{motorTaxWithMtplRight}</>;
    const mtplBottom = <>{motorTaxWithMtplBottom}</>;
    const mtplDialogContents = (
      <div className="row">
        <div className="column">
          {mtplLeft}
          {mtplRight}
          {mtplBottom}
        </div>
      </div>
    );

    return (
      <>
        <div className="row">
          <div className="column">{left}</div>
          <div className="column">{right}</div>
          {premiumTypeKey === PremiumType.MTPL ? (
            <>
              <div
                className="column"
                style={{ flex: "0.1%", backgroundColor: "#cccccc" }}
              ></div>
              <div className="column">{mtplDialogContents}</div>
            </>
          ) : null}
        </div>
        <div className="row">{bottom}</div>
      </>
    );
  }

  if (
    premiumTypeKey === PremiumType.HULL ||
    premiumTypeKey === PremiumType.HULL_BASIC
  ) {
    return (
      <>
        <div className="row">
          <div className="column">{left}</div>
          <div className="column">{right}</div>
          <>
            <div
              className="column"
              style={{ flex: "0.1%", backgroundColor: "#cccccc" }}
            ></div>
            <div className="column">{hullDeductibleFields}</div>
          </>
        </div>
        <div className="row">{bottom}</div>
      </>
    );
  }

  if (
    premiumTypeKey === PremiumType.FLEETLEGAL_CLOSINGDATE ||
    premiumTypeKey === PremiumType.FLEETLEGAL_PRORATA
  ) {
    return <FleetLegalFormContent />;
  }

  //     hullProduct
  // hullDeductible
  // hullDeductiblePercent
  // hullDeductibleMaxAmount
  // hullDeductibleMinAmount
  // hullDeductibleComment
  // gap
  // replacementValue
  // grossNegligence
  // electroPlusPackage

  return (
    <>
      <div className="row">
        <div className="column">{left}</div>
        <div className="column">{right}</div>
      </div>
      <div className="row">{bottom}</div>
    </>
  );
};

import { mergeStyleSets } from "@uifabric/merge-styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ErrorRetry } from "../../../../../components/ErrorRetry";
import { VSpace } from "../../../../../components/Spacer";
import { NoData } from "../../../../../components/styled";
import { loadFleetUsersActions } from "../../../../../store/fleetForm";
import { useSelector } from "../../../../../store/hooks";
import { Search } from "./Search";
import { Table } from "./Table";

const classes = mergeStyleSets({
  root: {
    position: "relative",
    marginTop: "100px",
    // height: "calc(100vh - 94px - 44px)",
    // overflowY: "auto",
  },
  searchContainer: {
    padding: "10px 15px",
  },
  noData: {
    paddingLeft: "15px",
  },
});

export const Users = ({ usersRef }) => {
  const { t } = useTranslation();
  const users = useSelector((s) => s.fleetForm.tempUsers);
  const loadFleetUsersLoadStatus = useSelector(
    (s) => s.fleetForm.loadFleetUsersLoadStatus
  );
  const loadFleetUsersErrorMessage = useSelector(
    (s) => s.fleetForm.loadFleetUsersErrorMessage
  );
  const areUsersInErrorStatus = loadFleetUsersLoadStatus === "error";
  const dispatch = useDispatch();
  const { fleetId } = useParams() as any;

  return (
    <div className={classes.root} id="users-tab" ref={usersRef}>
      <div className={classes.searchContainer}>
        <Search />
      </div>
      {areUsersInErrorStatus && (
        <>
          <ErrorRetry
            error={loadFleetUsersErrorMessage}
            onRetry={() =>
              dispatch(
                loadFleetUsersActions.trigger({
                  fleetId,
                })
              )
            }
          />
          <VSpace height={10} />
        </>
      )}
      {users.length === 0 && (
        <NoData className={classes.noData}>{t("bfm.noUsersAdded")}</NoData>
      )}
      {users.length > 0 && <Table />}
    </div>
  );
};

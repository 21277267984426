import {
  ConstrainMode,
  DetailsListLayoutMode,
  DetailsRow,
  IDetailsColumnRenderTooltipProps,
  IDetailsHeaderProps,
  IDetailsRowStyles,
  IRenderFunction,
  MarqueeSelection,
  mergeStyleSets,
  SelectAllVisibility,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  TooltipHost,
} from "@fluentui/react";
import { useConst } from "@uifabric/react-hooks";
import { messaging } from "config/colors";
import { useCurrentRoute } from "config/routes";
import { isExternalOrReadonlyAdmin } from "form/util";
import { constants } from "library/constants";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "store/hooks";
import {
  selectWithDocumentDate,
  setSelectedBookings,
} from "store/slices/bookingSlice";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { VehiclePremiumBookingInfo } from "types/types";
import { bookingColumns } from "./bookingColumns";
import { bookingColumnsGeneral } from "./bookingColumnsGeneral";
import BookingContextBtn from "./BookingContextBtn";
import BookingContextMenu from "./BookingContextMenu";
import ChangeBookingIssuingStatusDialog from "./ChangeBookingIssuingStatusDialog";

type TContextualMenyProps = {
  onDismiss: Function;
  target: any;
  item: any;
};

type Props = {
  bookingData: VehiclePremiumBookingInfo[];
  premiumType: string;
};

const excludeFromRegularBooking = [
  "options",
  "status",
  "comment",
  "vehiclePremiumBookingLastModifiedByUserName",
];

const BookingTable = ({ bookingData, premiumType }: Props) => {
  const theme = useTheme() as IStyledTheme;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const taxonomy = useSelector((s) => s.taxonomy);
  const userRole = useSelector((s) => s.auth.userRole);
  const route = useCurrentRoute();

  const withDocumentDate = useSelector(selectWithDocumentDate);
  const isEditMode = route === constants.editVehicle;
  const actionsEnabled =
    withDocumentDate && !isExternalOrReadonlyAdmin(userRole) && isEditMode;

  const [selectedBookings, setSelectedBookingsLocal] = useState([]);
  const [changeIssuingStatusOpen, setChangeIssuingStatusOpen] = useState(false);

  const [contextualMenuProps, setContextualMenuProps] = useState<
    TContextualMenyProps | undefined
  >(undefined);

  const classes = getClassNames(theme);

  const preparedBookingData = useMemo(() => {
    if (premiumType !== "GENERAL") return bookingData;
    let groupedByDate = [];
    bookingData.forEach((vehiclePremiumBooking) => {
      let item = groupedByDate.find(
        (el) =>
          moment(el.premiumBookingDate).format("YYYY-MM-DD") ===
          moment(vehiclePremiumBooking.premiumBookingDate).format("YYYY-MM-DD")
      );
      if (!item) {
        groupedByDate.push({
          premiumType: t(
            taxonomy.PremiumType.byId[vehiclePremiumBooking.premiumTypeCode]
              .code
          ),
          premiumBookingDate: vehiclePremiumBooking.premiumBookingDate,
          status: t(
            taxonomy.VehicleIssuingStatus.byId[
              vehiclePremiumBooking.vehicleIssuingStatusCode
            ].code
          ),
          bookedAmount: vehiclePremiumBooking.bookedAmount,
        });
      } else {
        if (
          !item.premiumType.includes(
            t(
              taxonomy.PremiumType.byId[vehiclePremiumBooking.premiumTypeCode]
                .code
            )
          )
        ) {
          item.premiumType +=
            ", " +
            t(
              taxonomy.PremiumType.byId[vehiclePremiumBooking.premiumTypeCode]
                .code
            );
        }
        if (
          !item.status.includes(
            t(
              taxonomy.VehicleIssuingStatus.byId[
                vehiclePremiumBooking.vehicleIssuingStatusCode
              ].code
            )
          )
        ) {
          item.status +=
            ", " +
            t(
              taxonomy.VehicleIssuingStatus.byId[
                vehiclePremiumBooking.vehicleIssuingStatusCode
              ].code
            );
        }
        item.bookedAmount =
          item.bookedAmount + vehiclePremiumBooking.bookedAmount;
      }
    });
    return groupedByDate;
  }, [bookingData, premiumType, taxonomy]);

  const columns = useMemo(() => {
    let bookingColumnsSelected = bookingColumns;
    if (premiumType === "GENERAL") {
      bookingColumnsSelected = bookingColumnsGeneral;
    }

    const columnList = bookingColumnsSelected.map((item) => {
      return {
        ...item,
        name: t(item.name),
      };
    });
    if (withDocumentDate) {
      if (actionsEnabled) {
        return columnList;
      } else {
        return columnList.filter((item) => item.key !== "options");
      }
    }
    return columnList.filter(
      (item) => !excludeFromRegularBooking.includes(item.key)
    );
  }, [actionsEnabled, t, withDocumentDate]);

  const selection = useConst(
    () =>
      new Selection({
        onSelectionChanged: async () => {
          const selected = selection.getSelection();
          setSelectedBookingsLocal(selected);
          dispatch(setSelectedBookings(selected));
        },
      })
  );

  const onRenderRow = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      const row = props.item;
      if (!row) return undefined;

      let datesAreSame = false;
      if (preparedBookingData.length - 1 > props.itemIndex) {
        const rowDate = preparedBookingData[props.itemIndex].premiumBookingDate;
        const nextRowDate =
          preparedBookingData[props.itemIndex + 1].premiumBookingDate;
        if (moment(rowDate).isSame(moment(nextRowDate), "date")) {
          datesAreSame = true;
        }
      }
      const issuingStatusTaxonomyCode =
        taxonomy.VehicleIssuingStatus.byId[row.vehicleIssuingStatusCode]?.code;
      if (issuingStatusTaxonomyCode === "VehicleIssuingStatus.INCORRECT") {
        customStyles.root = {
          background: `${
            theme.isDark ? theme.palette.redDark : messaging.severeWarning
          } !important`,
        };
      }
      if (issuingStatusTaxonomyCode === "VehicleIssuingStatus.NOT_YET_BOOKED") {
        customStyles.root = {
          borderBottom: datesAreSame
            ? "0px !important"
            : "5px solid rgb(243, 242, 241);",
          background: `${
            theme.isDark
              ? theme.palette.neutralLighter
              : theme.palette.neutralQuaternary
          } !important`,
        };
      }
      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  const onRenderRowGeneral = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      const row = props.item;
      if (!row) return undefined;

      if (moment(row.premiumBookingDate).isBefore(moment(new Date()))) {
        customStyles.root = {
          background: `${theme.isDark ? "#3d3d3d" : "#deded8"} !important`,
        };
      } else {
        customStyles.root = {
          background: `${theme.isDark ? "#787878" : "#efefe8"} !important`,
        };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  const onItemContextMenu = (
    item?: any,
    index?: number | undefined,
    ev?: Event | undefined
  ): boolean => {
    rightClickHandler(item, ev, columns[index || 0]);
    return false;
  };

  const rightClickHandler = (item: any, ev: any, column: any): void => {
    setContextualMenuProps({
      target: column ? ev : { x: ev.pageX, y: ev.pageY },
      item,
      onDismiss: () => {
        setContextualMenuProps(undefined);
      },
    });
  };

  const onRenderColumnHeaderTooltip: IRenderFunction<
    IDetailsColumnRenderTooltipProps
  > = (tooltipHostProps) => {
    return (
      <TooltipHost
        {...tooltipHostProps}
        content={tooltipHostProps?.column?.name}
      />
    );
  };
  return (
    <>
      <div className={classes.container} id="scrollableDiv">
        {contextualMenuProps && actionsEnabled && (
          <BookingContextMenu
            setChangeIssuingStatusOpen={setChangeIssuingStatusOpen}
            selectedBookings={selectedBookings}
            target={contextualMenuProps.target}
            onDismiss={contextualMenuProps.onDismiss}
          />
        )}
        {premiumType !== "GENERAL" ? (
          <MarqueeSelection selection={selection}>
            <ShimmeredDetailsList
              items={preparedBookingData || []}
              compact={false}
              columns={columns || []}
              selectionMode={
                actionsEnabled ? SelectionMode.multiple : SelectionMode.none
              }
              setKey="multiple"
              // getKey={"key"}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onRenderItemColumn={(booking, index, column) => {
                if (column.key === "options") {
                  return (
                    <span className="action-icon">
                      <BookingContextBtn
                        booking={booking}
                        setChangeIssuingStatusOpen={setChangeIssuingStatusOpen}
                      />
                    </span>
                  );
                }
                return column[index];
              }}
              enableShimmer={!preparedBookingData || !columns}
              shimmerLines={5}
              selectionPreservedOnEmptyClick={true}
              selection={selection}
              enterModalSelectionOnTouch={true}
              onItemContextMenu={onItemContextMenu}
              onRenderRow={onRenderRow}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              constrainMode={ConstrainMode.unconstrained}
              className={classes.table}
              onRenderDetailsHeader={(
                props: IDetailsHeaderProps | undefined,
                defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
              ) => (
                <>
                  {defaultRender!({
                    ...props,
                    selectAllVisibility: actionsEnabled
                      ? SelectAllVisibility.hidden
                      : SelectAllVisibility.none,
                    onRenderColumnHeaderTooltip,
                  })}
                </>
              )}
            />
          </MarqueeSelection>
        ) : (
          <>
            <ShimmeredDetailsList
              css={{ marginBottom: "40px" }}
              items={preparedBookingData || []}
              compact={false}
              columns={columns || []}
              selectionMode={SelectionMode.none}
              setKey="multiple"
              // getKey={"key"}

              onRenderItemColumn={(booking, index, column) => {
                if (column.key === "options") {
                  return (
                    <span className="action-icon">
                      <BookingContextBtn
                        booking={booking}
                        setChangeIssuingStatusOpen={setChangeIssuingStatusOpen}
                      />
                    </span>
                  );
                }
                return column[index];
              }}
              className={classes.table}
              enableShimmer={!preparedBookingData || !columns}
              shimmerLines={5}
              onRenderRow={onRenderRowGeneral}
            />
          </>
        )}

        {changeIssuingStatusOpen && (
          <ChangeBookingIssuingStatusDialog
            selection={selection}
            selectedBookings={selectedBookings}
            changeIssuingStatusOpen={changeIssuingStatusOpen}
            setChangeIssuingStatusOpen={setChangeIssuingStatusOpen}
          />
        )}
      </div>
    </>
  );
};

const getClassNames = (theme) =>
  mergeStyleSets({
    container: {
      marginBottom: "20px",
      position: "relative",
      overflow: "auto",
      width: "100%",
    },
    listContainer: {
      display: "flex",
    },

    table: {
      ".ms-List-cell:nth-child(odd) .ms-DetailsRow": {
        background: theme.isDark
          ? "rgb(2, 7, 10)"
          : theme.palette.themeLighterAlt,
      },
      ".ms-DetailsRow ": {
        height: "fit-content !important",
        display: "flex",
        alignItems: "center",
      },

      ".ms-List-cell:hover, .ms-List-cell .is-selected": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(208, 231, 248) !important",
      },
      ".ms-List-cell .ms-DetailsRow:hover": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(208, 231, 248) !important",
      },

      ".ms-DetailsHeader-cellTitle": {
        justifyContent: "center",
      },

      ".ms-DetailsRow-cell": {
        display: "flex",
        justifyContent: "center",
      },
      ".ms-DetailsRow-cell:first-child": {
        display: "flex",
      },

      ".ms-DetailsHeader-cellName i": {
        marginRight: "5px",
        fontSize: "smaller",
        fontWeight: "400",
      },
    },
  });

export default BookingTable;

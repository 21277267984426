import axios from "axios";
import { UseQueryResult, useQuery } from "react-query";
import { Setting } from "types/types";
import { urlFormat } from "utils/utils";
import { QUERY_KEYS } from "../queryKeys";

const uadUrl = urlFormat(process.env.REACT_APP_BFF_HOST_UAD);

export const getAppSettings = async (appID: number | string) => {
  const response = await axios.get(`${uadUrl}/appsettings/${appID}`);

  return response.data;
};

export function useGetAppSettings(
  appId: number | string
): UseQueryResult<Setting[]> {
  return useQuery([QUERY_KEYS.appSettings], () => getAppSettings(appId), {
    enabled: !!appId,
  });
}

import React from "react";
import { ClearButton, Flex } from "../../../components/styled";

import { DefaultButton, Icon } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IDropFile } from "./Dropzone";

type Props = {
  setFile: (file: IDropFile) => void;
  file: IDropFile;
  open: () => void;
};

function SingleUploadPreview({ open, setFile, file }: Props) {
  const { t } = useTranslation();

  return (
    <Flex
      direction="column"
      gap={10}
      style={{
        width: "100%",
        overflow: "hidden",
        flexGrow: 1,
      }}
    >
      <Flex
        style={{
          padding: "0 20px",
          width: "100%",
          overflow: "hidden",
          flexGrow: 1,
        }}
        gap={5}
        justify="center"
      >
        <StyledIcon iconName="ExcelDocument" />
        <FileName title={file.name}>{file.name}</FileName>
        <ClearButton
          iconProps={{ iconName: "Cancel" }}
          onClick={() => {
            setFile(null);
          }}
        />
      </Flex>
      <DefaultButton
        text={t("bfm.chooseDifferentFile")}
        onClick={() => open()}
      />
    </Flex>
  );
}

export default SingleUploadPreview;

const StyledIcon = styled(Icon)`
  color: ${(p) => p.theme.palette.greenDark};
`;

const FileName = styled.div`
  font-size: 20px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

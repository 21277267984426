import { useCurrentRoute } from "config/routes";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadInsurerSettingsActions } from "store/fleetForm";
import { useSelector } from "../../../store/hooks";

export const useFetchInsurerSettings = () => {
  const insurerSettingsExist = useSelector((s) => s.fleetForm.insurerSettings);
  const countryCode = useSelector(
    (s) => s.fleetForm?.fleetWithPremiums?.countryCode
  );
  const dispatch = useDispatch();
  const route = useCurrentRoute();

  useEffect(() => {
    if (!insurerSettingsExist && countryCode) {
      dispatch(
        loadInsurerSettingsActions.trigger({ countryCode: countryCode + "" })
      );
    }
  }, [insurerSettingsExist, route, countryCode]);
};

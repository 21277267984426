import { VehicleAzure } from "../types/types";

export const allowedVehiclePolicyStatuses = {
  admin: (vehicle: VehicleAzure, currentStatusCode: string) => {
    if (currentStatusCode === "VehiclePolicyStatusCode.ACTIVE") {
      return [
        "VehiclePolicyStatusCode.SUSPENDED",
        "VehiclePolicyStatusCode.DEACTIVATED",
      ];
    }
    if (currentStatusCode === "VehiclePolicyStatusCode.SUSPENDED") {
      return [
        "VehiclePolicyStatusCode.ACTIVE",
        "VehiclePolicyStatusCode.DEACTIVATED",
      ];
    }
    if (currentStatusCode === "VehiclePolicyStatusCode.DEACTIVATED") return [];
    if (
      currentStatusCode === "VehiclePolicyStatusCode.PENDING_FOR_ACTIVATION"
    ) {
      return [
        "VehiclePolicyStatusCode.ACTIVE",
        "VehiclePolicyStatusCode.DEACTIVATED",
      ];
    }
    if (
      currentStatusCode === "VehiclePolicyStatusCode.PENDING_FOR_SUSPENSION"
    ) {
      return [
        "VehiclePolicyStatusCode.SUSPENDED",
        "VehiclePolicyStatusCode.DEACTIVATED",
      ];
    }
    if (
      currentStatusCode === "VehiclePolicyStatusCode.PENDING_FOR_DEACTIVATION"
    ) {
      return ["VehiclePolicyStatusCode.DEACTIVATED"];
    }
    return [
      "VehiclePolicyStatusCode.ACTIVE",
      "VehiclePolicyStatusCode.SUSPENDED",
      "VehiclePolicyStatusCode.DEACTIVATED",
    ];
  },
  internal: (vehicle: VehicleAzure, currentStatusCode: string) => {
    if (currentStatusCode === "VehiclePolicyStatusCode.ACTIVE") {
      return [
        "VehiclePolicyStatusCode.SUSPENDED",
        "VehiclePolicyStatusCode.DEACTIVATED",
      ];
    }
    if (currentStatusCode === "VehiclePolicyStatusCode.SUSPENDED") {
      return [
        "VehiclePolicyStatusCode.ACTIVE",
        "VehiclePolicyStatusCode.DEACTIVATED",
      ];
    }
    if (currentStatusCode === "VehiclePolicyStatusCode.DEACTIVATED") return [];
  },
};

export const allowedVehicleStatuses = {
  admin: (vehicle: VehicleAzure, currentStatusCode: string) => {
    if (currentStatusCode === "VehicleStatusCode.ACTIVE") {
      return ["VehicleStatusCode.SUSPENDED", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.SUSPENDED") {
      return ["VehicleStatusCode.ACTIVE", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.DEACTIVATED") return [];
    if (currentStatusCode === "VehicleStatusCode.PENDING_FOR_ACTIVATION") {
      return ["VehicleStatusCode.ACTIVE", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.PENDING_FOR_SUSPENSION") {
      return ["VehicleStatusCode.SUSPENDED", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.PENDING_FOR_DEACTIVATION") {
      return ["VehicleStatusCode.DEACTIVATED"];
    }
    return [
      "VehicleStatusCode.ACTIVE",
      "VehicleStatusCode.SUSPENDED",
      "VehicleStatusCode.DEACTIVATED",
    ];
  },
  internal: (vehicle: VehicleAzure, currentStatusCode: string) => {
    if (currentStatusCode === "VehicleStatusCode.ACTIVE") {
      return ["VehicleStatusCode.SUSPENDED", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.SUSPENDED") {
      return ["VehicleStatusCode.ACTIVE", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.DEACTIVATED") return [];
    if (currentStatusCode === "VehicleStatusCode.PENDING_FOR_ACTIVATION") {
      return ["VehicleStatusCode.ACTIVE", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.PENDING_FOR_SUSPENSION") {
      return ["VehicleStatusCode.SUSPENDED", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.PENDING_FOR_DEACTIVATION") {
      return ["VehicleStatusCode.DEACTIVATED"];
    }
    return [
      "VehicleStatusCode.ACTIVE",
      "VehicleStatusCode.SUSPENDED",
      "VehicleStatusCode.DEACTIVATED",
    ];
  },
  admininternal: (vehicle: VehicleAzure, currentStatusCode: string) => {
    if (currentStatusCode === "VehicleStatusCode.ACTIVE") {
      return ["VehicleStatusCode.SUSPENDED", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.SUSPENDED") {
      return ["VehicleStatusCode.ACTIVE", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.DEACTIVATED") return [];
    if (currentStatusCode === "VehicleStatusCode.PENDING_FOR_ACTIVATION") {
      return ["VehicleStatusCode.ACTIVE", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.PENDING_FOR_SUSPENSION") {
      return ["VehicleStatusCode.SUSPENDED", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.PENDING_FOR_DEACTIVATION") {
      return ["VehicleStatusCode.DEACTIVATED"];
    }
    return [
      "VehicleStatusCode.ACTIVE",
      "VehicleStatusCode.SUSPENDED",
      "VehicleStatusCode.DEACTIVATED",
    ];
  },
  adminreadonly_admininternal: (
    vehicle: VehicleAzure,
    currentStatusCode: string
  ) => {
    if (currentStatusCode === "VehicleStatusCode.ACTIVE") {
      return ["VehicleStatusCode.SUSPENDED", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.SUSPENDED") {
      return ["VehicleStatusCode.ACTIVE", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.DEACTIVATED") return [];
    if (currentStatusCode === "VehicleStatusCode.PENDING_FOR_ACTIVATION") {
      return ["VehicleStatusCode.ACTIVE", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.PENDING_FOR_SUSPENSION") {
      return ["VehicleStatusCode.SUSPENDED", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.PENDING_FOR_DEACTIVATION") {
      return ["VehicleStatusCode.DEACTIVATED"];
    }
    return [
      "VehicleStatusCode.ACTIVE",
      "VehicleStatusCode.SUSPENDED",
      "VehicleStatusCode.DEACTIVATED",
    ];
  },
  adminreadonly_internal: (
    vehicle: VehicleAzure,
    currentStatusCode: string
  ) => {
    if (currentStatusCode === "VehicleStatusCode.ACTIVE") {
      return ["VehicleStatusCode.SUSPENDED", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.SUSPENDED") {
      return ["VehicleStatusCode.ACTIVE", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.DEACTIVATED") return [];
    if (currentStatusCode === "VehicleStatusCode.PENDING_FOR_ACTIVATION") {
      return ["VehicleStatusCode.ACTIVE", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.PENDING_FOR_SUSPENSION") {
      return ["VehicleStatusCode.SUSPENDED", "VehicleStatusCode.DEACTIVATED"];
    }
    if (currentStatusCode === "VehicleStatusCode.PENDING_FOR_DEACTIVATION") {
      return ["VehicleStatusCode.DEACTIVATED"];
    }
    return [
      "VehicleStatusCode.ACTIVE",
      "VehicleStatusCode.SUSPENDED",
      "VehicleStatusCode.DEACTIVATED",
    ];
  },
  external: (vehicle: VehicleAzure, currentStatusCode: string) => {
    if (currentStatusCode === "VehicleStatusCode.DEACTIVATED") return [];
    if (currentStatusCode === "VehicleStatusCode.ACTIVE") {
      return [
        "VehicleStatusCode.PENDING_FOR_SUSPENSION",
        "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
      ];
    }
    if (currentStatusCode === "VehicleStatusCode.SUSPENDED") {
      return [
        "VehicleStatusCode.PENDING_FOR_ACTIVATION",
        "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
      ];
    }
    if (
      [
        "VehicleStatusCode.PENDING_FOR_ACTIVATION",
        "VehicleStatusCode.PENDING_FOR_SUSPENSION",
        "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
      ].includes(currentStatusCode)
    ) {
      return [];
    }
    return [
      "VehicleStatusCode.PENDING_FOR_ACTIVATION",
      "VehicleStatusCode.PENDING_FOR_SUSPENSION",
      "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
    ];
  },
  adminreadonly: (vehicle: VehicleAzure, currentStatusCode: string) => {
    return [];
  },
};

import { useApproveVehiclePendingStatus } from "hooks/data/mutations/useApproveVehiclePendingStatus";
import { MUTATION_KEYS } from "hooks/data/queryKeys";
import React from "react";
import { useTranslation } from "react-i18next";
import { useIsMutating } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "../../../../components/FluentToast";
import { VehicleContextButton } from "../../../../components/VehicleContextButton";
import { CenteredCell } from "../../../../components/styled";
import {
  loadVehiclesActions,
  setCancelVehicleStatusDialog,
  setChangeVehicleIssuingStatusDialog,
  setChangeVehiclePendingIssuingStatusDialog,
  setChangeVehicleStatusDialog,
  setCorrectStatusDateDialog,
} from "../../../../store/fleetPage";
import { useSelector } from "../../../../store/hooks";
import { AppDispatch } from "../../../../store/store";
import { VehicleRedux } from "../../../../types/types";

type Props = {
  vehicle: VehicleRedux;
};

const ContextButton = ({ vehicle }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const isUpdateVehicleStatusLoading = !!useIsMutating({
    mutationKey: [MUTATION_KEYS.changeVehicleStatus],
  });

  // const isVehicleSelected = vehicle?.isSelected;

  const {
    mutate: onApproveVehiclePendingStatus,
    isLoading: isApproveVehicleStatusLoading,
  } = useApproveVehiclePendingStatus();

  const selectedVehicleIds = useSelector((s) => s.fleetPage.selectedVehicleIds);
  const fleetId = useSelector((s) => s.fleetForm.fleetWithPremiums?.fleetId);

  const onClickChangeStatus = () => {
    dispatch(
      setChangeVehicleStatusDialog({
        isOpen: true,
        vehicleIds: selectedVehicleIds,
        fleetId: fleetId?.toString(),
        vehicle,
      })
    );
  };

  const onClickChangeIssuingStatus = () => {
    dispatch(
      setChangeVehicleIssuingStatusDialog({
        isOpen: true,
        vehicleIds: selectedVehicleIds,
        fleetId: fleetId?.toString(),
      })
    );
  };

  const onClickChangePendingIssuingStatus = () => {
    dispatch(
      setChangeVehiclePendingIssuingStatusDialog({
        isOpen: true,
        vehicleIds: selectedVehicleIds,
        fleetId: fleetId?.toString(),
      })
    );
  };

  const onClickCorrectStatusDate = () => {
    dispatch(
      setCorrectStatusDateDialog({
        isOpen: true,
        vehicle,
      })
    );
  };

  const onClickApproveStatus = () => {
    onApproveVehiclePendingStatus(
      {
        fleetId: vehicle.fleetId,
        vehicleId: vehicle.vehicleId,
      },
      {
        onSuccess: () => {
          dispatch(loadVehiclesActions.trigger({ isReset: true }));
          toast.success(t("bfm.success"));
        },
      }
    );
  };

  const onClickCancelStatus = () => {
    dispatch(
      setCancelVehicleStatusDialog({
        fleetId: vehicle.fleetId,
        vehicleId: vehicle.vehicleId,
        isOpen: true,
      })
    );
  };

  return (
    <CenteredCell>
      <VehicleContextButton
        fleetId={fleetId}
        vehicle={vehicle}
        onClickChangeStatus={onClickChangeStatus}
        onClickChangeIssuingStatus={onClickChangeIssuingStatus}
        onClickChangePendingIssuingStatus={onClickChangePendingIssuingStatus}
        onClickCorrectStatusDate={onClickCorrectStatusDate}
        onClickApproveStatus={onClickApproveStatus}
        onClickCancelStatus={onClickCancelStatus}
        isApproveVehicleStatusLoading={isApproveVehicleStatusLoading}
        isUpdateVehicleStatusLoading={isUpdateVehicleStatusLoading}
      />
    </CenteredCell>
  );
};

export default React.memo(ContextButton);

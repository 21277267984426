import { UseQueryResult, useQuery } from "react-query";
import { Client } from "types/types";
import { QUERY_KEYS } from "../queryKeys";
import { getClients } from "store/api/api";

const fetchClientList = async () => {
  const response = await getClients();
  return response.data;
};

export function useGetClientList(): UseQueryResult<Client[]> {
  return useQuery([QUERY_KEYS.clientList], fetchClientList, {
    enabled: true,
  });
}

import React from "react";
import FluentTooltip from "../../../components/FluentTooltip";
import { formatDate } from "../../../utils/utils";
import CountryCode from "./columnCells/CountryCode";
import FleetName from "./columnCells/FleetName";
import MainInsurerCell from "./columnCells/MainInsurerCell";

export const defaultColumns: any[] = [
  {
    key: "fleetName",
    fieldName: "fleetName",
    labelKey: "bfm.fleetForm.fleetName.label",
    name: "bfm.fleetForm.fleetName.label",
    maxWidth: 250,
    minWidth: 250,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      if (!row) return null;
      return <FleetName fleetName={row?.fleetName} fleetId={row?.fleetId} />;
    },
  },
  {
    key: "options",
    fieldName: "options",
    labelKey: "",
    name: "",
    maxWidth: 20,
    minWidth: 20,
    isVisible: true,
  },
  {
    key: "countryCode",
    fieldName: "countryCode",
    labelKey: "bfm.country.label",
    name: "bfm.country.label",
    maxWidth: 70,
    isSortable: true,
    iconName: "Sort",
    minWidth: 70,
    isVisible: true,
    onRender: (row) => <CountryCode code={row?.countryCode} />,
  },
  {
    key: "clientName",
    fieldName: "clientName",
    labelKey: "bfm.fleetForm.client.label",
    name: "bfm.fleetForm.client.label",
    maxWidth: 250,
    minWidth: 250,
    isVisible: true,
    isSortable: true,
    onRender: (row) => (
      <FluentTooltip
        content={row?.clientName + " (" + row?.webBasePartnerNumber + ")"}
      >
        {row?.clientName + " (" + row?.webBasePartnerNumber + ")"}
      </FluentTooltip>
    ),
    iconName: "Sort",
  },
  {
    key: "mainMtplInsurerPartnerName",
    fieldName: "mainMtplInsurerPartnerName",
    labelKey: "bfm.fleetForm.mainMtplInsurerPartnerName.label",
    name: "bfm.fleetForm.mainMtplInsurerPartnerName.label",
    maxWidth: 230,
    minWidth: 230,
    isVisible: true,
    isSortable: true,
    onRender: (row) => (
      <MainInsurerCell
        hasMultipleInsurers={row.hasMultipleMtplInsurers}
        mainPartnerName={row?.mainMtplInsurerPartnerName}
        mainPartnerNumber={row?.mainMtplInsurerPartnerName}
      />
    ),
    iconName: "Sort",
  },
  {
    key: "mainHullInsurerPartnerName",
    fieldName: "mainHullInsurerPartnerName",
    labelKey: "bfm.fleetForm.mainHullInsurerPartnerName.label",
    name: "bfm.fleetForm.mainHullInsurerPartnerName.label",
    maxWidth: 230,
    minWidth: 230,
    isVisible: true,
    isSortable: true,
    onRender: (row) => (
      <MainInsurerCell
        hasMultipleInsurers={row.hasMultipleHullInsurers}
        mainPartnerName={row?.mainHullInsurerPartnerName}
        mainPartnerNumber={row?.mainHullInsurerPartnerNumber}
      />
    ),
    iconName: "Sort",
  },
  {
    key: "mainFleetLegalInsurerPartnerName",
    fieldName: "mainFleetLegalInsurerPartnerName",
    labelKey: "bfm.fleetForm.mainFleetLegalInsurerPartnerName.label",
    name: "bfm.fleetForm.mainFleetLegalInsurerPartnerName.label",
    maxWidth: 230,
    minWidth: 230,
    isVisible: true,
    isSortable: true,
    onRender: (row) => (
      <MainInsurerCell
        hasMultipleInsurers={row.hasMultipleFleetLegalInsurers}
        mainPartnerName={row?.mainFleetLegalInsurerPartnerName}
        mainPartnerNumber={row?.mainFleetLegalInsurerPartnerNumber}
      />
    ),
    iconName: "Sort",
  },
  {
    key: "businessYearStartMonth",
    fieldName: "businessYearStartMonth",
    labelKey: "bfm.fleetForm.fiscalDate.label",
    name: "bfm.fleetForm.fiscalDate.label",
    maxWidth: 90,
    minWidth: 90,
    isSortable: true,
    iconName: "Sort",
    isVisible: true,
    onRender: (row) =>
      row?.businessYearStartDay + "." + row?.businessYearStartMonth,
  },
  {
    key: "dueMonth",
    fieldName: "dueMonth",
    labelKey: "bfm.fleetForm.dueDate.label",
    name: "bfm.fleetForm.dueDate.label",
    maxWidth: 90,
    minWidth: 90,
    isSortable: true,
    iconName: "Sort",
    isVisible: true,
    onRender: (row) => row?.dueDay + "." + row?.dueMonth,
  },
  {
    key: "fleetCreatedAt",
    fieldName: "fleetCreatedAt",
    labelKey: "bfm.fleetForm.fleetCreatedAt.label",
    name: "bfm.fleetForm.fleetCreatedAt.label",
    maxWidth: 90,
    minWidth: 90,
    isVisible: true,
    isSortable: true,
    onRender: (row) =>
      row?.fleetCreatedAt && formatDate(new Date(row?.fleetCreatedAt)),
    iconName: "Sort",
  },
  {
    key: "fleetCreatedByUserName",
    fieldName: "fleetCreatedByUserName",
    labelKey: "bfm.fleetForm.fleetCreatedBy.label",
    name: "bfm.fleetForm.fleetCreatedBy.label",
    maxWidth: 130,
    minWidth: 130,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => (
      <FluentTooltip
        content={row?.fleetCreatedByUserName ?? row?.fleetCreatedByUserId}
      >
        {row?.fleetCreatedByUserName ?? row?.fleetCreatedByUserId}
      </FluentTooltip>
    ),
  },
  {
    key: "fleetPolicyNumber",
    fieldName: "fleetPolicyNumber",
    labelKey: "bfm.fleetForm.fleetPolicyNumber.label",
    name: "bfm.fleetForm.fleetPolicyNumber.label",
    maxWidth: 110,
    minWidth: 110,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      if (!row) return null;
      return row?.fleetPolicyNumber;
    },
  },
  {
    key: "fleetLegalPolicyNumber",
    fieldName: "fleetLegalPolicyNumber",
    labelKey: "bfm.fleetForm.fleetLegalPolicyNumber.label",
    name: "bfm.fleetForm.fleetLegalPolicyNumber.label",
    maxWidth: 200,
    minWidth: 200,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      if (!row) return null;
      return row?.fleetLegalPolicyNumber;
    },
  },
  {
    key: "fleetComment",
    fieldName: "fleetComment",
    labelKey: "bfm.fleetForm.fleetComment.label",
    name: "bfm.fleetForm.fleetComment.label",
    maxWidth: 250,
    minWidth: 250,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => (
      <FluentTooltip content={row?.fleetComment ?? row?.fleetComment}>
        {row?.fleetComment ?? row?.fleetComment}
      </FluentTooltip>
    ),
  },
];

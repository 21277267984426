import React from "react";
import { TimelineDialogType } from "../../../types/types";
import { useFields } from "../../form/useFields";
import { modifyLicensePlateDialogConfig } from "./modifyLicensePlateDialogConfig";

const config = modifyLicensePlateDialogConfig;

interface IProps {
  dialogType: TimelineDialogType;
}

export const ModifyLicensePlateDialogContents = ({ dialogType }: IProps) => {
  const { all: licensePlate } = useFields([config.licensePlate]);
  const { all: validFromDate } = useFields([config.validFromDate]);
  const { all: validTo } = useFields([config.validToDate]);
  const { all: comment } = useFields([config.comment]);

  let dialogContents = null;
  if (dialogType === TimelineDialogType.INSERT_LICENSE_PLATE) {
    dialogContents = (
      <>
        <div className="row">{licensePlate}</div>
        <div className="row">{validFromDate}</div>
        <div className="row">{validTo}</div>
        <div className="row">{comment}</div>
      </>
    );
  }
  if (dialogType === TimelineDialogType.CHANGE_LICENSE_PLATE) {
    dialogContents = (
      <>
        <div className="row">{licensePlate}</div>
        <div className="row">{validFromDate}</div>
        <div className="row">{comment}</div>
      </>
    );
  }
  if (dialogType === TimelineDialogType.CORRECT_LICENSE_PLATE) {
    dialogContents = (
      <>
        <div className="row">{licensePlate}</div>
        <div className="row">{validFromDate}</div>
        <div className="row">{comment}</div>
      </>
    );
  }
  if (dialogType === TimelineDialogType.DELETE_LICENSE_PLATE) {
    dialogContents = (
      <>
        <div className="row">{comment}</div>
      </>
    );
  }

  return dialogContents;
};

import * as yup from "yup";
import { TimelineDialogType } from "../../../types/types";

export const validationObjectConfig = (item, dialogType): any => {
  const fieldSets = [];

  const basicFields = {
    oneTimePremiumDate: yup.date().required().nullable(),
    oneTimePremiumGrossValue: yup.string().required(),
    oneTimePremiumNetValue: yup.string().required(),
  };

  const commentField = {
    oneTimePremiumComment: yup.string().trim().max(512),
  };

  fieldSets.push(basicFields); //0
  fieldSets.push(commentField); //1

  let yupObject: any = {};
  if (dialogType === TimelineDialogType.CHANGE_ONETIME_PREMIUM) {
    yupObject = {
      ...fieldSets[0],
      ...fieldSets[1],
    };
  }
  if (dialogType === TimelineDialogType.ADD_ONETIME_PREMIUM) {
    yupObject = {
      ...fieldSets[0],
      ...fieldSets[1],
    };
  }
  if (dialogType === TimelineDialogType.DELETE_ONETIME_PREMIUM) {
    yupObject = {
      ...fieldSets[1],
    };
  }
  return yupObject;
};

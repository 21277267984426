import React from "react";
import { formatNumber } from "utils/number";
import { formatDateString } from "utils/utils";
import BookingField from "./BookingField";

export const bookingColumnsGeneral: any[] = [
  {
    key: "premiumType",
    isResizable: true,
    fieldName: "premiumType",
    labelKey: "bfm.fleet.premiumType.label",
    name: "bfm.fleet.premiumType.label",
    minWidth: 50,
    maxWidth: 500,
    isVisible: true,
    onRender: (row) => {
      if (!row) return null;
      return row.premiumType;
    },
  },

  {
    key: "bookingDate",
    isResizable: true,
    fieldName: "bookingData",
    labelKey: "bfm.vehicleForm.tabPremiumBooking.bookingDate.label",
    name: "bfm.vehicleForm.tabPremiumBooking.bookingDate.label",
    minWidth: 100,
    maxWidth: 100,
    isVisible: true,
    onRender: (row) => {
      if (!row) return null;
      return <BookingField value={formatDateString(row?.premiumBookingDate)} />;
    },
  },

  {
    key: "status",
    isResizable: true,
    fieldName: "status",
    labelKey: "bfm.vehicleForm.tabPremiumBooking.form.status.label",
    name: "bfm.vehicleForm.tabPremiumBooking.form.status.label",
    minWidth: 300,
    maxWidth: 500,
    isVisible: true,
    onRender: (row) => {
      if (!row) return null;

      return row.status;
    },
  },
  {
    key: "booked",
    isResizable: true,
    fieldName: "booked",
    labelKey: "bfm.vehicleForm.tabPremiumBooking.booked.label",
    name: "bfm.vehicleForm.tabPremiumBooking.booked.label",
    minWidth: 70,
    maxWidth: 100,
    isVisible: true,

    onRender: (row) => {
      if (!row) return null;

      return (
        <span style={{ textAlign: "right" }}>
          &nbsp;&nbsp;&nbsp;{formatNumber(row?.bookedAmount)}&nbsp;&nbsp;&nbsp;
        </span>
      );
    },
  },
];

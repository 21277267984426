import { DatePicker, Icon, Text, mergeStyleSets } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import ErrorMsg from "./ErrorMsg";
import { FieldContainer } from "./FieldContainer";

const DatePickerField = ({
  defaultValue,
  disabled,
  label,
  placeholder,
  name = "",
  setFieldValue,
  errors,
  required = false,
  isMarked = false,
  confidentiality = null,
  ...props
}) => {
  const theme = useTheme() as IStyledTheme;
  const { t } = useTranslation();
  const [value, setValue] = useState(defaultValue);

  const classes = getClasses(theme, theme?.isDark, disabled);

  useEffect(() => {
    setValue(defaultValue);
    setFieldValue(name, defaultValue);
  }, [defaultValue]);

  const onFormatDate = (date?: Date): string => {
    return !date
      ? ""
      : ((date.getDate() + "").length === 1 ? "0" : "") +
          date.getDate() +
          "." +
          ((date.getMonth() + 1 + "").length === 1 ? "0" : "") +
          (date.getMonth() + 1) +
          "." +
          date.getFullYear();
  };
  const onParseDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = value || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year += previousValue.getFullYear();
      }
      return new Date(year, month, day);
    },
    [value]
  );

  return (
    <FieldContainer isTooltipHidden={disabled} tooltipText={label}>
      <DatePicker
        id={name + "-date-picker"}
        onSelectDate={(value) => {
          setValue(value);
          setFieldValue(name, value);
        }}
        styles={datePickerStyles(theme, isMarked, confidentiality, disabled)}
        textField={{
          name: name,
          // errorMessage: errors[name] ? errors[name] : null,
          onRenderLabel: () => (
            <Text className={classes.label}>
              {t(label)}{" "}
              <Text className={classes.labelAsterix}>{required && "*"}</Text>
            </Text>
          ),

          onRenderSuffix: () => (
            <Icon
              style={{
                visibility: value && !disabled ? "visible" : "hidden",
              }}
              iconName="Cancel"
              onClick={() => {
                setValue(null);
                setFieldValue(name, null);
              }}
            />
          ),
          styles: { suffix: { padding: "0 4px" } },
        }}
        formatDate={onFormatDate}
        disabled={disabled}
        // isRequired={required}
        label={t(label)}
        value={value}
        placeholder={placeholder}
        ariaLabel="Select a date"
        {...props}
        allowTextInput={true}
        disableAutoFocus={true}
        parseDateFromString={onParseDateFromString}
      />
      {errors[name] && <ErrorMsg>{errors[name]}</ErrorMsg>}
    </FieldContainer>
  );
};

export default React.memo(DatePickerField);

const getClasses = (theme, isDarkMode, disabled) =>
  mergeStyleSets({
    label: {
      fontSize: "14px",
      fontWeight: "600",
      padding: "5px",
      display: "block",
      color: (function () {
        if (disabled) {
          return theme.palette.neutralSecondaryAlt;
        }
        if (isDarkMode) {
          return "white";
        }
        return theme.palette.black;
      })(),
    },
    labelAsterix: {
      color: theme.palette.redDark,
    },
  });

const datePickerStyles = (theme, isMarked, confidentiality, disabled) => ({
  root: {
    ".ms-TextField-fieldGroup": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
      background: "transparent !important",
      ...(isMarked &&
        !confidentiality && { background: theme.palette.yellowLight }),
      ...(confidentiality && {
        background: `rgb(${confidentiality < 0.9 ? 255 : 200},${
          confidentiality >= 0.8 ? 255 : 200
        },200)`,
      }),
    },
    ".ms-TextField-fieldGroup::after": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
    },
    ".ms-TextField-suffix": {
      //Cancel Icon
      marginRight: "30px",
      background: "none",
      cursor: "pointer",
    },
    ".ms-TextField-fieldGroup input": {
      background: "transparent !important",
      color: isMarked
        ? "black"
        : theme.isDark
        ? confidentiality
          ? "black"
          : "white"
        : "black",
    },
  },
  icon: { right: "0" },

  label: {
    fontSize: "14px",
    fontWeight: "600",
    padding: "5px",
    display: "block",
    color: (function () {
      if (disabled) {
        return theme.palette.neutralSecondaryAlt;
      }
      if (theme?.isDark) {
        return "white";
      }
      return theme.palette.black;
    })(),
  },

  labelAsterix: {
    color: theme.palette.redDark,
  },
});

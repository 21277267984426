import { mergeStyleSets, Nav } from "@fluentui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { TimelineType, UserRole } from "types/types";
import { useCurrentRoute } from "../../../config/routes";
import { useSelector } from "../../../store/hooks";
import * as VehicleSlice from "../../../store/vehicle";

const getClasses = (theme, isDarkMode) =>
  mergeStyleSets({
    navigation: {
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
      height: "100%",
      "& .ms-Nav-groupContent": {
        marginBottom: "0",
      },
      ".ms-Nav-compositeLink.is-selected .ms-Button": {
        background: isDarkMode ? "#001321" : "#d0e7f8",
      },
    },
  });

const SidebarNav = () => {
  const dispatch = useDispatch();
  const userRole = useSelector((s) => s.auth.userRole);
  const route = useCurrentRoute();
  const tab = useSelector((s) => s.vehicle.tab);
  const vehicle = useSelector((s) => s.vehicle.vehicle);
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;
  const classes = getClasses(theme, isDarkMode);
  const { t } = useTranslation();
  const location = useLocation();
  const vehicleAppSettings = useSelector((s) => s.vehicle.vehicleAppSettings);

  const isFinanceVisible = !(
    route === "createVehicle" && userRole === UserRole.external
  );

  const isVehicleVisible =
    route !== "createTradeVehicle" && !vehicle?.isTradeLicensePlate;

  const isBookingsVisible = vehicleAppSettings?.VEHICLE_TIMELINE_TYPES.includes(
    TimelineType.VEHICLE_PREMIUM_BOOKINGS
  );

  const handleNavItemClick = (e, tab) => {
    const tabItem: any = document.querySelector(`#${tab.key}`);
    document.querySelector("#vehicle-form-wrapper")!.scroll({
      // document.querySelector("#app-wrapper")!.scroll({
      top: tab.key === "booking-vehicle-form" ? 0 : tabItem?.offsetTop,
      left: 0,
      behavior: "auto",
    });
    if (!tab) return;
    dispatch(VehicleSlice.setTab(tab.key as any));
  };

  const handleItemChangeFromLocation = () => {
    if (location.search) {
      const tab = location.search.split("?tab=")[1];
      const tabItem: any = document.querySelector(`#${tab}`);
      document.querySelector("#vehicle-form-wrapper")!.scroll({
        top: tabItem?.offsetTop,
        left: 0,
        behavior: "auto",
      });
      dispatch(VehicleSlice.setTab(tab));
    }
  };

  useEffect(() => {
    handleItemChangeFromLocation();
  }, []);

  return (
    <>
      <Nav
        onLinkClick={handleNavItemClick}
        selectedKey={tab}
        groups={getNavLinkGroups(
          isFinanceVisible,
          isVehicleVisible,
          isBookingsVisible,
          t,
          route
        )}
        className={classes.navigation}
      />
    </>
  );
};

const getNavLinkGroups = (
  isFinanceVisible: boolean,
  isVehicleVisible: boolean,
  isBookingsVisible: boolean,
  t: any,
  route: any
) => {
  const groups = [
    {
      links: [
        {
          name: t("bfm.vehicleForm.tabGeneral.label"),
          url: "",
          key: "general-vehicle-form",
          isExpanded: true,
          target: "_blank",
        },
      ],
    },
  ];

  if (isVehicleVisible) {
    groups[0].links.push({
      name: t("bfm.vehicleForm.tabVehicle.label"),
      url: "",
      key: "vehicle-form",
      isExpanded: true,
      target: "_blank",
    });
  }

  if (isFinanceVisible) {
    groups[0].links.push({
      name: t("bfm.vehicleForm.tabFinancials.label"),
      url: "",
      key: "finance-vehicle-form",
      isExpanded: true,
      target: "_blank",
    });
  }

  if (
    isBookingsVisible &&
    route !== "createVehicle" &&
    route !== "editTempVehicle" &&
    route !== "createTradeVehicle"
  ) {
    groups[0].links.push({
      name: t("bfm.bookings.label"),
      url: "",
      key: "booking-vehicle-form",
      isExpanded: true,
      target: "_blank",
    });
  }

  return groups;
};
export default SidebarNav;

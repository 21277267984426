import { useMutation } from "react-query";
import {
  ClosedVehiclePremiumRequestParams,
  cancelVehiclePremium,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const cancelVehiclePremiumFn = async ({
  rowVersion,
  params,
}: {
  rowVersion: string;
  params: ClosedVehiclePremiumRequestParams;
}) => {
  return await cancelVehiclePremium(rowVersion, params);
};

export function useCancelVehiclePremium() {
  return useMutation(cancelVehiclePremiumFn, {
    mutationKey: MUTATION_KEYS.cancelVehiclePremium,
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

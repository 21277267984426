import { TFunction } from "i18next";
import React from "react";
import { ClaimIcon } from "./componentIcons/ClaimIcon";
import { ClientIcon } from "./componentIcons/ClientIcon";
import { FleetIcon } from "./componentIcons/FleetIcon";
import { InsurerIcon } from "./componentIcons/InsurerIcon";
import { IntermediaryIcon } from "./componentIcons/IntermediaryIcon";
import { PartnerIcon } from "./componentIcons/PartnerIcon";
import { PolicyIcon } from "./componentIcons/PolicyIcon";
import { RamtIcon } from "./componentIcons/RamtIcon";
import { TmsIcon } from "./componentIcons/TmsIcon";
import { INavigationLink } from "./types";

const GOS_BASE_URL = process.env.REACT_APP_GOS_BASE_URL;

export const NavigationLinks = (t: TFunction): INavigationLink[] => {
  return [
    {
      icon: () => {
        return <ClientIcon width="31" height="26" />;
      },
      iconLarge: () => {
        return <ClientIcon width="62" height="52" />;
      },
      name: t("bfm.clients.label"),
      path: `${GOS_BASE_URL}/crm/clients`,
      background: "#2B579A",
      disabled: false,
      id: "gos-homepage-clients",
      openInNewTab: true,
    },
    {
      icon: () => {
        return <InsurerIcon width="31" height="26" />;
      },
      iconLarge: () => {
        return <InsurerIcon width="62" height="52" />;
      },
      name: t("bfm.insurers.label"),
      path: `${GOS_BASE_URL}/crm/insurers`,
      background: "#2B579A",
      disabled: false,
      id: "gos-homepage-insurers",
      openInNewTab: true,
    },
    {
      icon: () => {
        return <IntermediaryIcon width="36" height="36" />;
      },
      iconLarge: () => {
        return <IntermediaryIcon width="62" height="56" />;
      },
      name: t("bfm.intermediaries.label"),
      path: `${GOS_BASE_URL}/crm/intermediaries`,
      background: "#2B579A",
      disabled: false,
      id: "gos-homepage-intermediaries",
      openInNewTab: true,
    },
    {
      icon: () => {
        return <PartnerIcon width="31" height="26" />;
      },
      iconLarge: () => {
        return <PartnerIcon width="62" height="52" />;
      },
      name: t("bfm.experts.label"),
      path: `${GOS_BASE_URL}//crm/experts`,
      background: "#2B579A",
      disabled: true,
      id: "gos-homepage-experts",
      openInNewTab: true,
    },
    {
      icon: () => {
        return <PolicyIcon width="31" height="26" />;
      },
      iconLarge: () => {
        return <PolicyIcon width="62" height="52" />;
      },
      name: t("bfm.policies.label"),
      path: `${GOS_BASE_URL}/policies`,
      background: "#2B579A",
      disabled: false,
      id: "gos-homepage-policies",

      openInNewTab: true,
    },
    {
      icon: () => {
        return <ClaimIcon width="31" height="31" />;
      },
      iconLarge: () => {
        return <ClaimIcon width="62" height="52" />;
      },
      name: t("bfm.claims.label"),
      path: `${GOS_BASE_URL}/claims`,
      background: "#5D8DE4",
      disabled: false,
      id: "gos-homepage-claims",
      openInNewTab: true,
    },
    {
      icon: () => {
        return <TmsIcon width="31" height="26" />;
      },
      iconLarge: () => {
        return <TmsIcon width="62" height="52" />;
      },
      name: t("bfm.client.sideBarTasks"),
      path: `${GOS_BASE_URL}/task`,
      background: "#2B579A",
      disabled: false,
      id: "gos-homepage-tasks",
      openInNewTab: true,
    },
    {
      icon: () => {
        return <FleetIcon width="31" height="26" />;
      },
      iconLarge: () => {
        return <FleetIcon width="62" height="52" />;
      },
      name: t("bfm.fleets.label"),
      path: process.env.REACT_APP_BASE_URL_FLEETS || "/",
      openInNewTab: true,
      background: "#2B579A",
      disabled: false,
      id: "gos-homepage-fleets",
    },
    {
      icon: () => {
        return <RamtIcon width="31" height="26" />;
      },
      iconLarge: () => {
        return <RamtIcon width="62" height="52" />;
      },
      name: t("bfm.ramt.label"),
      path: "/",
      background: "#5D8DE4",
      disabled: true,
      id: "gos-homepage-ramt",
      openInNewTab: true,
    },
  ];
};

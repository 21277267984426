import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
  clientSearchByInternalNumber,
  clientSearchByText,
} from "store/api/api";
import { toast } from "../../components/FluentToast";

export const fetchClintByText = createAsyncThunk(
  "fleetFormikForm/fetchClintByText",
  async (text) => {
    const response = await clientSearchByText(text);
    return response;
  }
);

// export const fetchClintByInternalNumber = createAsyncThunk(
//   "fleetFormikForm/fetchClintByInternalNumber",
//   async (internalNumber) => {
//     const response = await clientSearchByInternalNumber(internalNumber);
//     return response;
//   }
// );

export const fetchClintByInternalNumber = createAsyncThunk(
  "fleetFormikForm/fetchClintByInternalNumber",

  async (internalNumber: number, { rejectWithValue }) => {
    try {
      const response = await clientSearchByInternalNumber(internalNumber);
      return response;
    } catch (err) {
      let error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      toast.error(error.response.data.exceptionMessage);
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  client: null,
  loading: false,
  clientByInternalNumber: null,
};

const fleetFormikFormSlice = createSlice({
  name: "fleetFormikForm",
  initialState,
  reducers: {
    clearClientFromInternalNumber: (state, action) => {
      state.clientByInternalNumber = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClintByText.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchClintByText.fulfilled, (state, action) => {
      state.client = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchClintByText.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fetchClintByInternalNumber.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchClintByInternalNumber.fulfilled, (state, action) => {
      state.clientByInternalNumber = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchClintByInternalNumber.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const selectClient = (state) => state.fleetFormikForm?.client;
export const selectClientByInternalNumber = (state) =>
  state.fleetFormikForm?.clientByInternalNumber;

export const { clearClientFromInternalNumber } = fleetFormikFormSlice.actions;

export default fleetFormikFormSlice;

import { IContextualMenuItem } from "@fluentui/react";
import { CenteredCell, ListContextMenuIconButton } from "components/styled";
import React from "react";
import { useSelector } from "store/hooks";
import { selectSelectedBookings } from "store/slices/bookingSlice";
import useChangeIssuingStatusMenuItem from "./hooks/useContextualMenuItems";

type Props = {
  booking: any;
  setChangeIssuingStatusOpen: any;
};

const BookingContextBtn = ({ booking, setChangeIssuingStatusOpen }: Props) => {
  const selectedBookings = useSelector(selectSelectedBookings);
  const items: IContextualMenuItem[] = useChangeIssuingStatusMenuItem({
    selectedBookings,
    setChangeIssuingStatusOpen,
  });

  return (
    <CenteredCell>
      <ListContextMenuIconButton
        iconProps={{ iconName: "MoreVertical" }}
        menuIconProps={{ style: { display: "none" } }}
        menuProps={{
          items: items,
        }}
      />
    </CenteredCell>
  );
};

export default BookingContextBtn;

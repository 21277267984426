import { Checkbox, Text, mergeStyleSets } from "@fluentui/react";
import { isArray } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorMsg from "./ErrorMsg";
import { FieldContainer } from "./FieldContainer";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

const CheckboxField = ({
  disabled,
  label,
  name,
  setFieldValue,
  checked,
  errors,
  isMarked = false,
  required = false,
  ...props
}) => {
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;

  function _onChange(
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) {
    setFieldValue(name, isChecked);
  }

  const classes = mergeStyleSets({
    wrap: {
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    labelAsterix: {
      color: theme.palette.redDark,
    },
    label: {
      fontSize: "14px",
      fontWeight: "400",
      padding: "5px",
      display: "block",
      color: (function () {
        if (disabled) {
          return theme.palette.neutralSecondaryAlt;
        }
        if (isDarkMode) {
          return "white";
        }
        return theme.palette.black;
      })(),
    },
  });

  const isChecked = isArray(checked)
    ? checked?.includes("on")
      ? true
      : false
    : checked;

  const onRenderLabel = () => (
    <Text className={classes.label}>
      {t(label)} <Text className={classes.labelAsterix}>{required && "*"}</Text>
    </Text>
  );

  return (
    <div className={classes.wrap}>
      <FieldContainer isTooltipHidden={disabled} tooltipText={label}>
        <Checkbox
          id={name + "-checkbox"}
          name={name}
          disabled={disabled}
          label={t(label)}
          onChange={_onChange}
          checked={isChecked}
          onRenderLabel={onRenderLabel}
          {...props}
        />
        {errors[name] && <ErrorMsg>{errors[name]}</ErrorMsg>}
      </FieldContainer>
    </div>
  );
};

export default React.memo(CheckboxField);

import { InsurerSettingsTemplate } from "AppSettings";
import { useFormikContext } from "formik";
import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "store/hooks";
import {
  ETimelineExtraDataLineSpecial,
  PremiumType,
  TimelineDialogType,
  TimelineSegment,
  TimelineSegmentType,
} from "types/types";
import {
  calculateAnnualNetPremium,
  stringToNumber,
} from "../../../utils/utils";

export const useAnnualNetPremium = (premiumTypeKey) => {
  const formik = useFormikContext();
  const appSettings = useSelector((s) => s.timeline.timelineAppSettings);

  const annualGrossPremium = Number(
    formik.values["annualGrossPremium"] &&
      formik.values["annualGrossPremium"] !== ""
      ? stringToNumber(formik.values["annualGrossPremium"])
      : 0
  );

  const insurerSettings = useSelector((s) => s.vehicle.insurerSettings);

  const premiumTypeCode = useSelector(
    (s) => s.timeline.dialog.item.domain.value
  );
  const dialogDate = useSelector((s) => s.timeline.dialog.date);
  const dialogType = useSelector((s) => s.timeline.dialog.type);
  const timelineSegments = useSelector((s) => s.timeline.timeline.data);
  const hullIsBasicItem = useSelector(
    (s) => s.timeline.dialog.item?.extraData?.lineSpecial
  );

  const taxonomy = useSelector((s) => s.taxonomy);
  const premiumTypeId = taxonomy.PremiumType.byCode[premiumTypeCode]?.id;

  const formatForDoubleFieldPercentHungarian = (defaultValueOperation) => {
    let annualNetPremium = "";
    if (defaultValueOperation.formValues) {
      const formValues = defaultValueOperation.formValues;

      let insurer = formValues.insurerPartnerNumber?.value;

      if (
        premiumTypeCode !== "PremiumType.MTPL" &&
        premiumTypeCode !== "PremiumType.HULL" &&
        premiumTypeCode !== "PremiumType.HULL_BASIC" &&
        premiumTypeCode !== PremiumType.FLEETLEGAL_PRORATA &&
        premiumTypeCode !== PremiumType.FLEETLEGAL_CLOSINGDATE &&
        premiumTypeCode !== "PremiumType.HORSE_POWER"
      ) {
        const segment: TimelineSegment = timelineSegments
          ?.filter(
            (seg) =>
              seg.domain.value === "PremiumType.MTPL" &&
              (seg.segmentType === TimelineSegmentType.PREMIUM ||
                seg.segmentType === TimelineSegmentType.SUSPENSION)
          )
          ?.find(
            (seg) =>
              moment(seg.startDate).toDate() <= dialogDate &&
              moment(seg.endDate).toDate() > dialogDate
          );
        if (segment) {
          insurer = segment.associatedObject.insurerPartnerNumber;
        }
      }

      let hullIsBasic = null;
      let premiumType = premiumTypeId;
      if (
        dialogType === TimelineDialogType.ADD_PREMIUM ||
        dialogType === TimelineDialogType.INSERT_PREMIUM
      ) {
        let hullIsBasicForm = formValues.hullIsBasic?.value;
        if (hullIsBasicForm) {
          hullIsBasic = hullIsBasicForm === "true" ? true : false;
        }
      }
      if (dialogType === TimelineDialogType.CORRECT_PREMIUM) {
        hullIsBasic =
          hullIsBasicItem &&
          hullIsBasicItem === ETimelineExtraDataLineSpecial.BASIC;
      }
      if (premiumTypeCode === "PremiumType.HULL" && hullIsBasic) {
        premiumType = taxonomy.PremiumType.byCode["PremiumType.HULL_BASIC"].id;
      }

      const insurerSettingsForInsurer = insurerSettings?.filter(
        (is) => is.insurerPartnerNumber === insurer
      );

      let insurerSetting = null;
      if (insurerSettingsForInsurer.length === 1) {
        insurerSetting = insurerSettingsForInsurer?.find((is) => {
          const a = moment(
            moment(is.validFromDate).format("YYYY-MM-DD")
          ).toDate();
          const b = moment(
            moment(formValues.validFromDate).format("YYYY-MM-DD")
          ).toDate();
          if (
            is.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails &&
            is.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails.length > 0
          ) {
            return true;
          }
          return a <= b;
        });
      } else if (insurerSettingsForInsurer.length > 1) {
        // Find latest insurer setting with valid from date before dialog date. Sording the array by validFromDate descending
        insurerSetting = insurerSettingsForInsurer
          ?.sort((a, b) => {
            return (
              moment(b.validFromDate).toDate().getTime() -
              moment(a.validFromDate).toDate().getTime()
            );
          })
          ?.find((is) => {
            const a = moment(
              moment(is.validFromDate).format("YYYY-MM-DD")
            ).toDate();
            const b = moment(
              moment(formValues.validFromDate).format("YYYY-MM-DD")
            ).toDate();
            if (
              is.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails &&
              is.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails.length >
                0
            ) {
              return true;
            }
            return a <= b;
          });
      }

      if (insurerSetting) {
        if (
          insurerSetting.insurerSettingPerPremiumTypeDetailsHU &&
          insurerSetting.insurerSettingPerPremiumTypeDetailsHU.length > 0
        ) {
          const setting3 =
            insurerSetting.insurerSettingPerPremiumTypeDetailsHU.find(
              (el) => el.premiumTypeCode === premiumType
            );

          const annualGrossPremium = parseFloat(
            (formValues.annualGrossPremium as string)
              ?.replaceAll(" ", "")
              ?.replaceAll(".", "")
              .replaceAll(",", ".")
          );
          const maxValueForTaxCalculation = setting3?.maxValueForTaxCalculation;
          const taxPercent = setting3?.taxPercent;
          const taxExtraPercent1 = setting3?.taxExtraPercent1;
          let calculatedPremium = calculateAnnualNetPremium(
            premiumTypeCode,
            taxPercent,
            taxExtraPercent1,
            annualGrossPremium,
            maxValueForTaxCalculation
          );

          //   annualGrossPremium > maxValueForTaxCalculation
          //     ? maxValueForTaxCalculation
          //     : annualGrossPremium;
          // calculatedPremium = calculatedPremium * (1 + taxPercent / 100);
          // calculatedPremium =
          //   calculatedPremium * (1 + taxExtraPercent1 / 100);
          // calculatedPremium =
          //   calculatedPremium * (1 + taxExtraPercent2 / 100);

          const premiumString = calculatedPremium.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          annualNetPremium = premiumString;

          //toast.success(t("bfm.fillInInsurerSettingsSuccess.label"));
        }
        // if results attribute value is "undefined" then remove it from the object
        if (annualNetPremium === "undefined") {
          return null;
        }
      }
    }
    return annualNetPremium;
  };

  useEffect(() => {
    if (
      !appSettings?.INSURER_SETTINGS_TEMPLATES.includes(
        InsurerSettingsTemplate.INSURANCE_LINE_DETAILS
      )
    ) {
      const netPremiumPerc = premiumTypeKey === PremiumType.ACCIDENT ? 4 : 11;
      const annualNetPremium =
        Math.round(
          ((annualGrossPremium / (100 + netPremiumPerc)) * 100 +
            Number.EPSILON) *
            100
        ) / 100;
      const annualNetPremimString = annualNetPremium.toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      if (formik.dirty && !formik.isSubmitting) {
        formik.setFieldValue("annualNetPremium", annualNetPremimString);
      }
    } else {
      const annualNetPremium = formatForDoubleFieldPercentHungarian({
        formValues: formik.values,
      });
      if (formik.dirty && !formik.isSubmitting) {
        formik.setFieldValue("annualNetPremium", annualNetPremium);
      }
    }
  }, [
    annualGrossPremium,
    formik.touched["annualGrossPremium"],
    formik.values["annualGrossPremium"],
    premiumTypeKey,
    appSettings,
  ]);
};

import { CommandBarButton } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";

const saveBtnStyle = {
  root: {
    height: "100%",
  },
};

const ImportScanButton = ({ disabled, action }: any) => {
  const { t } = useTranslation();
  const vehicleAppSettings = useSelector((s) => s.vehicle.vehicleAppSettings);

  return (
    <>
      {vehicleAppSettings?.SHOW_SCAN_DOCUMENTS ? (
        <CommandBarButton
          styles={saveBtnStyle}
          type="button"
          iconProps={{ iconName: "GenericScan" }}
          text={t("bfm.scanDocument.label")}
          disabled={disabled}
          onClick={() => {
            action();
          }}
        />
      ) : null}
    </>
  );
};

export default ImportScanButton;

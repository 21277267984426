import {
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  PrimaryButton,
} from "@fluentui/react";
import { useDeleteInsurerSetting } from "hooks/data/mutations/useDeleteInsurerSetting";
import { useDeleteInsurerSettings } from "hooks/data/mutations/useDeleteInsurerSettings";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setDeleteDialog } from "store/insurersPage";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { toast } from "../../components/FluentToast";
import { FormSpinner } from "../../components/FormSpinner";
import { useSelector } from "../../store/hooks";

export const DeleteDialog = () => {
  const theme = useTheme() as IStyledTheme;
  const deleteDialog = useSelector((s) => s.insurersPage.deleteDialog);
  const { t } = useTranslation();
  const phraseActivated = usePhraseActivationStatus().activated;
  const { mutate: onDeleteInsurerSettings, isLoading } =
    useDeleteInsurerSettings();

  const { mutate: onDeleteInsurerSetting, isLoading: isLoadingOne } =
    useDeleteInsurerSetting();

  const dispatch = useDispatch();

  const close = () => {
    dispatch(
      setDeleteDialog({
        isOpen: false,
        insurerSettings: null,
        mode: "single",
        callback: () => {},
      })
    );
  };

  return (
    <Dialog
      hidden={!deleteDialog.isOpen}
      onDismiss={close}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
      dialogContentProps={{
        type: DialogType.normal,
        title: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              iconName="Warning"
              style={{
                color: theme.semanticColors.errorIcon,
                fontSize: "25px",
                marginRight: "10px",
              }}
            />
            {t("greco.delete")}
          </div>
        ),
      }}
    >
      {t("greco.dialog.deleteInsurerSettings")}
      <DialogFooter>
        <PrimaryButton
          text={t("greco.delete")}
          iconProps={{
            iconName: "Delete",
          }}
          disabled={isLoading || isLoadingOne}
          onClick={() => {
            if (deleteDialog.mode === "single") {
              onDeleteInsurerSetting(
                {
                  insurerPartnerNumber:
                    deleteDialog.insurerSettings.insurerPartnerNumber.toString(),
                  insurerSettingId: deleteDialog.insurerSettings.id.toString(),
                },
                {
                  onSuccess: () => {
                    toast.success(t("bfm.success"));
                    close();
                    deleteDialog.callback && deleteDialog.callback();
                  },
                }
              );
            } else {
              onDeleteInsurerSettings(
                {
                  insurerPartnerNumber:
                    deleteDialog.insurerSettings.insurerPartnerNumber.toString(),
                },
                {
                  onSuccess: () => {
                    toast.success(t("bfm.success"));
                    close();
                    deleteDialog.callback && deleteDialog.callback();
                  },
                }
              );
            }
          }}
        />
      </DialogFooter>
      {(isLoading || isLoadingOne) && <FormSpinner />}
    </Dialog>
  );
};

import { useMutation } from "react-query";
import {
  ClosedVehiclePremiumRequestParams,
  deleteVehiclePremium,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const deleteVehiclePremiumFn = async ({
  rowVersion,
  params,
}: {
  rowVersion: string;
  params: ClosedVehiclePremiumRequestParams;
}) => {
  return await deleteVehiclePremium(rowVersion, params);
};

export function useDeleteVehiclePremium() {
  return useMutation(deleteVehiclePremiumFn, {
    mutationKey: MUTATION_KEYS.deleteVehiclePremium,
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import * as yup from "yup";

export const validationObjectConfig = (isTradeLicensePlate: boolean): any => {
  let yupObject: any = {
    licensePlate: yup.string().trim().max(12).required().nullable(),
    vin: yup.string().trim().max(32).required().nullable(),
    mtplPolicyNumber: yup.string().trim().max(34).nullable(),
    hullPolicyNumber: yup.string().trim().max(34).nullable(),
    fleetLegalPolicyNumber: yup.string().trim().max(34).nullable(),
    certificateNumber: yup.string().trim().nullable(),
    nationalCodeId: yup.string().trim().max(254).nullable(),
  };
  if (isTradeLicensePlate) {
    yupObject = {
      licensePlate: yup.string().trim().max(12).required().nullable(),
      mtplPolicyNumber: yup.string().trim().max(34).nullable(),
      hullPolicyNumber: yup.string().trim().max(34).nullable(),
      fleetLegalPolicyNumber: yup.string().trim().max(34).nullable(),
      certificateNumber: yup.string().trim().nullable(),
    };
  }
  return yupObject;
};

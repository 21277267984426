import { ContextualMenu, mergeStyleSets } from "@fluentui/react";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  closeAppLuncher: () => void;
  index: any;
  link: any;
  id?: string;
}

function NavigationComponent({ index, link, closeAppLuncher, id }: Props) {
  const navigate = useNavigate();
  let location = useLocation();
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(true);
  const iconRef = useRef(null);

  const classes = getClasses(link);

  const handleLinkClick = () => {
    if (!link.disabled) {
      if (link.openInNewTab) {
        window.open(link.path, "_blank");
      } else {
        navigate(link.path, {
          state: {
            prevRoute: location.pathname,
          },
        });
      }
      closeAppLuncher();
    }
  };
  return (
    <div
      id={id}
      key={index}
      className={classes.container}
      onClick={handleLinkClick}
      ref={iconRef}
      onContextMenu={
        link.openInNewTab
          ? undefined
          : (e) => {
              setShow(false);
              e.preventDefault();
            }
      }
    >
      <div className={classes.linkOpacity}>{link.icon()}</div>
      <div className={classes.linkName}>{link.name}</div>
      <ContextualMenu
        items={[
          {
            key: "newItem",
            text: t("clm.openNewTab.contextMenu.label"),
            onClick: () => {
              window.open(link.path, "_blank");
            },
          },
        ]}
        hidden={show}
        target={iconRef}
        onDismiss={() => setShow(true)}
        onItemClick={() => setShow(true)}
      />
    </div>
  );
}

export default NavigationComponent;

const getClasses = (link) =>
  mergeStyleSets({
    container: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      width: "40%",
      height: "50px",
      pointerEvents: link.disabled ? "none" : "auto",
    },
    linkOpacity: {
      opacity: link.disabled ? "0.3" : "1",
    },
    linkName: {
      paddingLeft: "10px",
      opacity: link.disabled ? "0.3" : "1",
    },
  });

import { useMutation, useQueryClient } from "react-query";
import {
  CreateFleetDefaultSettingParams,
  createFleetDefaultSetting,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS, QUERY_KEYS } from "../queryKeys";

const createFleetVehicleDefaultSetting = async (
  params: CreateFleetDefaultSettingParams
) => {
  return await createFleetDefaultSetting(params);
};

export function useCreateFleetVehicleDefaultSetting() {
  const queryClient = useQueryClient();

  return useMutation(createFleetVehicleDefaultSetting, {
    mutationKey: MUTATION_KEYS.createFleetVehicleDefaultSetting,
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.fleetVehicleDefaultSettings);
    },
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

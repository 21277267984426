import { mergeStyleSets } from "@uifabric/merge-styles";
import React, { useMemo } from "react";
import { useSelector } from "store/hooks";

const classes = mergeStyleSets({
  item: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    height: "100%",
  },
});

const LineInsurer = ({ partnerNumber }) => {
  const insurerList1 = useSelector((s) => s.fleetForm.insurerList);
  const insurerList2 = useSelector((s) => s.requireActionVehicles.insurerList);

  const insurer1 = insurerList1.find(
    (insurer) => insurer.insurerInternalNumber + "" === partnerNumber
  );
  const insurerName1 = insurer1 ? insurer1?.insurerName : "";

  const insurerName2 = useMemo(() => {
    const insurer2 = insurerList2.find(
      (insurer) => insurer.insurerInternalNumber + "" === partnerNumber
    );
    return insurer2 ? insurer2?.insurerName : "";
  }, [insurerList2, partnerNumber]);

  return (
    <p className={classes.item}>
      {insurerName1}
      {insurerName2}
    </p>
  );
};

export default React.memo(LineInsurer);

import { createAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import i18next from "i18next";
import { toast } from "../components/FluentToast";
import { BackendError } from "../types/types";

export const sort = (sort: any) => (a: any, b: any) => {
  let aValue = a[sort.key];
  let bValue = b[sort.key];

  if (aValue === null) aValue = "";
  if (bValue === null) bValue = "";

  if (aValue > bValue) {
    if (sort.dir === "desc") return -1;
    else return 1;
  }
  //
  else if (aValue < bValue) {
    if (sort.dir === "desc") return 1;
    else return -1;
  }
  //
  else {
    return 0;
  }
};

export const createAsyncRoutine = <T = void, L = void, S = void, E = void>(
  prefix: string
) => {
  return {
    trigger: createAction<T>(`${prefix}/trigger`),
    loading: createAction<L>(`${prefix}/loading`),
    success: createAction<S>(`${prefix}/success`),
    error: createAction<E>(`${prefix}/error`),
  };
};

export const extractServerErrors = (err: AxiosError<any>) => {
  let serverErrors;
  if (Array.isArray(err?.response?.data)) {
    if ("field" in err.response.data[0]) {
      serverErrors = err.response.data.reduce((obj, e) => {
        const key = `${e.field[0].toLowerCase()}${e.field.slice(1)}`;
        obj[key] = e.error;
        return obj;
      }, {});
    }
    if ("Field" in err.response.data[0]) {
      serverErrors = err.response.data.reduce((obj, e) => {
        const key = `${e.Field[0].toLowerCase()}${e.Field.slice(1)}`;
        obj[key] = e.Error;
        return obj;
      }, {});
    }
  }
  return serverErrors;
};

export const handleAxiosError = (err?: any) => {
  if (err?.isAxiosError) {
    const response = err.response;
    if (response) {
      const responseData = err.response.data;
      if (responseData) {
        const env = process.env.REACT_APP_ENVIRONMENT;
        const developmentEnvironments = ["L", "D", "T"];
        const isDevOrTestEnv = developmentEnvironments.includes(env);
        if (Array.isArray(responseData)) {
          responseData.forEach((e: BackendError) => {
            const translatedMessageVariables = e.messageVariables.map(
              (item: string) => {
                return i18next.t(item);
              }
            );
            let message = e.messageCode;
            if (isDevOrTestEnv) {
              message = e?.exceptionMessage || e?.formatedMessage;
              toast.error(i18next.t(message));
            } else {
              toast.error(i18next.t(message, translatedMessageVariables));
            }
          });
        }
        //
        else {
          let message = responseData.messageCode;
          if (message) {
            if (isDevOrTestEnv) {
              message =
                responseData?.exceptionMessage || responseData?.formatedMessage;
            }

            const translatedMessageVariables =
              responseData.messageVariables.map((item: string) => {
                return i18next.t(item);
              });
            toast.error(i18next.t(message, translatedMessageVariables));
          } else {
            if (responseData.message) {
              toast.error(responseData.message);
            } else {
              toast.error(responseData);
            }
          }
        }
      } else {
        toast.error(err?.toString());
      }
    } else {
      toast.error(err?.toString());
    }
  } else {
    toast.error(err?.toString());
  }
};
// Utility function to parse strings to numbers
const parseToNumber = (value) => {
  if (typeof value === "string") {
    return Number.parseFloat(
      value.replaceAll(" ", "").replaceAll(".", "").replaceAll(",", ".")
    );
  }
  return value;
};

export const createFieldPairValidation = (fieldFrom, fieldTo) => {
  return function (values) {
    // Parse values to numbers if they are strings
    const fromValue = parseToNumber(values[fieldFrom]);
    const toValue = parseToNumber(values[fieldTo]);

    // Perform validation
    if (fromValue || toValue) {
      if (
        typeof fromValue === "undefined" ||
        fromValue === null ||
        fromValue === "" ||
        typeof toValue === "undefined" ||
        toValue === null ||
        toValue === ""
      ) {
        return this.createError({
          path: fieldFrom,
          message: i18next.t("bfm.validation.fieldsBothRequired", {
            fieldFrom: fieldFrom,
            fieldTo: fieldTo,
          }),
        });
      }
      if (toValue <= fromValue) {
        return this.createError({
          path: fieldTo,
          message: i18next.t("bfm.validation.fieldToGreaterThanFrom", {
            fieldFrom: fieldFrom,
            fieldTo: fieldTo,
          }),
        });
      }
    }
    return true;
  };
};

import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS, QUERY_KEYS } from "../queryKeys";

const url = process.env.REACT_APP_BASE_URL;

type TCreateSetting = {
  fleetId: string | number;
  data: {
    insurerPartnerNumber?: number | string;
    suspensionDays?: number | string;
    activationDays?: number | string;
    garageRiskDiscountPercentHull?: number | string; //float
    garageRiskDiscountPercentHullBasic?: number | string; //float
    insurerBranchOffice?: number | string;
    paymentMethod?: number | string;
    paymentMean?: number | string;
  };
};

const createFleetInsurerDefaultSetting = async ({
  fleetId,
  data,
}: TCreateSetting) => {
  const response = await axios.post(
    `${url}/CreateFleetInsurerSetting/${fleetId}`,
    data
  );

  return response;
};

export function useCreateFleetInsurerDefaultSetting() {
  const queryClient = useQueryClient();

  return useMutation(createFleetInsurerDefaultSetting, {
    mutationKey: MUTATION_KEYS.createFleetInsurerDefaultSetting,
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.fleetInsurerDefaultSettings);
    },
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

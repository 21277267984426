import { Icon } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useCurrentRoute } from "../../../config/routes";
import { useSelector } from "../../../store/hooks";
import BookingDetails from "./BookingDetails/BookingDetails";

export const General = () => {
  const { t } = useTranslation();
  const vehicle = useSelector((s) => s.vehicle.vehicle);
  const route = useCurrentRoute();
  if (route === "createVehicle" || route === "duplicateVehicle") return null;

  if (!vehicle) return null;
  if (!vehicle.vehiclePremiumBookingData) return null;

  let bookingData = vehicle.vehiclePremiumBookingData;
  if (!!!bookingData) return null;
  return (
    <>
      <BookingDetails premiumType="GENERAL" />
    </>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 150px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

const ColumnRight = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;
const PremiumValue = styled.div`
  font-size: 12px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  font-weight: bold;
`;

const HistoryIcon = styled(Icon)`
  color: ${(p) => p.theme.palette.neutralPrimary};
`;

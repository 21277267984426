import { mergeStyleSets } from "@fluentui/react";
import FluentTooltip from "components/FluentTooltip";
import React from "react";

const classes = mergeStyleSets({
  field: {
    textAlign: "left",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "flex",
    alignIitems: "center",
  },
});

const BookingField = ({ value }) => {
  return (
    <div className={classes.field}>
      <FluentTooltip content={value}>{value}</FluentTooltip>
    </div>
  );
};

export default BookingField;

import { TooltipHost } from "@fluentui/react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "utils/number";

const BookingAmountField = ({ row }) => {
  const { t } = useTranslation();
  return (
    <TooltipHost
      content={t("bfm.bookingAmount.tooltip", {
        date: moment(row?.premiumBookingDate).format("DD.MM.YYYY"),
        dateSum: formatNumber(row?.dateSum),
      })}
    >
      <span style={{ padding: "10px" }}>
        &nbsp;&nbsp;&nbsp;{formatNumber(row?.bookedAmount)}&nbsp;&nbsp;&nbsp;
      </span>
    </TooltipHost>
  );
};

export default BookingAmountField;

import {
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  PrimaryButton,
} from "@fluentui/react";
import { toast } from "components/FluentToast";
import TextInputField from "components/controls/TextInputField";
import { Formik } from "formik";
import { useDeleteMultipleVehicles } from "hooks/data/mutations/useDeleteMultipleVehicles";
import { useDeleteVehicle } from "hooks/data/mutations/useDeleteVehicle";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import { delay } from "library/delay";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { setYupLocale } from "utils/setYupLocale";
import * as yup from "yup";
import { FormSpinner } from "../../components/FormSpinner";
import { loadVehiclesActions, setDeleteDialog } from "../../store/fleetPage";
import { useSelector } from "../../store/hooks";

export const DeleteDialog = () => {
  const dispatch = useDispatch();

  const theme = useTheme() as IStyledTheme;
  const deleteDialog = useSelector((s) => s.fleetPage.deleteDialog);

  const { mutate: onDeleteVehicle, isLoading: deleteVehicleInProgress } =
    useDeleteVehicle();

  const {
    mutate: onDeleteMultipleVehicles,
    isLoading: multipleDeleteVehiclesInProgress,
  } = useDeleteMultipleVehicles();

  const phraseActivated = usePhraseActivationStatus().activated;
  const { t } = useTranslation();
  const isLoading = deleteVehicleInProgress || multipleDeleteVehiclesInProgress;

  const close = () => {
    dispatch(
      setDeleteDialog({
        isOpen: false,
        fleetId: null,
        vehicle: null,
        vehicleIds: [],
      })
    );
  };

  const onSubmit = (values) => {
    const comment = values.comment;
    if (deleteDialog.vehicle) {
      onDeleteVehicle(
        {
          fleetId: deleteDialog.vehicle.fleetId,
          vehicleId: deleteDialog.vehicle.vehicleId,
          body: comment,
        },
        {
          onSuccess: () => {
            close();
            toast.success(t("bfm.success"));
            dispatch(loadVehiclesActions.trigger({ isReset: true }));
          },
        }
      );
    } else if (deleteDialog.vehicleIds.length > 0) {
      onDeleteMultipleVehicles(
        {
          fleetId: deleteDialog.fleetId,
          vehicleIds: deleteDialog.vehicleIds,
          body: { comment, vehicleIds: deleteDialog.vehicleIds },
        },
        {
          onSuccess: () => {
            close();
            toast.success(t("bfm.success"));
            dispatch(loadVehiclesActions.trigger({ isReset: true }));
          },
        }
      );
    }
  };
  return (
    <Dialog
      hidden={!deleteDialog.isOpen}
      onDismiss={close}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
      dialogContentProps={{
        type: DialogType.normal,
        title: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              iconName="Warning"
              style={{
                color: theme.semanticColors.errorIcon,
                fontSize: "25px",
                marginRight: "5px",
              }}
            />
            {deleteDialog.vehicle
              ? t("greco.delete")
              : t("bfm.deleteVehicles.label", {
                  num: deleteDialog.vehicleIds?.length,
                })}
          </div>
        ),
      }}
    >
      <Formik
        initialValues={{ comment: "" }}
        enableReinitialize
        validationSchema={() => {
          setYupLocale(t);
          return yup.object().shape({
            comment: yup.string().max(512).required(),
          });
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setSubmitting }) => {
          onSubmit(values);
          await delay(400);
          setSubmitting(false);
        }}
      >
        {(props) => {
          const { values, handleChange, errors, handleSubmit } = props;

          return (
            <form onSubmit={handleSubmit}>
              <TextInputField
                value={values.comment}
                onChange={handleChange}
                errors={errors}
                required
                name={"comment"}
                multiline
                rows={4}
                disabled={false}
                label={t("bfm.comment.label")}
              />
              <DialogFooter>
                <PrimaryButton
                  text={t("greco.delete")}
                  type="submit"
                  iconProps={{
                    iconName: "Delete",
                  }}
                  disabled={isLoading}
                />
              </DialogFooter>
              {isLoading && <FormSpinner />}
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

import { useMutation } from "react-query";
import {
  RecalculateFleetProRataAndBookingsRequestParams,
  recalculateFleetProRataAndBookings,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

export const recalculateFleetProRataAndBookingsFn = async (
  params: RecalculateFleetProRataAndBookingsRequestParams
) => {
  return await recalculateFleetProRataAndBookings(params);
};

export function useRecalculateFleetProRataAndBookings() {
  return useMutation(recalculateFleetProRataAndBookingsFn, {
    mutationKey: MUTATION_KEYS.recalculateFleetProRataAndBookings,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

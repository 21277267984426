import { useMutation } from "react-query";
import { IndexRequestParams, indexFleet } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const indexFleetFn = async (params: IndexRequestParams) => {
  return await indexFleet(params);
};

export function useIndexFleet() {
  return useMutation(indexFleetFn, {
    mutationKey: MUTATION_KEYS.indexFleet,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

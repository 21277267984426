import { useMutation } from "react-query";
import { VehiclePremiumRequestParams, addVehiclePremium } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const addVehiclePremiumFn = async ({
  rowVersion,
  params,
}: {
  rowVersion: string;
  params: VehiclePremiumRequestParams;
}) => {
  return await addVehiclePremium(rowVersion, params);
};

export function useAddVehiclePremium() {
  return useMutation(addVehiclePremiumFn, {
    mutationKey: MUTATION_KEYS.suspendSharedLicensePlate,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

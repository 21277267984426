import { UseQueryResult, useQuery } from "react-query";
import { getInsurersByCountryId } from "store/api/api";
import { Insurer } from "types/types";
import { QUERY_KEYS } from "../queryKeys";

const getInsurerList = async (countryCode?: number) => {
  if (typeof countryCode === "undefined") return [];
  const res = await getInsurersByCountryId(countryCode);
  return res?.data;
};

export function useGetInsurerListByCountry(
  countryCode: number
): UseQueryResult<Insurer[]> {
  return useQuery(
    [QUERY_KEYS.insurerListByCountry],
    () => getInsurerList(countryCode),
    {
      staleTime: 3_600_000, //1 hour
      enabled: countryCode !== null,
    }
  );
}

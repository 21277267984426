import { el } from "date-fns/locale";
import moment from "moment";
import { TaxonomyState } from "../../../store/taxonomy";
import {
  Timeline,
  TimelineSegment,
  TimelineSegmentType,
  TimelineType,
  Vehicle,
} from "../../../types/types";

const StatusSegmentType = {
  "VehicleStatusCode.DEACTIVATED": TimelineSegmentType.STATUS_DEACTIVATED,
  "VehicleStatusCode.ACTIVE": TimelineSegmentType.STATUS_ACTIVE,
  "VehicleStatusCode.SUSPENDED": TimelineSegmentType.STATUS_SUSPENDED,
  "VehicleStatusCode.PENDING_FOR_ACTIVATION":
    TimelineSegmentType.STATUS_PENDING_FOR_ACTIVATION,
  "VehicleStatusCode.PENDING_FOR_SUSPENSION":
    TimelineSegmentType.STATUS_PENDING_FOR_SUSPENSION,
  "VehicleStatusCode.PENDING_FOR_DEACTIVATION":
    TimelineSegmentType.STATUS_PENDING_FOR_DEACTIVATION,
};

export function prepareStatusSegments(
  timeline: Timeline,
  vehicle: Vehicle,
  taxonomy: TaxonomyState
): TimelineSegment[] {
  let statuses: TimelineSegment[] = vehicle.vehicleStatusHistoryData.map(
    (item) => {
      return {
        domain: {
          type: TimelineType.VEHICLE_STATUS,
          value: taxonomy.VehicleStatusCode.byId[item.statusCode].code,
        },
        segmentType:
          StatusSegmentType[
            taxonomy.VehicleStatusCode.byId[item.statusCode].code
          ],

        startDate: moment(item.statusDate).toDate(),
        endDate: null,
        extraData: { isLastSegmentInLine: false },
        associatedObject: item,
      };
    }
  );

  statuses = statuses.sort(
    (a, b) => a.startDate.getTime() - b.startDate.getTime()
  );

  for (let i = 0; i < statuses.length; i++) {
    if (i < statuses.length - 1) {
      statuses[i].endDate = statuses[i + 1].startDate;
    } else {
      // last segment in line
      statuses[i].endDate = moment(timeline.endDate)
        .subtract(1, "month")
        .toDate();
      statuses[i].extraData.isLastSegmentInLine = true;
    }
  }
  const pendingStatuses = [
    "VehicleStatusCode.PENDING_FOR_ACTIVATION",
    "VehicleStatusCode.PENDING_FOR_SUSPENSION",
    "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
  ];
  const vehicleStatus =
    taxonomy.VehicleStatusCode.byId[vehicle.vehicleStatusCode].code;
  if (pendingStatuses.includes(vehicleStatus)) {
    const pendingStatusDate = moment(vehicle.vehicleLastStatusDate).toDate();
    const vehicleLastStatusComment = vehicle.vehicleLastStatusComment;
    statuses.push({
      domain: {
        type: TimelineType.VEHICLE_STATUS,
        value: vehicleStatus,
      },
      segmentType: StatusSegmentType[vehicleStatus],

      startDate: pendingStatusDate,
      endDate: pendingStatusDate,
      extraData: { vehicleLastStatusComment: vehicleLastStatusComment },
      associatedObject: null,
    });
  }
  return statuses;
}

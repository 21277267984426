import React, { useMemo } from "react";

import { Dropdown } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

type TlpOption = {
  text: string;
  key: string;
};

type Props = {
  value: TlpOption | null;
  onChange?: (value: TlpOption | null) => void;
  options: any[];
  isClearable?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isOptionDisabled?: (o: TlpOption) => boolean;
  portalTarget?: any;
};

export const TlpSelect = ({
  value,
  onChange,
  options,
  isClearable,
  isLoading,
  isDisabled,
  isOptionDisabled,
  portalTarget,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;
  const optionsList = useMemo(() => {
    const optionsList = options
      ? options.map((tax) => {
          return {
            key: tax.key,
            text: t(tax.text),
          };
        })
      : [];

    return optionsList;
  }, []);

  return (
    <>
      <Dropdown
        style={{
          minWidth: 170,
        }}
        styles={{
          root: {
            "& .ms-Dropdown-title": {
              borderColor: theme.palette.neutralTertiaryAlt,
            },
          },
        }}
        defaultSelectedKey={"null"}
        onChange={(_, option) => {
          onChange && onChange(option as any);
        }}
        placeholder={""}
        label=""
        options={optionsList}
        disabled={isDisabled}
      />
    </>
  );
};

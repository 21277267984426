import { Persona, PersonaSize } from "@fluentui/react";
import React from "react";
import { formatDateString, formatDateWithMoment } from "utils/utils";
import BookingAmountField from "./BookingAmountField";
import BookingComment from "./BookingComment";
import BookingField from "./BookingField";
import ReasonField from "./ReasonField";
import StatusField from "./StatusField";

export const bookingColumns: any[] = [
  {
    key: "vehiclePremiumBookingLastModifiedByUserName",
    isResizable: true,
    fieldName: "vehiclePremiumBookingLastModifiedByUserName",
    labelKey: "bfm.user",
    name: "bfm.user",
    minWidth: 40,
    maxWidth: 40,
    isVisible: true,
    onRender: (row) => {
      if (!row) return null;
      const tooltipTitle = `Last modified on: ${formatDateWithMoment(
        row?.vehiclePremiumBookingLastModifiedAt
      )} by ${row?.vehiclePremiumBookingLastModifiedByUserName} - ${
        row?.vehiclePremiumBookingLastModifiedByUserEmail
      }`;
      return (
        <Persona
          text={row?.vehiclePremiumBookingLastModifiedByUserName}
          title={tooltipTitle}
          hidePersonaDetails
          size={PersonaSize.size24}
        />
      );
    },
  },
  {
    key: "options",
    isResizable: true,
    fieldName: "options",
    labelKey: "greco.options",
    name: "",
    minWidth: 15,
    maxWidth: 15,
    isVisible: true,
  },
  {
    key: "bookingDate",
    isResizable: true,
    fieldName: "bookingData",
    labelKey: "bfm.vehicleForm.tabPremiumBooking.bookingDate.label",
    name: "bfm.vehicleForm.tabPremiumBooking.bookingDate.label",
    minWidth: 50,
    maxWidth: 100,
    isVisible: true,
    onRender: (row) => {
      if (!row) return null;
      return <BookingField value={formatDateString(row?.premiumBookingDate)} />;
    },
  },
  {
    key: "premiumBookingFromDate",
    isResizable: true,
    fieldName: "premiumBookingFromDate",
    labelKey: "bfm.vehicleForm.tabPremiumBooking.bookingPeriod.label",
    name: "bfm.vehicleForm.tabPremiumBooking.bookingPeriod.label",
    minWidth: 50,
    maxWidth: 130,
    isVisible: true,
    onRender: (row) => {
      if (!row) return null;
      const bookingPeriod =
        formatDateString(row.premiumBookingFromDate as any) +
        " - " +
        formatDateString(row.premiumBookingToDate as any);
      return <BookingField value={bookingPeriod} />;
    },
  },
  {
    key: "status",
    isResizable: true,
    fieldName: "status",
    labelKey: "bfm.vehicleForm.tabPremiumBooking.form.status.label",
    name: "bfm.vehicleForm.tabPremiumBooking.form.status.label",
    minWidth: 50,
    maxWidth: 200,
    isVisible: true,
    onRender: (row) => {
      if (!row) return null;

      return <StatusField row={row} />;
    },
  },
  {
    key: "booked",
    isResizable: true,
    fieldName: "booked",
    labelKey: "bfm.vehicleForm.tabPremiumBooking.booked.label",
    name: "bfm.vehicleForm.tabPremiumBooking.booked.label",
    minWidth: 50,
    maxWidth: 60,
    isVisible: true,

    onRender: (row) => {
      if (!row) return null;

      return <BookingAmountField row={row} />;
    },
  },
  {
    key: "reason",
    isResizable: true,
    fieldName: "reason",
    labelKey: "Reason",
    name: "Reason",
    minWidth: 50,
    maxWidth: 280,
    isVisible: true,
    onRender: (row) => {
      if (!row) return null;
      return (
        <ReasonField premiumBookingReasonCode={row?.premiumBookingReasonCode} />
      );
    },
  },
  {
    key: "comment",
    isResizable: true,
    fieldName: "comment",
    labelKey: "bfm.vehicleForm.tabPremiumBooking.form.comment.label",
    name: "bfm.vehicleForm.tabPremiumBooking.form.comment.label",
    minWidth: 40,
    maxWidth: 40,
    isVisible: true,
    onRender: (row) => {
      if (!row) return null;
      return (
        <BookingComment
          vehicleIssuingStatusComment={row?.vehicleIssuingStatusComment}
        />
      );
    },
  },
];

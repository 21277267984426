import React from "react";
import BookingDetails from "./BookingDetails/BookingDetails";

export const FleetLegalProrata = () => {
  return (
    <>
      <BookingDetails premiumType="FLEETLEGAL_PRORATA" />
    </>
  );
};

import { ActionButton, IDropdownOption } from "@fluentui/react";
import { GrecoHeader } from "@greco/components";
import { ISettingsToggle } from "@greco/components/dist/components/GrecoHeader/GrecoPanel/Settings/Settings";
import { ErrorHandlerMessage, errorHandlerClasses } from "library/errorHandler";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { setPhraseToolActivationStatus } from "store/slices/phraseToolSlice";

import { Setting, SupportedLanguage } from "types/types";
import { AppContext } from "../../AppContext";
import { inContextEditorPostProcessor } from "../../i18n";
import { MSGraphAuth } from "../../store/api/GraphService";

import { useAddAppSettings } from "hooks/data/mutations/useAddAppSettings";
import { useUpdateAppSettings } from "hooks/data/mutations/useUpdateAppSettings";
import { useGetAppSettings } from "hooks/data/queries/useGetAppSettings";
import { useLoadUADTaxonomy } from "hooks/data/queries/useLoadUADTaxonomy";
import { history } from "utils/_helpers";
import i18n from "../../i18n";
import { GosNavigation } from "./GosNavigation/GosNavigation";

interface IAppHeaderProps {
  headerConfig?: any;
  user?: any;
  panelStatus?: Function;
  tooltipStatusChange?: any;
  logo?: any;
  logout?: any;
  searchOnChange?: any;
  searchOnSearch?: any;
  search?: string;
  darkMode?: any;
  tooltipsStatus?: any;
}

const languageList: { [key: string]: string } = {
  "Language.EN_GB": "en-GB",
  "Language.DE_AT": "de-AT",
  "Language.HU_HU": "hu-HU",
};

function AppHeader(props: IAppHeaderProps) {
  const { t } = useTranslation();
  const { search, pathname } = window.location;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shouldShowPhrase, setShouldShowPhrase] = useState(false);

  history.navigate = useNavigate();
  history.location = useLocation();

  const appContext = useContext(AppContext);

  const { data: taxonomyUad } = useLoadUADTaxonomy();

  const applicationCode = taxonomyUad?.ApplicationCode.find(
    (item) => item.code === "Application.BFM"
  );
  const applicationCodeId = applicationCode?.id;

  const { data: appSettings } = useGetAppSettings(applicationCodeId);

  const { mutate: updateAppSetting } = useUpdateAppSettings();
  const { mutate: addAppSetting } = useAddAppSettings();

  const { notifications } = appContext;

  const appSettingCodeId = taxonomyUad?.ApplicationSetting?.find(
    (el) => el.code === "AppSetting.UserSettings"
  ).id;

  const phraseToggle: ISettingsToggle = {
    title: "Phrase",
    toggleProps: {
      defaultChecked:
        !!new URLSearchParams(search).get("phrase_context") ||
        inContextEditorPostProcessor.phraseEnabled,
      onChange: (_event, checked) => {
        if (checked) {
          dispatch(setPhraseToolActivationStatus(true));
          inContextEditorPostProcessor.phraseEnabled = true;
        } else {
          dispatch(setPhraseToolActivationStatus(false));
          inContextEditorPostProcessor.phraseEnabled = false;
          const queryParams = new URLSearchParams(search);
          if (queryParams.get("phrase_context")) {
            queryParams.delete("phrase_context");
            navigate({
              pathname: pathname,
              search: queryParams.toString(),
            });
          }

          // Reload is needed because we have to remove
          // Phrase scripts from the client
          window.location.reload();
        }
      },
    },
  };

  const enableInContextEditor = async () => {
    const account = await MSGraphAuth.accountObj;
    if (!(account && account.idTokenClaims)) return false;

    const roles: string[] = (account.idTokenClaims as { roles: string[] })
      .roles;
    return !!(roles && roles.includes("PHRASE"));
  };

  const gosNav = (closeAppLuncher: () => void) => {
    return (
      <GosNavigation
        closeAppLuncher={closeAppLuncher}
        isRolesToggleButtonsFeatureFlagOn={false}
      />
    );
  };

  useEffect(() => {
    (async () => {
      setShouldShowPhrase(await enableInContextEditor());
    })();
  }, []);

  return (
    <GrecoHeader
      gosNavigation={gosNav}
      headerConfig={props.headerConfig}
      user={props.user}
      panelStatus={props.panelStatus}
      tooltipStatusChange={props.tooltipStatusChange}
      logo={props.logo}
      logout={props.logout}
      searchOnChange={props.searchOnChange}
      searchOnSearch={props.searchOnSearch}
      search={props.search}
      darkMode={props.darkMode}
      tooltipsStatus={props.tooltipsStatus}
      defaultLanguage={i18n.resolvedLanguage}
      // languageOptions={
      //   taxonomyUad
      //     ? (
      //         taxonomySupportedLanguages as SupportedLanguage[]
      //       ).map<IDropdownOption>((language: SupportedLanguage) => ({
      //         key: language.code,
      //         text: t(language.code),
      //       }))
      //     : []
      // }
      languageOptions={
        taxonomyUad
          ? (taxonomyUad.SupportedLanguage as SupportedLanguage[])
              .filter(
                (language: SupportedLanguage) =>
                  language.isGrECoLanguage &&
                  (language.code === "Language.EN_GB" ||
                    language.code === "Language.DE_AT" ||
                    language.code === "Language.HU_HU")
              )
              .map<IDropdownOption>((language: SupportedLanguage) => ({
                key: languageList[language.code],
                text: t(language.code),
              }))
          : []
      }
      onChangeLanguage={async (__event: any, languageOption?: any) => {
        if (!languageOption) return;

        const createData: any = {
          applicationCodeId: applicationCodeId,
          appSettingCodeId: appSettingCodeId,
          userAppSettingName: "Language",
          userAppSettingValue: languageOption.key,
          isDefault: true,
        };
        try {
          const userSettingsForViewOptions: any = appSettings
            .filter((item: any) => item.appSettingCodeId === appSettingCodeId)
            ?.find((userSetting: any) => userSetting.isDefault);

          if (userSettingsForViewOptions) {
            const editData = {
              applicationCodeId: userSettingsForViewOptions.applicationCodeId,
              appSettingCodeId: userSettingsForViewOptions.appSettingCodeId,
              userAppSettingId: userSettingsForViewOptions.userAppSettingId,
              userAppSettingName: userSettingsForViewOptions.userAppSettingName,
              userAppSettingDescription:
                userSettingsForViewOptions.userAppSettingDescription,
              userAppSettingValue: languageOption.key,
              isDefault: userSettingsForViewOptions.isDefault,
            } as Setting;

            await updateAppSetting(editData);
          } else await addAppSetting(createData);

          i18n.changeLanguage(languageOption.key as string);
        } catch (err) {
          i18n.changeLanguage(languageOption.key as string);
        }
      }}
      headerStrings={{
        notificationsPanelStrings: {
          panelTitle: t("header.Notifications"),
          noNotificationsTitle: t("header.NoNotificationsTitle"),
          noNotificationsSubtitle: t("header.NoNotificationsSubtitle"),
        },
        helpPanelStrings: {
          panelTitle: t("header.Help"),
        },
        settingsPanelStrings: {
          panelTitle: t("header.Settings"),
        },
        userSettingsPanelStrings: {
          panelTitle: t("header.UserSettings"),
          myOfficeProfileLinkText: t("header.MyOfficeProfile"),
          myAccountLinkText: t("header.UserSettings"),
          signOutLinkText: t("header.SignOut"),
        },
        searchPlaceholder: t("header.Search"),
      }}
      additionalSettingsToggles={shouldShowPhrase ? [phraseToggle] : undefined}
      showErrorAndWarningMessages={() => {
        return (
          <>
            {notifications?.map((item) => {
              return (
                <div
                  key={item.notify?.label}
                  onClick={item.handleNotificationClick}
                  className={errorHandlerClasses.notificationMessage}
                >
                  <ErrorHandlerMessage
                    notify={item.notify}
                    errors={null}
                    fieldName={null}
                  />
                </div>
              );
            })}
            <ActionButton
              iconProps={{ iconName: "RingerRemove" }}
              allowDisabledFocus
              styles={{
                root: { float: "right", margin: "10px 10px 0 0" },
              }}
              onClick={() => appContext.clearAllNotifications()}
            >
              {t("greco.clear")}
            </ActionButton>
          </>
        );
      }}
      messagesCount={notifications.length}
    />
  );
}

export default AppHeader;

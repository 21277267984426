import { GrecoSpinner } from "@greco/components";

import { mergeStyles } from "@fluentui/react";
import React from "react";

const spinerClass = mergeStyles({
  position: "absolute",
  opacity: 0.2,
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  zIndex: 1000,
  background: "black",
});

const FormSubmitSpinner = () => {
  return (
    <div className={spinerClass}>
      <GrecoSpinner />
    </div>
  );
};

export default FormSubmitSpinner;

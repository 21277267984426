import SharedLicensePlate from "pages/SharedLicensePlate/SharedLicensePlate";
import TradeLicensePlates from "pages/TradeLicensePlates/TradeLicensePlates";
import FleetForm from "pages/fleet-form/FleetForm/FleetForm";
import { Fleet } from "pages/fleet/Fleet";
import { Homepage } from "pages/homepage/Homepage";
import InsurerForm from "pages/insurers/InsurerForm/InsurerForm";
import { Insurers } from "pages/insurers/Insurers";
import { FunctionComponent } from "react";
import { matchRoutes, useLocation } from "react-router-dom";
import { Analytics } from "../pages/analytics/Analytics";
import { Fleets } from "../pages/fleets/Fleets";
import RequireActionVehicles from "../pages/require-action-vehicles/RequireActionVehicles";
import Vehicle from "../pages/vehicle/Vehicle";

// import TradeVehicle from "pages/TradeVehicle/TradeVehicle";

const fleetPath = "/fleets/:fleetId";
const insurerPath = "/insurers/:insurerId";
const getFleetPath = (fleetId: number) => `/fleets/${fleetId}`;
const getInsurerPath = (insurerId: number) => `/insurers/${insurerId}`;

const fleetSharedLicensePlatePath = "/sharedLicensePlate/:fleetId";

const getSharedLicensePlatePath = (fleetId: number) =>
  `/sharedLicensePlate/${fleetId}`;

const fleetTradeLicensePlatesPath = "/tradeLicensePlate/:fleetId";

const getTradeLicensePlatesPath = (fleetId: number) =>
  `/tradeLicensePlate/${fleetId}`;

const analyticsPath = "/analytics";
const fleetlistPath = "/fleetlist";

export const insurersPath = "/insurers";

const fleetAnalyticsPath = "/analytics/:fleetId";
const getFleetAnalyticsPath = (fleetId: number) => `/analytics/${fleetId}`;

const editFleetPath = `${fleetPath}/edit`;
const getEditFleetPath = (fleetId: number) => `${getFleetPath(fleetId)}/edit`;

const createFleetPath = "/add-fleet";

const readOnlyVehiclePath = `${fleetPath}/vehicles/:vehicleId`;
const getReadOnlyVehiclePath = (fleetId: number, vehicleId: number) =>
  `${getFleetPath(fleetId)}/vehicles/${vehicleId}`;

const readOnlyTempVehiclePath = `${fleetPath}/vehicles/temp/:vehicleId`;

const editTempVehiclePath = `${readOnlyTempVehiclePath}/edit`;

const duplicateVehiclePath = `${readOnlyVehiclePath}/duplicate`;
const editVehiclePath = `${readOnlyVehiclePath}/edit`;

type RouteDef = {
  key: RouteKey;
  path: string;
  component: FunctionComponent<any>;
  getPath: (...args: any[]) => string;
};

type Routes = {
  home: RouteDef;
  fleets: RouteDef;
  fleet: RouteDef;
  fleetAnalytics: RouteDef;
  insurers: RouteDef;
  editFleet: RouteDef;
  editInsurer: RouteDef;
  createFleet: RouteDef;
  createVehicle: RouteDef;
  createTradeVehicle: RouteDef;
  editVehicle: RouteDef;
  editTempVehicle: RouteDef;
  duplicateVehicle: RouteDef;
  readOnlyVehicle: RouteDef;
  requireActionVehicles: RouteDef;
  analytics: RouteDef;
  sharedLicensePlate: RouteDef;
  tradeLicensePlates: RouteDef;
};

export type RouteKey = keyof Routes;

export const routes: Routes = {
  home: {
    key: "home",
    path: "/",
    component: Homepage,
    getPath: () => "/",
  },
  fleets: {
    key: "fleets",
    path: fleetlistPath,
    component: Fleets,
    getPath: () => fleetlistPath,
  },
  insurers: {
    key: "insurers",
    path: insurersPath,
    component: Insurers,
    getPath: () => insurersPath,
  },
  analytics: {
    key: "analytics",
    path: analyticsPath,
    component: Analytics,
    getPath: () => analyticsPath,
  },
  fleetAnalytics: {
    key: "fleetAnalytics",
    path: fleetAnalyticsPath,
    component: Analytics,
    getPath: getFleetAnalyticsPath,
  },
  fleet: {
    key: "fleet",
    path: fleetPath,
    component: Fleet,
    getPath: getFleetPath,
  },
  editFleet: {
    key: "editFleet",
    path: editFleetPath,
    component: FleetForm,
    getPath: getEditFleetPath,
  },
  sharedLicensePlate: {
    key: "sharedLicensePlate",
    path: fleetSharedLicensePlatePath,
    component: SharedLicensePlate,
    getPath: getSharedLicensePlatePath,
  },
  tradeLicensePlates: {
    key: "tradeLicensePlates",
    path: fleetTradeLicensePlatesPath,
    component: TradeLicensePlates,
    getPath: getTradeLicensePlatesPath,
  },
  createFleet: {
    key: "createFleet",
    path: createFleetPath,
    component: FleetForm,
    getPath: () => createFleetPath,
  },

  editInsurer: {
    key: "editInsurer",
    path: insurerPath,
    component: InsurerForm,
    getPath: getInsurerPath,
  },
  createVehicle: {
    key: "createVehicle",
    path: `${fleetPath}/add-vehicle`,
    component: Vehicle,
    getPath: (fleetId: number) => `${getFleetPath(fleetId)}/add-vehicle`,
  },
  createTradeVehicle: {
    key: "createTradeVehicle",
    path: `${fleetPath}/add-trade-vehicle`,
    component: Vehicle,
    getPath: (fleetId: number) => `${getFleetPath(fleetId)}/add-trade-vehicle`,
  },
  editVehicle: {
    key: "editVehicle",
    path: editVehiclePath,
    component: Vehicle,
    getPath: (fleetId: number, vehicleId: number) =>
      `${getFleetPath(fleetId)}/vehicles/${vehicleId}/edit`,
  },
  editTempVehicle: {
    key: "editTempVehicle",
    path: editTempVehiclePath,
    component: Vehicle,
    getPath: (fleetId: number, vehicleId: number) =>
      `${getFleetPath(fleetId)}/vehicles/temp/${vehicleId}/edit`,
  },
  duplicateVehicle: {
    key: "duplicateVehicle",
    path: duplicateVehiclePath,
    component: Vehicle,
    getPath: (fleetId: number, vehicleId: number) =>
      `${getReadOnlyVehiclePath(fleetId, vehicleId)}/duplicate`,
  },
  readOnlyVehicle: {
    key: "readOnlyVehicle",
    path: readOnlyVehiclePath,
    component: Vehicle,
    getPath: (fleetId: number, vehicleId: number) =>
      `${getReadOnlyVehiclePath(fleetId, vehicleId)}`,
  },
  requireActionVehicles: {
    key: "requireActionVehicles",
    path: "/need-attention-vehicles",
    component: RequireActionVehicles,
    getPath: () => "/need-attention-vehicles",
    // matchPath: (l) =>
    // matchPath(l.pathname, {
    //   path: "/require-action-vehicles",
    //   exact: true,
    // }),
  },
};
export const useCurrentRoute = () => {
  const location = useLocation();
  // const matchResult = useMatch(location.pathname);
  const realRoutes = Object.keys(routes).map((route) => {
    return {
      path: routes[route].path,
    };
  });
  const matchResult = matchRoutes(realRoutes, location);

  // console.log(matchResult, "matchResult matchResult lmatchResultcation");

  return Object.values(routes).reduce((match: any | null, r: any) => {
    if (matchResult[0].route.path === r.path) {
      return r.key;
    }
    return match;
  }, null);
};

// const location = useLocation();
// return Object.values(routes).reduce((match: RouteKey | null, r) => {
//   const matchResult = r.matchPath(location);
//   if (!!matchResult) {
//     return r.key;
//   }
//   return match;
// }, null);

import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "../../../../store/hooks";

const VehicleVin = ({ vehicleVin }) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  return <span>{t(taxonomy.VehicleMainGroup.byId[vehicleVin]?.code)}</span>;
};

export default VehicleVin;

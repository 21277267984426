import { useMutation } from "react-query";
import { importOrUpdateFleet } from "store/api/api";
import { handleAxiosError } from "store/util";
import { CreateUpdateFleetData } from "types/types";
import { MUTATION_KEYS } from "../queryKeys";

const updateFleet = async (params: CreateUpdateFleetData) => {
  return await importOrUpdateFleet(params);
};

export function useUpdateFleet() {
  return useMutation(updateFleet, {
    mutationKey: MUTATION_KEYS.updateFleet,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

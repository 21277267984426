import { useCurrentRoute } from "config/routes";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setVehicleTimelineOperationRetVal } from "store/vehicle";
import { setFleetTimelineOperationRetVal } from "../../../store/fleetForm";
import { useSelector } from "../../../store/hooks";
import {
  setOperationRetVal,
  setTimeline,
  setTimelineAppSettings,
} from "../../../store/timeline";
import { TimelineType } from "../../../types/types";
import { prepareTimeline } from "../prepareData/prepareTimeline";

export const useTimeline = () => {
  const dispatch = useDispatch();

  const vehicle = useSelector((s) => s.vehicle.vehicle);
  const vehicleFleet = useSelector((s) => s.vehicle.fleet);
  const insurerList = useSelector((s) => s.vehicle.insurerList);
  const taxonomy = useSelector((s) => s.taxonomy);

  const fleet = useSelector((s) => s.fleetForm.fleetWithPremiums);
  const fleetAppSettings = useSelector((s) => s.fleetForm.fleetAppSettings);
  const vehicleAppSettings = useSelector((s) => s.vehicle.vehicleAppSettings);
  const sharedLicensePlateVehicles = useSelector(
    (s) => s.fleetForm.sharedLicensePlateVehicles
  );
  const types = useSelector((s) => s.timeline.types);
  const readOnly = useSelector((s) => s.timeline.readOnly);
  const showBookingsWithDocumentDate = useSelector(
    (s) => s.timeline.timeline.showBookingsWithDocumentDate
  );
  const operationRetVal = useSelector((s) => s.timeline.operationRetVal);
  const route = useCurrentRoute();
  const { t } = useTranslation();

  useEffect(() => {
    let requiredDataPresent: boolean = false;
    if (!types) return;
    if (
      types.includes(TimelineType.VEHICLE_PREMIUM) &&
      vehicle &&
      vehicleFleet &&
      insurerList &&
      taxonomy
    ) {
      requiredDataPresent = true;
    } else if (
      types.includes(TimelineType.SHARED_LICENSE_PLATE_STATUS) &&
      fleet &&
      sharedLicensePlateVehicles &&
      taxonomy
    ) {
      requiredDataPresent = true;
    }

    if (requiredDataPresent) {
      const vehicleObj = { ...vehicle };
      if (route === "duplicateVehicle") {
        vehicleObj.vehicleLicensePlateHistoryData = [];
        vehicleObj.vehicleSharedLicensePlateHistoryData = [];
        vehicleObj.vehicleStatusHistoryData = [];
        vehicleObj.vehiclePremiumSegmentHistoryData = [];
        vehicleObj.vehicleOneTimePremiumSegmentHistoryData = [];
      }

      let prepareObj = {
        types,
        vehicle: vehicleObj,
        showBookingsWithDocumentDate,
        vehiclePremiumBookingData: showBookingsWithDocumentDate
          ? vehicleObj.vehiclePremiumBookingWithDocumentDateData
          : vehicleObj.vehiclePremiumBookingData,
        vehicleFleet,
        insurerList,
        fleet: null,
        sharedLicensePlateVehicles,
        taxonomy,
        readOnly,
        t,
        appSettings: vehicleAppSettings,
      };
      if (fleet) {
        prepareObj = {
          ...prepareObj,
          appSettings: fleetAppSettings,
          fleet: {
            fleetId: fleet.fleetId,
            fleetName: fleet.fleetName,
            fleetLastModifiedAt: fleet.fleetLastModifiedAt,
            fleetLastModifiedByUserId: fleet.fleetLastModifiedByUserId,
            fleetCreatedAt: fleet.fleetCreatedAt,
            fleetCreatedByUserId: fleet.fleetCreatedByUserId,
            fleetCreatedByUserName: fleet.fleetCreatedByUserName,
            countryCode: fleet.countryCode,
            clientName: fleet.clientName,
            webBasePartnerNumber: "" + fleet.webBasePartnerNumber,
            businessYearStartMonth: "" + fleet.businessYearStartMonth,
            businessYearStartDay: "" + fleet.businessYearStartDay,
            dueMonth: "" + fleet.dueMonth,
            dueDay: "" + fleet.dueDay,
            maxCalculationDate: fleet.maxCalculationDate,
            rowVersion: fleet.rowVersion,
          },
        };
      }
      dispatch(
        setTimelineAppSettings(fleet ? fleetAppSettings : vehicleAppSettings)
      );
      const timeline = prepareTimeline(prepareObj);
      dispatch(setTimeline(timeline));
    }
  }, [
    types,
    vehicle,
    vehicleFleet,
    insurerList,
    fleet,
    sharedLicensePlateVehicles,
    taxonomy,
    readOnly,
    route,
    showBookingsWithDocumentDate,
    t,
    vehicleAppSettings,
    fleetAppSettings,
  ]);

  useEffect(() => {
    if (!operationRetVal) return;
    if (types.includes(TimelineType.VEHICLE_PREMIUM)) {
      operationRetVal &&
        dispatch(setVehicleTimelineOperationRetVal(operationRetVal));
    } else if (types.includes(TimelineType.SHARED_LICENSE_PLATE_STATUS)) {
      operationRetVal &&
        dispatch(setFleetTimelineOperationRetVal(operationRetVal));
    }
    dispatch(setOperationRetVal(null));
  }, [types, operationRetVal, dispatch]);
};

import { mergeStyleSets } from "@fluentui/react";
import AppSettings, { InsurerSettingsTemplate } from "AppSettings";
import { BackButton } from "components/navigation/BackButton";
import { Links } from "components/navigation/Links";
import { HSpace } from "components/styled";
import { insurersPath } from "config/routes";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { useSelector } from "../../../store/hooks";
import InsurerSaveBtn from "./InsurerSaveBtn";

const saveBtnStyle = {
  root: {
    height: "100%",
  },
};

const getClasses = (theme) =>
  mergeStyleSets({
    navigation: {
      height: "44px",
      background: theme.palette.white,
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: "0px",
      position: "sticky",
      top: 0,
      zIndex: 999,
    },
  });

const InsurerCommandBar = ({ insurer }: any) => {
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;
  const classes = getClasses(theme);

  const dispatch = useDispatch();
  const loadFleetLoadStatus = useSelector(
    (s) => s.fleetForm.loadFleetLoadStatus
  );

  const taxonomy = useSelector((s) => s.taxonomy);

  const isDisabled = loadFleetLoadStatus === "loading";

  const insurerCountrySettings =
    AppSettings.getInstance().getAppSettingsTypes()[
      taxonomy.Country.byId[insurer?.countryCodeId]?.code
    ];
  return (
    <>
      <div className={classes.navigation}>
        <Links />
        <HSpace width={10} />
        <BackButton text={"bfm.back"} path={insurersPath} />
      </div>

      {insurerCountrySettings?.INSURER_SETTINGS_TEMPLATES.includes(
        InsurerSettingsTemplate.INSURANCE_LINE_DETAILS
      ) ? null : (
        <InsurerSaveBtn />
      )}
    </>
  );
};

export default InsurerCommandBar;

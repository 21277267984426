import { Dialog, DialogFooter, Icon } from "@fluentui/react";
import { formatISO } from "date-fns";
import { Form, Formik } from "formik";
import { useUpdateMultipleVehicleIssuingStatus } from "hooks/data/mutations/useUpdateMultipleVehicleIssuingStatus";
import { useUpdateVehicleIssuingStatus } from "hooks/data/mutations/useUpdateVehicleIssuingStatus";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import * as yup from "yup";
import { RefProvider } from "../../contexts/RefProvider";
import { useSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/store";
import { setYupLocale } from "../../utils/setYupLocale";
import { toast } from "../FluentToast";
import { FormSpinner } from "../FormSpinner";

import {
  loadVehiclesActions,
  setChangeVehicleIssuingStatusDialog,
} from "store/fleetPage";
import { useFields } from "../form/useFields";
import { StyledPrimaryButton } from "../styled";
import { config } from "./config";

export const ChangeVehicleIssuingStatusDialogComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;
  const dispatch: AppDispatch = useDispatch();
  const taxonomy = useSelector((s) => s.taxonomy);
  const phraseActivated = usePhraseActivationStatus().activated;

  const changeVehicleIssuingStatusDialog = useSelector(
    (s) => s.fleetPage.changeVehicleIssuingStatusDialog
  );

  const close = () => {
    dispatch(
      setChangeVehicleIssuingStatusDialog({
        isOpen: false,
        fleetId: null,
        vehicle: null,
        vehicleIds: [],
      })
    );
  };

  const {
    mutate: onUpdateVehicleIssuingStatus,
    isLoading: isUpdateIssuingStatusLoading,
  } = useUpdateVehicleIssuingStatus();

  const {
    mutate: onUpdateMultipleVehicleIssuingStatus,
    isLoading: isUpdateMultipleIssuingStatusLoading,
  } = useUpdateMultipleVehicleIssuingStatus();

  const schema = useMemo(() => {
    setYupLocale(t);
    return yup.object({
      vehicleIssuingStatusCode: yup.mixed().required(),
      vehicleIssuingSubstatusCode: yup
        .mixed()
        .when("vehicleIssuingStatusCode", {
          is: (s) => s && s.value === 2,
          then: yup.mixed().required(),
          otherwise: yup.mixed(),
        }),
      vehicleIssuingComment: yup.string().max(512).nullable(),
      vehicleFirstIssuingStatusDate: yup.date().nullable(),
      vehicleLastIssuingStatusDate: yup.date().nullable(),
    });
  }, [t]);

  const { vehicle, vehicleIds, fleetId } = changeVehicleIssuingStatusDialog;

  const isLoading =
    isUpdateIssuingStatusLoading || isUpdateMultipleIssuingStatusLoading;

  const [didTrySubmit, setDidTrySubmit] = useState(false);

  const title = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Icon
        // iconName="LightningBolt"
        style={{
          color: theme.palette.themePrimary,
          fontSize: "25px",
          marginRight: "5px",
        }}
      />
      {vehicle
        ? t("bfm.changeIssuingStatus.label")
        : t("bfm.changeIssuingStatusVehicles.label", {
            num: vehicleIds.length,
          })}
    </div>
  );

  const onSubmit = (data) => {
    if (!vehicle && vehicleIds.length === 0) return;
    if (data.vehicleIssuingStatusCode.value !== 2) {
      data.vehicleIssuingSubstatusCode = undefined;
    }
    if (vehicle) {
      onUpdateVehicleIssuingStatus(
        {
          fleetId: fleetId,
          vehicleId: vehicle.vehicleId,
          vehicleIssuingStatusCode: data.vehicleIssuingStatusCode.value,
          vehicleFirstIssuingStatusDate: data.vehicleFirstIssuingStatusDate
            ? formatISO(data.vehicleLastIssuingStatusDate, {
                representation: "date",
              })
            : null,
          vehicleLastIssuingStatusDate: data.vehicleLastIssuingStatusDate
            ? formatISO(data.vehicleLastIssuingStatusDate, {
                representation: "date",
              })
            : null,
          vehicleIssuingSubstatusCode: parseInt(
            data?.vehicleIssuingSubstatusCode?.value
          ),
          vehicleIssuingComment: data.vehicleIssuingComment,
        },
        {
          onSuccess: () => {
            dispatch(loadVehiclesActions.trigger({ isReset: true }));
            toast.success(t("bfm.statusChanged"));
            close();
          },
        }
      );
    } else if (vehicleIds.length > 0) {
      onUpdateMultipleVehicleIssuingStatus(
        {
          fleetId: fleetId,
          vehicleIds: vehicleIds.map((item) => item.toString()),
          vehicleIssuingStatusCode: data.vehicleIssuingStatusCode.value,
          vehicleFirstIssuingStatusDate: data.vehicleFirstIssuingStatusDate
            ? formatISO(data.vehicleFirstIssuingStatusDate, {
                representation: "date",
              })
            : null,
          vehicleLastIssuingStatusDate: data.vehicleLastIssuingStatusDate
            ? formatISO(data.vehicleLastIssuingStatusDate, {
                representation: "date",
              })
            : null,
          vehicleIssuingSubstatusCode: parseInt(
            data?.vehicleIssuingSubstatusCode?.value
          ),
          vehicleIssuingComment: data.vehicleIssuingComment,
        },
        {
          onSuccess: () => {
            dispatch(loadVehiclesActions.trigger({ isReset: true }));
            toast.success(t("bfm.statusChanged"));
            close();
          },
        }
      );
    }
  };

  const initialValues = useMemo(() => {
    if (!vehicle) return {};
    let issuingStatusCode =
      taxonomy.VehicleIssuingStatus.byId[vehicle.vehicleIssuingStatusCode];
    let vehicleIssuingStatusCode = issuingStatusCode?.code;
    if (vehicleIssuingStatusCode) {
      vehicleIssuingStatusCode = {
        label: t(issuingStatusCode?.code),
        value: vehicle.vehicleIssuingStatusCode,
      } as any;
    }

    let issuingSubstatusCode =
      taxonomy.VehicleIssuingSubstatus.byId[
        vehicle.vehicleIssuingSubstatusCode
      ];
    let vehicleIssuingSubstatusCode = issuingSubstatusCode?.code;
    if (vehicleIssuingSubstatusCode) {
      vehicleIssuingSubstatusCode = {
        label: t(issuingSubstatusCode?.code),
        value: vehicle.vehicleIssuingSubstatusCode,
      } as any;
    }

    let vehicleFirstIssuingStatusDate = new Date(
      null //vehicle?.vehicleLastIssuingStatusDate
    );
    let vehicleLastIssuingStatusDate = new Date(
      null //vehicle?.vehicleLastIssuingStatusDate
    );

    let vehicleIssuingComment = vehicle?.vehicleIssuingComment;

    return {
      vehicleIssuingStatusCode,
      vehicleIssuingSubstatusCode,
      vehicleFirstIssuingStatusDate,
      vehicleLastIssuingStatusDate,
      vehicleIssuingComment,
    };
  }, [vehicle, taxonomy, t]);

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: title,
        showCloseButton: true,
      }}
      minWidth={500}
      onDismiss={close}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <RefProvider>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={didTrySubmit}
          validateOnBlur={didTrySubmit}
          validationSchema={schema}
        >
          {({ submitForm }) => {
            return (
              <Form>
                <Fields />
                <DialogFooter>
                  <StyledPrimaryButton
                    text={t("greco.save")}
                    onClick={() => {
                      setDidTrySubmit(true);
                      submitForm();
                    }}
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </DialogFooter>
                {isUpdateIssuingStatusLoading && <FormSpinner />}
              </Form>
            );
          }}
        </Formik>
      </RefProvider>
    </Dialog>
  );
};

const Fields = () => {
  const { all } = useFields(config);
  return all;
};

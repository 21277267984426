import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "store/hooks";
import { Timeline } from "../../../components/timeline/Timeline";
import { setTimelineInitValues } from "../../../store/timeline";
import FinanceForm from "./FinanceForm/FinanceForm";
import FinancePreview from "./FinancePreview";

const FinanceSection = ({
  vehicle,
  route,
  defaultSettings,
  initialValues,
  formikProps,
}) => {
  const isReadOnlyMode = route === "readOnlyVehicle";

  const dispatch = useDispatch();
  const fleetCountryCode = useSelector((s) => s.vehicle.fleet.countryCode);
  const vehicleAppSettings = useSelector((s) => s.vehicle.vehicleAppSettings);

  useEffect(() => {
    if (route !== "editTempVehicle")
      dispatch(
        setTimelineInitValues({
          types: vehicleAppSettings?.VEHICLE_TIMELINE_TYPES,
          readOnly: isReadOnlyMode,
          countryCode: fleetCountryCode,
        })
      );
  }, [route, fleetCountryCode, vehicleAppSettings]);

  return (
    <div>
      {isReadOnlyMode ? (
        <>
          <FinancePreview vehicle={vehicle} route={route} />
          {vehicle && (
            <div
              style={{
                padding: 50,
              }}
            >
              {route !== "editTempVehicle" && <Timeline width={1100} />}
            </div>
          )}
        </>
      ) : (
        <>
          {vehicle &&
            route !== "duplicateVehicle" &&
            route !== "editTempVehicle" && (
              <Timeline width={970} marginLeft={70} />
            )}

          <FinanceForm
            formikProps={formikProps}
            vehicle={vehicle}
            route={route}
          />
        </>
      )}
    </div>
  );
};

export default FinanceSection;

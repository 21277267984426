import * as yup from "yup";
import { TimelineDialogType } from "../../../types/types";

export const validationObjectConfig = (item, dialogType): any => {
  const fieldSets = [];

  const vehicleLastPolicyStatusDate = {
    vehicleLastPolicyStatusDate: yup.date().required().nullable(),
  };
  const comment = {
    comment: yup.string().trim().max(512),
  };

  const vehiclePolicyStatusCode = {
    vehiclePolicyStatusCode: yup.mixed().nullable().required(),
  };

  fieldSets.push(vehicleLastPolicyStatusDate); //0
  fieldSets.push(comment); //1

  fieldSets.push(vehiclePolicyStatusCode); //1

  let yupObject: any = {};
  if (dialogType === TimelineDialogType.CORRECT_POLICY_STATUS) {
    yupObject = {
      ...fieldSets[0],
      ...fieldSets[1],
      ...fieldSets[2],
    };
  }
  if (dialogType === TimelineDialogType.DELETE_POLICY_STATUS) {
    yupObject = {
      ...fieldSets[1],
    };
  }
  return yupObject;
};

import { ActionButton } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { routes } from "../../../config/routes";

type Props = {
  isActive?: boolean;
};

const VehicleListBtn = ({ isActive }: Props) => {
  const { fleetId } = useParams() as any;
  const { t } = useTranslation();

  return (
    <Link to={routes.fleet.getPath(fleetId)}>
      <ActionButton
        text={t("bfm.vehicleList")}
        styles={{
          root: {
            fontWeight: isActive ? "bold" : "inherit",
          },
        }}
        iconProps={{
          iconName: "NumberedList",
          styles: {
            root: {
              fontWeight: isActive ? "bold" : "inherit",
            },
          },
        }}
      />
    </Link>
  );
};

export default VehicleListBtn;

import React from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import NumberInputField from "../NumberInputField";

const InsuranceLineForm = ({ item, props }: any) => {
  const { t } = useTranslation();

  const theme = useTheme();
  return (
    <Table>
      <thead>
        <tr>
          <th>{t("bfm.insuranceLineDetails.premiumTypeCode.label")}</th>
          <th>{t("bfm.insuranceLineDetails.taxPercent.label")}</th>
          <th>{t("bfm.insuranceLineDetails.taxExtraPercent1.label")}</th>
          {/* <th>{t("bfm.insuranceLineDetails.taxExtraPercent2.label")}</th> */}
          <th>
            {t("bfm.insuranceLineDetails.maxValueForTaxCalculation.label")}
          </th>
        </tr>
      </thead>
      <tbody>
        {item.insurerSettingPerPremiumTypeDetailsHU.map(
          (insuranceLineDetail, index) => (
            <tr key={index}>
              <td>
                <div
                  style={{
                    fontSize: "12px",
                    fontStyle: "italic",
                    color: theme.palette.neutralSecondaryAlt,
                    textAlign: "center",
                  }}
                >
                  {t(insuranceLineDetail.premiumTypeCode)}
                </div>
              </td>
              <td>
                <NumberInputField
                  setFieldValue={props.setFieldValue}
                  value={
                    props.values?.insurerSettingPerPremiumTypeDetailsHU[index]
                      .taxPercent
                  }
                  errors={props.errors}
                  onChange={props.handleChange}
                  name={`insurerSettingPerPremiumTypeDetailsHU[${index}].taxPercent`}
                  disabled={false}
                  label={""}
                  tooltip={t("bfm.insuranceLineDetails.taxPercent.label")}
                />
              </td>
              <td>
                <NumberInputField
                  setFieldValue={props.setFieldValue}
                  value={
                    props.values?.insurerSettingPerPremiumTypeDetailsHU[index]
                      .taxExtraPercent1
                  }
                  errors={props.errors}
                  onChange={props.handleChange}
                  name={`insurerSettingPerPremiumTypeDetailsHU[${index}].taxExtraPercent1`}
                  disabled={false}
                  label={""}
                  tooltip={t("bfm.insuranceLineDetails.taxExtraPercent1.label")}
                />
              </td>
              {/* <td>
                {" "}
                {insuranceLineDetail.premiumTypeCode === "PremiumType.MTPL" ? (
                  <NumberInputField
                    setFieldValue={props.setFieldValue}
                    value={
                      props.values?.insurerSettingPerPremiumTypeDetailsHU[index]
                        .taxExtraPercent2
                    }
                    errors={props.errors}
                    onChange={props.handleChange}
                    name={`insurerSettingPerPremiumTypeDetailsHU[${index}].taxExtraPercent2`}
                    disabled={false}
                    label={""}
                    tooltip={t(
                      "bfm.insuranceLineDetails.taxExtraPercent2.label"
                    )}
                  />
                ) : null}
              </td> */}
              <td>
                {insuranceLineDetail.premiumTypeCode === "PremiumType.MTPL" ? (
                  <NumberInputField
                    setFieldValue={props.setFieldValue}
                    value={
                      props.values?.insurerSettingPerPremiumTypeDetailsHU[index]
                        .maxValueForTaxCalculation
                    }
                    errors={props.errors}
                    onChange={props.handleChange}
                    name={`insurerSettingPerPremiumTypeDetailsHU[${index}].maxValueForTaxCalculation`}
                    disabled={false}
                    label={""}
                    tooltip={t(
                      "bfm.insuranceLineDetails.maxValueForTaxCalculation.label"
                    )}
                  />
                ) : null}
              </td>
            </tr>
          )
        )}
      </tbody>
    </Table>
  );
};

export default InsuranceLineForm;

export const Table = styled.table`
  border-collapse: collapse;
  // border-top: 1px solid ${(p) => p.theme.palette.neutralLighterAlt};
  width: 100%;
  margin-bottom: 20px;
  td {
    padding: 5px 8px;
  }
  th {
    padding: 5px;
    font-size: 12px;
    font-weight: 650;
    // border-bottom: 1px solid ${(p) => p.theme.palette.neutralLighterAlt};
    height: 42px;
    color: ${(p) => p.theme.palette.neutralPrimary};
  }
  tr {
    border-bottom: 1px solid rgb(237, 235, 233);
  }
`;

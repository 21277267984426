import { Icon } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useCurrentRoute } from "../../../config/routes";
import { useSelector } from "../../../store/hooks";
import { PremiumTypeKey } from "../../../store/vehicle";
import { VehiclePremiumBookingPerTypeData } from "../../../types/types";
import { capitalize } from "../../../utils/utils";

type Props = {
  premiumType: PremiumTypeKey;
};

export const BookingSummary = ({ premiumType }: Props) => {
  const { t } = useTranslation();
  const vehicle = useSelector((s) => s.vehicle.vehicle);
  const route = useCurrentRoute();
  if (route === "createVehicle" || route === "duplicateVehicle") return null;

  if (!vehicle) return null;
  if (!vehicle.vehiclePremiumBookingData) return null;

  var pt =
    premiumType === "HORSE_POWER"
      ? "EngineTax"
      : capitalize(premiumType.toLowerCase());

  if (premiumType === "HULL_BASIC") pt = "HullBasic";

  var pte = `vehicle${pt}PremiumBookingData`;

  let bookingData = vehicle.vehiclePremiumBookingData[
    pte
  ] as VehiclePremiumBookingPerTypeData;
  if (!!!bookingData) return null;
  return null;
  //   <div>
  //     <VSpace height={5} />
  //     <Row>
  //       <Column>
  //         <Flex>
  //           <HistoryIcon iconName="Financial" />
  //           <HSpace />
  //           <Label>
  //             {t("bfm.vehicleForm.tabPremiumBooking.currentPeriod.label")}
  //           </Label>
  //         </Flex>
  //         <VSpace height={5} />
  //         <span>
  //           <PremiumValue>
  //             <Row>
  //               <Column>
  //                 {t("bfm.vehicleForm.tabPremiumBooking.booked.label")}
  //               </Column>
  //               <ColumnRight>
  //                 {formatNumber(
  //                   bookingData.totalBookedAmountPerCurrentPolicyCoverPeriod
  //                 )}
  //               </ColumnRight>
  //             </Row>
  //           </PremiumValue>
  //           <VSpace height={5} />
  //         </span>
  //         <span>
  //           <PremiumValue>
  //             <Row>
  //               <Column>
  //                 {t("bfm.vehicleForm.tabPremiumBooking.paid.label")}
  //               </Column>
  //               <ColumnRight>
  //                 {formatNumber(
  //                   bookingData.totalPaidAmountPerCurrentPolicyCoverPeriod
  //                 )}
  //               </ColumnRight>
  //             </Row>
  //           </PremiumValue>
  //           <VSpace height={5} />
  //         </span>
  //         <hr></hr>
  //         <span>
  //           <PremiumValue>
  //             <Row>
  //               <Column>
  //                 {t("bfm.vehicleForm.tabPremiumBooking.balance.label")}
  //               </Column>
  //               <ColumnRight>
  //                 {formatNumber(
  //                   bookingData.totalSaldoPerCurrentPolicyCoverPeriod
  //                 )}
  //               </ColumnRight>
  //             </Row>
  //           </PremiumValue>
  //           <VSpace height={5} />
  //         </span>
  //       </Column>
  //       <Column>
  //         <Flex>
  //           <HistoryIcon iconName="FinancialSolid" />
  //           <HSpace />
  //           <Label>
  //             {t("bfm.vehicleForm.tabPremiumBooking.totalPeriod.label")}
  //           </Label>
  //         </Flex>
  //         <VSpace height={5} />
  //         <span>
  //           <PremiumValue>
  //             <Row>
  //               <Column>
  //                 {t("bfm.vehicleForm.tabPremiumBooking.booked.label")}
  //               </Column>
  //               <ColumnRight>
  //                 {formatNumber(bookingData.totalBookedAmount)}
  //               </ColumnRight>
  //             </Row>
  //           </PremiumValue>
  //           <VSpace height={5} />
  //         </span>
  //         <span>
  //           <PremiumValue>
  //             <Row>
  //               <Column>
  //                 {t("bfm.vehicleForm.tabPremiumBooking.paid.label")}
  //               </Column>
  //               <ColumnRight>
  //                 {formatNumber(bookingData.totalPaidAmount)}
  //               </ColumnRight>
  //             </Row>
  //           </PremiumValue>
  //           <VSpace height={5} />
  //         </span>
  //         <hr></hr>
  //         <span>
  //           <PremiumValue>
  //             <Row>
  //               <Column>
  //                 {t("bfm.vehicleForm.tabPremiumBooking.balance.label")}
  //               </Column>
  //               <ColumnRight>
  //                 {formatNumber(bookingData.totalSaldo)}
  //               </ColumnRight>
  //             </Row>
  //           </PremiumValue>
  //           <VSpace height={5} />
  //         </span>
  //       </Column>
  //     </Row>
  //     <VSpace height={5} />
  //   </div>
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 150px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

const ColumnRight = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

const PremiumValue = styled.div`
  font-size: 12px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  font-weight: bold;
`;

const HistoryIcon = styled(Icon)`
  color: ${(p) => p.theme.palette.neutralPrimary};
`;

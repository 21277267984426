import {
  IShimmerElement,
  Shimmer,
  ShimmerElementType,
} from "@fluentui/react/lib/Shimmer";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import * as React from "react";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

const classes = mergeStyleSets({
  navigation: {
    height: 44,
  },
  wrapp: {
    marginTop: 50,
  },
  content: {
    maxWidth: "80%",
    margin: "50px auto",
  },
});

const headerSkeleton: IShimmerElement[] = [
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "20%",
    height: 44,
  },
];

const mainFieldsSkeleton: IShimmerElement[] = [
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "30%",
    height: 44,
  },
  { type: ShimmerElementType.gap, width: "2%" },
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "30%",
    height: 44,
  },
  { type: ShimmerElementType.gap, width: "2%" },
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "30%",
    height: 44,
  },
];

const formSeleton: IShimmerElement[] = [
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "100%",
    height: 800,
  },
];

const VehicleSkeleton = () => {
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;
  return (
    <div>
      <div>
        <Shimmer shimmerElements={headerSkeleton} />
      </div>
      <div className={classes.content}>
        <Shimmer
          shimmerElements={mainFieldsSkeleton}
          shimmerColors={{
            background: isDarkMode ? "#151515" : "#f8f9fe",
          }}
        />
        <Shimmer
          style={{
            marginTop: "20px",
          }}
          shimmerElements={mainFieldsSkeleton}
          shimmerColors={{
            background: isDarkMode ? "#151515" : "#f8f9fe",
          }}
        />

        <div className={classes.wrapp}>
          <Shimmer shimmerElements={formSeleton} />
        </div>
      </div>
    </div>
  );
};

export default VehicleSkeleton;

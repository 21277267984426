import { useMutation, useQueryClient } from "react-query";
import { deleteInsurerSetting } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS, QUERY_KEYS } from "../queryKeys";

export const deleteInsurerSettingFn = async ({
  insurerPartnerNumber,
  insurerSettingId,
}: {
  insurerSettingId: string;
  insurerPartnerNumber: string;
}) => {
  return await deleteInsurerSetting(insurerPartnerNumber, insurerSettingId);
};

export function useDeleteInsurerSetting() {
  const queryClient = useQueryClient();
  return useMutation(deleteInsurerSettingFn, {
    mutationKey: MUTATION_KEYS.deleteInsurerSetting,
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.insurerListByCountry);
      queryClient.invalidateQueries(QUERY_KEYS.insurerListSettings);
    },
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

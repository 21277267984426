import React from "react";
import { TimelineDialogType } from "../../../types/types";
import { useFields } from "../../form/useFields";
import { modifySharedLicensePlateDialogConfig } from "./modifySharedLicensePlateDialogConfig";

const config = modifySharedLicensePlateDialogConfig;

interface IProps {
  dialogType: TimelineDialogType;
}

export const ModifySharedLicensePlateDialogContents = ({
  dialogType,
}: IProps) => {
  const { all: premiumTypeCode } = useFields([config.premiumTypeCode]);
  const { all: licensePlate } = useFields([config.licensePlate]);
  const { all: reasonForModificationComment } = useFields([
    config.reasonForModificationComment,
  ]);
  const { all: validFromDate } = useFields([config.validFromDate]);
  const { all: validToDate } = useFields([config.validToDate]);
  const { all: mainVehicleId } = useFields([config.mainVehicleId]);

  let left = <></>;
  let right = <></>;
  let bottom = <></>;

  if (dialogType === TimelineDialogType.INSERT_SHARED_LICENSE_PLATE) {
    left = (
      <>
        {licensePlate}
        {premiumTypeCode}
      </>
    );
    right = (
      <>
        {mainVehicleId}
        {validFromDate}
        {validToDate}
      </>
    );
    bottom = <>{reasonForModificationComment}</>;
  }
  if (dialogType === TimelineDialogType.CANCEL_SHARED_LICENSE_PLATE) {
    left = (
      <>
        {licensePlate}
        {premiumTypeCode}
      </>
    );
    right = <>{validFromDate}</>;
    bottom = <>{reasonForModificationComment}</>;
  }
  if (dialogType === TimelineDialogType.CORRECT_SHARED_LICENSE_PLATE) {
    left = (
      <>
        {licensePlate}
        {premiumTypeCode}
      </>
    );
    right = (
      <>
        {mainVehicleId}
        {validFromDate}
        {validToDate}
      </>
    );
    bottom = <>{reasonForModificationComment}</>;
  }
  if (dialogType === TimelineDialogType.DELETE_SHARED_LICENSE_PLATE) {
    left = <>{premiumTypeCode}</>;
    right = <>{licensePlate}</>;
    bottom = <>{reasonForModificationComment}</>;
  }
  if (dialogType === TimelineDialogType.CONTINUE_SHARED_LICENSE_PLATE) {
    left = <>{premiumTypeCode}</>;
    right = <>{licensePlate}</>;
    bottom = <>{reasonForModificationComment}</>;
  }
  if (dialogType === TimelineDialogType.ACTIVATE_SHARED_LICENSE_PLATE) {
    left = <>{licensePlate}</>;
    right = (
      <>
        {validFromDate}
        {validToDate}
      </>
    );
    bottom = <>{reasonForModificationComment}</>;
  }
  if (dialogType === TimelineDialogType.SUSPEND_SHARED_LICENSE_PLATE) {
    left = <>{licensePlate}</>;
    right = (
      <>
        {validFromDate}
        {validToDate}
      </>
    );
    bottom = <>{reasonForModificationComment}</>;
  }
  let dialogContents = (
    <>
      <div className="row">
        <div className="column">{left}</div>
        <div className="column">{right}</div>
      </div>
      <div className="row">{bottom}</div>
    </>
  );

  return dialogContents;
};

import React, { useRef, useEffect } from "react";
import { scaleTime, brushX, select } from "d3";
import { AxisBottom } from "./AxisBottom";
import { PastBackground } from "./PastBackground";
import { useSelector } from "../../../../store/hooks";
import { useDispatch } from "react-redux";
import { setDateFilter } from "../../../../store/timeline";

interface IProps {
  x: number;
  y: number;
  timezoneWidth: number;
  timezoneHeight: number;
  xScale: any;
}
/* Component */
export const PeriodSelector = ({
  x,
  y,
  xScale,
  timezoneWidth,
  timezoneHeight,
}: IProps) => {
  const dispatch = useDispatch();
  const brushRef = useRef();

  useEffect(() => {
    const brush = brushX().extent([
      [0, 0],
      [timezoneWidth, timezoneHeight],
    ]);
    brush(select(brushRef.current));
    brush.on("brush end", (event) => {
      dispatch(
        setDateFilter(event.selection && event.selection.map(xScale.invert))
      );
    });
  }, [xScale, dispatch, timezoneHeight, timezoneWidth, x, y]);

  return (
    <g transform={`translate(${x},${y})`}>
      <PastBackground
        innerHeight={timezoneHeight}
        innerWidth={timezoneWidth}
        xScale={xScale}
      />
      <AxisBottom innerHeight={timezoneHeight} xScale={xScale} />

      <g ref={brushRef} />
    </g>
  );
};

import { useMutation } from "react-query";
import { approveVehiclePendingStatus } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const approveVehiclePendingStatusFn = async ({
  fleetId,
  vehicleId,
  rowVersion,
}: {
  fleetId: string | number;
  vehicleId: string | number;
  rowVersion?: string;
}) => {
  return await approveVehiclePendingStatus(fleetId, vehicleId, rowVersion);
};

export function useApproveVehiclePendingStatus() {
  return useMutation(approveVehiclePendingStatusFn, {
    mutationKey: MUTATION_KEYS.approveVehiclePendingStatus,
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { DefaultButton, mergeStyleSets } from "@fluentui/react";
import { isExternalOrReadonlyAdmin } from "form/util";
import { useGetFleetInsurerDefaultSettings } from "hooks/data/queries/getFleetInsurerDefaultSettings";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "store/hooks";
import { IFleetInsurerDefaultSetting } from "types/types";
import { EActionDialog } from "../../constants";
import InsurerSettingDialog from "./InsurerSettingDialog/InsurerSettingDialog";
import InsurerTable from "./InsurerTable/InsurerTable";

type Props = {
  insurerSettingsRef: React.MutableRefObject<any>;
};

const defaultSettingDialogState = {
  actionType: "",
  title: "",
  isOpen: false,
  settings: null,
};

export interface ISettingDialogState {
  actionType: EActionDialog;
  title: string;
  isOpen: boolean;
  settings: IFleetInsurerDefaultSetting | null;
}

function InsurerDefaultSettings({ insurerSettingsRef }: Props) {
  const { fleetId } = useParams() as any;
  const userRole = useSelector((s) => s.auth.userRole);

  useGetFleetInsurerDefaultSettings(fleetId);

  const [settingDialog, setSettingDialog] = useState({
    actionType: "",
    title: "",
    isOpen: false,
    settings: null,
  });

  const { t } = useTranslation();

  const onCloseSettingDialog = useCallback(() => {
    setSettingDialog(defaultSettingDialogState);
  }, []);

  const onSetSettingDialog = useCallback((data: ISettingDialogState) => {
    setSettingDialog(data);
  }, []);

  return (
    <div
      className={classes.container}
      ref={insurerSettingsRef}
      id="inusrer-default-values-tab"
    >
      <DefaultButton
        iconProps={{
          iconName: "Add",
        }}
        className={classes.addBtn}
        onClick={() => {
          setSettingDialog({
            actionType: EActionDialog.CreateSetting,
            title: t("bfm.createInsurerDefaultSettings.label"),
            isOpen: true,
            settings: null,
          });
        }}
        text={t("bfm.createInsurerDefaultSettings.label")}
        disabled={isExternalOrReadonlyAdmin(userRole)}
      />

      <InsurerTable onSetSettingDialog={onSetSettingDialog} />
      <InsurerSettingDialog
        isOpen={settingDialog.isOpen}
        onClose={onCloseSettingDialog}
        settingDialog={settingDialog}
      />
    </div>
  );
}

export default InsurerDefaultSettings;

const classes = mergeStyleSets({
  container: {
    marginTop: "100px",
  },
  content: {
    display: "flex",
    height: "44px",
    padding: "0 8px",
  },
  addBtn: {
    marginBottom: "10px",
  },
});

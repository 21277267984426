import {
  ConstrainMode,
  DetailsListLayoutMode,
  IColumn,
  IDetailsColumnRenderTooltipProps,
  IDetailsHeaderProps,
  IRenderFunction,
  mergeStyleSets,
  Modal,
  ScrollablePane,
  SelectionMode,
  ShimmeredDetailsList,
  Spinner,
  Sticky,
  StickyPositionType,
  TooltipHost,
} from "@fluentui/react";
import { QUERY_KEYS } from "hooks/data/queryKeys";
import React, { useState } from "react";
import { useIsFetching } from "react-query";
import { useDispatch } from "react-redux";
import {
  selectSortInsurersPage,
  setSortInsurersPage,
} from "store/insurersPage";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { useSelector } from "../../../store/hooks";
import { Insurer } from "../../../types/types";
import { ContextButtonInsurers } from "./ContextButtonInsurers";

type TContextualMenyProps = {
  onDismiss: Function;
  target: any;
  item: Insurer;
};

const InsurerListTable = ({ items, columns }) => {
  const theme = useTheme() as IStyledTheme;

  const dispatch = useDispatch();

  const isLoadingInsurerList = !!useIsFetching({
    queryKey: QUERY_KEYS.insurerListByCountry,
  });

  const isLoadingInsurerListSettings = !!useIsFetching({
    queryKey: QUERY_KEYS.insurerListSettings,
  });

  const isLoading = isLoadingInsurerList || isLoadingInsurerListSettings;

  const sort = useSelector(selectSortInsurersPage);

  const [contextualMenuProps, setContextualMenuProps] = useState<
    TContextualMenyProps | undefined
  >(undefined);

  const classes = getClassNames(theme);

  const onClickHeaderCell = (column) => {
    if (!column.isSortable) return;
    const isSorted = sort.key === column.key;
    if (isSorted) {
      if (sort.dir === "asc") {
        dispatch(
          setSortInsurersPage({
            key: column.key,
            dir: "desc",
          })
        );
      }
      //
      else {
        dispatch(
          setSortInsurersPage({
            key: "insurerName",
            dir: "asc",
          })
        );
      }
    }
    //
    else {
      dispatch(
        setSortInsurersPage({
          key: column.key,
          dir: "asc",
        })
      );
    }
  };

  // const onItemInvoked = (fleet) => {
  //   const link = routes.fleet.getPath(fleet.fleetId);
  //   history.push(link);
  // };

  const onItemContextMenu = (
    item?: Insurer,
    index?: number | undefined,
    ev?: Event | undefined
  ): boolean => {
    rightClickHandler(item, ev, columns[index || 0]);
    return false;
  };

  const rightClickHandler = (item: Insurer, ev: any, column: IColumn): void => {
    setContextualMenuProps({
      target: column ? ev : { x: ev.pageX, y: ev.pageY },
      item,
      onDismiss: () => {
        setContextualMenuProps(undefined);
      },
    });
  };

  const _getKey = (item: Insurer, index?: number): string => {
    return item && item?.insurerId.toString();
  };

  const onRenderColumnHeaderTooltip: IRenderFunction<
    IDetailsColumnRenderTooltipProps
  > = (tooltipHostProps) => {
    return (
      <TooltipHost
        {...tooltipHostProps}
        content={tooltipHostProps?.column?.name}
      />
    );
  };

  const onRenderDetailsHeader = (
    props: IDetailsHeaderProps | undefined,
    defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
  ) => (
    <Sticky
      stickyPosition={StickyPositionType.Header}
      isScrollSynced
      stickyClassName={classes.table}
    >
      {defaultRender!({
        ...props,
        onRenderColumnHeaderTooltip,
      })}
    </Sticky>
  );

  return (
    <>
      <div className={classes.container} id="scrollableDiv">
        {/* {contextualMenuProps && (
          <InsurerContextMenu
            insurer={contextualMenuProps.item}
            target={contextualMenuProps.target}
            onDismiss={contextualMenuProps.onDismiss}
          />
        )} */}
        <ScrollablePane>
          <ShimmeredDetailsList
            items={items || []}
            compact={false}
            columns={columns || []}
            selectionMode={SelectionMode.single}
            setKey="single"
            getKey={_getKey}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onRenderItemColumn={(item, index, column) => {
              if (column.key === "options") {
                return (
                  <span className="action-icon">
                    <ContextButtonInsurers
                      insurer={item}
                      insurerSettingsHistory={item.insurerSettingsHistory}
                    />
                  </span>
                );
              }
              return column[index];
            }}
            enableShimmer={isLoading}
            shimmerLines={20}
            selectionPreservedOnEmptyClick={true}
            // onItemInvoked={onItemInvoked}
            enterModalSelectionOnTouch={true}
            onItemContextMenu={onItemContextMenu}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            constrainMode={ConstrainMode.unconstrained}
            onColumnHeaderClick={(ev, column) => {
              onClickHeaderCell(column);
            }}
            className={classes.table}
            onRenderDetailsHeader={(
              props: IDetailsHeaderProps | undefined,
              defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
            ) => onRenderDetailsHeader(props, defaultRender)}
          />
        </ScrollablePane>
      </div>
      <Modal //prevent scrolling on loading data
        styles={{
          root: {
            opacity: 0,
          },
        }}
        isOpen={isLoading}
        isBlocking={isLoading}
      ></Modal>
      {isLoading && (
        <Spinner
        // label={t('Loading More Items')}
        />
      )}
    </>
  );
};

const getClassNames = (theme) =>
  mergeStyleSets({
    container: {
      // height: "90vh",
      height: "calc(100vh  - 100px)",

      marginBottom: "20px",
      position: "relative",
      overflow: "auto",
      width: "100%",
    },
    listContainer: {
      display: "flex",
    },

    table: {
      ".ms-List-cell:nth-child(odd) .ms-DetailsRow": {
        background: theme.isDark
          ? "rgb(2, 7, 10)"
          : theme.palette.themeLighterAlt,
      },
      ".ms-DetailsRow ": {
        height: "30px !important",
      },

      ".ms-List-cell:hover, .ms-List-cell .is-selected": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(208, 231, 248) !important",
      },
      ".ms-List-cell .ms-DetailsRow:hover": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(208, 231, 248) !important",
      },

      ".ms-DetailsHeader-cellTitle": {
        justifyContent: "flex-start",
      },
      "[data-item-key='fleetName'] span": {
        justifyContent: "flex-start",
      },

      ".ms-DetailsRow-cell": {
        display: "flex",
        justifyContent: "flex-start",
      },
      ".ms-DetailsRow-cell:first-child": {
        display: "flex",
        justifyContent: "flex-start",
      },

      ".ms-DetailsHeader-cellName i": {
        marginRight: "5px",
        fontSize: "smaller",
        fontWeight: "400",
      },
    },
  });

export default InsurerListTable;

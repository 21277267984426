import { useMutation } from "react-query";
import { changeVehiclePolicyStatus } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const changeVehiclePolicyStatusFn = async (params) => {
  return await changeVehiclePolicyStatus(params);
};

export function useChangeVehiclePolicyStatus() {
  return useMutation(changeVehiclePolicyStatusFn, {
    mutationKey: MUTATION_KEYS.changeVehiclePolicyStatus,
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { getUserDetails } from "./GraphService";

export class UserDataLoader {
  private static instance: UserDataLoader;
  private user!: Promise<MicrosoftGraph.User>;

  private constructor() {}

  public static async getUserData(): Promise<MicrosoftGraph.User> {
    if (!UserDataLoader.instance) {
      UserDataLoader.instance = new UserDataLoader();
    }

    if (!UserDataLoader.instance.user) {
      UserDataLoader.instance.user = new Promise<MicrosoftGraph.User>(
        (resolve, reject) => {
          getUserDetails()
            .then((user) => {
              resolve(user);
            })
            .catch(() => {
              reject({});
            });
        }
      );
    }

    return UserDataLoader.instance.user;
  }
}

import { useMutation, useQueryClient } from "react-query";
import { createInsurerSettings } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS, QUERY_KEYS } from "../queryKeys";

const createInsurerSettingsFn = async (params) => {
  return await createInsurerSettings(params);
};

export function useCreateInsurerSettings() {
  const queryClient = useQueryClient();

  return useMutation(createInsurerSettingsFn, {
    mutationKey: MUTATION_KEYS.createInsurerSettings,
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.insurerListByCountry);
      queryClient.invalidateQueries(QUERY_KEYS.insurerListSettings);
    },
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { Dialog, DialogFooter, Icon } from "@fluentui/react";
import { Form, Formik } from "formik";
import { useUpdateVehiclePremiumBookingIssuingStatus } from "hooks/data/mutations/useUpdateVehiclePremiumBookingIssuingStatus";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import { TFunction } from "i18next";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { TaxonomyState } from "store/taxonomy";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import * as yup from "yup";
import { RefProvider } from "../../../contexts/RefProvider";
import { useSelector } from "../../../store/hooks";
import { closeDialog, setOperationRetVal } from "../../../store/timeline";
import { setYupLocale } from "../../../utils/setYupLocale";
import { removeEmpty } from "../../../utils/utils";
import { toast } from "../../FluentToast";
import { FormSpinner } from "../../FormSpinner";
import { transformForServer } from "../../form/transformForServer";
import { Flex, HSpace, StyledPrimaryButton } from "../../styled";
import { ModifyBookingDialogContents } from "./ModifyBookingDialogContents";
import { modifyBookingDialogConfig } from "./modifyBookingDialogConfig";
import { validationObjectConfig } from "./modifyBookingDialogValidation";

export const ModifyBookingDialog = () => {
  const theme = useTheme() as IStyledTheme;
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const phraseActivated = usePhraseActivationStatus().activated;
  const item = useSelector((s) => s.timeline.dialog.item);

  const { mutate: oneUpdateVehiclePremiumBookingIssuingStatus, isLoading } =
    useUpdateVehiclePremiumBookingIssuingStatus();

  const schema = useMemo(() => {
    setYupLocale(t);
    let yupObject = validationObjectConfig();
    return yup.object(yupObject);
  }, [t]);
  const rowVersion = useSelector((s) => s.vehicle.vehicle?.rowVersion);

  const taxonomy = useSelector((s) => s.taxonomy);

  const onSubmit = (data) => {
    let result = transformForServer({
      obj: data,
      config: modifyBookingDialogConfig,
    }) as any;
    if (result.vehicleIssuingStatusCode !== 2) {
      result.vehicleIssuingSubstatusCode = null;
    }
    result = removeEmpty(result);
    let objectToSend = {
      fleetId: item.associatedObject.fleetId as any,
      vehicleId: item.associatedObject.vehicleId as any,
      vehiclePremiumBookingId: item.associatedObject
        .vehiclePremiumBookingId as any,
      body: {
        ...result,
      },
      onSuccess: () => {
        toast.success(t("bfm.success"));
        dispatch(closeDialog());
      },
    };

    oneUpdateVehiclePremiumBookingIssuingStatus(
      {
        rowVersion,
        params: objectToSend,
      },
      {
        onSuccess: (res) => {
          toast.success(t("bfm.success"));
          dispatch(setOperationRetVal(res.data));
          dispatch(closeDialog());
        },
      }
    );
  };
  const Title = (
    <Flex>
      <Icon
        iconName="CircleCorrectition"
        style={{
          color: theme.palette.green,
        }}
      />
      <HSpace />
      <div>{t("bfm.changeIssuingStatus.label")}</div>
    </Flex>
  );

  const initialValues = useMemo(() => {
    let initObj = transformForForm({
      obj: {
        vehicleIssuingStatusCode:
          item.associatedObject.vehicleIssuingStatusCode,
        vehicleIssuingSubstatusCode:
          item.associatedObject.vehicleIssuingSubstatusCode,
        vehicleIssuingComment: null,
      },
      config: modifyBookingDialogConfig,
      t,
      taxonomy,
    });
    return initObj;
  }, [t, taxonomy, item]);

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: Title,
        showCloseButton: true,
      }}
      minWidth={700}
      onDismiss={() => {
        dispatch(closeDialog());
      }}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <RefProvider>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={didTrySubmit}
          validateOnBlur={didTrySubmit}
          validationSchema={schema}
        >
          {({ submitForm }) => {
            return (
              <Form>
                <ModifyBookingDialogContents />
                <DialogFooter>
                  <StyledPrimaryButton
                    text={t("greco.save")}
                    onClick={() => {
                      setDidTrySubmit(true);
                      submitForm();
                    }}
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </DialogFooter>
                {isLoading && <FormSpinner />}
              </Form>
            );
          }}
        </Formik>
      </RefProvider>
    </Dialog>
  );
};

export const transformForForm = ({
  obj,
  config,
  t,
  taxonomy,
}: {
  obj: { [key: string]: any };
  config: any;
  t: TFunction;
  taxonomy: TaxonomyState;
}) => {
  return Object.entries(obj).reduce((result, [key, value]) => {
    const fieldConfig = config[key];
    if (!fieldConfig) {
      result[key] = value;
      return result;
    }
    if (fieldConfig.type === "date") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      result[key] = new Date(value);
    }
    //
    else if (fieldConfig.type === "number") {
      if (value === null || value === undefined) {
        result[key] = "";
        return result;
      }
      if (fieldConfig.format === "integer") {
        result[key] = value.toString();
      }
      //
      else {
        result[key] = value.toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    }
    //
    else if (fieldConfig.type === "taxonomy") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      //
      const taxonomyObject = taxonomy[fieldConfig.taxonomyKey]?.byId[value];
      if (!taxonomyObject) {
        result[key] = null;
        return result;
      }
      result[key] = {
        label: t(taxonomyObject.code),
        value,
      };
    } else if (fieldConfig.type === "hulltypecombobox") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      //
      value === true
        ? (result[key] = {
            label: t("bfm.vehicleForm.hullType.basic.label"),
            value: "basic",
          })
        : (result[key] = {
            label: t("bfm.vehicleForm.hullType.full.label"),
            value: "full",
          });
    }
    //
    else if (fieldConfig.type === "text") {
      result[key] = value === null || value === undefined ? "" : value;
    }
    //
    else if (fieldConfig.type === "checkbox") {
      result[key] = value;
    }
    return result;
  }, {});
};

import { Dialog, DialogFooter, Icon } from "@fluentui/react";
import { Form, Formik } from "formik";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { RefProvider } from "../../../contexts/RefProvider";
import { useSelector } from "../../../store/hooks";
import { toast } from "../../FluentToast";
import { FormSpinner } from "../../FormSpinner";
import { transformForServer } from "../../form/transformForServer";
import { Flex, HSpace, StyledPrimaryButton } from "../../styled";

import { useChangeVehicleLicensePlate } from "hooks/data/mutations/useChangeVehicleLicensePlate";
import { useCorrectVehicleLicensePlate } from "hooks/data/mutations/useCorrectVehicleLicensePlate";
import { useDeleteVehicleLicensePlate } from "hooks/data/mutations/useDeleteVehicleLicensePlate";
import { useInsertVehicleLicensePlate } from "hooks/data/mutations/useInsertVehicleLicensePlate";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { closeDialog, setOperationRetVal } from "../../../store/timeline";
import { TimelineDialogType } from "../../../types/types";
import { setYupLocale } from "../../../utils/setYupLocale";
import { removeEmpty } from "../../../utils/utils";
import { ModifyLicensePlateDialogContents } from "./ModifyLicensePlateDialogContents";
import { modifyLicensePlateDialogConfig } from "./modifyLicensePlateDialogConfig";
import { validationObjectConfig } from "./modifyLicensePlateDialogValidation";
import { transformForForm } from "./transformForForm";

export enum ModifyLicensePlateDialogType {
  Insert,
  Correct,
  Delete,
  Cancel,
}

export const ModifyLicensePlateDialog = () => {
  const theme = useTheme() as IStyledTheme;
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const phraseActivated = usePhraseActivationStatus().activated;

  const item = useSelector((s) => s.timeline.dialog.item);
  const validFrom = useSelector((s) => s.timeline.dialog.date);
  const validTo = useSelector((s) => s.timeline.dialog.item.endDate);
  const dialogType = useSelector((s) => s.timeline.dialog.type);
  const rowVersion = useSelector((s) => s.vehicle.vehicle?.rowVersion);

  const {
    mutate: onCorrectVehicleLicensePlate,
    isLoading: correctVehicleLicensePlateInProgress,
  } = useCorrectVehicleLicensePlate();

  const {
    mutate: onInsertVehicleLicensePlate,
    isLoading: insertVehicleLicensePlateInProgress,
  } = useInsertVehicleLicensePlate();
  const {
    mutate: onDeleteVehicleLicensePlate,
    isLoading: deleteVehicleLicensePlateInProgress,
  } = useDeleteVehicleLicensePlate();

  const {
    mutate: onChangeVehicleLicensePlate,
    isLoading: changeVehicleLicensePlateInProgress,
  } = useChangeVehicleLicensePlate();

  const handleSuccess = (res) => {
    dispatch(setOperationRetVal(res?.data));
    toast.success(t("bfm.success"));
    dispatch(closeDialog());
  };

  const schema = useMemo(() => {
    setYupLocale(t);
    let yupObject = validationObjectConfig(item, dialogType);
    return yup.object(yupObject);
  }, [t, dialogType, item]);

  const isLoading =
    correctVehicleLicensePlateInProgress ||
    insertVehicleLicensePlateInProgress ||
    deleteVehicleLicensePlateInProgress ||
    changeVehicleLicensePlateInProgress;
  const vehicle = useSelector((s) => s.vehicle.vehicle);

  const taxonomy = useSelector((s) => s.taxonomy);
  const insurerList = useSelector((s) => s.vehicle.insurerList);

  const onSubmit = (data) => {
    let result = transformForServer({
      obj: data,
      config: modifyLicensePlateDialogConfig,
    }) as any;
    result = removeEmpty(result);
    let objectToSend: any = {
      vehicleId: vehicle.vehicleId as any,
      vehicleLicensePlateId: item?.associatedObject
        ?.vehicleLicensePlateId as any,
      fleetId: vehicle.fleetId as any,

      body: {
        ...result,
        vehicleLicensePlateId: item?.associatedObject?.vehicleLicensePlateId,
      },
    };
    if (dialogType === TimelineDialogType.CORRECT_LICENSE_PLATE) {
      onCorrectVehicleLicensePlate(
        {
          rowVersion,
          params: objectToSend,
        },
        {
          onSuccess: handleSuccess,
        }
      );
    }
    if (dialogType === TimelineDialogType.INSERT_LICENSE_PLATE) {
      onInsertVehicleLicensePlate(
        {
          rowVersion,
          params: objectToSend,
        },
        {
          onSuccess: handleSuccess,
        }
      );
    }
    if (dialogType === TimelineDialogType.DELETE_LICENSE_PLATE) {
      onDeleteVehicleLicensePlate(
        {
          rowVersion,
          params: objectToSend,
        },
        {
          onSuccess: handleSuccess,
        }
      );
    }
    if (dialogType === TimelineDialogType.CHANGE_LICENSE_PLATE) {
      onChangeVehicleLicensePlate(
        {
          rowVersion,
          params: objectToSend,
        },
        {
          onSuccess: handleSuccess,
        }
      );
    }
  };
  const Title = (
    <Flex>
      <Icon
        iconName="CircleCorrectition"
        style={{
          color: theme.palette.green,
        }}
      />
      <HSpace />
      <div>
        {dialogType === TimelineDialogType.CORRECT_LICENSE_PLATE
          ? t("bfm.correctLicensePlate")
          : null}
        {dialogType === TimelineDialogType.INSERT_LICENSE_PLATE
          ? t("bfm.insertLicensePlate")
          : null}
        {dialogType === TimelineDialogType.DELETE_LICENSE_PLATE
          ? t("bfm.deleteLicensePlate")
          : null}
        {dialogType === TimelineDialogType.CHANGE_LICENSE_PLATE
          ? t("bfm.changeLicensePlate")
          : null}
      </div>
    </Flex>
  );

  const initialValues = useMemo(() => {
    let initObj = transformForForm({
      obj: {
        vehicleLicensePlateId: item?.associatedObject?.vehicleLicensePlateId,
        licensePlate: item?.associatedObject?.licensePlate,
        validFromDate: validFrom,
        validToDate: validTo,
      },
      config: modifyLicensePlateDialogConfig,
      t,
      taxonomy,
      insurerList,
      possibleMainVehicles: null,
    });
    initObj = {
      ...initObj,
    };
    return initObj;
  }, [t, taxonomy, insurerList, item, validFrom, validTo]);

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: Title,
        showCloseButton: true,
      }}
      minWidth={400}
      onDismiss={() => {
        dispatch(closeDialog());
      }}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <RefProvider>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={didTrySubmit}
          validateOnBlur={didTrySubmit}
          validationSchema={schema}
        >
          {({ submitForm }) => {
            return (
              <Form>
                <ModifyLicensePlateDialogContents dialogType={dialogType} />
                <DialogFooter>
                  <StyledPrimaryButton
                    text={t("greco.save")}
                    onClick={() => {
                      setDidTrySubmit(true);
                      submitForm();
                    }}
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </DialogFooter>
                {isLoading && <FormSpinner />}
              </Form>
            );
          }}
        </Formik>
      </RefProvider>
    </Dialog>
  );
};

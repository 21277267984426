import React from "react";
import BookingDetails from "./BookingDetails/BookingDetails";
import { BookingSummary } from "./BookingSummary";

export const Accident = () => {
  return (
    <>
      <BookingSummary premiumType="ACCIDENT" />
      <BookingDetails premiumType="ACCIDENT" />
    </>
  );
};

import debounce from "debounce-promise";
import React from "react";
import { useDispatch } from "react-redux";
import { PeoplePicker } from "../../../../../components/PeoplePicker";
import { searchUsers } from "../../../../../store/api/api";
import { setTempUsers } from "../../../../../store/fleetForm";
import { useSelector } from "../../../../../store/hooks";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

export const Search = () => {
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;
  const users = useSelector((s) => s.fleetForm.tempUsers);
  const dispatch = useDispatch();

  return (
    <PeoplePicker
      value={null}
      onChange={(value) => {
        if (!value) return;
        const user = value[0];
        if (!user) return;
        dispatch(
          setTempUsers(
            users.concat({
              ...user,
              isInternal: /@greco.services$/.test(user.userEmailAddress),
            })
          )
        );
      }}
      loadOptions={debounce(async (input) => {
        const trimmedFilter = input.trim();
        if (trimmedFilter.length === 0) return [];
        const usersResult = await searchUsers(trimmedFilter);
        return usersResult
          .filter(
            (userResult) => !users.find((u) => u.userId === userResult.userId)
          )
          .map((u) => {
            return u;
          });
      }, 300)}
      styles={{
        menuPortal: (p) => ({
          ...p,
          background: theme.palette.neutralLighter,
          zIndex: 9999999,
        }),
        menu: (p) => ({
          ...p,
          zIndex: 9999,
          background: theme.palette.neutralLighter,
        }),
        input: (p) => ({
          ...p,
          color: (function () {
            if (isDarkMode) {
              return "white";
            }
            return theme.palette.black;
          })(),
        }),
        control: (p) => ({
          ...p,
          borderColor: "#ccc",
          "&:hover": {
            borderColor: "#ccc",
          },
          minWidth: "300px",
          background: theme.palette.white,
        }),
        option: (p, state) => ({
          ...p,
          background: theme.palette.white,
          color: theme.palette.white,
          "&:hover": {
            background: theme.palette.neutralLighter,
          },
        }),
        noOptionsMessage: (p, state) => ({
          ...p,
          background: theme.palette.white,
        }),
      }}
    />
  );
};

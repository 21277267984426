import { Dialog, DialogFooter, Icon } from "@fluentui/react";
import { Form, Formik } from "formik";
import { useActivateSharedLicensePlate } from "hooks/data/mutations/useActivateSharedLicensePlate";
import { useCancelSharedLicensePlate } from "hooks/data/mutations/useCancelSharedLicensePlate";
import { useContinueSharedLicensePlate } from "hooks/data/mutations/useContinueSharedLicensePlate";
import { useCorrectSharedLicensePlate } from "hooks/data/mutations/useCorrectSharedLicensePlate";
import { useDeleteSharedLicensePlate } from "hooks/data/mutations/useDeleteSharedLicensePlate";
import { useInsertSharedLicensePlate } from "hooks/data/mutations/useInsertSharedLicensePlate";
import { useSuspendSharedLicensePlate } from "hooks/data/mutations/useSuspendSharedLicensePlate";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import * as yup from "yup";
import { RefProvider } from "../../../contexts/RefProvider";
import { useSelector } from "../../../store/hooks";
import { closeDialog, setOperationRetVal } from "../../../store/timeline";
import {
  ETimelineExtraDataLineSpecial,
  PremiumType,
  TimelineDialogType,
  TimelineSegment,
} from "../../../types/types";
import { setYupLocale } from "../../../utils/setYupLocale";
import { removeEmpty } from "../../../utils/utils";
import { toast } from "../../FluentToast";
import { FormSpinner } from "../../FormSpinner";
import { transformForServer } from "../../form/transformForServer";
import { Flex, HSpace, StyledPrimaryButton } from "../../styled";
import { ModifySharedLicensePlateDialogContents } from "./ModifySharedLicensePlateDialogContents";
import { modifySharedLicensePlateDialogConfig } from "./modifySharedLicensePlateDialogConfig";
import { validationObjectConfig } from "./modifySharedLicensePlateDialogValidation";
import { transformForForm } from "./transformForForm";
export enum ModifyPremiumDialogType {
  Correct,
  Adjust,
  Update,
  Cancel,
  Delete,
  Continue,
}

export const ModifySharedLicensePlateDialog = () => {
  const theme = useTheme() as IStyledTheme;

  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const phraseActivated = usePhraseActivationStatus().activated;
  const item = useSelector((s) => s.timeline.dialog.item);
  const dialogType = useSelector((s) => s.timeline.dialog.type);
  const premiumTypeKey = useSelector(
    (s) => s.timeline.dialog.item.domain.value
  );

  const {
    mutate: onInsertSharedLicensePlate,
    isLoading: insertSharedLPInProgress,
  } = useInsertSharedLicensePlate();
  const {
    mutate: onCancelSharedLicensePlate,
    isLoading: cancelSharedLPInProgress,
  } = useCancelSharedLicensePlate();

  const {
    mutate: onCorrectSharedLicensePlate,
    isLoading: correctSharedLPInProgress,
  } = useCorrectSharedLicensePlate();
  const {
    mutate: onDeleteSharedLicensePlate,
    isLoading: deleteSharedLPInProgress,
  } = useDeleteSharedLicensePlate();

  const {
    mutate: onContinueSharedLicensePlate,
    isLoading: continueSharedLPInProgress,
  } = useContinueSharedLicensePlate();
  const {
    mutate: onActivateSharedLicensePlate,
    isLoading: activateSharedLPInProgress,
  } = useActivateSharedLicensePlate();

  const {
    mutate: onSuspendSharedLicensePlate,
    isLoading: suspendSharedLPInProgress,
  } = useSuspendSharedLicensePlate();

  const handleSuccess = (res) => {
    dispatch(setOperationRetVal(res?.data));
    toast.success(t("bfm.success"));
    dispatch(closeDialog());
  };

  const date = useSelector((s) => s.timeline.dialog.date);
  const isLoading =
    insertSharedLPInProgress ||
    cancelSharedLPInProgress ||
    correctSharedLPInProgress ||
    deleteSharedLPInProgress ||
    continueSharedLPInProgress ||
    activateSharedLPInProgress ||
    suspendSharedLPInProgress;

  const schema = useMemo(() => {
    setYupLocale(t);
    let yupObject = validationObjectConfig(item, dialogType, premiumTypeKey);
    return yup.object(yupObject);
  }, [t, premiumTypeKey, dialogType, item]);

  const vehicle = useSelector((s) => s.vehicle?.vehicle);
  const fleetId = useSelector((s) => s.fleetForm?.fleetWithPremiums?.fleetId);
  const possibleVehicles = useSelector(
    (s) => s.timeline.dialog.item.extraData?.possibleVehicles
  );

  const taxonomy = useSelector((s) => s.taxonomy);

  const onSubmit = (data) => {
    let result = transformForServer({
      obj: data,
      config: modifySharedLicensePlateDialogConfig,
    }) as any;
    result = removeEmpty(result);
    let premiumTypeKeyFinal = premiumTypeKey;
    if (
      premiumTypeKeyFinal === PremiumType.HULL &&
      item.extraData.lineSpecial === ETimelineExtraDataLineSpecial.BASIC
    ) {
      premiumTypeKeyFinal = PremiumType.HULL_BASIC;
    }
    if (
      premiumTypeKeyFinal === PremiumType.FLEETLEGAL_PRORATA &&
      item.extraData.lineSpecial === ETimelineExtraDataLineSpecial.CLOSING_DATE
    ) {
      premiumTypeKeyFinal = PremiumType.FLEETLEGAL_CLOSINGDATE;
    }

    let objectToSend = {
      mainVehicleForSharedLicensePlateId:
        item.associatedObject.mainVehicleForSharedLicensePlateId,
      mainVehicleId: item.associatedObject?.mainVehicleId,
      licensePlate: item.associatedObject?.licensePlate,
      fleetId: vehicle ? vehicle.fleetId : (fleetId as any),
      body: {
        ...result,
        premiumType: taxonomy.PremiumType.byCode[`${premiumTypeKeyFinal}`]?.id,
      },
    };
    if (dialogType === TimelineDialogType.INSERT_SHARED_LICENSE_PLATE) {
      onInsertSharedLicensePlate(objectToSend, {
        onSuccess: handleSuccess,
      });
    }
    if (dialogType === TimelineDialogType.CANCEL_SHARED_LICENSE_PLATE) {
      onCancelSharedLicensePlate(objectToSend, {
        onSuccess: handleSuccess,
      });
    }
    if (dialogType === TimelineDialogType.CORRECT_SHARED_LICENSE_PLATE) {
      onCorrectSharedLicensePlate(objectToSend, {
        onSuccess: handleSuccess,
      });
    }
    if (dialogType === TimelineDialogType.DELETE_SHARED_LICENSE_PLATE) {
      onDeleteSharedLicensePlate(objectToSend, {
        onSuccess: handleSuccess,
      });
    }
    if (dialogType === TimelineDialogType.CONTINUE_SHARED_LICENSE_PLATE) {
      onContinueSharedLicensePlate(objectToSend, {
        onSuccess: handleSuccess,
      });
    }
    if (dialogType === TimelineDialogType.ACTIVATE_SHARED_LICENSE_PLATE) {
      onActivateSharedLicensePlate(objectToSend, {
        onSuccess: handleSuccess,
      });
    }
    if (dialogType === TimelineDialogType.SUSPEND_SHARED_LICENSE_PLATE) {
      onSuspendSharedLicensePlate(objectToSend, {
        onSuccess: handleSuccess,
      });
    }
  };

  const Title = (
    <Flex>
      <Icon
        iconName="CircleCorrectition"
        style={{
          color: theme.palette.green,
        }}
      />
      <HSpace />
      <div>
        {dialogType === TimelineDialogType.INSERT_SHARED_LICENSE_PLATE
          ? t("bfm.addSharedLicensePlate")
          : null}{" "}
        {dialogType === TimelineDialogType.CANCEL_SHARED_LICENSE_PLATE
          ? t("bfm.cancelSharedLicensePlate")
          : null}{" "}
        {dialogType === TimelineDialogType.CORRECT_SHARED_LICENSE_PLATE
          ? t("bfm.correctSharedLicensePlate")
          : null}
        {dialogType === TimelineDialogType.DELETE_SHARED_LICENSE_PLATE
          ? t("bfm.deleteSharedLicensePlate")
          : null}{" "}
        {dialogType === TimelineDialogType.CONTINUE_SHARED_LICENSE_PLATE
          ? t("bfm.continueSharedLicensePlate")
          : null}{" "}
        {dialogType === TimelineDialogType.ACTIVATE_SHARED_LICENSE_PLATE
          ? t("bfm.activateSharedLicensePlate")
          : null}{" "}
        {dialogType === TimelineDialogType.SUSPEND_SHARED_LICENSE_PLATE
          ? t("bfm.suspendSharedLicensePlate")
          : null}{" "}
      </div>
    </Flex>
  );
  const initialValues = useMemo(() => {
    let validToDate = initializeValidToDate(item, dialogType);

    let initObj = transformForForm({
      obj: {
        mainVehicleId: item.associatedObject?.vehicleShortInfo?.vehicleId,
        premiumTypeCode: item.extraData?.premiumTypeCode
          ? taxonomy.PremiumType.byCode[item.extraData?.premiumTypeCode].id
          : null,
        licensePlate: item.associatedObject?.licensePlate,
        reasonForModificationComment: null,
        validFromDate:
          dialogType === TimelineDialogType.CANCEL_SHARED_LICENSE_PLATE ||
          dialogType === TimelineDialogType.ACTIVATE_SHARED_LICENSE_PLATE ||
          dialogType === TimelineDialogType.SUSPEND_SHARED_LICENSE_PLATE
            ? date
            : dialogType === TimelineDialogType.ADD_SHARED_LICENSE_PLATE ||
              dialogType === TimelineDialogType.INSERT_SHARED_LICENSE_PLATE
            ? item.startDate
            : moment(item.associatedObject?.validFromDate).toDate(),
        validToDate: validToDate,
      },
      config: modifySharedLicensePlateDialogConfig,
      t,
      taxonomy,
      insurerList: null,
      possibleMainVehicles: possibleVehicles,
    });
    initObj = {
      ...initObj,
    };
    return initObj;
  }, [t, taxonomy, date, item, dialogType, possibleVehicles]);

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: Title,
        showCloseButton: true,
      }}
      minWidth={700}
      onDismiss={() => {
        dispatch(closeDialog());
      }}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <RefProvider>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={didTrySubmit}
          validateOnBlur={didTrySubmit}
          validationSchema={schema}
        >
          {({ submitForm }) => {
            return (
              <Form>
                <ModifySharedLicensePlateDialogContents
                  dialogType={dialogType}
                />
                <DialogFooter>
                  <StyledPrimaryButton
                    text={t("greco.save")}
                    onClick={() => {
                      setDidTrySubmit(true);
                      submitForm();
                    }}
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </DialogFooter>
                {isLoading && <FormSpinner />}
              </Form>
            );
          }}
        </Formik>
      </RefProvider>
    </Dialog>
  );
};
const initializeValidToDate = (
  item: TimelineSegment,
  dialogType: TimelineDialogType
): Date => {
  let validToDate = null;
  if (item.associatedObject?.isContinuing) {
    validToDate = null;
  } else if (dialogType === TimelineDialogType.ACTIVATE_SHARED_LICENSE_PLATE) {
    validToDate = item.endDate;
  } else {
    validToDate = item.associatedObject?.validToDate;
  }
  return validToDate;
};

import { isExternalOrReadonlyAdmin, isFullAdminOrAnyInternal } from "form/util";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../store/hooks";
import { openDialog } from "../../../store/timeline";
import {
  TimelineDialogType,
  TimelineSegmentType,
  TimelineType,
  UserRole,
} from "../../../types/types";

export const useBookingMenuItems = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const segmentContextMenuItem = useSelector(
    (s) => s.timeline.contextMenu.item
  );
  const segmentContextMenuDate = useSelector(
    (s) => s.timeline.contextMenu.date
  );
  const userRole = useSelector((s) => s.auth.userRole);

  const segmentContextMenuItemDomainType = useSelector(
    (s) => s.timeline.contextMenu.item?.domain.type
  );

  const showBookingsWithDocumentDate = useSelector(
    (s) => s.timeline.timeline.showBookingsWithDocumentDate
  );

  return useMemo(() => {
    const items = [];
    if (!showBookingsWithDocumentDate) {
      return [];
    }
    if (segmentContextMenuItemDomainType !== TimelineType.VEHICLE_PREMIUM) {
      return [];
    }
    if (isExternalOrReadonlyAdmin(userRole)) return [];
    if (segmentContextMenuItem.segmentType === TimelineSegmentType.BOOKING) {
      items.push({
        key: "change issuing status",
        text: t("bfm.changeIssuingStatus.label"),
        onClick: () => {
          dispatch(
            openDialog({
              dialogType: TimelineDialogType.CHANGE_BOOKING,
              contextMenuItem: segmentContextMenuItem,
              contextMenuDate: segmentContextMenuDate,
            })
          );
        },
      });
    }
    return items;
  }, [
    dispatch,
    t,
    showBookingsWithDocumentDate,
    segmentContextMenuDate,
    segmentContextMenuItem,
    segmentContextMenuItemDomainType,
    userRole,
  ]);
};

import { mergeStyleSets, MessageBar, MessageBarType } from "@fluentui/react";
import { toast } from "components/FluentToast";
import FormSubmitSpinner from "components/FormSubmitSpinner";
import { Formik } from "formik";
import { useCreateTradeLicensePlate } from "hooks/data/mutations/useCreateTradeLicensePlate";
import { useCreateVehicle } from "hooks/data/mutations/useCreateVehicle";
import { useUpdateTempVehicle } from "hooks/data/mutations/useUpdateTempVehicle";
import { useUpdateVehicle } from "hooks/data/mutations/useUpdateVehicle";
import { constants } from "library/constants";
import { delay } from "library/delay";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { clearClientFromInternalNumber } from "store/slices/fleetFormikFormSlice";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { UserRole } from "types/types";
import * as yup from "yup";
import * as Colors from "../../config/colors";
import { routes, useCurrentRoute } from "../../config/routes";
import { useSelector } from "../../store/hooks";
import * as TimelineSlice from "../../store/timeline";
import * as VehicleSlice from "../../store/vehicle";
import { setYupLocale } from "../../utils/setYupLocale";
import { transformVehiclePayload } from "./helpers";
import { useFetchDefaultSettings } from "./hooks/useFetchDefaultSettings";
import useFetchFleetInsurerSettings from "./hooks/useFetchFleetInsurerSettings";
import { useFetchInsurerSettings } from "./hooks/useFetchInsurerSettings";
import { useFetchVehicle } from "./hooks/useFetchVehicle";
import useTabChange from "./hooks/useTabChange";
import ImportScanDialog from "./ImportScanDialog/ImportScanDialog";
import MainFields from "./MainFields/MainFields";
import { Navigation } from "./navigation/Navigation";
import Sidebar from "./Sidebar/Sidebar";
import { vehicleConfig } from "./vehicleConfig";
import VehicleForm from "./VehicleForm";
import VehicleFormErrors from "./VehicleFormErrors";
import VehicleSkeleton from "./VehicleSkeleton";

export type VehicleTab =
  | "general-vehicle-form"
  | "vehicle-form"
  | "finance-vehicle-form";

const Vehicle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const route = useCurrentRoute();
  const vehicle = useSelector((s) => s.vehicle?.vehicle);
  const fleet = useSelector((s) => s.vehicle.fleet);
  const taxonomy = useSelector((s) => s.taxonomy);
  const insurerList = useSelector((s) => s.vehicle.insurerList);
  const isImportScanDocumentDialogOpened = useSelector(
    (s) => s.vehicle.importScanDocumentDialog.isOpen
  );
  const [didTrySubmit, setDidTrySubmit] = useState(false);

  const { mutate: onUpdateVehicle, isLoading: updateVehicleInProgress } =
    useUpdateVehicle();

  const { mutate: onCreateVehicle, isLoading: createVehicleInProgress } =
    useCreateVehicle();

  const { mutate: onCreateTradeLicensePlate, isLoading: createTLPInProgress } =
    useCreateTradeLicensePlate();

  const {
    mutate: onUpdateTempVehicle,
    isLoading: updateTempVehicleInProgress,
  } = useUpdateTempVehicle();

  const [goToEdit, setGoToEdit] = useState(false);

  const { createVehicle, editVehicle, duplicateVehicle, editTempVehicle } =
    constants;
  const isTradeLicensePlate =
    route === "createTradeVehicle" || vehicle?.isTradeLicensePlate;

  const isLoading =
    useSelector(VehicleSlice.selectShouldDisableForm)(route) ||
    updateVehicleInProgress ||
    updateTempVehicleInProgress ||
    createVehicleInProgress ||
    createTLPInProgress;
  const scannedFormFields = useSelector((s) => s.vehicle.scannedFormFields);

  const theme = useTheme() as IStyledTheme;
  const generalFormRef = useRef(null);
  const vehicleFormRef = useRef(null);
  const { fleetId } = useParams() as any;
  useFetchVehicle();
  useFetchDefaultSettings();
  useFetchInsurerSettings();
  useFetchFleetInsurerSettings();

  useTabChange({ generalFormRef, vehicleFormRef });
  const scannedRegistrationDocumentPath = useSelector(
    (s) => s.vehicle?.scannedFormFields?.scannedRegistrationDocumentPath
  );
  const isCreateVehicle = route === createVehicle;
  const isDuplicateVehicle = route === duplicateVehicle;

  const userRole = useSelector((s) => s.auth.userRole);

  const vehicleStatusCode =
    taxonomy.VehicleStatusCode.byId[vehicle?.vehicleStatusCode]?.code;

  const status = Colors.vehicleStatus[vehicleStatusCode];

  const vehicleConf = useMemo(() => {
    // if (!vehicle) return;
    let v = vehicle || {};
    if (scannedFormFields) v = scannedFormFields;
    return vehicleConfig({
      vehicle: v,
      route,
      t,
      taxonomy,
      fleet,
      insurerList,
    });
  }, [route, t, taxonomy, vehicle, fleet, insurerList, scannedFormFields]);

  const classes = getClassNames(status, theme);

  useEffect(() => {
    if (
      route === "createVehicle" ||
      route === "createTradeVehicle" ||
      route === "editTempVehicle"
    ) {
      dispatch(
        VehicleSlice.loadFleetWithInsurersActions.trigger({
          fleetId,
          isVehiclePage: true,
        })
      );
    }
  }, [dispatch, fleetId, route]);

  const handleSubmit = async (values, goToEdit) => {
    let val = { ...values };

    if (scannedRegistrationDocumentPath) {
      val = { ...val, scannedRegistrationDocumentPath };
    }

    const payload = transformVehiclePayload({
      data: val,
      config: vehicleConf,
      vehicle,
      route,
    });
    if (isTradeLicensePlate) {
      if (route === editVehicle) {
        onUpdateVehicle(
          {
            ...payload,
            fleetId,
          },
          {
            onSuccess: (res) => {
              toast.success(t("bfm.successfullyUpdatedVehicle"));
              dispatch(VehicleSlice.setVehicle(res.data));
            },
          }
        );
      } else if (route === editTempVehicle) {
        onUpdateTempVehicle(
          {
            ...payload,
            vehicleId: vehicle.vehicleId,
            rowVersion: vehicle.rowVersion,
            fleetId,
          },
          {
            onSuccess: (res) => {
              dispatch(VehicleSlice.setVehicle(res.data));
              toast.success(t("bfm.successfullyUpdatedTEMPVehicle"));
            },
          }
        );
      } else {
        onCreateTradeLicensePlate(
          {
            ...payload,
            fleetId,
          },
          {
            onSuccess: (data) => {
              toast.success(t("bfm.successfullyCreatedVehicle"));
              const path = routes.readOnlyVehicle.getPath(
                data?.fleetId,
                data?.vehicleId
              );
              navigate(goToEdit ? `${path}/edit` : path);

              setGoToEdit(false);
            },
          }
        );
      }
      return;
    }
    if (route === createVehicle || route === duplicateVehicle) {
      onCreateVehicle(
        {
          ...payload,
          fleetId,
        },
        {
          onSuccess: (data) => {
            toast.success(t("bfm.successfullyCreatedVehicle"));
            const path = routes.readOnlyVehicle.getPath(
              data?.fleetId,
              data?.vehicleId
            );

            navigate(goToEdit ? `${path}/edit` : path);
            setGoToEdit(false);
          },
        }
      );
    }
    //
    else {
      if (route === editTempVehicle) {
        onUpdateTempVehicle(
          {
            ...payload,
            vehicleId: vehicle.vehicleId,
            rowVersion: vehicle.rowVersion,
            fleetId,
          },
          {
            onSuccess: (res) => {
              dispatch(VehicleSlice.setVehicle(res.data));
              toast.success(t("bfm.successfullyUpdatedTEMPVehicle"));
            },
          }
        );
      }

      //
      if (route === editVehicle) {
        onUpdateVehicle(
          {
            ...payload,
            vehicleId: vehicle.vehicleId,
            rowVersion: vehicle.rowVersion,
            fleetId,
          },
          {
            onSuccess: (res) => {
              dispatch(VehicleSlice.setVehicle(res.data));
              toast.success(t("bfm.successfullyUpdatedVehicle"));
            },
          }
        );
      }
    }
  };

  const validationSchema = useMemo(() => {
    const schemaObj = {};
    vehicleConf?.forEach((configItem) => {
      schemaObj[configItem.name] = configItem.validation;
    });
    return yup.object().shape(schemaObj);
  }, [vehicleConf]);

  const initialValues = useMemo(() => {
    const initialValuesObj = {};
    vehicleConf?.forEach((configItem) => {
      initialValuesObj[configItem.name] = configItem.value;
    });
    return initialValuesObj;
  }, [vehicleConf]);

  const handleGoToEdit = useCallback((value) => {
    setGoToEdit(value);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(VehicleSlice.resetState());
      dispatch(TimelineSlice.resetState());
      dispatch(clearClientFromInternalNumber(null));
    };
  }, [dispatch]);

  useEffect(() => {
    setYupLocale(t);
  }, [t]);

  useEffect(() => {
    dispatch(VehicleSlice.setScannedFormFields(null));
  }, [vehicle]);

  if (
    (!vehicle &&
      route !== constants.createVehicle &&
      route !== constants.createTradeVehicle) ||
    !taxonomy ||
    !insurerList
  ) {
    return <VehicleSkeleton />;
  }
  return (
    <>
      {isLoading && <FormSubmitSpinner />}
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnChange={didTrySubmit}
        validateOnBlur={didTrySubmit}
        onSubmit={async (values, { setSubmitting }) => {
          handleSubmit(values, goToEdit);
          await delay(400);
          setSubmitting(false);
        }}
      >
        {(props) => {
          return (
            <>
              <div id="vehicle-form-wrapper" className={classes.main}>
                <form
                  onSubmit={(ev) => {
                    ev.preventDefault();
                    setDidTrySubmit(true);
                    props.handleSubmit();
                  }}
                >
                  <Navigation
                    setGoToEdit={handleGoToEdit}
                    disabled={
                      userRole === UserRole.adminreadonly || props.isSubmitting
                    }
                  />
                  <div>
                    {!isCreateVehicle && !isDuplicateVehicle && (
                      <MainFields formikProps={props} route={route} />
                    )}

                    <div className={classes.container}>
                      <Sidebar />
                      <div className={classes.fullWidth}>
                        {route === editTempVehicle && vehicle?.errorMessage && (
                          <MessageBar messageBarType={MessageBarType.error}>
                            {vehicle?.errorMessage}
                          </MessageBar>
                        )}
                        <VehicleForm
                          generalFormRef={generalFormRef}
                          vehicleFormRef={vehicleFormRef}
                          vehicle={vehicle}
                          props={props}
                          initialValues={initialValues}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <VehicleFormErrors
                errors={props.errors}
                configVehicle={vehicleConf}
                isSubmitting={props.isSubmitting}
              />
            </>
          );
        }}
      </Formik>{" "}
      {isImportScanDocumentDialogOpened ? <ImportScanDialog /> : null}
    </>
  );
};

const getClassNames = (status, theme) =>
  mergeStyleSets({
    container: {
      background: theme.palette.white,
      borderTop: `5px solid ${status}`,
      boxShadow:
        "0 3.2px 7.2px 0 rgba(0,0,0,.132), 0 0.6px 1.8px 0 rgba(0,0,0,.108)",
      display: "flex",
      margin: "50px auto 0",
      borderRadius: "4px",
      maxWidth: "80%",
      minWidth: "1200px",
      height: "90%", //
      position: "relative",
      marginBottom: "100px",
    },
    fullWidth: {
      width: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      flexWrap: "wrap",
    },
    main: {
      overflow: "auto", //
      height: "100%",
      position: "relative",
      paddingTop: 0,
      flex: 1,
    },
  });

export default Vehicle;

import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { Setting } from "types/types";
import { urlFormat } from "utils/utils";
import { MUTATION_KEYS, QUERY_KEYS } from "../queryKeys";

const uadUrl = urlFormat(process.env.REACT_APP_BFF_HOST_UAD);

export const addAppSetting = async (setting: Partial<Setting>) => {
  return await axios.post<Setting[]>(`${uadUrl}/addappsettings`, [setting]);
};

export function useAddAppSettings() {
  const queryClient = useQueryClient();

  return useMutation(addAppSetting, {
    mutationKey: MUTATION_KEYS.addAppSettings,
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.appSettings);
    },
    onError: (error) => {},
  });
}

import React from "react";
import moment from "moment";
import { timeFormat } from "d3";
import { useSelector } from "../../../../store/hooks";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

const tickFormat = timeFormat("%d.%m.%y");
const shortTickFormat = timeFormat("%m.%y");
const yearTickFormat = timeFormat("%Y");
export const AxisBottom = ({ innerHeight, tickOffset = 13, xScale }) => {
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;
  const minDate = useSelector((s) => s.timeline.timeline.dateFilter[0]);
  const maxDate = useSelector((s) => s.timeline.timeline.dateFilter[1]);

  const startDate = moment(minDate);
  const endDate = moment(maxDate);
  const result = [];
  while (startDate.isBefore(endDate) || startDate.isSame(endDate, "day")) {
    result.push(startDate.toDate());
    startDate.set("date", 1);
    startDate.add(1, "month");
  }

  const tickElements = result.map((tickValue, index) => (
    <g
      className={isDarkMode ? "tickdark" : "tick"}
      key={index}
      transform={`translate(${xScale(tickValue)},0)`}
    >
      <line y2={innerHeight} strokeWidth={1} />
      <text
        style={{ textAnchor: "middle" }}
        dy=".71em"
        y={innerHeight + tickOffset}
      >
        {result.length > 18
          ? moment(tickValue).get("month") === 0
            ? yearTickFormat(tickValue)
            : null
          : result.length > 9
          ? shortTickFormat(tickValue)
          : tickFormat(tickValue)}
      </text>
    </g>
  ));

  return <>{tickElements}</>;
};

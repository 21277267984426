import { Label } from "@fluentui/react";
import { FieldContainer } from "components/controls/FieldContainer";
import { useField, useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "store/hooks";
import {
  resetDialogDefaultValueOperation,
  resetDialogInsurerSettingsOperation,
  setDialogDefaultValueOperation,
  setDialogInsurerSettingsOperation,
} from "store/timeline";
import { VSpace } from "../Spacer";
import {
  ErrorMessage,
  Flex,
  StyledIconButton,
  StyledTextField,
} from "../styled";
import { useNumberFormat } from "../use-number-format/useNumberFormat";
import { NumberFieldConfig } from "./types";
import { useDynamicConfig } from "./useDynamicConfig";

type Props = { fieldConfig: NumberFieldConfig };

export const NumberField = ({ fieldConfig }: Props) => {
  const { values } = useFormikContext();

  const dispatch = useDispatch();
  const config = {
    ...fieldConfig,
    ...useDynamicConfig(fieldConfig.d),
  };
  const { t } = useTranslation();

  const {
    name,
    label,
    isVisible = true,
    isDisabled,
    format,
    isRequired,
    includeThousandSeparators,
    max,
    isNegativeAllowed,
    defaultValueFunction,
    insurerSettingsFunction,
    validate,
    background,
  } = config;

  const [field, { error }, { setValue }] = useField({ name, validate });

  const rifm = useNumberFormat({
    value: field.value,
    setValue,
    format,
    includeThousandSeparators,
    max,
    isNegativeAllowed,
  });

  const defaultValueOperation = useSelector(
    (s) => s.timeline.dialog.defaultValueOperation
  );

  const insurerSettingsOperation = useSelector(
    (s) => s.timeline.dialog.insurerSettingsOperation
  );

  useEffect(() => {
    if (
      insurerSettingsFunction &&
      insurerSettingsOperation?.fieldNames?.includes(name) &&
      insurerSettingsOperation.results &&
      insurerSettingsOperation.results[name]
    ) {
      setValue(insurerSettingsFunction(insurerSettingsOperation.results[name]));
      dispatch(resetDialogInsurerSettingsOperation(name));
    }
  }, [insurerSettingsOperation, name, insurerSettingsFunction]);

  const fieldNames = useMemo(() => {
    const retVal = [];
    defaultValueOperation?.fieldNames?.forEach((fieldName) => {
      if (fieldName === "deductible") {
        retVal.push("hullDeductibleMinAmount");
        retVal.push("hullDeductibleMaxAmount");
        retVal.push("hullDeductiblePercent");
        retVal.push("hullDeductibleComment");
      } else if (fieldName === "calculation") {
        retVal.push("annualGrossPremium");
      } else {
        retVal.push(fieldName);
      }
    });
    return retVal;
  }, [defaultValueOperation.fieldNames]);

  useEffect(() => {
    if (
      fieldNames?.includes(name) &&
      defaultValueOperation.results &&
      defaultValueOperation.results[name]
    ) {
      setValue(defaultValueFunction(defaultValueOperation.results[name]));
      dispatch(resetDialogDefaultValueOperation(name));
    }
  }, [defaultValueOperation, name, defaultValueFunction]);

  if (!isVisible) return null;

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <FieldContainer
        isTooltipHidden={isDisabled}
        tooltipText={label}
        isReadOnly={true}
      >
        <Label required={isRequired} disabled={isDisabled}>
          {t(label)}
        </Label>
        {false && (defaultValueFunction || insurerSettingsFunction) ? (
          <Flex gap={5} direction="row" style={{ width: "100%", flex: 1 }}>
            <StyledTextField
              id={name}
              style={{
                flex: 1,
              }}
              name={name}
              borderless
              disabled={isDisabled}
              hasError={!!error}
              background={background}
              autoComplete={"off"}
              {...field}
              {...(rifm as any)}
            />

            <StyledIconButton
              iconProps={{
                iconName: defaultValueFunction
                  ? "DoubleChevronLeft8"
                  : "Shield",
              }}
              title={t(
                defaultValueFunction
                  ? "bfm.fillInFleetDefaultsValue.label"
                  : "bfm.fillInInsurerSettingsValue.label"
              )}
              ariaLabel={t(
                defaultValueFunction
                  ? "bfm.fillInFleetDefaultsValue.label"
                  : "bfm.fillInInsurerSettingsValue.label"
              )}
              onClick={(e) => {
                if (defaultValueFunction) {
                  dispatch(
                    setDialogDefaultValueOperation({
                      fieldNames: [name],
                      formValues: values,
                    })
                  );
                } else if (insurerSettingsFunction) {
                  dispatch(
                    setDialogInsurerSettingsOperation({
                      fieldNames: [name],
                      formValues: values,
                    })
                  );
                }
              }}
            />
          </Flex>
        ) : (
          <StyledTextField
            style={{ flex: 1 }}
            id={name}
            name={name}
            borderless
            disabled={isDisabled}
            hasError={!!error}
            background={background}
            autoComplete={"off"}
            {...field}
            {...(rifm as any)}
          />
        )}
        {error && (
          <ErrorMessage id={field.name + "_error"}>{error}</ErrorMessage>
        )}
        <VSpace height={10} />
      </FieldContainer>
    </div>
  );
};

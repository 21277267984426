import { useMutation } from "react-query";
import {
  SharedLicensePlateRequestParams,
  cancelSharedLicensePlate,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const cancelSharedLicensePlateFn = async (
  params: SharedLicensePlateRequestParams
) => {
  return await cancelSharedLicensePlate(params);
};

export function useCancelSharedLicensePlate() {
  return useMutation(cancelSharedLicensePlateFn, {
    mutationKey: MUTATION_KEYS.cancelSharedLicensePlate,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import AppSettings, { InsurerSettingsTemplate } from "AppSettings";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import { UserRole } from "types/types";
import NumberInputField from "../NumberInputField";

const SuspensionAndActivationDays = ({
  props,
  classesTab,
  selectedInsurer,
}: any) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const userRole = useSelector((s) => s.auth.userRole);

  const insurerCountrySettings =
    AppSettings.getInstance().getAppSettingsTypes()[
      taxonomy.Country.byId[selectedInsurer?.countryCodeId]?.code
    ];
  if (!props.values || !insurerCountrySettings) {
    return null;
  }
  return (
    <>
      {insurerCountrySettings.INSURER_SETTINGS_TEMPLATES.includes(
        InsurerSettingsTemplate.SUSPENSION_AND_ACTIVATION_DAYS
      ) ? (
        <>
          <div className={classesTab.item}></div>
          <div className={classesTab.item}></div>
          <div className={classesTab.item}>
            {insurerCountrySettings.SUSPENSION_DAYS_INCLUDED ? (
              <NumberInputField
                setFieldValue={props.setFieldValue}
                value={props.values?.suspensionDays}
                errors={props.errors}
                onChange={props.handleChange}
                required
                name={"suspensionDays"}
                disabled={userRole !== UserRole.admin}
                label={"bfm.vehicleForm.suspensionDays.label"}
              />
            ) : null}
            {/* {suspensionDays} */}
          </div>
          <div className={classesTab.item}>
            {insurerCountrySettings.ACTIVATION_DAYS_INCLUDED ? (
              <NumberInputField
                setFieldValue={props.setFieldValue}
                value={props.values?.activationDays}
                errors={props.errors}
                onChange={props.handleChange}
                required
                name={"activationDays"}
                disabled={userRole !== UserRole.admin}
                label={"bfm.vehicleForm.activationDays.label"}
              />
            ) : null}

            {/* {activationDays} */}
          </div>
        </>
      ) : null}
    </>
  );
};

export default SuspensionAndActivationDays;

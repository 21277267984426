import FleetNavigation from "components/FleetNavigation";
import { NavigationBar } from "components/styled";
import React from "react";

const SharedLicensePlateNavigation = () => {
  return (
    <NavigationBar>
      <FleetNavigation />
    </NavigationBar>
  );
};

export default SharedLicensePlateNavigation;

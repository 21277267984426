import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { StyledPrimaryButton } from "../../../components/styled";
import { setVehicle } from "../../../store/vehicle";
import { useConfirmVehicleChanges } from "hooks/data/mutations/useConfirmVehicleChanges";

export const ConfirmChangesButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme() as IStyledTheme;

  const { fleetId, vehicleId } = useParams() as any;

  const { mutate: onConfirmVehicleChanges, isLoading } =
    useConfirmVehicleChanges();

  return (
    <StyledPrimaryButton
      text={t("bfm.navigation.confirmChanges")}
      iconProps={{ iconName: "CheckboxComposite" }}
      // background={theme.palette.green}
      style={{
        background: theme.palette.redDark,
      }}
      onClick={() => {
        onConfirmVehicleChanges(
          {
            fleetId,
            vehicleId,
          },
          {
            onSuccess(res) {
              console.log(res, "res herer");
              dispatch(setVehicle(res.data));
            },
          }
        );
      }}
      disabled={isLoading}
    />
  );
};

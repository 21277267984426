import { mergeStyleSets } from "@uifabric/merge-styles";
import TextInputField from "components/controls/TextInputField";
import { FormikValues } from "formik";
import { constants } from "library/constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatDateWithMoment } from "utils/utils";
import { useSelector } from "../../../store/hooks";

type Props = {
  formikProps: FormikValues;
  route: any;
};

const MainFields = ({ formikProps, route }: Props) => {
  const { t } = useTranslation();

  const { createTradeVehicle } = constants;
  const fleet = useSelector((s) => s.vehicle.fleet);
  const clientName = fleet?.clientName;
  const vehicle = useSelector((s) => s.vehicle.vehicle);
  const { values, errors } = formikProps;

  const isTradeLicensePlate =
    route === createTradeVehicle || vehicle?.isTradeLicensePlate;

  const classes = mergeStyleSets({
    mainFields: {
      display: "flex",
      margin: "50px auto 0",
      // position: "sticky",
      // top: 44,
      // zIndex: 999,
      // background: theme.palette.white,
      padding: 0,
      maxWidth: "80%",
    },
    gridContainer: {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "auto auto auto",
      gap: 20,
      padding: 0,
    },
    gridItem: {
      // padding: "20px",
    },
    commentItem: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
      gridRowStart: 3,
    },
    transparentBg: {
      background: "transparent",
    },
  });

  return (
    <div className={classes.mainFields}>
      <div className={classes.gridContainer}>
        {!isTradeLicensePlate && (
          <div className={classes.gridItem}>
            <TextInputField
              onChange={() => {}}
              errors={errors}
              value={clientName}
              name=""
              // transparent
              maxLength={128}
              disabled={true}
              key={values.vehicleUser}
              label={"bfm.vehicleForm.clientName.label"}
            />
          </div>
        )}
        <div className={classes.gridItem}>
          <TextInputField
            onChange={() => {}}
            value={values.licensePlate}
            errors={errors}
            key={values?.licensePlate}
            required
            maxLength={12}
            name=""
            disabled={true}
            label={"bfm.vehicleForm.licensePlate.label"}
          />
        </div>
        {!isTradeLicensePlate && (
          <div className={classes.gridItem}>
            <TextInputField
              onChange={() => {}}
              disabled={true}
              key={values?.brandCode?.label}
              errors={errors}
              name=""
              label={"bfm.vehicleForm.brandCode.label"}
              value={values?.brandCode?.label}
            />
          </div>
        )}
        <div className={classes.gridItem}>
          <TextInputField
            onChange={() => {}}
            errors={errors}
            key={values?.registrationDate}
            value={
              values.registrationDate &&
              formatDateWithMoment(values.registrationDate)
            }
            name=""
            required
            disabled={true}
            label={"bfm.vehicleForm.registrationDate.label"}
            placeholder={t("bfm.selectDate")}
          />
        </div>
        {!isTradeLicensePlate && (
          <div className={classes.gridItem}>
            <TextInputField
              onChange={() => {}}
              value={values.vin}
              key={values?.vin}
              errors={errors}
              maxLength={32}
              required
              // onChange={handleChange}
              name=""
              disabled={true}
              label={"bfm.vehicleForm.vin.label"}
            />
          </div>
        )}
        {!isTradeLicensePlate && (
          <div className={classes.gridItem}>
            <TextInputField
              onChange={() => {}}
              value={values.model}
              key={values?.model}
              errors={errors}
              maxLength={254}
              // onChange={handleChange}
              name=""
              disabled={true}
              label={"bfm.vehicleForm.model.label"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainFields;

export const Country_ALL = 0;
export const Country_AUSTRIA = 834;
export const Country_AUSTRIA_CODE = "AT";

export interface Client {
  parentClientId: number;
  parentClientInternalNumber: number;
  costCenterCodeId: number;
  defaultDueMonth: number;
  defaultDueDay: number;
  updateIfExists: boolean;
  clientId: number;
  clientInternalNumber: number;
  clientName: string;
  countryCodeId: number;
}

export interface Fleet {
  fleetLegalPolicyNumber: string;
  mainFleetLegalInsurerPartnerNumber: number;
  fleetId: number;
  fleetName: string;
  fleetLastModifiedAt: string;
  fleetLastModifiedByUserId: string;
  fleetCreatedAt: string;
  fleetCreatedByUserId: string;
  fleetCreatedByUserName: string;
  countryCode: number;
  webBasePartnerNumber: string;
  clientName: string;
  businessYearStartMonth: string;
  businessYearStartDay: string;
  dueMonth: string;
  dueDay: string;
  maxCalculationDate: Date;
  mainMtplInsurerPartnerName: string;
  mainMtplInsurerPartnerNumber: string;
  hasMultipleMtplInsurers: boolean;
  mainHullInsurerPartnerName: string;
  mainHullInsurerPartnerNumber: string;
  hasMultipleHullInsurers: boolean;
  fleetPolicyNumber: string;
  fleetComment: string;
  rowVersion: string;
}

export type CreateUpdateFleetData = {
  fleet: any;
  fleetUsers: FleetUser[];
  fleetVehiclesExcel?: {
    name: string;
    base64str: string;
  };
  businessYear?: number;
};

export type CreateUpdateFleetResponse = {
  fleet: Fleet;
  fleetUsers: FleetUser[];
  fleetVehiclesExcel: {
    name: string;
    base64str: string;
  } | null;
};

export type ExcelReportResponse = {
  name: string;
  url: string;
};

export interface VehicleAzure {
  fleetLegalIsPolicyIssued: boolean | null;
  fleetLegalAnnualGrossPremium?: number;
  fleetLegalIsClosingDate?: boolean;
  fleetLegalProRataGrossPremium?: number;
  hullIsBasic: any;
  allLicensePlates: string;
  "@search.score": number;
  fleetId: string;
  fleetName: string;
  fleetLastModifiedAt: string;
  fleetCreatedAt: string;
  countryCode: string;
  webBasePartnerNumber: string;
  businessYearStartMonth: string;
  businessYearStartDay: string;
  vehicleId: string;
  vin: null;
  licensePlate: string;
  vehicleUser: string;
  brandCode: string;
  model: null;
  vehicleStatusCode: string;
  vehicleLastStatusDate: string;
  vehicleCreatedAt: string;
  vehicleLastActivatedDate: string;
  vehicleLastSuspendedDate: string | null;
  vehicleDeactivatedDate: null;
  vehicleLastStatusComment: null;
  vehicleIssuingStatusCode: string;
  vehicleIssuingComment: null;
  vehicleIssuingSubstatusCode: any;
  carUsageCode: string;
  carUsageCodeAdditional: null;
  vehicleTypeCode: string;
  vehicleLastModifiedAt: string;
  seats: null;
  powerKW: null;
  weightCapacityKg: null;
  maxGrossWeightKg: null;
  yearOfConstruction: null;
  listPrice: string | null;
  listPriceTypeCode: null;
  costCenterClient: null;
  specialEquipment: null;
  clientComment: null;
  firstRegistrationDate: null;
  mtplPolicyNumber: null;
  mtplIsPolicyIssued: null;
  mtplPolicyDocumentDate: null;
  mtplGreenCardExpiryDate: null;
  mtplPolicyCancelationDocumentDate: null;
  mtplInsurerPartnerNumber: number | null;
  fleetLegalInsurerPartnerNumber: number | null;
  mtplAnnualGrossPremium: number;
  mtplProRataGrossPremium: number;
  hullPolicyNumber: null;
  hullIsPolicyIssued: null;
  hullPolicyDocumentDate: null;
  hullPolicyCancelationDocumentDate: null;
  hullInsurerPartnerNumber: number | null;
  hullAnnualGrossPremium: number;
  hullProRataGrossPremium: number;
  hullProduct: null;
  hullDeductiblePercent: null;
  hullDeductibleMaxAmount: null;
  hullDeductibleMinAmount: null;
  hullDeductibleComment: null;
  isIncludingTax: null;
  isLeasing: null;
  accidentAnnualGrossPremium: number;
  accidentProRataGrossPremium: number;
  assistanceAnnualGrossPremium: null;
  assistanceProRataGrossPremium: null;
  legalAnnualGrossPremium: null;
  legalProRataGrossPremium: null;
  excludeHorsePowerBasedTax: boolean;
  excludeHorsePowerBasedTaxReason: null;
  horsePowerBasedTaxAnnualy: number;
  horsePowerBasedTaxProRata: number;
  horsePowerBasedTaxLastStartDate: string;
  horsePowerBasedTaxLastEndDate: string;
  secondLicensePlate: null;
  vehicleOwner: string;
  vehicleClassCode: null;
  nationalCodeID: null;
  co2Emission: null;
  vehiclePowerTypeCode: null;
  vatReimbursment: null;
  gap: null;
  replacementValue: null;
  grossNegligence: null;
  electroPlusPackage: null;
  leasingCompany: null;
  certificateNumber: null;
  comment: null;
  horsePowerBasedTaxComment: null;
  mtplComment: null;
  hullComment: null;
  accidentComment: null;
  assistanceComment: null;
  legalComment: null;
  hasAttachments: boolean;
  needsGrECoUserAttention: null;
  mtplLastStartDate: string;
  mtplImportEndDate: string;
  hullLastStartDate: string;
  hullImportEndDate: string;
  accidentLastStartDate: string;
  assistanceLastStartDate: null;
  legalLastStartDate: null;
  hasSharedLicensePlate: null;
  hadSharedLicensePlate: null;
  isMainVehicleForSharedLicensePlateMtpl: null;
  wasMainVehicleForSharedLicensePlateMtpl: null;
  isMainVehicleForSharedLicensePlateEngineTax: null;
  wasMainVehicleForSharedLicensePlateEngineTax: null;
  hasIndemnityPremium: boolean;
  hasBonusMalusPremium: boolean;
  hadIndemnityPremium: boolean;
  hadBonusMalusPremium: boolean;
}

export type VehicleRedux = VehicleAzure & {
  isTEMP: boolean;
  isSelected: boolean;
  vehicleStatusCode: number;
};

export interface FleetUser {
  userId: string;
  userName: string;
  userEmailAddress: string;
}

export type FleetUserExtended = FleetUser & {
  isInternal: boolean;
};

export type FleetUserResponse = {
  fleetId: number;
  isInternal: boolean;
  rowVersion: string;
  userEmailAddress: string;
  userId: string;
  userName: string;
};

export type Insurers = {
  countryId: number | null;
  insurerList: Insurer[];
  fleetInsurerPartnerNumberMtpl: number | null;
  fleetInsurerPartnerNumberHull: number | null;
  fleetInsurerPartnerNumberAccident: number | null;
  fleetInsurerPartnerNumberAssistance: number | null;
  fleetInsurerPartnerNumberLegalAdvise: number | null;
};

export type BranchOffice = {
  branchOfficeId: number;
  branchOfficeInternalNumber: number;
  branchOfficeName: string;
};

export interface TaxonomyResponse {
  Country: Taxonomy[];
  VehicleBrand: Taxonomy[];
  VehicleClass: Taxonomy[];
  VehiclePowerType: Taxonomy[];
  VehicleTypeLegal: Taxonomy[];
  VehicleUsageType: Taxonomy[];
  PaymentMethod: Taxonomy[];
  VehicleStatusCode: Taxonomy[];
  [key: string]: Taxonomy[];
}

export type Taxonomy = {
  code: string;
  id: number;
  isGrECoLanguage?: boolean;
};

export interface VehiclesAzureResponse {
  "@odata.context": string;
  "@odata.count": number;
  value: VehicleAzure[];
}

export interface TaxonomyUADResponse {
  ApplicationCode: Taxonomy[];
  ApplicationSetting: Taxonomy[];
  Country: Taxonomy[];
  CostCenter: Taxonomy[];
  Gender: Taxonomy[];
  SupportedLanguage: Taxonomy[];
  LanguageListType: Taxonomy[];
}

export interface Setting {
  userId: number;
  appSettingCodeId: number;
  userAppSettingValue: string;
  userAppSettingId: number;
  userAppSettingName: string;
  userAppSettingDescription: string;
  applicationCodeId: number;
  isDefault: boolean;
}

export type SettingsResponse = Setting[];

export interface VehicleStatusHistoryItem {
  statusCode: number;
  statusDate: string;
  statusComment: string;
  setByUserId: string;
  setByUserName: null;
  setByUserEmail: null;
  setAt: string;
}

export interface VehiclePremiumHistoryData {
  vehicleEngineTaxPremiumValueHistoryData: VehiclePremiumHistoryItem[];
  vehicleMtplPremiumValueHistoryData: VehiclePremiumHistoryItem[];
  vehicleHullPremiumValueHistoryData: VehiclePremiumHistoryItem[];
  vehicleAccidentPremiumValueHistoryData: VehiclePremiumHistoryItem[];
  vehicleAssistancePremiumValueHistoryData: VehiclePremiumHistoryItem[];
  vehicleLegalPremiumValueHistoryData: VehiclePremiumHistoryItem[];
}

export interface VehiclePremiumHistoryItem {
  premiumTypeCode: number;
  premiumValue: number | null;
  validFromDate: string;
  validToDate: null;
  vehicleReason: number;
  fleetReason: number | null;
  comment: string;
  setByUserId: string;
  setByUserName: string;
  setByUserEmail: string;
  setAt: string;
}

export interface Insurer {
  map(arg0: (item: any) => { code?: any; id?: any; value?: any; label?: any });
  insurerId: number;
  insurerInternalNumber: number;
  insurerName: string;
  countryCodeId: number;
  street: string;
  city: string;
  zipCode: string;
  reportInsurerBranchOffices: BranchOffice[];
}

export type FleetWithInsurersResponse = {
  fleet: Fleet;
  insurers: Insurer[];
};

export type FleetWithPremiumsAndInsurersResponse = {
  fleet: FleetWithPremiumsResponse;
  insurers: Insurer[];
};

export type VehicleWithInsurersResponse = {
  fleet: Fleet;
  vehicle: Vehicle;
  insurers: Insurer[];
};

export type VehiclePremiumResponse = {
  vehicle: Vehicle;
};

export interface Vehicle {
  //debugger
  fleetLegalAnnualGrossPremium?: any;
  fleetLegalProrataGrossPremium?: any;
  //debugger
  emptyWeightKg: any;
  hullDueDay: any;
  hullDueMonth: any;
  mtplDueDay: any;
  mtplDueMonth: any;
  vehicleTypeCode: number;
  vehicleMainGroupCode: number;
  carUsageCode: null;
  brandCode: number | null;
  listPriceTypeCode: null;
  vehicleClassCode: null;
  vehiclePowerTypeCode: null;
  vehicleIssuingStatusCode: number;
  vehicleIssuingSubstatusCode: any;
  vehicleIssuingComment: null;
  horsePowerBasedTaxPaymentMethod: null;
  horsePowerBasedTaxPaymentMean: null;
  mtplPolicyNumber: null;
  mtplInsurerBranchOffice: number | null;
  mtplIsPolicyIssued: boolean | null;
  mtplPolicyDocumentDate: null;
  mtplPolicyCancelationDocumentDate: null;
  mtplLastStartDate: null;
  mtplImportEndDate: null;
  mtplLastUpdateDate: null;
  mtplLastEndDate: null;
  mtplAnnualGrossPremium: null | number;
  mtplInsurerPartnerNumber: number | null;
  hullInsurerPartnerNumber: number | null;
  mtplAnnualNetPremium: null | number;
  mtplProRataGrossPremium: null;
  mtplPaymentMethod: null;
  mtplPaymentMean: null | number;
  hullPolicyNumber: null;
  hullInsurerBranchOffice: null | boolean;
  hullLastStartDate: null;
  hullPaymentMean: null | number;
  hullIsPolicyIssued: null | boolean;
  hullPolicyDocumentDate: null;
  hullPolicyCancelationDocumentDate: null;
  insurerBranchOffice: null | number;
  hullImportEndDate: null;
  hullLastUpdateDate: null;
  hullLastEndDate: null;
  hullAnnualGrossPremium: null | number;
  hullAnnualNetPremium: null | number;
  hullProRataGrossPremium: null;
  registrationDate: null;
  hullProduct: null;
  hullDeductiblePercent: string | null;
  hullDeductibleMaxAmount: string | null;
  hullDeductibleMinAmount: string | null;
  hullDeductibleComment: null;
  hullPaymentMethod: null;
  listPrice: null | number;
  specialEquipment: null | number;
  isLeasing: null;
  leasingCompany: null;
  isIncludingTax: null;
  isIgnoringHullPremiumCalculation: null;
  isTradeLicensePlate: null;
  accidentPolicyNumber: null;
  accidentLastStartDate: null;
  accidentLastUpdateDate: null;
  accidentLastEndDate: null;
  accidentAnnualGrossPremium: null | number;
  accidentAnnualNetPremium: null | number;
  accidentPaymentMethod: null;
  accidentProRataGrossPremium: null;
  assistancePolicyNumber: null;
  assistanceLastStartDate: null;
  assistanceLastUpdateDate: null;
  assistancePaymentMethod: null;
  assistanceLastEndDate: null;
  assistanceAnnualGrossPremium: null | number;
  assistanceAnnualNetPremium: null | number;
  assistanceProRataGrossPremium: null;
  legalPolicyNumber: null;
  legalLastStartDate: null;
  legalLastUpdateDate: null;
  legalLastEndDate: null;
  legalPaymentMethod: null;
  legalAnnualGrossPremium: null | number;
  legalAnnualNetPremium: null | number;
  legalProRataGrossPremium: null;
  excludeHorsePowerBasedTax: boolean;
  excludeHorsePowerBasedTaxReason: null;
  horsePowerBasedTaxAnnualy: number;
  horsePowerBasedTaxProRata: number;
  horsePowerBasedTaxLastStartDate: null;
  horsePowerBasedTaxLastEndDate: null;
  annualPremiumSum: number;
  annualNetPremiumSum: number;
  proRataPremiumSum: number;
  vatReimbursment: null;
  gap: null;
  replacementValue: null;
  grossNegligence: null;
  electroPlusPackage: null;
  certificateNumber: null;
  paymentMean: null;
  comment: null;
  mtplComment: null | string;
  horsePowerBasedTaxComment: null;
  hullComment: null;
  hullIsBasic: null;
  accidentComment: null;
  assistanceComment: null;
  legalComment: null;
  needsGrEcoUserAttention: null;
  vehicleLaystModifiedAt: string;
  vehicleLastModifiedByUserId: string;
  vehicleCreatedAt: string;
  vehicleCreatedByUserId: string;
  vehicleFirstActivatedDate: string | null;
  vehicleLastActivatedDate: string;
  vehicleLastSuspendedDate: null;
  vehicleDeactivatedDate: null;
  vehicleUser: string;
  licensePlate: string;
  carUsageCodeAdditional: null;
  horsePowerBasedTaxAnnualyCalculated: number;
  horsePowerBasedTaxSuspensionDaysLimit: number;
  mtplSuspensionDays: number;
  mtplActivationDays: number;
  mtplSuspensionDaysLimit: number;
  hullSuspensionDays: number;
  hullActivationDays: number;
  hullSuspensionDaysLimit: number;
  hullGarageRiskDiscountPercent: number;
  accidentSuspensionDaysLimit: number;
  assistanceSuspensionDaysLimit: number;
  legalSuspensionDaysLimit: number;
  model: null;
  vin: null;
  seats: string | null;
  powerKw: string | null;
  cubicCapacityCcm: string | null;
  weightCapacityKg: string | null;
  maxGrossWeightKg: string | null;
  yearOfConstruction: null;
  costCenterClient: null;
  clientComment: null;
  firstRegistrationDate: null;
  secondLicensePlate: null;
  vehicleOwner: null;
  nationalCodeId: null;
  co2Emission: string | null;
  rowVersion: string;
  vehicleStatusCode: number;
  vehicleLastStatusDate: string | null;
  vehicleLastStatusComment: null;
  fleetId: number;
  vehicleId: number;
  errorMessage: string | null;
  fieldsUpdatedByExternalUser: string | null;
  vehicleStatusHistoryData: VehicleStatusHistoryItem[];
  vehiclePremiumValueHistoryData: VehiclePremiumHistoryData;
  vehiclePremiumBookingData?: VehiclePremiumBookingData;
  vehiclePremiumBookingWithDocumentDateData?: VehiclePremiumBookingData;
  vehiclePremiumSegmentHistoryData?: Array<VehiclePremiumSegmentHistoryData>;
  vehiclePolicyStatusHistoryData?: Array<VehiclePolicyStatusHistoryData>;
  vehicleSharedLicensePlateHistoryData?: Array<SharedLicensePlateHistory>;
  vehicleLicensePlateHistoryData?: Array<VehicleLicensePlateHistoryData>;
  vehicleLicensePlateSharedDateRangeData?: Array<SharedLicensePlateDateRange>;
  vehicleOneTimePremiumSegmentHistoryData?: Array<VehicleOneTimePremiumSegmentHistoryData>;
  hasSharedLicensePlate: null;
  hadSharedLicensePlate: null;
  isMainVehicleForSharedLicensePlateMtpl: null;
  wasMainVehicleForSharedLicensePlateMtpl: null;
  isMainVehicleForSharedLicensePlateEngineTax: null;
  wasMainVehicleForSharedLicensePlateEngineTax: null;
  scannedRegistrationDocumentPath: string | null;
}

export interface VehicleOneTimePremiumSegmentHistoryData {
  fleetId: number;
  oneTimePremiumComment: "test";
  oneTimePremiumDate: Date;
  oneTimePremiumGrossValue: number;
  oneTimePremiumNetValue: number;
  oneTimePremiumTypeCode: number;
  premiumTypeCode: number;
  setAt: Date;
  setByUserEmail: string;
  setByUserId: string;
  setByUserName: string;
  vehicleId: number;
  vehicleOneTimePremiumId: number;
  vehiclePremiumVersionId: number;
}
export interface VehiclePremiumBookingData {
  vehicleFleetLegalProRataPremiumBookingData: VehiclePremiumBookingPerTypeData;
  vehicleFleetLegalClosingDatePremiumBookingData: VehiclePremiumBookingPerTypeData;
  currentFleetPolicyCoverPeriodStart: Date;
  currentFleetPolicyCoverPeriodEnd: Date;
  vehicleEngineTaxPremiumBookingData?: VehiclePremiumBookingPerTypeData;
  vehicleMtplPremiumBookingData?: VehiclePremiumBookingPerTypeData;
  vehicleHullPremiumBookingData?: VehiclePremiumBookingPerTypeData;
  vehicleHullBasicPremiumBookingData?: VehiclePremiumBookingPerTypeData;
  vehicleAccidentPremiumBookingData?: VehiclePremiumBookingPerTypeData;
  vehicleAssistancePremiumBookingData?: VehiclePremiumBookingPerTypeData;
  vehicleLegalPremiumBookingData?: VehiclePremiumBookingPerTypeData;
  totalBookedAmount: number;
  totalBookedAmountPerCurrentPolicyCoverPeriod: number;
  totalPaidAmount: number;
  totalPaidAmountPerCurrentPolicyCoverPeriod: number;
  totalSaldo: number;
  totalSaldoPerCurrentPolicyCoverPeriod: number;
}
export interface VehicleLicensePlateHistoryData {
  comment: string;
  fleetId: number;
  isDeleted: boolean;
  isContinuing: boolean;
  licensePlate: string;
  rowVersion: string;
  setAt: Date;
  setByUserEmail: string;
  setByUserId: string;
  setByUserName: string;
  validFromDate: Date;
  validToDate: Date;
  vehicleId: number;
  vehicleLicensePlateId: number;
}

export interface VehiclePremiumSegmentHistoryData {
  vehiclePremiumVersionSegmentId: number;
  vehiclePremiumVersionId: number;
  vehiclePremiumId: number;
  vehicleId: number;
  fleetId: number;
  premiumTypeCode: number;
  webBasePolicyNumber: number;
  webBasePolicyVersionNumber: number;
  insurerPolicyNumber: string;
  insurerPartnerNumber: number;
  insurerBranchOffice: number;
  suspensionDays: number;
  activationDays: number;
  suspensionDaysLimit: number;
  garageRiskDiscountPercent: number;
  policyValidFromDate: Date;
  policyValidToDate: Date;
  validFromDate: Date;
  validToDate: Date;
  expiryDate: Date;
  annualGrossPremium: number;
  annualNetPremium: number;
  paymentMethod: number;
  paymentMean: number;
  changeReasonFleet: number;
  changeReasonVehicle: number;
  changeReasonComment: string;
  setAt: Date;
  setByUserId: string;
  proRataValidFromDate: Date;
  proRataValidToDate: Date;
  totalProRataPremium: number;
  proRataPremium: number;
  cancelationPremium: number;
  suspensionDaysPremium: number;
  suspensionDaysLastDay: Date;
  activationDaysPremium: number;
  activationDaysFirstDay: Date;
  garageRiskPremium: number;
  isVehicleSuspended: boolean;
  isVehicleDeactivated: boolean;
  isNotPayingBecauseOfSharedLicensePlate: boolean;
  isGarageRiskPremium: boolean;
  isCancelationPremium: boolean;
  isSuspensionDaysPremium: boolean;
  isActivationDaysPremium: boolean;
  isPolicyIssued: boolean;
  documentDate: Date;
  cancelationDocumentDate: Date;
  dueDay: number;
  dueMonth: number;
  indemnityPremium: number;
  bonusMalusPremium: number;
}

export interface VehiclePolicyStatusHistoryData {
  vehiclePremiumVersionId: number;
  vehiclePremiumId: number;
  vehicleId: number;
  fleetId: number;
  statusId: number;
  statusCode: number;
  statusDate: Date;
  statusComment: string;
  setAt: Date;
  setByUserId: string;
  setByUserName: string;
  setByUserEmail: string;
}

export interface VehiclePremiumBookingPerTypeData {
  vehiclePremiumBooking: Array<VehiclePremiumBookingInfo>;
  totalBookedAmount: number;
  totalBookedAmountPerCurrentPolicyCoverPeriod: number;
  totalPaidAmount: number;
  totalPaidAmountPerCurrentPolicyCoverPeriod: number;
  totalSaldo: number;
  totalSaldoPerCurrentPolicyCoverPeriod: number;
}

export interface VehiclePremiumBookingInfo {
  vehiclePremiumBookingId: number;
  fleetId: number;
  vehicleId: number;
  premiumTypeCode: number;
  bookedAmount: number;
  paidAmount: number;
  premiumBookingDate: Date;
  premiumBookingFromDate: Date;
  premiumBookingReasonCode: number;
  premiumBookingToDate: Date;
  paidDate?: Date;
  vehicleIssuingStatusCode: number;
  vehicleIssuingSubstatusCode?: number;
  vehicleIssuingStatusComment: string;
  vehiclePremiumBookingLastModifiedAt: Date;
  vehiclePremiumBookingLastModifiedByUserId: string;
  vehiclePremiumBookingLastModifiedByUserEmail?: string;
  vehiclePremiumBookingLastModifiedByUserName?: string;
  vehiclePremiumBookingCreatedAt: Date;
  vehiclePremiumBookingCreatedByUserId: string;
  vehiclePremiumBookingCreatedByUserEmail?: string;
  vehiclePremiumBookingCreatedByUserName?: string;
  isDeleted: boolean;
  isPolicyIssued: boolean;
  isPending: boolean;
  documentDate: Date;
  insurerPartnerNumber: number;
  insurerPolicyNumber: string;
  rowVersion: string;
}

export enum UserRole {
  admin = "admin",
  internal = "internal",
  external = "external",
  adminreadonly = "adminreadonly",
  admininternal = "admininternal",
  adminreadonly_admininternal = "adminreadonly_admininternal",
  adminreadonly_internal = "adminreadonly_internal",
}

export type GroupPolicyInfo = {
  count: number;
  groupPolicyNumber: string;
};

export type FleetWithPremiumsResponse = {
  fleetPolicyCoverPeriodStartDate: any;
  fleetPolicyCoverPeriodEndDate: any;
  paymentMethod: any;
  fleetLegalClosingDateAnnualGrossPremium: number;
  fleetLegalProRataProRataGrossPremium: number;
  fleetLegalProRataAnnualGrossPremium: number;
  mainFleetLegalInsurerPartnerNumber: FleetInsurersInfo[];
  fleetLegalInsurersInfo: FleetInsurersInfo[];
  mtplAnnualGrossPremium: number;
  mtplProRataGrossPremium: number;
  mtplInsurersInfo: FleetInsurersInfo[];
  mtplGroupPolicyInfo: GroupPolicyInfo[];
  hullGroupPolicyInfo: GroupPolicyInfo[];
  hullInsurersInfo: FleetInsurersInfo[];
  hullAnnualGrossPremium: number;
  hullProRataGrossPremium: number;
  accidentAnnualGrossPremium: number;
  accidentProRataGrossPremium: number;
  assistanceAnnualGrossPremium: number;
  assistanceProRataGrossPremium: number;
  legalAnnualGrossPremium: number;
  legalProRataGrossPremium: number;
  horsePowerBasedTaxAnnualy: number;
  horsePowerBasedTaxProRata: number;
  horsePowerBasedTaxLastStartDate: string;
  horsePowerBasedTaxLastEndDate: string;
  annualPremiumSum: number;
  proRataPremiumSum: number;
  fleetId: number;
  fleetName: string;
  fleetLastModifiedAt: string;
  fleetLastModifiedByUserId: string;
  fleetCreatedAt: string;
  fleetCreatedByUserId: string;
  fleetCreatedByUserName: string;
  countryCode: number;
  clientName: string;
  webBasePartnerNumber: number;
  businessYearStartMonth: number;
  businessYearStartDay: number;
  dueMonth: number;
  dueDay: number;
  maxCalculationDate: Date;
  mainMtplInsurerPartnerName: string;
  mainMtplInsurerPartnerNumber: string;
  hasMultipleMtplInsurers: boolean;
  mainHullInsurerPartnerName: string;
  mainHullInsurerPartnerNumber: string;
  hasMultipleHullInsurers: boolean;
  fleetPolicyNumber: string;
  fleetLegalPolicyNumber: string;

  fleetComment: string;
  rowVersion: string;
  fleetActionHistoryData: FleetHistoryItem[];
};

export interface FleetHistoryItem {
  isFirstForFleetLegalClosingDate: boolean | null;
  fleetActionHistoryId: number;
  fleetActionReason: number;
  fleetActionReasonComment: string;
  fleetActionExecutedAt: string;
  fleetActionExecutedByUserId: string;
  fleetActionExecutedByUserName: string;
  fleetActionExecutedByUserEmail: string;
  insurerPartnerNumber: number;
  premiumTypeCode: number;
  vehicleMainGroupCode: number;
  fleetActionValue: number;
  fleetActionStartDate: string;
  fleetActionEndDate: null;
  reportDocumentPath: string;
  importOrUpdateDocumentPath: string;
  fleetActionCriteria: SettingCriteria;
}

export interface InsurerSettings {
  id: number;
  lastModifiedAt: string;
  lastModifiedByUserId: string;
  lastModifiedByUserName: string;
  lastModifiedByUserEmail: string;
  createdAt: string;
  createdByUserId: string;
  createdByUserName: string;
  createdByUserEmail: string;
  rowVersion: string;
  insurerPartnerNumber: number;
  suspensionDays: number;
  activationDays: number;
  garageRiskDiscountPercentHull: number;
  garageRiskDiscountPercentHullBasic: number;
  insurerSettingPerPremiumTypeAndVehicleMainGroupDetails?: InsurerSettingsDetails[];
  insurerSettingPerPremiumTypeDetailsHU?: InsurerSettingsDetailsHU[];
  validFromDate: string;
}

export interface InsurerSettingsDetails {
  id: number;
  lastModifiedAt: string;
  lastModifiedByUserId: string;
  lastModifiedByUserName: string;
  lastModifiedByUserEmail: string;
  createdAt: string;
  createdByUserId: string;
  createdByUserName: string;
  createdByUserEmail: string;
  rowVersion: string;
  premiumTypeCode: number;
  vehicleMainGroupCode: number;
  suspensionDaysLimit: number;
}

export interface InsurerSettingsDetailsHU {
  id: number;
  lastModifiedAt: string;
  lastModifiedByUserId: string;
  lastModifiedByUserName: string;
  lastModifiedByUserEmail: string;
  createdAt: string;
  createdByUserId: string;
  createdByUserName: string;
  createdByUserEmail: string;
  rowVersion: string;
  premiumTypeCode: number;
  maxValueForTaxCalculation: number;
  taxPercent: number;
  taxExtraPercent1: number;
  taxExtraPercent2: number;
}

export interface FleetInsurersInfo {
  count: number;
  insurerInternalNumber: number;
  insurerName: string;
}

export interface FleetDefaultSetting {
  fleetId: number;
  fleetVehicleDefaultSettingId: number;
  attributeName: "annualGrossPremium" | "deductible" | "calculation";
  attributeValue: string | number;
  insurerPartnerNumber: number;
  premiumType: number;
  //vehicleMainGroup: number;
  // vehicleType: number;
  // carUsage: number;
  // weightCapacityKgRange: number;
  // maxGrossWeightKgRange: number;
  // powerKwRange?: number;
  // powerKwFrom: number;
  // powerKwTo: number;
  // cubicCapacityCcmRange: number;
  // numberOfSeatsMoped: number;
  // numberOfSeatsRange: number;
  // listPriceType: number;
  // listPriceMin: number;
  // listPriceMax: number;
  lastModifiedByUserName: string;
  lastModifiedByUserEmail: string;
  lastModifiedByUserId: string;
  rowVersion: string;
  // isTradeLicensePlate: boolean;
  // emptyWeightKgFrom?: number;
  // emptyWeightKgTo?: number;
  validFromDate: Date;
  settingCriteria: SettingCriteria;
  overwrittenCriteria?: any;
  insurerGroupPolicyNumber?: string;
}

export interface SettingCriteria {
  vehicleTypeCodes: number[];
  carUsageCodes: number[];
  weightCapacityKgRangeCodes: number[];
  maxGrossWeightKgRangeCodes: number[];
  powerKws: FromTo[];
  emptyWeightKgs: FromTo[];
  cubicCapacityCcmRangeCodes: number[];
  numberOfSeatsRangeCodes: number[];
  numberOfSeatsMopedCodes: number[];
  listPrices: ListPriceTypeMinMax[];
  isTradeLicensePlate: boolean;
}

export interface ListPriceTypeMinMax {
  type: number;
  min: number;
  max: number;
}
export interface FromTo {
  from: number;
  to: number;
}
export interface IFleetInsurerDefaultSetting {
  fleetId: number;
  fleetInsurerDefaultSettingId: number;
  insurerPartnerNumber: number;
  suspensionDays: number;
  activationDays: number;
  garageRiskDiscountPercentHull: number;
  garageRiskDiscountPercentHullBasic: number;
  insurerBranchOffice: any;
  paymentMethod: any;
  paymentMethodFleetLegal: number;
  vehicleType: any;
  paymentMethodEngineTax: any;
  paymentMean: number;
  paymentMeanFleetLegal: number;
  lastModifiedAt: string;
  lastModifiedByUserId: string;
  lastModifiedByUserName: string;
  lastModifiedByUserEmail: string;
  createdAt: string;
  createdByUserId: string;
  createdByUserName: string;
  createdByUserEmail: string;

  isDeleted: boolean;
  rowVersion: string;
}

export interface SharedLicensePlate {
  currentVehicles: any;
  historyInfo: SharedLicensePlateHistory[];
  licensePlate: string;
  licensePlateSharedDateRangeInfo: SharedLicensePlateDateRange[];
}
export interface VehicleShortInfo {
  brandCode: number;
  carUsageCode: number;
  co2emission: number;
  cubicCapacityCcm: number;
  firstRegistrationDate: Date;
  horsePowerBasedTaxAnnualy: number;
  maxGrossWeightKg: number;
  model: string;
  powerKw: number;
  vehicleId: number;
  vehiclePowerTypeCode: number;
  vehicleTypeCode: number;
  vin: string;
}

export interface SharedLicensePlateVehicle {
  carUsageCode: number;
  firstRegistrationDate: string;
  horsePowerBasedTaxAnnualy: number;
  maxGrossWeightKg: number;
  vehicleShortInfo: VehicleShortInfo;
  vin: string;
}

export interface SharedLicensePlateDateRange {
  licensePlate: string;
  isContinuing: boolean;
  possibleVehicles: VehicleShortInfo[];
  statusRanges: TimelineStatusRanges[];
  validFromDate: string;
  validToDate: string;
}

export interface TimelineStatusRanges {
  validFromDate: string;
  validToDate: string;
  isSuspended: boolean;
}

export interface SharedLicensePlateHistory {
  comment: string;
  fleetId: number;
  licensePlate: string;
  mainVehicleForSharedLicensePlateId: number;
  possibleVehicles: SharedLicensePlateVehicle[];
  premiumTypeCode: number;
  reason: number;
  setAt: string;
  setByUserEmail: string;
  setByUserId: string;
  setByUserName: string;
  validFromDate: string;
  validToDate: string;
  vehicleId: number;
}

export type SharedLicensePlateSegment = SharedLicensePlateHistory & {
  line: string;
  start: Date;
  end: Date;
  type: SlpTimelineSegmentType;
};

export interface BackendError {
  statusCode: number;
  errorCode: string;
  type: string;
  correlationID: string;
  messageCode: string;
  messageVariables: any[];
  statusDescription: string;
  message: string;
  formatedMessage: string;
  exceptionMessage: string;
  stackTrace: string;
}

export interface PowerBIEmbedMeta {
  reportId: string;
  embedUrl: string;
  accessToken: string;
  embedTokenExpiry: Date;
}

export type VehicleTimelineData = {
  data: VehicleTimelineSegment[];
  startDate: Date;
  endDate: Date;
};

export enum VehicleTimelineSegmentType {
  PREMIUM = "premium",
  SUSPENSION = "suspension",
  VOID_BEFORE = "void before",
  VOID_BETWEEN = "void between",
  VOID_AFTER = "void after",
  STATUS_ACTIVE = "status active",
  STATUS_SUSPENDED = "status suspended",
  STATUS_DEACTIVATED = "status deactivated",
}

export enum SlpTimelineSegmentType {
  MAIN_VEHICLE = "main_vehicle",
  VOID_BEFORE = "void before",
  VOID_BETWEEN = "void between",
  VOID_AFTER = "void after",
}

export type Timeline = {
  startDate: Date;
  endDate: Date;
  domain: TimelineDomain[];
  prorata: TimelineProrata[];
  bookingTotals: TimelineProrata[];
  data: TimelineSegment[];
  dateFilter: [Date, Date];
  filteredData: TimelineSegment[];
  legend: TimelineLegendSegment[];
  insurerList: Insurer[];
  showBookings: boolean;
  showBookingsWithDocumentDate: boolean;
};

export type TimelineProrata = {
  premiumType: PremiumType;
  overall: number;
  current: number;
};

export enum TimelineType {
  VEHICLE_PREMIUM = "vehicle premium",
  VEHICLE_PREMIUM_STATUSES = "vehicle premium statuses",
  VEHICLE_POLICY_STATUSES = "vehicle policy statuses",
  VEHICLE_PREMIUM_BOOKINGS = "vehicle premium bookings",
  VEHICLE_ONE_TIME_PREMIUM = "vehicle one time premium",
  VEHICLE_STATUS = "vehicle status",
  VEHICLE_LICENSE_PLATE = "license plate",
  SHARED_LICENSE_PLATE_MTPL = "shared license plate MTPL",
  SHARED_LICENSE_PLATE_ENGINE_TAX = "shared license Engine Power",
  SHARED_LICENSE_PLATE_STATUS = "shared license plate Status",
}

export enum TimelineSegmentType {
  PREMIUM = "premium",
  SUSPENSION = "suspension",
  VOID_BEFORE = "void before",
  VOID_BETWEEN = "void between",
  VOID_AFTER = "void after",

  POLICY_STATUS_PENDING = "policy status pending",
  POLICY_STATUS_CLOSED = "policy status closed",
  POLICY_STATUS_WAITING_FOR_INSURER = "policy status waiting for insurer",
  POLICY_STATUS_WAITING_FOR_CLIENT = "policy status waiting for client",
  POLICY_STATUS_WAITING_FOR_REGISTRATION = "policy status waiting for registration",
  POLICY_STATUS_WAITING_FOR_CHECKING = "policy status waiting for checking",
  POLICY_STATUS_WAITING_FOR_USER = "policy status waiting for user",

  STATUS_ACTIVE = "status active",
  STATUS_SUSPENDED = "status suspended",
  STATUS_DEACTIVATED = "status deactivated",
  STATUS_PENDING_FOR_ACTIVATION = "status pending for activation",
  STATUS_PENDING_FOR_SUSPENSION = "status pending for suspension",
  STATUS_PENDING_FOR_DEACTIVATION = "status pending for deactivation",

  MAIN_SHARED_VEHICLE = "main shared vehicle",
  LICENSE_PLATE = "license plate",
  BOOKING = "booking",
  ONE_TIME_PREMIUM = "one time premium",
  BOOKING_DOCUMENT_DATE = "booking document date",
}

export type TimelineDomain = {
  type: TimelineType;
  value: string;
  currentProrata?: number;
  overallProrata?: number;
};

export enum ETimelineExtraDataLineSpecial {
  BASIC = "BASIC",
  CLOSING_DATE = "CLOSING_DATE",
}

export type TimelineSegment = {
  isFirstForFleetLegalClosingDate?: boolean | null;
  domain: TimelineDomain;
  segmentType: TimelineSegmentType;
  startDate: Date;
  endDate: Date;
  extraData: any;
  associatedObject: any;
};

export type TimelineLegendSegment = {
  segmentType: TimelineType;
  title: string;
  items: TimelineLegendSegmentItem[];
};

export type TimelineLegendSegmentItem = {
  label: string;
  itemType: TimelineLegendSegmentItemType;
  itemAttributes: any;
};

export enum TimelineLegendSegmentItemType {
  TEXT = "text",
  PREMIUM_INSURER = "premium insurer",
  PREMIUM_REGULAR = "premium regular",
  PREMIUM_SUSPENDED = "premium suspended",
  PREMIUM_BOOKING = "premium booking",
  PREMIUM_NOTHING_TO_PAY = "premium nothing to pay",
  PREMIUM_NOT_MAIN_SHARED_VEHICLE = "premium not main shared vehicle",
  STATUS = "status",
  LICENSE_PLATE = "license plate",
  SHARED_LICENSE_PLATE = "shared license plate",
  SHARED_LICENSE_PLATE_VEHICLE = "shared license plate vehicle",
  PREMIUM_BOOKING_CORRECT = "booking correct",
  PREMIUM_BOOKING_INCORRECT = "booking incorrect",
  PREMIUM_BOOKING_NOT_YET_BOOKED = "booking not yet booked",
  PREMIUM_BOOKING_PENDING = "booking pending",
  PREMIUM_BOOKING_PLUSMINUS = "booking plusminus",
  PREMIUM_BOOKING_ONETIMEPREMIUM = "booking onetimepremium",
  POLICY_STATUS = "policy status",
}

export type TimelineContextMenu = {
  isOpen: boolean;
  type: TimelineContextMenuType;
  item: TimelineSegment | null;
  ref: any | null;
  date: Date;
};

export enum TimelineContextMenuType {
  PREMIUM = "premium",
  PENDING_STATUS = "pending status",
  STATUS_BAR = "status bar",
  POLICY_STATUS_BAR = "policy status bar",
  STATUS_POINT = "status point",
  POLICY_STATUS_POINT = "policy status point",
  LICENSE_PLATE = "license plate",
  LICENSE_PLATE_POINT = "license plate point",
  SHARED_LICENSE_PLATE = "shared license plate",
  BOOKING = "booking",
  ONETIME_PREMIUM = "onetime premium",
}

export enum TimelineDialogType {
  ADD_PREMIUM = "add premium",
  INSERT_PREMIUM = "insert premium",
  DELETE_PREMIUM = "delete premium",
  CORRECT_PREMIUM = "correct premium",
  ADJUST_PREMIUM = "adjust premium",
  CONTINUE_PREMIUM = "continue premium",
  CANCEL_PREMIUM = "cancel premium",
  CHANGE_BOOKING = "change booking",

  ADD_ONETIME_PREMIUM = "add onetime premium",
  CHANGE_ONETIME_PREMIUM = "change onetime premium",
  DELETE_ONETIME_PREMIUM = "delete onetime premium",

  ADD_STATUS = "add status",
  DELETE_STATUS = "delete status",
  INSERT_STATUS = "insert status",
  ACCEPT_STATUS = "accept status",
  CORRECT_STATUS = "correct status",
  CANCEL_STATUS = "cancel status",
  APPROVE_PENDING_STATUS = "approve pending status",

  ADD_POLICY_STATUS = "add policy status",
  DELETE_POLICY_STATUS = "delete policy status",
  INSERT_POLICY_STATUS = "insert policy status",
  CORRECT_POLICY_STATUS = "correct policy status",

  ADD_SHARED_LICENSE_PLATE = "add shared license plate",
  INSERT_SHARED_LICENSE_PLATE = "insert shared license plate",
  DELETE_SHARED_LICENSE_PLATE = "delete shared license plate",
  CORRECT_SHARED_LICENSE_PLATE = "correct shared license plate",
  CONTINUE_SHARED_LICENSE_PLATE = "continue shared license plate",
  CANCEL_SHARED_LICENSE_PLATE = "cancel shared license plate",
  ACTIVATE_SHARED_LICENSE_PLATE = "activate shared license plate",
  SUSPEND_SHARED_LICENSE_PLATE = "suspend shared license plate",

  INSERT_LICENSE_PLATE = "insert license plate",
  CORRECT_LICENSE_PLATE = "correct license plate",
  CHANGE_LICENSE_PLATE = "change license plate",
  DELETE_LICENSE_PLATE = "delete license plate",
}

export interface VehicleTimelineSegment {
  segmentId: number;
  line: string;
  premium: number;
  type: VehicleTimelineSegmentType;
  insurer: string;
  start: Date;
  end: Date;

  id: number;
  policyNumber: string;
  activationDays: number;
  activationDaysFirstDay: null;
  activationDaysPremium: number;
  annualGrossPremium: number;
  annualNetPremium: number;
  bonusMalusPremium: number;
  changeReasonComment: string;
  changeReasonFleet: null;
  changeReasonVehicle: number;
  fleetId: number;
  garageRiskDiscountPercent: number;
  garageRiskPremium: number;
  indemnityPremium: number;
  insurerBranchOffice: number;
  insurerPartnerNumber: number;
  insurerPolicyNumber: string;
  isPolicyIssued: boolean;
  documentDate: string;
  cancelationDocumentDate: string;
  isActivationDaysPremium: false;
  isGarageRiskPremium: false;
  isNotPayingBecauseOfSharedLicensePlate: false;
  isCancelationPremium: false;
  isSuspensionDaysPremium: false;
  isVehicleDeactivated: false;
  isVehicleSuspended: false;
  paymentMean: number;
  paymentMethod: number;
  premiumTypeCode: number;
  proRataPremium: number;
  proRataValidFromDate: string;
  proRataValidToDate: string;
  setAt: string;
  setByUserEmail: string;
  setByUserId: string;
  setByUserName: string;
  suspensionDays: number;
  suspensionDaysLastDay: null;
  suspensionDaysLimit: number;
  suspensionDaysPremium: number;
  cancelationPremium: number;
  totalProRataPremium: number;
  validFromDate: string;
  validToDate: string;
  expiryDate: string;
  vehicleId: number;
  vehiclePremiumId: number;
  vehiclePremiumVersionId: number;
  vehiclePremiumVersionSegmentId: number;
  webBasePolicyNumber: null;
  webBasePolicyVersionNumber: null;
  lineSpecial: null;
  isLastSegmentInLine: boolean;
  isInfinitePremium: boolean;
}

export interface VehicleTimelinePeriod {
  from: Date;
  to: Date;
}

export enum PremiumType {
  MTPL = "PremiumType.MTPL",
  HORSE_POWER = "PremiumType.HORSE_POWER",
  ACCIDENT = "PremiumType.ACCIDENT",
  ASSISTANCE = "PremiumType.ASSISTANCE",
  LEGAL = "PremiumType.LEGAL",
  HULL = "PremiumType.HULL",
  HULL_BASIC = "PremiumType.HULL_BASIC",
  FLEETLEGAL = "PremiumType.FLEETLEGAL",
  FLEETLEGAL_PRORATA = "PremiumType.FLEETLEGAL_PRORATA",
  FLEETLEGAL_CLOSINGDATE = "PremiumType.FLEETLEGAL_CLOSINGDATE",

  // Used only for timeline graph
  STATUS = "bfm.status.label",
  LICENSE_PLATE = "bfm.licensePlate.label",
  SHARED_LICENSE_PLATE = "bfm.sharedLicensePlate",
}

export enum FleetHullCalculationDefaultSettingType {
  PREMIUM_RATE_PERCENTAGE = "premiumRatePercentage",
  ABSOULUTE_PREMIUM_VALUE = "absoulutePremiumValue",
}

export interface SupportedLanguage {
  isGrECoLanguage: boolean;
  code: string;
  isDeleted: boolean;
  id: number;
}

export interface ViewOptions {
  appSettingCodeId: number;
  userAppSettingValue: any; // IColumnCRM[]
  userAppSettingId: number;
  userAppSettingName: string;
  userAppSettingDescription: string;
  applicationCodeId: number;
  isDefault: boolean;
}

export interface IUadItem {
  id: number;
  code: string;
  isDeleted: boolean;
}

export interface IUadTaxonomy {
  ApplicationCode: IUadItem[];
  ApplicationSetting: IUadItem[];
  Country: IUadItem[];
  CostCenter: IUadItem[];
  Gender: IUadItem[];
  SupportedLanguage: SupportedLanguage[];
  LanguageListType: IUadItem[];
}

export interface IUser {
  "@odata.context": string;
  businessPhones?: any[];
  displayName?: string;
  givenName?: string;
  jobTitle?: string | null;
  mail?: string | null;
  mobilePhone?: string;
  officeLocation?: string | null;
  preferredLanguage?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
}

export interface IUpdateVehicleIssuingStatus {
  vehicleId: string | number;
  fleetId: string | number;
  vehicleIssuingStatusCode: number;
  vehicleFirstIssuingStatusDate: string;
  vehicleLastIssuingStatusDate: string;
  vehicleIssuingSubstatusCode: number;
  vehicleIssuingComment: string;
}
export interface IUpdateMultipleVehicleIssuingStatus {
  vehicleIds: string[];
  fleetId: string | number;
  vehicleIssuingStatusCode: number;
  vehicleFirstIssuingStatusDate: string;
  vehicleLastIssuingStatusDate: string;
  vehicleIssuingSubstatusCode: number;
  vehicleIssuingComment: string;
}
export interface IUpdateMultipleVehiclePendingIssuingStatus {
  vehicleIds: string[];
  fleetId: string | number;

  vehicleIssuingStatusCode: number;
  vehicleFirstIssuingStatusDate?: string;
  vehicleLastIssuingStatusDate?: string;
  vehicleIssuingSubstatusCode: number;
  vehicleIssuingComment: string;
}

import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { fork } from "redux-saga/effects";
import { analyticsPageReducer, analyticsPageSaga } from "./analyticsPage";
import { authReducer } from "./auth";
import { filterReducer } from "./filter";
import { fleetFormReducer, fleetFormSaga } from "./fleetForm";
import { fleetPageReducer, fleetPageSaga } from "./fleetPage";
import { fleetPageColumnOptionsReducer } from "./fleetPageColumnOptions";
import { fleetsPageReducer, fleetsPageSaga } from "./fleetsPage";

import { InsurersPageReducer } from "./insurersPage";
import {
  requireActionVehiclesReducer,
  requireActionVehiclesSaga,
} from "./requireActionVehicles";
import { requireActionVehiclesColumnOptionsReducer } from "./requireActionVehiclesColumnOptions";
import { settingsReducer, settingsSaga } from "./settings";
import bookingSlice from "./slices/bookingSlice";
import fleetFormikFormSlice from "./slices/fleetFormikFormSlice";
import phraseToolSlice from "./slices/phraseToolSlice";
import tradeLicensePlateSlice from "./slices/tradeLicensePlateSlice";
import { taxonomyReducer, taxonomySaga } from "./taxonomy";
import { timelineReducer } from "./timeline";
import { vehicleReducer, vehicleSaga } from "./vehicle";

const reducer = combineReducers({
  tradeLicensePlates: tradeLicensePlateSlice.reducer,
  fleetFormikForm: fleetFormikFormSlice.reducer,
  taxonomy: taxonomyReducer,
  fleetsPage: fleetsPageReducer,
  insurersPage: InsurersPageReducer,
  fleetPage: fleetPageReducer,
  analyticsPage: analyticsPageReducer,
  fleetPageColumnOptions: fleetPageColumnOptionsReducer,
  fleetForm: fleetFormReducer,
  auth: authReducer,
  settings: settingsReducer,
  vehicle: vehicleReducer,
  requireActionVehicles: requireActionVehiclesReducer,
  requireActionVehiclesColumnOptions: requireActionVehiclesColumnOptionsReducer,
  timeline: timelineReducer,
  booking: bookingSlice.reducer,
  phraseTool: phraseToolSlice.reducer,
  filter: filterReducer,
});

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer,
  middleware: [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
    sagaMiddleware,
  ],
});

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = any;

export type LoadStatus = "none" | "loading" | "success" | "error";

sagaMiddleware.run(function* () {
  yield fork(taxonomySaga);
  yield fork(fleetsPageSaga);
  yield fork(analyticsPageSaga);
  yield fork(fleetPageSaga);
  yield fork(settingsSaga);
  yield fork(vehicleSaga);
  yield fork(fleetFormSaga);
  yield fork(requireActionVehiclesSaga);
});

import React from "react";
import { useTranslation } from "react-i18next";
import { FleetCountrySelect } from "./FleetCountrySelect";

type Props = {
  value: string;
  options: any[];

  onChange: (val: { text: string; key: string | null }) => void;
};

export const FleetCountryFilter = ({ value, options, onChange }: Props) => {
  const { t } = useTranslation();
  return (
    <FleetCountrySelect
      value={value ? value : null}
      onChange={(o: any) => {
        onChange(o);
      }}
      options={options}
      isClearable={false}
      isLoading={false}
    />
  );
};

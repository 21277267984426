export function getInsurerBranchOffice({
  formInsurer,
  insurerList,
  formBranchOffice,
}): {
  label: string;
  value: string | number;
} | null {
  const selectedInsurer =
    insurerList &&
    insurerList?.find(
      (insurer) => insurer?.insurerInternalNumber === formInsurer
    );
  const reportInsurerBranchOffices =
    selectedInsurer?.reportInsurerBranchOffices;

  const options = reportInsurerBranchOffices
    ? reportInsurerBranchOffices.map((tax) => {
        return {
          value: tax.branchOfficeInternalNumber,
          label:
            tax.branchOfficeName + " (" + tax.branchOfficeInternalNumber + ")",
        };
      })
    : [];

  return options.find((option) => option.value === formBranchOffice) || null;
}

import { FontIcon } from "@fluentui/react";
import { mergeStyleSets } from "@uifabric/merge-styles";
import { VSpace } from "components/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

const FormItemTitle = ({ title, iconName }) => {
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;
  const classes = mergeStyleSets({
    wrapper: {
      display: "flex",
      alignItems: "center",
    },
    horizontalLine: {
      height: "2px",
      width: "100%",
      background: "#dadada",
      flexGrow: 1,
    },
    generalItem: {
      margin: "0 5px",
      fontSize: "18px",
      height: "100%",
      color: theme.palette.black,
    },
    icon: {
      display: "flex",
      alignItems: "center",
      fontSize: 20,
      height: 30,
      borderRadius: "50%",
      width: 30,
      margin: "0 10px",
      color: theme.palette.black,
    },
  });

  return (
    <>
      <VSpace height={20} />
      <div className={classes.wrapper}>
        <div className={classes.icon}>
          <FontIcon
            aria-label={iconName}
            iconName={iconName}
            className={classes.icon}
          />
        </div>
        <div className={classes.generalItem}>{title}</div>
      </div>
      <VSpace height={20} />
    </>
  );
};

export default FormItemTitle;

import React from "react";
import BookingDetails from "./BookingDetails/BookingDetails";
import { BookingSummary } from "./BookingSummary";

export const Assistance = () => {
  return (
    <>
      <BookingSummary premiumType="ASSISTANCE" />
      <BookingDetails premiumType="ASSISTANCE" />
    </>
  );
};

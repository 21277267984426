import { mergeStyleSets } from "@uifabric/merge-styles";
import TextInputField from "components/controls/TextInputField";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import { selectClientByInternalNumber } from "store/slices/fleetFormikFormSlice";
import AsyncClientComboBox from "../../AsyncClientComboBox";

const classes = mergeStyleSets({
  searchItem: {
    padding: "20px",
    width: "49%",
    "@media(min-width: 1500px)": {
      width: "66%",
    },
  },
  item: {
    padding: "20px",
    width: "49%",
    "@media(min-width: 1500px)": {
      width: "33%",
    },
  },
});

const ClientSection = () => {
  const { t } = useTranslation();
  const client = useSelector(selectClientByInternalNumber);
  const taxonomy = useSelector((s) => s.taxonomy);

  return (
    <>
      <div className={classes.searchItem}>
        <AsyncClientComboBox
          required
          name="webBasePartnerNumber"
          label={"bfm.fleetForm.SearchForClient.label"}
        />
      </div>
      {client && (
        <>
          <div className={classes.item}>
            <TextInputField
              onChange={() => {}}
              value={`${client?.clientName} (${client?.clientInternalNumber})`}
              errors={[]}
              name=""
              disabled={true}
              label={"bfm.fleetForm.client.label"}
            />
          </div>
          <div className={classes.item}>
            <TextInputField
              onChange={() => {}}
              value={t(taxonomy.Country.byId[client?.countryCodeId]?.code)}
              errors={[]}
              name=""
              disabled={true}
              label={"bfm.country.label"}
            />
          </div>
          <div className={classes.item}>
            <TextInputField
              onChange={() => {}}
              value={`${client?.fiscalDay}.${client?.fiscalMonth}`}
              errors={[]}
              name=""
              disabled={true}
              label={"bfm.fleetForm.fiscalDate.label"}
            />
          </div>
          <div className={classes.item}>
            <TextInputField
              onChange={() => {}}
              value={`${client?.defaultDueDay}.${client?.defaultDueMonth}`}
              errors={[]}
              name=""
              disabled={true}
              label={"bfm.fleetForm.dueDate.label"}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ClientSection;

export enum EActionDialog {
  GenerateFleetLegalReport = "generateFleetLegalReport",
  AddPremium = "addPremium",
  AdjustDeductible = "adjustDeductible",
  Adjustment = "adjustment",
  ChangeDueDate = "changeDueDate",
  CopySettings = "copySettings",
  CreateSetting = "createSetting",
  EditSetting = "editSetting",
  GenerateComparisonReport = "generateComparisonReport",
  GenerateCoversheetReport = "generateCoversheetReport",
  Index = "index",
  PaymentMethodChange = "paymentMethodChange",
  ReadOnly = "readOnly",
  RecalculateProRataAndBookings = "recalculateProRataAndBookings",
  RemovePremium = "removePremium",
  SynchronizeFleetClient = "synchronizeFleetClient",
  UpdatePremium = "updatePremium",
  ExportFleet = "exportFleet",
}

export enum EDialogAttributeName {
  AnnualGrossPremium = "annualGrossPremium",
  Deductible = "deductible",
  Calculation = "calculation",
}

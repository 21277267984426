import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Label,
} from "@fluentui/react";
import { VehiclePolicyStatusSelect } from "components/VehiclePolicyStatusSelect";
import { Formik, Field as FormikField } from "formik";
import { useChangeVehiclePolicyStatus } from "hooks/data/mutations/useChangeVehiclePolicyStatus";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { toast } from "../../../components/FluentToast";
import { FormSpinner } from "../../../components/FormSpinner";
import { VSpace } from "../../../components/Spacer";
import { Field } from "../../../components/form/Field";
import { combine, required } from "../../../components/form/validation";
import { ErrorMessage, StyledPrimaryButton } from "../../../components/styled";
import * as Colors from "../../../config/colors";
import { useSelector } from "../../../store/hooks";
import { AppDispatch } from "../../../store/store";

import moment from "moment";
import { setChangePolicyStatusDialog, setVehicle } from "store/vehicle";
import { getSelectStyles } from "../../../utils/utils";
import {
  SignificantValues,
  findSignificantValuesOnGivenDateAndInsuranceLine,
} from "./transformForForm";

export const ChangePolicyStatusDialog = () => {
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;
  const date = useSelector((s) => s.vehicle.changePolicyStatusDialog.date);
  const item = useSelector((s) => s.vehicle.changePolicyStatusDialog.item);
  const timelineData = useSelector((s) => s.timeline.timeline.data);
  const vehicle = useSelector((s) => s.vehicle.vehicle);

  const {
    mutate: onChangeVehiclePolicyStatus,
    isLoading: changeStatusInProgress,
  } = useChangeVehiclePolicyStatus();

  const addNew = useSelector((s) => s.vehicle.changePolicyStatusDialog.addNew);
  const phraseActivated = usePhraseActivationStatus().activated;
  const dispatch: AppDispatch = useDispatch();
  const taxonomy = useSelector((s) => s.taxonomy);

  const userRole = useSelector((s) => s.auth.userRole);

  // const isOptionDisabled = useIsVehiclePolicyStatusOptionDisabled(
  //   item || undefined
  // );
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const close = () => {
    dispatch(
      setChangePolicyStatusDialog({
        isOpen: false,
        date: null,
        item: null,
        addNew: null,
      })
    );
  };

  // const vehiclePolicyStatusTaxCode =
  //   taxonomy.VehiclePolicyStatusCode.byId[item?.associatedObject.statusCode]
  //     ?.code;

  let allowedStatusCodes = useMemo(() => {
    let allowedStatusCodes = [];

    allowedStatusCodes.push("VehiclePolicyStatusCode.PENDING");
    allowedStatusCodes.push("VehiclePolicyStatusCode.WAITING_FOR_INSURER");
    allowedStatusCodes.push("VehiclePolicyStatusCode.WAITING_FOR_CLIENT");
    allowedStatusCodes.push("VehiclePolicyStatusCode.WAITING_FOR_REGISTRATION");
    allowedStatusCodes.push("VehiclePolicyStatusCode.WAITING_FOR_CHECKING");
    allowedStatusCodes.push("VehiclePolicyStatusCode.WAITING_FOR_USER");
    allowedStatusCodes.push("VehiclePolicyStatusCode.CLOSED");

    if (item.associatedObject) {
      allowedStatusCodes = allowedStatusCodes.filter((code) => {
        return (
          code !==
          taxonomy.VehiclePolicyStatusCode.byId[
            item.associatedObject.statusCode
          ].code
        );
      });
    }
    return allowedStatusCodes;
  }, []);

  const options = useMemo(() => {
    return taxonomy.VehiclePolicyStatusCode.items
      .filter((tax) => allowedStatusCodes.includes(tax.code))
      .map((tax) => ({
        label: t(tax.code),
        value: tax.id,
        color: Colors.vehiclePolicyStatus[tax.code],
        code: tax.code,
      }));
  }, [taxonomy.VehiclePolicyStatusCode.items, allowedStatusCodes, t]);

  const onSubmit = (data) => {
    if (item) {
      const values: SignificantValues =
        findSignificantValuesOnGivenDateAndInsuranceLine(
          addNew,
          item,
          timelineData,
          data.vehicleLastStatusDate,
          data.vehicleLastStatusTime
        );
      onChangeVehiclePolicyStatus(
        {
          //rowVersion: item.rowVersion,
          fleetId: vehicle.fleetId,
          vehicleId: vehicle.vehicleId,
          vehiclePolicyStatusId: values.vehiclePolicyStatusId,
          body: {
            validFromDateTime: values.validFromDateTime,

            vehiclePremiumId: values.vehiclePremiumId,
            vehiclePremiumVersionId: values.vehiclePremiumVersionId,

            vehiclePolicyStatusCode: data.vehiclePolicyStatusCode.value,
            comment: data.vehicleLastStatusComment,
          },
        },
        {
          onSuccess: (res) => {
            dispatch(
              setVehicle(Array.isArray(res.data) ? res.data[0] : res.data)
            );
            dispatch(
              setChangePolicyStatusDialog({
                isOpen: false,
                item: null,
                vehicleIds: [],
                date: null,
                addNew: null,
              })
            );
            toast.success(t("bfm.statusChanged"));
          },
        }
      );
    }
  };

  const title = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {addNew ? t("bfm.insertPolicyStatus") : t("bfm.changePolicyStatus")}
    </div>
  );

  const initialValues = useMemo(() => {
    let currentOption = null;
    if (!item) return {};
    if (!addNew) {
      const statusTax =
        taxonomy.VehiclePolicyStatusCode.byId[item.associatedObject.statusCode];
      if (statusTax) {
        currentOption = {
          label: t(statusTax?.code),
          value: item.associatedObject.statusCode,
          color: Colors.vehiclePolicyStatus[statusTax?.code],
        } as any;
      }
    }
    let vehicleLastStatusDate = date;
    let vehicleLastStatusTime = date
      ? moment(date)
          .set("hours", 7)
          .set("minutes", 0)
          .set("seconds", 0)
          .toDate()
      : moment().set("hours", 7).set("minutes", 0).set("seconds", 0).toDate();
    let pendingComment = "";

    return {
      from: addNew ? null : currentOption,
      vehicleLastStatusDate,
      vehicleLastStatusTime,
      pendingComment,
    };
  }, [item, taxonomy, t, date]);
  if (!item) return null;
  return (
    <Dialog
      hidden={false}
      onDismiss={close}
      dialogContentProps={{
        type: DialogType.normal,
        title,
        showCloseButton: true,
      }}
      minWidth={400}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={didTrySubmit}
        validateOnBlur={didTrySubmit}
        enableReinitialize
      >
        {({ submitForm }) => {
          return (
            <>
              {!addNew ? (
                <>
                  <Label>{t("bfm.statusChangeFrom.label")}</Label>
                  <FormikField
                    name="from"
                    render={({ field }) => {
                      return (
                        <VehiclePolicyStatusSelect
                          {...field}
                          options={[field.value]}
                          isDisabled
                          styles={getSelectStyles(false, theme)}
                        />
                      );
                    }}
                  />

                  <VSpace />
                </>
              ) : null}

              <Label required>{t("bfm.policyStatusChangeTo.label")}</Label>
              <FormikField
                name="vehiclePolicyStatusCode"
                validate={(value) => combine(required(value))}
                render={({
                  field,
                  form: { setFieldValue },
                  meta: { error },
                }) => {
                  return (
                    <>
                      <VehiclePolicyStatusSelect
                        {...field}
                        onChange={(option) => {
                          if (!option) return;
                          setFieldValue("vehiclePolicyStatusCode", option);
                        }}
                        options={options}
                        isOptionDisabled={(o) =>
                          // !addNew ? isOptionDisabled(o.value) :
                          false
                        }
                        styles={getSelectStyles(!!error, theme)}
                        portalTarget={undefined}
                      />
                      {!!error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  );
                }}
              />
              <VSpace />
              <Field
                name="vehicleLastStatusDate"
                timeName="vehicleLastStatusTime"
                type="datetime"
                label={t("bfm.date.label")}
                validate={(value) => combine(required(value))}
                isRequired
                d={({ t, values, errors, setFieldValue }) => ({
                  label: t("bfm.date.label"),
                  values,
                  errors,
                  setFieldValue,
                  defaultValue: values?.vehicleLastStatusDate,
                  defaultTimeValue: values?.vehicleLastStatusTime,
                })}
              />
              <Field
                name="vehicleLastStatusComment"
                type="text"
                label={t("bfm.comment.label")}
                isMultiline
                rows={4}
              />
              <VSpace height={25} />
              <DialogFooter>
                <SaveButton
                  onClick={() => {
                    setDidTrySubmit(true);
                    submitForm();
                  }}
                />
                <DefaultButton onClick={close} text={t("bfm.close.label")} />
              </DialogFooter>
              {changeStatusInProgress && <FormSpinner />}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const SaveButton = ({
  onClick,
  isLoading,
  isDisabled,
}: {
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <StyledPrimaryButton
      onClick={onClick}
      text={t("bfm.saveStatusChange.label")}
      disabled={isDisabled}
      style={{
        marginRight: "5px",
      }}
      iconProps={{
        iconName: isLoading ? "ProgressRingDots" : "",
      }}
    />
  );
};

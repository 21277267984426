import { ColumnOptionsUtils } from "@greco/components";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import Axios, { AxiosError } from "axios";
import { replace } from "connected-react-router";
import { sortedUniq } from "lodash";
import {
  call,
  cancelled,
  delay,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { defaultColumns as defaultColumnsFile } from "../pages/fleet/VehicleListTable/columns";

import { ExpandedColumn } from "../types/columns";
import {
  Fleet,
  FleetWithPremiumsResponse,
  Setting,
  VehicleRedux,
  VehiclesAzureResponse,
} from "../types/types";
import { hydrateColumn } from "../utils/columns";
import * as API from "./api/api";
import { selectAllFleetPageColumns } from "./fleetPageColumnOptions";
import { LoadStatus, RootState } from "./store";
import { createAsyncRoutine, handleAxiosError } from "./util";

const initCancelVehicleStatusDialog = {
  fleetId: null,
  vehicleId: null,
  isOpen: false,
};

const initDuplicateWithPremiumsDialog = {
  isOpen: false,
  fleetId: null,
  vehicleId: null,
  isTradeLicensePlate: false,
};
const initCorrectStatusDateDialog = {
  isOpen: false,
  vehicle: null,
};

const vehiclesAdapter = createEntityAdapter<VehicleRedux>({
  selectId: (v) => v.vehicleId,
});

type FleetPageStateWithoutEntities = {
  loadVehiclesStatus: LoadStatus;
  loadFleetStatus: LoadStatus;

  showTEMP: boolean;
  search: string;
  sort: any;
  vehicleStatusFilter: number | null;
  fleet: FleetWithPremiumsResponse | null;
  vehiclesTotalCount: number;
  deleteTEMPDialog: {
    isOpen: boolean;
    fleetId: VehicleRedux["fleetId"] | null;
    vehicleId: VehicleRedux["vehicleId"] | null;
  };
  changeVehicleStatusDialog: {
    isOpen: boolean;
    fleetId: VehicleRedux["fleetId"] | null;
    vehicle?: VehicleRedux | null;
    vehicleIds?: number[];
  };
  duplicateVehicleWithPremiumsDialog: {
    isOpen: boolean;
    isTradeLicensePlate: boolean;
    fleetId: VehicleRedux["fleetId"] | null;
    vehicleId: VehicleRedux["vehicleId"] | null;
  };
  changeVehicleIssuingStatusDialog: {
    isOpen: boolean;
    fleetId: VehicleRedux["fleetId"] | null;
    vehicle?: VehicleRedux | null;
    vehicleIds?: number[];
  };
  changeVehiclePendingIssuingStatusDialog: {
    isOpen: boolean;
    fleetId: VehicleRedux["fleetId"] | null;
    vehicle?: VehicleRedux | null;
    vehicleIds?: number[];
  };
  selectedViewId: Setting["userAppSettingId"] | null;

  cancelVehicleStatusDialog: {
    isOpen: boolean;
    fleetId: VehicleRedux["fleetId"] | null;
    vehicleId: VehicleRedux["vehicleId"] | null;
  };
  correctStatusDateDialog: {
    isOpen: boolean;
    vehicle: VehicleRedux | null;
  };

  deleteDialog: {
    isOpen: boolean;
    fleetId: VehicleRedux["fleetId"] | null;
    vehicle?: VehicleRedux | null;
    vehicleIds?: number[];
  };
  prorataReportDialog: {
    isOpen: boolean;
    fleetId: VehicleRedux["fleetId"] | null;
    vehicleIds?: number[];
  };

  applicationReportDialog: {
    isOpen: boolean;
    fleetId: VehicleRedux["fleetId"] | null;
    vehicleIds?: number[];
  };

  selectedVehicleIds: Array<number> | null;
  selectedVehicleIndexes: number[];
};

const initialState = vehiclesAdapter.getInitialState({
  loadVehiclesStatus: "none",
  loadFleetStatus: "none",

  showTEMP: false,
  search: "",
  sort: {
    key: "vehicleLastModifiedAt",
    dir: "desc",
  },
  vehicleStatusFilter: null,
  fleet: null,
  vehiclesTotalCount: 0,
  deleteTEMPDialog: {
    isOpen: false,
    fleetId: null,
    vehicleId: null,
  },
  changeVehicleStatusDialog: {
    isOpen: false,
    fleetId: null,
    vehicle: null,
  },
  changeVehicleIssuingStatusDialog: {
    isOpen: false,
    fleetId: null,
    vehicle: null,
  },
  changeVehiclePendingIssuingStatusDialog: {
    isOpen: false,
    fleetId: null,
    vehicle: null,
  },
  duplicateVehicleWithPremiumsDialog: {
    isOpen: false,
    fleetId: null,
    vehicleId: null,
    isTradeLicensePlate: false,
  },
  selectedViewId: null,

  correctStatusDateDialog: initCorrectStatusDateDialog,
  cancelVehicleStatusDialog: initCancelVehicleStatusDialog,

  deleteDialog: {
    isOpen: false,
    vehicle: null,
  },
  prorataReportDialog: {
    isOpen: false,
    fleetId: null,
    vehicleIds: null,
  },

  applicationReportDialog: {
    isOpen: false,
    fleetId: null,
    vehicleIds: null,
  },

  selectedVehicleIds: [],
  selectedVehicleIndexes: [],
} as FleetPageStateWithoutEntities);

export type FleetPageState = FleetPageStateWithoutEntities &
  EntityState<VehicleRedux>;

export const loadVehiclesActions = createAsyncRoutine<
  {
    isReset: boolean;
    top?: number;
    skip?: number;
    shouldDelay?: boolean;
    shouldEmptyVehicles?: boolean;
  },
  void,
  {
    response: VehiclesAzureResponse;
    isReset: boolean;
    isTEMP: boolean;
  },
  any
>("fleetPage/loadVehicles");

export const loadFleetActions = createAsyncRoutine<
  Fleet["fleetId"],
  void,
  FleetWithPremiumsResponse,
  any
>("fleetPage/loadFleet");

export const loadVehiclesExcelActions = createAsyncRoutine<{
  fleetId: number;
  onSuccess?: (vehiclesAzureResponse: any) => void;
  onError?: (err: AxiosError<any>) => void;
}>("fleetPage/loadVehiclesExcel");

export const fleetPageSlice = createSlice({
  name: "fleetPage",
  initialState,
  reducers: {
    setSearch: (s, a: PayloadAction<FleetPageState["search"]>) => {
      s.search = a.payload;
    },

    setVehicleStatusFilter: (
      s,
      a: PayloadAction<FleetPageState["vehicleStatusFilter"]>
    ) => {
      s.vehicleStatusFilter = a.payload;
    },

    setRemoveTEMPVehicle: (s, a) => {
      vehiclesAdapter.removeOne(s, a.payload?.vehicleId);
    },

    setSort: (s, a: PayloadAction<FleetPageState["sort"]>) => {
      s.sort = a.payload;
    },
    setShowTEMP: (s, a: PayloadAction<FleetPageState["showTEMP"]>) => {
      s.showTEMP = a.payload;
    },
    setDeleteTEMPDialog: (
      s,
      a: PayloadAction<FleetPageState["deleteTEMPDialog"]>
    ) => {
      s.deleteTEMPDialog = a.payload;
    },
    setChangeVehicleStatusDialog: (
      s,
      a: PayloadAction<FleetPageState["changeVehicleStatusDialog"]>
    ) => {
      s.changeVehicleStatusDialog = a.payload;
    },
    setChangeVehicleIssuingStatusDialog: (
      s,
      a: PayloadAction<FleetPageState["changeVehicleIssuingStatusDialog"]>
    ) => {
      s.changeVehicleIssuingStatusDialog = a.payload;
    },
    setChangeVehiclePendingIssuingStatusDialog: (
      s,
      a: PayloadAction<
        FleetPageState["changeVehiclePendingIssuingStatusDialog"]
      >
    ) => {
      s.changeVehiclePendingIssuingStatusDialog = a.payload;
    },
    setDuplicateVehicleWithPremiumsDialog: (
      s,
      a: PayloadAction<FleetPageState["duplicateVehicleWithPremiumsDialog"]>
    ) => {
      s.duplicateVehicleWithPremiumsDialog = a.payload;
    },

    setSelectedViewId: (
      s,
      a: PayloadAction<FleetPageState["selectedViewId"]>
    ) => {
      s.selectedViewId = a.payload;
    },
    setCancelVehicleStatusDialog: (
      s,
      a: PayloadAction<FleetPageState["cancelVehicleStatusDialog"]>
    ) => {
      s.cancelVehicleStatusDialog = a.payload;
    },
    closeCancelVehicleStatusDialog: (s) => {
      s.cancelVehicleStatusDialog = initCancelVehicleStatusDialog;
    },
    closeDuplicateWithPremiumsDialog: (s) => {
      s.duplicateVehicleWithPremiumsDialog = initDuplicateWithPremiumsDialog;
    },
    setCorrectStatusDateDialog: (
      s,
      a: PayloadAction<FleetPageState["correctStatusDateDialog"]>
    ) => {
      s.correctStatusDateDialog = a.payload;
    },
    closeCorrectStatusDateDialog: (s) => {
      s.correctStatusDateDialog = initCorrectStatusDateDialog;
    },
    setDeleteDialog: (s, a: PayloadAction<FleetPageState["deleteDialog"]>) => {
      s.deleteDialog = a.payload;
    },
    setProrataReportDialog: (
      s,
      a: PayloadAction<FleetPageState["prorataReportDialog"]>
    ) => {
      s.prorataReportDialog = a.payload;
    },

    setApplicationReportDialog: (
      s,
      a: PayloadAction<FleetPageState["applicationReportDialog"]>
    ) => {
      s.applicationReportDialog = a.payload;
    },

    resetState: (s) => {
      const exclude: (keyof FleetPageState)[] = [
        "sort",
        "search",
        "selectedViewId",
        "showTEMP",
      ];
      Object.keys(s).forEach((key) => {
        if (!exclude.includes(key as any)) {
          s[key] = initialState[key];
        }
      });
      vehiclesAdapter.removeAll(s);
    },
    toogleSelectedVehiclesIds: (state, action) => {
      state.selectedVehicleIds = action.payload;
    },
    removeAllVehicles: vehiclesAdapter.removeAll,
    unselectAllVehicles: (state) => {
      const allSelected = [...state.selectedVehicleIds];
      state.selectedVehicleIds = [];
      state.selectedVehicleIndexes = [];

      allSelected.forEach((id) => {
        vehiclesAdapter.updateOne(state, {
          id: id,
          changes: {
            isSelected: false,
          },
        });
      });
    },

    updateVehicleRow: (
      s,
      a: PayloadAction<{
        vehicle: VehicleRedux;
        checked: boolean;
        rowIndex: number;
      }>
    ) => {
      const { rowIndex } = a.payload;

      vehiclesAdapter.updateOne(s, {
        id: a.payload.vehicle.vehicleId,
        changes: {
          isSelected: a.payload.checked,
        },
      });

      if (!!!a.payload.checked) {
        const index = s.selectedVehicleIds.indexOf(
          +a.payload.vehicle.vehicleId
        );
        if (index > -1) {
          s.selectedVehicleIds.splice(index, 1);
          s.selectedVehicleIndexes = s.selectedVehicleIndexes.filter(
            (index) => index !== rowIndex
          );
        }
      } else {
        s.selectedVehicleIds.push(+a.payload.vehicle.vehicleId);
        s.selectedVehicleIndexes.push(rowIndex);
      }
    },
    updateMultipleVehicleRow: (
      state,
      action: PayloadAction<{
        vehicle: VehicleRedux;
        checked: boolean;
        rowIndex: number;
      }>
    ) => {
      const allIndexes = [
        ...state.selectedVehicleIndexes,
        action.payload.rowIndex,
      ];

      const selectStartAt = Math.min(...allIndexes);
      const selectEndAt = Math.max(...allIndexes);

      const slicedVehicleListIds = state.ids.slice(selectStartAt, selectEndAt);
      const rowListToSelect = sortedUniq(slicedVehicleListIds).map((item) =>
        Number(item)
      );

      rowListToSelect.forEach((id) => {
        vehiclesAdapter.updateOne(state, {
          id: id,
          changes: {
            isSelected: true,
          },
        });
        if (!state.selectedVehicleIds.includes(id)) {
          state.selectedVehicleIds.push(id);
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadVehiclesActions.loading, (s, a) => {
      s.loadVehiclesStatus = "loading";
    });
    builder.addCase(loadVehiclesActions.success, (s, a) => {
      s.loadVehiclesStatus = "success";
      const { isReset, isTEMP, response } = a.payload;
      const mappedVehicles = response.value.map((v) => ({
        ...v,
        isSelected: false,
        isTEMP,
        vehicleStatusCode:
          v.vehicleStatusCode !== null
            ? Number(v.vehicleStatusCode)
            : v.vehicleStatusCode,
      }));
      if (isReset) {
        s.selectedVehicleIds = [];
        s.selectedVehicleIndexes = [];

        vehiclesAdapter.setAll(s, mappedVehicles as any);
      }
      //
      else {
        vehiclesAdapter.addMany(s, mappedVehicles as any);
      }
      s.vehiclesTotalCount = response["@odata.count"];
    });
    builder.addCase(loadVehiclesActions.error, (s, a) => {
      s.loadVehiclesStatus = "error";
    });
    builder.addCase(loadFleetActions.loading, (s, a) => {
      s.loadFleetStatus = "loading";
    });
    builder.addCase(loadFleetActions.success, (s, a) => {
      s.loadFleetStatus = "success";
      s.fleet = a.payload;
    });
    builder.addCase(loadFleetActions.error, (s, a) => {
      s.loadFleetStatus = "error";
    });
  },
});

export const {
  reducer: fleetPageReducer,
  actions: {
    setSearch: setSearchFleetPage,
    setVehicleStatusFilter,
    setRemoveTEMPVehicle,
    setSort: setSortFleetPage,
    setShowTEMP,
    setDeleteTEMPDialog,
    setChangeVehicleStatusDialog,
    setChangeVehicleIssuingStatusDialog,
    setChangeVehiclePendingIssuingStatusDialog,
    setSelectedViewId: setSelectedViewIdFleet,
    setCancelVehicleStatusDialog,
    removeAllVehicles,
    toogleSelectedVehiclesIds,
    closeCancelVehicleStatusDialog,
    closeDuplicateWithPremiumsDialog,
    setCorrectStatusDateDialog,
    closeCorrectStatusDateDialog,
    resetState,
    setDeleteDialog,
    updateVehicleRow,
    updateMultipleVehicleRow,
    unselectAllVehicles,
    setDuplicateVehicleWithPremiumsDialog,
    setApplicationReportDialog,
    setProrataReportDialog,
  },
} = fleetPageSlice;

export const { selectAll: selectAllVehicles, selectById: selectByIdVehicles } =
  vehiclesAdapter.getSelectors<RootState>((s) => s.fleetPage);

export function* loadVehiclesSaga() {
  yield takeLatest(
    loadVehiclesActions.trigger.type,
    function* (a: ReturnType<typeof loadVehiclesActions.trigger>) {
      if (a.payload.shouldDelay !== false) {
        yield delay(300);
      }
      if (a.payload.shouldEmptyVehicles) {
        yield put(removeAllVehicles());
      }
      const cancelSource = Axios.CancelToken.source();
      const { isReset, top, skip } = a.payload;
      let actualTop = top;
      let actualSkip = skip;
      if (isReset) {
        // actualTop = *45; // ITEMS PER PAGE -
        actualTop = 40;
        actualSkip = 0;
      }
      const showTEMP = yield select((s) => s.fleetPage.showTEMP);
      const search = yield select((s) => s.fleetPage.search);
      const sort = yield select((s) => s.fleetPage.sort);
      const columns = yield select(selectAllFleetPageColumns);
      const isTradeLicensePlate = yield select(
        (s) => s.tradeLicensePlates.isTradeLicensePlate
      );
      const vehicleStatusFilter = yield select(
        (s) => s.fleetPage.vehicleStatusFilter
      );
      // const historyLocation = yield select((s) => s.router.location);
      const historyLocation = window.location.pathname;

      const splitedPathname = historyLocation.split("/");
      const locationFleetId = splitedPathname[splitedPathname?.length - 1];
      // const fleetMatchPath = routes.fleet.matchPath(historyLocation);
      // const tradeLicensePlatesMatchPath =
      //   routes.tradeLicensePlates.matchPath(historyLocation);

      // const fleetMatchPath = matchPath(
      //   {
      //     path: historyLocation,
      //   },
      //   routes.fleet.getPath(locationFleetId)
      // );

      // const tradeLicensePlatesMatchPath = matchPath(
      //   {
      //     path: historyLocation,
      //   },
      //   routes.tradeLicensePlates.getPath(locationFleetId)
      // );

      let fleetId = Number(locationFleetId);

      try {
        yield put(loadVehiclesActions.loading());
        const res = yield call(API.searchVehicles, {
          skip: actualSkip!,
          top: actualTop!,
          isTradeLicensePlate,
          isTEMP: showTEMP,
          search,
          sort,
          vehicleStatusCode: vehicleStatusFilter || undefined,
          fleetId,
          columns,
          cancelToken: cancelSource.token,
        });
        yield put(
          loadVehiclesActions.success({
            response: res.data,
            isReset: a.payload.isReset,
            isTEMP: showTEMP,
          })
        );
      } catch (err) {
        handleAxiosError(err);
        yield put(loadVehiclesActions.error(err));
      } finally {
        if (yield cancelled()) {
          cancelSource.cancel();
        }
      }
    }
  );
}

function* setShowTEMPSaga() {
  yield takeEvery(
    setShowTEMP.type,
    function* (a: ReturnType<typeof setShowTEMP>) {
      // yield put(setSortFleetPage({ key: "licensePlate", dir: "asc" }));
      yield put(setSearchFleetPage(""));
      yield put(setVehicleStatusFilter(null));
      yield put(
        loadVehiclesActions.trigger({
          isReset: true,
          shouldEmptyVehicles: true,
        })
      );
    }
  );
}

function* setSearchFleetPageSaga() {
  yield takeEvery(setSearchFleetPage.type, function* () {
    yield put(
      loadVehiclesActions.trigger({
        isReset: true,
      })
    );
  });
}

function* setVehicleStatusFilterSaga() {
  yield takeEvery(setVehicleStatusFilter.type, function* () {
    yield put(
      loadVehiclesActions.trigger({
        isReset: true,
      })
    );
  });
}

function* setSortFleetPageSaga() {
  yield takeEvery(setSortFleetPage.type, function* () {
    yield put(
      loadVehiclesActions.trigger({
        isReset: true,
      })
    );
  });
}

// function* setColumnsFleetPageSaga() {
//   yield takeEvery(setColumns.type, function* () {
//     yield put(
//       loadVehiclesActions.trigger({
//         isReset: true,
//       })
//     );
//   });
// }

function* loadFleetSaga() {
  yield takeEvery(
    loadFleetActions.trigger.type,
    function* (a: ReturnType<typeof loadFleetActions.trigger>) {
      const fleetId = a.payload;
      try {
        yield put(loadFleetActions.loading());
        const res = yield call(API.getFleetWithPremiums, fleetId);
        yield put(loadFleetActions.success(res.data));
      } catch (err: any) {
        yield put(loadFleetActions.error(err));
        handleAxiosError(err);
        if (err.response.status === 403) {
          yield put(replace("/"));
        }
      }
    }
  );
}

function* setSelectedViewIdFleetSaga() {
  // yield takeEvery(setSelectedViewIdFleet.type, function* () {
  //   const state = yield select();
  //   const columns = selectColumnsSelectedView(state);
  //   yield put(setFleetPageTempColumns(columns));
  // });
}

export function* fleetPageSaga() {
  yield fork(loadVehiclesSaga);
  yield fork(setShowTEMPSaga);
  yield fork(setSearchFleetPageSaga);
  yield fork(setVehicleStatusFilterSaga);
  yield fork(setSortFleetPageSaga);
  yield fork(loadFleetSaga);
  // yield fork(setColumnsFleetPageSaga);
  yield fork(setSelectedViewIdFleetSaga);
}

export const selectIsAnyFiltered = createSelector(
  (s) => s.fleetPage.columns,
  (columns) => {
    return (columns as any)?.some((c) => {
      if (c.filter) {
        return ColumnOptionsUtils.isColumnFiltered(c.filter as any);
      }
      return false;
    });
  }
);

export const selectSelectedView = createSelector(
  (s) => s.fleetPage.selectedViewId,
  (s) => s.settings.entities,
  (id: any, entities: any) => {
    if (id === null) return null;
    const view = entities[id];
    if (!view) return null;
    return entities[id];
  }
);

export const selectIsViewSelected = (s: RootState) => {
  return s.fleetPage.selectedViewId !== null;
};

export const selectDefaultColumns = (s: RootState) => {
  let appSettings = s.fleetForm.fleetAppSettings;
  // if (appSettings === null) {
  //   appSettings = AppSettings.getInstance().getAppSettingsTypes()["Country.HU"];
  //   appSettings.COUNTRY_CODE = "Country.HU";
  // }
  // const countryCode = appSettings?.COUNTRY_CODE.replace("Country.", "");
  let retVal = appSettings?.DEFAULT_COLUMNS?.map((c) => {
    return defaultColumnsFile.find((dc) => dc.key === c);
  });
  // retVal = retVal?.map((c) => {
  //   if (c.key === "carUsageCode") {
  //     return {
  //       ...c,
  //       filter: {
  //         ...c.filter,
  //         taxonomyKey: "VehicleUsageType" + countryCode,
  //       },
  //     };
  //   } else {
  //     return c;
  //   }
  // });
  return retVal;
};

export const selectColumnsSelectedView = createSelector(
  [selectSelectedView, selectDefaultColumns],
  (view, defaultColumns) => {
    if (view === null) return defaultColumns;
    const columns = JSON.parse(view.userAppSettingValue) as ExpandedColumn[];
    const mappedColumns = columns?.reduce((arr, c) => {
      const hydratedColumn = hydrateColumn({
        column: c,
        defaultColumns: defaultColumns,
      });
      if (!hydratedColumn) return arr;
      arr.push(hydratedColumn);
      return arr;
    }, []);

    return mappedColumns;
  }
);

import { MUTATION_KEYS } from "hooks/data/queryKeys";
import { useIsMutating } from "react-query";
import { useSelector } from "store/hooks";

function useIsUpdateVehicleIssuingStatusLoading(vehicleId: string | number) {
  const updateVehicleIssuingStatusStatus = !!useIsMutating({
    mutationKey: [MUTATION_KEYS.changeVehicleIssuingStatus],
  });

  const updateMultipleVehicleIssuingStatusStatus = !!useIsMutating({
    mutationKey: [MUTATION_KEYS.changeMultipleVehicleIssuingStatus],
  });

  const changeVehicleIssuingStatusDialogVehicleIds = useSelector(
    (s) => s.fleetPage.changeVehicleIssuingStatusDialog.vehicleIds
  )?.map((item) => Number(item));

  const isLoading =
    (updateVehicleIssuingStatusStatus ||
      updateMultipleVehicleIssuingStatusStatus) &&
    changeVehicleIssuingStatusDialogVehicleIds?.includes(Number(vehicleId));

  return isLoading;
}

export default useIsUpdateVehicleIssuingStatusLoading;

import React from "react";

import { Dropdown, mergeStyleSets } from "@fluentui/react";
import { useTheme } from "styled-components";
import StatusCircle from "./StatusCircle";

type VehicleStatusOption = {
  label: string;
  value: number;
  color: string;
};

type Props = {
  value: VehicleStatusOption | null;
  onChange?: (value: VehicleStatusOption | null) => void;
  options: any[];
  isClearable?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isOptionDisabled?: (o: VehicleStatusOption) => boolean;
  styles?: Partial<any>;
  portalTarget?: any;
};

const pendingOptions = [
  "VehicleStatusCode.PENDING_FOR_ACTIVATION",
  "VehicleStatusCode.PENDING_FOR_SUSPENSION",
  "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
];
export const VehicleStatusSelect = ({
  value,
  onChange,
  options,
  isClearable,
  isLoading,
  isDisabled,
  isOptionDisabled,
  styles,
  portalTarget,
}: Props) => {
  const theme = useTheme();

  const onRenderOption = (option: any): JSX.Element => {
    const isPending = pendingOptions.includes(option.code);
    const classes = getClassNames(isPending);
    return (
      <div className={classes.itemWrap}>
        {option.color && (
          <div className={classes.item}>
            <StatusCircle color={option.color} />
          </div>
        )}
        {option.text}
      </div>
    );
  };

  const onRenderTitle = (options: any): JSX.Element => {
    const option = options[0];
    const isPending = pendingOptions.includes(option.code);
    const classes = getClassNames(isPending);

    return (
      <div className={classes.itemWrap}>
        {option.color && (
          <div className={classes.item}>
            <StatusCircle color={option.color} />
          </div>
        )}
        {option.text}
      </div>
    );
  };

  const optionsForFluentDropdown = options.map((item) => {
    return {
      ...item,
      text: item.label,
      key: item.value,
    };
  });
  return (
    <>
      <Dropdown
        style={{
          minWidth: "210px",
        }}
        styles={{
          root: {
            "& .ms-Dropdown-title": {
              borderColor: theme.palette.neutralTertiaryAlt,
            },
          },
        }}
        defaultSelectedKey={value?.value}
        onChange={(_, option) => {
          onChange && onChange(option as any);
        }}
        onRenderOption={onRenderOption}
        onRenderTitle={onRenderTitle}
        placeholder={""}
        label=""
        options={optionsForFluentDropdown}
        disabled={isDisabled}
      />
    </>
  );
};

const getClassNames = (isPending: boolean) =>
  mergeStyleSets({
    itemWrap: {
      display: "flex",
      alignItems: "center",
      gap: "6px",
    },
    item: {
      border: `1px dashed ${isPending ? "black" : "transparent"}`,
      borderRadius: "16px",
      width: "16px",
      height: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexShrink: 0,
    },
  });

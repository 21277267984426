import {
  ITooltipHostProps,
  ITooltipHostStyles,
  mergeStyleSets,
  TooltipHost,
  TooltipOverflowMode,
} from "@fluentui/react";
import React from "react";

const classNames = mergeStyleSets({
  // Applied to make content overflow (and tooltips activate)
  overflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "block" },
};

const FluentTooltip = ({ children, content, ...props }: ITooltipHostProps) => {
  return (
    <TooltipHost
      overflowMode={TooltipOverflowMode.Self}
      // The TooltipHost itself will overflow
      hostClassName={classNames.overflow}
      content={content}
      onTooltipToggle={() => true}
      styles={hostStyles}

      // In this mode, aria-describedby is automatically added/removed based on tooltip visibility
    >
      {children}
    </TooltipHost>
  );
};

export default React.memo(FluentTooltip);

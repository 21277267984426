import { CommandBarButton, TooltipHost, mergeStyles } from "@fluentui/react";
import { Links } from "components/navigation/Links";
import { NavigationBar, Right } from "components/styled";
import { routes, useCurrentRoute } from "config/routes";
import { isExternalOrReadonlyAdmin } from "form/util";
import { constants } from "library/constants";
import { ExportExcelButton } from "pages/fleet/navigation/ExportExcelButton";
import { FleetAnalytics } from "pages/fleet/navigation/FleetAnalytics";
import SharedLicensePlateBtn from "pages/fleet/navigation/SharedLicensePlateBtn";
import TradeLicensePlatesBtn from "pages/fleet/navigation/TradeLicensePlatesBtn";
import { UpdateFleetButton } from "pages/fleet/navigation/UpdateFleetButton";
import VehicleListBtn from "pages/fleet/navigation/VehicleListBtn";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "store/hooks";
import { useTheme } from "styled-components";
import { TimelineType, UserRole } from "types/types";
import { BookingComparisonDialog } from "./BookingComparisonDialog";
import { ProrataReportDialog } from "./ProrataReportDialog";

type Props = {
  refExcelExport?: any;
};

const getBtnClass = (theme) =>
  mergeStyles({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "300px",
    cursor: "pointer",
    padding: 5,
    color: theme.palette.black,
  });

const FleetNavigation = ({ refExcelExport }: Props) => {
  const { t } = useTranslation();
  const userRole = useSelector((s) => s.auth.userRole);
  const theme = useTheme();
  const { fleetId } = useParams() as any;
  const route = useCurrentRoute();
  const dispatch = useDispatch();

  const isProrataReportDialogOpen = useSelector(
    (s) => s.fleetPage.prorataReportDialog.isOpen
  );

  const fleet = useSelector((s) => s.fleetPage.fleet);
  const fleetAppSettings = useSelector((s) => s.fleetForm.fleetAppSettings);
  const fleetNamePremiums = useSelector(
    (s) => s.fleetForm?.fleetWithPremiums?.fleetName
  );
  const fleetAnalytics = useSelector((s) => s.analyticsPage.fleet);
  const fleets = useSelector((s) => s.fleetsPage.entities);

  const [bookingComparisonDialogOpen, setBookingComparisonDialogOpen] =
    useState(false);

  const onBookingComparisonDialogClose = useCallback(() => {
    setBookingComparisonDialogOpen((prev) => !prev);
  }, []);

  const isTradeLicensePlateRoute = route === constants.tradeLicensePlates;
  const isSharedLicensePlateRoute = route === constants.sharedLicensePlate;
  const isAnalyticsFleetRoute = route === constants.fleetAnalytics;
  const isEditFleetRoute = route === constants.editFleet;
  const isVehicleListRoute = route === constants.fleet;
  const isCreateFleetRoute = route === constants.createFleet;
  const isAnalyticsMain = route === constants.analytics;
  const btnClass = getBtnClass(theme);
  const fleetName =
    fleet?.fleetName ||
    fleetNamePremiums ||
    fleets[fleetId]?.fleetName ||
    fleetAnalytics?.fleetName;

  return (
    <>
      <NavigationBar>
        <Links />
        {!isCreateFleetRoute && !isAnalyticsMain && (
          <TooltipHost content={fleetName}>
            <p
              className={btnClass}
              onClick={() => routes.fleet.getPath(fleetId)}
            >
              {fleetName}
            </p>
          </TooltipHost>
        )}
        {refExcelExport && (
          <ExportExcelButton
            refExcelExport={refExcelExport}
            isFleetPage={true}
          />
        )}
        {/* {isVehicleListRoute && (
          <CommandBarButton
            styles={{
              root: {
                height: "32px",
              },
            }}
            iconProps={{
              iconName: "ReportLock",
            }}
            text={t("bfm.prorataReport.label")}
            onClick={() => {
              dispatch(
                setProrataReportDialog({
                  isOpen: true,
                  vehicleIds: [],
                  fleetId: "" + fleetId,
                })
              );
            }}
          />
        )} */}
        {!isExternalOrReadonlyAdmin(userRole) &&
          fleetAppSettings?.VEHICLE_TIMELINE_TYPES.includes(
            TimelineType.VEHICLE_PREMIUM_BOOKINGS
          ) &&
          (isVehicleListRoute || isTradeLicensePlateRoute) && (
            <CommandBarButton
              style={{
                height: "32px",
                marginLeft: "50px",
              }}
              iconProps={{
                iconName: "ReportDocument",
              }}
              text={t("bfm.bookingComparison.label")}
              onClick={() => {
                setBookingComparisonDialogOpen(true);
              }}
            />
          )}
        {!isCreateFleetRoute && !isAnalyticsMain && (
          <Right>
            <VehicleListBtn isActive={!!isVehicleListRoute} />
            {fleetAppSettings?.SHOW_TRADE_LICENSE_PLATES ? (
              <TradeLicensePlatesBtn isActive={!!isTradeLicensePlateRoute} />
            ) : null}
            {fleetAppSettings?.SHOW_SHARED_LICENSE_PLATES ? (
              <SharedLicensePlateBtn isActive={!!isSharedLicensePlateRoute} />
            ) : null}
            {userRole !== UserRole.external && (
              <UpdateFleetButton isActive={!!isEditFleetRoute} />
            )}
            <FleetAnalytics isActive={!!isAnalyticsFleetRoute} />
          </Right>
        )}
      </NavigationBar>
      {bookingComparisonDialogOpen && (
        <BookingComparisonDialog
          isOpen={bookingComparisonDialogOpen}
          onClose={onBookingComparisonDialogClose}
        />
      )}
      {isProrataReportDialogOpen && <ProrataReportDialog />}
    </>
  );
};

export default FleetNavigation;

import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  PrimaryButton,
} from "@fluentui/react";
import { Formik } from "formik";
import { useFleetVehicleReport } from "hooks/data/mutations/useFleetVehicleReport";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setProrataReportDialog } from "store/fleetPage";
import { useSelector } from "store/hooks";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { setYupLocale } from "utils/setYupLocale";
import * as yup from "yup";
import { toast } from "./FluentToast";
import { FormSpinner } from "./FormSpinner";
import { VSpace } from "./Spacer";
import { Field } from "./form/Field";
import { StyledPrimaryButton } from "./styled";

export const ProrataReportDialog = () => {
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;
  const dispatch = useDispatch();

  const prorataReportDialog = useSelector(
    (s) => s.fleetPage.prorataReportDialog
  );

  const close = () => {
    dispatch(
      setProrataReportDialog({
        isOpen: false,
        fleetId: null,
        vehicleIds: [],
      })
    );
  };

  const { vehicleIds, fleetId } = prorataReportDialog;
  const [didTrySubmit, setDidTrySubmit] = useState(false);

  const {
    data: excelReportResponse,
    mutate: onFleetVehicleReport,
    isLoading: isReportLoading,
  } = useFleetVehicleReport();

  const excelReportData = excelReportResponse?.data;

  const onSubmit = (data) => {
    const payload: any = {
      fleetId: fleetId,
      body: {
        //comment: data.comment,
        reportType: "prorata",
        validFromDate: data.validFromDate
          ? moment(data.validFromDate).format("YYYY-MM-DD")
          : null,
        validToDate: data.validToDate
          ? moment(data.validToDate).format("YYYY-MM-DD")
          : null,
        vehicleIds: vehicleIds.map((vid) => {
          return {
            fleetId: parseInt(fleetId),
            vehicleId: parseInt(vid.toString()),
          };
        }),
      },
    };
    onFleetVehicleReport(payload, {
      onSuccess: () => {
        toast.success(t("bfm.reportGeneratedSuccessfully.label"));
      },
    });
  };
  const title = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Icon
        // iconName="SyncStatus"
        style={{
          color: theme.palette.themePrimary,
          fontSize: "25px",
          marginRight: "5px",
        }}
      />
      {t("bfm.prorataReport.label")}
    </div>
  );

  const initialValues = useMemo(() => {
    return {
      validFromDate: null,
      validToDate: null,
      //comment: null,
    };
  }, []);

  const schema = useMemo(() => {
    setYupLocale(t);
    return yup.object({
      validFromDate: yup.mixed().nullable(),
      validToDate: yup.mixed().nullable(),
    });
  }, [t]);

  return (
    <Dialog
      hidden={false}
      onDismiss={close}
      dialogContentProps={{
        type: DialogType.normal,
        title,
        showCloseButton: true,
      }}
      minWidth={400}
      modalProps={{
        isBlocking: true,
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={didTrySubmit}
        validateOnBlur={didTrySubmit}
        enableReinitialize
        validationSchema={schema}
      >
        {({ submitForm }) => {
          return (
            <>
              {excelReportData ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "25px",
                  }}
                >
                  <PrimaryButton
                    title={excelReportData.name}
                    onClick={() => {
                      window.open(excelReportData.url);

                      close();
                    }}
                    text={t("bfm.downloadReport.label")}
                  />
                </div>
              ) : (
                <>
                  <Field
                    name="validFromDate"
                    type="date"
                    label={"bfm.vehicleForm.validFromDate.label"}
                    d={({ t, values, errors, setFieldValue }) => ({
                      label: "bfm.vehicleForm.validFromDate.label",
                      values,
                      errors,
                      setFieldValue,
                      defaultValue: values?.validFromDate,
                    })}
                  />
                  <Field
                    name="validToDate"
                    type="date"
                    label={"bfm.vehicleForm.validToDate.label"}
                    d={({ t, values, errors, setFieldValue }) => ({
                      label: "bfm.vehicleForm.validToDate.label",
                      values,
                      errors,
                      setFieldValue,
                      defaultValue: values?.validToDate,
                    })}
                  />
                  {/* <Field
                    name="comment"
                    type="text"
                    label={"bfm.comment.label"}
                    isMultiline
                    rows={4}
                    validate={(value) => combine(maxLength(value, 512, t))}
                  /> */}
                  <VSpace height={25} />
                  {(vehicleIds as []).length === 0 ? (
                    <span>{t("bfm.report.warning.label")}</span>
                  ) : null}
                  <DialogFooter>
                    <SaveButton
                      onClick={() => {
                        setDidTrySubmit(true);
                        submitForm();
                      }}
                    />
                    <DefaultButton
                      onClick={close}
                      text={t("bfm.close.label")}
                    />
                  </DialogFooter>
                </>
              )}
              {isReportLoading && <FormSpinner />}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const SaveButton = ({
  onClick,
  isLoading,
  isDisabled,
}: {
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <StyledPrimaryButton
      onClick={onClick}
      text={t("bfm.generateReport.label")}
      disabled={isDisabled}
      style={{
        marginRight: "5px",
      }}
      iconProps={{
        iconName: isLoading ? "ProgressRingDots" : "",
      }}
    />
  );
};

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import BookingField from "./BookingField";

const ReasonField = ({ premiumBookingReasonCode }) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);

  const reason = useMemo(() => {
    return t(
      taxonomy?.PremiumBookingReason?.byId[premiumBookingReasonCode]?.code
    );
  }, [premiumBookingReasonCode, t, taxonomy?.PremiumBookingReason?.byId]);
  return <BookingField value={reason} />;
};

export default ReasonField;

import { useTheme } from "styled-components";

import { pointer } from "d3";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IStyledTheme } from "theme/types";
import { useSelector } from "../../../../../store/hooks";
import { openContextMenu } from "../../../../../store/timeline";
import {
  TimelineContextMenuType,
  TimelineLegendSegmentItemType,
  TimelineSegmentType,
  TimelineType,
} from "../../../../../types/types";
import {
  displayVehicleInfo,
  invertColor,
  truncateTextIfLong,
} from "../../../../../utils/utils";
import { InfinityArrow } from "./InfinityArrow";
import { SharedLicensePlateSegmentTooltip } from "./SharedLicensePlateSegmentTooltip";

export const SharedLicensePlateSegments = ({ xScale, yScale, innerWidth }) => {
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;
  const dispatch = useDispatch();
  const types = useSelector((s) => s.timeline.types);
  const data = useSelector((s) => s.timeline.timeline.filteredData);
  const domain = useSelector((s) => s.timeline.timeline.domain);
  const timelineStart = useSelector((s) => s.timeline.timeline.startDate);
  const timelineEnd = useSelector((s) => s.timeline.timeline.endDate);
  const taxonomy = useSelector((s) => s.taxonomy);
  const { t } = useTranslation();

  const shouldMenusBeAvailable = !types.includes(TimelineType.VEHICLE_PREMIUM);

  const legend = useSelector((s) => s.timeline.timeline.legend);
  if (!data) return null;
  const licenseLegend = legend.find(
    (el) => el.segmentType === TimelineType.SHARED_LICENSE_PLATE_STATUS
  );

  const licensePlateItems = licenseLegend?.items?.filter(
    (item) =>
      item.itemType === TimelineLegendSegmentItemType.SHARED_LICENSE_PLATE
  );
  const licensePlateVehicles = licenseLegend?.items?.filter(
    (item) =>
      item.itemType ===
      TimelineLegendSegmentItemType.SHARED_LICENSE_PLATE_VEHICLE
  );
  const segments = data
    .filter(
      (segment) =>
        segment.domain.type === TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX ||
        segment.domain.type === TimelineType.SHARED_LICENSE_PLATE_MTPL
    )
    .map((d, index) => {
      let segmentClass = "";
      let fillColor = "";
      let strokeColor = "";
      let strokeWidth = "";
      if (d.segmentType === TimelineSegmentType.MAIN_SHARED_VEHICLE) {
        const licenseItem = licensePlateVehicles.find(
          (item) =>
            item.label ===
            displayVehicleInfo(
              d.associatedObject?.vehicleShortInfo,
              d.associatedObject?.premiumTypeCode,
              taxonomy,
              t
            )
        );
        segmentClass = "mark";
        fillColor = licenseItem.itemAttributes.fillColor;
        strokeColor = licenseItem.itemAttributes.strokeColor;
        strokeWidth = "3";
        if (d.associatedObject.totalProRataPremium === 0) {
          segmentClass = segmentClass + " transparent_segment";
        }
        if (isDarkMode) {
          fillColor = invertColor(fillColor);
          strokeColor = invertColor(strokeColor);
        }
      } else if (d.segmentType === TimelineSegmentType.VOID_BEFORE) {
        segmentClass = "void";
        fillColor = "white";
        strokeColor = "white";
        strokeWidth = "0";
      } else if (d.segmentType === TimelineSegmentType.VOID_BETWEEN) {
        segmentClass = "void";
        fillColor = "white";
        strokeColor = "white";
        strokeWidth = "0";
      } else if (d.segmentType === TimelineSegmentType.VOID_AFTER) {
        segmentClass = "void";
        fillColor = "white";
        strokeColor = "white";
        strokeWidth = "0";
      } else {
        return null;
      }

      const rectRef = React.createRef<SVGRectElement>();
      let x1 = xScale(d.startDate);
      let x2 = xScale(d.endDate);
      if (x1 < 0) x1 = 0;
      if (x2 > innerWidth) x2 = innerWidth;
      let width = x2 - x1;

      // \
      // /
      return (
        <SharedLicensePlateSegmentTooltip key={index} d={d}>
          <g
            id={
              "sharedlicenseplate1_" +
              (d.associatedObject &&
              d.associatedObject?.vehiclePremiumVersionSegmentId
                ? d.associatedObject.vehiclePremiumVersionSegmentId
                : index)
            }
            name={"s_premium_" + d.domain.value.replace("TimelineType.", "")}
          >
            <rect
              ref={rectRef}
              className={`${segmentClass} ${
                shouldMenusBeAvailable ? "clickable" : null
              }`}
              x={x1}
              width={width + 1}
              y={yScale(d.domain.value)}
              height={yScale.bandwidth()}
              fill={fillColor}
              onClick={(e) => {
                return dispatch(
                  openContextMenu({
                    type: TimelineContextMenuType.SHARED_LICENSE_PLATE,
                    item: d,
                    ref: { x: e.pageX, y: e.pageY },
                    date: xScale.invert(pointer(e)[0]),
                  })
                );
              }}
            ></rect>

            {d.segmentType !== TimelineSegmentType.VOID_BEFORE &&
            d.segmentType !== TimelineSegmentType.VOID_BETWEEN &&
            d.segmentType !== TimelineSegmentType.VOID_AFTER &&
            d.associatedObject?.isContinuing ? (
              <InfinityArrow
                yScale={yScale}
                strokeColor={strokeColor}
                d={d}
                x2={x2}
                domainValueFunc={(d) => d.domain.value}
              />
            ) : null}
            {d.segmentType === TimelineSegmentType.MAIN_SHARED_VEHICLE ? (
              <text
                fill={strokeColor}
                style={{
                  textAnchor: "middle",
                  fontSize: "0.75em",
                  fontWeight: "normal",
                  cursor: "pointer",
                  pointerEvents: "none",
                }}
                x={(x1 + x2) / 2}
                dy=".32em"
                y={yScale(d.domain.value) + yScale.bandwidth() / 2}
              >
                {truncateTextIfLong(
                  displayVehicleInfo(
                    d.associatedObject?.vehicleShortInfo,
                    d.associatedObject?.premiumTypeCode,
                    taxonomy,
                    t,
                    true
                  ),
                  x2 - x1,
                  8
                )}
              </text>
            ) : null}
          </g>
        </SharedLicensePlateSegmentTooltip>
      );
    });

  const segmentStatuses = data
    .filter(
      (segment) =>
        segment.domain.type === TimelineType.SHARED_LICENSE_PLATE_STATUS
    )
    .map((d, index) => {
      let segmentClass = "";
      let fillColor = "";
      let strokeColor = "";
      let strokeWidth = "";
      let strokeDasharray = null;
      if (d.segmentType === TimelineSegmentType.MAIN_SHARED_VEHICLE) {
        const licenseItem = licensePlateItems.find(
          (item) => item.label === d.associatedObject.licensePlate
        );
        segmentClass = "mark_plain";
        fillColor = licenseItem.itemAttributes.fillColor;
        strokeColor = licenseItem.itemAttributes.strokeColor;
        strokeWidth = "3";
        if (d.associatedObject.totalProRataPremium === 0) {
          segmentClass = segmentClass + " transparent_segment";
        }
        if (d.extraData.suspendedRange) {
          if (!types.includes(TimelineType.VEHICLE_PREMIUM)) {
            fillColor = "red";
            strokeColor = "white";
            strokeWidth = "0";
          } else {
            fillColor = "white";
            strokeDasharray = "5,5";
            strokeWidth = "2";
          }
        } else {
          if (!types.includes(TimelineType.VEHICLE_PREMIUM)) {
            fillColor = "green";
            strokeColor = "white";
            strokeWidth = "0";
          }
        }

        if (isDarkMode) {
          fillColor = invertColor(fillColor);
          strokeColor = invertColor(strokeColor);
        }

        if (d.extraData.suspendedRange) {
          if (!types.includes(TimelineType.VEHICLE_PREMIUM)) {
            fillColor = "red";
            strokeColor = "white";
            strokeWidth = "0";
          } else {
            fillColor = "white";
            strokeDasharray = "5,5";
            strokeWidth = "2";
          }
        } else {
          if (!types.includes(TimelineType.VEHICLE_PREMIUM)) {
            fillColor = "green";
            strokeColor = "white";
            strokeWidth = "0";
          }
        }
      } else if (d.segmentType === TimelineSegmentType.VOID_BEFORE) {
        segmentClass = "void_shared";
        fillColor = "white";
        strokeColor = "white";
        strokeWidth = "0";
      } else if (d.segmentType === TimelineSegmentType.VOID_BETWEEN) {
        segmentClass = "void_shared";
        fillColor = "white";
        strokeColor = "white";
        strokeWidth = "0";
      } else if (d.segmentType === TimelineSegmentType.VOID_AFTER) {
        segmentClass = "void_shared";
        fillColor = "white";
        strokeColor = "white";
        strokeWidth = "0";
      } else {
        return null;
      }

      const rectRef = React.createRef<SVGRectElement>();
      let x1 = xScale(d.startDate);
      let x2 = xScale(d.endDate);
      if (x1 < 0) x1 = 0;
      if (x2 > innerWidth) x2 = innerWidth;
      let width = x2 - x1;
      // \
      // /
      return (
        <SharedLicensePlateSegmentTooltip key={index} d={d}>
          <g
            id={
              "sharedlicenseplate2_" +
              (d.associatedObject &&
              d.associatedObject?.vehiclePremiumVersionSegmentId
                ? d.associatedObject.vehiclePremiumVersionSegmentId
                : index)
            }
            name={"s_plate_" + d.domain.value.replace("TimelineType.", "")}
          >
            <rect
              ref={rectRef}
              className={`${segmentClass} ${"clickable"}`}
              x={x1}
              width={width + 1}
              y={yScale(d.domain.value) + yScale.bandwidth() * (1 / 6)}
              height={yScale.bandwidth() * (2 / 3)}
              fill={fillColor}
              onClick={(e) => {
                return dispatch(
                  openContextMenu({
                    type: TimelineContextMenuType.SHARED_LICENSE_PLATE,
                    item: d,
                    ref: { x: e.pageX, y: e.pageY },
                    date: xScale.invert(pointer(e)[0]),
                  })
                );
              }}
            ></rect>

            {d.associatedObject?.isContinuing ? (
              <InfinityArrow
                yScale={yScale}
                strokeColor={strokeColor}
                d={d}
                x2={x2}
                domainValueFunc={(d) => d.domain.value}
              />
            ) : null}
            {types.includes(TimelineType.VEHICLE_PREMIUM) ? (
              <text
                fill={strokeColor}
                style={{
                  textAnchor: "middle",
                  fontSize: "0.75em",
                  fontWeight: "normal",
                  cursor: "pointer",
                  pointerEvents: "none",
                }}
                x={(x1 + x2) / 2}
                dy=".32em"
                y={yScale(d.domain.value) + yScale.bandwidth() / 2}
              >
                {truncateTextIfLong(
                  d.associatedObject?.licensePlate,
                  x2 - x1,
                  8
                )}
              </text>
            ) : null}
          </g>
        </SharedLicensePlateSegmentTooltip>
      );
    });

  const lines = domain
    .filter((d) => d.type === TimelineType.SHARED_LICENSE_PLATE_STATUS)
    .map((d, index) => {
      let x1 = xScale(timelineStart);
      let x2 = xScale(timelineEnd);
      if (x1 < 0) x1 = 0;
      if (x2 > innerWidth) x2 = innerWidth;
      return index === 0 ? (
        <line
          key={index}
          className="raquo"
          stroke={isDarkMode ? "#464646" : "#D9D9D9"}
          strokeWidth={2}
          x1={-150 + x1}
          x2={x2}
          y1={yScale(d.value) - yScale.bandwidth() / 4}
          y2={yScale(d.value) - yScale.bandwidth() / 4}
        />
      ) : null;
    });
  const lines2 = domain
    .filter((d) => d.type === TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX)
    .map((d, index) => {
      let x1 = xScale(timelineStart);
      let x2 = xScale(timelineEnd);
      if (x1 < 0) x1 = 0;
      if (x2 > innerWidth) x2 = innerWidth;
      return shouldMenusBeAvailable ? (
        <line
          key={index}
          className="raquo"
          stroke={isDarkMode ? "#464646" : "#D9D9D9"}
          strokeWidth={2}
          x1={-150 + x1}
          x2={x2}
          y1={yScale(d.value) + yScale.bandwidth() + yScale.bandwidth() / 4 + 1}
          y2={yScale(d.value) + yScale.bandwidth() + yScale.bandwidth() / 4 + 1}
        />
      ) : null;
    });
  return (
    <>
      {lines}
      {lines2}
      {segments}
      {segmentStatuses}
    </>
  );
};

import {
  Breadcrumb,
  Checkbox,
  DatePicker,
  Icon,
  IconButton,
  Nav,
  Pivot,
  PrimaryButton,
  Slider,
  Spinner,
  TextField,
} from "@fluentui/react";
import styled, { css, keyframes } from "styled-components";
import { financePivotHeight } from "../config/ui";
import { lightenDarkenColor } from "../utils/colors";

export const StyledDatePicker = styled(DatePicker)<{
  hasError: boolean;
  background?: string;
}>`
  .ms-TextField-fieldGroup {
    border: none;
    &::after {
      display: none;
    }
    background-color: ${(p) => p.background ?? undefined};
  }
  .ms-TextField-wrapper {
    border-bottom: 1px solid ${(p) => p.theme.palette.neutralTertiaryAlt};
    &:focus-within {
      border-color: ${(p) =>
        p.hasError ? p.theme.palette.red : p.theme.palette?.themePrimary};
    }
  }
  .ms-TextField-suffix {
    padding-right: 32px;
    background: transparent;
  }
  .ms-TextField.is-disabled {
    .ms-TextField-fieldGroup {
      background-color: ${(p) => p.background ?? undefined};
    }
    input {
      background-color: transparent;
    }
  }
`;

export const ErrorMessage = styled.div<{ pt?: number }>`
  font-size: 12px;
  font-weight: 400;
  color: rgb(164, 38, 44);
  padding-top: ${(p) => (p.pt !== undefined ? `${p.pt}px` : `5px`)};
`;

export const StyledTextField = styled(TextField)<{
  hasError?: boolean;
  background?: string;
}>`
  &.is-active {
    .ms-TextField-fieldGroup {
      border-color: ${(p) =>
        p.hasError ? p.theme.palette.red : p.theme.palette?.themePrimary};
    }
  }
  &.is-active.hasError {
    .ms-TextField-fieldGroup {
      border-color: ${(p) => p.theme.palette.red};
    }
  }
  .ms-TextField-fieldGroup {
    border-bottom: 1px solid ${(p) => p.theme.palette.neutralTertiaryAlt};
    border-radius: 0;
    background-color: ${(p) => p.background ?? undefined};
  }
  &&& .ms-TextField-suffix {
    border: 0;
  }
  &.is-disabled {
    input {
      background: ${(p) => p.background ?? p.theme.palette.white};
    }
  }
  &.noMargin {
    margin-bottom: 0;
  }
  textarea[disabled] {
    background: ${(p) => p.background ?? p.theme.palette.white};
  }
`;

export const CenteredCell = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCheckbox = styled(Checkbox)<{ background?: string }>`
  margin: 10px 0;
  .ms-Checkbox-checkbox {
    background-color: ${(p) => p.background ?? undefined};
  }
  .ms-Checkbox-label {
    font-weight: 600;
  }
`;

export const StyledCheckboxRadio = styled(Checkbox)<{ background?: string }>`
  margin: 10px 0;
  .ms-Checkbox-checkbox {
    background-color: ${(p) => p.background ?? undefined};
    border-radius: 50%;
    border: 1px solid grey;
  }
  .ms-Checkbox-label {
    font-weight: 600;
  }
  .ms-Checkbox-checkbox i {
    font-size: smaller;
    font-weight: bold;
  }
`;

export const ListContextMenuIconButton = styled(IconButton)`
  &:hover {
    background: transparent;
    border: 1px solid ${(p) => p.theme.palette?.themePrimary};
  }
  &.is-expanded {
    background: transparent;
    border: 1px solid ${(p) => p.theme.palette?.themePrimary};
  }
`;

export const StyledSlider = styled(Slider)`
  width: 300px;
  .ms-Slider-slideBox:hover {
    .ms-Slider-inactive {
      background-color: #c7e0f4;
    }
  }
  .ms-Slider-inactive {
    background-color: #c8c6c4;
  }
`;

export const Concatenate = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const IndicatorIcon = styled(Icon)`
  color: #a4262c;
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
  margin-top: 0;
  padding: 5px 20px;
  border-bottom: 1px solid #efefef;
  .ms-Breadcrumb-listItem:first-child {
    & > span {
      padding-left: 0;
    }
    .ms-Breadcrumb-item {
      padding-left: 0;
    }
  }
  .ms-Breadcrumb-item {
    font-size: 14px;
  }
  .ms-Breadcrumb-listItem:last-child {
    & > span {
      font-weight: 600;
      padding-right: 0;
    }
  }
`;

export const StatusCellSpinner = styled(Spinner)`
  .ms-Spinner-circle {
    width: 15px;
    height: 15px;
    border-width: 2.5px;
  }
`;

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const spinAnimationRule = css`
  ${spinAnimation} 500ms linear infinite
`;

export const StyledPrimaryButton = styled(PrimaryButton)<{
  background?: string;
}>`
  background-color: ${(p) => p.background ?? p.theme.palette?.themePrimary};

  && {
    &.ms-Button--hasMenu + .ms-Button {
      .ms-Icon {
        color: unset;
      }
      &:hover {
        background-color: #106ebe;
      }
    }
    &:hover {
      background-color: #106ebe;
    }
  }
  &.isLoading {
    i {
      animation: ${spinAnimationRule};
    }
  }
  &&.is-disabled {
    background-color: ${(p) => p.theme.palette.neutralLighter};
    &.ms-Button--hasMenu + .ms-Button.is-disabled {
      background-color: ${(p) => p.theme.palette.neutralLighter};
    }
  }
  border: 0;
  &&:hover {
    border: 0;
    background-color: ${(p) => {
      if (p.background) {
        return lightenDarkenColor(p.background, 15);
      } else {
        return undefined;
      }
    }};
  }
`;

export const NavigationBar = styled.div`
  height: 44px;
  background: ${(p) => p.theme?.palette?.white};
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  border-bottom: 1px solid ${(p) => p.theme?.palette?.neutralLight};
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const Truncate = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${(props) => props.theme.palette.black};
`;

export const StyledNav = styled(Nav)`
  background: ${(p) => p.theme.palette.white};
  button {
    border-top: 0;
    border-left: 0;
    border-radius: 0;
    &::after {
      border-color: ${(p) => p.theme.palette?.themePrimary};
    }
    &:hover {
      background: ${(p) => p.theme.palette.neutralLighterAlt};
    }
  }
  .ms-Nav-compositeLink {
    &.is-selected {
      button {
        background: ${(p) => p.theme.palette.neutralLighter};
      }
      &:hover {
        button {
          background: ${(p) => p.theme.palette.neutralLighter};
        }
      }
    }
    &:hover {
      button {
        background: ${(p) => p.theme.palette.neutralLighterAlt};
      }
    }
  }
  .ms-Nav-linkText {
    color: ${(p) => p.theme.palette.neutralPrimary};
  }
  .ms-Nav-groupContent {
    margin: 0;
  }
`;

export const Flex = styled.div<{
  gap?: number;
  direction?: "row" | "column";
  justify?: "center" | "flex-end" | "flex-start";
}>`
  display: flex;
  align-items: center;
  gap: ${(p) => (p.gap !== undefined ? `${p.gap}px` : undefined)};
  flex-direction: ${(p) => p.direction};
  justify-content: ${(p) => p.justify};
  flex-wrap: wrap;
`;

export const ClearButton = styled(IconButton)`
  color: ${(p) => p.theme.palette.neutralPrimary};
  &:hover {
    color: ${(p) => p.theme.palette.neutralPrimary};
  }
`;

export const NoData = styled.div`
  color: ${(p) => p.theme.palette.neutralSecondary};
  font-size: 12px;
  font-style: italic;
`;

export const HSpace = styled.div<{ width?: number }>`
  width: ${(p) => (p.width ? `${p.width}px` : "5px")};
`;

export const StyledPivot = styled(Pivot)`
  width: 100%;
  height: ${financePivotHeight}px;
  .ms-Pivot-link {
    &:before {
      left: 0;
      right: 0;
    }
    margin: 0;
    padding: 0 12px;
  }
`;

export const FlexRight = styled.div`
  margin-left: auto;
`;

export const StyledIconButton = styled(IconButton)<{
  background?: string;
  color?: string;
}>`
  background-color: ${(p) => p.background ?? undefined};
  &:hover {
    background: ${(p) => {
      if (p.background) {
        return lightenDarkenColor(p.background, 15);
      } else {
        return undefined;
      }
    }};
    border: 1px solid ${(p) => p.background ?? undefined};
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  height: 100%;
`;

export const Left = styled.div`
  margin-left: auto;
`;

export const VSpace = styled.div<{ height?: number }>`
  height: ${(p) => (p.height ? `${p.height}px` : "10px")};
`;

export const TimelinePlaceholder = styled.div`
  background: ${(p) => p.theme.palette.white};
`;

export const SvgBackground = styled.svg`
  background: ${(p) => p.theme.palette.white};
`;

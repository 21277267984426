import React from "react";
import { TimelineDialogType } from "../../../types/types";
import { useFields } from "../../form/useFields";
import { modifyPolicyStatusPointDialogConfig } from "./modifyPolicyStatusPointDialogConfig";

const config = modifyPolicyStatusPointDialogConfig;

interface IProps {
  dialogType: TimelineDialogType;
}

export const ModifyPolicyStatusPointDialogContents = ({
  dialogType,
}: IProps) => {
  const { all: vehicleLastPolicyStatusDate } = useFields([
    config.vehicleLastPolicyStatusDate,
  ]);

  const { all: vehiclePolicyStatusCode } = useFields([
    config.vehiclePolicyStatusCode,
  ]);
  const { all: comment } = useFields([config.comment]);

  let left = <></>;
  let bottom = <></>;
  let right = null;
  if (dialogType === TimelineDialogType.CORRECT_POLICY_STATUS) {
    left = (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{}}>{vehiclePolicyStatusCode}</div>
        <div style={{}}>{vehicleLastPolicyStatusDate}</div>
      </div>
    );

    bottom = <>{comment}</>;
  }

  if (dialogType === TimelineDialogType.DELETE_POLICY_STATUS) {
    left = <></>;
    bottom = <>{comment}</>;
  }

  let dialogContents = (
    <>
      <div className="row">{left}</div>
      <div className="row">{bottom}</div>
    </>
  );
  if (right) {
    dialogContents = (
      <>
        <div className="row">{left}</div>
        <div className="row">{right}</div>
        <div className="row">{bottom}</div>
      </>
    );
  }

  return dialogContents;
};

import React from "react";
import moment, { Moment } from "moment";
import { useSelector } from "../../../../store/hooks";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

export const PastBackground = ({
  innerHeight,
  innerWidth,
  tickOffset = 13,
  xScale,
}) => {
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;

  let minDate = useSelector((s) => s.timeline.timeline.startDate);
  let endDate: Moment = moment(new Date());
  let width = xScale(endDate) < 0 ? 0 : xScale(endDate);
  if (width > innerWidth) width = innerWidth;
  return (
    <g className="tick" transform={`translate(${xScale(minDate)},0)`}>
      <rect
        className={isDarkMode ? "backgrounddark" : "background"}
        x={0}
        y={0}
        width={width}
        height={innerHeight}
      ></rect>
    </g>
  );
};

import { MUTATION_KEYS } from "hooks/data/queryKeys";
import { useIsMutating } from "react-query";
import { useSelector } from "store/hooks";

function useIsUpdateVehiclePendingIssuingStatusLoading(
  vehicleId: string | number
) {
  const updateInProgress = !!useIsMutating({
    mutationKey: [MUTATION_KEYS.changeMultipleVehiclePendingIssuingStatus],
  });

  const changeVehiclePendingIssuingStatusDialogVehicleIds = useSelector(
    (s) => s.fleetPage.changeVehiclePendingIssuingStatusDialog.vehicleIds
  )?.map((item) => Number(item));

  const isLoading =
    updateInProgress &&
    changeVehiclePendingIssuingStatusDialogVehicleIds?.includes(
      Number(vehicleId)
    );

  return isLoading;
}

export default useIsUpdateVehiclePendingIssuingStatusLoading;

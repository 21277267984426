import { CommandBarButton } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../../config/routes";

const saveBtnStyle = {
  root: {
    height: "100%",
  },
};

const EditButton = ({ disabled }: any) => {
  const { t } = useTranslation();
  const { fleetId, vehicleId } = useParams() as any;
  const navigate = useNavigate();
  return (
    <CommandBarButton
      styles={saveBtnStyle}
      onClick={() => {
        navigate(routes.editVehicle.getPath(fleetId, vehicleId));
      }}
      iconProps={{ iconName: "SingleColumnEdit" }}
      text={t("bfm.edit")}
      disabled={disabled}
    />
  );
};

export default EditButton;

import { mergeStyleSets } from "@uifabric/merge-styles";
import React from "react";
import FleetSidebarNav from "./FleetSidebarNav";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

const FleetSidebar = () => {
  const theme = useTheme() as IStyledTheme;
  const classes = mergeStyleSets({
    container: {
      // boxShadow: `${theme.palette.neutralPrimary} 2px 0px 5px -5px`,
      // flexBasis: "200px",
      // background: theme.palette.white,
      // position: "sticky",
      // top: 0,
      // minWidth: 200,
      boxShadow: `${theme.palette.neutralPrimary} 2px 0px 5px -5px`,
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      boxSizing: "border-box",
      justifyContent: "space-between",
      background: theme.palette.white,
      // height: "calc(100vh - 138px)",
      height: "100vh",
      position: "sticky",
      top: 95,
      width: 200,
    },
  });

  return (
    <div className={classes.container}>
      <FleetSidebarNav />
    </div>
  );
};
export default FleetSidebar;

import { ContextualMenuItemType } from "@fluentui/react";
import { isExternalOrReadonlyAdmin } from "form/util";
import _ from "lodash";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "../../../store/hooks";
import { openDialog } from "../../../store/timeline";
import {
  Timeline,
  TimelineDialogType,
  TimelineSegment,
  TimelineSegmentType,
  TimelineType,
} from "../../../types/types";

export const useSharedLicensePlateSegmentMenuItems = (
  timelineData: Timeline,
  types: TimelineType[]
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const segmentContextMenuItem = useSelector(
    (s) => s.timeline.contextMenu.item
  );
  const segmentContextMenuDate = useSelector(
    (s) => s.timeline.contextMenu.date
  );

  const segmentContextMenuItemDomainType = useSelector(
    (s) => s.timeline.contextMenu.item?.domain.type
  );

  const vehicleId = useSelector(
    (s) =>
      s.timeline.contextMenu.item?.associatedObject?.vehicleShortInfo?.vehicleId
  );
  const fleetId = useSelector(
    (s) => s.timeline.contextMenu.item?.associatedObject?.fleetId
  );

  const vehicleFleetId = useSelector((s) => s.vehicle?.vehicle?.fleetId);

  const vehiclePageVehicleId = useSelector(
    (s) => s.vehicle?.vehicle?.vehicleId
  );

  const userRole = useSelector((s) => s.auth.userRole);

  return useMemo(() => {
    const items = [];

    // Add and Insert
    if (
      segmentContextMenuItemDomainType !==
        TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX &&
      segmentContextMenuItemDomainType !==
        TimelineType.SHARED_LICENSE_PLATE_MTPL &&
      segmentContextMenuItemDomainType !==
        TimelineType.SHARED_LICENSE_PLATE_STATUS
    ) {
      return [];
    }
    if (
      segmentContextMenuItemDomainType ===
      TimelineType.SHARED_LICENSE_PLATE_STATUS
    ) {
      if (!isExternalOrReadonlyAdmin(userRole)) {
        if (!types.includes(TimelineType.VEHICLE_PREMIUM)) {
          let date = segmentContextMenuDate;
          items.push({
            key: "activate",
            text: t("bfm.activateInfo"),
            onClick: () => {
              dispatch(
                openDialog({
                  dialogType: TimelineDialogType.ACTIVATE_SHARED_LICENSE_PLATE,
                  contextMenuItem: segmentContextMenuItem,
                  contextMenuDate: date,
                  previousDialogData: null,
                })
              );
            },
          });
          items.push({
            key: "suspend",
            text: t("bfm.suspendInfo"),
            onClick: () => {
              dispatch(
                openDialog({
                  dialogType: TimelineDialogType.SUSPEND_SHARED_LICENSE_PLATE,
                  contextMenuItem: segmentContextMenuItem,
                  contextMenuDate: date,
                  previousDialogData: null,
                })
              );
            },
          });
        }
      }
      if (types.includes(TimelineType.VEHICLE_PREMIUM)) {
        vehicleFleetId &&
          items.push({
            key: "link",
            text: t("bfm.gotToSharedLicensePlateDetails"),
            onClick: () => {
              navigate(`/sharedLicensePlate/${vehicleFleetId}`);
            },
          });
      }
    }
    if (
      segmentContextMenuItemDomainType ===
        TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX ||
      segmentContextMenuItemDomainType ===
        TimelineType.SHARED_LICENSE_PLATE_MTPL
    ) {
      if (!types.includes(TimelineType.VEHICLE_PREMIUM)) {
        if (!isExternalOrReadonlyAdmin(userRole)) {
          if (
            segmentContextMenuItem.segmentType ===
            TimelineSegmentType.VOID_AFTER
          ) {
            let date = segmentContextMenuDate;

            items.push({
              key: "correct",
              text: t("bfm.addInfo"),
              onClick: () => {
                dispatch(
                  openDialog({
                    dialogType: TimelineDialogType.INSERT_SHARED_LICENSE_PLATE,
                    contextMenuItem: segmentContextMenuItem,
                    contextMenuDate: date,
                    previousDialogData: null,
                  })
                );
              },
            });
          }
          if (
            segmentContextMenuItem?.segmentType ===
              TimelineSegmentType.VOID_BEFORE ||
            segmentContextMenuItem?.segmentType ===
              TimelineSegmentType.VOID_BETWEEN
          ) {
            let date = segmentContextMenuDate;
            items.push({
              key: "correct",
              text: t("bfm.insertInfo"),
              onClick: () => {
                dispatch(
                  openDialog({
                    dialogType: TimelineDialogType.INSERT_SHARED_LICENSE_PLATE,
                    contextMenuItem: segmentContextMenuItem,
                    contextMenuDate: date,
                  })
                );
              },
            });
          }
          // Correct, Adjust, and Update
          if (
            segmentContextMenuItem?.segmentType ===
              TimelineSegmentType.MAIN_SHARED_VEHICLE &&
            segmentContextMenuItem?.associatedObject.isContinuing
          ) {
            items.push({
              key: "correct",
              text: t("bfm.correctInfo"),
              onClick: () => {
                dispatch(
                  openDialog({
                    dialogType: TimelineDialogType.CORRECT_SHARED_LICENSE_PLATE,
                    contextMenuItem: segmentContextMenuItem,
                    contextMenuDate: segmentContextMenuDate,
                  })
                );
              },
            });
          }
          if (
            segmentContextMenuItem?.segmentType ===
              TimelineSegmentType.MAIN_SHARED_VEHICLE &&
            !segmentContextMenuItem?.associatedObject.isContinuing
          ) {
            items.push({
              key: "correct",
              text: t("bfm.correctInfo"),
              onClick: () => {
                dispatch(
                  openDialog({
                    dialogType: TimelineDialogType.CORRECT_SHARED_LICENSE_PLATE,
                    contextMenuItem: segmentContextMenuItem,
                    contextMenuDate: segmentContextMenuDate,
                  })
                );
              },
            });
          }
          // Continue
          if (
            segmentContextMenuItem?.segmentType ===
              TimelineSegmentType.MAIN_SHARED_VEHICLE &&
            !segmentContextMenuItem?.associatedObject.isContinuing
          ) {
            const premiumTypeKey = segmentContextMenuItem.domain.value;
            const listOfDates = timelineData.data
              .filter((v: TimelineSegment) => {
                const retVal =
                  v.domain.value === premiumTypeKey &&
                  v.segmentType === TimelineSegmentType.MAIN_SHARED_VEHICLE;

                return retVal;
              })
              .map((v: TimelineSegment) => {
                return v.endDate;
              });
            const lastSegmentEndDateFromTheSameLine = moment(
              _.max(listOfDates)
            ).toDate();
            let isLastSegment = false;
            if (
              moment(lastSegmentEndDateFromTheSameLine).isSame(
                moment(segmentContextMenuItem.endDate),
                "day"
              )
            ) {
              isLastSegment = true;
            }
            if (isLastSegment) {
              items.push({
                key: "cancel",
                text: t("bfm.continueInfo"),
                onClick: () => {
                  dispatch(
                    openDialog({
                      dialogType:
                        TimelineDialogType.CONTINUE_SHARED_LICENSE_PLATE,
                      contextMenuItem: segmentContextMenuItem,
                      contextMenuDate: segmentContextMenuDate,
                    })
                  );
                },
              });
            }
          }
          // Cancel
          if (
            segmentContextMenuItem?.segmentType ===
              TimelineSegmentType.MAIN_SHARED_VEHICLE &&
            segmentContextMenuItem?.associatedObject.isContinuing
          ) {
            items.push({
              key: "divider_2",
              itemType: ContextualMenuItemType.Divider,
            });
            items.push({
              key: "cancel",
              text: t("bfm.cancelInfo"),
              onClick: () => {
                dispatch(
                  openDialog({
                    dialogType: TimelineDialogType.CANCEL_SHARED_LICENSE_PLATE,
                    contextMenuItem: segmentContextMenuItem,
                    contextMenuDate: segmentContextMenuDate,
                  })
                );
              },
            });
          }
          // Delete
          if (
            segmentContextMenuItem?.segmentType ===
            TimelineSegmentType.MAIN_SHARED_VEHICLE
          ) {
            items.push({
              key: "delete",
              text: t("bfm.deleteInfo"),
              onClick: () => {
                dispatch(
                  openDialog({
                    dialogType: TimelineDialogType.DELETE_SHARED_LICENSE_PLATE,
                    contextMenuItem: segmentContextMenuItem,
                    contextMenuDate: segmentContextMenuDate,
                  })
                );
              },
            });
          }
        }
      }
      if (!types.includes(TimelineType.VEHICLE_PREMIUM)) {
        fleetId &&
          vehicleId &&
          items.push({
            key: "divider_3",
            itemType: ContextualMenuItemType.Divider,
          });
      }
    }
    var shouldBeAbleToJumpToVehicle = false;
    if (
      !types.includes(TimelineType.VEHICLE_PREMIUM) ||
      (types.includes(TimelineType.VEHICLE_PREMIUM) &&
        vehiclePageVehicleId !== vehicleId)
    ) {
      shouldBeAbleToJumpToVehicle = true;
    }
    shouldBeAbleToJumpToVehicle &&
      fleetId &&
      vehicleId &&
      items.push({
        key: "link",
        text: t("bfm.gotToVehicleDetails"),
        onClick: () => {
          navigate(`/fleets/${fleetId}/vehicles/${vehicleId}`);
        },
      });

    return items;
  }, [
    dispatch,
    types,
    fleetId,
    vehicleFleetId,
    navigate,
    vehicleId,
    segmentContextMenuDate,
    segmentContextMenuItem,
    segmentContextMenuItemDomainType,
    userRole,
    t,
    timelineData,
    vehiclePageVehicleId,
  ]);
};

import { useMutation } from "react-query";
import { updatePremiumBookingIssuingStatus } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

export const updateBookingIssuingStatus = async ({
  fleetId,
  vehicleId,
  body,
}: {
  fleetId: string | number;
  vehicleId: string | number;
  body: {
    vehicleIssuingStatusCode: number;
    vehicleIssuingSubstatusCode: number;
    vehicleIssuingComment: string;
    vehiclePremiumBookingIds: string[];
    rowVersion: string;
  };
}) => {
  const response = await updatePremiumBookingIssuingStatus({
    fleetId,
    vehicleId,
    body,
  });

  return response;
};
export function useUpdateBookingIssuingStatus() {
  return useMutation(updateBookingIssuingStatus, {
    mutationKey: MUTATION_KEYS.updatePremiumBookingIssuingStatus,
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { TooltipHost } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

type Props = {
  hullIsPolicyIssued: boolean;
  isHullBasic: boolean;
};

function HullLicenseCell({ hullIsPolicyIssued, isHullBasic }: Props) {
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;

  return hullIsPolicyIssued ? (
    <>
      {/* <i
        className={isHullBasic ? "las la-dharmachakra" : "las la-anchor"}
        style={{
          fontSize: 18,
        }}
      ></i> */}
    </>
  ) : (
    <TooltipHost
      content={
        isHullBasic
          ? t("bfm.hullBasicPolicyIsNotIssued")
          : t("bfm.hullPolicyIsNotIssued")
      }
    >
      <i
        className={isHullBasic ? "las la-dharmachakra" : "las la-anchor"}
        style={{
          fontSize: 18,
          color: theme.palette.redDark,
        }}
      ></i>
    </TooltipHost>
  );
}

export default HullLicenseCell;

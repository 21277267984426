import {
  IShimmerElement,
  Shimmer,
  ShimmerElementType,
} from "@fluentui/react/lib/Shimmer";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import * as React from "react";

const classes = mergeStyleSets({
  navigation: {
    height: 44,
  },
  wrapp: {
    marginTop: 50,
  },
  content: {
    maxWidth: "80%",
    width: "80%",
    margin: "50px auto",
  },
});

const headerSkeleton: IShimmerElement[] = [
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "20%",
    height: 44,
  },
];

const mainFieldsSkeleton: IShimmerElement[] = [
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "30%",
    height: 44,
  },
  { type: ShimmerElementType.gap, width: "2%" },
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "30%",
    height: 44,
  },
  { type: ShimmerElementType.gap, width: "2%" },
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "30%",
    height: 44,
  },
];

const formSeleton: IShimmerElement[] = [
  {
    type: ShimmerElementType.line,
    verticalAlign: "bottom",
    width: "100%",
    height: 800,
  },
];

const FleetFormSkeleton = () => {
  return (
    <div className={classes.content}>
      <div className={classes.wrapp}>
        <Shimmer shimmerElements={formSeleton} />
      </div>
    </div>
  );
};

export default FleetFormSkeleton;

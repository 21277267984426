import { Icon, mergeStyles } from "@fluentui/react";
import { accent } from "config/colors";
import React, { useMemo } from "react";

type Props = {
  value: any;
};

function CheckColumn({ value }: Props) {
  const Content = useMemo(() => {
    if (value === null) return;
    if (value) {
      return <Icon iconName="SkypeCheck" style={{ color: accent.greenDark }} />;
    }
    return <Icon iconName="Cancel" style={{ color: accent.redDark }} />;
  }, [value]);

  return <div className={containerClass}>{Content}</div>;
}

export default CheckColumn;

const containerClass = mergeStyles({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { handleAxiosError } from "store/util";
import { Setting } from "types/types";
import { urlFormat } from "utils/utils";
import { MUTATION_KEYS, QUERY_KEYS } from "../queryKeys";

const uadUrl = urlFormat(process.env.REACT_APP_BFF_HOST_UAD);

export const deleteAppSetting = async (
  userAppSettingId: Setting["userAppSettingId"]
) => {
  return await axios.delete<Setting[]>(`${uadUrl}/deleteappsettings`, {
    data: [userAppSettingId],
  });
};

export function useDeleteAppSettings() {
  const queryClient = useQueryClient();

  return useMutation(deleteAppSetting, {
    mutationKey: MUTATION_KEYS.deleteAppSetting,

    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.appSettings);
    },
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import moment from "moment";
import { UserRole } from "types/types";

export const hasVehicleBeenActivated = ({ vehicle, route }) =>
  route !== "duplicateVehicle" &&
  vehicle &&
  vehicle?.vehicleFirstActivatedDate !== null;

export const isDisabledExternalAndHasBeenActivated = ({
  userRole,
  vehicle,
  route,
}: any) => {
  return (
    (userRole === UserRole.external &&
      hasVehicleBeenActivated({ vehicle, route })) ||
    route === "readOnlyVehicle"
  );
};

export const isDisabledExternalOrHasBeenActivated = ({
  userRole,
  vehicle,
  route,
}: any) => {
  return (
    userRole === UserRole.external ||
    hasVehicleBeenActivated({ vehicle, route }) ||
    route === "readOnlyVehicle"
  );
};

export const vehicleUserDefaultValue = (
  vehicleUser,
  route,
  defaultSettings
) => {
  let value = vehicleUser;

  if (route === "createVehicle") {
    const defaultSettingVehicleUser = defaultSettings?.find(
      (s) => s.attributeName === "vehicleUser"
    );
    if (!defaultSettingVehicleUser) return value;

    value = String(defaultSettingVehicleUser.attributeValue);
  }
  return value;
};

export const isDisabledExternal = ({ userRole, route }: any) =>
  userRole === UserRole.external || route === "readOnlyVehicle";

export const transformVehiclePayload = ({
  data,
  config,
  route,
  vehicle,
}: any) => {
  let payload = { ...vehicle, ...data };
  config?.forEach((configItem) => {
    if (configItem.field === "number") {
      payload[configItem.name] =
        payload[configItem.name]?.replaceAll(".", "") || null;
    }
    if (configItem.field === "option") {
      payload[configItem.name] = payload[configItem.name]?.value;
    }

    if (configItem.field === "checkbox") {
      payload[configItem.name] = payload[configItem.name] ? true : false;
    }
    if (configItem.field === "radio-group") {
      const fieldValue = payload[configItem.name];

      let payloadValue = null;
      if (fieldValue?.toString() === "true") {
        payloadValue = true;
      }
      if (fieldValue?.toString() === "false") {
        payloadValue = false;
      }

      payload[configItem.name] = payloadValue;
    }
    if (configItem.field === "date") {
      payload[configItem.name] =
        payload[configItem.name] &&
        moment(payload[configItem.name]).format("YYYY-MM-DD");
    }

    if (configItem.field === "double") {
      payload[configItem.name] = payload[configItem.name]
        ? Number(
            payload[configItem.name]
              ?.replaceAll(" ", "")
              ?.replaceAll(".", "")
              ?.replaceAll(",", ".")
          )
        : null;
    }
  });
  if (payload.vehicleIssuingStatusCode !== 2) {
    payload.vehicleIssuingSubstatusCode = undefined;
  }

  if (payload.hullIsBasic === "true" || !!payload.hullIsBasic) {
    payload.hullIsBasic = true;
  } else {
    payload.hullIsBasic = false;
  }

  if (route === "createVehicle" || route === "duplicateVehicle") {
    if (payload.excludeHorsePowerBasedTaxReason !== null) {
      payload.excludeHorsePowerBasedTax = true;
    } else {
      payload.excludeHorsePowerBasedTax = false;
      payload.horsePowerBasedTaxLastStartDate = null;
    }
  }
  if (route === "duplicateVehicle") {
    Object.keys(payload).forEach((key) => {
      if (
        !!!key.includes("horsePowerBasedTaxLastEndDate") &&
        !!!key.includes("horsePowerBasedTaxSuspensionDays") &&
        !!!key.includes("horsePowerBasedTaxActivationDays") &&
        !!!key.includes("horsePowerBasedTaxSuspensionDaysLimit") &&
        (key.includes("ProRata") ||
          key.includes("LastUpdateDate") ||
          key.includes("LastEndDate") ||
          key.includes("horsePower"))
      ) {
        delete payload[key];
      }
    });
    delete payload.annualPremiumSum;
    delete payload.proRataPremiumSum;
    delete payload.vehicleId;
  }
  return payload;
};

export const formatPreviewValue = (
  value: boolean | string | number,
  t: any
) => {
  if (typeof value === "boolean") {
    return value ? t("greco.yes") : t("greco.no");
  }
  return value?.toString();
};

export const formatDayMonthValue = (
  dayValue: string | number,
  monthValue: string | number
) => {
  const formattedDayValue =
    dayValue?.toString()?.length === 1 ? `0${dayValue}` : dayValue;
  const formattedMonthValue =
    monthValue?.toString()?.length === 1 ? `0${monthValue}` : monthValue;
  return `${formattedDayValue}${formattedMonthValue}`;
};

export const formatDayMonthValueForPayload = (dayMonth: string) => {
  const formattedDayMonth = dayMonth?.replace("/", "");
  return {
    dayField: Number(formattedDayMonth.substring(0, 2)),
    monthField: Number(formattedDayMonth.substring(2, 4)),
  };
};

export const parseDayMonthFromNumber = (dayMonth: number) => {
  if (!dayMonth)
    return {
      day: "",
      month: "",
    };
  let formatNumber = dayMonth / 100;

  const splitedNumber = formatNumber.toString().split(/[.,]/);

  const day = Number(splitedNumber[0]);
  const month =
    splitedNumber[1]?.length === 1
      ? Number(`${splitedNumber[1]}0`)
      : Number(splitedNumber[1]);

  return { day, month };
};

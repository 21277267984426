import {
  Checkbox,
  DefaultButton,
  mergeStyleSets,
  TooltipHost,
} from "@fluentui/react";
import CheckboxField from "components/controls/CheckboxField";
import ComboBoxField from "components/controls/ComboBoxField";
import DatePickerField from "components/controls/DatePickerField";
import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import NumberInputField from "components/controls/NumberInputField";
import TextInputField from "components/controls/TextInputField";
import { toast } from "components/FluentToast";
import { FormSpinner } from "components/FormSpinner";
import { StyledPrimaryButton } from "components/styled";
import { TSetDefaultSettingValues } from "components/timeline/dialogs/AddPremiumDialog/AddPremiumDialog";
import { Formik } from "formik";
import { useAddFleetPremium } from "hooks/data/mutations/useAddFleetPremium";
import { delay } from "library/delay";
import moment from "moment";
import { getInsurerBranchOffice } from "pages/fleet-form/FleetForm/tabs/InsurerDefaultSettings/utils";
import { formatDayMonthValue } from "pages/vehicle/helpers";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import GroupPolicyNumberField from "components/controls/GroupPolicyNumberField";
import { useGetFleetInsurerDefaultSettings } from "hooks/data/queries/getFleetInsurerDefaultSettings";
import { useParams } from "react-router";
import { createFieldPairValidation } from "store/util";
import { IFleetInsurerDefaultSetting, PremiumType } from "types/types";
import { setYupLocale } from "utils/setYupLocale";
import { formatDoubleFieldValue, stringToNumber } from "utils/utils";
import * as yup from "yup";
import {
  setDialog,
  setDialogDefaultValueOperation,
  setDialogInsurerSettingsOperation,
  setFleetWithPremiums,
} from "../../../../store/fleetForm";
import { useSelector } from "../../../../store/hooks";
import useActionConfig from "../../hooks/useActionConfg";
import DocumentDateDependencyField from "../components/DocumentDateDependencyField";
import FormActionInsurerField from "../components/FormActionInsurerField";
import IsPolicyIssuedDependencyField from "../components/IsPolicyIssuedDependencyField";
import { useDefaultSettings } from "../hooks/useDefaultSettings";
import { useInsurerSettings } from "../hooks/useInsurerSettings";
import VehicleTypeDependencyField from "../VehicleTypeDependencyField";
import MotorTaxFields from "./MotorTaxFields";
import MtplAndFleetLegalDependencyFields from "./MtplAndFleetLegalDependencyFields";
import MtplAndHullDependencyFields from "./MtplAndHullDependencyFields";

const AddPremiumFormDialog = () => {
  const { fleetId } = useParams() as any;
  const { t } = useTranslation();
  const [isTradeLicensePlate, setTradeLicensePlate] = useState(false);
  const taxonomy = useSelector((s) => s.taxonomy);
  const fleetAppSettings = useSelector((s) => s.fleetForm.fleetAppSettings);

  const { mutate: onAddFleetPremium, isLoading } = useAddFleetPremium();

  const fleet = useSelector((s) => s.fleetForm.fleetWithPremiums);
  const mtplGroupPolicyInfo = useSelector(
    (s) => s.fleetForm.fleetWithPremiums.mtplGroupPolicyInfo
  );
  const hullGroupPolicyInfo = useSelector(
    (s) => s.fleetForm.fleetWithPremiums.hullGroupPolicyInfo
  );
  const initDueDate = useMemo(() => {
    return formatDayMonthValue(fleet?.dueDay, fleet?.dueMonth);
  }, [fleet?.dueDay, fleet?.dueMonth]);

  const actionsConfig = useActionConfig(fleetAppSettings);
  const dialogData = useSelector((s) => s.fleetForm.dialog);
  const defaultData =
    dialogData.type === "editSetting" && dialogData.data?.selectedSetting;
  const carUsageOptions = actionsConfig.carUsageOptions;

  const insurerList = useSelector((s) => s.fleetForm.insurerList);

  const paymentMeanOptions = actionsConfig.paymentMeanOptions;
  const paymentMethodOptions = actionsConfig.paymentMethodOptions;

  const dispatch = useDispatch();

  const mainMtplInsurerPartnerNumber = useSelector(
    (s) => s.fleetForm.fleetWithPremiums?.mainMtplInsurerPartnerNumber
  );

  const mainHullInsurerPartnerNumber = useSelector(
    (s) => s.fleetForm.fleetWithPremiums?.mainHullInsurerPartnerNumber
  );

  const mainFleetLegalInsurerPartnerNumber = useSelector(
    (s) => s.fleetForm.fleetWithPremiums.mainFleetLegalInsurerPartnerNumber
  );

  const { data: fleetInsurerDefaultSettings } =
    useGetFleetInsurerDefaultSettings(fleetId);

  useDefaultSettings();
  useInsurerSettings();

  const close = () => {
    dispatch(setDialog({ type: "", isOpen: false }));
  };
  const premiumTypeOptions = actionsConfig.premiumTypeOptions;
  const vehiclePolicyStatusOptions = actionsConfig.vehiclePolicyStatusOptions;
  const vehicleTypeCodeOptions = actionsConfig.vehicleTypeCodeOptions;
  const listPriceTypeOptions = actionsConfig.listPriceTypeOptions;

  const insurerOptions = actionsConfig.insurerOptions;
  const initialValues = {
    premiumType: null,
    insurerGroupPolicyNumber: null,
    insurerPartnerNumber: null,
    annualNetPremium: null,
    vehicleType: null,
    dueDate: initDueDate,
    listPriceType: null,
    listPriceMin: null,
    listPriceMax: null,
    annualGrossPremium: null,
    cubicCapacityCcmRange: null,
    powerKwFrom: null,
    powerKwTo: null,
    weightCapacityKgRange: null,
    validFromDate: new Date(new Date().getFullYear(), 0, 1),
    reasonForModificationComment: null,
    suspensionDaysLimit: null,

    //fields depends on mtpl
    insurerPolicyNumber: null,
    insurerBranchOffice: null,
    dueDay: null,
    dueMonth: null,
    expiryDate: null,
    greenCardExpiryDate: null,
    motorTaxPaymentMethod: null,
    motorTaxSuspensionDaysLimit: null,
    motorTaxExclusionComment: null,

    //fleet legal closing date field
    isFirstForFleetLegalClosingDate: null,

    //fields depends on mtpl and hull
    paymentMethod: null,
    paymentMean: null,
    activationDays: null,
    suspensionDays: null,
    garageRiskDiscountPercent: null,
    carUsage: null,

    emptyWeightKgFrom: null,
    emptyWeightKgTo: null,
    documentDate: null,
    isPolicyIssued: false,
  };

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    const validationObj = {
      premiumType: yup.mixed().required(),
      insurerPartnerNumber: yup.mixed().required(),
      ...((fleetAppSettings.DEFAULT_COLUMNS.includes("mtplPolicyStatus") ||
        fleetAppSettings.DEFAULT_COLUMNS.includes("hullPolicyStatus")) && {
        policyStatus: yup.mixed().required(),
      }),

      annualNetPremium: yup.string().required(),
      annualGrossPremium: yup.string().required(),
      cubicCapacityCcmRange: yup.mixed().nullable(),

      weightCapacityKgRange: yup.mixed().nullable(),
      validFromDate: yup.mixed().required(),
      ...(fleetAppSettings.SUSPENSION_DAYS_LIMIT_INCLUDED && {
        suspensionDaysLimit: yup.number().required().min(-1),
      }),
      reasonForModificationComment: yup.string().max(512).nullable(),
      vehicleType: isTradeLicensePlate
        ? yup.mixed().nullable()
        : yup.mixed().required(),

      //fields depends on MTPL or FLEET LEGAL

      insurerPolicyNumber: yup.mixed().when("premiumType", {
        is: (field) =>
          (field && field?.code.includes("MTPL")) ||
          field?.code.includes("FLEETLEGAL"), //
        then: yup.string().trim().required(),
        otherwise: yup.string().nullable(),
      }),

      insurerBranchOffice: yup.mixed().when("premiumType", {
        is: (field) => field && field?.code.includes("MTPL"), //
        then: yup.mixed().nullable(),
        otherwise: yup.mixed().nullable(),
      }),

      dueDate: yup.mixed().when("premiumType", {
        is: (field) => field && field?.code.includes("MTPL"),
        then: yup.string().required(),
        otherwise: yup.string().nullable(),
      }),

      expiryDate: yup.mixed().when("premiumType", {
        is: (field) =>
          (field && field?.code.includes("MTPL")) ||
          field?.code.includes("FLEETLEGAL"), //
        then: yup
          .date()
          .min(
            yup.ref("validFromDate"),
            "Expiry date must be after valid from date"
          )
          .required(),
        otherwise: yup.mixed().nullable(),
      }),
      //motor tax
      ...(!isTradeLicensePlate &&
        fleetAppSettings.VEHICLE_PREMIUM_TYPES.includes(
          PremiumType.HORSE_POWER
        ) && {
          isMotorTaxIncluded: yup.mixed().when("premiumType", {
            is: (field) => field && field?.code.includes("MTPL"), //
            then: yup.boolean().required(),
            otherwise: yup.string().nullable(),
          }),

          motorTaxPaymentMethod: yup
            .mixed()
            .when(["premiumType", "isMotorTaxIncluded"], {
              is: (premiumType, isMotorTaxIncluded) => {
                return (
                  premiumType &&
                  premiumType?.code.includes("MTPL") &&
                  isMotorTaxIncluded
                );
              }, //
              then: yup.mixed().required(),
              otherwise: yup.mixed().nullable(),
            }),
          motorTaxSuspensionDaysLimit: yup
            .mixed()
            .when(["premiumType", "isMotorTaxIncluded"], {
              is: (premiumType, isMotorTaxIncluded) =>
                premiumType &&
                premiumType?.code.includes("MTPL") &&
                isMotorTaxIncluded,
              then: yup.string().required(),
              otherwise: yup.string().nullable(),
            }),
          motorTaxExclusionReason: yup
            .mixed()
            .when(["premiumType", "isMotorTaxIncluded"], {
              is: (premiumType, isMotorTaxIncluded) =>
                premiumType &&
                premiumType?.code.includes("MTPL") &&
                !isMotorTaxIncluded,
              then: yup.mixed().required(),
              otherwise: yup.string().nullable(),
            }),
          motorTaxExclusionComment: yup.mixed().when("premiumType", {
            is: (field) => field && field?.code.includes("MTPL"), //
            then: yup.string().nullable(),
            otherwise: yup.string().nullable(),
          }),
        }),
      //fields depends on mtpl and hull

      paymentMethod: yup.mixed().when("premiumType", {
        is: (field) =>
          field &&
          (field?.code.includes("HULL") || field?.code.includes("MTPL")), //
        then: yup.mixed().required(),
        otherwise: yup.mixed().nullable(),
      }),

      paymentMean: yup.mixed().when("premiumType", {
        is: (field) =>
          field &&
          (field?.code.includes("HULL") || field?.code.includes("MTPL")), //
        then: yup.mixed().required(),
        otherwise: yup.mixed().nullable(),
      }),
      ...(fleetAppSettings.ACTIVATION_DAYS_INCLUDED && {
        activationDays: yup.string().when("premiumType", {
          is: (field) => field && field?.code.includes("HULL"), //
          then: yup.string().required(),
          otherwise: yup.string().nullable(),
        }),
      }),
      ...(fleetAppSettings.SUSPENSION_DAYS_INCLUDED && {
        suspensionDays: yup.string().when("premiumType", {
          is: (field) => field && field?.code.includes("HULL"), //
          then: yup.string().required(),
          otherwise: yup.string().nullable(),
        }),
      }),
      ...(fleetAppSettings.GARAGE_RISK_DISCOUNT_PERCENT_INCLUDED && {
        garageRiskDiscountPercent: yup.string().when("premiumType", {
          is: (field) => field && field?.code.includes("HULL"), //
          then: yup.string().required(),
          otherwise: yup.string().nullable(),
        }),
      }),

      documentDate: yup.mixed().nullable(),
      isPolicyIssued: yup.mixed().nullable(),
      // ...(fleetAppSettings.INCLUDE_GROUP_POLICY && {
      //   insurerGroupPolicyNumber: yup.mixed().nullable().required(),
      emptyWeightKgFrom: yup.number().nullable(),
      emptyWeightKgTo: yup.number().nullable(),
      powerKwFrom: yup.number().nullable(),
      powerKwTo: yup.number().nullable(),
      // }),
    };

    return yup
      .object()
      .shape(validationObj)
      .test(
        "emptyWeightKg",
        createFieldPairValidation("emptyWeightKgFrom", "emptyWeightKgTo")
      )
      .test("powerKw", createFieldPairValidation("powerKwFrom", "powerKwTo"))
      .test(
        "listPrice",
        createFieldPairValidation("listPriceMin", "listPriceMax")
      );
  }, [t, isTradeLicensePlate, fleetAppSettings]);

  const classes = getClassNames();

  const setDefaultSettingValues = async ({
    setFieldValue,
    values,
    insurer,
    noToastMsg = true,
    mustHaveInsurer = false,
    isHullPremium = true,
    setFieldTouched,
  }: TSetDefaultSettingValues) => {
    // if (!isMtplPremiumType && !isHullBasicType && !isHullPremiumType) return;

    if (mustHaveInsurer && !insurer) return;
    const premiumTypeValue = values?.premiumType?.value;
    const taxonomyByCode = taxonomy.PremiumType.byCode;

    const isMtplPremiumType =
      premiumTypeValue === taxonomyByCode[PremiumType.MTPL]?.id;
    const isHullPremiumType =
      premiumTypeValue === taxonomyByCode[PremiumType.HULL]?.id;
    const isHullBasicPremiumType =
      premiumTypeValue === taxonomyByCode[PremiumType.HULL_BASIC]?.id;

    const isFleetLegalProRataPremiumType =
      premiumTypeValue === taxonomyByCode[PremiumType.FLEETLEGAL_PRORATA]?.id;
    const isFleetLegalClosingDatePremiumType =
      premiumTypeValue ===
      taxonomyByCode[PremiumType.FLEETLEGAL_CLOSINGDATE]?.id;

    const insurerInternalNumberByPremiumType = (() => {
      if (insurer) {
        return Number(insurer.value);
      }
      if (isMtplPremiumType) {
        return Number(mainMtplInsurerPartnerNumber);
      }
      if (isHullPremiumType) {
        return Number(mainHullInsurerPartnerNumber);
      }
      if (
        isFleetLegalProRataPremiumType ||
        isFleetLegalClosingDatePremiumType
      ) {
        return Number(mainFleetLegalInsurerPartnerNumber);
      }

      return null;
    })();

    const insurerPartnerNumberByPremiumType = insurerList.find(
      (insurer) =>
        insurer.insurerInternalNumber === insurerInternalNumberByPremiumType
    )?.insurerInternalNumber;

    const selectedFleetInsurerDefaultSetting: IFleetInsurerDefaultSetting =
      (() => {
        if (
          (isMtplPremiumType ||
            isHullPremiumType ||
            isHullBasicPremiumType ||
            isFleetLegalProRataPremiumType ||
            isFleetLegalClosingDatePremiumType) &&
          insurerInternalNumberByPremiumType
        ) {
          return fleetInsurerDefaultSettings.find(
            (setting) =>
              setting.insurerPartnerNumber ===
              insurerInternalNumberByPremiumType
          );
        }
        return null;
      })();

    const selectedInsurer = insurerOptions.find(
      (option) => option.value === insurerPartnerNumberByPremiumType
    );
    const insurerBranchOffice = getInsurerBranchOffice({
      formInsurer: insurerPartnerNumberByPremiumType,
      insurerList,
      formBranchOffice: selectedFleetInsurerDefaultSetting?.insurerBranchOffice,
    });

    const paymentMean = paymentMeanOptions.find(
      (item) => item.value === selectedFleetInsurerDefaultSetting?.paymentMean
    );

    const paymentMethod = paymentMethodOptions.find(
      (item) => item.value === selectedFleetInsurerDefaultSetting?.paymentMethod
    );

    const paymentMethodEngineTax = paymentMethodOptions.find(
      (item) =>
        item.value ===
        selectedFleetInsurerDefaultSetting?.paymentMethodEngineTax
    );

    const paymentMeanFleetLegal = paymentMeanOptions.find(
      (item) =>
        item.value === selectedFleetInsurerDefaultSetting?.paymentMeanFleetLegal
    );

    const paymentMethodFleetLegal = paymentMethodOptions.find(
      (item) =>
        item.value ===
        selectedFleetInsurerDefaultSetting?.paymentMethodFleetLegal
    );

    setFieldValue("insurerPartnerNumber", selectedInsurer);
    if (selectedFleetInsurerDefaultSetting) {
      setFieldValue("insurerBranchOffice", insurerBranchOffice);
    }

    if (isFleetLegalProRataPremiumType || isFleetLegalClosingDatePremiumType) {
      if (paymentMeanFleetLegal) {
        setFieldValue("paymentMean", paymentMeanFleetLegal);
      } else {
        if (paymentMean !== undefined)
          setFieldValue("paymentMean", paymentMean);
      }
      if (paymentMethodFleetLegal) {
        setFieldValue("paymentMethod", paymentMethodFleetLegal);
      } else {
        if (paymentMethod !== undefined)
          setFieldValue("paymentMethod", paymentMethod);
      }
    } else {
      if (paymentMean !== undefined) setFieldValue("paymentMean", paymentMean);
      if (paymentMethod !== undefined)
        setFieldValue("paymentMethod", paymentMethod);
    }

    let fieldNames1 = ["annualGrossPremium", "deductible", "calculation"];

    dispatch(
      setDialogDefaultValueOperation({
        fieldNames: fieldNames1,
        formValues: {
          ...values,
          isTradeLicensePlate: isTradeLicensePlate,
        },
        noMessage: noToastMsg,
      })
    );

    let fieldNames2 = [];

    setFieldTouched("motorTaxPaymentMethod", false);

    if (paymentMethodEngineTax) {
      setFieldValue("motorTaxPaymentMethod", paymentMethodEngineTax);
    } else {
      if (paymentMethod !== undefined)
        setFieldValue("motorTaxPaymentMethod", paymentMethod);
    }

    if (selectedFleetInsurerDefaultSetting?.activationDays?.toString()) {
      setFieldValue(
        "activationDays",
        selectedFleetInsurerDefaultSetting?.activationDays
      );
    } else {
      fieldNames2.push("activationDays");
    }

    if (selectedFleetInsurerDefaultSetting?.suspensionDays?.toString()) {
      setFieldValue(
        "suspensionDays",
        selectedFleetInsurerDefaultSetting?.suspensionDays
      );
    } else {
      fieldNames2.push("suspensionDays");
    }

    if (isHullPremium) {
      if (
        selectedFleetInsurerDefaultSetting?.garageRiskDiscountPercentHull?.toString()
      ) {
        setFieldValue(
          "garageRiskDiscountPercent",
          selectedFleetInsurerDefaultSetting?.garageRiskDiscountPercentHull
            ?.toString()
            .replace(".", ",")
        );
      } else {
        fieldNames2.push("garageRiskDiscountPercent");
      }
    } else {
      if (
        selectedFleetInsurerDefaultSetting?.garageRiskDiscountPercentHullBasic?.toString()
      ) {
        setFieldValue(
          "garageRiskDiscountPercent",
          selectedFleetInsurerDefaultSetting?.garageRiskDiscountPercentHullBasic
            ?.toString()
            .replace(".", ",")
        );
      } else {
        fieldNames2.push("garageRiskDiscountPercent");
      }
    }

    fieldNames2.push("annualNetPremium");
    fieldNames2.push("suspensionDaysLimit");
    fieldNames2.push("motorTaxSuspensionDaysLimit");

    dispatch(
      setDialogInsurerSettingsOperation({
        fieldNames: fieldNames2,
        formValues: values,
        noMessage: noToastMsg,
        isTradeLicensePlate,
      })
    );
  };

  const onSubmit = (values) => {
    const dueDate = values?.dueDate;

    const dueDay = dueDate ? stringToNumber(dueDate.substring(0, 2)) : null;
    const dueMonth = dueDate ? stringToNumber(dueDate.substring(2, 4)) : null;

    const premiumTypeValue = values?.premiumType?.value;
    const taxonomyByCode = taxonomy.PremiumType.byCode;

    const isMtplPremiumType =
      premiumTypeValue === taxonomyByCode[PremiumType.MTPL]?.id;

    const isHullPremiumType =
      premiumTypeValue === taxonomyByCode[PremiumType.HULL]?.id;

    const isHullBasicPremiumType =
      premiumTypeValue === taxonomyByCode[PremiumType.HULL_BASIC]?.id;

    const isFleetLegalClosingDate =
      premiumTypeValue ===
      taxonomyByCode[PremiumType.FLEETLEGAL_CLOSINGDATE]?.id;

    const isFleetLegalProrata =
      premiumTypeValue === taxonomyByCode[PremiumType.FLEETLEGAL_PRORATA]?.id;

    const isFleetLegalPremiumType =
      isFleetLegalProrata || isFleetLegalClosingDate;

    const oneOfMainLinesPremiumType =
      isMtplPremiumType ||
      // isHullPremiumType ||
      // isHullBasicPremiumType ||
      isFleetLegalClosingDate ||
      isFleetLegalPremiumType;

    let body: any = {
      criteria: {
        vehicleTypeCodes: isTradeLicensePlate
          ? null
          : values?.vehicleType.map((item) => item.value),

        carUsageCodes: values?.carUsage
          ? values?.carUsage.map((item) => item.value)
          : null,
        weightCapacityKgRangeCodes: values?.weightCapacityKgRange
          ? values?.weightCapacityKgRange?.map((item) => item.value)
          : null,
        maxGrossWeightKgRangeCodes: values?.maxGrossWeightKgRange
          ? values?.maxGrossWeightKgRange?.map((item) => item.value)
          : null,
        powerKws:
          values?.powerKwFrom && values?.powerKwTo
            ? [
                {
                  from: values?.powerKwFrom,
                  to: values?.powerKwTo,
                },
              ]
            : null,
        emptyWeightKgs:
          values?.emptyWeightKgFrom && values?.emptyWeightKgTo
            ? [
                {
                  from: values?.emptyWeightKgFrom,
                  to: values?.emptyWeightKgTo,
                },
              ]
            : null,
        cubicCapacityCcmRangeCodes: values?.cubicCapacityCcmRange
          ? values?.cubicCapacityCcmRange?.map((item) => item.value)
          : null,
        numberOfSeatsRangeCodes: values?.numberOfSeatsRange
          ? values?.numberOfSeatsRange?.map((item) => item.value)
          : null,
        numberOfSeatsMopedCodes: values?.numberOfSeatsMoped
          ? values?.numberOfSeatsMoped?.map((item) => item.value)
          : null,
        listPrices: values?.listPriceType
          ? [
              {
                type: values?.listPriceType?.value
                  ? values?.listPriceType?.value
                  : null,
                min: values?.listPriceMin
                  ? Number(formatDoubleFieldValue(values?.listPriceMin))
                  : undefined,
                max: values?.listPriceMax
                  ? Number(formatDoubleFieldValue(values?.listPriceMax))
                  : undefined,
              },
            ]
          : null,
        isTradeLicensePlate: isTradeLicensePlate,
      },
      ...((fleetAppSettings.DEFAULT_COLUMNS.includes("mtplPolicyStatus") ||
        fleetAppSettings.DEFAULT_COLUMNS.includes("hullPolicyStatus")) && {
        policyStatus: values?.policyStatus?.value,
      }),
      // isTradeLicensePlate: isTradeLicensePlate,
      premiumType: values?.premiumType.value,
      insurerPartnerNumber: values?.insurerPartnerNumber?.value,
      // vehicleType: isTradeLicensePlate ? null : values?.vehicleType?.value,

      annualNetPremium: Number(
        values.annualNetPremium?.toString()?.replace(".", "")?.replace(",", ".")
      ),
      annualGrossPremium: Number(
        values.annualGrossPremium
          ?.toString()
          ?.replace(".", "")
          ?.replace(",", ".")
      ),

      // weightCapacityKgRange: values?.weightCapacityKgRange?.value,
      // maxGrossWeightKgRange: values?.maxGrossWeightKgRange?.value,
      // cubicCapacityCcmRange: values?.cubicCapacityCcmRange?.value,
      // powerKwFrom: values?.powerKwFrom,
      // powerKwTo: values?.powerKwTo,
      // numberOfSeatsRange: values?.numberOfSeatsRange?.value,
      // numberOfSeatsMoped: values?.numberOfSeatsMoped?.value,
      validFromDate: moment(values?.validFromDate).format("YYYY-MM-DD"),

      reasonForModificationComment: values?.reasonForModificationComment,
      suspensionDaysLimit: values.suspensionDaysLimit
        ? Number(
            values.suspensionDaysLimit
              ?.toString()
              .replace(".", "")
              ?.replace(",", ".")
          )
        : 0,

      ///main lines dependency fields
      ...(oneOfMainLinesPremiumType && {
        documentDate: values?.documentDate
          ? moment(values?.documentDate).format("YYYY-MM-DD")
          : null,
        isPolicyIssued: values?.isPolicyIssued,
      }),

      //fields depends on mtpl or fleet legal
      ...(isFleetLegalPremiumType && {
        dueDay,
        dueMonth,
        insurerBranchOffice: values?.insurerBranchOffice?.value,
        insurerPolicyNumber: values?.insurerPolicyNumber.trim(),
        expiryDate: moment(values?.expiryDate).format("YYYY-MM-DD"),
      }),
      ...(isFleetLegalClosingDate && {
        isFirstForFleetLegalClosingDate:
          values?.isFirstForFleetLegalClosingDate,
      }),
      ...(isMtplPremiumType && {
        insurerPolicyNumber: values?.insurerPolicyNumber,
        insurerBranchOffice: values?.insurerBranchOffice?.value,
        dueDay,
        dueMonth,
        expiryDate: moment(values?.expiryDate).format("YYYY-MM-DD"),
        ...(fleetAppSettings.HAS_GREENCARD_EXPIRY_DATE && {
          greenCardExpiryDate: values?.greenCardExpiryDate
            ? moment(values?.greenCardExpiryDate).format("YYYY-MM-DD")
            : null,
        }),
        //motor tax
        isMotorTaxIncluded: values?.isMotorTaxIncluded,

        ...(values?.isMotorTaxIncluded
          ? {
              motorTaxPaymentMethod: values?.motorTaxPaymentMethod?.value
                ? values?.motorTaxPaymentMethod?.value
                : 0,
              motorTaxSuspensionDaysLimit: values?.motorTaxSuspensionDaysLimit
                ? values?.motorTaxSuspensionDaysLimit
                    ?.toString()
                    ?.replace(".", "")
                    ?.replace(",", ".")
                : 0,
            }
          : {
              motorTaxExclusionReason: values?.motorTaxExclusionReason?.value,
              // clutch for hungarians, not to change the backend
              motorTaxPaymentMethod: values?.paymentMethod?.value,
              motorTaxSuspensionDaysLimit: 0,
              //
            }),
        motorTaxExclusionComment: values?.motorTaxExclusionComment,
      }),
      //fields depends on mtpl and hull
      paymentMethod: values?.paymentMethod
        ? values?.paymentMethod?.value
        : null,
      paymentMean: values?.paymentMean ? values?.paymentMean?.value : null,
      activationDays: values.activationDays
        ? Number(
            values.activationDays
              ?.toString()
              .replace(".", "")
              ?.replace(",", ".")
          )
        : 0,
      suspensionDays: values.suspensionDays
        ? Number(
            values.suspensionDays
              ?.toString()
              .replace(".", "")
              ?.replace(",", ".")
          )
        : 0,
      garageRiskDiscountPercent: values.garageRiskDiscountPercent
        ? Number(
            values.garageRiskDiscountPercent
              ?.toString()
              .replace(".", "")
              ?.replace(",", ".")
          )
        : 0,
      // listPriceType: values?.listPriceType?.value,
      // carUsage: values?.carUsage?.value,

      // emptyWeightKgFrom: values?.emptyWeightKgFrom,
      // emptyWeightKgTo: values?.emptyWeightKgTo,
    };
    if (fleetAppSettings?.INCLUDE_GROUP_POLICY) {
      body = {
        ...body,
        insurerGroupPolicyNumber: values?.insurerGroupPolicyNumber
          ? values?.insurerGroupPolicyNumber.value
          : null,
      };
    }
    onAddFleetPremium(
      {
        fleetId: fleet.fleetId,
        body,
      },
      {
        onSuccess: (res) => {
          dispatch(setFleetWithPremiums(res?.data));
          toast.success(t("bfm.success"));
          close();
        },
      }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit(values);
        await delay(400);
        setSubmitting(false);
      }}
    >
      {(props) => {
        const {
          values,
          handleSubmit,
          setFieldValue,
          handleChange,
          errors,
          setFieldTouched,
        } = props;
        const formPremiumType = values.premiumType?.value;
        const taxonomyByCode = taxonomy.PremiumType.byCode;
        const isMtplPremiumType =
          formPremiumType === taxonomyByCode[PremiumType.MTPL]?.id;
        const showMotorTaxSection =
          isMtplPremiumType &&
          !isTradeLicensePlate &&
          fleetAppSettings.VEHICLE_PREMIUM_TYPES.includes(
            PremiumType.HORSE_POWER
          );
        const isFleetLegalProrataPremiumType =
          formPremiumType ===
          taxonomyByCode[PremiumType.FLEETLEGAL_PRORATA]?.id;

        const isFleetLegalClosingDatePremiumType =
          formPremiumType ===
          taxonomyByCode[PremiumType.FLEETLEGAL_CLOSINGDATE]?.id;

        const isHullPremiumType =
          formPremiumType === taxonomyByCode[PremiumType.HULL]?.id;

        const isHullBasicPremiumType =
          formPremiumType === taxonomyByCode[PremiumType.HULL_BASIC]?.id;

        const isFleetLegalProrata =
          formPremiumType ===
          taxonomyByCode[PremiumType.FLEETLEGAL_PRORATA]?.id;

        const oneOfMainLinesPremiumType =
          isMtplPremiumType ||
          // isHullPremiumType ||
          // isHullBasicPremiumType ||
          isFleetLegalClosingDatePremiumType ||
          isFleetLegalProrata;

        const insurerGroupPolicyNumberOptions = () => {
          if (
            values?.premiumType?.code === "HULL" ||
            values?.premiumType?.code === "HULL_BASIC"
          ) {
            return hullGroupPolicyInfo?.map((policyInfo) => ({
              label: policyInfo.groupPolicyNumber,
              value: policyInfo.groupPolicyNumber,
            }));
          }
          if (values?.premiumType?.code === "MTPL") {
            return mtplGroupPolicyInfo?.map((policyInfo) => ({
              label: policyInfo.groupPolicyNumber,
              value: policyInfo.groupPolicyNumber,
            }));
          }
        };

        return (
          <>
            <form
              onSubmit={handleSubmit}
              style={{
                width: showMotorTaxSection ? "1200px" : "auto",
              }}
            >
              {fleetAppSettings.SHOW_TRADE_LICENSE_PLATES && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "baseline",
                  }}
                >
                  <div className={classes.fieldItem}>
                    <Checkbox
                      label={t("bfm.tradeLicensePlates.label")}
                      styles={{
                        root: {
                          fontWeight: 600,
                        },
                      }}
                      disabled={false}
                      checked={isTradeLicensePlate}
                      onChange={(ev) => {
                        setFieldValue("vehicleType", null);
                        setFieldValue("carUsage", null);
                        setTradeLicensePlate(!isTradeLicensePlate);
                      }}
                    />
                  </div>
                  {isFleetLegalClosingDatePremiumType && (
                    <div className={classes.fieldItem}>
                      <CheckboxField
                        checked={values?.isFirstForFleetLegalClosingDate}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        name={"isFirstForFleetLegalClosingDate"}
                        disabled={false}
                        label={"bfm.isFirstForFleetLegalClosingDate.label"}
                        isMarked={false}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className={classes.contentWrap}>
                <div className={classes.contentItem}>
                  <div className={classes.fieldItemsWrap}>
                    <div className={classes.fieldItem}>
                      <ComboBoxField
                        disabled={false}
                        errors={errors}
                        name="premiumType"
                        required
                        label={"bfm.fleet.premiumType.label"}
                        setFieldValue={setFieldValue}
                        options={premiumTypeOptions || []}
                        defaultValue={null}
                        confidentiality={null}
                      />
                    </div>
                    {fleetAppSettings.INCLUDE_GROUP_POLICY && (
                      <div className={classes.fieldItem}>
                        <GroupPolicyNumberField
                          id="insurerGroupPolicyNumber"
                          setFieldValue={setFieldValue}
                          options={insurerGroupPolicyNumberOptions() || []}
                          defaultValue={values?.insurerGroupPolicyNumber}
                          errors={errors}
                          name={"insurerGroupPolicyNumber"}
                          disabled={false}
                          label={
                            "bfm.fleet.setting.insurerGroupPolicyNumber.label"
                          }
                          confidentiality={null}
                        />
                      </div>
                    )}
                    {fleetAppSettings.DEFAULT_COLUMNS.includes(
                      "mtplPolicyStatus"
                    ) ||
                    fleetAppSettings.DEFAULT_COLUMNS.includes(
                      "hullPolicyStatus"
                    ) ? (
                      <div className={classes.fieldItem}>
                        <ComboBoxField
                          disabled={false}
                          errors={errors}
                          name="policyStatus"
                          required
                          label={"bfm.vehicleForm.policyStatus.label"}
                          setFieldValue={setFieldValue}
                          options={vehiclePolicyStatusOptions || []}
                          defaultValue={null}
                          confidentiality={null}
                        />
                      </div>
                    ) : null}
                    <div className={classes.fieldItem}>
                      <FormActionInsurerField
                        actionFormType={"addPremium"}
                        allInsurerOptions={insurerOptions}
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                      />
                    </div>

                    {isMtplPremiumType ||
                    isFleetLegalProrataPremiumType ||
                    isFleetLegalClosingDatePremiumType ? (
                      <MtplAndFleetLegalDependencyFields
                        handleChange={handleChange}
                        values={values}
                        premiumTypeSelected={values?.premiumType}
                        fieldItemStyle={classes.fieldItem}
                        errors={errors}
                        setFieldValue={setFieldValue}
                      />
                    ) : null}
                    <div className={classes.fieldItem}>
                      <DatePickerField
                        errors={errors}
                        setFieldValue={setFieldValue}
                        defaultValue={values.validFromDate}
                        name={"validFromDate"}
                        disabled={false}
                        label={"bfm.vehicleForm.validFromDate.label"}
                        placeholder={t("bfm.selectDate")}
                        required
                        confidentiality={null}
                      />
                    </div>
                    {fleetAppSettings.SUSPENSION_DAYS_INCLUDED && (
                      <div className={classes.fieldItem}>
                        <NumberInputField
                          required
                          setFieldValue={setFieldValue}
                          value={values?.suspensionDaysLimit}
                          onChange={handleChange}
                          errors={errors}
                          name={"suspensionDaysLimit"}
                          disabled={false}
                          isNegativeAllowed={true}
                          label={
                            "bfm.vehicleForm.horsePowerBasedTaxSuspensionDaysLimit.label"
                          }
                        />
                      </div>
                    )}
                    <div className={classes.fieldItem}>
                      {!isTradeLicensePlate ? (
                        <ComboBoxField
                          disabled={false}
                          errors={errors}
                          name="carUsage"
                          label={"bfm.vehicleForm.carUsageCode.label"}
                          setFieldValue={setFieldValue}
                          options={carUsageOptions || []}
                          defaultValue={values?.carUsage}
                          confidentiality={null}
                          isMulti={true}
                        />
                      ) : null}
                    </div>
                    {values.premiumType?.code === "HULL_BASIC" ||
                    values.premiumType?.code === "HULL" ? (
                      <>
                        <div className={classes.fieldItem}>
                          <ComboBoxField
                            disabled={false}
                            errors={errors}
                            name="listPriceType"
                            label={"bfm.vehicleForm.listPriceTypeCode.label"}
                            setFieldValue={setFieldValue}
                            options={listPriceTypeOptions || []}
                            defaultValue={values?.listPriceType}
                            confidentiality={null}
                          />
                        </div>
                        <div
                          className={classes.fieldItem}
                          style={{ display: "flex", gap: "15px" }}
                        >
                          <div style={{ width: "50%" }}>
                            <DoubleNumberInputField
                              setFieldValue={setFieldValue}
                              value={values.listPriceMin}
                              onChange={handleChange}
                              errors={errors}
                              name={"listPriceMin"}
                              disabled={false}
                              label={"bfm.listPriceMin.label"}
                            />
                          </div>
                          <div style={{ width: "50%" }}>
                            <DoubleNumberInputField
                              setFieldValue={setFieldValue}
                              value={values.listPriceMax}
                              onChange={handleChange}
                              errors={errors}
                              name={"listPriceMax"}
                              disabled={false}
                              label={"bfm.listPriceMax.label"}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {!isTradeLicensePlate ? (
                      <div className={classes.fieldItem}>
                        <ComboBoxField
                          disabled={isTradeLicensePlate}
                          errors={errors}
                          name="vehicleType"
                          required={true}
                          label={"bfm.vehicleForm.vehicleTypeCode.label"}
                          setFieldValue={setFieldValue}
                          options={vehicleTypeCodeOptions || []}
                          defaultValue={values?.vehicleType}
                          confidentiality={null}
                          isMulti={true}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className={classes.fieldItem}>
                    <VehicleTypeDependencyField
                      values={values}
                      vehicleTypeSelected={values?.vehicleType}
                      fieldItemStyle={classes.fieldItem}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      selectedDependency={defaultData}
                      disabled={false}
                      includeMargins={false}
                    />
                  </div>
                  {/* 3 */}
                  <div className={classes.fieldItemsWrap}>
                    <MtplAndHullDependencyFields
                      handleChange={handleChange}
                      values={values}
                      premiumTypeSelected={values?.premiumType}
                      fieldItemStyle={classes.fieldItem}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                  </div>
                  {oneOfMainLinesPremiumType && (
                    <div className={classes.fieldItemsWrap}>
                      <IsPolicyIssuedDependencyField
                        premiumTypeSelected={values?.premiumType}
                        classesItem={classes.fieldItem}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                      <DocumentDateDependencyField
                        premiumTypeSelected={values?.premiumType}
                        classesItem={classes.fieldItem}
                        errors={errors}
                        fieldName={"documentDate"}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    </div>
                  )}
                  <div className={classes.fieldItem}>
                    <TextInputField
                      value={values.reasonForModificationComment}
                      onChange={handleChange}
                      errors={errors}
                      name={"reasonForModificationComment"}
                      multiline
                      rows={4}
                      disabled={false}
                      label={"bfm.comment.label"}
                    />
                  </div>
                </div>

                {showMotorTaxSection && (
                  <div
                    className={`${classes.contentItem} ${classes.motorTaxContent}`}
                  >
                    <MotorTaxFields />
                  </div>
                )}
              </div>
              <div className={classes.footer}>
                <TooltipHost content={t("bfm.fillInAllDefaults.label")}>
                  <StyledPrimaryButton
                    text={t("bfm.defaultSettings.label")}
                    onClick={() => {
                      const premiumTypeCode = values?.premiumType?.code;
                      const isHullPremium = premiumTypeCode === "HULL";

                      setDefaultSettingValues({
                        setFieldValue,
                        values,
                        insurer: values["insurerPartnerNumber"],
                        mustHaveInsurer: true,
                        noToastMsg: false,
                        isHullPremium,
                        setFieldTouched: props.setFieldTouched,
                      });
                    }}
                    iconProps={{
                      iconName: "Shield",
                    }}
                    disabled={
                      isLoading ||
                      !values?.premiumType ||
                      !values?.insurerPartnerNumber ||
                      !values["validFromDate"]
                    }
                  />
                </TooltipHost>

                <DefaultButton
                  primary
                  text={t("greco.save")}
                  type="submit"
                  iconProps={{
                    iconName: "Save",
                  }}
                  disabled={isLoading}
                />
              </div>
              {isLoading && <FormSpinner />}
            </form>
          </>
        );
      }}
    </Formik>
  );
};

const getClassNames = () =>
  mergeStyleSets({
    contentWrap: {
      display: "flex",
    },
    contentItem: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    motorTaxContent: {
      maxWidth: "calc(100% / 3)",
      borderLeft: "1px solid #cccccc",
      marginLeft: "20px",
      paddingLeft: "20px",
    },
    fieldItemsWrap: {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "1fr 1fr",
      gridGap: "10px",
    },
    fieldItem: {
      width: "100%",
      marginTop: 20,
    },
    footer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginTop: 150,
    },
  });

export default AddPremiumFormDialog;

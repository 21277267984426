import { PremiumType, TimelineType } from "types/types";

export type AppSettingsType = {
  COUNTRY_CODE: string;
  CURRENCY_SYMBOL: string;
  BAR_SECTION_HEIGHT: number;
  PREMIUM_BAR_HEIGHT: number;
  POLICY_STATUS_BAR_HEIGHT: number;
  VEHICLE_STATUS_BAR_HEIGHT: number;
  LICENSE_BAR_HEIGHT: number;
  PERIOD_SELECTOR_HEIGHT: number;
  VEHICLE_TIMELINE_TYPES: TimelineType[];
  VEHICLE_PREMIUM_DOMAINS: PremiumType[];
  VEHICLE_PREMIUM_TYPES: PremiumType[];
  SUSPENSION_DAYS_INCLUDED: boolean;
  SUSPENSION_DAYS_DEFAULT: number | null;
  ACTIVATION_DAYS_INCLUDED: boolean;
  ACTIVATION_DAYS_DEFAULT: number | null;
  SUSPENSION_DAYS_LIMIT_INCLUDED: boolean;
  SUSPENSION_DAYS_LIMIT_DEFAULT: number | null;
  GARAGE_RISK_DISCOUNT_PERCENT_INCLUDED: boolean;
  GARAGE_RISK_DISCOUNT_PERCENT_DEFAULT: number | null;
  INSURER_SETTINGS_TEMPLATES: string[];
  INCLUDE_GROUP_POLICY: boolean;
  INCLUDE_ISSUING_STATUS: boolean;
  INCLUDE_HULL_PRODUCT: boolean;
  INCLUDE_HULL_DEDUCTIBLE: boolean;
  INCLUDE_HULL_CALCULATION: boolean;
  INCLUDE_INSURER_DEFAULT_SETTINGS: boolean;
  SHOW_REPORTS_BUTTON: boolean;
  SHOW_REPORTS_PRORATA_REPORT: boolean;
  SHOW_REPORTS_COMPARISON_REPORT: boolean;
  SHOW_REPORTS_COVERSHEET_REPORT: boolean;
  SHOW_REPORTS_FLEETLEGAL_REPORT: boolean;
  SHOW_REPORTS_EXPORTFLEET_REPORT: boolean;
  SHOW_TRADE_LICENSE_PLATES: boolean;
  SHOW_SHARED_LICENSE_PLATES: boolean;
  SHOW_SCAN_DOCUMENTS: boolean;
  SHOW_HULL_PRODUCT: boolean;
  SHOW_LEASING_EXTRA_FIELDS: boolean;
  SHOW_EUROTAX_CODE: boolean;
  SHOW_BONUS_MALUS_CODE: boolean;
  DEFAULT_COLUMNS: string[];
  HAS_GREENCARD_EXPIRY_DATE: boolean;
};

export enum InsurerSettingsTemplate {
  SUSPENDED_DAYS_LIMITS = "suspendedDaysLimits",
  GARAGE_RISK_DISCOUNT = "garageRiskDiscount",
  SUSPENSION_AND_ACTIVATION_DAYS = "suspensionAndActivationDays",
  INSURANCE_LINE_DETAILS = "insuranceLineDetails",
}
class AppSettings {
  private static instance: AppSettings;

  private constructor() {}

  // Method to get the singleton instance
  public static getInstance(): AppSettings {
    if (!AppSettings.instance) {
      AppSettings.instance = new AppSettings();
    }
    return AppSettings.instance;
  }

  public getAppSettingsTypes(): { [key: string]: AppSettingsType } {
    if (!AppSettings.instance) {
      AppSettings.instance = new AppSettings();
    }
    return AppSettings.appSettingsTypes;
  }

  private static hungarianSettings: AppSettingsType = {
    COUNTRY_CODE: "Country.HU",
    CURRENCY_SYMBOL: "Ft ",
    BAR_SECTION_HEIGHT: 114,
    PREMIUM_BAR_HEIGHT: 32,
    POLICY_STATUS_BAR_HEIGHT: 32,
    VEHICLE_STATUS_BAR_HEIGHT: 17.25,
    LICENSE_BAR_HEIGHT: 22,
    PERIOD_SELECTOR_HEIGHT: 57,
    VEHICLE_TIMELINE_TYPES: [
      TimelineType.VEHICLE_PREMIUM,
      TimelineType.VEHICLE_POLICY_STATUSES,
      TimelineType.VEHICLE_STATUS,
      TimelineType.VEHICLE_LICENSE_PLATE,
      TimelineType.SHARED_LICENSE_PLATE_STATUS,
    ],
    VEHICLE_PREMIUM_DOMAINS: [PremiumType.MTPL, PremiumType.HULL],
    VEHICLE_PREMIUM_TYPES: [
      PremiumType.MTPL,
      PremiumType.HULL,
      PremiumType.HULL_BASIC,
    ],
    SUSPENSION_DAYS_INCLUDED: false,
    SUSPENSION_DAYS_DEFAULT: 0,
    ACTIVATION_DAYS_INCLUDED: false,
    ACTIVATION_DAYS_DEFAULT: 0,
    SUSPENSION_DAYS_LIMIT_INCLUDED: false,
    SUSPENSION_DAYS_LIMIT_DEFAULT: 0,
    GARAGE_RISK_DISCOUNT_PERCENT_INCLUDED: false,
    GARAGE_RISK_DISCOUNT_PERCENT_DEFAULT: 0,
    INSURER_SETTINGS_TEMPLATES: [
      InsurerSettingsTemplate.INSURANCE_LINE_DETAILS,
      InsurerSettingsTemplate.GARAGE_RISK_DISCOUNT,
      InsurerSettingsTemplate.SUSPENSION_AND_ACTIVATION_DAYS,
    ],
    INCLUDE_GROUP_POLICY: true,
    INCLUDE_ISSUING_STATUS: false,
    INCLUDE_HULL_PRODUCT: false,
    INCLUDE_HULL_DEDUCTIBLE: true,
    INCLUDE_HULL_CALCULATION: true,
    INCLUDE_INSURER_DEFAULT_SETTINGS: true,
    SHOW_REPORTS_BUTTON: true,
    SHOW_REPORTS_PRORATA_REPORT: false,
    SHOW_REPORTS_COMPARISON_REPORT: false,
    SHOW_REPORTS_COVERSHEET_REPORT: false,
    SHOW_REPORTS_FLEETLEGAL_REPORT: false,
    SHOW_REPORTS_EXPORTFLEET_REPORT: true,
    SHOW_TRADE_LICENSE_PLATES: false,
    SHOW_SHARED_LICENSE_PLATES: false,
    SHOW_SCAN_DOCUMENTS: false,
    SHOW_HULL_PRODUCT: false,
    SHOW_LEASING_EXTRA_FIELDS: true,
    SHOW_EUROTAX_CODE: true,
    SHOW_BONUS_MALUS_CODE: true,
    DEFAULT_COLUMNS: [
      "vehicleStatusCode",
      "licensePlate",
      "fleetName",
      "options",
      "insuranceLineIcons",
      "mtplPolicyStatus",
      "mtplPolicyStatusDateTime",
      "hullPolicyStatus",
      "hullPolicyStatusDateTime",
      "mtplGreenCardExpiryDate",
      "totalAnnualGrossPremium",
      "totalMtplRelatedAnnualGrossPremium",
      "hullAnnualGrossPremium",
      "totalProRataGrossPremium",
      "totalMtplRelatedProRataGrossPremium",
      "hullProRataGrossPremium",
      "carUsageCode",
      "vehicleCreatedAt",
      "vehicleLastModifiedAt",
      "vehicleLastStatusDate",
      "hullIsBasic",
      "vehicleClassCode",
      "needsGrECoUserAttention",
      "vin",
      "vehicleMainGroupCode",
      "vehicleTypeCode",
      "brandCode",
      "model",
      "costCenterClient",
      "leasingCompany",
      // "leasingContractNumber",
      // "leasingContractStartDate",
      // "leasingContractEndDate",
      // "leasingAmountPaidByLessee",
      // "eurotaxCode",
      // "bonusMalusCode",
      "clientComment",
      "seats",
      "powerKW",
      "cubicCapacityCcm",
      "weightCapacityKg",
      "maxGrossWeightKg",
      "yearOfConstruction",
      "firstRegistrationDate",
      "listPrice",
      "listPriceTypeCode",
      "specialEquipment",
      "secondLicensePlate",
      "vehicleOwner",
      "nationalCodeID",
      "co2Emission",
      "certificateNumber",
      "comment",
      "mtplComment",
      "hullComment",
      "gap",
      "replacementValue",
      "grossNegligence",
      "electroPlusPackage",
      "hullGroupPolicyNumber",
      "hullPolicyNumber",
      "isLeasing",
      "mtplAnnualGrossPremium",
      "mtplGroupPolicyNumber",
      "mtplPolicyNumber",
      "mtplProRataGrossPremium",
      "vatReimbursment",
      "vehiclePowerTypeCode",
      "mtplInsurerPartnerNumber",
      "hullInsurerPartnerNumber",
    ],
    HAS_GREENCARD_EXPIRY_DATE: true,
  };

  private static austrianSettings: AppSettingsType = {
    COUNTRY_CODE: "Country.AT",
    CURRENCY_SYMBOL: "€",
    BAR_SECTION_HEIGHT: 62,
    PREMIUM_BAR_HEIGHT: 32,
    POLICY_STATUS_BAR_HEIGHT: 32,
    VEHICLE_STATUS_BAR_HEIGHT: 17.25,
    LICENSE_BAR_HEIGHT: 22,
    PERIOD_SELECTOR_HEIGHT: 57,
    VEHICLE_TIMELINE_TYPES: [
      TimelineType.VEHICLE_PREMIUM,
      TimelineType.VEHICLE_PREMIUM_BOOKINGS,
      TimelineType.VEHICLE_STATUS,
      TimelineType.VEHICLE_LICENSE_PLATE,
      TimelineType.SHARED_LICENSE_PLATE_STATUS,
    ],
    VEHICLE_PREMIUM_DOMAINS: [
      PremiumType.MTPL,
      PremiumType.HORSE_POWER,
      PremiumType.ACCIDENT,
      PremiumType.ASSISTANCE,
      PremiumType.LEGAL,
      PremiumType.HULL,
      //debugger

      PremiumType.FLEETLEGAL_PRORATA,
    ],
    VEHICLE_PREMIUM_TYPES: [
      PremiumType.MTPL,
      PremiumType.HORSE_POWER,
      PremiumType.ACCIDENT,
      PremiumType.ASSISTANCE,
      PremiumType.LEGAL,
      PremiumType.HULL,
      PremiumType.HULL_BASIC,
      PremiumType.FLEETLEGAL_PRORATA,
      PremiumType.FLEETLEGAL_CLOSINGDATE,
    ],
    SUSPENSION_DAYS_INCLUDED: true,
    SUSPENSION_DAYS_DEFAULT: null,
    ACTIVATION_DAYS_INCLUDED: true,
    ACTIVATION_DAYS_DEFAULT: null,
    SUSPENSION_DAYS_LIMIT_INCLUDED: true,
    SUSPENSION_DAYS_LIMIT_DEFAULT: null,
    GARAGE_RISK_DISCOUNT_PERCENT_INCLUDED: true,
    GARAGE_RISK_DISCOUNT_PERCENT_DEFAULT: null,
    INSURER_SETTINGS_TEMPLATES: [
      InsurerSettingsTemplate.SUSPENDED_DAYS_LIMITS,
      InsurerSettingsTemplate.GARAGE_RISK_DISCOUNT,
      InsurerSettingsTemplate.SUSPENSION_AND_ACTIVATION_DAYS,
    ],
    INCLUDE_GROUP_POLICY: false,
    INCLUDE_ISSUING_STATUS: true,
    INCLUDE_HULL_PRODUCT: true,
    INCLUDE_HULL_DEDUCTIBLE: true,
    INCLUDE_HULL_CALCULATION: true,
    INCLUDE_INSURER_DEFAULT_SETTINGS: true,
    SHOW_REPORTS_BUTTON: true,
    SHOW_REPORTS_PRORATA_REPORT: true,
    SHOW_REPORTS_COMPARISON_REPORT: true,
    SHOW_REPORTS_COVERSHEET_REPORT: true,
    SHOW_REPORTS_FLEETLEGAL_REPORT: true,
    SHOW_REPORTS_EXPORTFLEET_REPORT: true,
    SHOW_TRADE_LICENSE_PLATES: true,
    SHOW_SHARED_LICENSE_PLATES: true,
    SHOW_SCAN_DOCUMENTS: true,
    SHOW_HULL_PRODUCT: true,
    SHOW_LEASING_EXTRA_FIELDS: false,
    SHOW_EUROTAX_CODE: false,
    SHOW_BONUS_MALUS_CODE: false,
    DEFAULT_COLUMNS: [
      "vehicleStatusCode",
      "licensePlate",
      "fleetName",
      "options",
      "insuranceLineIcons",
      "vehicleIssuingStatusCode",
      "totalAnnualGrossPremium",
      "totalMtplRelatedAnnualGrossPremium",
      "hullAnnualGrossPremium",
      "totalProRataGrossPremium",
      "totalMtplRelatedProRataGrossPremium",
      "hullProRataGrossPremium",
      "totalBookingAmount",
      "mtplRelatedBookingAmount",
      "hullRelatedBookingAmount",
      "carUsageCode",
      "vehicleCreatedAt",
      "vehicleLastModifiedAt",
      "vehicleLastStatusDate",
      "hasSharedLicensePlate",
      "hadSharedLicensePlate",
      "hasIndemnityPremium",
      "hasBonusMalusPremium",
      "hadIndemnityPremium",
      "hadBonusMalusPremium",
      "hullIsBasic",
      "hullProduct",
      "vehicleClassCode",
      "needsGrECoUserAttention",
      "vin",
      "vehicleMainGroupCode",
      "vehicleTypeCode",
      "brandCode",
      "model",
      "costCenterClient",
      "leasingCompany",
      "clientComment",
      "seats",
      "powerKW",
      "cubicCapacityCcm",
      "weightCapacityKg",
      "maxGrossWeightKg",
      "yearOfConstruction",
      "firstRegistrationDate",
      "listPrice",
      "listPriceTypeCode",
      "specialEquipment",
      "secondLicensePlate",
      "vehicleOwner",
      "nationalCodeID",
      "co2Emission",
      "certificateNumber",
      "comment",
      "horsePowerBasedTaxComment",
      "mtplComment",
      "hullComment",
      "accidentComment",
      "assistanceComment",
      "legalComment",
      "excludeHorsePowerBasedTaxReason",
      "excludeHorsePowerBasedTax",
      "gap",
      "replacementValue",
      "grossNegligence",
      "electroPlusPackage",
      "horsePowerBasedTaxAnnualy",
      "horsePowerBasedTaxProRata",
      "hullDeductibleComment",
      "hullDeductibleMaxAmount",
      "hullDeductibleMinAmount",
      "hullDeductiblePercent",
      "hullPolicyNumber",
      "isIncludingTax",
      "isLeasing",
      "legalAnnualGrossPremium",
      "legalProRataGrossPremium",
      "mtplAnnualGrossPremium",
      "mtplPolicyNumber",
      "mtplProRataGrossPremium",
      "accidentAnnualGrossPremium",
      "accidentProRataGrossPremium",
      "assistanceAnnualGrossPremium",
      "assistanceProRataGrossPremium",
      "vatReimbursment",
      "vehiclePowerTypeCode",
      "vehicleIssuingComment",
      "vehicleIssuingSubstatusCode",
      "mtplInsurerPartnerNumber",
      "hullInsurerPartnerNumber",
      "fleetLegalPolicyNumber",
      "fleetLegalIsPolicyIssued",
      "fleetLegalPolicyDocumentDate",
      "fleetLegalInsurerPartnerNumber",
      "fleetLegalAnnualGrossPremium",
      "fleetLegalProRataGrossPremium",
      "fleetLegalIsClosingDate",
      "fleetLegalLastStartDate",
      "fleetLegalComment",
      "fleetLegalRelatedBookingAmount",
    ],
    HAS_GREENCARD_EXPIRY_DATE: false,
  };
  //.map((column) => defaultColumnsByKey[column])
  private static appSettingsTypes: { [key: string]: AppSettingsType } = {
    "Country.HU": AppSettings.hungarianSettings,
    "Country.AT": AppSettings.austrianSettings,
  };
}

export default AppSettings;

import { Label } from "@fluentui/react";
import { FieldContainer } from "components/controls/FieldContainer";
import { useField } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import useMask from "react-mask-hook";
import {} from "store/timeline";
import { VSpace } from "../Spacer";
import { ErrorMessage, StyledTextField } from "../styled";
import { DueDateFieldConfig } from "./types";
import { useDynamicConfig } from "./useDynamicConfig";

type Props = { fieldConfig: DueDateFieldConfig };

export const DueDateField = ({ fieldConfig }: Props) => {
  const config = {
    ...fieldConfig,
    ...useDynamicConfig(fieldConfig.d),
  };

  const {
    name,
    label,
    isVisible = true,
    isDisabled,
    isRequired,
    validate,
    background,
  } = config;
  const { t } = useTranslation();

  const [field, { error }, { setValue }] = useField({ name, validate });
  // const rifm = useDueDateFormat({
  //   value: field.value,
  //   setValue: setValue,
  // });

  function handleChange(value) {
    setValue(value.toUpperCase()); // render as uppercase
  }

  const maskProps = useMask({
    value: field.value,
    onChange: handleChange,
    // array of regexp and formatting characters
    mask: "##/##",
    placeholder: "DD/MM",
  });

  if (!isVisible) return null;

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <FieldContainer
        isTooltipHidden={isDisabled}
        tooltipText={label}
        isReadOnly={true}
      >
        <Label required={isRequired} disabled={isDisabled}>
          {t(label)}
        </Label>
        <StyledTextField
          style={{ flex: 1 }}
          id={name}
          name={name}
          borderless
          disabled={isDisabled}
          hasError={!!error}
          background={background}
          autoComplete={"off"}
          {...field}
          {...(maskProps as any)}
          // {...(rifm as any)}
        />
        {error && (
          <ErrorMessage id={field.name + "_error"}>{error}</ErrorMessage>
        )}
      </FieldContainer>
      <VSpace height={10} />
    </div>
  );
};

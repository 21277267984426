import { CommandBarButton } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
  text?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  path?: string;
};

const backBtnStyle = {
  root: {
    height: "100%",
  },
};

export const BackButton = ({ text, onClick, isDisabled, path }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goTo = () => {
    if (path) {
      navigate(path);
    } else {
      navigate(-1); //go back
    }
  };
  const buttonText = t(text || "bfm.cancel");
  return (
    <CommandBarButton
      iconProps={{ iconName: "Back" }}
      onClick={goTo}
      styles={backBtnStyle}
      text={buttonText}
      disabled={isDisabled}
    />
  );
};

import React, { useEffect, useState } from "react";

import { Toggle, mergeStyleSets } from "@fluentui/react";
import { useTranslation } from "react-i18next";

import { getUserRoles } from "store/api/api";
import { handleAxiosError } from "store/util";
import NavigationComponent from "./NavigationComponent";
import { NavigationLinks } from "./NavigationLinks";
import { allRoles } from "./constants";
import { INavigationLink } from "./types";

interface Props {
  isRolesToggleButtonsFeatureFlagOn: boolean;
  closeAppLuncher: () => void;
}

export const GosNavigation = ({
  isRolesToggleButtonsFeatureFlagOn,
  closeAppLuncher,
}: Props) => {
  const { t } = useTranslation();
  const [userRoles, setUserRoles] = useState([]);

  const fetchUserRoles = async () => {
    try {
      const roles = await getUserRoles();
      setUserRoles(roles);
    } catch (error) {
      handleAxiosError(error);
    }
  };

  useEffect(() => {
    fetchUserRoles();
  }, []);

  return (
    <>
      <h2 className={classes.appHeader}>{t("bfm.appLauncher.header")}</h2>
      <div className={classes.container}>
        <>
          <div className={classes.navWrap}>
            {NavigationLinks(t).map((link: INavigationLink, index: number) => {
              return (
                <NavigationComponent
                  key={link.id}
                  id={link.id}
                  link={link}
                  index={index}
                  closeAppLuncher={closeAppLuncher}
                />
              );
            })}
          </div>
          {isRolesToggleButtonsFeatureFlagOn && (
            <div className={classes.mt}>
              <Toggle
                label={
                  allRoles.length === userRoles.length
                    ? "Deselct all"
                    : "Select all"
                }
                styles={{
                  root: { marginBottom: 20 },
                  label: { width: 100 },
                }}
                inlineLabel
                checked={allRoles.length === userRoles.length}
                onText="On"
                offText="Off"
                onChange={(_, checked) => {
                  setUserRoles(
                    allRoles.length === userRoles.length
                      ? []
                      : allRoles.map((r) => `GOS-${r}`)
                  );
                }}
              />
              {allRoles.map((role) => {
                return (
                  <Toggle
                    key={role}
                    label={role}
                    styles={{ label: { width: 100 } }}
                    inlineLabel
                    checked={userRoles.includes(`GOS-${role}`)}
                    onText="On"
                    offText="Off"
                    onChange={(_, checked) => {
                      let roles = [...userRoles];

                      if (checked) {
                        roles.push(`GOS-${role}`);
                      } else {
                        roles = roles.filter((r) => r !== `GOS-${role}`);
                      }

                      setUserRoles(roles);
                    }}
                  />
                );
              })}
            </div>
          )}
        </>
      </div>
    </>
  );
};

const classes = mergeStyleSets({
  appHeader: { paddingLeft: "15px" },
  container: {
    padding: "12px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  navWrap: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  mt: {
    marginTop: 20,
  },
});

import { mergeStyleSets } from "@uifabric/merge-styles";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "utils/number";
import { useSelector } from "../../../../store/hooks";
import { formatDateWithMoment } from "../../../../utils/utils";
import FormItemTitle from "../../FormItemTitle";
import { vehicleConfig } from "../../vehicleConfig";
import { formatPreviewValue } from "pages/vehicle/helpers";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

const getClassNames = (theme) => {
  // TODO
  return mergeStyleSets({
    container: {
      padding: 40,
    },
    gridRoot: {
      display: "grid",
      margin: "15px 0px 0px 50px",
      gridTemplateColumns: "50% 50%",
      marginLeft: "50px",
    },
    previewLabel: {
      fontWeight: 600,
      fontSize: "14px",
      marginTop: "10px",
      color: theme.palette.black,
    },
    previewValue: {
      fontWeight: "normal",
      color: theme.palette.black,
    },
    isMarked: {
      background: theme.palette.yellowLight,
      color: "black",
    },
  });
};

const VehicleInfoPreview = ({ vehicle, route }) => {
  const theme = useTheme() as IStyledTheme;
  const { t } = useTranslation();
  const vehicleLastStatusDateVisible = false; //route === "createVehicle" || route === "duplicateVehicle";
  const taxonomy = useSelector((s) => s.taxonomy);

  const classes = getClassNames(theme);

  const initialValues = useMemo(() => {
    return vehicleConfig({ vehicle, route, t, taxonomy })
      .map((configItem: any) => {
        let value = configItem?.value?.label || configItem.value;
        if (configItem.field === "date" && configItem.value) {
          value = formatDateWithMoment(value);
        }

        if (
          (configItem.field === "number" || configItem.field === "double") &&
          configItem.value &&
          !configItem?.excludeFromPreviewFormat
        ) {
          value = formatNumber(value);
          if (value % 1 === 0 && configItem.field === "double") {
            value = `${value},00`;
          }
        }
        return {
          value,
          key: configItem.key,
          tab: configItem.tab,
          isMarked: configItem.editedByExternalUser,
        };
      })
      .filter((valueObj) => {
        return !!valueObj.value && valueObj.tab === "vehicle-form";
      });
  }, [vehicle, route, t, taxonomy]);

  return (
    <div className={classes.container}>
      <FormItemTitle
        title={t("bfm.vehicleForm.tabVehicle.label")}
        iconName={"Car"}
      />
      <div className={classes.gridRoot}>
        {initialValues.map((valueObj, index) => {
          if (
            valueObj.key === "bfm.vehicleForm.vehicleLastStatusDate.label" &&
            !vehicleLastStatusDateVisible
          ) {
            return null;
          }
          const value = formatPreviewValue(valueObj?.value, t);

          return (
            <p key={valueObj.key} className={`${classes.previewLabel} `}>
              <span className={valueObj.isMarked && classes.isMarked}>
                {t(valueObj.key)}:{" "}
              </span>
              <span
                className={`${classes.previewValue} ${
                  valueObj.isMarked && classes.isMarked
                }`}
              >
                {value}
              </span>
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default VehicleInfoPreview;

import { AppSettingsType } from "AppSettings";
import _ from "lodash";
import moment from "moment";
import { TaxonomyState } from "../../../store/taxonomy";
import {
  ETimelineExtraDataLineSpecial,
  Insurer,
  PremiumType,
  Timeline,
  TimelineSegment,
  TimelineSegmentType,
  TimelineType,
  Vehicle,
  VehiclePremiumBookingData,
  VehiclePremiumSegmentHistoryData,
} from "../../../types/types";

const setDomainValue = (associatedObjectPremiumTypeCode, taxonomy) => {
  if (
    associatedObjectPremiumTypeCode ===
    taxonomy.PremiumType.byCode[PremiumType.HULL_BASIC].id
  ) {
    return taxonomy.PremiumType.byCode[PremiumType.HULL]?.code;
  }
  if (
    associatedObjectPremiumTypeCode ===
    taxonomy.PremiumType.byCode[PremiumType.FLEETLEGAL_CLOSINGDATE].id
  ) {
    return taxonomy.PremiumType.byCode[PremiumType.FLEETLEGAL_PRORATA]?.code;
  }
  return taxonomy.PremiumType.byId[associatedObjectPremiumTypeCode]?.code;
};

export const prepareBookingSegments = (
  vehicle: Vehicle,
  vehiclePremiumBookingData: VehiclePremiumBookingData,
  taxonomy: TaxonomyState,
  showBookingsWithDocumentDate: boolean,
  appSettings: AppSettingsType
): TimelineSegment[] => {
  let segments: TimelineSegment[] = [];

  const premiumTypes = appSettings?.VEHICLE_PREMIUM_TYPES;

  let bookings = {};
  if (premiumTypes.includes(PremiumType.MTPL)) {
    bookings[PremiumType.MTPL] =
      vehiclePremiumBookingData.vehicleMtplPremiumBookingData.vehiclePremiumBooking;
  }
  if (premiumTypes.includes(PremiumType.HORSE_POWER)) {
    bookings[PremiumType.HORSE_POWER] =
      vehiclePremiumBookingData.vehicleEngineTaxPremiumBookingData.vehiclePremiumBooking;
  }
  if (premiumTypes.includes(PremiumType.ACCIDENT)) {
    bookings[PremiumType.ACCIDENT] =
      vehiclePremiumBookingData.vehicleAccidentPremiumBookingData.vehiclePremiumBooking;
  }
  if (premiumTypes.includes(PremiumType.ASSISTANCE)) {
    bookings[PremiumType.ASSISTANCE] =
      vehiclePremiumBookingData.vehicleAssistancePremiumBookingData.vehiclePremiumBooking;
  }
  if (premiumTypes.includes(PremiumType.LEGAL)) {
    bookings[PremiumType.LEGAL] =
      vehiclePremiumBookingData.vehicleLegalPremiumBookingData.vehiclePremiumBooking;
  }
  if (premiumTypes.includes(PremiumType.HULL)) {
    bookings[PremiumType.HULL] =
      vehiclePremiumBookingData.vehicleHullPremiumBookingData.vehiclePremiumBooking;
  }
  if (premiumTypes.includes(PremiumType.HULL_BASIC)) {
    bookings[PremiumType.HULL_BASIC] =
      vehiclePremiumBookingData.vehicleHullBasicPremiumBookingData.vehiclePremiumBooking;
  }
  //debugger
  if (premiumTypes.includes(PremiumType.FLEETLEGAL_PRORATA)) {
    bookings[PremiumType.FLEETLEGAL_PRORATA] =
      vehiclePremiumBookingData.vehicleFleetLegalProRataPremiumBookingData.vehiclePremiumBooking;
  }
  if (premiumTypes.includes(PremiumType.FLEETLEGAL_CLOSINGDATE)) {
    bookings[PremiumType.FLEETLEGAL_CLOSINGDATE] =
      vehiclePremiumBookingData.vehicleFleetLegalClosingDatePremiumBookingData.vehiclePremiumBooking;
  }
  //debugger

  Object.keys(bookings).forEach((bookingByPremiumType, index) => {
    const lineSegments: TimelineSegment[] = [];

    bookings[bookingByPremiumType]
      .filter((booking) => booking.isDeleted === false)
      .forEach((booking) => {
        const segment: TimelineSegment = {
          domain: {
            type: TimelineType.VEHICLE_PREMIUM,
            value:
              premiumTypes[index] === PremiumType.HULL_BASIC
                ? PremiumType.HULL
                : premiumTypes[index] === PremiumType.FLEETLEGAL_CLOSINGDATE
                ? PremiumType.FLEETLEGAL_PRORATA
                : premiumTypes[index],
          },
          segmentType: TimelineSegmentType.BOOKING,
          startDate: moment(booking.premiumBookingDate).toDate(),
          endDate: null,
          extraData: {},
          associatedObject: booking,
        };
        lineSegments.push(segment);
      });
    let aggregatedLineSegments: TimelineSegment[] = [];
    lineSegments.forEach((lineSegment) => {
      const existingSegmentOnDate = aggregatedLineSegments.find(
        (ls) =>
          ls.associatedObject.premiumBookingDate ===
          lineSegment.associatedObject.premiumBookingDate
      );
      let obj: TimelineSegment = null;
      if (existingSegmentOnDate) {
        obj = {
          ...existingSegmentOnDate,
          extraData: {
            ...existingSegmentOnDate.extraData,
            premiums: [
              ...existingSegmentOnDate.extraData.premiums,
              {
                reason: lineSegment.associatedObject.premiumBookingReasonCode,
                amount: lineSegment.associatedObject.bookedAmount,
              },
            ],
          },
        };
      } else {
        obj = {
          ...lineSegment,
          extraData: {
            ...lineSegment.extraData,
            premiums: [
              {
                reason: lineSegment.associatedObject.premiumBookingReasonCode,
                amount: lineSegment.associatedObject.bookedAmount,
              },
            ],
          },
        };
      }
      if (lineSegment.associatedObject.bookedAmount > 0) {
        obj.extraData.positiveAmount = true;
      }
      if (lineSegment.associatedObject.bookedAmount < 0) {
        obj.extraData.negativeAmount = true;
      }
      if (existingSegmentOnDate) {
        aggregatedLineSegments = aggregatedLineSegments.map((segment) =>
          segment.associatedObject.vehiclePremiumBookingId ===
          existingSegmentOnDate.associatedObject.vehiclePremiumBookingId
            ? obj
            : segment
        );
      } else {
        aggregatedLineSegments.push(obj);
      }
    });
    segments.push(...aggregatedLineSegments);
  });
  segments = segments.sort((a, b) => {
    const aDate = moment(a.associatedObject.premiumBookingDate).toDate();
    const bDate = moment(b.associatedObject.premiumBookingDate).toDate();
    return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
  });

  const documentTypes = vehicle.vehiclePremiumSegmentHistoryData.map(
    (v: VehiclePremiumSegmentHistoryData) => {
      return {
        premiumType: taxonomy.PremiumType.byId[v.premiumTypeCode].code,
        date: moment(v.documentDate).toDate(),
      };
    }
  );
  let seen = Object.create(null);
  let filteredDocumentTypes = documentTypes.filter((documentType) => {
    const key = documentType.premiumType + documentType.date;
    const keep = !seen[key];
    if (keep) {
      seen[key] = true;
    }
    return keep;
  });

  segments.push(
    ...filteredDocumentTypes.map((documentType) => {
      return {
        domain: {
          type: TimelineType.VEHICLE_PREMIUM,
          value: documentType.premiumType,
        },
        segmentType: TimelineSegmentType.BOOKING_DOCUMENT_DATE,
        startDate: moment(documentType.date).toDate(),
        endDate: null,
        extraData: {},
        associatedObject: null,
      };
    })
  );

  return segments;
};

export const prepareOneTimePremiumSegments = (
  timeline: Timeline,
  vehicle: Vehicle,
  taxonomy: TaxonomyState
): TimelineSegment[] => {
  let segments: TimelineSegment[] = [];

  vehicle.vehicleOneTimePremiumSegmentHistoryData.forEach((item, index) => {
    const segment: TimelineSegment = {
      domain: {
        type: TimelineType.VEHICLE_ONE_TIME_PREMIUM,
        value: setDomainValue(item.premiumTypeCode, taxonomy),
      },
      segmentType: TimelineSegmentType.ONE_TIME_PREMIUM,
      startDate: moment(item.oneTimePremiumDate).toDate(),
      endDate: null,
      extraData: {},
      associatedObject: item,
    };
    segments.push(segment);
  });

  let aggregatedLineSegments: TimelineSegment[] = [];

  segments.forEach((segment) => {
    const existingSegmentOnDate = aggregatedLineSegments.find(
      (ls) =>
        ls.associatedObject.oneTimePremiumDate ===
        segment.associatedObject.oneTimePremiumDate
    );
    let obj: TimelineSegment = null;
    if (existingSegmentOnDate) {
      obj = {
        ...existingSegmentOnDate,
        extraData: {
          ...existingSegmentOnDate.extraData,
          associatedObjects: [
            ...existingSegmentOnDate.extraData.associatedObjects,
            segment.associatedObject,
          ],
        },
      };
    } else {
      obj = {
        ...segment,
        extraData: {
          ...segment.extraData,
          associatedObjects: [segment.associatedObject],
        },
      };
    }
    if (segment.associatedObject.oneTimePremiumGrossValue > 0) {
      obj.extraData.positiveAmount = true;
    }
    if (segment.associatedObject.oneTimePremiumGrossValue < 0) {
      obj.extraData.negativeAmount = true;
    }
    if (existingSegmentOnDate) {
      aggregatedLineSegments = aggregatedLineSegments.map((segment) =>
        segment.associatedObject.vehiclePremiumVersionId ===
        existingSegmentOnDate.associatedObject.vehiclePremiumVersionId
          ? obj
          : segment
      );
    } else {
      aggregatedLineSegments.push(obj);
    }
  });
  aggregatedLineSegments = aggregatedLineSegments.sort((a, b) => {
    const aDate = moment(a.associatedObject.oneTimePremiumDate).toDate();
    const bDate = moment(b.associatedObject.oneTimePremiumDate).toDate();
    return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
  });

  return aggregatedLineSegments;
};

export const preparePremiumSegments = (
  timeline: Timeline,
  vehicle: Vehicle,
  insurerList: Insurer[],
  taxonomy: TaxonomyState,
  fleet: any,
  appSettings: AppSettingsType
): TimelineSegment[] => {
  let segments: TimelineSegment[] = [];
  const vehiclePremiumSegments = [...vehicle.vehiclePremiumSegmentHistoryData];
  vehiclePremiumSegments.sort(sortSegments());

  const endSegmentDate = moment(
    _.max(
      vehiclePremiumSegments.map((v: VehiclePremiumSegmentHistoryData) => {
        return moment(v.proRataValidToDate).toDate();
      })
    )
  ).toDate();
  const infinitePremiumVersionSegmentIds = [];

  for (let index = 0; index < vehiclePremiumSegments.length; index++) {
    const premiumSegment = vehiclePremiumSegments[index];

    const isInfinitePremium =
      premiumSegment.validToDate === null &&
      moment(endSegmentDate).isSame(
        moment(premiumSegment.proRataValidToDate),
        "day"
      );

    if (isInfinitePremium) {
      if (
        !infinitePremiumVersionSegmentIds.find(
          (item) => premiumSegment.vehiclePremiumVersionId === item
        )
      ) {
        infinitePremiumVersionSegmentIds.push(
          premiumSegment.vehiclePremiumVersionId
        );
      }
    }

    let lineName =
      taxonomy.PremiumType.byId[premiumSegment.premiumTypeCode].code;
    const acceptedPremiumTypes = appSettings?.VEHICLE_PREMIUM_TYPES;
    let segmentAcceptable = false;
    for (let i = 0; i < acceptedPremiumTypes.length; i++) {
      if (lineName === acceptedPremiumTypes[i]) {
        segmentAcceptable = true;
        break;
      }
    }
    if (!segmentAcceptable) continue;

    let lineSpecial = null;
    if (lineName === PremiumType.HULL_BASIC) {
      lineName = PremiumType.HULL;
      lineSpecial = ETimelineExtraDataLineSpecial.BASIC;
    }

    if (lineName === PremiumType.FLEETLEGAL_CLOSINGDATE) {
      lineName = PremiumType.FLEETLEGAL_PRORATA;
      lineSpecial = ETimelineExtraDataLineSpecial.CLOSING_DATE;
    }
    const insurerObj = insurerList.find(
      (insurer) =>
        insurer.insurerInternalNumber === premiumSegment.insurerPartnerNumber
    );
    segments.push({
      domain: {
        type: TimelineType.VEHICLE_PREMIUM,
        value: lineName,
      },
      segmentType: premiumSegment.isVehicleSuspended
        ? TimelineSegmentType.SUSPENSION
        : TimelineSegmentType.PREMIUM,
      startDate: moment(premiumSegment.proRataValidFromDate).toDate(),
      endDate: moment(premiumSegment.proRataValidToDate).toDate(),
      extraData: {
        isInfinitePremium: infinitePremiumVersionSegmentIds.includes(
          premiumSegment.vehiclePremiumVersionId
        ),
        isLastSegmentInLine: isInfinitePremium,
        vehiclePremiumVersionId: premiumSegment.vehiclePremiumVersionId,
        lineSpecial: lineSpecial,
        insurer: insurerObj?.insurerName,
        insurerInternalNumber: insurerObj?.insurerInternalNumber,
        branchOffice: insurerObj.reportInsurerBranchOffices.find(
          (bo) =>
            bo.branchOfficeInternalNumber === premiumSegment.insurerBranchOffice
        )?.branchOfficeName,
        branchOfficeInternalNumber: insurerObj.reportInsurerBranchOffices.find(
          (bo) =>
            bo.branchOfficeInternalNumber === premiumSegment.insurerBranchOffice
        )?.branchOfficeInternalNumber,
      },
      associatedObject: premiumSegment,
    });

    segments.sort(sortPremiumSegments());

    let currentId = null;
    for (let index = 0; index < segments.length; index++) {
      const currentSegment = segments[index];
      if (
        !currentId ||
        currentId !== currentSegment.extraData.vehiclePremiumVersionId
      ) {
        if (index > 0) {
          segments[index - 1].extraData.rightBorder = true;
        }
        segments[index].extraData.leftBorder = true;
        currentId = currentSegment.extraData.vehiclePremiumVersionId;
      } else {
        if (index > 0) {
          segments[index - 1].extraData.rightBorder = false;
        }
        segments[index].extraData.leftBorder = false;
      }
    }
    if (segments.length > 0) {
      segments[segments.length - 1].extraData.rightBorder = true;
    }
  }
  let currentLine = "";
  let lastSegment: TimelineSegment = null;

  const startDate = timeline.startDate;
  const endDate = timeline.endDate;

  // Insert void segments
  const segmentsToInsert: TimelineSegment[] = [];
  for (let index = 0; index < segments.length; index++) {
    let segment: TimelineSegment = segments[index];
    if (segment.domain.value !== currentLine) {
      // End element
      if (lastSegment !== null) {
        if (
          moment(lastSegment.endDate).isBefore(moment(endDate)) &&
          !lastSegment.extraData.isInfinitePremium
        ) {
          segmentsToInsert.push({
            domain: {
              type: TimelineType.VEHICLE_PREMIUM,
              value: setDomainValue(
                lastSegment.associatedObject.premiumTypeCode,
                taxonomy
              ),
            },
            segmentType: TimelineSegmentType.VOID_AFTER,

            startDate: moment(lastSegment.endDate).toDate(),
            endDate: moment(endDate).toDate(),
            extraData: {
              leftBorder: false,
              rightBorder: false,
            },
            associatedObject: null,
          });
        }
      }
      // First element
      currentLine = segment.domain.value;
      if (moment(segment.startDate).isAfter(moment(startDate))) {
        segmentsToInsert.push({
          domain: {
            type: TimelineType.VEHICLE_PREMIUM,
            value: setDomainValue(
              segment.associatedObject.premiumTypeCode,
              taxonomy
            ),
          },
          segmentType: TimelineSegmentType.VOID_BEFORE,

          startDate: moment(startDate).toDate(),
          endDate: moment(segment.startDate).toDate(),
          extraData: {
            leftBorder: false,
            rightBorder: false,
          },
          associatedObject: null,
        });
      }
    } else {
      if (moment(segment.startDate).isAfter(moment(lastSegment.endDate))) {
        let domainValue =
          taxonomy.PremiumType.byId[segment.associatedObject.premiumTypeCode]
            ?.code;
        let lineSpecial = null;
        if (
          taxonomy.PremiumType.byId[segment.associatedObject.premiumTypeCode]
            ?.code === PremiumType.HULL_BASIC
        ) {
          domainValue = PremiumType.HULL;
          lineSpecial = ETimelineExtraDataLineSpecial.BASIC;
        }

        if (
          taxonomy.PremiumType.byId[segment.associatedObject.premiumTypeCode]
            ?.code === PremiumType.FLEETLEGAL_CLOSINGDATE
        ) {
          domainValue = PremiumType.FLEETLEGAL_PRORATA;
          lineSpecial = ETimelineExtraDataLineSpecial.CLOSING_DATE;
        }

        segmentsToInsert.push({
          domain: {
            type: TimelineType.VEHICLE_PREMIUM,
            value: domainValue,
          },
          segmentType: TimelineSegmentType.VOID_BETWEEN,

          startDate: moment(lastSegment.endDate).toDate(),
          endDate: moment(segment.startDate).toDate(),
          extraData: {
            leftBorder: false,
            rightBorder: false,
            ...(lineSpecial != null && { lineSpecial: lineSpecial }),
          },
          associatedObject: null,
        });
      }
    }
    lastSegment = segment;
  }
  if (
    lastSegment &&
    moment(lastSegment.endDate).isBefore(moment(endDate)) &&
    !lastSegment.extraData.isLastSegmentInLine
  ) {
    segmentsToInsert.push({
      domain: {
        type: TimelineType.VEHICLE_PREMIUM,
        value: setDomainValue(
          lastSegment.associatedObject.premiumTypeCode,
          taxonomy
        ),
      },
      segmentType: TimelineSegmentType.VOID_AFTER,

      startDate: moment(lastSegment.endDate).toDate(),
      endDate: moment(endDate).toDate(),
      extraData: {
        leftBorder: false,
        rightBorder: false,
      },
      associatedObject: null,
    });
  }
  // No premium for line - add empty segment
  const premiumDomain = appSettings?.VEHICLE_PREMIUM_DOMAINS;

  for (let insuranceLine of premiumDomain) {
    const result = segmentsToInsert.find(
      ({ domain }) => domain.value === insuranceLine
    );
    if (
      vehicle.isTradeLicensePlate &&
      insuranceLine === PremiumType.HORSE_POWER
    ) {
      continue;
    }

    if (!!!result) {
      segmentsToInsert.push({
        domain: {
          type: TimelineType.VEHICLE_PREMIUM,
          value: insuranceLine,
        },
        segmentType: TimelineSegmentType.VOID_AFTER,

        startDate: moment(startDate).toDate(),
        endDate: moment(endDate).toDate(),
        extraData: {
          leftBorder: false,
          rightBorder: false,
        },
        associatedObject: null,
      });
    }
  }
  segments = [...segments, ...segmentsToInsert];
  segments.sort(sortPremiumSegments());

  return segments;
};

function extractDomainValues({ appSettings }) {
  return appSettings?.VEHICLE_PREMIUM_DOMAINS;
}

function extractPolicyStatusSegments(
  availablePoliciesPerInsuranceLines: any,
  vehiclePolicyStatusHistoryData: any,
  taxonomy
) {
  vehiclePolicyStatusHistoryData.forEach((segment) => {
    const premiumId = segment.vehiclePremiumId;
    availablePoliciesPerInsuranceLines.forEach(
      (insuranceLineValue, insuranceLine) => {
        const policy = insuranceLineValue.get(premiumId);
        if (policy) {
          const policyStatus =
            taxonomy.VehiclePolicyStatusCode.byId[segment.statusCode].code;
          policy.segments.push({
            associatedObject: { ...segment },
            domain: {
              type: TimelineType.VEHICLE_POLICY_STATUSES,
              value: insuranceLine,
            },
            segmentType: PolicyStatusSegmentType[policyStatus],
            startDate: new Date(segment.statusDate),
            endDate: null,
          });
        }
      }
    );
  });
  availablePoliciesPerInsuranceLines.forEach((insuranceLineValue) => {
    insuranceLineValue.forEach((policy) => {
      policy.segments.sort(
        (a, b) =>
          moment(a.startDate).toDate().getTime() -
          moment(b.startDate).toDate().getTime()
      );
    });
  });
  // set end date based on next status or total policy end date
  availablePoliciesPerInsuranceLines.forEach((insuranceLineValue) => {
    insuranceLineValue.forEach((policy) => {
      for (let i = 0; i < policy.segments.length; i++) {
        if (i === policy.segments.length - 1) {
          policy.segments[i].endDate = policy.endDate;
        } else {
          policy.segments[i].endDate = policy.segments[i + 1].startDate;
        }
      }
    });
  });
  // insert special void segments before, between, and after policy status segments if there is a gap
  availablePoliciesPerInsuranceLines.forEach((insuranceLineValue) => {
    insuranceLineValue.forEach((policy) => {
      const segmentsToInsert: TimelineSegment[] = [];
      for (let i = 0; i < policy.segments.length; i++) {
        const currentSegment = policy.segments[i];
        if (i === 0) {
          if (moment(currentSegment.startDate).isAfter(policy.startDate)) {
            segmentsToInsert.push({
              domain: {
                type: TimelineType.VEHICLE_POLICY_STATUSES,
                value: currentSegment.domain.value,
              },
              segmentType: TimelineSegmentType.VOID_BEFORE,
              startDate: policy.startDate,
              endDate: currentSegment.startDate,
              extraData: {},
              associatedObject: null,
            });
          }
        } else {
          const previousSegment = policy.segments[i - 1];
          if (
            moment(currentSegment.startDate).isAfter(previousSegment.endDate)
          ) {
            segmentsToInsert.push({
              domain: {
                type: TimelineType.VEHICLE_POLICY_STATUSES,
                value: currentSegment.domain.value,
              },
              segmentType: TimelineSegmentType.VOID_BETWEEN,
              startDate: previousSegment.endDate,
              endDate: currentSegment.startDate,
              extraData: {},
              associatedObject: null,
            });
          }
        }
        if (i === policy.segments.length - 1) {
          if (moment(currentSegment.endDate).isBefore(policy.endDate)) {
            segmentsToInsert.push({
              domain: {
                type: TimelineType.VEHICLE_POLICY_STATUSES,
                value: currentSegment.domain.value,
              },
              segmentType: TimelineSegmentType.VOID_AFTER,
              startDate: currentSegment.endDate,
              endDate: policy.endDate,
              extraData: {},
              associatedObject: null,
            });
          }
        }
      }
      if (policy.segments.length === 0) {
        segmentsToInsert.push({
          domain: {
            type: TimelineType.VEHICLE_POLICY_STATUSES,
            value: policy.insuranceLine,
          },
          segmentType: TimelineSegmentType.VOID_AFTER,
          startDate: policy.startDate,
          endDate: policy.endDate,
          extraData: {},
          associatedObject: null,
        });
      }
      policy.segments = [...policy.segments, ...segmentsToInsert];
    });
  });

  const segments = [];
  availablePoliciesPerInsuranceLines.forEach((insuranceLineValue) => {
    insuranceLineValue.forEach((policy) => {
      for (let i = 0; i < policy.segments.length; i++) {
        const currentSegment = policy.segments[i];
        segments.push(currentSegment);
      }
    });
  });

  return segments;
}

export const preparePolicyStatusSegments = (
  timeline: Timeline,
  vehicle: Vehicle,
  insurerList: Insurer[],
  taxonomy: TaxonomyState,
  fleet: any,
  appSettings: AppSettingsType
): TimelineSegment[] => {
  let segments: TimelineSegment[] = preparePremiumSegments(
    timeline,
    vehicle,
    insurerList,
    taxonomy,
    fleet,
    appSettings
  );
  segments = segments.filter(
    (segment) => segment.segmentType === TimelineSegmentType.PREMIUM
  );

  let availableInsuranceLines = extractDomainValues({ appSettings });

  let availablePoliciesPerInsuranceLines = extractPolicies(
    availableInsuranceLines,
    segments
  );
  const vehiclePolicyStatusHistoryData = [
    ...vehicle.vehiclePolicyStatusHistoryData,
  ];

  let policyStatusSegments = extractPolicyStatusSegments(
    availablePoliciesPerInsuranceLines,
    vehiclePolicyStatusHistoryData,
    taxonomy
  );
  return policyStatusSegments;
};

function extractPolicies(
  availableInsuranceLines: PremiumType[],
  premiumSegments: TimelineSegment[]
) {
  const availablePoliciesPerInsuranceLines = new Map();
  availableInsuranceLines.forEach((insuranceLine) => {
    const availablePolicies = new Map();
    premiumSegments.forEach((segment) => {
      if (segment.domain.value === insuranceLine) {
        let list = availablePolicies.get(
          segment.associatedObject.vehiclePremiumId
        );
        if (!list) list = [];
        list.push(segment);
        availablePolicies.set(segment.associatedObject.vehiclePremiumId, list);
      }
    });
    // go though items in availablePolicies map and replce the list with object like { lowest start date, largest end date }
    availablePolicies.forEach((value, key) => {
      let lowestStartDate = null;
      let largestEndDate = null;
      value.forEach((segment) => {
        if (!lowestStartDate || segment.startDate < lowestStartDate) {
          lowestStartDate = segment.startDate;
        }
        if (!largestEndDate || segment.endDate > largestEndDate) {
          largestEndDate = segment.endDate;
        }
      });
      availablePolicies.set(key, {
        startDate: lowestStartDate,
        endDate: largestEndDate,
        insuranceLine: insuranceLine,
        segments: [],
      });
    });
    availablePoliciesPerInsuranceLines.set(insuranceLine, availablePolicies);
  });
  return availablePoliciesPerInsuranceLines;
}

const PolicyStatusSegmentType = {
  "VehiclePolicyStatusCode.PENDING": TimelineSegmentType.POLICY_STATUS_PENDING,
  "VehiclePolicyStatusCode.CLOSED": TimelineSegmentType.POLICY_STATUS_CLOSED,
  "VehiclePolicyStatusCode.WAITING_FOR_INSURER":
    TimelineSegmentType.POLICY_STATUS_WAITING_FOR_INSURER,
  "VehiclePolicyStatusCode.WAITING_FOR_CLIENT":
    TimelineSegmentType.POLICY_STATUS_WAITING_FOR_CLIENT,
  "VehiclePolicyStatusCode.WAITING_FOR_REGISTRATION":
    TimelineSegmentType.POLICY_STATUS_WAITING_FOR_REGISTRATION,
  "VehiclePolicyStatusCode.WAITING_FOR_CHECKING":
    TimelineSegmentType.POLICY_STATUS_WAITING_FOR_CHECKING,
  "VehiclePolicyStatusCode.WAITING_FOR_USER":
    TimelineSegmentType.POLICY_STATUS_WAITING_FOR_USER,
};
function createStatusObjects(mergedObjects, statusObjects, taxonomy) {
  const statusMap = new Map();

  // Group status objects by vehiclePremiumId
  statusObjects.forEach((status) => {
    const key = status.vehiclePremiumId;

    if (!statusMap.has(key)) {
      statusMap.set(key, []);
    }
    statusMap.get(key).push(status);
  });

  const result = [];

  mergedObjects.forEach((mergedObj) => {
    const key = mergedObj.associatedObject.vehiclePremiumId;
    const statuses = statusMap.get(key) || [];

    // Sort status objects by statusDate
    statuses.sort(
      (a, b) =>
        moment(a.statusDate).toDate().getTime() -
        moment(b.statusDate).toDate().getTime()
    );

    statuses.forEach((status, index) => {
      const nextStatus = statuses[index + 1];
      const endDate = nextStatus
        ? new Date(nextStatus.statusDate)
        : mergedObj.endDate;
      const policyStatus =
        taxonomy.VehiclePolicyStatusCode.byId[status.statusCode].code;

      result.push({
        associatedObject: { ...status },
        domain: {
          type: TimelineType.VEHICLE_POLICY_STATUSES,
          value: mergedObj.domain.value,
        },
        segmentType: PolicyStatusSegmentType[policyStatus],
        startDate: new Date(status.statusDate),
        endDate: endDate,
      });
    });
  });
  return result;
}
function sortPremiumSegments() {
  return (a, b) => {
    var aLine = a.domain.value;
    var bLine = b.domain.value;
    var aDate = a.startDate;
    var bDate = b.startDate;
    if (aLine === bLine) {
      return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
    } else {
      return aLine < bLine ? -1 : 1;
    }
  };
}

function sortSegments() {
  return (a, b) => {
    var aLine = a.premiumTypeCode;
    var bLine = b.premiumTypeCode;
    var aDate = a.proRataValidFromDate;
    var bDate = b.proRataValidFromDate;
    if (aLine === bLine) {
      return aDate < bDate ? 1 : aDate > bDate ? -1 : 0;
    } else {
      return aLine < bLine ? 1 : -1;
    }
  };
}

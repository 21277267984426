import { useMutation } from "react-query";
import {
  SharedLicensePlateRequestParams,
  correctSharedLicensePlate,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const correctSharedLicensePlateFn = async (
  params: SharedLicensePlateRequestParams
) => {
  return await correctSharedLicensePlate(params);
};

export function useCorrectSharedLicensePlate() {
  return useMutation(correctSharedLicensePlateFn, {
    mutationKey: MUTATION_KEYS.correctSharedLicensePlate,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import {
  Checkbox,
  CommandBarButton,
  IconButton,
  IIconProps,
  mergeStyleSets,
  TooltipHost,
} from "@fluentui/react";
import { Search } from "pages/fleet/Search";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { UserRole } from "types/types";
import { ReactComponent as FleetIcon } from "../../assets/Groupfleet.svg";
import { ResultsScore } from "../../components/ResultsScore";
import { HSpace } from "../../components/styled";
import { VehicleStatusFilter } from "../../components/VehicleStatusFilter";
import {
  loadVehiclesActions,
  setShowTEMP,
  setVehicleStatusFilter,
  unselectAllVehicles,
} from "../../store/fleetPage";
import { useSelector } from "../../store/hooks";
import AddTradeVehicleButton from "./AddTradeVehicleButton";

type Props = {
  setClearAllSelectedVehicles: Function;
  isTradeLicensePlate?: boolean;
};

const TradeLicensePlatesToolbar = ({ setClearAllSelectedVehicles }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loadVehiclesStatus = useSelector((s) => s.fleetPage.loadVehiclesStatus);
  const isLoading = loadVehiclesStatus === "loading";

  const fleet = useSelector((s) => s.fleetForm.fleetWithPremiums);
  const showTEMP = useSelector((s) => s.fleetPage.showTEMP);
  const userRole = useSelector((s) => s.auth.userRole);
  const selectedVehicleIds = useSelector((s) => s.fleetPage.selectedVehicleIds);
  const vehiclesTotalCount = useSelector((s) => s.fleetPage.vehiclesTotalCount);

  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;

  const vehicleStatusFilter = useSelector(
    (s) => s.fleetPage.vehicleStatusFilter
  );
  const cancelIcon: IIconProps = { iconName: "Cancel" };
  const classes = mergeStyleSets({
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      background: isDarkMode
        ? theme.palette.blackTranslucent40
        : theme.palette.white,
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
      height: "50px",
      padding: "0 10px",
    },
    search: {
      marginLeft: "10px",
    },
    fleetName: {
      fontWeight: "bold",
      color: isDarkMode ? theme.palette.white : "inherit",
    },
    toolbarRight: {
      marginLeft: "auto",
      paddingRight: "10px",
      display: "flex",
      alignItems: "center",
    },
    cancelBtn: {
      display: "flex",
      alignItems: "center",
      height: 32,
      ".ms-Button-icon": {
        height: "inherit",
        display: "flex",
        alignItems: "center",
      },
    },
  });

  return (
    <div className={classes.container}>
      <FleetIcon />

      <HSpace width={10} />
      <TooltipHost content={t("Refresh")}>
        <IconButton
          iconProps={{ iconName: "Refresh" }}
          onClick={() => {
            dispatch(
              loadVehiclesActions.trigger({
                isReset: true,
                shouldDelay: false,
                shouldEmptyVehicles: true,
              })
            );
          }}
          disabled={isLoading}
        />
      </TooltipHost>
      <HSpace width={20} />
      {userRole !== UserRole.external && (
        <>
          <Checkbox
            label={t("bfm.showTempTradeLicensePlate.label")}
            checked={showTEMP}
            onChange={() => dispatch(setShowTEMP(!showTEMP))}
          />
          <HSpace width={20} />
        </>
      )}
      <HSpace width={20} />

      <Search />
      <HSpace width={10} />

      {!showTEMP && (
        <VehicleStatusFilter
          vehicleStatusId={vehicleStatusFilter}
          onChange={(id) => {
            dispatch(setVehicleStatusFilter(id));
          }}
        />
      )}
      {userRole !== UserRole.adminreadonly && <AddTradeVehicleButton />}

      <div className={classes.toolbarRight}>
        {selectedVehicleIds.length > 0 && (
          <>
            <CommandBarButton
              onClick={() => {
                setClearAllSelectedVehicles(true);
                dispatch(unselectAllVehicles());
              }}
              className={classes.cancelBtn}
              iconProps={cancelIcon}
              text={`${selectedVehicleIds.length} selected`}
            />
            <HSpace width={10} />
          </>
        )}

        <ResultsScore score={vehiclesTotalCount} />
      </div>
    </div>
  );
};

export default TradeLicensePlatesToolbar;

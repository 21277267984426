import { routes, useCurrentRoute } from "config/routes";
import { MUTATION_KEYS } from "hooks/data/queryKeys";
import { useIsMutating } from "react-query";
import { useSelector } from "store/hooks";

function useIsCancelVehiclePendingStatusLoading(vehicleId: string | number) {
  const route = useCurrentRoute();

  const isRequireActionRoute = route === routes.requireActionVehicles.key;

  const selectLocation = isRequireActionRoute
    ? (s) => s.requireActionVehicles.selectedVehicleIds
    : (s) => s.fleetPage.selectedVehicleIds;

  const selectedVehicleIds = useSelector(selectLocation)?.map((item) =>
    Number(item)
  );

  const cancelStatusLoadingStatus = !!useIsMutating({
    mutationKey: [MUTATION_KEYS.cancelVehiclePendingStatus],
  });

  const isLoading =
    cancelStatusLoadingStatus && selectedVehicleIds.includes(Number(vehicleId));

  return isLoading;
}

export default useIsCancelVehiclePendingStatusLoading;

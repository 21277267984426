import { IFieldConfig } from "../../form/types";

export const modifyOneTimePremiumDialogConfig: IFieldConfig = {
  oneTimePremiumDate: {
    name: "oneTimePremiumDate",
    type: "date",
    isRequired: true,
    d: ({ t, values, errors, setFieldValue }) => ({
      isDisabled: false,
      label: "bfm.oneTimePremiumDate.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.oneTimePremiumDate,
    }),
  },
  oneTimePremiumGrossValue: {
    name: "oneTimePremiumGrossValue",
    type: "number",
    isNegativeAllowed: true,
    isRequired: true,
    d: ({ t, vehicle }) => ({
      label: "bfm.oneTimePremiumGrossValue.label",
    }),
  },
  oneTimePremiumNetValue: {
    name: "oneTimePremiumNetValue",
    type: "number",
    isNegativeAllowed: true,
    isRequired: true,
    d: ({ t }) => ({
      label: "bfm.oneTimePremiumNetValue.label",
    }),
  },

  oneTimePremiumComment: {
    name: "oneTimePremiumComment",
    type: "text",
    isMultiline: true,
    rows: 6,
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.comment.label",
    }),
  },
  oneTimePremiumType: {
    name: "oneTimePremiumType",
    type: "text",
    isVisible: false,
    d: ({ t, values, errors, setFieldValue }) => ({
      isDisabled: false,

      label: "bfm.oneTimePremiumType.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.oneTimePremiumDate,
    }),
  },
};

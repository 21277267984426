import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useCurrentRoute } from "../../../config/routes";
import { useSelector } from "../../../store/hooks";
import { loadFleetDefaultSettingsRoutine } from "../../../store/vehicle";

export const useFetchDefaultSettings = () => {
  const fleet = useSelector((s) => s.vehicle.fleet);
  const defaultSettingsExist = useSelector(
    (s) => s.vehicle.fleetDefaultSettings
  );
  const route = useCurrentRoute();
  const dispatch = useDispatch();
  const { fleetId } = useParams() as any;

  useEffect(() => {
    if (
      (route === "createVehicle" ||
        route === "duplicateVehicle" ||
        route === "editVehicle" ||
        route === "editTempVehicle") &&
      fleet
    ) {
      if (!defaultSettingsExist) {
        dispatch(loadFleetDefaultSettingsRoutine.trigger({ fleetId }));
      }
    }
  }, [fleet, route, defaultSettingsExist, fleetId]);
};

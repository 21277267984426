import { isExternalOrReadonlyAdmin } from "form/util";
import { useApproveVehiclePendingStatus } from "hooks/data/mutations/useApproveVehiclePendingStatus";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setChangePolicyStatusDialog } from "store/vehicle";
import { useSelector } from "../../../store/hooks";
import { openDialog } from "../../../store/timeline";
import {
  TimelineContextMenuType,
  TimelineDialogType,
  TimelineSegmentType,
  TimelineType,
} from "../../../types/types";

export const usePolicyStatusSegmentMenuItems = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const segmentContextMenuItem = useSelector(
    (s) => s.timeline.contextMenu.item
  );
  const userRole = useSelector((s) => s.auth.userRole);

  const {
    mutate: onApproveVehiclePendingStatus,
    isLoading: isApproveVehicleStatusLoading,
  } = useApproveVehiclePendingStatus();

  const segmentContextMenuType = useSelector(
    (s) => s.timeline.contextMenu.type
  );
  const vehicle = useSelector((s) => s.vehicle?.vehicle);

  const statusContextMenuDate = useSelector((s) => s.timeline.contextMenu.date);

  const segmentContextMenuItemDomainType = useSelector(
    (s) => s.timeline.contextMenu.item?.domain.type
  );

  const segmentType = useSelector(
    (s) => s.timeline.contextMenu.item?.segmentType
  );

  const segmentContextMenuItemDomainValue = useSelector(
    (s) => s.timeline.contextMenu.item?.domain.value
  );

  return useMemo(() => {
    const items = [];
    if (
      segmentContextMenuItemDomainType !== TimelineType.VEHICLE_POLICY_STATUSES
    ) {
      return [];
    }
    // Add and Insert
    if (
      segmentContextMenuType === TimelineContextMenuType.POLICY_STATUS_POINT
    ) {
      // items.push({
      //   key: "insert policy status",
      //   text: t("bfm.insertPolicyStatus"),
      //   onClick: () => {
      //     dispatch(
      //       setChangePolicyStatusDialog({
      //         fleetId: "" + vehicle.fleetId,
      //         isOpen: true,
      //         vehicleIds: [vehicle.vehicleId],
      //         date: statusContextMenuDate,
      //         addNew: false,
      //       })
      //     );
      //   },
      // });
      if (!isExternalOrReadonlyAdmin(userRole)) {
        items.push({
          key: "correct policy status date",
          text: t("bfm.correctPolicyStatusDate"),
          onClick: () => {
            dispatch(
              openDialog({
                dialogType: TimelineDialogType.CORRECT_POLICY_STATUS,
                contextMenuItem: segmentContextMenuItem,
                contextMenuDate: moment(
                  segmentContextMenuItem.startDate
                ).toDate(),
                previousDialogData: null,
              })
            );
          },
        });

        items.push({
          key: "delete policy status date",
          text: t("bfm.deletePolicyStatusChange"),
          onClick: () => {
            dispatch(
              openDialog({
                dialogType: TimelineDialogType.DELETE_POLICY_STATUS,
                contextMenuItem: segmentContextMenuItem,
                contextMenuDate: moment(
                  segmentContextMenuItem.startDate
                ).toDate(),
                previousDialogData: null,
              })
            );
          },
        });
      }
    }
    if (segmentContextMenuType === TimelineContextMenuType.POLICY_STATUS_BAR) {
      if (!isExternalOrReadonlyAdmin(userRole)) {
        if (
          segmentType === TimelineSegmentType.VOID_AFTER ||
          segmentType === TimelineSegmentType.VOID_BEFORE ||
          segmentType === TimelineSegmentType.VOID_BETWEEN
        ) {
          items.push({
            key: "insert policy status",
            text: t("bfm.insertPolicyStatus"),
            onClick: () => {
              dispatch(
                setChangePolicyStatusDialog({
                  isOpen: true,
                  date: statusContextMenuDate,
                  addNew: true,
                  item: segmentContextMenuItem,
                })
              );
            },
          });
        } else {
          items.push({
            key: "change policy status",
            text: t("bfm.changePolicyStatus"),
            onClick: () => {
              dispatch(
                setChangePolicyStatusDialog({
                  isOpen: true,
                  date: statusContextMenuDate,
                  addNew: false,
                  item: segmentContextMenuItem,
                })
              );
            },
          });
        }
        // if (!segmentContextMenuItem.segmentType.includes("void")) {
        //   items.push({
        //     key: "correct policy status date",
        //     text: t("bfm.correctPolicyStatusDate"),
        //     onClick: () => {
        //       dispatch(
        //         openDialog({
        //           dialogType: TimelineDialogType.CORRECT_POLICY_STATUS,
        //           contextMenuItem: segmentContextMenuItem,
        //           contextMenuDate: moment(
        //             segmentContextMenuItem.startDate
        //           ).toDate(),
        //           previousDialogData: null,
        //         })
        //       );
        //     },
        //   });

        //   items.push({
        //     key: "delete policy status date",
        //     text: t("bfm.deletePolicyStatusChange"),
        //     onClick: () => {
        //       dispatch(
        //         openDialog({
        //           dialogType: TimelineDialogType.DELETE_POLICY_STATUS,
        //           contextMenuItem: segmentContextMenuItem,
        //           contextMenuDate: moment(
        //             segmentContextMenuItem.startDate
        //           ).toDate(),
        //           previousDialogData: null,
        //         })
        //       );
        //     },
        //   });
        // }
      } else {
        // items.push({
        //   key: "insert policy status",
        //   text: t("bfm.insertPolicyStatus"),
        //   onClick: () => {
        //     dispatch(
        //       setChangePolicyStatusDialog({
        //         fleetId: "" + vehicle.fleetId,
        //         isOpen: true,
        //         vehicleIds: [vehicle.vehicleId],
        //         date: statusContextMenuDate,
        //         addNew: false,
        //       })
        //     );
        //   },
        // });
      }
    }
    // if (segmentContextMenuType === TimelineContextMenuType.POLICY_STATUS_BAR) {
    // if (segmentContextMenuItem.extraData.isLastSegmentInLine) {
    //   if (
    //     segmentContextMenuItemDomainValue !== "VehicleStatusCode.DEACTIVATED"
    //   ) {
    //     items.push({
    //       key: "change status",
    //       text: t("bfm.changeStatus"),
    //       onClick: () => {
    //         dispatch(
    //           setChangeVehicleStatusDialog({
    //             fleetId: "" + vehicle.fleetId,
    //             isOpen: true,
    //             vehicleIds: [vehicle.vehicleId],
    //             date: statusContextMenuDate,
    //             addNew: false,
    //           })
    //         );
    //       },
    //     });
    //   }
    // } else {
    //   items.push({
    //     key: "insert policy status",
    //     text: t("bfm.insertPolicyStatus"),
    //     onClick: () => {
    //       dispatch(
    //         openDialog({
    //           dialogType: TimelineDialogType.INSERT_POLICY_STATUS,
    //           contextMenuItem: segmentContextMenuItem,
    //           contextMenuDate: moment(statusContextMenuDate).toDate(),
    //           previousDialogData: null,
    //         })
    //       );
    //     },
    //   });
    // }
    //}
    return items;
  }, [
    dispatch,
    t,
    segmentContextMenuItem,
    segmentContextMenuType,
    statusContextMenuDate,
    segmentContextMenuItemDomainType,
    segmentContextMenuItemDomainValue,
    userRole,
    vehicle,
  ]);
};

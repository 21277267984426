import DatePickerField from "components/controls/DatePickerField";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const typeSelection = [
  "HULL",
  "HULL_BASIC",
  "MTPL",
  "FLEETLEGAL_PRORATA",
  "FLEETLEGAL_CLOSINGDATE",
];

const DocumentDateDependencyField = ({
  premiumTypeSelected,
  classesItem,
  errors,
  fieldName,
  setFieldValue,
  values,
}) => {
  const { t } = useTranslation();
  const renderDocumentDate = typeSelection.includes(premiumTypeSelected?.code);

  useEffect(() => {
    if (!renderDocumentDate) {
      setFieldValue(fieldName, null);
    }
  }, [premiumTypeSelected, renderDocumentDate, setFieldValue]);

  if (!renderDocumentDate) {
    return null;
  }

  return (
    <div className={classesItem}>
      <DatePickerField
        errors={errors}
        setFieldValue={setFieldValue}
        defaultValue={values.documentDate}
        name={fieldName}
        disabled={false}
        label={"bfm.vehicleForm.documentDate.label"}
        placeholder={t("bfm.selectDate")}
      />
    </div>
  );
};

export default DocumentDateDependencyField;

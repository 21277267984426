import { useApproveVehiclePendingStatus } from "hooks/data/mutations/useApproveVehiclePendingStatus";
import { MUTATION_KEYS } from "hooks/data/queryKeys";
import React from "react";
import { useTranslation } from "react-i18next";
import { useIsMutating } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "../../../components/FluentToast";
import {
  loadVehiclesActions,
  setCancelVehicleStatusDialog,
  setChangeVehicleIssuingStatusDialog,
  setChangeVehicleStatusDialog,
} from "../../../store/requireActionVehicles";
import { AppDispatch } from "../../../store/store";
import { VehicleRedux } from "../../../types/types";
import { RequiredVehicleContextButton } from "./RequiredVehicleContextButton";

type Props = {
  vehicle: VehicleRedux;
};

export const ContextButton = ({ vehicle }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const isUpdateVehicleStatusLoading = !!useIsMutating({
    mutationKey: [MUTATION_KEYS.changeVehicleStatus],
  });

  const {
    mutate: onApproveVehiclePendingStatus,
    isLoading: isApproveVehicleStatusLoading,
  } = useApproveVehiclePendingStatus();

  const onClickChangeStatus = () => {
    dispatch(
      setChangeVehicleStatusDialog({
        isOpen: true,
        fleetId: vehicle.fleetId,
        vehicleIds: [Number(vehicle.vehicleId)],
        vehicle,
      })
    );
  };

  const onClickChangeIssuingStatus = () => {
    dispatch(
      setChangeVehicleIssuingStatusDialog({
        isOpen: true,
        fleetId: vehicle.fleetId,
        vehicle,
      })
    );
  };

  // const onClickCorrectStatusDate = () => {
  //   dispatch(
  //     setCorrectStatusDateDialog({
  //       isOpen: true,
  //       vehicle,
  //     })
  //   );
  // };

  const onClickApproveStatus = () => {
    onApproveVehiclePendingStatus(
      {
        fleetId: vehicle.fleetId,
        vehicleId: vehicle.vehicleId,
      },
      {
        onSuccess: () => {
          dispatch(loadVehiclesActions.trigger({ isReset: true }));
          toast.success(t("bfm.success"));
        },
      }
    );
  };

  const onClickCancelStatus = () => {
    dispatch(
      setCancelVehicleStatusDialog({
        fleetId: vehicle.fleetId,
        vehicleId: vehicle.vehicleId,
        isOpen: true,
      })
    );
  };

  return (
    <RequiredVehicleContextButton
      fleetId={vehicle.fleetId}
      vehicle={vehicle}
      onClickChangeStatus={onClickChangeStatus}
      onClickChangeIssuingStatus={onClickChangeIssuingStatus}
      // onClickCorrectStatusDate={onClickCorrectStatusDate}
      onClickApproveStatus={onClickApproveStatus}
      onClickCancelStatus={onClickCancelStatus}
      isApproveVehicleStatusLoading={isApproveVehicleStatusLoading}
      isUpdateVehicleStatusLoading={isUpdateVehicleStatusLoading}
    />
  );
};

import React from "react";
import BookingDetails from "./BookingDetails/BookingDetails";
import { BookingSummary } from "./BookingSummary";

export const Legal = () => {
  return (
    <>
      <BookingSummary premiumType="LEGAL" />
      <BookingDetails premiumType="LEGAL" />
    </>
  );
};

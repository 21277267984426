import {
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  PrimaryButton,
} from "@fluentui/react";
import { toast } from "components/FluentToast";
import TextInputField from "components/controls/TextInputField";
import { Formik } from "formik";
import { useDeleteFleet } from "hooks/data/mutations/useDeleteFleet";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import { useQuery } from "hooks/useQuery";
import { delay } from "library/delay";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { setYupLocale } from "utils/setYupLocale";
import * as yup from "yup";
import { FormSpinner } from "../../components/FormSpinner";
import { loadFleetsActions, setDeleteDialog } from "../../store/fleetsPage";
import { useSelector } from "../../store/hooks";

export const DeleteDialog = () => {
  const theme = useTheme() as IStyledTheme;
  const deleteDialog = useSelector((s) => s.fleetsPage.deleteDialog);
  const { t } = useTranslation();
  const phraseActivated = usePhraseActivationStatus().activated;

  const dispatch = useDispatch();
  const query = useQuery();

  const { mutate: onDeleteFleet, isLoading: deleteInProgress } =
    useDeleteFleet();

  const close = () => {
    dispatch(
      setDeleteDialog({
        isOpen: false,
        fleet: null,
      })
    );
  };

  const onSubmit = (values) => {
    const comment = values.comment;
    const clientId = query.get("clientId");

    onDeleteFleet(
      {
        fleetId: deleteDialog.fleet.fleetId,
        body: { comment },
      },
      {
        onSuccess: () => {
          close();
          dispatch(loadFleetsActions.trigger({ clientId }));
          toast.success(t("bfm.success"));
        },
      }
    );
  };

  return (
    <Dialog
      hidden={!deleteDialog.isOpen}
      onDismiss={deleteInProgress ? () => {} : close}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
      dialogContentProps={{
        type: DialogType.normal,
        title: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              iconName="Warning"
              style={{
                color: theme.semanticColors.errorIcon,
                fontSize: "25px",
                marginRight: "10px",
              }}
            />
            {t("greco.delete")}
          </div>
        ),
      }}
    >
      <Formik
        initialValues={{ comment: "" }}
        enableReinitialize
        validationSchema={() => {
          setYupLocale(t);
          return yup.object().shape({
            comment: yup.string().max(512).required(),
          });
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setSubmitting }) => {
          onSubmit(values);
          await delay(400);
          setSubmitting(false);
        }}
      >
        {(props) => {
          const { values, handleChange, errors, handleSubmit } = props;

          return (
            <form onSubmit={handleSubmit}>
              <TextInputField
                value={values.comment}
                onChange={handleChange}
                errors={errors}
                required
                name={"comment"}
                multiline
                rows={4}
                disabled={false}
                label={t("bfm.comment.label")}
              />
              <DialogFooter>
                <PrimaryButton
                  text={t("greco.delete")}
                  type="submit"
                  iconProps={{
                    iconName: "Delete",
                  }}
                  disabled={deleteInProgress}
                />
              </DialogFooter>
              {deleteInProgress && <FormSpinner />}
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

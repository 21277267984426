import React from "react";

export const InfinityArrow = ({
  yScale,
  strokeColor,
  d,
  x2,
  domainValueFunc,
  deltaYForPolicyStatus = 0,
}) => {
  const arrowLineThickness = 3;
  const arrowLineSize = 5;
  const arrowLineRight = 15;

  return (
    <>
      <line
        className="raquo"
        stroke={strokeColor}
        strokeWidth={arrowLineThickness}
        x1={x2 - arrowLineRight}
        x2={x2 - arrowLineRight - arrowLineSize}
        y1={
          yScale(domainValueFunc(d)) +
          yScale.bandwidth() / 2 +
          deltaYForPolicyStatus
        }
        y2={
          yScale(domainValueFunc(d)) +
          (yScale.bandwidth() / 2 - arrowLineSize + deltaYForPolicyStatus)
        }
      />
      <line
        className="raquo"
        stroke={strokeColor}
        strokeWidth={arrowLineThickness}
        x1={x2 - arrowLineRight}
        x2={x2 - arrowLineRight - arrowLineSize}
        y1={
          yScale(domainValueFunc(d)) +
          yScale.bandwidth() / 2 +
          deltaYForPolicyStatus
        }
        y2={
          yScale(domainValueFunc(d)) +
          (yScale.bandwidth() / 2 + arrowLineSize + deltaYForPolicyStatus)
        }
      />
      <line
        className="raquo"
        stroke={strokeColor}
        strokeWidth={arrowLineThickness}
        x1={x2 - arrowLineRight + 1.2 * arrowLineSize}
        x2={x2 - arrowLineRight - arrowLineSize + 1.2 * arrowLineSize}
        y1={
          yScale(domainValueFunc(d)) +
          yScale.bandwidth() / 2 +
          deltaYForPolicyStatus
        }
        y2={
          yScale(domainValueFunc(d)) +
          (yScale.bandwidth() / 2 - arrowLineSize + deltaYForPolicyStatus)
        }
      />
      <line
        className="raquo"
        stroke={strokeColor}
        strokeWidth={arrowLineThickness}
        x1={x2 - arrowLineRight + 1.2 * arrowLineSize}
        x2={x2 - arrowLineRight - arrowLineSize + 1.2 * arrowLineSize}
        y1={
          yScale(domainValueFunc(d)) +
          yScale.bandwidth() / 2 +
          deltaYForPolicyStatus
        }
        y2={
          yScale(domainValueFunc(d)) +
          (yScale.bandwidth() / 2 + arrowLineSize + deltaYForPolicyStatus)
        }
      />
    </>
  );
};

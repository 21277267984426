import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { CorrectStatusDateDialog as CorrectStatusDateDialogGeneric } from "../../components/CorrectStatusDateDialog";
import {
  closeCorrectStatusDateDialog,
  loadVehiclesActions,
} from "../../store/requireActionVehicles";
import { useSelector } from "../../store/hooks";
import { useCorrectVehicleStatusDate } from "hooks/data/mutations/useCorrectVehicleStatusDate";
import { toast } from "components/FluentToast";
import { useTranslation } from "react-i18next";

export const CorrectStatusDateDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const correctStatusDateDialog = useSelector(
    (s) => s.requireActionVehicles.correctStatusDateDialog
  );

  const { mutate: onCorrectVehicleStatusDate, isLoading } =
    useCorrectVehicleStatusDate();

  const onClose = useCallback(() => {
    dispatch(closeCorrectStatusDateDialog());
  }, []);

  const handleCorrectVehicleStatusDate = useCallback((payload) => {
    onCorrectVehicleStatusDate(payload, {
      onSuccess: () => {
        loadVehiclesActions.trigger({ isReset: true });
        toast.success(t("bfm.success"));
        onClose();
      },
    });
  }, []);

  return (
    <CorrectStatusDateDialogGeneric
      correctStatusDateDialog={correctStatusDateDialog}
      correctStatusDateAction={handleCorrectVehicleStatusDate}
      isLoading={isLoading}
      close={onClose}
    />
  );
};

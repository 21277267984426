import { TooltipHost, mergeStyles } from "@fluentui/react";
import { mergeStyleSets } from "@uifabric/merge-styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ListContextMenuIconButton } from "../../../../components/styled";
import { neutrals } from "../../../../config/colors";
import { routes } from "../../../../config/routes";
import { useSelector } from "../../../../store/hooks";
import { PremiumType, UserRole, VehicleAzure } from "../../../../types/types";

type Props = {
  vehicle: VehicleAzure;
};

const classes = mergeStyleSets({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  emptyPlace: {
    width: "32px !important",
  },
});

const InsuranceLineIconsCell = ({ vehicle }: Props) => {
  const { t } = useTranslation();

  const userRole = useSelector((s) => s.auth.userRole);

  const clickRoute = (vehicle as any).isTEMP
    ? "editTempVehicle"
    : userRole === UserRole.adminreadonly
    ? "readOnlyVehicle"
    : "editVehicle";

  // if (vehicle?.licensePlate === "L67N6L9O89HB") {
  //   console.log(vehicle, "test test");
  // }

  const isHullBasic = vehicle?.hullIsBasic;
  const isfleetLegalClosingDate = vehicle?.fleetLegalIsClosingDate;

  return (
    <div className={classes.container}>
      {vehicle.horsePowerBasedTaxProRata !== null ? (
        <TooltipHost content={t(PremiumType.HORSE_POWER)}>
          <Link
            to={
              routes[clickRoute].getPath(vehicle.fleetId, vehicle.vehicleId) +
              `?tab=finance-vehicle-form`
            }
          >
            <ListContextMenuIconButton
              iconProps={{
                iconName: "Money",
              }}
              className={
                (vehicle.horsePowerBasedTaxAnnualy === null ||
                  vehicle.mtplAnnualGrossPremium === null) &&
                greyedOut
              }
            />
          </Link>
        </TooltipHost>
      ) : (
        <span className={classes.emptyPlace}></span>
      )}
      {vehicle.mtplProRataGrossPremium !== null ? (
        <TooltipHost content={t(PremiumType.MTPL)}>
          <Link
            to={
              routes[clickRoute].getPath(vehicle.fleetId, vehicle.vehicleId) +
              `?tab=finance-vehicle-form`
            }
          >
            <ListContextMenuIconButton
              iconProps={{
                iconName: "car",
              }}
              className={vehicle.mtplAnnualGrossPremium === null && greyedOut}
            />
          </Link>
        </TooltipHost>
      ) : (
        <span className={classes.emptyPlace}></span>
      )}
      {vehicle.hullProRataGrossPremium !== null ? (
        <TooltipHost
          content={
            isHullBasic ? t(PremiumType.HULL_BASIC) : t(PremiumType.HULL)
          }
        >
          <Link
            to={
              routes[clickRoute].getPath(vehicle.fleetId, vehicle.vehicleId) +
              `?tab=finance-vehicle-form`
            }
          >
            <ListContextMenuIconButton
              className={vehicle.hullAnnualGrossPremium === null && greyedOut}
              onRenderIcon={() => {
                return (
                  <i
                    className={
                      isHullBasic ? "las la-dharmachakra" : "las la-anchor"
                    }
                    style={{
                      fontSize: 20,
                    }}
                  ></i>
                );
              }}
            />
          </Link>
        </TooltipHost>
      ) : (
        <span className={classes.emptyPlace}></span>
      )}
      {vehicle.accidentProRataGrossPremium !== null ? (
        <TooltipHost content={t(PremiumType.ACCIDENT)}>
          <Link
            to={
              routes[clickRoute].getPath(vehicle.fleetId, vehicle.vehicleId) +
              `?tab=finance-vehicle-form`
            }
          >
            <ListContextMenuIconButton
              className={
                (vehicle.accidentAnnualGrossPremium === null ||
                  vehicle.mtplAnnualGrossPremium === null) &&
                greyedOut
              }
              iconProps={{
                iconName: "Warning",
              }}
            />
          </Link>
        </TooltipHost>
      ) : (
        <span className={classes.emptyPlace}></span>
      )}
      {vehicle.assistanceProRataGrossPremium !== null ? (
        <TooltipHost content={t(PremiumType.ASSISTANCE)}>
          <Link
            to={
              routes[clickRoute].getPath(vehicle.fleetId, vehicle.vehicleId) +
              `?tab=finance-vehicle-form`
            }
          >
            <ListContextMenuIconButton
              className={
                (vehicle.assistanceAnnualGrossPremium === null ||
                  vehicle.mtplAnnualGrossPremium === null) &&
                greyedOut
              }
              onRenderIcon={() => {
                return (
                  <i
                    className={"las la-hands-helping"}
                    style={{
                      fontSize: 20,
                    }}
                  ></i>
                );
              }}
            />
          </Link>
        </TooltipHost>
      ) : (
        <span className={classes.emptyPlace}></span>
      )}
      {vehicle.legalProRataGrossPremium !== null ? (
        <TooltipHost content={t(PremiumType.LEGAL)}>
          <Link
            to={
              routes[clickRoute].getPath(vehicle.fleetId, vehicle.vehicleId) +
              `?tab=finance-vehicle-form`
            }
          >
            <ListContextMenuIconButton
              className={
                (vehicle.legalAnnualGrossPremium === null ||
                  vehicle.mtplAnnualGrossPremium === null) &&
                greyedOut
              }
              onRenderIcon={() => {
                return (
                  <i
                    className={"la la-balance-scale"}
                    style={{
                      fontSize: 20,
                    }}
                  ></i>
                );
              }}
            />
          </Link>
        </TooltipHost>
      ) : (
        <span className={classes.emptyPlace}></span>
      )}
      {vehicle.fleetLegalProRataGrossPremium !== null ? (
        <TooltipHost
          content={
            isfleetLegalClosingDate
              ? t(PremiumType.FLEETLEGAL_CLOSINGDATE)
              : t(PremiumType.FLEETLEGAL_PRORATA)
          }
        >
          <Link
            to={
              routes[clickRoute].getPath(vehicle.fleetId, vehicle.vehicleId) +
              `?tab=finance-vehicle-form`
            }
          >
            <ListContextMenuIconButton
              className={
                vehicle.fleetLegalAnnualGrossPremium === null && greyedOut
              }
              onRenderIcon={() => {
                return (
                  <i
                    className={
                      isfleetLegalClosingDate
                        ? "las la-car-alt"
                        : "las la-car-side"
                    }
                    style={{
                      fontSize: 20,
                    }}
                  ></i>
                );
              }}
            />
          </Link>
        </TooltipHost>
      ) : (
        <span className={classes.emptyPlace}></span>
      )}
      {vehicle.hasIndemnityPremium === true ||
      vehicle.hadIndemnityPremium === true ? (
        <TooltipHost content={t("bfm.indemnity")}>
          <Link
            to={
              routes[clickRoute].getPath(vehicle.fleetId, vehicle.vehicleId) +
              `?tab=finance-vehicle-form`
            }
          >
            <ListContextMenuIconButton
              className={vehicle.hasIndemnityPremium !== true && greyedOut}
              iconProps={{
                iconName: "Suitcase",
              }}
            />
          </Link>
        </TooltipHost>
      ) : (
        <span className={classes.emptyPlace}></span>
      )}
      {vehicle.hasBonusMalusPremium === true ||
      vehicle.hadBonusMalusPremium === true ? (
        <TooltipHost content={t("bfm.bonusMalus")}>
          <Link
            to={
              routes[clickRoute].getPath(vehicle.fleetId, vehicle.vehicleId) +
              `?tab=finance-vehicle-form`
            }
          >
            <ListContextMenuIconButton
              className={vehicle.hasBonusMalusPremium !== true && greyedOut}
              iconProps={{
                iconName: "Calculator",
              }}
            />
          </Link>
        </TooltipHost>
      ) : (
        <span className={classes.emptyPlace}></span>
      )}
    </div>
  );
};

export default React.memo(InsuranceLineIconsCell);

const greyedOut = mergeStyles({
  color: neutrals.neutralSecondary,
  "&&&:hover": {
    color: neutrals.neutralSecondary,
    borderColor: neutrals.neutralSecondary,
  },
});

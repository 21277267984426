import { CommandBarButton } from "@fluentui/react";
import { IContextualMenuProps } from "@fluentui/react/lib/ContextualMenu";
import { isExternalOrReadonlyAdmin } from "form/util";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setDialog } from "store/fleetForm";

import { useParams } from "react-router-dom";
import { setProrataReportDialog } from "store/fleetPage";
import { useSelector } from "store/hooks";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { EActionDialog } from "../FleetForm/constants";

function FleetReportsContextMenu({ fleetAppSettings }: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme() as IStyledTheme;
  const userRole = useSelector((s) => s.auth.userRole);
  const { fleetId } = useParams() as any;

  const loadFleetLoadStatus = useSelector(
    (s) => s.fleetForm.loadFleetLoadStatus
  );

  const isDisabledLoading = loadFleetLoadStatus === "loading";

  const isDisabled = isDisabledLoading;

  const isExternalOrReadOnlyAdmin = isExternalOrReadonlyAdmin(userRole);

  const items = React.useMemo(() => {
    let items = [];
    if (fleetAppSettings?.SHOW_REPORTS_PRORATA_REPORT) {
      items.push({
        key: "prorataReport",
        disabled: isDisabledLoading,
        text: t("bfm.prorataReport.label"),
        iconProps: {
          iconName: "ReportLock",
        },

        onClick: () => {
          dispatch(
            setProrataReportDialog({
              isOpen: true,
              vehicleIds: [],
              fleetId: "" + fleetId,
            })
          );
        },
      });
    }
    if (fleetAppSettings?.SHOW_REPORTS_COMPARISON_REPORT) {
      items.push({
        key: "generateComparisonReport",
        disabled: isDisabledLoading,
        text: t("bfm.fleet.action.generateComparisonReport"),
        iconProps: {
          iconName: "Compare",
        },
        onClick: () => {
          dispatch(
            setDialog({
              type: EActionDialog.GenerateComparisonReport,
              isOpen: true,
              title: t("bfm.fleet.action.generateComparisonReport"),
              icon: "Compare",
            })
          );
        },
      });
    }
    if (fleetAppSettings?.SHOW_REPORTS_COVERSHEET_REPORT) {
      items.push({
        key: "generateCoversheetReport",
        disabled: isDisabledLoading || isExternalOrReadOnlyAdmin,
        text: t("bfm.fleet.action.generateCoversheetReport"),
        iconProps: {
          iconName: "ExcelLogo",
        },
        onClick: () => {
          dispatch(
            setDialog({
              type: EActionDialog.GenerateCoversheetReport,
              isOpen: true,
              title: t("bfm.fleet.action.generateCoversheetReport"),
              icon: "ExcelLogo",
            })
          );
        },
      });
    }

    if (fleetAppSettings?.SHOW_REPORTS_FLEETLEGAL_REPORT) {
      items.push({
        key: "generateFleetLegalReport",
        disabled: isDisabledLoading || isExternalOrReadOnlyAdmin,
        text: t("bfm.fleetLegalApplicationReport.label"),
        iconProps: {},
        onRenderIcon: (props) => {
          return (
            <i
              className={"las la-car-alt"}
              style={{
                fontSize: "20px",
                width: "16px",
                margin: "0px 4px",
                color:
                  isDisabledLoading || isExternalOrReadOnlyAdmin
                    ? theme.palette.neutralSecondaryAlt
                    : theme.palette.themePrimary,
              }}
            ></i>
          );
        },
        onClick: () => {
          dispatch(
            setDialog({
              type: EActionDialog.GenerateFleetLegalReport,
              isOpen: true,
              title: t("bfm.fleetLegalApplicationReport.label"),
              icon: "ExcelLogo",
            })
          );
        },
      });
    }

    if (fleetAppSettings?.SHOW_REPORTS_EXPORTFLEET_REPORT) {
      items.push({
        key: "generateExportFleet",
        disabled: isDisabledLoading || isExternalOrReadOnlyAdmin,
        text: t("bfm.exportFleet.label"),
        iconProps: {
          iconName: "ExcelLogo",
        },
        onClick: () => {
          dispatch(
            setDialog({
              type: EActionDialog.ExportFleet,
              isOpen: true,
              title: t("bfm.exportFleet.label"),
              icon: "Export",
            })
          );
        },
      });
    }
    return items;
  }, [
    fleetAppSettings,
    fleetId,
    isDisabledLoading,
    t,
    theme.palette.neutralSecondaryAlt,
    theme.palette.themePrimary,
    isExternalOrReadOnlyAdmin,
  ]);

  const menuProps = React.useMemo<IContextualMenuProps>(() => {
    return {
      shouldFocusOnMount: true,
      items: items,
    };
  }, [items]);

  return (
    <>
      {fleetAppSettings?.SHOW_REPORTS_BUTTON ? (
        <CommandBarButton
          iconProps={{
            iconName: "ReportDocument",
          }}
          disabled={isDisabled}
          text={t("bfm.reports.label")}
          menuProps={menuProps}
          styles={{
            root: {
              whiteSpace: "nowrap",
              minHeight: 44,
            },
            flexContainer: {
              alignItems: "normal",
            },
          }}
        />
      ) : null}
    </>
  );
}

export default FleetReportsContextMenu;

import { IFieldConfig } from "../../form/types";

export const modifyLicensePlateDialogConfig: IFieldConfig = {
  licensePlate: {
    name: "licensePlate",
    type: "text",
    isRequired: true,
    d: ({ t }) => ({
      label: "bfm.vehicleLicensePlate.label",
    }),
  },
  validFromDate: {
    name: "validFromDate",
    type: "date",
    isRequired: true,
    d: ({ t, values, errors, setFieldValue }) => ({
      label: "bfm.vehicleForm.validFromDate.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.validFromDate,
    }),
  },
  validToDate: {
    name: "validToDate",
    type: "date",
    isRequired: true,
    d: ({ t, values, errors, setFieldValue }) => ({
      label: "bfm.vehicleForm.validToDate.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.validToDate,
    }),
  },
  comment: {
    name: "comment",
    type: "text",
    isMultiline: true,
    rows: 6,
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.comment.label",
    }),
  },
};

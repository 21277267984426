import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  resetDialogInsurerSettings,
  setDialogInsurerSettingsOperationResult,
} from "store/fleetForm";
import { calculateAnnualNetPremium } from "utils/utils";
import { toast } from "../../../../components/FluentToast";
import { useSelector } from "../../../../store/hooks";

export const useInsurerSettings = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const insurerSettings = useSelector((s) => s.fleetForm.insurerSettings);

  const formValues = useSelector(
    (s) => s.fleetForm.insurerSettingsOperation.formValues
  );
  const premiumType = formValues?.premiumType;
  const taxonomy = useSelector((s) => s.taxonomy);
  const premiumTypeCode = premiumType ? premiumType.code : null;
  const defaultValueOperation = useSelector(
    (s) => s.fleetForm.insurerSettingsOperation
  );
  useEffect(() => {
    if (
      defaultValueOperation.fieldNames &&
      defaultValueOperation.formValues &&
      !defaultValueOperation.results
    ) {
      const fieldNames = defaultValueOperation.fieldNames;
      const formValues = defaultValueOperation.formValues;

      let insurer = formValues.insurerPartnerNumber?.value;

      const insurerSettingsForInsurer = insurerSettings?.filter(
        (is) => is.insurerPartnerNumber === insurer
      );
      let insurerSetting = null;
      if (insurerSettingsForInsurer.length === 1) {
        insurerSetting = insurerSettingsForInsurer?.find((is) => {
          const a = moment(
            moment(is.validFromDate).format("YYYY-MM-DD")
          ).toDate();
          const b = moment(
            moment(formValues.validFromDate).format("YYYY-MM-DD")
          ).toDate();
          return a <= b;
        });
      } else if (insurerSettingsForInsurer.length > 1) {
        // Find latest insurer setting with valid from date before dialog date. Sording the array by validFromDate descending
        insurerSetting = insurerSettingsForInsurer
          ?.sort((a, b) => {
            return (
              moment(b.validFromDate).toDate().getTime() -
              moment(a.validFromDate).toDate().getTime()
            );
          })
          ?.find((is) => {
            const a = moment(
              moment(is.validFromDate).format("YYYY-MM-DD")
            ).toDate();
            const b = moment(
              moment(formValues.validFromDate).format("YYYY-MM-DD")
            ).toDate();
            return a <= b;
          });
      }

      if (insurerSetting) {
        let valueString = null;

        let results = {};
        for (const fieldName of fieldNames) {
          switch (fieldName) {
            case "suspensionDays":
              valueString = insurerSetting.suspensionDays + "";
              results[fieldName] = valueString;
              //dispatch(setDialogInsurerSettingsOperationResult(valueString));
              break;
            case "activationDays":
              valueString = insurerSetting.activationDays + "";
              results[fieldName] = valueString;
              // dispatch(setDialogInsurerSettingsOperationResult(valueString));
              break;
            case "garageRiskDiscountPercent":
              if (premiumTypeCode === "HULL_BASIC") {
                valueString = insurerSetting.garageRiskDiscountPercentHullBasic;
              } else {
                valueString = insurerSetting.garageRiskDiscountPercentHull;
              }
              valueString = valueString.toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              valueString = valueString + "";
              results[fieldName] = valueString;
              break;

            case "annualNetPremium":
              if (
                insurerSetting.insurerSettingPerPremiumTypeDetailsHU &&
                insurerSetting.insurerSettingPerPremiumTypeDetailsHU.length > 0
              ) {
                const setting3 =
                  insurerSetting.insurerSettingPerPremiumTypeDetailsHU.find(
                    (el) => {
                      const ptCode = "PremiumType." + premiumType.code;
                      const ptId = taxonomy.PremiumType.byCode[ptCode].id;
                      return el.premiumTypeCode === ptId;
                    }
                  );

                const annualGrossPremium = parseFloat(
                  (formValues.annualGrossPremium as string)
                    ?.replaceAll(" ", "")
                    ?.replaceAll(".", "")
                    .replaceAll(",", ".")
                );
                const maxValueForTaxCalculation =
                  setting3?.maxValueForTaxCalculation;
                const taxPercent = setting3?.taxPercent;
                const taxExtraPercent1 = setting3?.taxExtraPercent1;
                let calculatedPremium = calculateAnnualNetPremium(
                  premiumTypeCode,
                  taxPercent,
                  taxExtraPercent1,
                  annualGrossPremium,
                  maxValueForTaxCalculation
                );

                //   annualGrossPremium > maxValueForTaxCalculation
                //     ? maxValueForTaxCalculation
                //     : annualGrossPremium;
                // calculatedPremium = calculatedPremium * (1 + taxPercent / 100);
                // calculatedPremium =
                //   calculatedPremium * (1 + taxExtraPercent1 / 100);
                // calculatedPremium =
                //   calculatedPremium * (1 + taxExtraPercent2 / 100);

                const premiumString = calculatedPremium.toLocaleString(
                  "de-DE",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                );
                results["annualNetPremium"] = premiumString;
              }
              break;
          }
        }
        dispatch(setDialogInsurerSettingsOperationResult(results));
        !defaultValueOperation?.noMessage &&
          toast.success(t("bfm.fillInInsurerSettingsSuccess.label"));
      } else {
        !defaultValueOperation?.noMessage &&
          toast.info(t("bfm.fillInInsurerSettingsNoValue.label"));
        dispatch(resetDialogInsurerSettings(null));
      }
    }
  }, [defaultValueOperation, insurerSettings, premiumTypeCode]);
};

import { ExcelExport } from "@progress/kendo-react-excel-export";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Country_ALL } from "types/types";

import {
  selectAllRavPageColumns,
  selectColumnsTable,
} from "store/requireActionVehiclesColumnOptions";
import { ExportExcelVehicles } from "../../components/ExportExcelVehicles";
import { useSelector } from "../../store/hooks";
import {
  loadRavFleetsActions,
  loadVehiclesActions,
  requireActionVehiclesSlice,
  selectAllVehicles,
  selectDefaultColumns,
  setSelectedViewIdRav,
} from "../../store/requireActionVehicles";
import { AppDispatch } from "../../store/store";
import { CancelStatusDialog } from "./CancelStatusDialog";
import { ChangeVehicleStatusDialog } from "./ChangeStatusDialog";
import { CorrectStatusDateDialog } from "./CorrectStatusDateDialog";
import RequireActionVehicleListTable from "./RequireActionVehicleListTable/RequireActionVehicleListTable";
import { RequiredColumnOptions } from "./RequiredColumnOptions";
// import { defaultColumnsByKey } from "./VehicleListTable/columns";
import AppSettings from "AppSettings";
import moment from "moment";
import { defaultColumnsByKey } from "pages/fleet/VehicleListTable/columns";
import { setFleetCountryOptions } from "store/filter";
import { setRavPageColumns } from "store/requireActionVehiclesColumnOptions";
import { selectColumnOptionsSettings } from "store/settings";
import { ExpandedColumn } from "types/columns";
import { hydrateColumn } from "utils/columns";
import { sanitizeFileName } from "utils/utils";
import { Navigation } from "./navigation/Navigation";
import { ravDefaultColumns } from "./RequireActionVehicleListTable/columns";

const RequireActionVehicles = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const vehicles = useSelector(selectAllVehicles);

  const detailsListColumnsRedux = useSelector(selectColumnsTable)(t);
  const refExcelExport = useRef<ExcelExport>();
  const isChangeStatusDialogOpen = useSelector(
    (s) => s.requireActionVehicles.changeVehicleStatusDialog.isOpen
  );

  const isCancelStatusDialogOpen = useSelector(
    (s) => s.requireActionVehicles.cancelVehicleStatusDialog.isOpen
  );

  const taxonomy = useSelector((s) => s.taxonomy);
  const isCorrectStatusDateDialogOpen = useSelector(
    (s) => s.requireActionVehicles.correctStatusDateDialog.isOpen
  );
  const fleetCountryOptions = useSelector((s) => s.filter.fleetCountryOptions);
  const isColumnOptionsPanelOpen = useSelector(
    (s) => s.requireActionVehicles.isColumnOptionsPanelOpen
  );
  const columns = useSelector(selectAllRavPageColumns);

  const userCountryCode = useSelector((s) => s.auth.userCountryCode);

  const columnsCountryMap = useSelector(
    (s) => s.requireActionVehiclesColumnOptions.columnsCountryMap
  );
  const ravPageColumnOptionsColumns = useSelector(
    (s) => s.requireActionVehiclesColumnOptions.columns
  );
  const defaultColumns = useSelector(selectDefaultColumns) as any;
  const country = useSelector(
    (s) => s.requireActionVehiclesColumnOptions.country
  );

  const selectedCountryFilter = useSelector(
    (s) => s.filter.selectedCountryFilter
  );
  const columnOptionsSettings = useSelector(selectColumnOptionsSettings);

  const fleets = useSelector((s) => s.requireActionVehicles.fleets);

  useEffect(() => {
    if (!fleets) {
      dispatch(setFleetCountryOptions, []);
      return;
    }

    let retVal = Array.from(
      new Set(Object.values(fleets).map((item) => item.countryCode))
    ).map((countryCode) => {
      return {
        text: t(taxonomy.Country.byId[countryCode].code),
        key: countryCode + "",
        code: taxonomy.Country.byId[countryCode].code,
      };
    });
    if (retVal.length === 1) {
      dispatch(setFleetCountryOptions(retVal));
      return;
    }
    dispatch(
      setFleetCountryOptions([
        {
          text: t("VehicleStatusCode.ALL"),
          key: Country_ALL + "",
          code: "ALL",
        },
        ...retVal,
      ])
    );
  }, [fleets, taxonomy, t]);

  const fileName = useMemo(() => {
    return (
      sanitizeFileName("Vehicles_List") +
      moment(new Date()).format("_YYYY_MM_DD_HH_mm_ss")
    );
  }, []);

  useEffect(() => {
    return () => {
      dispatch(requireActionVehiclesSlice.actions.resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!selectedCountryFilter) return;
    let selectedCountryOption = fleetCountryOptions.find(
      (item) => item.key === selectedCountryFilter
    );
    if (!selectedCountryOption && selectedCountryFilter === "0") {
      selectedCountryOption = {
        text: t("VehicleStatusCode.ALL"),
        key: Country_ALL + "",
        code: "ALL",
      };
    }

    if (!columnsCountryMap[selectedCountryOption.code]) {
      // If selected country was never selected before, set default columns
      const defaultColumnOption = columnOptionsSettings.find(
        (item) => item.isDefault
      );
      let fleetAppSettings =
        AppSettings.getInstance().getAppSettingsTypes()[
          selectedCountryOption.code
        ];
      if (!fleetAppSettings) {
        // If there is no app settings for selected country, use default country (AT)
        fleetAppSettings = {
          ...AppSettings.getInstance().getAppSettingsTypes()["Country.AT"],
        };
        if (selectedCountryOption.key !== "0") {
          // merge fleetAppSettings DEFAULT_COLUMNS for all countries from fleetCountryOptions and update fleetAppSettings
          fleetCountryOptions.forEach((countryOption) => {
            const appSettings =
              AppSettings.getInstance().getAppSettingsTypes()[
                countryOption.code
              ];
            if (appSettings) {
              fleetAppSettings.DEFAULT_COLUMNS = [
                ...new Set([
                  ...fleetAppSettings.DEFAULT_COLUMNS,
                  ...appSettings.DEFAULT_COLUMNS,
                ]),
              ];
            }
          });
        }
      }
      if (defaultColumnOption) {
        // If there is default column option set, use it's columns
        dispatch(setSelectedViewIdRav(defaultColumnOption.userAppSettingId));
        let defaultViewColumns = JSON.parse(
          defaultColumnOption.userAppSettingValue
        ) as ExpandedColumn[];

        let finalColumns: ExpandedColumn[] = defaultViewColumns.filter((el) =>
          fleetAppSettings?.DEFAULT_COLUMNS.includes(el.key)
        );
        finalColumns = finalColumns.map((c) => {
          if (c.key === "hullProduct") {
            return {
              ...c,
              filter: defaultColumnsByKey[c.key].filter,
            };
          } else {
            return c;
          }
        });

        fleetAppSettings?.DEFAULT_COLUMNS.map((defaultColumn) => {
          const columnFromView = defaultViewColumns.find(
            (c) => c.key === defaultColumn
          );
          if (!columnFromView) {
            //   finalColumns.push(columnFromView);
            // } else {
            finalColumns.push(defaultColumnsByKey[defaultColumn]);
          }
        });

        const mappedColumns = finalColumns?.reduce((arr, c) => {
          const hydratedColumn = hydrateColumn({
            column: c,
            defaultColumns: ravDefaultColumns,
          });
          if (!hydratedColumn) return arr;
          arr.push(hydratedColumn);
          return arr;
        }, []);

        dispatch(
          setRavPageColumns({
            columns: mappedColumns,
            country: fleetAppSettings.COUNTRY_CODE,
          })
        );
      } else {
        dispatch(
          setRavPageColumns({
            columns: fleetAppSettings.DEFAULT_COLUMNS.map(
              (column) => defaultColumnsByKey[column]
            ),
            country: selectedCountryOption.code,
          })
        );
      }
    } else {
      // If selected country was selected before, load its columns
      dispatch(
        setRavPageColumns({
          columns: columnsCountryMap[selectedCountryOption.code],
          country: selectedCountryOption.code,
        })
      );
    }

    // if (selectedCountryOption) {
    //   dispatch(setFleetCountryOptions([selectedCountryOption]));
    // }

    // if (
    //   !columnsCountryMap[fleetAppSettings.COUNTRY_CODE] ||
    //   fleetAppSettings.COUNTRY_CODE !== country
    // ) {
    //   const defaultColumnOption = columnOptionsSettings.find(
    //     (item) => item.isDefault
    //   );
    //   if (defaultColumnOption) {
    //     dispatch(setSelectedViewIdRav(defaultColumnOption.userAppSettingId));

    //     const columns = JSON.parse(
    //       defaultColumnOption.userAppSettingValue
    //     ) as ExpandedColumn[];
    //     const mappedColumns = columns?.reduce((arr, c) => {
    //       const hydratedColumn = hydrateColumn({
    //         column: c,
    //         defaultColumns: defaultColumns,
    //       });
    //       if (!hydratedColumn) return arr;
    //       arr.push(hydratedColumn);
    //       return arr;
    //     }, []);

    //     dispatch(
    //       setFleetPageColumns({
    //         columns: mappedColumns,
    //         country: fleetAppSettings.COUNTRY_CODE,
    //       })
    //     );
    //   } else {
    //     dispatch(
    //       setFleetPageColumns({
    //         columns: fleetAppSettings.DEFAULT_COLUMNS.map(
    //           (column) => defaultColumnsByKey[column]
    //         ),
    //         country: fleetAppSettings.COUNTRY_CODE,
    //       })
    //     );
    //   }
    //}
  }, [selectedCountryFilter]);

  useEffect(() => {
    if (!columns) return;
    dispatch(loadRavFleetsActions.trigger());
  }, []);

  useEffect(() => {
    if (!columns) return;
    dispatch(
      loadVehiclesActions.trigger({
        isReset: true,
        shouldDelay: false,
      })
    );
  }, [columns, selectedCountryFilter]);

  // useEffect(() => {
  //   if (!selectedCountryId) {
  //   } else {
  //     let newAppSettings =
  //       AppSettings.getInstance().getAppSettingsTypes()[
  //         taxonomy.Country.byId[Number(selectedCountryId)].code + ""
  //       ];
  //     if (!newAppSettings) {
  //       newAppSettings =
  //         AppSettings.getInstance().getAppSettingsTypes()["Country.AT"];
  //     }
  //     setFleetAppSettings(newAppSettings);
  //   }
  // }, [selectedCountryId, taxonomy]);

  // useEffect(() => {
  //   if (
  //     !ravPageColumnOptionsColumns ||
  //     ravPageColumnOptionsColumns.ids.length === 0
  //   )
  //     return;
  //   const newColumns = ravPageColumnOptionsColumns.ids.map(
  //     (id) => ravPageColumnOptionsColumns.entities[id]
  //   );
  //   dispatch(setColumns(newColumns));
  // }, [fleetAppSettings?.COUNTRY_CODE, country]);

  // useEffect(() => {
  //   if (!fleetAppSettings) return;

  //   dispatch(
  //     setRavPageColumns({
  //       columns: fleetAppSettings.DEFAULT_COLUMNS.map(
  //         (column) => defaultColumnsByKey[column]
  //       ),
  //       country: fleetAppSettings.COUNTRY_CODE,
  //     })
  //   );
  //   if (columns === null) {
  //     dispatch(
  //       setColumns(
  //         fleetAppSettings.DEFAULT_COLUMNS.map(
  //           (column) => defaultColumnsByKey[column]
  //         )
  //       )
  //     );
  //   } else {
  //     dispatch(loadRavFleetsActions.trigger());
  //   }
  // }, [fleetAppSettings, columns, country]);

  const detailsListColumns = useMemo(() => {
    const hasFleetName = detailsListColumnsRedux?.some(
      (item) => item.key === "fleetName"
    );
    if (!hasFleetName) {
      detailsListColumnsRedux?.splice(2, 0, {
        key: "fleetName",
        isResizable: true,
        fieldName: "fleetName",
        labelKey: "bfm.fleetName",
        name: t("bfm.fleetName"),
        minWidth: 150,
        maxWidth: 150,
        isVisible: true,
        isFixed: true,
      });
    }

    return detailsListColumnsRedux
      ?.map((column) => {
        if (column?.key === "insuranceLineIcons") {
          return {
            ...column,
            minWidth: 320,
            maxWidth: 400,
          };
        }
        return column;
      })
      .concat([
        {
          key: "filler",
          label: "",
          width: 0,
        },
      ]);
  }, [detailsListColumnsRedux, t]);

  return (
    <>
      <Navigation
        refExcelExport={refExcelExport?.current}
        fleetCountryOptions={fleetCountryOptions}
      />
      <RequireActionVehicleListTable
        items={vehicles}
        columns={detailsListColumns}
      />
      <ExportExcelVehicles
        ref={refExcelExport}
        columns={detailsListColumns?.filter((col) => col.key !== "filler")}
        fileName={fileName}
      />
      {isColumnOptionsPanelOpen && (
        <RequiredColumnOptions
          countryCode={userCountryCode ? Number(userCountryCode) : Country_ALL}
        />
      )}
      {isChangeStatusDialogOpen && <ChangeVehicleStatusDialog />}
      {isCancelStatusDialogOpen && <CancelStatusDialog />}
      {isCorrectStatusDateDialogOpen && <CorrectStatusDateDialog />}
    </>
  );
};

export default RequireActionVehicles;

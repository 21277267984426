import { useMutation } from "react-query";
import {
  SynchronizeFleetClientRequestParams,
  synchronizeFleetClient,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

export const syncClient = async (
  params: SynchronizeFleetClientRequestParams
) => {
  return await synchronizeFleetClient(params);
};

export function useSyncClient() {
  return useMutation(syncClient, {
    mutationKey: MUTATION_KEYS.synchronizeFleetClient,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

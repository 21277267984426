import { useQuery } from "react-query";
import { getFleetDefaultSettings } from "store/api/api";
import { QUERY_KEYS } from "../queryKeys";

const getFleetVehicleDefaultSettings = async (fleetId: string | number) => {
  const response = await getFleetDefaultSettings(fleetId);
  return response?.data;
};

export function useGetFleetVehicleDefaultSettings(fleetId: string | number) {
  return useQuery(
    [QUERY_KEYS.fleetVehicleDefaultSettings, fleetId],
    () => getFleetVehicleDefaultSettings(fleetId),
    {
      enabled: !!fleetId,
    }
  );
}

import {
  mergeStyleSets,
  TooltipHost,
  TooltipOverflowMode,
} from "@fluentui/react";
import React from "react";

const classes = mergeStyleSets({
  fleetName: {
    cursor: "default",
    fontWeight: "bold",
  },
});

const Address = ({ insurer }) => {
  return (
    <TooltipHost
      content={insurer.insurerName}
      overflowMode={TooltipOverflowMode.Parent}
    >
      <span className={classes.fleetName}>
        {insurer.street}, {insurer.zipCode} {insurer.city}
      </span>
    </TooltipHost>
  );
};

export default Address;

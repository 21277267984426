import { useMutation } from "react-query";
import { changeVehicleIssuingStatus } from "store/api/api";
import { handleAxiosError } from "store/util";
import { IUpdateVehicleIssuingStatus } from "types/types";
import { MUTATION_KEYS } from "../queryKeys";

const changeVehicleIssuingStatusFn = async (
  params: IUpdateVehicleIssuingStatus
) => {
  return await changeVehicleIssuingStatus(params);
};

export function useUpdateVehicleIssuingStatus() {
  return useMutation(changeVehicleIssuingStatusFn, {
    mutationKey: MUTATION_KEYS.changeVehicleIssuingStatus,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { Dialog } from "@fluentui/react";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React from "react";
import { IFleetInsurerDefaultSetting } from "types/types";
import { EDialogType } from "../constats";
import InsurerSettingForm from "./InsurerSettingForm";

type Props = {
  settingDialog: {
    actionType: EDialogType | string;
    title: string;
    settings: IFleetInsurerDefaultSetting;
  };
  onClose: () => void;
  isOpen: boolean;
};

function InsurerSettingDialog({ isOpen, settingDialog, onClose }: Props) {
  const phraseActivated = usePhraseActivationStatus().activated;

  return (
    <Dialog
      minWidth={800}
      hidden={!isOpen}
      onDismiss={onClose}
      styles={{
        root: {
          overflow: "visible",
          minWidth: "500px",
          "& .ms-Dialog-inner, .ms-Dialog-title": {
            paddingBottom: "10px",
          },
        },
      }}
      css={{
        overflow: "visible",
      }}
      dialogContentProps={{
        title: settingDialog.title,
      }}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <InsurerSettingForm
        onClose={onClose}
        actionType={settingDialog.actionType}
        defaultSettings={settingDialog.settings}
      />
    </Dialog>
  );
}

export default InsurerSettingDialog;

import { useQuery, UseQueryResult } from "react-query";

import axios from "axios";
import { QUERY_KEYS } from "../queryKeys";
import { urlFormat } from "utils/utils";
import { IUadTaxonomy } from "types/types";

const uadUrl = urlFormat(process.env.REACT_APP_BFF_HOST_UAD);

const getUADTaxonomy = async () => {
  const response = await axios.get(`${uadUrl}/taxonomy`);

  return response.data;
};

export function useLoadUADTaxonomy(): UseQueryResult<IUadTaxonomy> {
  return useQuery([QUERY_KEYS.uadTaxonomy], getUADTaxonomy, {
    enabled: true,
  });
}

import Tippy from "@tippyjs/react";
import { timeFormat } from "d3";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import "tippy.js/dist/tippy.css";
import { useSelector } from "../../../../../store/hooks";
import {
  ETimelineExtraDataLineSpecial,
  PremiumType,
  TimelineSegmentType,
} from "../../../../../types/types";
import { formatNumber } from "../../../../../utils/number";

const tickFormat = timeFormat("%d.%m.%Y");

export const PremiumSegmentTooltip = ({ children, d }) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const appSettings = useSelector((s) => s.timeline.timelineAppSettings);

  let period = (
    <>
      {tickFormat(d.startDate)} - {tickFormat(d.endDate)}
      <br />
    </>
  );
  if (d.segmentType === TimelineSegmentType.VOID_BEFORE) {
    period = null; // <>Before {tickFormat(d.start)}<br /></>;
  }
  if (d.segmentType === TimelineSegmentType.VOID_AFTER) {
    period = null; //<>After {tickFormat(d.start)}<br /></>;
  }

  let extraInfo = <>No Insurance</>;
  if (d.extraData?.insurer !== null && d.associatedObject !== null)
    extraInfo = (
      <>
        {t("bfm.insurer.label")}: {d.extraData.insurer}
        {" ("}
        {d.extraData?.insurerInternalNumber}
        {") "}
        {d.associatedObject?.insurerBranchOffice !== null ? (
          <>
            <br />
            {t("bfm.vehicleForm.insurerBranchOffice.label") +
              ": " +
              d.extraData?.branchOffice}
            {" ("}
            {d.extraData?.branchOfficeInternalNumber}
            {") "}
          </>
        ) : (
          ""
        )}
        <br />
        {d.domain.value === PremiumType.HORSE_POWER ? (
          <>
            {d.associatedObject?.excludeHorsePowerBasedTaxReason ? (
              <>
                {t("bfm.reasonForMotorTaxExclusion")}:{" "}
                {t(
                  taxonomy.ReasonForMotorTaxExclusion.byId[
                    d.associatedObject?.excludeHorsePowerBasedTaxReason
                  ]?.code
                )}
                <br />
              </>
            ) : null}
          </>
        ) : null}
        {t("bfm.policyNumber")}: {d.associatedObject?.insurerPolicyNumber}{" "}
        {d.associatedObject?.insurerGroupPolicyNumber !== null ? (
          <>
            <br />
            {t("bfm.groupPolicyNumber.label")}:{" "}
            {d.associatedObject?.insurerGroupPolicyNumber}{" "}
          </>
        ) : (
          ""
        )}
        <br />
        {t("bfm.isPolicyIssued")}:{" "}
        {d.associatedObject?.isPolicyIssued ? t("greco.yes") : t("greco.no")}{" "}
        {d.associatedObject?.documentDate !== null ? (
          <>
            <br />
            {t("bfm.vehicleForm.documentDate.label") +
              ": " +
              tickFormat(moment(d.associatedObject?.documentDate).toDate())}
          </>
        ) : (
          ""
        )}
        {d.associatedObject?.cancelationDocumentDate !== null ? (
          <>
            <br />
            {t("bfm.vehicleForm.cancelationDocumentDate.label") +
              ": " +
              tickFormat(
                moment(d.associatedObject?.cancelationDocumentDate).toDate()
              )}
          </>
        ) : null}
        <br />
        {t("bfm.prorata")}:{" "}
        {formatNumber(d.associatedObject?.totalProRataPremium)}{" "}
        {appSettings?.CURRENCY_SYMBOL}
        <br />
        {d.associatedObject?.indemnityPremium !== 0 ||
        d.associatedObject?.bonusMalusPremium !== 0 ||
        d.associatedObject?.isSuspensionDaysPremium ||
        d.associatedObject?.activationDaysPremium ||
        d.associatedObject?.isCancelationPremium ||
        d.associatedObject?.isGarageRiskPremium ? (
          <span
            style={{
              paddingLeft: "20px",
            }}
          >
            {t("bfm.premium")}:{" "}
            {formatNumber(d.associatedObject?.proRataPremium)}{" "}
            {appSettings?.CURRENCY_SYMBOL}
            <br />
          </span>
        ) : null}
        {d.associatedObject?.indemnityPremium !== 0 ? (
          <span
            style={{
              paddingLeft: "20px",
            }}
          >
            {t("bfm.indemnity")}:{" "}
            {formatNumber(d.associatedObject?.indemnityPremium)}{" "}
            {appSettings?.CURRENCY_SYMBOL}
            <br />
          </span>
        ) : null}
        {d.associatedObject?.bonusMalusPremium !== 0 ? (
          <span
            style={{
              paddingLeft: "20px",
            }}
          >
            {t("bfm.bonusMalus")}:{" "}
            {formatNumber(d.associatedObject?.bonusMalusPremium)}{" "}
            {appSettings?.CURRENCY_SYMBOL}
            <br />
          </span>
        ) : null}
        {d.associatedObject?.isSuspensionDaysPremium ? (
          <span
            style={{
              paddingLeft: "20px",
            }}
          >
            {t("bfm.suspension")}:{" "}
            {formatNumber(d.associatedObject?.suspensionDaysPremium)}{" "}
            {appSettings?.CURRENCY_SYMBOL}
            <br />
          </span>
        ) : null}
        {d.associatedObject?.isCancelationPremium ? (
          <span
            style={{
              paddingLeft: "20px",
            }}
          >
            {t("bfm.cancelation")}:{" "}
            {formatNumber(d.associatedObject?.cancelationPremium)}{" "}
            {appSettings?.CURRENCY_SYMBOL}
            <br />
          </span>
        ) : null}
        {d.associatedObject?.isActivationDaysPremium ? (
          <span
            style={{
              paddingLeft: "20px",
            }}
          >
            {t("bfm.activation")}:{" "}
            {formatNumber(d.associatedObject?.activationDaysPremium)}{" "}
            {appSettings?.CURRENCY_SYMBOL}
            <br />
          </span>
        ) : null}
        {d.associatedObject?.isGarageRiskPremium ? (
          <span
            style={{
              paddingLeft: "20px",
            }}
          >
            {t("bfm.garageRisk")}:{" "}
            {formatNumber(d.associatedObject?.garageRiskPremium)}{" "}
            {appSettings?.CURRENCY_SYMBOL}
            <br />
          </span>
        ) : null}
        {t("bfm.annualGrossPremium")}:{" "}
        {formatNumber(d.associatedObject?.annualGrossPremium)}{" "}
        {appSettings?.CURRENCY_SYMBOL}
        <br />
        {t("bfm.vehicleForm.paymentMethod.label")}:&nbsp;
        {d.associatedObject?.paymentMethod
          ? t(
              taxonomy.PaymentMethod.byId[d.associatedObject?.paymentMethod]
                ?.code
            )
          : null}
        <br />
        {d.extraData?.lineSpecial ===
        ETimelineExtraDataLineSpecial.CLOSING_DATE ? (
          <>
            {t("bfm.isFirstForFleetLegalClosingDate.label")}:&nbsp;
            {d.associatedObject?.isFirstForFleetLegalClosingDate
              ? t("greco.yes")
              : t("greco.no")}
          </>
        ) : null}
        {appSettings?.SUSPENSION_DAYS_INCLUDED ? (
          <>
            <hr />
            {t("bfm.suspensionDays")}: {d.associatedObject?.suspensionDays}
            <br />
          </>
        ) : null}
        {appSettings?.ACTIVATION_DAYS_INCLUDED ? (
          <>
            {t("bfm.activationDays")}: {d.associatedObject?.activationDays}
            <br />
          </>
        ) : null}
        {appSettings?.SUSPENSION_DAYS_LIMIT_INCLUDED ? (
          <>
            {t("bfm.suspensionDaysLimit")}:{" "}
            {d.associatedObject?.suspensionDaysLimit}
            <br />
          </>
        ) : null}
        {d.domain.value === PremiumType.HULL ||
        d.domain.value === PremiumType.HULL_BASIC ? (
          <>
            {appSettings?.GARAGE_RISK_DISCOUNT_PERCENT_INCLUDED ? (
              <>
                {t("bfm.garageRiskDiscountPercent")}:{" "}
                {d.associatedObject?.garageRiskDiscountPercent}%
                <br />
              </>
            ) : null}
          </>
        ) : (
          ""
        )}
      </>
    );

  extraInfo = (
    <>
      {extraInfo}
      <hr />
      {d.domain.value === PremiumType.HULL ||
      d.domain.value === PremiumType.HULL_BASIC ? (
        <>
          {appSettings.SHOW_HULL_PRODUCT ? (
            <>
              {t("bfm.vehicleForm.hullProduct.label")}:{" "}
              {d.associatedObject?.hullPremiumRelatedInfo?.hullProduct}
              <br />
            </>
          ) : null}
          {t("bfm.vehicleForm.gap.label")}:{" "}
          {d.associatedObject?.hullPremiumRelatedInfo?.gap
            ? t("greco.yes")
            : t("greco.no")}
          <br />
          {t("bfm.vehicleForm.replacementValue.label")}:{" "}
          {d.associatedObject?.hullPremiumRelatedInfo?.replacementValue
            ? t("greco.yes")
            : t("greco.no")}
          <br />
          {t("bfm.vehicleForm.grossNegligence.label")}:{" "}
          {d.associatedObject?.hullPremiumRelatedInfo?.grossNegligence
            ? t("greco.yes")
            : t("greco.no")}
          <br />
          {t("bfm.vehicleForm.electroPlusPackage.label")}:{" "}
          {d.associatedObject?.hullPremiumRelatedInfo?.electroPlusPackage
            ? t("greco.yes")
            : t("greco.no")}
          <br />
          {d.associatedObject?.hullPremiumRelatedInfo?.hullDeductiblePercent ||
          d.associatedObject?.hullPremiumRelatedInfo?.hullDeductiblePercent ===
            0 ? (
            <>
              {t("bfm.vehicleForm.hullDeductiblePercent.label")}:{" "}
              {
                d.associatedObject?.hullPremiumRelatedInfo
                  ?.hullDeductiblePercent
              }
              {"%"}
              <br />
            </>
          ) : null}
          {d.associatedObject?.hullPremiumRelatedInfo
            ?.hullDeductibleMinAmount ||
          d.associatedObject?.hullPremiumRelatedInfo
            ?.hullDeductibleMinAmount === 0 ? (
            <>
              {t("bfm.vehicleForm.hullDeductibleMinAmount.label")}:{" "}
              {
                d.associatedObject?.hullPremiumRelatedInfo
                  ?.hullDeductibleMinAmount
              }
              <br />
            </>
          ) : null}
          {d.associatedObject?.hullPremiumRelatedInfo
            ?.hullDeductibleMaxAmount ||
          d.associatedObject?.hullPremiumRelatedInfo
            ?.hullDeductibleMaxAmount === 0 ? (
            <>
              {t("bfm.vehicleForm.hullDeductibleMaxAmount.label")}:{" "}
              {
                d.associatedObject?.hullPremiumRelatedInfo
                  ?.hullDeductibleMaxAmount
              }
              <br />
            </>
          ) : null}
          {d.associatedObject?.hullPremiumRelatedInfo
            ?.hullDeductibleComment && (
            <>
              {t("bfm.vehicleForm.hullDeductibleComment.label")}:{" "}
              {
                d.associatedObject?.hullPremiumRelatedInfo
                  ?.hullDeductibleComment
              }
              <br />
            </>
          )}
        </>
      ) : (
        ""
      )}
      <hr />
      {t("bfm.setByUserName.label")}: {d.associatedObject?.setByUserName}
      <br />
      {t("bfm.setAt.label")}:{" "}
      {tickFormat(moment(d.associatedObject?.setAt).toDate())}
      <br />
      {t("bfm.changeReasonComment.label")}:{" "}
      {d.associatedObject?.changeReasonComment}
    </>
  );
  //}

  const getDomainValue = (domainValue, lineSpecial) => {
    if (
      domainValue === PremiumType.FLEETLEGAL_PRORATA &&
      lineSpecial === ETimelineExtraDataLineSpecial.CLOSING_DATE
    ) {
      return PremiumType.FLEETLEGAL_CLOSINGDATE;
    }
    if (
      domainValue === PremiumType.FLEETLEGAL_PRORATA &&
      lineSpecial !== ETimelineExtraDataLineSpecial.CLOSING_DATE
    ) {
      return PremiumType.FLEETLEGAL_PRORATA;
    }

    if (
      domainValue === PremiumType.HULL &&
      lineSpecial === ETimelineExtraDataLineSpecial.BASIC
    ) {
      return PremiumType.HULL_BASIC;
    }
    if (
      domainValue === PremiumType.HULL &&
      lineSpecial !== ETimelineExtraDataLineSpecial.BASIC
    ) {
      return PremiumType.HULL;
    }

    return domainValue;
  };
  return (
    <Tippy
      delay={100}
      followCursor={true}
      disabled={false}
      content={
        <span>
          {t(getDomainValue(d.domain.value, d.extraData.lineSpecial))}
          <br />
          {period}
          <hr />
          {extraInfo}
        </span>
      }
    >
      {children}
    </Tippy>
  );
};

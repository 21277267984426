import CheckboxField from "components/controls/CheckboxField";
import ComboBoxField from "components/controls/ComboBoxField";
import DatePickerField from "components/controls/DatePickerField";
import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import NumberInputField from "components/controls/NumberInputField";
import TextInputField from "components/controls/TextInputField";
import { vehicleFormClasses } from "pages/vehicle/VehicleForm";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import {
  isDisabledExternal,
  isDisabledExternalOrHasBeenActivated,
} from "../../../helpers";
import useInsurerList from "./hooks/useInsurerList";

const FinanceTempFleetLegalFields = ({
  formikProps,
  userRole,
  route,
  vehicle,
  insurerList,
}) => {
  const { t } = useTranslation();
  const { setFieldValue, handleChange, values, errors } = formikProps;
  const taxonomy = useSelector((s) => s.taxonomy);
  const vehicleAppSettings = useSelector((s) => s.vehicle.vehicleAppSettings);

  const paymentMethodOptions = useMemo(() => {
    return taxonomy?.PaymentMethod?.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy?.PaymentMethod?.items]);
  const policyStatusOptions = useMemo(() => {
    return taxonomy?.VehiclePolicyStatusCode?.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy?.VehiclePolicyStatusCode?.items]);

  const paymentMeanOptions = useMemo(() => {
    return taxonomy?.PremiumPaymentMean?.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy?.PremiumPaymentMean?.items]);

  const insurerData = useInsurerList({
    insurerList,
    values,
    name: "fleetLegalInsurerPartnerNumber",
    subName: "fleetLegalInsurerBranchOffice",
    setFieldValue,
  });

  return (
    <>
      <div className={vehicleFormClasses.item}>
        <CheckboxField
          checked={values?.fleetLegalIsClosingDate}
          errors={errors}
          setFieldValue={setFieldValue}
          name={"fleetLegalIsClosingDate"}
          disabled={false}
          label={"bfm.fleetLegalIsClosingDate.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalIsClosingDate"
          )}
        />
      </div>

      <div className={vehicleFormClasses.item}>
        <CheckboxField
          checked={values?.isFirstForFleetLegalClosingDate}
          errors={errors}
          setFieldValue={setFieldValue}
          name={"isFirstForFleetLegalClosingDate"}
          disabled={false}
          label={"bfm.isFirstForFleetLegalClosingDate.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "isFirstForFleetLegalClosingDate"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <DatePickerField
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values.fleetLegalLastStartDate}
          name={"fleetLegalLastStartDate"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.startDate.label"}
          placeholder={t("bfm.selectDate")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalLastStartDate"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <DatePickerField
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values.fleetLegalImportEndDate}
          name={"fleetLegalImportEndDate"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.endDate.label"}
          placeholder={t("bfm.selectDate")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalImportEndDate"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <DatePickerField
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values.fleetLegalPolicyCancelationDocumentDate}
          name={"fleetLegalPolicyCancelationDocumentDate"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.cancelationDocumentDate.label"}
          placeholder={t("bfm.selectDate")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalPolicyCancelationDocumentDate"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          errors={errors}
          name="fleetLegalInsurerPartnerNumber"
          label={"bfm.fleetForm.fleetLegalInsurerPartnerNumber.label"}
          setFieldValue={setFieldValue}
          options={insurerData.insurerOptions}
          defaultValue={values?.fleetLegalInsurerPartnerNumber}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalInsurerPartnerNumber"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          errors={errors}
          name="fleetLegalInsurerBranchOffice"
          label={"bfm.vehicleForm.insurerBranchOffice.label"}
          setFieldValue={setFieldValue}
          options={insurerData.insurerBranchOfficeOptions}
          defaultValue={values?.fleetLegalInsurerBranchOffice}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalInsurerBranchOffice"
          )}
          confidentiality={null}
        />
      </div>

      <div className={vehicleFormClasses.item}>
        <TextInputField
          value={values.fleetLegalPolicyNumber}
          onChange={handleChange}
          errors={errors}
          name={"fleetLegalPolicyNumber"}
          // multiline
          // rows={4}
          disabled={isDisabledExternal({ userRole, route })}
          label={"bfm.vehicleForm.policyNumber.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalPolicyNumber"
          )}
        />
      </div>
      {vehicleAppSettings.DEFAULT_COLUMNS.includes("fleetLegalPolicyStatus") ? (
        <div className={vehicleFormClasses.item}>
          <ComboBoxField
            disabled={isDisabledExternal({ userRole, route })}
            errors={errors}
            name="fleetLegalPolicyStatus"
            label={"bfm.vehicleForm.fleetLegalPolicyStatus.label"}
            setFieldValue={setFieldValue}
            options={policyStatusOptions}
            defaultValue={values.fleetLegalPolicyStatus}
            isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
              "fleetLegalPolicyStatus"
            )}
            confidentiality={null}
          />
        </div>
      ) : null}
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternal({ userRole, route })}
          errors={errors}
          name="fleetLegalPaymentMethod"
          label={"bfm.vehicleForm.paymentMethod.label"}
          setFieldValue={setFieldValue}
          options={paymentMethodOptions}
          defaultValue={values.fleetLegalPaymentMethod}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalPaymentMethod"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          errors={errors}
          name="fleetLegalPaymentMean"
          label={"bfm.vehicleForm.paymentMean.label"}
          setFieldValue={setFieldValue}
          options={paymentMeanOptions}
          defaultValue={values.fleetLegalPaymentMean}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalPaymentMean"
          )}
          confidentiality={null}
        />
      </div>

      <div className={vehicleFormClasses.item}>
        <DoubleNumberInputField
          setFieldValue={setFieldValue}
          value={values?.fleetLegalAnnualGrossPremium}
          onChange={handleChange}
          errors={errors}
          name={"fleetLegalAnnualGrossPremium"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.annualGrossPremium.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalAnnualGrossPremium"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <DoubleNumberInputField
          setFieldValue={setFieldValue}
          value={values?.fleetLegalAnnualNetPremium}
          onChange={handleChange}
          errors={errors}
          name={"fleetLegalAnnualNetPremium"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.annualNetPremium.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalAnnualNetPremium"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.fleetLegalSuspensionDays}
          onChange={handleChange}
          errors={errors}
          name={"fleetLegalSuspensionDays"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.suspensionDays"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalSuspensionDays"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.fleetLegalActivationDays}
          onChange={handleChange}
          errors={errors}
          name={"fleetLegalActivationDays"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.activationDays"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalActivationDays"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.fleetLegalSuspensionDaysLimit}
          onChange={handleChange}
          errors={errors}
          name={"fleetLegalSuspensionDaysLimit"}
          disabled={isDisabledExternalOrHasBeenActivated({
            userRole,
            route,
            vehicle,
          })}
          label={"bfm.suspensionDaysLimit"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalSuspensionDaysLimit"
          )}
          isNegativeAllowed={true}
        />
      </div>

      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.fleetLegalDueMonth}
          onChange={handleChange}
          errors={errors}
          inputProps={{ type: "number", max: "12" }}
          name={"fleetLegalDueMonth"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.dueMonth.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalDueMonth"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.fleetLegalDueDay}
          onChange={handleChange}
          errors={errors}
          name={"fleetLegalDueDay"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.dueDay.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalDueDay"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <DatePickerField
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values.fleetLegalPolicyDocumentDate}
          name={"fleetLegalPolicyDocumentDate"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.documentDate.label"}
          placeholder={t("bfm.selectDate")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalPolicyDocumentDate"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.fullWidthFormItem}>
        <CheckboxField
          checked={values?.fleetLegalIsPolicyIssued}
          errors={errors}
          setFieldValue={setFieldValue}
          name={"fleetLegalIsPolicyIssued"}
          disabled={isDisabledExternalOrHasBeenActivated({
            userRole,
            route,
            vehicle,
          })}
          label={"bfm.isPolicyIssued"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalIsPolicyIssued"
          )}
        />
      </div>
      <div className={vehicleFormClasses.fullWidthFormItem}>
        <TextInputField
          value={values.fleetLegalComment}
          onChange={handleChange}
          errors={errors}
          name={"fleetLegalComment"}
          multiline
          rows={4}
          disabled={isDisabledExternal({ userRole, route })}
          label={"bfm.fleetForm.fleetComment.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "fleetLegalComment"
          )}
        />
      </div>
    </>
  );
};

export default FinanceTempFleetLegalFields;

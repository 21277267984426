import { pointer } from "d3";
import React, { createRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "store/hooks";
import { openContextMenu } from "store/timeline";
import { TimelineContextMenuType } from "types/types";
import { PolicyStatusSegmentTooltip } from "./PolicyStatusSegmentTooltip";

type PolicyStatusSegmentProps = {
  segment: any;
  index: number;
  xScale: any;
  yScale: any;
  statusColors: any;
  innerWidth: number;
  extraDeltaY?: number;
};

const PolicyStatusSegment = (props: PolicyStatusSegmentProps) => {
  const {
    segment,
    xScale,
    yScale,
    statusColors,
    index,
    innerWidth,
    extraDeltaY = 0,
  } = props;

  const d = segment;
  const appSettings = useSelector((s) => s.timeline.timelineAppSettings);

  const rectRef = createRef<SVGRectElement>();
  let x1 = xScale(d.startDate);
  let x2 = xScale(d.endDate);
  if (x1 < 0) x1 = 0;
  if (x2 > innerWidth) x2 = innerWidth;
  let width = x2 - x1;

  const dispatch = useDispatch();
  const deltaY =
    extraDeltaY +
    appSettings?.PREMIUM_BAR_HEIGHT / 2 -
    appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2;
  let strokeColor = null;
  let strokeWidth = 0;
  strokeColor = statusColors[segment.segmentType].strokeColor;
  strokeWidth = statusColors[segment.segmentType].strokeWidth;
  return (
    <PolicyStatusSegmentTooltip
      isDisplayed={true}
      aggregatedStatusSegmentList={[d]}
    >
      <g>
        <rect
          id={
            "policystatus1_" +
            (d.associatedObject && d.associatedObject?.statusId
              ? d.associatedObject.statusId
              : index)
          }
          ref={rectRef}
          className={`${statusColors[d.segmentType].segmentClass} clickable`}
          x={x1}
          width={width}
          y={yScale(d.domain.value) + yScale.bandwidth() / 2 + deltaY}
          height={appSettings?.VEHICLE_STATUS_BAR_HEIGHT}
          fill={statusColors[d.segmentType].fillColor}
          onClick={(e) => {
            return dispatch(
              openContextMenu({
                type: TimelineContextMenuType.POLICY_STATUS_BAR,
                item: d,
                ref: { x: e.pageX, y: e.pageY },
                date: xScale.invert(pointer(e)[0]),
              })
            );
          }}
        ></rect>
      </g>
    </PolicyStatusSegmentTooltip>
  );
};

export default PolicyStatusSegment;

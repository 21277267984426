import { useMutation } from "react-query";
import {
  CancelVehiclePendingStatusParams,
  cancelVehiclePendingStatus,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const cancelVehiclePendingStatusFn = async ({
  rowVersion,
  params,
}: {
  rowVersion?: string;
  params: CancelVehiclePendingStatusParams;
}) => {
  return await cancelVehiclePendingStatus(rowVersion, params);
};

export function useCancelVehiclePendingStatus() {
  return useMutation(cancelVehiclePendingStatusFn, {
    mutationKey: MUTATION_KEYS.cancelVehiclePendingStatus,
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

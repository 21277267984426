import { pointer } from "d3";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openContextMenu } from "store/timeline";
import { useSelector } from "../../../../../store/hooks";
import {
  TimelineContextMenuType,
  TimelineSegmentType,
  TimelineType,
} from "../../../../../types/types";
import { OneTimePremiumSegmentTooltip } from "./OneTimePremiumSegmentTooltip";

export const OneTimePremiumSegments = ({ xScale, yScale, innerWidth }) => {
  const data = useSelector((s) => s.timeline.timeline.filteredData);
  const dispatch = useDispatch();
  const taxonomy = useSelector((s) => s.taxonomy);
  const appSettings = useSelector((s) => s.timeline.timelineAppSettings);

  const { t } = useTranslation();
  if (!data) return null;

  const segments = data
    .filter(
      (segment) =>
        segment.domain.type === TimelineType.VEHICLE_ONE_TIME_PREMIUM &&
        segment.segmentType === TimelineSegmentType.ONE_TIME_PREMIUM
    )
    .map((d, index) => {
      let x1 = xScale(d.startDate);
      let display = true;
      if (x1 < 0) display = false;
      if (x1 > innerWidth) display = false;
      // \
      // /

      let existingTypes = {
        "OneTimePremiumType.INDEMNITY": 0,
        "OneTimePremiumType.BONUS_MALUS": 0,
      };
      d.extraData.associatedObjects.forEach((associatedObject) => {
        existingTypes[
          taxonomy.OneTimePremiumType.byId[
            associatedObject.oneTimePremiumTypeCode
          ].code
        ]++;
      });
      let circleClass = "bookingCircle";
      const deltax = 7;
      const deltay = 6;
      const xv = x1;
      //const yv = yScale(d.domain.value); // + yScale.bandwidth();
      const showPolicyStatuses = appSettings?.VEHICLE_TIMELINE_TYPES.includes(
        TimelineType.VEHICLE_POLICY_STATUSES
      );
      const deltaYForPolicyStatus = showPolicyStatuses
        ? -appSettings?.PREMIUM_BAR_HEIGHT / 2
        : 0;
      const yv =
        yScale(d.domain.value) +
        yScale.bandwidth() / 2 -
        appSettings?.PREMIUM_BAR_HEIGHT / 2 +
        deltaYForPolicyStatus;
      return display ? (
        <OneTimePremiumSegmentTooltip key={index} d={d}>
          <g
            id={
              "oneTimePremium_" +
              (d.associatedObject && d.associatedObject?.vehiclePremiumVersionId
                ? d.associatedObject.vehiclePremiumVersionId
                : index)
            }
            name={
              "oneTimePremium_" + d.domain.value.replace("PremiumType.", "")
            }
            style={{
              cursor: "pointer",
            }}
            onClick={(e) => {
              return dispatch(
                openContextMenu({
                  type: TimelineContextMenuType.ONETIME_PREMIUM,
                  item: d,
                  ref: { x: e.pageX, y: e.pageY },
                  date: xScale.invert(pointer(e)[0]),
                })
              );
            }}
          >
            {existingTypes["OneTimePremiumType.BONUS_MALUS"] > 0 &&
            existingTypes["OneTimePremiumType.INDEMNITY"] === 0 ? (
              <polygon
                className={circleClass}
                points={`${xv - 6.5},${yv} ${xv},${yv - 6.5} ${
                  xv + 6.5
                },${yv} ${xv},${yv + 6.5}`}
              />
            ) : null}
            {existingTypes["OneTimePremiumType.INDEMNITY"] > 0 &&
            existingTypes["OneTimePremiumType.BONUS_MALUS"] === 0 ? (
              <polygon
                className={circleClass}
                points={`${xv - 4.8},${yv - 4.8} ${xv + 4.8},${yv - 4.8} ${
                  xv + 4.8
                },${yv + 4.8} ${xv - 4.8},${yv + 4.8}`}
              />
            ) : null}

            {existingTypes["OneTimePremiumType.INDEMNITY"] > 0 &&
            existingTypes["OneTimePremiumType.BONUS_MALUS"] > 0 ? (
              <>
                <polygon
                  className={circleClass}
                  points={`${xv - 7},${yv} ${xv},${yv - 7} ${
                    xv + 7
                  },${yv} ${xv},${yv + 7}`}
                />{" "}
                <polygon
                  className={circleClass}
                  points={`${xv - 3.4},${yv - 3.4} ${xv + 3.4},${yv - 3.4} ${
                    xv + 3.4
                  },${yv + 3.4} ${xv - 3.4},${yv + 3.4}`}
                />
              </>
            ) : null}

            {/* <circle
              className={circleClass}
              r={5.5}
              cx={xv}
              cy={yv}
              strokeWidth={strokeWidth}
            /> */}

            {d.extraData.negativeAmount &&
            d.extraData.negativeAmount === true ? (
              <>
                <line
                  x1={deltax + xv - 1.5}
                  y1={deltay + yv}
                  x2={deltax + xv + 1.5}
                  y2={deltay + yv}
                  stroke={"#bb0000"}
                  strokeWidth={1.5}
                />
              </>
            ) : null}
            {d.extraData.positiveAmount &&
            d.extraData.positiveAmount === true ? (
              <>
                <line
                  x1={deltax + xv - 2.5}
                  y1={-deltay + yv}
                  x2={deltax + xv + 2.5}
                  y2={-deltay + yv}
                  stroke={"#008800"}
                  strokeWidth={1.5}
                />
                <line
                  x1={deltax + xv}
                  y1={-deltay + yv - 2.5}
                  x2={deltax + xv}
                  y2={-deltay + yv + 2.5}
                  stroke={"#008800"}
                  strokeWidth={1.5}
                />
              </>
            ) : null}
          </g>
        </OneTimePremiumSegmentTooltip>
      ) : null;
    });

  return <>{segments}</>;
};

//@ts-ignore

import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  Label,
} from "@fluentui/react";
import { formatISO } from "date-fns";
import { Formik, Field as FormikField } from "formik";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { setYupLocale } from "utils/setYupLocale";
import * as yup from "yup";
import { allowedVehicleStatuses } from "../config/businessLogic";
import * as Colors from "../config/colors";
import { useSelector } from "../store/hooks";
import { AppDispatch } from "../store/store";
import { getSelectStyles } from "../utils/utils";
import { FormSpinner } from "./FormSpinner";
import { VSpace } from "./Spacer";
import { VehicleStatusSelect } from "./VehicleStatusSelect";
import { Field } from "./form/Field";
import { combine, maxLength, required } from "./form/validation";
import { ErrorMessage, StyledPrimaryButton } from "./styled";
import { useIsVehicleStatusOptionDisabled } from "./useIsVehicleStatusOptionDisabled";

type Props = {
  changeVehicleStatusDialog: any;
  isUpdateStatusLoading: boolean;
  close: () => void;
  updateVehicleStatusAction: (payload: any) => void;
  updateVehiclesStatusAction: (payload: any) => void;
};

export const ChangeVehicleStatusDialog = ({
  isUpdateStatusLoading,
  changeVehicleStatusDialog,
  close,
  updateVehicleStatusAction,
  updateVehiclesStatusAction,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;

  const dispatch: AppDispatch = useDispatch();
  const taxonomy = useSelector((s) => s.taxonomy);
  const { vehicle, vehicleIds, fleetId } = changeVehicleStatusDialog;
  const singleSelected = vehicleIds?.length === 1;
  const userRole = useSelector((s) => s.auth.userRole);
  // const isLoading = updateVehicleStatusStatus === "loading";
  const phraseActivated = usePhraseActivationStatus().activated;
  const isOptionDisabled = useIsVehicleStatusOptionDisabled(
    vehicle || undefined
  );
  const [didTrySubmit, setDidTrySubmit] = useState(false);

  const vehicleStatusTaxCode =
    taxonomy.VehicleStatusCode.byId[vehicle?.vehicleStatusCode]?.code;
  let allowedStatusCodes = [];
  if (vehicle && vehicleStatusTaxCode) {
    allowedStatusCodes = allowedVehicleStatuses[userRole](
      vehicle as any,
      vehicleStatusTaxCode
    );
  } else if (vehicleIds?.length > 0) {
    allowedStatusCodes = allowedVehicleStatuses[userRole](
      vehicle as any,
      vehicleStatusTaxCode
    );
  }

  const options = useMemo(() => {
    return taxonomy.VehicleStatusCode.items
      .filter((tax) => allowedStatusCodes.includes(tax.code))
      .map((tax) => ({
        label: t(tax.code),
        value: tax.id,
        color: Colors.vehicleStatus[tax.code],
        code: tax.code,
      }));
  }, [taxonomy.VehicleStatusCode.items, allowedStatusCodes, t]);

  const onSubmit = (data) => {
    if (vehicle && (!vehicleIds || vehicleIds?.length < 2)) {
      updateVehicleStatusAction({
        fleetId: fleetId,
        vehicleId: vehicle.vehicleId,
        vehicleStatusCode: data.vehicleStatusCode.value,
        vehicleLastStatusComment: data.vehicleLastStatusComment,
        vehicleLastStatusDate: formatISO(data.vehicleLastStatusDate, {
          representation: "date",
        }),
      });
    } else if (vehicleIds?.length > 0) {
      updateVehiclesStatusAction({
        fleetId: fleetId,
        vehicleIds: vehicleIds,
        vehicleStatusCode: data.vehicleStatusCode.value,
        vehicleLastStatusComment: data.vehicleLastStatusComment,
        vehicleLastStatusDate: formatISO(data.vehicleLastStatusDate, {
          representation: "date",
        }),
      });
    }
  };

  const title = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Icon
        // iconName="SyncStatus"

        style={{
          color: theme.palette.themePrimary,
          fontSize: "25px",
          marginRight: "5px",
        }}
      />
      {vehicle
        ? t("bfm.changeStatus.label")
        : t("bfm.changeStatusVehicles.label", { num: vehicleIds?.length })}
    </div>
  );

  const initialValues = useMemo(() => {
    let currentOption = null;
    if (!vehicle) return {};
    const statusTax =
      taxonomy.VehicleStatusCode.byId[vehicle.vehicleStatusCode];
    const statusTaxCode = statusTax?.code;
    if (statusTax) {
      currentOption = {
        label: t(statusTax?.code),
        value: vehicle.vehicleStatusCode,
        color: Colors.vehicleStatus[statusTax?.code],
      } as any;
    }
    let vehicleLastStatusDate = new Date();
    let pendingComment = "";
    if (
      statusTaxCode === "VehicleStatusCode.PENDING_FOR_ACTIVATION" ||
      statusTaxCode === "VehicleStatusCode.PENDING_FOR_SUSPENSION" ||
      statusTaxCode === "VehicleStatusCode.PENDING_FOR_DEACTIVATION"
    ) {
      vehicleLastStatusDate = new Date(vehicle.vehicleLastStatusDate);
      pendingComment = vehicle.vehicleLastStatusComment;
    }
    return {
      from: currentOption,
      vehicleLastStatusDate,
      pendingComment,
    };
  }, [vehicle, taxonomy, t]);

  const schema = useMemo(() => {
    setYupLocale(t);
    return yup.object({
      vehicleLastStatusDate: yup.mixed().required(),
    });
  }, [t]);

  return (
    <Dialog
      hidden={false}
      onDismiss={close}
      dialogContentProps={{
        type: DialogType.normal,
        title,

        showCloseButton: true,
      }}
      minWidth={400}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={didTrySubmit}
        validateOnBlur={didTrySubmit}
        enableReinitialize
        validationSchema={schema}
      >
        {({ submitForm }) => {
          return (
            <>
              {vehicle && singleSelected ? (
                <>
                  <Label>{t("bfm.statusChangeFrom.label")}</Label>
                  <FormikField
                    name="from"
                    render={({ field }) => {
                      return (
                        <VehicleStatusSelect
                          {...field}
                          options={[field.value]}
                          // options={[]}
                          isDisabled
                          styles={getSelectStyles(false, theme)}
                        />
                      );
                    }}
                  />

                  <VSpace />
                </>
              ) : null}
              {[
                "VehicleStatusCode.PENDING_FOR_ACTIVATION",
                "VehicleStatusCode.PENDING_FOR_SUSPENSION",
                "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
              ].includes(vehicleStatusTaxCode) && (
                <>
                  <Field
                    name="pendingComment"
                    type="text"
                    isMultiline
                    rows={4}
                    label={"bfm.pendingComment.label"}
                    isDisabled
                  />
                  <VSpace />
                </>
              )}
              <Label required>{t("bfm.statusChangeTo.label")}</Label>
              <FormikField
                name="vehicleStatusCode"
                validate={(value) => combine(required(value))}
                render={({
                  field,
                  form: { setFieldValue },
                  meta: { error },
                }) => {
                  return (
                    <>
                      <VehicleStatusSelect
                        {...field}
                        onChange={(option) => {
                          if (!option) return;
                          setFieldValue("vehicleStatusCode", option);
                        }}
                        options={options}
                        isOptionDisabled={(o) =>
                          vehicle ? isOptionDisabled(o.value) : false
                        }
                        styles={getSelectStyles(!!error, theme)}
                        portalTarget={undefined}
                      />
                      {!!error && (
                        <ErrorMessage id={field.name + "_error"}>
                          {error}
                        </ErrorMessage>
                      )}
                    </>
                  );
                }}
              />
              <VSpace />
              <Field
                name="vehicleLastStatusDate"
                type="date"
                label={"bfm.date.label"}
                validate={(value) => combine(required(value))}
                isRequired
                d={({ t, values, errors, setFieldValue }) => ({
                  label: "bfm.date.label",
                  values,
                  errors,
                  setFieldValue,
                  defaultValue: values?.vehicleLastStatusDate,
                })}
              />
              <Field
                name="vehicleLastStatusComment"
                type="text"
                label={"bfm.comment.label"}
                isMultiline
                rows={4}
                validate={(value) => combine(maxLength(value, 512, t))}
              />
              <VSpace height={25} />
              <DialogFooter>
                <SaveButton
                  onClick={() => {
                    setDidTrySubmit(true);
                    submitForm();
                  }}
                />
                <DefaultButton onClick={close} text={t("bfm.close.label")} />
              </DialogFooter>
              {isUpdateStatusLoading && <FormSpinner />}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const SaveButton = ({
  onClick,
  isLoading,
  isDisabled,
}: {
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <StyledPrimaryButton
      onClick={onClick}
      text={t("bfm.saveStatusChange.label")}
      disabled={isDisabled}
      style={{
        marginRight: "5px",
      }}
      iconProps={{
        iconName: isLoading ? "ProgressRingDots" : "",
      }}
    />
  );
};

import { useMutation } from "react-query";
import { changeMultipleVehiclePendingIssuingStatus } from "store/api/api";
import { handleAxiosError } from "store/util";
import { IUpdateMultipleVehiclePendingIssuingStatus } from "types/types";
import { MUTATION_KEYS } from "../queryKeys";

const changeVehiclesPendingIssuingStatusFn = async (
  params: IUpdateMultipleVehiclePendingIssuingStatus
) => {
  return await changeMultipleVehiclePendingIssuingStatus(params);
};

export function useUpdateMultipleVehiclePendingIssuingStatus() {
  return useMutation(changeVehiclesPendingIssuingStatusFn, {
    mutationKey: MUTATION_KEYS.changeMultipleVehiclePendingIssuingStatus,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { Label } from "@fluentui/react";
import { FieldContainer } from "components/controls/FieldContainer";
import { useField } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { VSpace } from "../Spacer";
import { ErrorMessage, StyledCheckbox } from "../styled";
import { CheckboxFieldConfig } from "./types";
import { useDynamicConfig } from "./useDynamicConfig";

type Props = { fieldConfig: CheckboxFieldConfig };

export const CheckboxField = ({ fieldConfig }: Props) => {
  const { t } = useTranslation();
  const config = {
    ...fieldConfig,
    ...useDynamicConfig(fieldConfig.d),
  };
  const { name, label, isVisible = true, isDisabled, isRequired } = config;
  const [field, { error }, { setValue }] = useField({
    name,
    type: "checkbox",
  });

  if (!isVisible) return null;

  return (
    <FieldContainer
      isTooltipHidden={isDisabled}
      tooltipText={label}
      isReadOnly={true}
    >
      <Label required={isRequired} disabled={isDisabled}>
        {t(label)}
      </Label>
      <StyledCheckbox
        {...field}
        onChange={(e, checked) => setValue(checked)}
        disabled={isDisabled}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <VSpace height={10} />
    </FieldContainer>
  );
};

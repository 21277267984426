import { ActionButton } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { routes } from "../../../config/routes";

type Props = {
  isActive?: boolean;
};

export const UpdateFleetButton = ({ isActive }: Props) => {
  const { fleetId } = useParams() as any;
  const { t } = useTranslation();

  return (
    <Link to={routes.editFleet.getPath(fleetId)}>
      <ActionButton
        styles={{
          root: {
            fontWeight: isActive ? "bold" : "inherit",
          },
        }}
        iconProps={{
          iconName: "DocumentManagement",
          styles: {
            root: {
              fontWeight: isActive ? "bold" : "inherit",
            },
          },
        }}
        text={t("bfm.nav.fleetDetails")}
      />
    </Link>
  );
};

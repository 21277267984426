import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "store/hooks";
import { openContextMenu } from "store/timeline";
import { TimelineContextMenuType } from "types/types";
import { PolicyStatusSegmentTooltip } from "./PolicyStatusSegmentTooltip";

const PolicyStatusExpandedPoints = (props: {
  segmentList: any[];
  statusColors: any;
  x: number;
  y: number;
  xScale: any;
  yScale: any;
}) => {
  const { segmentList, statusColors, x, y, xScale, yScale } = props;
  const dispatch = useDispatch();
  const appSettings = useSelector((s) => s.timeline.timelineAppSettings);

  let strokeColor = null;
  let strokeWidth = 0;
  const expandedPoints = segmentList.map((segment, index) => {
    const d = segment;
    strokeColor = statusColors[segment.segmentType].strokeColor;
    strokeWidth = statusColors[segment.segmentType].strokeWidth;
    const rectRef = React.createRef<SVGRectElement>();
    return (
      <g key={"g_" + index}>
        {index < segmentList.length - 1 ? (
          <PolicyStatusSegmentTooltip
            isDisplayed={true}
            aggregatedStatusSegmentList={[d]}
          >
            <g>
              <rect
                id={
                  "policystatus1_" +
                  (d.associatedObject && d.associatedObject?.statusId
                    ? d.associatedObject.statusId
                    : index)
                }
                ref={rectRef}
                className={`${
                  statusColors[d.segmentType].segmentClass
                } clickable`}
                x={x + appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2 + index * 30}
                width={12}
                y={21 + y}
                height={appSettings?.VEHICLE_STATUS_BAR_HEIGHT}
                fill={statusColors[d.segmentType].fillColor}
                onClick={(e) => {
                  return dispatch(
                    openContextMenu({
                      type: TimelineContextMenuType.POLICY_STATUS_BAR,
                      item: d,
                      ref: { x: e.pageX, y: e.pageY },
                      date: d.startDate,
                    })
                  );
                }}
              ></rect>
            </g>
          </PolicyStatusSegmentTooltip>
        ) : null}
        <PolicyStatusSegmentTooltip
          isDisplayed={true}
          aggregatedStatusSegmentList={[segment]}
        >
          <g
            onClick={(e) => {
              return dispatch(
                openContextMenu({
                  type: TimelineContextMenuType.POLICY_STATUS_POINT,
                  item: segment,
                  ref: { x: e.pageX, y: e.pageY },
                  date: segment.startDate,
                })
              );
            }}
          >
            <rect
              ref={rectRef}
              className={"status-point"}
              r="10"
              x={x - appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2 + index * 30}
              y={30 + y - appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2}
              width={appSettings?.VEHICLE_STATUS_BAR_HEIGHT}
              height={appSettings?.VEHICLE_STATUS_BAR_HEIGHT}
              fill={"white"}
              stroke={statusColors[segment.segmentType].strokeColor}
              strokeWidth={statusColors[segment.segmentType].strokeWidth}
            />
            <rect
              key={index}
              className={`policy-status-point-${
                statusColors[segment.segmentType].pointColor
              }`}
              r="5"
              x={x - appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 4 + index * 30}
              y={30 + y - appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 4}
              width={appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2}
              height={appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2}
              stroke={statusColors[segment.segmentType].fillColor}
              strokeWidth={0}
            />
          </g>
        </PolicyStatusSegmentTooltip>
      </g>
    );
  });
  if (segmentList.length === 1) {
    return null;
  }

  return (
    <g>
      <rect
        x={x - appSettings?.VEHICLE_STATUS_BAR_HEIGHT}
        y={30 + y - appSettings?.VEHICLE_STATUS_BAR_HEIGHT}
        width={
          segmentList.length * 30 + appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 4
        }
        height={10 + appSettings?.VEHICLE_STATUS_BAR_HEIGHT * 1.5}
        fill={"white"}
        stroke={"black"}
        strokeWidth={1}
      />
      <line
        x1={x}
        x2={x}
        y1={y + appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2}
        y2={30 + y}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      {expandedPoints}
    </g>
  );
};
export default PolicyStatusExpandedPoints;

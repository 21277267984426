import DatePickerField from "components/controls/DatePickerField";
import TextInputField from "components/controls/TextInputField";
import { constants } from "library/constants";
import {
  hasVehicleBeenActivated,
  isDisabledExternalAndHasBeenActivated,
} from "pages/vehicle/helpers";
import { vehicleFormClasses } from "pages/vehicle/VehicleForm";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";

const GeneralFormItems = ({ initialValues, vehicle, route, formikProps }) => {
  const { t } = useTranslation();
  const userRole = useSelector((s) => s.auth.userRole);

  const { values, errors, handleChange, setFieldValue } = formikProps;
  const { createVehicle, duplicateVehicle, createTradeVehicle } = constants;

  const vehicleLastStatusDateVisible = false;
  // route === createVehicle ||
  // route === duplicateVehicle ||
  // route === createTradeVehicle;

  const isTradeLicensePlate =
    route === createTradeVehicle || vehicle?.isTradeLicensePlate;
  const confidentiality = useSelector(
    (s) => s.vehicle?.scannedFormFields?.confidentialityInfo
  );
  const isLicensePlateDisabled = ({ vehicle, route }: any) => {
    return (
      hasVehicleBeenActivated({ vehicle, route }) || route === "readOnlyVehicle"
    );
  };

  return (
    <div className={vehicleFormClasses.container}>
      {/* 1 */}
      <div className={vehicleFormClasses.item}>
        <TextInputField
          value={values.licensePlate}
          errors={errors}
          required
          maxLength={12}
          onChange={handleChange}
          name={"licensePlate"}
          disabled={isLicensePlateDisabled({
            route,
            vehicle,
          })}
          label={"bfm.vehicleForm.licensePlate.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "licensePlate"
          )}
          confidentiality={confidentiality?.LicensePlate}
        />
      </div>

      {/* 2 */}
      {vehicleLastStatusDateVisible && (
        <div className={vehicleFormClasses.item}>
          <DatePickerField
            errors={errors}
            setFieldValue={setFieldValue}
            defaultValue={initialValues.vehicleLastStatusDate}
            name={"vehicleLastStatusDate"}
            disabled={route === "readOnlyVehicle"}
            label={"bfm.vehicleForm.vehicleLastStatusDate.label"}
            placeholder={t("bfm.selectDate")}
            required
            isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
              "vehicleLastStatusDate"
            )}
            confidentiality={confidentiality?.VehicleLastStatusDate}
          />
        </div>
      )}
      {/* 2 */}
      <div className={vehicleFormClasses.item}>
        <DatePickerField
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={initialValues.registrationDate}
          required
          name="registrationDate"
          disabled={isDisabledExternalAndHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.registrationDate.label"}
          placeholder={t("bfm.selectDate")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "registrationDate"
          )}
          confidentiality={confidentiality?.RegistrationDate}
        />
      </div>
      {/* 3 */}
      <div className={vehicleFormClasses.item}>
        <TextInputField
          value={values.vehicleOwner}
          errors={errors}
          maxLength={128}
          onChange={handleChange}
          name={"vehicleOwner"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.vehicleOwner.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "vehicleOwner"
          )}
          confidentiality={confidentiality?.VehicleOwner}
        />
      </div>
    </div>
  );
};

export default GeneralFormItems;

import { isExternalOrReadonlyAdmin } from "form/util";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../store/hooks";
import { openDialog } from "../../../store/timeline";
import {
  TimelineContextMenuType,
  TimelineDialogType,
  TimelineType
} from "../../../types/types";

export const useLicensePlateSegmentMenuItems = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const segmentContextMenuItem = useSelector(
    (s) => s.timeline.contextMenu.item
  );

  const segmentContextMenuType = useSelector(
    (s) => s.timeline.contextMenu.type
  );

  const vehicle = useSelector((s) => s.vehicle?.vehicle);

  const statusContextMenuDate = useSelector((s) => s.timeline.contextMenu.date);

  const segmentContextMenuItemDomainType = useSelector(
    (s) => s.timeline.contextMenu.item?.domain.type
  );
  const userRole = useSelector((s) => s.auth.userRole);

  return useMemo(() => {
    const items = [];
    if (isExternalOrReadonlyAdmin(userRole)) return [];
    if (
      segmentContextMenuItemDomainType !== TimelineType.VEHICLE_LICENSE_PLATE
    ) {
      return [];
    }
    /*
    if (vehicle && vehicle.isTradeLicensePlate) {
      return [];
    }
    */
    // Add and Insert
    if (
      segmentContextMenuType === TimelineContextMenuType.LICENSE_PLATE_POINT
    ) {
      items.push({
        key: "correct license plate date",
        text: t("bfm.correctLicensePlate"),
        onClick: () => {
          dispatch(
            openDialog({
              dialogType: TimelineDialogType.CORRECT_LICENSE_PLATE,
              contextMenuItem: segmentContextMenuItem,
              contextMenuDate: moment(
                segmentContextMenuItem.startDate
              ).toDate(),
              previousDialogData: null,
            })
          );
        },
      });

      items.push({
        key: "delete license plate date",
        text: t("bfm.deleteLicensePlate"),
        onClick: () => {
          dispatch(
            openDialog({
              dialogType: TimelineDialogType.DELETE_LICENSE_PLATE,
              contextMenuItem: segmentContextMenuItem,
              contextMenuDate: moment(
                segmentContextMenuItem.startDate
              ).toDate(),
              previousDialogData: null,
            })
          );
        },
      });
    }

    if (segmentContextMenuType === TimelineContextMenuType.LICENSE_PLATE) {
      if (segmentContextMenuItem.extraData.isInfinitePremium) {
        items.push({
          key: "change license plate",
          text: t("bfm.changeLicensePlate"),
          onClick: () => {
            dispatch(
              openDialog({
                dialogType: TimelineDialogType.CHANGE_LICENSE_PLATE,
                contextMenuItem: segmentContextMenuItem,
                contextMenuDate: moment(statusContextMenuDate).toDate(),
                previousDialogData: null,
              })
            );
          },
        });
      } else {
        items.push({
          key: "insert license plate",
          text: t("bfm.insertLicensePlate"),
          onClick: () => {
            dispatch(
              openDialog({
                dialogType: TimelineDialogType.INSERT_LICENSE_PLATE,
                contextMenuItem: segmentContextMenuItem,
                contextMenuDate: moment(statusContextMenuDate).toDate(),
                previousDialogData: null,
              })
            );
          },
        });
      }
    }
    return items;
  }, [
    dispatch,
    t,
    segmentContextMenuItem,
    segmentContextMenuType,
    statusContextMenuDate,
    segmentContextMenuItemDomainType,
  ]);
};

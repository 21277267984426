import { DefaultButton, DialogFooter, PrimaryButton } from "@fluentui/react";
import { toast } from "components/FluentToast";
import { FormSpinner } from "components/FormSpinner";
import { Field } from "components/form/Field";
import { StyledPrimaryButton, VSpace } from "components/styled";
import { RefProvider } from "contexts/RefProvider";
import { Formik } from "formik";
import { useFleetVehicleReport } from "hooks/data/mutations/useFleetVehicleReport";
import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setYupLocale } from "utils/setYupLocale";
import * as yup from "yup";
import { setDialog } from "../../../store/fleetForm";

const GenerateFleetLegalReportFormDialog = () => {
  const { t } = useTranslation();

  const {
    data: excelReportResponse,
    mutate: onFleetVehicleReport,
    isLoading: isReportLoading,
  } = useFleetVehicleReport();

  const excelReportData = excelReportResponse?.data;

  const dispatch = useDispatch();
  const { fleetId } = useParams() as any;

  const close = () => {
    dispatch(setDialog({ type: "", isOpen: false }));
  };

  const initialValues = {
    reportType: "fleetLegalClosingDate",
    validFromDate: new Date(new Date().getFullYear(), 0, 1),
  };

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    return yup.object({
      validFromDate: yup.mixed().required(),
    });
  }, [t]);

  const onSubmit = (values) => {
    const payload = {
      reportType: values?.reportType,
      comment: null,
      insurerPartnerNumber: null,
      premiumType: null,
      validFromDate: moment(values.validFromDate).format("YYYY-MM-DD"),
      validToDate: null,
      vehicleIds: null,
    };

    onFleetVehicleReport(
      {
        fleetId,
        body: {
          ...payload,
        },
      },
      {
        onSuccess: () => {
          toast.success(t("bfm.reportGeneratedSuccessfully.label"));
        },
      }
    );
  };

  return (
    <RefProvider>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnChange={!!excelReportData}
        validateOnBlur={!!excelReportData}
        onSubmit={onSubmit}
      >
        {({ submitForm }) => {
          return (
            <>
              {excelReportData ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "25px",
                  }}
                >
                  <PrimaryButton
                    title={excelReportData.name}
                    onClick={() => {
                      window.open(excelReportData.url);

                      close();
                    }}
                    text={t("bfm.downloadReport.label")}
                  />
                </div>
              ) : (
                <>
                  <Field
                    name="validFromDate"
                    type="date"
                    label={"bfm.vehicleForm.validFromDate.label"}
                    isRequired
                    d={({ t, values, errors, setFieldValue }) => ({
                      label: "bfm.vehicleForm.validFromDate.label",
                      values,
                      errors,
                      setFieldValue,
                      defaultValue: values?.validFromDate,
                    })}
                  />
                  <VSpace height={25} />
                  <span>{t("bfm.report.warning.label")}</span>
                  <DialogFooter>
                    <SaveButton
                      onClick={() => {
                        submitForm();
                      }}
                    />
                    <DefaultButton
                      onClick={close}
                      text={t("bfm.close.label")}
                    />
                  </DialogFooter>
                </>
              )}
              {isReportLoading && <FormSpinner />}
            </>
          );
        }}
      </Formik>
    </RefProvider>
  );
};

const SaveButton = ({
  onClick,
  isLoading,
  isDisabled,
}: {
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <StyledPrimaryButton
      onClick={onClick}
      text={t("bfm.generateReport.label")}
      disabled={isDisabled}
      style={{
        marginRight: "5px",
      }}
      iconProps={{
        iconName: isLoading ? "ProgressRingDots" : "",
      }}
    />
  );
};

export default GenerateFleetLegalReportFormDialog;

import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useTranslation } from "react-i18next";
import { timeFormat } from "d3";

import moment from "moment";

const tickFormat = timeFormat("%d.%m.%Y");

export const StatusSegmentTooltip = ({ children, d }) => {
  const { t } = useTranslation();
  let period = (
    <>
      {tickFormat(d.startDate)} - {tickFormat(d.endDate)}
      <br />
    </>
  );
  let extraInfo = <></>;
  if (d.associatedObject?.setByUserName) {
    extraInfo = (
      <>
        {t("bfm.setByUserName.label")}: {d.associatedObject?.setByUserName}
        <br />
        {t("bfm.setAt.label")}:{" "}
        {tickFormat(moment(d.associatedObject?.setAt).toDate())}
        <br />
        {t("bfm.changeReasonComment.label")}:{" "}
        {d.associatedObject?.statusComment}
      </>
    );
  }
  return (
    <Tippy
      delay={100}
      followCursor={true}
      disabled={false}
      content={
        <span>
          {t(d.domain.value)}
          <br />
          {period}
          <hr />
          {extraInfo}
        </span>
      }
    >
      {children}
    </Tippy>
  );
};

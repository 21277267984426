import { mergeStyleSets } from "@fluentui/react";
import { useGetInsurerListByCountry } from "hooks/data/queries/useGetInsurerListByCountry";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Country_ALL } from "types/types";
import { ResultsScore } from "../../components/ResultsScore";
import { Links } from "../../components/navigation/Links";
import { HSpace, NavigationBar } from "../../components/styled";
import { useSelector } from "../../store/hooks";
import { setSearchInsurersPage } from "../../store/insurersPage";
import { AppDispatch } from "../../store/store";
import { NavigationSearch } from "./NavigationSearch";

export const Navigation = () => {
  const { t } = useTranslation();
  const search = useSelector((s) => s.insurersPage.search);
  const dispatch: AppDispatch = useDispatch();
  const taxonomy = useSelector((s) => s.taxonomy);
  const userCountryCode = useSelector((s) => s.auth.userCountryCode);

  const { data: insurers } = useGetInsurerListByCountry(
    userCountryCode && userCountryCode !== "-1"
      ? Number(userCountryCode)
      : Country_ALL
  );

  const classes = getClassNames();
  return (
    <NavigationBar>
      <Links />
      <HSpace />
      <NavigationSearch
        value={search}
        onChange={(value) => {
          dispatch(setSearchInsurersPage(value));
        }}
        placeholder={t("bfm.searchInsurers")}
      />

      <span className={classes.resultScoreContainer}>
        <ResultsScore score={insurers?.length} />
      </span>
    </NavigationBar>
  );
};

const getClassNames = () =>
  mergeStyleSets({
    resultScoreContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
    },
    addInsurerBtn: {
      height: "100%",
      i: {
        marginRight: 0,
      },
    },
    addInsurerLink: {
      height: "100%",
    },
  });

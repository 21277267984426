import { EActionDialog } from "pages/fleet-form/FleetForm/constants";
import { useSelector } from "store/hooks";

function useDefaultSettingsData() {
  const dialogData = useSelector((s) => s.fleetForm.dialog);

  const defaultData =
    (dialogData.type === EActionDialog.EditSetting ||
      dialogData.type === EActionDialog.CopySettings ||
      dialogData.type === EActionDialog.ReadOnly) &&
    dialogData.data?.selectedSetting;
  return defaultData;
}

export default useDefaultSettingsData;

import { useMutation } from "react-query";
import { updateVehicle } from "store/api/api";
import { handleAxiosError } from "store/util";
import { Vehicle } from "types/types";
import { MUTATION_KEYS } from "../queryKeys";

const updateVehicleFn = async (params: Vehicle) => {
  return await updateVehicle(params);
};

export function useUpdateVehicle() {
  return useMutation(updateVehicleFn, {
    mutationKey: MUTATION_KEYS.updateVehicle,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

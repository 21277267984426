import axios from "axios";
import { useMutation } from "react-query";
import { searchVehicles } from "store/api/api";
import { handleAxiosError } from "store/util";
import { ExpandedColumn } from "types/columns";
import { MUTATION_KEYS } from "../queryKeys";

interface ISearchVehiclesForExcelPayload {
  isFleetPage: boolean;
  search: string;
  clientIds?: number[];
  sort: any;
  isTEMP: boolean;
  vehicleStatusCode?: number;
  isTradeLicensePlate: boolean;
  fleetId?: number;
  columns: ExpandedColumn[];
  isNeedsAttention?: boolean;
}

export const searchVehiclesFn = async ({
  isFleetPage,
  search,
  clientIds,
  sort,
  isTEMP,
  vehicleStatusCode,
  isTradeLicensePlate,
  fleetId,
  columns,
  isNeedsAttention,
}: ISearchVehiclesForExcelPayload) => {
  let skip = 0;
  const limit = 1000;
  let data: any[] = [];
  const cancelSource = axios.CancelToken.source();

  const payload = isFleetPage
    ? {
        skip,
        top: limit,
        isTEMP,
        search,
        sort,
        vehicleStatusCode: vehicleStatusCode || undefined,
        isTradeLicensePlate,
        fleetId,
        columns,
        cancelToken: cancelSource.token,
      }
    : {
        skip,
        top: limit,
        isTEMP: false,
        search,
        sort,
        columns,
        clientIds,
        cancelToken: cancelSource.token,
        isNeedsAttention,
        isTradeLicensePlate,
      };

  const res: any = await searchVehicles(payload);

  data = data.concat(res.data.value);

  skip += limit;

  while (res.data["@odata.count"] > data.length) {
    const res: any = await searchVehicles({
      ...payload,
      skip,
    });

    data = data.concat(res.data.value); // append the new data to the existing data

    skip += limit; // increment the skip for the next batch
  }

  return data;
};
export function useSearchVehiclesForExportExcel() {
  return useMutation(searchVehiclesFn, {
    mutationKey: MUTATION_KEYS.searchVehiclesForExcel,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { useMutation, useQueryClient } from "react-query";
import { deleteFleetDefaultSetting } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS, QUERY_KEYS } from "../queryKeys";

type TSettings = {
  fleetId: string | number;
  fleetDefaultSettingId?: string | number;
};

const deleteFleetVehicleDefaultSetting = async ({
  fleetId,
  fleetDefaultSettingId,
}: TSettings) => {
  const response = await deleteFleetDefaultSetting(
    fleetId,
    fleetDefaultSettingId
  );

  return response?.data;
};

export function useDeleteFleetVehicleDefaultSetting() {
  const queryClient = useQueryClient();

  return useMutation(deleteFleetVehicleDefaultSetting, {
    mutationKey: MUTATION_KEYS.deleteFleetVehicleDefaultSetting,

    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.fleetVehicleDefaultSettings);
    },
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

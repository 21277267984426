import { mergeStyleSets, Spinner, SpinnerSize } from "@fluentui/react";
import ErrorMsg from "components/controls/ErrorMsg";
import { FieldContainer } from "components/controls/FieldContainer";
import debounce from "debounce-promise";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { components, mergeStyles } from "react-select";
import AsyncReactSelect from "react-select/async";
import { clientSearchByText } from "store/api/api";
import { useSelector } from "store/hooks";
import {
  clearClientFromInternalNumber,
  fetchClintByInternalNumber,
} from "store/slices/fleetFormikFormSlice";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { getSelectStyles } from "utils/utils";

const getClassNames = (theme, required, disabled) =>
  mergeStyleSets({
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      height: "100%",
    },
    label: {
      fontSize: "14px",
      fontWeight: "600",
      color: disabled ? theme.palette.neutralSecondaryAlt : theme.palette.black,
      padding: "4px 4px 0",
    },
    required: {
      color: theme.palette.redDark,
      fontSize: 14,
      marginLeft: 3,
    },
  });

const AsyncClientComboBox = ({
  name,
  label = "",
  disabled = false,
  required = false,
  isMarked = false,
}) => {
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;
  const { t } = useTranslation();
  const { setFieldValue, errors } = useFormikContext();

  const onChange = (value, props) => {
    if (props.action === "clear") {
      dispatch(clearClientFromInternalNumber(null));
      setFieldValue(name, null);
    }
    if (value) {
      dispatch(fetchClintByInternalNumber(value?.clientInternalNumber));
      setFieldValue(name, value?.clientInternalNumber);
    }
  };

  const styles = mergeStyles(getSelectStyles(!!errors, theme), {
    menuPortal: (p) => ({
      ...p,
      background: theme.palette.neutralLighter,
      zIndex: 9999999,
    }),
    menu: (p) => ({
      ...p,
      zIndex: 9999,
      background: theme.palette.neutralLighter,
    }),
    input: (p) => ({
      ...p,
      color: (function () {
        if (disabled) {
          return theme.palette.neutralSecondaryAlt;
        }
        if (isDarkMode) {
          return "white";
        }
        return theme.palette.black;
      })(),
    }),
    control: (p, state) => {
      return {
        ...p,
        background: state.isDisabled ? "transparent" : theme.palette.white,
        ...(isMarked && { background: theme.palette.yellowLight }),
        ...(isMarked && theme.isDark && { color: "black" }),

        borderBottom: hasError
          ? `1px solid rgb(164, 38, 44) !important`
          : state.isFocused
          ? `1px solid rgb(0, 90, 161) !important`
          : `1px solid ${theme.palette.black} !important`,
        boxShadow: "none",
        ...(state.isDisabled && {
          borderBottom: `1px solid ${theme.palette.neutralLighter} !important`,
        }),
        cursor: "pointer",
      };
    },

    option: (p, state) => ({
      ...p,
      background:
        state.isSelected || state.isFocused
          ? theme.palette.neutralLighter
          : theme.palette.white,
      "&:hover": {
        background: theme.palette.neutralLighter,
      },
      color: theme.palette.black,
      fontSize: "14px",
      fontWeight: 400,
    }),

    singleValue: (p, state) => ({
      ...p,
      color: state.isDisabled
        ? theme.palette.neutralSecondaryAlt
        : theme.palette.black,
      fontSize: "14px",
      fontWeight: 400,
    }),
    dropdownIndicator: (p, state) => ({
      ...p,
      ...(state.isDisabled && { visibility: "hidden" }),
    }),
    noOptionsMessage: (p, state) => ({
      ...p,
      background: theme.palette.white,
    }),
    indicatorSeparator: (p, state) => ({
      ...p,
      ...(state.isDisabled && { visibility: "hidden" }),
    }),
  });
  const classes = getClassNames(theme, required, disabled);
  const hasError = errors[name];
  const dispatch = useDispatch();
  return (
    <>
      <div className={classes.container}>
        <FieldContainer
          isTooltipHidden={disabled}
          tooltipText={label}
          isReadOnly={true}
        >
          <label
            id="aria-label"
            htmlFor="aria-example-input"
            className={classes.label}
          >
            {t(label)}
            {required && <span className={classes.required}>*</span>}
          </label>
          <AsyncReactSelect
            name={name}
            cacheOptions
            placeholder={t("greco.form.searchPlaceholder")}
            loadingMessage={() => t("greco.loading")}
            noOptionsMessage={() => t("greco.noResults")}
            styles={styles}
            onChange={onChange}
            defaultOptions={false}
            isDisabled={disabled}
            components={{
              Option: CustomOptionUser,
              Input,
              LoadingIndicator,
            }}
            isClearable={true}
            loadOptions={debounce(async (input: string) => {
              const trimmedFilter = input.trim();
              if (trimmedFilter.length === 0) return [];
              let client = await clientSearchByText(input);
              return client;
            }, 500)}
          />
          <ErrorMsg>{errors[name]}</ErrorMsg>{" "}
        </FieldContainer>
      </div>
    </>
  );
};

const Input = (props) => <components.Input {...props} maxLength={100} />;

const LoadingIndicator = () => {
  return (
    <Spinner size={SpinnerSize.medium} styles={{ root: { marginRight: 9 } }} />
  );
};

const CustomOptionUser = (props: any) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);

  const { data } = props;
  const { clientName, countryCodeId } = data;
  const country = t(taxonomy.Country.byId[countryCodeId]?.code);

  return (
    <components.Option {...props}>
      {clientName} ({country})
    </components.Option>
  );
};

export default React.memo(AsyncClientComboBox);

import { Checkbox, mergeStyles } from "@fluentui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "store/hooks";
import {
  selectWithDocumentDate,
  setWithDocumentDate,
} from "store/slices/bookingSlice";

const wrapClass = mergeStyles({
  margin: "20px 0",
});

const WithDocumentDateCheckbox = () => {
  const dispatch = useDispatch();
  const withDocumentDate = useSelector(selectWithDocumentDate);

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(setWithDocumentDate(true));
    };
  }, [dispatch]);

  return (
    <div className={wrapClass}>
      <Checkbox
        label={t("bfm.showbookingsDocumentDate.label")}
        checked={withDocumentDate}
        onChange={() => {
          dispatch(setWithDocumentDate(!withDocumentDate));
        }}
      />
    </div>
  );
};

export default WithDocumentDateCheckbox;

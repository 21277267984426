import { useMutation } from "react-query";
import { deleteTEMPVehicle } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

export const deleteTEMPVehicleFn = async ({
  fleetId,
  vehicleId,
}: {
  fleetId: string;
  vehicleId: string;
}) => {
  return await deleteTEMPVehicle(fleetId, vehicleId);
};

export function useDeleteTEMPVehicle() {
  return useMutation(deleteTEMPVehicleFn, {
    mutationKey: MUTATION_KEYS.deleteTEMPVehicle,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import NumberInputField from "components/controls/NumberInputField";
import TextInputField from "components/controls/TextInputField";
import { vehicleFormClasses } from "pages/vehicle/VehicleForm";
import React from "react";
import {
  isDisabledExternal,
  isDisabledExternalOrHasBeenActivated,
} from "../../../helpers";

const FinanceTempLegalFields = ({ formikProps, userRole, route, vehicle }) => {
  const { setFieldValue, handleChange, values, errors } = formikProps;

  return (
    <>
      <div className={vehicleFormClasses.item}>
        <DoubleNumberInputField
          setFieldValue={setFieldValue}
          value={values?.legalAnnualGrossPremium}
          onChange={handleChange}
          errors={errors}
          name={"legalAnnualGrossPremium"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.annualGrossPremium.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "legalAnnualGrossPremium"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <DoubleNumberInputField
          setFieldValue={setFieldValue}
          value={values?.legalAnnualNetPremium}
          onChange={handleChange}
          errors={errors}
          name={"legalAnnualNetPremium"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.annualNetPremium.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "legalAnnualNetPremium"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.legalSuspensionDaysLimit}
          onChange={handleChange}
          errors={errors}
          name={"legalSuspensionDaysLimit"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          isNegativeAllowed={true}
          label={"bfm.vehicleForm.legalSuspensionDaysLimit.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "legalSuspensionDaysLimit"
          )}
        />
      </div>
      <div className={vehicleFormClasses.fullWidthFormItem}>
        <TextInputField
          value={values.legalComment}
          onChange={handleChange}
          errors={errors}
          name={"legalComment"}
          multiline
          rows={4}
          disabled={isDisabledExternal({ userRole, route })}
          label={"bfm.vehicleForm.legalComment.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "legalComment"
          )}
        />
      </div>
    </>
  );
};

export default FinanceTempLegalFields;

import { useMutation } from "react-query";
import {
  ClosedVehiclePremiumRequestParams,
  continueVehiclePremium,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const continueVehiclePremiumFn = async ({
  rowVersion,
  params,
}: {
  rowVersion: string;
  params: ClosedVehiclePremiumRequestParams;
}) => {
  return await continueVehiclePremium(rowVersion, params);
};

export function useContinueVehiclePremium() {
  return useMutation(continueVehiclePremiumFn, {
    mutationKey: MUTATION_KEYS.continueVehiclePremium,
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

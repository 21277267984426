import { DefaultButton, IIconProps } from "@fluentui/react";
import { css } from "@uifabric/utilities/lib/css";
import * as React from "react";
import styles from "./Accordion.module.scss";
import { IAccordionProps, IAccordionState } from "./index";

/**
 * Icon styles. Feel free to change them
 */
const collapsedIcon: IIconProps = {
  iconName: "ChevronRight",
  className: styles.accordionChevron,
};
const collapsedIconDark: IIconProps = {
  iconName: "ChevronRight",
  className: styles.accordionChevronDark,
};
const expandedIcon: IIconProps = {
  iconName: "ChevronDown",
  className: styles.accordionChevron,
};
const expandedIconDark: IIconProps = {
  iconName: "ChevronDown",
  className: styles.accordionChevronDark,
};

export class Accordion extends React.Component<
  IAccordionProps,
  IAccordionState
> {
  private _drawerDiv: HTMLDivElement = undefined;
  constructor(props: IAccordionProps) {
    super(props);
    this.state = {
      expanded:
        props.defaultCollapsed == null ? false : !props.defaultCollapsed,
    };
  }

  public render(): React.ReactElement<IAccordionProps> {
    let exp = expandedIcon;
    if (this.props.isDark) exp = expandedIconDark;

    let col = collapsedIcon;
    if (this.props.isDark) col = collapsedIconDark;

    return (
      <div
        className={css(
          this.props.isDark ? styles.accordionDark : styles.accordion,
          this.props.className
        )}
      >
        <DefaultButton
          toggle
          checked={this.state.expanded}
          text={this.props.title}
          iconProps={this.state.expanded ? exp : col}
          onClick={() => {
            this.setState({
              expanded: !this.state.expanded,
            });
          }}
          aria-expanded={this.state.expanded}
          aria-controls={this._drawerDiv && this._drawerDiv.id}
        />
        {this.state.expanded && (
          <div
            className={styles.drawer}
            ref={(el) => {
              this._drawerDiv = el;
            }}
          >
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

import { mergeStyleSets } from "@fluentui/react";
import React from "react";

const classes = mergeStyleSets({
  insurerName: {
    cursor: "default",
    fontWeight: "bold",
  },
});

const InsurerName = ({ insurerName, insurerInternalNumber }) => {
  return (
    <span className={classes.insurerName}>
      {insurerName} ({insurerInternalNumber})
    </span>
  );
};

export default InsurerName;

import { IFieldConfig } from "../../../components/form/types";

export const modifyStatusPointDialogConfig: IFieldConfig = {
  vehicleLastStatusDate: {
    name: "vehicleLastStatusDate",
    type: "date",
    isRequired: true,
    d: ({ t, values, errors, setFieldValue }) => ({
      label: "bfm.statusDate.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.vehicleLastStatusDate,
    }),
  },
  comment: {
    name: "comment",
    type: "text",
    isMultiline: true,
    rows: 6,
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.comment.label",
    }),
  },

  validFromDate: {
    name: "validFromDate",
    type: "date",
    isRequired: true,
    d: ({ t, values, errors, setFieldValue }) => ({
      label: "bfm.vehicleForm.validFromDate.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.validFromDate,
    }),
  },
  validToDate: {
    name: "validToDate",
    type: "date",
    isRequired: true,
    d: ({ t, values, errors, setFieldValue }) => ({
      label: "bfm.vehicleForm.validToDate.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.validToDate,
    }),
  },
  reasonForModificationComment: {
    name: "reasonForModificationComment",
    type: "text",
    isMultiline: true,
    rows: 3,
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.comment.label",
    }),
  },
};

import {
  DefaultButton,
  DialogFooter,
  PrimaryButton,
  Text,
} from "@fluentui/react";
import { toast } from "@greco/components";
import { FormSpinner } from "components/FormSpinner";
import { useFleetVehicleReport } from "hooks/data/mutations/useFleetVehicleReport";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setDialog } from "store/fleetForm";
import { useSelector } from "store/hooks";
import styled from "styled-components";

export function ExportFleetDialog() {
  const { t } = useTranslation();
  const dialogData = useSelector((s) => s.fleetForm.dialog);

  const dispatch = useDispatch();

  const {
    data: excelReportResponse,
    mutate: onGenerateExcelFleetReport,
    isLoading: isReportLoading,
  } = useFleetVehicleReport();

  const excelReportData = excelReportResponse?.data;

  const close = () => {
    dispatch(setDialog({ type: "", isOpen: false }));
  };

  const { fleetId } = useParams() as any;

  const onExportExcel = () => {
    const payload = {
      reportType: dialogData?.type,
      comment: "",
      insurerPartnerNumber: null,
      premiumType: null,
      validFromDate: null,
      validToDate: null,
      vehicleIds: null,
    };

    onGenerateExcelFleetReport(
      {
        fleetId,
        body: {
          ...payload,
        },
      },
      {
        onSuccess: () => {
          toast.success(t("bfm.reportGeneratedSuccessfully.label"));
        },
      }
    );
  };

  return (
    <Container>
      {excelReportData ? (
        <>
          <PrimaryButton
            title={excelReportData.name}
            onClick={() => {
              window.open(excelReportData.url);
              close();
            }}
            text={t("bfm.downloadReport.label")}
          />
        </>
      ) : (
        <>
          <Text
            variant="medium"
            styles={{
              root: {
                display: "flex",
                width: "100%",
                paddingLeft: "10px",
              },
            }}
          >
            {t("bfm.report.warning.label")}
          </Text>

          <DialogFooter>
            <PrimaryButton
              onClick={onExportExcel}
              text={t("bfm.generateReport.label")}
              disabled={isReportLoading}
              style={{
                marginRight: "5px",
              }}
              iconProps={{
                iconName: isReportLoading ? "ProgressRingDots" : "Export",
                style: {
                  marginTop: "3px",
                },
              }}
            />
            <DefaultButton onClick={close} text={t("bfm.close.label")} />
          </DialogFooter>
        </>
      )}
      {isReportLoading && <FormSpinner />}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  padding: 10px 5px 10px 0;
`;

import { useApproveVehiclePendingStatus } from "hooks/data/mutations/useApproveVehiclePendingStatus";
import { MUTATION_KEYS } from "hooks/data/queryKeys";
import React from "react";
import { useTranslation } from "react-i18next";
import { useIsMutating } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "../../../../components/FluentToast";
import {
  loadVehiclesActions,
  setCancelVehicleStatusDialog,
  setChangeVehicleIssuingStatusDialog,
  setChangeVehiclePendingIssuingStatusDialog,
  setChangeVehicleStatusDialog,
} from "../../../../store/fleetPage";
import { useSelector } from "../../../../store/hooks";
import { AppDispatch } from "../../../../store/store";
import ContextualMenuItem from "./ContextualMenuItem";

type Props = {
  vehicle: any;
  target: any;
  onDismiss: Function;
};

const VehicleContextMenu = ({ vehicle, target, onDismiss }: Props) => {
  const isMultipleSelected = vehicle!.length > 1;
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const isUpdateVehicleStatusLoading = !!useIsMutating({
    mutationKey: [MUTATION_KEYS.changeVehicleStatus],
  });

  const {
    mutate: onApproveVehiclePendingStatus,
    isLoading: isApproveVehicleStatusLoading,
  } = useApproveVehiclePendingStatus();

  const selectedVehicleIds = useSelector((s) => s.fleetPage.selectedVehicleIds);
  const fleetId = useSelector((s) => s.fleetForm.fleetWithPremiums?.fleetId);

  const onClickChangeStatus = () => {
    dispatch(
      setChangeVehicleStatusDialog({
        isOpen: true,
        vehicleIds: selectedVehicleIds,
        fleetId: fleetId?.toString(),
        vehicle: vehicle[0],
      })
    );
  };

  const onClickChangeIssuingStatus = () => {
    dispatch(
      setChangeVehicleIssuingStatusDialog({
        isOpen: true,
        vehicleIds: selectedVehicleIds,
        fleetId: fleetId?.toString(),
      })
    );
  };

  const onClickChangePendingIssuingStatus = () => {
    dispatch(
      setChangeVehiclePendingIssuingStatusDialog({
        isOpen: true,
        vehicleIds: selectedVehicleIds,
        fleetId: fleetId?.toString(),
      })
    );
  };

  const onClickApproveStatus = () => {
    onApproveVehiclePendingStatus(
      {
        fleetId: vehicle.fleetId,

        vehicleId: vehicle[0].vehicleId,
      },
      {
        onSuccess: () => {
          dispatch(loadVehiclesActions.trigger({ isReset: true }));
          toast.success(t("bfm.success"));
        },
      }
    );
  };

  const onClickCancelStatus = () => {
    dispatch(
      setCancelVehicleStatusDialog({
        fleetId: vehicle[0].fleetId,
        vehicleId: vehicle[0].vehicleId,
        isOpen: true,
      })
    );
  };

  return (
    <ContextualMenuItem
      onDismiss={onDismiss}
      fleetId={fleetId}
      target={target}
      vehicle={isMultipleSelected ? vehicle : vehicle[0]}
      onClickChangeStatus={onClickChangeStatus}
      onClickChangeIssuingStatus={onClickChangeIssuingStatus}
      onClickChangePendingIssuingStatus={onClickChangePendingIssuingStatus}
      // onClickCorrectStatusDate={onClickCorrectStatusDate}
      onClickApproveStatus={onClickApproveStatus}
      onClickCancelStatus={onClickCancelStatus}
      isApproveVehicleStatusLoading={isApproveVehicleStatusLoading}
      isUpdateVehicleStatusLoading={isUpdateVehicleStatusLoading}
    />
  );
};

export default React.memo(VehicleContextMenu);

import { useMutation } from "react-query";
import { changeVehicleStatus } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const changeVehicleStatusFn = async (params: {
  rowVersion?: string;
  vehicleId: string;
  fleetId: string;
  vehicleStatusCode: number;
  vehicleLastStatusDate: string;
  vehicleLastStatusComment: string;
  vehicleIds?: number[];
}) => {
  return await changeVehicleStatus(params);
};

export function useChangeVehicleStatus() {
  return useMutation(changeVehicleStatusFn, {
    mutationKey: MUTATION_KEYS.changeVehicleStatus,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { IFieldConfig } from "../../form/types";

export const modifySharedLicensePlateDialogConfig: IFieldConfig = {
  premiumTypeCode: {
    name: "premiumTypeCode",
    type: "taxonomy",
    taxonomyKey: "PremiumType",
    isRequired: true,
    d: ({ t, values }) => ({
      label: "bfm.fleet.premiumType.label",
      isDisabled: true,
      isVisible: true,
    }),
  },
  licensePlate: {
    name: "licensePlate",
    type: "text",
    isRequired: true,
    isDisabled: true,
    d: ({ t }) => ({
      label: "bfm.licensePlate.label",
    }),
  },
  reasonForModificationComment: {
    name: "reasonForModificationComment",
    type: "text",
    isMultiline: true,
    rows: 3,
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.comment.label",
    }),
  },
  validFromDate: {
    name: "validFromDate",
    type: "date",
    isRequired: true,
    d: ({ t, values, errors, setFieldValue }) => ({
      label: "bfm.vehicleForm.validFromDate.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.validFromDate,
    }),
  },
  validToDate: {
    name: "validToDate",
    type: "date",
    isRequired: false,
    d: ({ t, values, errors, setFieldValue }) => ({
      label: "bfm.vehicleForm.validToDate.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.validToDate,
    }),
  },
  mainVehicleId: {
    name: "mainVehicleId",
    type: "timelineoperationvehiclecombobox",
    isRequired: true,
    isDisabled: false,
    d: ({ t }) => ({
      label: "bfm.mainVehicle.label",
    }),
  },
};

import { DefaultButton, DialogFooter, mergeStyleSets } from "@fluentui/react";
import { toast } from "components/FluentToast";
import { FormSpinner } from "components/FormSpinner";
import TextInputField from "components/controls/TextInputField";
import { Formik } from "formik";
import { useSyncClient } from "hooks/data/mutations/useSyncClient";
import { delay } from "library/delay";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setYupLocale } from "utils/setYupLocale";
import * as yup from "yup";
import { setDialog, setFleetWithPremiums } from "../../../store/fleetForm";

const SynchronizeFleetClientFormDialog = () => {
  const { t } = useTranslation();

  const { mutate: onSyncClient, isLoading } = useSyncClient();

  const dispatch = useDispatch();
  const { fleetId } = useParams() as any;

  const close = () => {
    dispatch(setDialog({ type: "", isOpen: false }));
  };

  const initialValues = {
    comment: null,
  };

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    return yup.object().shape({
      comment: yup.string().max(512).nullable(),
    });
  }, [t]);
  const classes = getClassNames();

  const onSubmit = (values) => {
    const payload = {
      comment: values?.comment,
    };

    onSyncClient(
      {
        fleetId,
        body: {
          ...payload,
        },
      },
      {
        onSuccess: (res) => {
          toast.success(t("bfm.success"));
          close();
          dispatch(setFleetWithPremiums(res.data));
        },
      }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit(values);
        await delay(400);
        setSubmitting(false);
      }}
    >
      {(props) => {
        const { values, handleSubmit, handleChange, errors } = props;

        return (
          <>
            <div className={classes.main}>
              <form onSubmit={handleSubmit}>
                <div className={classes.fieldItem}>
                  <TextInputField
                    value={values.comment}
                    onChange={handleChange}
                    errors={errors}
                    name={"comment"}
                    multiline
                    rows={4}
                    disabled={false}
                    label={"bfm.comment.label"}
                  />
                </div>
                <DialogFooter className={classes.footer}>
                  <DefaultButton
                    primary
                    text={t("greco.save")}
                    type="submit"
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </DialogFooter>
                {isLoading && <FormSpinner />}
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const getClassNames = () =>
  mergeStyleSets({
    main: {},
    fieldItem: {
      width: "100%",
      marginTop: 20,
    },
    footer: {
      marginTop: 50,
    },
  });

export default SynchronizeFleetClientFormDialog;

import { FieldConfig } from "components/form/types";

export const config: FieldConfig[] = [
  {
    name: "vehicleIssuingStatusCode",
    type: "taxonomy",
    taxonomyKey: "VehicleIssuingStatus",
    isRequired: true,
    d: ({ t }) => ({
      label: "bfm.vehicleForm.vehicleIssuingStatusCode.label",
    }),
  },
  {
    name: "vehicleIssuingSubstatusCode",
    type: "taxonomy",
    taxonomyKey: "VehicleIssuingSubstatus",
    d: ({ t, values, taxonomy }) => {
      const incorrectId =
        taxonomy.VehicleIssuingStatus.byCode["VehicleIssuingStatus.INCORRECT"]
          ?.id;

      return {
        label: "bfm.vehicleForm.vehicleIssuingSubstatusCode.label",
        isVisible:
          !!values.vehicleIssuingStatusCode &&
          values.vehicleIssuingStatusCode.value === incorrectId,
        isRequired:
          !!values.vehicleIssuingStatusCode &&
          values.vehicleIssuingStatusCode.value === incorrectId,
      };
    },
  },
  {
    name: "vehicleFirstIssuingStatusDate",
    type: "date",
    d: ({ t, values, errors, setFieldValue }) => ({
      label: "bfm.vehicleForm.vehicleFirstIssuingStatusDate.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.vehicleFirstIssuingStatusDate,
    }),
  },
  {
    name: "vehicleLastIssuingStatusDate",
    type: "date",
    d: ({ t, values, errors, setFieldValue }) => ({
      label: "bfm.vehicleForm.vehicleLastIssuingStatusDate.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.vehicleLastIssuingStatusDate,
    }),
  },
  {
    name: "vehicleIssuingComment",
    type: "text",
    isMultiline: true,
    rows: 7,
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.vehicleForm.vehicleIssuingComment.label",
    }),
  },
];

import { AppSettingsType } from "AppSettings";
import _ from "lodash";
import moment from "moment";
import { TaxonomyState } from "../../../store/taxonomy";
import {
  Fleet,
  Insurer,
  PremiumType,
  SharedLicensePlate,
  Timeline,
  TimelineDomain,
  TimelineProrata,
  TimelineSegment,
  TimelineSegmentType,
  TimelineType,
  Vehicle,
  VehiclePremiumBookingData,
} from "../../../types/types";
import { prepareLegend } from "./prepareLegend";
import { prepareLicensePlateSegments } from "./prepareLicensePlateSegments";
import {
  prepareBookingSegments,
  prepareOneTimePremiumSegments,
  preparePolicyStatusSegments,
  preparePremiumSegments,
} from "./preparePremiumSegments";
import {
  determineSharedLicensePlateObject,
  prepareSharedLicensePlateSegments,
  prepareSharedLicensePlateStatusSegments,
} from "./prepareSharedLicensePlateSegments";
import { prepareStatusSegments } from "./prepareStatusSegments";

export const statusDomain = [PremiumType.STATUS];

export type PrepareTimelineParams = {
  types: TimelineType[];

  vehicle: Vehicle;
  vehiclePremiumBookingData: VehiclePremiumBookingData;
  vehicleFleet: Fleet;
  insurerList: Insurer[];

  fleet: Fleet;
  sharedLicensePlateVehicles: SharedLicensePlate[];
  showBookingsWithDocumentDate: boolean;

  taxonomy: TaxonomyState;
  readOnly: boolean;
  t: any;
  appSettings: AppSettingsType;
};

export const prepareTimeline = (
  prepareTimelineParams: PrepareTimelineParams
): Timeline => {
  const timeline: Timeline = {
    startDate: null,
    endDate: null,
    domain: null,
    data: null,
    prorata: null,
    bookingTotals: null,
    dateFilter: null,
    filteredData: null,
    legend: null,
    insurerList: null,
    showBookings: prepareTimelineParams.types.includes(
      TimelineType.VEHICLE_PREMIUM_BOOKINGS
    )
      ? true
      : false,
    showBookingsWithDocumentDate: prepareTimelineParams.types.includes(
      TimelineType.VEHICLE_PREMIUM_BOOKINGS
    )
      ? prepareTimelineParams.showBookingsWithDocumentDate
      : false,
  };
  const timelineRange: [Date, Date] = determineTimeRange(prepareTimelineParams);
  timeline.startDate = timelineRange[0];
  timeline.endDate = timelineRange[1];
  timeline.dateFilter = timelineRange;
  timeline.domain = determineDomain(prepareTimelineParams);

  timeline.data = prepareData(timeline, prepareTimelineParams);
  timeline.filteredData = timeline.data;

  timeline.prorata = prepareProrata(timeline, prepareTimelineParams);
  timeline.bookingTotals = prepareBookingTotals(
    timeline,
    prepareTimelineParams
  );

  timeline.legend = prepareLegend(timeline, prepareTimelineParams);
  timeline.insurerList = prepareTimelineParams.insurerList;
  return timeline;
};

function prepareData(
  timeline: Timeline,
  params: PrepareTimelineParams
): TimelineSegment[] {
  const retVal: TimelineSegment[] = [];
  if (params.types.includes(TimelineType.VEHICLE_PREMIUM)) {
    retVal.push(
      ...preparePremiumSegments(
        timeline,
        params.vehicle,
        params.insurerList,
        params.taxonomy,
        params.fleet,
        params.appSettings
      )
    );
    if (params.types.includes(TimelineType.VEHICLE_PREMIUM_BOOKINGS)) {
      retVal.push(
        ...prepareBookingSegments(
          params.vehicle,
          params.vehiclePremiumBookingData,
          params.taxonomy,
          params.showBookingsWithDocumentDate,
          params.appSettings
        )
      );
    }
    if (params.types.includes(TimelineType.VEHICLE_POLICY_STATUSES)) {
      retVal.push(
        ...preparePolicyStatusSegments(
          timeline,
          params.vehicle,
          params.insurerList,
          params.taxonomy,
          params.fleet,
          params.appSettings
        )
      );
    }

    retVal.push(
      ...prepareOneTimePremiumSegments(
        timeline,
        params.vehicle,
        params.taxonomy
      )
    );
  }

  if (params.types.includes(TimelineType.VEHICLE_STATUS)) {
    retVal.push(
      ...prepareStatusSegments(timeline, params.vehicle, params.taxonomy)
    );
  }

  if (params.types.includes(TimelineType.SHARED_LICENSE_PLATE_STATUS)) {
    const segments = prepareSharedLicensePlateStatusSegments(
      timeline,
      determineSharedLicensePlateObject(params),
      params.types
    );
    retVal.push(...segments);
    retVal.push(
      ...prepareSharedLicensePlateSegments(
        timeline,
        segments,
        determineSharedLicensePlateObject(params),
        params.taxonomy,
        params.types
      )
    );
  }
  if (params.types.includes(TimelineType.VEHICLE_LICENSE_PLATE)) {
    retVal.push(...prepareLicensePlateSegments(timeline, params.vehicle));
  }

  return retVal;
}

const determineFleetObject = (
  prepareTimelineParams: PrepareTimelineParams
): Fleet => {
  if (prepareTimelineParams.types.includes(TimelineType.VEHICLE_PREMIUM)) {
    return prepareTimelineParams.vehicleFleet;
  }
  if (
    prepareTimelineParams.types.includes(
      TimelineType.SHARED_LICENSE_PLATE_STATUS
    )
  ) {
    return prepareTimelineParams.fleet;
  }
};

const determineTimeRange = (params: PrepareTimelineParams): [Date, Date] => {
  const fleet = determineFleetObject(params);
  let startDate = null;
  let initialStartDate = moment().subtract(1, "months").toDate();
  let endDate = moment(fleet?.maxCalculationDate).toDate();
  const startDatesList: Date[] = [initialStartDate];
  const endDatesList: Date[] = [endDate];
  if (params.types.includes(TimelineType.VEHICLE_PREMIUM)) {
    params.vehicle?.vehiclePremiumSegmentHistoryData?.forEach((el) => {
      startDatesList.push(moment(el.validFromDate).toDate());
      endDatesList.push(moment(el.proRataValidToDate).toDate());
    });
    const bookings = [
      ...params.vehiclePremiumBookingData?.vehicleMtplPremiumBookingData
        ?.vehiclePremiumBooking,
      ...params.vehiclePremiumBookingData?.vehicleAccidentPremiumBookingData
        ?.vehiclePremiumBooking,
      ...params.vehiclePremiumBookingData?.vehicleAssistancePremiumBookingData
        ?.vehiclePremiumBooking,
      ...params.vehiclePremiumBookingData?.vehicleLegalPremiumBookingData
        ?.vehiclePremiumBooking,
      ...params.vehiclePremiumBookingData?.vehicleEngineTaxPremiumBookingData
        ?.vehiclePremiumBooking,
      ...params.vehiclePremiumBookingData?.vehicleHullPremiumBookingData
        ?.vehiclePremiumBooking,
      ...params.vehiclePremiumBookingData?.vehicleHullBasicPremiumBookingData
        ?.vehiclePremiumBooking,
    ];
    bookings.forEach((el) => {
      endDatesList.push(moment(el.premiumBookingDate).toDate());
    });
  }
  if (params.types.includes(TimelineType.VEHICLE_STATUS)) {
    params.vehicle?.vehicleStatusHistoryData?.forEach((el) =>
      startDatesList.push(moment(el.statusDate).toDate())
    );
  }
  if (params.types.includes(TimelineType.SHARED_LICENSE_PLATE_STATUS)) {
    params.sharedLicensePlateVehicles =
      determineSharedLicensePlateObject(params);
    params.sharedLicensePlateVehicles.forEach((licensePlate) => {
      licensePlate.historyInfo.forEach((history) => {
        startDatesList.push(moment(history.validFromDate).toDate());
        endDatesList.push(moment(history.validToDate).toDate());
      });
      licensePlate.licensePlateSharedDateRangeInfo.forEach((history) => {
        startDatesList.push(moment(history.validFromDate).toDate());
        endDatesList.push(moment(history.validToDate).toDate());
      });
    });
  }
  if (params.types.includes(TimelineType.VEHICLE_LICENSE_PLATE)) {
    params.vehicle.vehicleLicensePlateHistoryData.forEach((licensePlate) => {
      startDatesList.push(moment(licensePlate.validFromDate).toDate());
      endDatesList.push(moment(licensePlate.validToDate).toDate());
    });
  }
  startDate = _.min(startDatesList);
  endDate = _.max(endDatesList);
  // Add month around dates
  if (startDate.getTime() < initialStartDate.getTime()) {
    startDate = moment(startDate).subtract(1, "month").toDate();
  }
  endDate = moment(endDate).add(1, "month").toDate();
  return [startDate, endDate];
};

function determineDomain(params: PrepareTimelineParams): TimelineDomain[] {
  const retVal: TimelineDomain[] = [];
  if (params.types.includes(TimelineType.VEHICLE_PREMIUM)) {
    const premiumDomain = params.appSettings.VEHICLE_PREMIUM_DOMAINS;
    for (let domainValue of premiumDomain) {
      if (
        params.vehicle.isTradeLicensePlate &&
        domainValue === PremiumType.HORSE_POWER
      ) {
        continue;
      }
      retVal.push({
        type: TimelineType.VEHICLE_PREMIUM,
        value: domainValue,
      });
    }
  }
  if (params.types.includes(TimelineType.VEHICLE_STATUS)) {
    retVal.push({
      type: TimelineType.VEHICLE_STATUS,
      value: PremiumType.STATUS,
    });
  }

  if (params.types.includes(TimelineType.VEHICLE_LICENSE_PLATE)) {
    retVal.push({
      type: TimelineType.VEHICLE_LICENSE_PLATE,
      value: PremiumType.LICENSE_PLATE,
    });
  }
  if (params.types.includes(TimelineType.SHARED_LICENSE_PLATE_STATUS)) {
    params.sharedLicensePlateVehicles.forEach((licensePlate) => {
      // if (!params.types.includes(TimelineType.VEHICLE_PREMIUM)) {
      if (
        !params.types.includes(TimelineType.VEHICLE_PREMIUM) ||
        licensePlate.licensePlateSharedDateRangeInfo.length > 0
      ) {
        retVal.push({
          type: TimelineType.SHARED_LICENSE_PLATE_STATUS,
          value: licensePlate.licensePlate + "#" + licensePlate.licensePlate,
        });
        // }
        retVal.push({
          type: TimelineType.SHARED_LICENSE_PLATE_MTPL,
          value:
            "TimelineType.SHARED_LICENSE_PLATE_MTPL" +
            "#" +
            licensePlate.licensePlate,
        });
        retVal.push({
          type: TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX,
          value:
            "TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX" +
            "#" +
            licensePlate.licensePlate,
        });
      }
    });
  }
  return retVal;
}

const currentProRataFields = {
  "PremiumType.MTPL": "mtplProRataGrossPremium",
  "PremiumType.HORSE_POWER": "horsePowerBasedTaxProRata",
  "PremiumType.ACCIDENT": "accidentProRataGrossPremium",
  "PremiumType.ASSISTANCE": "assistanceProRataGrossPremium",
  "PremiumType.LEGAL": "legalProRataGrossPremium",
  "PremiumType.HULL": "hullProRataGrossPremium",
  //debugger
  "PremiumType.FLEETLEGAL_PRORATA": "fleetLegalProRataGrossPremium",
  //debugger
};
function prepareProrata(
  timeline: Timeline,
  prepareTimelineParams: PrepareTimelineParams
): TimelineProrata[] {
  const retVal: TimelineProrata[] = [];
  if (!prepareTimelineParams.types.includes(TimelineType.VEHICLE_PREMIUM)) {
    return retVal;
  }
  const vehicle = prepareTimelineParams.vehicle;

  const premiumDomain =
    prepareTimelineParams.appSettings.VEHICLE_PREMIUM_DOMAINS;
  premiumDomain.forEach((domain) => {
    if (
      prepareTimelineParams.vehicle.isTradeLicensePlate &&
      domain === PremiumType.HORSE_POWER
    ) {
      return;
    }

    const currentProrataField = vehicle[currentProRataFields[domain]];
    const overallProrataField = timeline.data
      .filter(
        (segment) =>
          (segment.segmentType === TimelineSegmentType.PREMIUM ||
            segment.segmentType === TimelineSegmentType.SUSPENSION) &&
          segment.domain.value === domain &&
          segment.associatedObject
      )
      .reduce(
        (accumulator, current) =>
          accumulator +
          (current.associatedObject.totalProRataPremium === null
            ? 0
            : current.associatedObject.totalProRataPremium),
        0
      );
    retVal.push({
      premiumType: domain,
      current: currentProrataField === null ? 0 : currentProrataField,
      overall: overallProrataField === null ? 0 : overallProrataField,
    });
  });
  const totalCurrent = retVal.reduce(
    (accumulator, current) => accumulator + current.current,
    0
  );
  const totalOverall = retVal.reduce(
    (accumulator, current) => accumulator + current.overall,
    0
  );
  retVal.push({
    premiumType: null,
    current: totalCurrent,
    overall: totalOverall,
  });

  return retVal;
}

function prepareBookingTotals(
  timeline: Timeline,
  prepareTimelineParams: PrepareTimelineParams
): TimelineProrata[] {
  const retVal: TimelineProrata[] = [];
  if (!prepareTimelineParams.types.includes(TimelineType.VEHICLE_PREMIUM)) {
    return retVal;
  }
  const bookingData = prepareTimelineParams.vehiclePremiumBookingData;

  const vehiclePremiumTypes =
    prepareTimelineParams.appSettings.VEHICLE_PREMIUM_TYPES;

  if (vehiclePremiumTypes.includes(PremiumType.MTPL)) {
    retVal.push({
      premiumType: PremiumType.MTPL,
      current:
        bookingData.vehicleMtplPremiumBookingData
          .totalBookedAmountPerCurrentPolicyCoverPeriod,
      overall: bookingData.vehicleMtplPremiumBookingData.totalBookedAmount,
    });
  }
  if (!prepareTimelineParams.vehicle.isTradeLicensePlate) {
    if (vehiclePremiumTypes.includes(PremiumType.HORSE_POWER)) {
      retVal.push({
        premiumType: PremiumType.HORSE_POWER,
        current:
          bookingData.vehicleEngineTaxPremiumBookingData
            .totalBookedAmountPerCurrentPolicyCoverPeriod,
        overall:
          bookingData.vehicleEngineTaxPremiumBookingData.totalBookedAmount,
      });
    }
  }
  if (vehiclePremiumTypes.includes(PremiumType.ACCIDENT)) {
    retVal.push({
      premiumType: PremiumType.ACCIDENT,
      current:
        bookingData.vehicleAccidentPremiumBookingData
          .totalBookedAmountPerCurrentPolicyCoverPeriod,
      overall: bookingData.vehicleAccidentPremiumBookingData.totalBookedAmount,
    });
  }
  if (vehiclePremiumTypes.includes(PremiumType.ASSISTANCE)) {
    retVal.push({
      premiumType: PremiumType.ASSISTANCE,
      current:
        bookingData.vehicleAssistancePremiumBookingData
          .totalBookedAmountPerCurrentPolicyCoverPeriod,
      overall:
        bookingData.vehicleAssistancePremiumBookingData.totalBookedAmount,
    });
  }
  if (vehiclePremiumTypes.includes(PremiumType.LEGAL)) {
    retVal.push({
      premiumType: PremiumType.LEGAL,
      current:
        bookingData.vehicleLegalPremiumBookingData
          .totalBookedAmountPerCurrentPolicyCoverPeriod,
      overall: bookingData.vehicleLegalPremiumBookingData.totalBookedAmount,
    });
  }
  if (vehiclePremiumTypes.includes(PremiumType.HULL)) {
    retVal.push({
      premiumType: PremiumType.HULL,
      current:
        bookingData.vehicleHullPremiumBookingData
          .totalBookedAmountPerCurrentPolicyCoverPeriod +
        bookingData.vehicleHullBasicPremiumBookingData
          .totalBookedAmountPerCurrentPolicyCoverPeriod,
      overall:
        bookingData.vehicleHullPremiumBookingData.totalBookedAmount +
        bookingData.vehicleHullBasicPremiumBookingData.totalBookedAmount,
    });
  }
  if (vehiclePremiumTypes.includes(PremiumType.FLEETLEGAL_PRORATA)) {
    retVal.push({
      premiumType: PremiumType.FLEETLEGAL_PRORATA,
      current:
        bookingData.vehicleFleetLegalProRataPremiumBookingData
          .totalBookedAmountPerCurrentPolicyCoverPeriod +
        bookingData.vehicleFleetLegalClosingDatePremiumBookingData
          .totalBookedAmountPerCurrentPolicyCoverPeriod,
      overall:
        bookingData.vehicleFleetLegalProRataPremiumBookingData
          .totalBookedAmount +
        bookingData.vehicleFleetLegalClosingDatePremiumBookingData
          .totalBookedAmount,
    });
  }
  retVal.push({
    premiumType: null,
    current: bookingData.totalBookedAmountPerCurrentPolicyCoverPeriod,

    overall: bookingData.totalBookedAmount,
  });

  return retVal;
}

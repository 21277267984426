import ComboBoxField from "components/controls/ComboBoxField";
import { useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "store/hooks";

type Props = {
  disabled: Boolean;
  defaultValue: string | number;
};

function BranchOfficeField({ defaultValue, disabled }: Props) {
  const { values, errors, setFieldValue } = useFormikContext();
  const insurerList = useSelector((s) => s.fleetForm.insurerList);

  const formInsurer = values["insurerPartnerNumber"];
  const formBranchOffice = values["insurerBranchOffice"];

  const options = useMemo(() => {
    const selectedInsurer =
      insurerList &&
      insurerList?.find(
        (insurer) => insurer?.insurerInternalNumber === formInsurer?.value
      );
    const reportInsurerBranchOffices =
      selectedInsurer?.reportInsurerBranchOffices;

    const options = reportInsurerBranchOffices
      ? reportInsurerBranchOffices.map((tax) => {
          return {
            value: tax.branchOfficeInternalNumber,
            label:
              tax.branchOfficeName +
              " (" +
              tax.branchOfficeInternalNumber +
              ")",
          };
        })
      : [];

    return options;
  }, [insurerList, formInsurer]);

  const defaultFieldValue = useMemo(() => {
    return options.find((option) => option.value === Number(defaultValue));
  }, [options, defaultValue]);

  useEffect(() => {
    if (!formInsurer && formBranchOffice) {
      setFieldValue("formBranchOffice", null);
    }
  }, [formInsurer, formBranchOffice, setFieldValue]);

  return (
    <ComboBoxField
      key={formInsurer?.value}
      options={options}
      errors={errors}
      name={"insurerBranchOffice"}
      disabled={disabled}
      //   isNegativeAllowed={true}
      label={"bfm.vehicleForm.insurerBranchOffice.label"}
      setFieldValue={setFieldValue}
      defaultValue={defaultFieldValue}
      confidentiality={null}
    />
  );
}

export default BranchOfficeField;

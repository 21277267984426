import {
  ErrorMessage,
  GlobalStyles,
  GrecoSpinner,
  ThemeProvider,
} from "@greco/components";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { useQuery } from "hooks/useQuery";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import styled, {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
} from "styled-components";
import { darkStyledTheme, styledTheme } from "theme";
import Logo from "../src/assets/GrECo_Logo_small.jpg";
import AppProvider from "./AppProvider";
import backgroundImage from "./assets/GrECoGraphicTriangles.svg";
import AppHeader from "./components/header/AppHeader";
import { IHeaderConfig, headerConfig } from "./config/headerConfig";
import { routes } from "./config/routes";
import { getUserPhotoSmall, logout } from "./store/api/GraphService";
import { UserDataLoader } from "./store/api/UserDataLoader";
import { loadFleetsActions } from "./store/fleetsPage";
// import { history } from "./store/store";
import AppSettings from "AppSettings";
import { useGetClientList } from "hooks/data/queries/useGetClientList";
import { setUserAppSettings } from "store/auth";
import { useSelector } from "store/hooks";
import { loadTaxonomyActions, loadTaxonomyUADActions } from "./store/taxonomy";

const reactPlugin: any = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env
      .REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY as string,
    extensions: [reactPlugin],
    // extensionConfig: {
    //   [reactPlugin.identifier]: {
    //     history: createBrowserHistory({ basename: "" }),
    //   },
    // },
  },
});

interface IAppState {
  headerConfig: IHeaderConfig;
  panelIsOpen: boolean;
  user: any;
  error: string;
  loading: boolean;
  search: string;
  appInsights: ApplicationInsights;
}

export const App = () => {
  const [state, setState] = useState<IAppState>({
    headerConfig: headerConfig,
    panelIsOpen: false,
    user: {},
    error: "",
    loading: false,
    search: "",
    appInsights: appInsights,
  });

  const dispatch = useDispatch();

  useGetClientList();

  const query = useQuery();
  const clientId = query.get("clientId");

  useEffect(() => {
    dispatch(loadTaxonomyUADActions.trigger());
  }, []);

  useEffect(() => {
    dispatch(loadFleetsActions.trigger({ clientId }));
  }, [clientId]);

  const userCountryCode = useSelector((s) => s.auth.userCountryCode);
  const taxonomy = useSelector((s) => s.taxonomy);

  useEffect(() => {
    if (!userCountryCode) return;
    const userAppSettingsCountryCode =
      AppSettings.getInstance().getAppSettingsTypes()[
        taxonomy.Country.byId[userCountryCode]?.code
      ];
    dispatch(setUserAppSettings(userAppSettingsCountryCode));
  }, [taxonomy, userCountryCode]);

  const getUserData = () => {
    setState({ ...state, loading: true });
    Promise.all([UserDataLoader.getUserData(), getUserPhotoSmall()])
      .then((values: any) => {
        return { ...values[0], photoSmall: values[1] };
      })
      .then((results: any) => {
        setState({ ...state, user: results, loading: false });
      })
      .catch((err) => {
        errorHendler(err);
      });
  };

  const isDarkMode = Boolean(state.headerConfig.darkMode);
  const tooltipsStatus = Boolean(state.headerConfig.tooltipsStatus);

  const closeErrorMessage = (): void => {
    setState({ ...state, error: "" });
  };

  const panelStatus = (status: boolean): void => {
    setState({ ...state, panelIsOpen: status });
  };

  useEffect(() => {
    setState({ ...state, panelIsOpen: false });
  }, [isDarkMode, tooltipsStatus]);

  const searchOnChange = (value: any): void => {
    setState({ ...state, search: value });
  };

  const searchOnSearch = (value: string): void => {
    alert(value);
  };

  const errorHendler = (value: string): void => {
    setState({ ...state, error: value });
  };

  useEffect(() => {
    const isDarkMode =
      localStorage.getItem("darkMode") === "true" ? true : false;
    localStorage.setItem("darkMode", String(isDarkMode));

    const tooltipsStatus =
      localStorage.getItem("tooltipsStatus") === "true" ? true : false;
    localStorage.setItem("tooltipsStatus", String(tooltipsStatus));
  }, []);

  useEffect(() => {
    getUserData();

    appInsights.loadAppInsights();
    appInsights.trackPageView();

    dispatch(loadTaxonomyActions.trigger());
  }, []);

  return (
    <Suspense fallback={<GrecoSpinner />}>
      <BrowserRouter>
        {/* <ConnectedRouter history={history}> */}
        <StyledThemeProvider theme={isDarkMode ? darkStyledTheme : styledTheme}>
          <ThemeProvider isDarkMode={isDarkMode}>
            {state.loading && <GrecoSpinner />}
            {state.error && (
              <ErrorMessage
                message={state.error}
                closeError={closeErrorMessage}
              />
            )}
            <AppProvider tooltipsStatus={state.headerConfig.tooltipsStatus}>
              {state.user && (
                <AppHeader
                  user={state.user}
                  headerConfig={state.headerConfig}
                  logo={Logo}
                  logout={logout}
                  tooltipsStatus={state.headerConfig.tooltipsStatus}
                  tooltipStatusChange={(tooltipsStatus) => {
                    const checked = Boolean(
                      tooltipsStatus.target.ariaChecked === "true"
                        ? false
                        : true
                    );
                    localStorage.setItem("tooltipsStatus", String(checked));
                    setState({
                      ...state,
                      headerConfig: {
                        ...state.headerConfig,
                        tooltipsStatus: checked,
                      },
                    });
                  }}
                  panelStatus={panelStatus}
                  searchOnChange={searchOnChange}
                  searchOnSearch={searchOnSearch}
                  darkMode={(isDarkMode) => {
                    localStorage.setItem("darkMode", String(isDarkMode));
                    setState({
                      ...state,
                      headerConfig: {
                        ...state.headerConfig,
                        darkMode: isDarkMode,
                      },
                    });
                  }}
                />
              )}

              <AppWrapper
                isdarkmode={isDarkMode === true ? "true" : "false"}
                isopen={state.panelIsOpen === true ? "true" : "false"}
                id="app-wrapper"
              >
                <Routes>
                  {Object.keys(routes).map((key) => {
                    const route = routes[key];
                    return (
                      <Route
                        key={key}
                        path={route.path}
                        element={<route.component />}
                      />
                    );
                  })}
                  {/* <Navigate to="/" /> */}
                </Routes>
              </AppWrapper>
              <GlobalStyles />
              <GlobalStyle />
              <ToastContainer />
            </AppProvider>
          </ThemeProvider>
        </StyledThemeProvider>
        {/* </ConnectedRouter> */}
      </BrowserRouter>
    </Suspense>
  );
};

interface IAppWrapperProps {
  isopen: string;
  isdarkmode: string;
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: white;
  }
  input:-internal-autofill-selected{
    background-color: white !important;
    background-image: none !important;
  }

  .Toastify__toast-container {
    padding: 0;

    .Toastify__toast--default {
    }
    .Toastify__toast--info {
    }
    .Toastify__toast--success {
      .Toastify__progress-bar {
        background-color: green;
      }
    }
    .Toastify__toast--warning {
      .Toastify__progress-bar {
        background-color: orange;
      }
    }
    .Toastify__toast--error {
      .Toastify__progress-bar {
        background-color: #c50f1f;
      }
    }
    .Toastify__toast--error {
    }
    .Toastify__toast {
      min-height: unset;
      padding: 0;
      border: 1px solid #ccc;
    }
    .Toastify__close-button {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      padding: 0 10px;
      color: black;
    }
  }
`;

const AppWrapper = styled.div<IAppWrapperProps>`
  background-image: url(${backgroundImage});
  background-color: ${(props) =>
    props.isdarkmode === "true" ? "#151515" : "#f8f9fe"};
  position: absolute;
  // overflow: auto;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: auto;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
  width: ${(props) =>
    props.isopen === "true" ? "calc(100% - 340px)" : "100%"};
`;

export default App;

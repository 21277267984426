import { pointer } from "d3";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { useSelector } from "../../../../../store/hooks";
import { openContextMenu } from "../../../../../store/timeline";
import {
  ETimelineExtraDataLineSpecial,
  PremiumType,
  TimelineContextMenuType,
  TimelineLegendSegmentItemType,
  TimelineSegment,
  TimelineSegmentType,
  TimelineType,
} from "../../../../../types/types";
import { formatNumber } from "../../../../../utils/number";
import { invertColor, truncateTextIfLong } from "../../../../../utils/utils";
import { InfinityArrow } from "./InfinityArrow";
import { PremiumSegmentPolicyNameTooltip } from "./PremiumSegmentPolicyNameTooltip";
import { PremiumSegmentTooltip } from "./PremiumSegmentTooltip";

export const PremiumSegments = ({ xScale, yScale, innerWidth }) => {
  const [labelCopied, setLabelCopied] = useState("");
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;
  const dispatch = useDispatch();
  const data = useSelector((s) => s.timeline.timeline.filteredData);
  const endDate = useSelector((s) => s.timeline.timeline.endDate);
  const legend = useSelector((s) => s.timeline.timeline.legend);
  const taxonomy = useSelector((s) => s.taxonomy);
  const { t } = useTranslation();
  const appSettings = useSelector((s) => s.timeline.timelineAppSettings);

  if (!data) return null;
  const premiumLegend = legend.find(
    (el) => el.segmentType === TimelineType.VEHICLE_PREMIUM
  );
  const insurerItems = premiumLegend?.items?.filter(
    (item) => item.itemType === TimelineLegendSegmentItemType.PREMIUM_INSURER
  );
  const determineSegmentsForPolicies = (data: TimelineSegment[]) => {
    const segmentsForPolicies = data
      .filter((segment) => {
        const segmentDomainType = segment.domain.type;
        const segmentType = segment.segmentType;
        const segmentDomainValue = segment.domain.value;
        return (
          segmentDomainType === TimelineType.VEHICLE_PREMIUM &&
          (segmentType === TimelineSegmentType.SUSPENSION ||
            segmentType === TimelineSegmentType.PREMIUM) &&
          (segmentDomainValue === PremiumType.MTPL ||
            segmentDomainValue === PremiumType.HULL ||
            segmentDomainValue === PremiumType.HULL_BASIC ||
            segmentDomainValue === PremiumType.FLEETLEGAL_PRORATA ||
            segmentDomainValue === PremiumType.FLEETLEGAL_CLOSINGDATE)
        );
      })
      .map((d, index) => {
        return {
          premiumType: d.domain.value,
          startDate: moment(d.associatedObject.policyValidFromDate).toDate(),
          endDate: d.associatedObject.policyValidToDate
            ? moment(d.associatedObject.policyValidToDate).toDate()
            : moment(endDate).toDate(),
          policyNumber: d.associatedObject.insurerPolicyNumber,
          insurer: d.extraData.insurer,
          insurerInternalNumber: d.extraData.insurerInternalNumber,
          branchOffice: d.extraData.branchOffice,
          branchOfficeInternalNumber: d.extraData.branchOfficeInternalNumber,
          isPolicyIssued: d.associatedObject.isPolicyIssued,
        };
      });
    const uniqueSegmentsForPolicies = [];
    segmentsForPolicies.forEach((segment) => {
      let exists = false;
      uniqueSegmentsForPolicies.forEach((uniqueSeg) => {
        if (
          uniqueSeg.premiumType === segment.premiumType &&
          uniqueSeg.startDate.getTime() === segment.startDate.getTime() &&
          uniqueSeg.policyNumber === segment.policyNumber &&
          uniqueSeg.insurer === segment.insurer
        ) {
          exists = true;
        }
      });
      if (!exists) {
        uniqueSegmentsForPolicies.push(segment);
      }
    });
    return uniqueSegmentsForPolicies;
  };

  const segmentsForPolicies = determineSegmentsForPolicies(data);

  const policyStarts = segmentsForPolicies.map((segment, index) => {
    let x1 = xScale(segment.startDate);
    let x2 = xScale(segment.endDate);
    if (x1 < 0) x1 = 0;
    if (x2 > innerWidth) x2 = innerWidth;

    const textValue = truncateTextIfLong(
      `${segment.policyNumber} (${segment.insurer})`,
      x2 - x1,
      5
    );

    const copiedToClipboard =
      labelCopied === segment.policyNumber.toString()
        ? ` - ${t("bfm.copied.label")}`
        : "";

    return (
      <g key={`title_${index}`}>
        <PremiumSegmentPolicyNameTooltip key={index} d={segment}>
          <text
            data-testid="copy-policy-number"
            onClick={() => {
              navigator?.clipboard.writeText(segment.policyNumber);
              setLabelCopied(segment.policyNumber);
            }}
            onBlur={() => {
              setLabelCopied("");
            }}
            fill={
              !segment.isPolicyIssued
                ? "#ff0000"
                : isDarkMode
                ? "#ffffff"
                : "#000000"
            }
            style={{
              textAnchor: "start",
              fontSize: "0.60em",
              cursor: "pointer",
            }}
            x={x1 + 6}
            dy=".32em"
            y={yScale(segment.premiumType) - (2 * yScale.bandwidth()) / 5}
          >
            &#128203;
            {`${textValue} ${copiedToClipboard}`}
          </text>
        </PremiumSegmentPolicyNameTooltip>

        <line
          x1={x1}
          x2={x1}
          y1={yScale(segment.premiumType) - (2 * yScale.bandwidth()) / 5}
          y2={
            yScale(
              segment.premiumType === PremiumType.MTPL &&
                appSettings?.VEHICLE_PREMIUM_DOMAINS.includes(PremiumType.LEGAL)
                ? PremiumType.LEGAL
                : segment.premiumType
            ) + yScale.bandwidth()
          }
          stroke={isDarkMode ? "#888888" : "#888888"}
          strokeWidth={0.75}
        />
        <line
          x1={x1}
          x2={x1 + 3}
          y1={yScale(segment.premiumType) - (2 * yScale.bandwidth()) / 5}
          y2={yScale(segment.premiumType) - (2 * yScale.bandwidth()) / 5}
          stroke={isDarkMode ? "#888888" : "#888888"}
          strokeWidth={0.75}
        />
      </g>
    );
  });

  const segments = data
    .filter((segment) => segment.domain.type === TimelineType.VEHICLE_PREMIUM)
    .map((d, index) => {
      let segmentClass = "";
      let fillColor = "";
      let strokeColor = "";
      let strokeWidth = "";

      if (d.segmentType === TimelineSegmentType.PREMIUM) {
        const insurerItem = insurerItems.find(
          (item) => item.label === d.extraData.insurer
        );
        segmentClass = "mark";
        fillColor = insurerItem.itemAttributes.fillColor;
        strokeColor = insurerItem.itemAttributes.strokeColor;
        strokeWidth = "3";
        if (d.associatedObject.totalProRataPremium === 0) {
          segmentClass = segmentClass + " transparent_segment";
        }
        if (isDarkMode) {
          fillColor = invertColor(fillColor);
          strokeColor = invertColor(strokeColor);
        }
      } else if (d.segmentType === TimelineSegmentType.SUSPENSION) {
        const insurerItem = insurerItems.find(
          (item) => item.label === d.extraData.insurer
        );
        segmentClass = "suspension";
        fillColor = insurerItem.itemAttributes.fillColor;
        strokeColor = insurerItem.itemAttributes.strokeColor;
        strokeWidth = "3";
        if (d.associatedObject.totalProRataPremium === 0) {
          segmentClass = segmentClass + " transparent_segment";
        }

        if (isDarkMode) {
          fillColor = invertColor(fillColor);
          strokeColor = invertColor(strokeColor);
        }
      } else if (d.segmentType === TimelineSegmentType.VOID_BEFORE) {
        segmentClass = "void";
        fillColor = "white";
        strokeColor = "white";
        strokeWidth = "0";
      } else if (d.segmentType === TimelineSegmentType.VOID_BETWEEN) {
        segmentClass = "void";
        fillColor = "white";
        strokeColor = "white";
        strokeWidth = "0";
      } else if (d.segmentType === TimelineSegmentType.VOID_AFTER) {
        segmentClass = "void";
        fillColor = "white";
        strokeColor = "white";
        strokeWidth = "0";
      } else {
        return null;
      }

      const rectRef = React.createRef<SVGRectElement>();
      let x1 = xScale(d.startDate);
      let x2 = xScale(d.endDate);
      if (x1 < 0) x1 = 0;
      if (x2 > innerWidth) x2 = innerWidth;
      let width = x2 - x1;
      if (width < 2) width = 2;
      // \
      // /
      const isFleetLegalClosingDate =
        d?.extraData?.lineSpecial ===
        ETimelineExtraDataLineSpecial.CLOSING_DATE;
      const totalProRataPremium = isFleetLegalClosingDate
        ? d.associatedObject?.proRataPremiumToDisplayForFleetLegalClosingDate
        : d.associatedObject?.totalProRataPremium;

      const showPolicyStatuses = appSettings?.VEHICLE_TIMELINE_TYPES.includes(
        TimelineType.VEHICLE_POLICY_STATUSES
      );
      const deltaYForPolicyStatus = showPolicyStatuses
        ? -appSettings?.PREMIUM_BAR_HEIGHT / 2
        : 0;
      return (
        <PremiumSegmentTooltip key={index} d={d}>
          <g
            id={
              "premium_" +
              (d.associatedObject &&
              d.associatedObject?.vehiclePremiumVersionSegmentId
                ? d.associatedObject?.vehiclePremiumVersionSegmentId
                : index)
            }
            name={"premium_" + d.domain.value.replace("PremiumType.", "")}
          >
            <rect
              ref={rectRef}
              className={segmentClass}
              x={x1}
              width={width + 1}
              y={
                yScale(d.domain.value) +
                yScale.bandwidth() / 2 -
                appSettings?.PREMIUM_BAR_HEIGHT / 2 +
                deltaYForPolicyStatus
              }
              height={appSettings?.PREMIUM_BAR_HEIGHT}
              // y={yScale(d.domain.value)}
              // height={yScale.bandwidth()}
              fill={fillColor}
              onClick={(e) => {
                return dispatch(
                  openContextMenu({
                    type: TimelineContextMenuType.PREMIUM,
                    item: d,
                    ref: { x: e.pageX, y: e.pageY },
                    date: xScale.invert(pointer(e)[0]),
                  })
                );
              }}
            ></rect>
            {d.segmentType === TimelineSegmentType.SUSPENSION ? (
              <rect
                className={"clickable"}
                x={x1 + 2}
                width={width}
                y={
                  yScale(d.domain.value) +
                  yScale.bandwidth() / 2 -
                  appSettings?.PREMIUM_BAR_HEIGHT / 2 +
                  deltaYForPolicyStatus +
                  2
                }
                height={appSettings?.PREMIUM_BAR_HEIGHT - 4}
                // y={yScale(d.domain.value) + 2}
                // height={yScale.bandwidth() - 4}
                fill="url(#pattern_stripes)"
                onClick={(e) => {
                  return dispatch(
                    openContextMenu({
                      type: TimelineContextMenuType.PREMIUM,
                      item: d,
                      ref: { x: e.pageX, y: e.pageY },
                      date: xScale.invert(pointer(e)[0]),
                    })
                  );
                }}
              ></rect>
            ) : null}
            {d.associatedObject?.isNotPayingBecauseOfSharedLicensePlate ? (
              <rect
                className={"clickable"}
                x={x1 + 2}
                width={width - 2}
                y={
                  yScale(d.domain.value) +
                  yScale.bandwidth() / 2 -
                  appSettings?.PREMIUM_BAR_HEIGHT / 2 +
                  deltaYForPolicyStatus +
                  2
                }
                height={appSettings?.PREMIUM_BAR_HEIGHT - 4}
                // y={yScale(d.domain.value) + 2}
                // height={yScale.bandwidth() - 4}
                fill="url(#pattern_dashes)"
                onClick={(e) => {
                  return dispatch(
                    openContextMenu({
                      type: TimelineContextMenuType.PREMIUM,
                      item: d,
                      ref: { x: e.pageX, y: e.pageY },
                      date: xScale.invert(pointer(e)[0]),
                    })
                  );
                }}
              ></rect>
            ) : null}
            {/* <line
              x1={x1}
              x2={x1 + width}
              y1={yScale(d.domain.value)}
              y2={yScale(d.domain.value)}
              stroke={strokeColor}
              strokeWidth={strokeWidth}
            />
            <line
              x1={x1}
              x2={x1 + width}
              y1={yScale(d.domain.value) + yScale.bandwidth()}
              y2={yScale(d.domain.value) + yScale.bandwidth()}
              stroke={strokeColor}
              strokeWidth={strokeWidth}
            /> */}
            {d.extraData.leftBorder ? (
              <line
                x1={x1}
                x2={x1}
                // y1={yScale(d.domain.value)}
                // y2={yScale(d.domain.value) + yScale.bandwidth()}
                y1={
                  yScale(d.domain.value) +
                  yScale.bandwidth() / 2 -
                  appSettings?.PREMIUM_BAR_HEIGHT / 2 +
                  deltaYForPolicyStatus
                }
                y2={
                  yScale(d.domain.value) +
                  yScale.bandwidth() / 2 +
                  appSettings?.PREMIUM_BAR_HEIGHT / 2 +
                  deltaYForPolicyStatus
                }
                stroke={isDarkMode ? "#3a3a3a" : "dbf5fc"}
                strokeWidth={strokeWidth}
              />
            ) : d.segmentType === TimelineSegmentType.PREMIUM ||
              d.segmentType === TimelineSegmentType.SUSPENSION ? (
              <line
                x1={x1}
                x2={x1}
                // y1={yScale(d.domain.value)}
                // y2={yScale(d.domain.value) + yScale.bandwidth()}
                y1={
                  yScale(d.domain.value) +
                  yScale.bandwidth() / 2 -
                  appSettings?.PREMIUM_BAR_HEIGHT / 2 +
                  deltaYForPolicyStatus
                }
                y2={
                  yScale(d.domain.value) +
                  yScale.bandwidth() / 2 +
                  appSettings?.PREMIUM_BAR_HEIGHT / 2 +
                  deltaYForPolicyStatus
                }
                stroke={isDarkMode ? "#3a3a3a" : "#dbf5fc"}
                strokeWidth={2}
                strokeDasharray={"5,5"}
              />
            ) : null}
            {d.extraData.rightBorder ? (
              <line
                x1={x1 + width}
                x2={x1 + width}
                // y1={yScale(d.domain.value)}
                // y2={yScale(d.domain.value) + yScale.bandwidth()}
                y1={
                  yScale(d.domain.value) +
                  yScale.bandwidth() / 2 -
                  appSettings?.PREMIUM_BAR_HEIGHT / 2 +
                  deltaYForPolicyStatus
                }
                y2={
                  yScale(d.domain.value) +
                  yScale.bandwidth() / 2 +
                  appSettings?.PREMIUM_BAR_HEIGHT / 2 +
                  deltaYForPolicyStatus
                }
                stroke={isDarkMode ? "#3a3a3a" : "#dbf5fc"}
                strokeWidth={strokeWidth}
              />
            ) : null}
            {d.extraData.isLastSegmentInLine ? (
              <InfinityArrow
                yScale={yScale}
                strokeColor={strokeColor}
                d={d}
                x2={x2}
                domainValueFunc={(d) => d.domain.value}
                deltaYForPolicyStatus={deltaYForPolicyStatus}
              />
            ) : null}
            {d.segmentType === TimelineSegmentType.PREMIUM ||
            d.segmentType === TimelineSegmentType.SUSPENSION ? (
              <text
                fill={strokeColor}
                style={{
                  textAnchor: "middle",
                  fontSize: "0.75em",
                  cursor: "pointer",
                  pointerEvents: "none",
                }}
                x={(x1 + x2) / 2}
                dy=".32em"
                y={
                  yScale(d.domain.value) +
                  yScale.bandwidth() / 2 +
                  deltaYForPolicyStatus
                }
              >
                {taxonomy.PremiumType.byId[
                  `${d.associatedObject?.premiumTypeCode}`
                ]?.code === PremiumType.HORSE_POWER &&
                d.associatedObject?.excludeHorsePowerBasedTaxReason
                  ? truncateTextIfLong(
                      t(
                        `${
                          taxonomy.ReasonForMotorTaxExclusion.byId[
                            `${d.associatedObject?.excludeHorsePowerBasedTaxReason}`
                          ]?.code
                        }`
                      ),
                      x2 - x1
                    )
                  : totalProRataPremium
                  ? truncateTextIfLong(
                      `${formatNumber(totalProRataPremium)} / ${formatNumber(
                        d.associatedObject?.annualGrossPremium
                      )} ${appSettings?.CURRENCY_SYMBOL}`,
                      x2 - x1
                    )
                  : truncateTextIfLong(
                      `${formatNumber(0)} ${
                        appSettings?.CURRENCY_SYMBOL
                      } / ${formatNumber(
                        d.associatedObject
                          .isNotPayingBecauseOfSharedLicensePlate
                          ? 0
                          : d.associatedObject?.annualGrossPremium
                      )} ${appSettings?.CURRENCY_SYMBOL}`,
                      x2 - x1
                    )}
              </text>
            ) : null}
          </g>
        </PremiumSegmentTooltip>
      );
    });
  return (
    <>
      {segments}
      {policyStarts}
    </>
  );
};

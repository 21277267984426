import { mergeStyleSets } from "@uifabric/merge-styles";
import React from "react";
import { useCurrentRoute } from "../../../config/routes";
import SidebarNav from "./SidebarNav";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

const Sidebar = () => {
  const route = useCurrentRoute();

  const theme = useTheme() as IStyledTheme;
  const isReadOnly = route === "readOnlyVehicle";
  const classes = mergeStyleSets({
    container: {
      // boxShadow: `${theme.palette.neutralPrimary} 2px 0px 5px -5px`,
      // flexBasis: "200px",
      // background: theme.palette.white,
      // position: "sticky",
      // top: 0,
      // minWidth: 200,
      boxShadow: `${theme.palette.neutralPrimary} 2px 0px 5px -5px`,
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      boxSizing: "border-box",
      justifyContent: "space-between",
      background: theme.palette.white,
      // height: "calc(100vh - 138px)",
      height: "100vh",
      position: "sticky",
      //top: 200, // 43 height of navigation + 107 height of main fields
      top: 43, // 43 height of navigation + 107 height of main fields
      minWidth: 200,
    },
  });
  if (isReadOnly) {
    return null;
  }
  return (
    <div className={classes.container}>
      <SidebarNav />
    </div>
  );
};
export default Sidebar;

import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS, QUERY_KEYS } from "../queryKeys";

const url = process.env.REACT_APP_BASE_URL;

type TUpdateSetting = {
  fleetId: string | number;
  fleetInsurerSettingId: string | number;

  data: {
    insurerPartnerNumber?: number | string;
    suspensionDays?: number | string;
    activationDays?: number | string;
    garageRiskDiscountPercentHull?: number | string; //float
    garageRiskDiscountPercentHullBasic?: number | string; //float
    insurerBranchOffice?: number | string;
    paymentMethod?: number | string;
    paymentMean?: number | string;
    rowVersion: string;
  };
};

const updateFleetInsurerDefaultSetting = async ({
  fleetId,
  fleetInsurerSettingId,
  data,
}: TUpdateSetting) => {
  const response = await axios.patch(
    `${url}/UpdateFleetInsurerSetting/${fleetId}/${fleetInsurerSettingId}`,
    data
  );

  return response;
};

export function useUpdateFleetInsurerDefaultSetting() {
  const queryClient = useQueryClient();

  return useMutation(updateFleetInsurerDefaultSetting, {
    mutationKey: MUTATION_KEYS.updateFleetInsurerDefaultSetting,
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.fleetInsurerDefaultSettings);
    },
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { Persona, PersonaSize, Spinner, SpinnerSize } from "@fluentui/react";
import styled from "styled-components";

import React from "react";
import { useTranslation } from "react-i18next";
import { MultiValueProps, components, mergeStyles } from "react-select";
import AsyncReactSelect from "react-select/async";
import { Flex, Truncate } from "./styled";

type Props = {
  value: any;
  onChange: (value: any) => void;
  loadOptions: () => Promise<any>;
  styles?: any;
};

export const PeoplePicker = ({
  value,
  onChange,
  loadOptions,
  styles,
}: Props) => {
  const { t } = useTranslation();

  return (
    <AsyncReactSelect
      placeholder={t("greco.form.searchPlaceholder")}
      loadingMessage={() => t("greco.loading")}
      noOptionsMessage={() => t("greco.noResults")}
      loadOptions={loadOptions}
      value={value}
      onChange={onChange}
      isMulti
      components={{
        Option: CustomOptionUser,
        MultiValue: CustomMultiValue,
        LoadingIndicator,
      }}
      styles={mergeStyles(
        {
          option: (p) => {
            return {
              ...p,
              padding: "6px 4px",
            };
          },
        },
        styles
      )}
    />
  );
};

const CustomOptionUser = (props: any) => {
  return (
    <components.Option {...props}>
      <Flex>
        <Persona
          text={props.data.userName}
          hidePersonaDetails
          size={PersonaSize.size32}
          style={{
            marginRight: "5px",
          }}
        />
        <div
          style={{
            flex: 1,
            minWidth: 0,
          }}
        >
          <Truncate>{props.data.userName}</Truncate>
          <Email>
            <Truncate>{props.data.userEmailAddress}</Truncate>
          </Email>
        </div>
      </Flex>
    </components.Option>
  );
};

const Email = styled.div`
  font-size: 12px;
  color: ${(p) => p.theme.palette.neutralSecondary};
`;
const LoadingIndicator = () => {
  return (
    <Spinner size={SpinnerSize.medium} styles={{ root: { marginRight: 9 } }} />
  );
};

const CustomMultiValue = (props: MultiValueProps<any>) => {
  return (
    <components.MultiValue {...props}>
      <Flex>
        <Persona
          size={PersonaSize.size24}
          text={props.data.label}
          hidePersonaDetails
          style={{
            marginRight: "5px",
          }}
        />
        <Truncate>{props.children}</Truncate>
      </Flex>
    </components.MultiValue>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { useSelector } from "../../../../store/hooks";

export const AxisLeft = ({ yScale }) => {
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;
  const { t } = useTranslation();
  const yDomain = useSelector((s) => s.timeline.timeline.domain);

  const ticks = yDomain.map((tickValue, i) => {
    const label: string = tickValue.value;
    const labelParts = label.split("#");
    let finalLabel = labelParts[0];
    return (
      <g className={isDarkMode ? "tickdark" : "tick"} key={i}>
        <text
          className="boldText"
          key={tickValue.value}
          style={{ textAnchor: "end" }}
          x={-13}
          dy=".32em"
          y={yScale(tickValue.value) + yScale.bandwidth() / 2}
        >
          {t(finalLabel)}
        </text>
      </g>
    );
  });
  return <>{ticks}</>;
};

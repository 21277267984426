import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppSettingsType } from "AppSettings";
import { UserRole } from "../types/types";

export type AuthState = {
  userRole: null | UserRole;
  userCountryCode: null | string;
  userAppSettings: AppSettingsType;
};

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userRole: null,
    userCountryCode: null,
  } as AuthState,
  reducers: {
    setUserRole: (s, a: PayloadAction<AuthState["userRole"]>) => {
      s.userRole = a.payload;
    },
    setUserAppSettings: (s, a: PayloadAction<AuthState["userAppSettings"]>) => {
      s.userAppSettings = a.payload;
    },
    setUserCountryCode: (s, a: PayloadAction<AuthState["userCountryCode"]>) => {
      s.userCountryCode = a.payload;
    },
  },
});

export const {
  actions: { setUserRole, setUserCountryCode, setUserAppSettings },
  reducer: authReducer,
} = authSlice;

import { formatISO } from "date-fns";
import { UserRole } from "types/types";
import { TaxonomyKey, TaxonomyState } from "../store/taxonomy";
import { formatIntegerNumber, formatNumber } from "../utils/number";
import { stringToNumber } from "../utils/utils";

export const adjustTaxonomyValue = (
  id: number | null,
  taxonomy: TaxonomyState,
  taxonomyKey: TaxonomyKey,
  t: any
) => {
  return id
    ? {
        label: t(taxonomy[taxonomyKey].byId[id]?.code),
        value: id,
      }
    : null;
};

export const transformTaxonomyValue = (
  id: number | null,
  taxonomy: TaxonomyState,
  taxonomyKey: TaxonomyKey,
  t: any
) => {
  return id
    ? {
        label: t(taxonomy[taxonomyKey]?.byId[id]?.code),
        value: id,
      }
    : null;
};

export const transformInsurerOptionValue = (
  id: number | null,
  insurerList: any[]
) => {
  const insurer = insurerList?.find(
    (insurerObj) => insurerObj?.insurerInternalNumber === id
  );

  return (
    id && {
      label: insurer?.insurerName,
      value: id,
    }
  );
};

export const transformInsurerBranchOptionValue = (
  insurerId: number | null,
  insurerBranchId,
  insurerList: any[]
) => {
  const insurerBranchOptions = insurerList?.find(
    (insurerObj) => insurerObj?.insurerInternalNumber === insurerId
  )?.reportInsurerBranchOffices;

  const insurerBranch = insurerBranchOptions?.find(
    (insurerObj) => insurerObj?.branchOfficeInternalNumber === insurerBranchId
  );

  return (
    insurerBranch && {
      value: insurerBranchId,
      label: insurerBranch.branchOfficeName,
    }
  );
};

export const adjustComboboxValue = (
  id: boolean | null,
  options: any[],
  t: any
) => {
  return id !== null && id === true
    ? {
        label: t("bfm.vehicleForm.hullType.basic.label"),
        value: "basic",
      }
    : {
        label: t("bfm.vehicleForm.hullType.full.label"),
        value: "full",
      };
};

export const adjustDateValue = (date?: string) => {
  return date ? new Date(date) : null;
};

export const adjustFloatNumberValue = (n?: number) => {
  return n !== null && n !== undefined ? formatNumber(n) : null;
};

export const adjustIntegerNumberValue = (n?: number) => {
  return n !== null && n !== undefined ? formatIntegerNumber(n) : null;
};

export const transformSelectValue = (value: any) => {
  if (value === null || value === undefined) {
    return null;
  }
  return value.value;
};

export const transformInsurerValue = (value: any) => {
  if (value === null || value === undefined) {
    return null;
  }
  return value.value;
};

export const transformDateValue = (value: any) => {
  if (value === null || value === undefined) {
    return null;
  }
  return formatISO(value, { representation: "date" });
};

export const transformNumberValue = (value: any) => {
  if (value === null || value === undefined) {
    return null;
  }
  return stringToNumber(value);
};

export const transformStringValue = (value: any) => {
  if (value === null || value === undefined || typeof value !== "string") {
    return null;
  }
  return value.trim();
};

export const getWeightCapacityCodeByNumber = (n: number) => {
  if (n >= 0 && n <= 500) {
    return "WeightCapacityKgRange.0_500";
  } else if (n >= 501 && n <= 1000) {
    return "WeightCapacityKgRange.501_1000";
  } else if (n >= 1001 && n <= 1500) {
    return "WeightCapacityKgRange.1001_1500";
  } else if (n >= 1501 && n <= 3000) {
    return "WeightCapacityKgRange.1501_3000";
  } else if (n >= 3001 && n <= 5000) {
    return "WeightCapacityKgRange.3001_5000";
  } else if (n >= 5001 && n <= 10000) {
    return "WeightCapacityKgRange.5001_10000";
  } else if (n >= 10001) {
    return "WeightCapacityKgRange.10001_UNLIMITED";
  }
};

export const getMaxGrossWeightKgRangeCodeByNumber = (n: number) => {
  if (n >= 0 && n <= 3500) {
    return "MaxGrossWeightKgRange.0_3500";
  } else if (n >= 3501) {
    return "MaxGrossWeightKgRange.3501_UNLIMITED";
  }
};

export const getCubicCapacityCodeByNumber = (n: number) => {
  if (n >= 0 && n <= 250) {
    return "CubicCapacityCcmRangeHG1.0_250";
  } else if (n >= 251 && n <= 500) {
    return "CubicCapacityCcmRangeHG1.251_500";
  } else if (n >= 501) {
    return "CubicCapacityCcmRangeHG1.501_UNLIMITED";
  }
};
export const getNumberOfSeatsMopedCodeByNumber = (n: number) => {
  if (n === 1) {
    return "NumberOfSeatsMoped.1";
  } else if (n === 2) {
    return "NumberOfSeatsMoped.2";
  }
};

export const getNumberOfSeatsRangeCodeByNumber = (n: number) => {
  if (n >= 0 && n <= 20) {
    return "NumberOfSeatsRange.0_20";
  } else if (n >= 21 && n <= 25) {
    return "NumberOfSeatsRange.21_25";
  } else if (n >= 26 && n <= 30) {
    return "NumberOfSeatsRange.26_30";
  } else if (n >= 31 && n <= 35) {
    return "NumberOfSeatsRange.31_35";
  } else if (n >= 36 && n <= 40) {
    return "NumberOfSeatsRange.36_40";
  } else if (n >= 41 && n <= 45) {
    return "NumberOfSeatsRange.41_45";
  } else if (n >= 46 && n <= 50) {
    return "NumberOfSeatsRange.46_50";
  } else if (n >= 51 && n <= 55) {
    return "NumberOfSeatsRange.51_55";
  } else if (n >= 56 && n <= 60) {
    return "NumberOfSeatsRange.56_60";
  } else if (n >= 61 && n <= 65) {
    return "NumberOfSeatsRange.61_65";
  } else if (n >= 66 && n <= 70) {
    return "NumberOfSeatsRange.66_70";
  } else if (n >= 71 && n <= 75) {
    return "NumberOfSeatsRange.71_75";
  } else if (n >= 76 && n <= 80) {
    return "NumberOfSeatsRange.76_80";
  } else if (n >= 81 && n <= 85) {
    return "NumberOfSeatsRange.81_85";
  } else if (n >= 86 && n <= 90) {
    return "NumberOfSeatsRange.86_90";
  } else if (n >= 91 && n <= 95) {
    return "NumberOfSeatsRange.91_95";
  } else if (n >= 96 && n <= 100) {
    return "NumberOfSeatsRange.96_100";
  } else if (n >= 101 && n <= 105) {
    return "NumberOfSeatsRange.101_105";
  } else if (n >= 106 && n <= 110) {
    return "NumberOfSeatsRange.106_110";
  } else if (n >= 111 && n <= 115) {
    return "NumberOfSeatsRange.111_115";
  } else if (n >= 116 && n <= 120) {
    return "NumberOfSeatsRange.116_120";
  } else if (n >= 121 && n <= 125) {
    return "NumberOfSeatsRange.121_125";
  } else if (n >= 126 && n <= 130) {
    return "NumberOfSeatsRange.126_130";
  } else if (n >= 131 && n <= 135) {
    return "NumberOfSeatsRange.131_135";
  } else if (n >= 136 && n <= 140) {
    return "NumberOfSeatsRange.136_140";
  } else if (n >= 141 && n <= 145) {
    return "NumberOfSeatsRange.141_145";
  } else if (n >= 146 && n <= 150) {
    return "NumberOfSeatsRange.146_150";
  } else if (n >= 151) {
    return "NumberOfSeatsRange.151_UNLIMITED";
  }
};

export const getPowerKwCodeByNumber = (n: number) => {
  if (n >= 0 && n <= 12) {
    return "PowerKwRangeHG2.0_12";
  } else if (n >= 13 && n <= 15) {
    return "PowerKwRangeHG2.13_15";
  } else if (n >= 16 && n <= 26) {
    return "PowerKwRangeHG2.16_26";
  } else if (n >= 27 && n <= 40) {
    return "PowerKwRangeHG2.27_40";
  } else if (n >= 41 && n <= 55) {
    return "PowerKwRangeHG2.41_55";
  } else if (n >= 56 && n <= 67) {
    return "PowerKwRangeHG2.56_67";
  } else if (n >= 68 && n <= 89) {
    return "PowerKwRangeHG2.68_89";
  } else if (n >= 90) {
    return "PowerKwRangeHG2.90_UNLIMITED";
  }
};

export const isExternalOrReadonlyAdmin = (userRole: UserRole) => {
  return userRole === UserRole.external || userRole === UserRole.adminreadonly;
};

export const isFullOrInternalAdmin = (userRole: UserRole) => {
  return (
    userRole === UserRole.admin ||
    userRole === UserRole.admininternal ||
    userRole === UserRole.adminreadonly_admininternal
  );
};

export const isFullAdminOrAnyInternal = (userRole: UserRole) => {
  return (
    userRole === UserRole.admin ||
    userRole === UserRole.admininternal ||
    userRole === UserRole.adminreadonly_admininternal ||
    userRole === UserRole.internal
  );
};

export const isFullOrReadonlyAdmin = (userRole: UserRole) => {
  return userRole === UserRole.admin || userRole === UserRole.adminreadonly;
};

import { useMutation } from "react-query";
import { AdjustDeductibleParams, adjustFleetDeductible } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const adjustDeductibleFleetPremium = async (params: AdjustDeductibleParams) => {
  return await adjustFleetDeductible(params);
};

export function useAdjustDeductibleFleetPremium() {
  return useMutation(adjustDeductibleFleetPremium, {
    mutationKey: MUTATION_KEYS.adjustFleetDeductible,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

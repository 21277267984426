import { DefaultButton, Dialog, DialogType, Icon } from "@fluentui/react";
import { useDeleteTEMPVehicle } from "hooks/data/mutations/useDeleteTEMPVehicle";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { toast } from "../../components/FluentToast";
import { VSpace } from "../../components/Spacer";
import { StyledPrimaryButton } from "../../components/styled";
import {
  selectByIdVehicles,
  setDeleteTEMPDialog,
  setRemoveTEMPVehicle,
} from "../../store/fleetPage";
import { useSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/store";

export const DeleteTEMPDialog = () => {
  const theme = useTheme() as IStyledTheme;

  const { t } = useTranslation();
  const deleteTEMPDialog = useSelector((s) => s.fleetPage.deleteTEMPDialog);
  const { mutate: onDeleteTEMPVehicle, isLoading } = useDeleteTEMPVehicle();
  const phraseActivated = usePhraseActivationStatus().activated;
  const { isOpen, vehicleId } = deleteTEMPDialog;
  const dispatch: AppDispatch = useDispatch();
  const vehicle = useSelector((s) => selectByIdVehicles(s, vehicleId || -1));

  if (!vehicle) return null;

  const close = () => {
    dispatch(
      setDeleteTEMPDialog({ isOpen: false, fleetId: null, vehicleId: null })
    );
  };

  return (
    <Dialog
      hidden={!isOpen}
      onDismiss={close}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
      dialogContentProps={{
        type: DialogType.normal,
        title: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              iconName="Warning"
              style={{
                color: theme.semanticColors.errorIcon,
                fontSize: "25px",
                marginRight: "5px",
              }}
            />
            {t("greco.dialog.deleteTitle", {
              object: t("bfm.fleet.tabs.defaultValues"),
            })}
          </div>
        ),
      }}
    >
      <div>
        <strong>{t("bfm.licensePlate.label")}:</strong> {vehicle?.licensePlate}
      </div>
      <div>
        <strong>{t("bfm.vehicleVin.label")}:</strong> {vehicle?.vin}
      </div>
      <VSpace height={30} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <StyledPrimaryButton
          onClick={() =>
            onDeleteTEMPVehicle(
              {
                fleetId: vehicle.fleetId,
                vehicleId: vehicle.vehicleId,
              },
              {
                onSuccess: () => {
                  dispatch(
                    setRemoveTEMPVehicle({
                      vehicleId,
                    })
                  );
                  toast.success(
                    t("greco.notification.delete.success", {
                      object: t("bfm.vehicle").toLowerCase(),
                    })
                  );
                },
              }
            )
          }
          text={"Delete"}
          disabled={isLoading}
          style={{
            marginRight: "5px",
          }}
          iconProps={{
            iconName: isLoading ? "ProgressRingDots" : "Delete",
          }}
        />
        <DefaultButton onClick={close} text={t("greco.cancel")} />
      </div>
    </Dialog>
  );
};

import { toast } from "components/FluentToast";
import { useCancelVehiclePendingStatus } from "hooks/data/mutations/useCancelVehiclePendingStatus";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CancelStatusDialog as CancelStatusDialogGeneric } from "../../components/CancelStatusDialog";
import {
  closeCancelVehicleStatusDialog,
  loadVehiclesActions,
} from "../../store/fleetPage";
import { useSelector } from "../../store/hooks";

export const CancelStatusDialog = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const cancelVehicleStatusDialog = useSelector(
    (s) => s.fleetPage.cancelVehicleStatusDialog
  );
  const { fleetId, vehicleId } = cancelVehicleStatusDialog;

  const { mutate: onCancelVehiclePendingStatus, isLoading } =
    useCancelVehiclePendingStatus();

  const onClose = useCallback(() => {
    dispatch(closeCancelVehicleStatusDialog());
  }, []);

  const handleCancelVehiclePendingStatus = useCallback((data) => {
    onCancelVehiclePendingStatus(
      {
        params: {
          fleetId,
          vehicleId,
          comment: data.comment,
        },
      },
      {
        onSuccess: () => {
          dispatch(loadVehiclesActions.trigger({ isReset: true }));
          toast.success(t("bfm.success"));
          onClose();
        },
      }
    );
  }, []);

  return (
    <CancelStatusDialogGeneric
      cancelStatusAction={handleCancelVehiclePendingStatus}
      isLoading={isLoading}
      close={onClose}
    />
  );
};

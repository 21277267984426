import _, { forEach } from "lodash";
import moment from "moment";
import { TaxonomyState } from "../../../store/taxonomy";
import {
  PremiumType,
  SharedLicensePlate,
  Timeline,
  TimelineSegment,
  TimelineSegmentType,
  TimelineType,
} from "../../../types/types";
import { PrepareTimelineParams } from "./prepareTimeline";

export const sharedLicensePlateDomain = [
  PremiumType.MTPL,
  PremiumType.HORSE_POWER,
];
export function prepareSharedLicensePlateStatusSegments(
  timeline: Timeline,
  sharedLicensePlates: SharedLicensePlate[],
  types: TimelineType[]
): TimelineSegment[] {
  let retVal: TimelineSegment[] = [];
  let domainStatus = TimelineType.SHARED_LICENSE_PLATE_STATUS;
  // if (types.includes(TimelineType.VEHICLE_PREMIUM)) {
  //   domainStatus = TimelineType.VEHICLE_LICENSE_PLATE;
  // }
  sharedLicensePlates.forEach((sharedLicensePlate) => {
    let segments: TimelineSegment[] = [];
    sharedLicensePlate.licensePlateSharedDateRangeInfo.forEach((rangeInfo) => {
      if (types.includes(TimelineType.VEHICLE_PREMIUM)) {
        segments.push({
          domain: {
            type: domainStatus,
            value:
              sharedLicensePlate.licensePlate +
              "#" +
              sharedLicensePlate.licensePlate,
          },
          segmentType: TimelineSegmentType.MAIN_SHARED_VEHICLE,
          startDate: moment(rangeInfo.validFromDate).toDate(),
          endDate: moment(rangeInfo.validToDate).toDate(),
          extraData: { suspendedRange: false },
          associatedObject: rangeInfo,
        });
      }
      rangeInfo.statusRanges?.forEach((range) => {
        segments.push({
          domain: {
            type: domainStatus,
            value:
              sharedLicensePlate.licensePlate +
              "#" +
              sharedLicensePlate.licensePlate,
          },
          segmentType: TimelineSegmentType.MAIN_SHARED_VEHICLE,
          startDate: moment(range.validFromDate).toDate(),
          endDate: moment(range.validToDate).toDate(),
          extraData: { suspendedRange: range.isSuspended },
          associatedObject: { ...rangeInfo },
        });
      });
    });
    segments = sortLicensePlates(segments);

    // Identifying last segment in line
    sharedLicensePlateDomain.forEach((domain) => {
      const domainSegments = segments.filter(
        (segment) => domain === segment.extraData.premiumTypeCode
      );
      domainSegments.forEach((domainSegment, index) => {
        if (index === domainSegments.length - 1) {
          domainSegment.extraData.isLastSegmentInLine = true;
        } else {
          domainSegment.extraData.isLastSegmentInLine = false;
        }
      });
    });

    retVal = [...retVal, ...segments];
  });
  retVal = sortLicensePlates(retVal);
  return retVal;
}

export function prepareSharedLicensePlateSegments(
  timeline: Timeline,
  mainSegments: TimelineSegment[],
  sharedLicensePlates: SharedLicensePlate[],
  taxonomy: TaxonomyState,
  types: TimelineType[]
): TimelineSegment[] {
  let retVal: TimelineSegment[] = [];
  sharedLicensePlates.forEach((sharedLicensePlate) => {
    let segments: TimelineSegment[] = [];
    sharedLicensePlate.historyInfo.forEach((historyInfo) => {
      segments.push({
        domain: {
          type:
            taxonomy.PremiumType.byId[historyInfo.premiumTypeCode].code ===
            PremiumType.MTPL
              ? TimelineType.SHARED_LICENSE_PLATE_MTPL
              : TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX,
          value:
            (taxonomy.PremiumType.byId[historyInfo.premiumTypeCode].code ===
            PremiumType.MTPL
              ? "TimelineType.SHARED_LICENSE_PLATE_MTPL"
              : "TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX") +
            "#" +
            sharedLicensePlate.licensePlate,
        },
        segmentType: TimelineSegmentType.MAIN_SHARED_VEHICLE,
        startDate: moment(historyInfo.validFromDate).toDate(),
        endDate: moment(historyInfo.validToDate).toDate(),
        extraData: {
          premiumTypeCode:
            taxonomy.PremiumType.byId[historyInfo.premiumTypeCode].code,
        },
        associatedObject: historyInfo,
      });
    });
    segments = sortLicensePlates(segments);

    // Identifying last segment in line
    sharedLicensePlateDomain.forEach((domain) => {
      const domainSegments = segments.filter(
        (segment) => domain === segment.extraData.premiumTypeCode
      );
      domainSegments.forEach((domainSegment, index) => {
        if (index === domainSegments.length - 1) {
          domainSegment.extraData.isLastSegmentInLine = true;
        } else {
          domainSegment.extraData.isLastSegmentInLine = false;
        }
      });
    });

    let currentLine = "";
    let lastSegment: TimelineSegment = null;

    const startDate = timeline.startDate;
    const endDate = timeline.endDate;
    // Insert void segments
    const segmentsToInsert: TimelineSegment[] = [];
    for (let index = 0; index < segments.length; index++) {
      let segment: TimelineSegment = segments[index];
      let licenseSegments = types.includes(TimelineType.VEHICLE_PREMIUM)
        ? mainSegments
        : mainSegments.filter(
            (seg) =>
              seg.associatedObject.licensePlate ===
              segment.associatedObject.licensePlate
          );
      if (segment.extraData.premiumTypeCode !== currentLine) {
        // End element
        if (lastSegment !== null) {
          if (
            moment(lastSegment.endDate).isBefore(moment(endDate)) &&
            !lastSegment.associatedObject?.isContinuing
          ) {
            const dateRanges = acceptableDateRanges(
              [lastSegment.endDate, endDate],
              licenseSegments
            );
            for (let index = 0; index < dateRanges.length; index++) {
              const range = dateRanges[index];
              segmentsToInsert.push({
                domain: {
                  type:
                    lastSegment.extraData.premiumTypeCode === PremiumType.MTPL
                      ? TimelineType.SHARED_LICENSE_PLATE_MTPL
                      : TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX,
                  value:
                    (lastSegment.extraData.premiumTypeCode === PremiumType.MTPL
                      ? "TimelineType.SHARED_LICENSE_PLATE_MTPL"
                      : "TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX") +
                    "#" +
                    sharedLicensePlate.licensePlate,
                },
                segmentType: TimelineSegmentType.VOID_AFTER,

                startDate: moment(range[0]).toDate(),
                endDate: moment(range[1]).toDate(),
                extraData: {
                  premiumTypeCode: lastSegment.extraData.premiumTypeCode,
                },
                associatedObject: range[2].associatedObject,
              });
            }
          }
        }
        // First element
        currentLine = segment.extraData.premiumTypeCode;
        if (moment(segment.startDate).isAfter(moment(startDate))) {
          const dateRanges = acceptableDateRanges(
            [startDate, segment.startDate],
            licenseSegments
          );
          for (let index = 0; index < dateRanges.length; index++) {
            const range = dateRanges[index];
            segmentsToInsert.push({
              domain: {
                type:
                  segment.extraData.premiumTypeCode === PremiumType.MTPL
                    ? TimelineType.SHARED_LICENSE_PLATE_MTPL
                    : TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX,
                value:
                  (segment.extraData.premiumTypeCode === PremiumType.MTPL
                    ? "TimelineType.SHARED_LICENSE_PLATE_MTPL"
                    : "TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX") +
                  "#" +
                  sharedLicensePlate.licensePlate,
              },
              segmentType: TimelineSegmentType.VOID_BEFORE,

              startDate: moment(range[0]).toDate(),
              endDate: moment(range[1]).toDate(),
              extraData: {
                premiumTypeCode: segment.extraData.premiumTypeCode,
              },
              associatedObject: range[2].associatedObject,
            });
          }
        }
      } else {
        if (moment(segment.startDate).isAfter(moment(lastSegment.endDate))) {
          const dateRanges = acceptableDateRanges(
            [lastSegment.endDate, segment.startDate],
            licenseSegments
          );
          for (let index = 0; index < dateRanges.length; index++) {
            const range = dateRanges[index];
            segmentsToInsert.push({
              domain: {
                type:
                  segment.extraData.premiumTypeCode === PremiumType.MTPL
                    ? TimelineType.SHARED_LICENSE_PLATE_MTPL
                    : TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX,
                value:
                  (segment.extraData.premiumTypeCode === PremiumType.MTPL
                    ? "TimelineType.SHARED_LICENSE_PLATE_MTPL"
                    : "TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX") +
                  "#" +
                  sharedLicensePlate.licensePlate,
              },
              segmentType: TimelineSegmentType.VOID_BETWEEN,

              startDate: moment(range[0]).toDate(),
              endDate: moment(range[1]).toDate(),
              extraData: {
                premiumTypeCode: segment.extraData.premiumTypeCode,
              },
              associatedObject: range[2].associatedObject,
            });
          }
        }
      }

      lastSegment = segment;
    }
    if (
      lastSegment &&
      moment(lastSegment.endDate).isBefore(moment(endDate)) &&
      !lastSegment.associatedObject?.isContinuing
    ) {
      const dateRanges = acceptableDateRanges(
        [lastSegment.endDate, endDate],
        types.includes(TimelineType.VEHICLE_PREMIUM)
          ? mainSegments
          : mainSegments.filter(
              (seg) =>
                seg.associatedObject.licensePlate ===
                lastSegment.associatedObject.licensePlate
            )
      );
      for (let index = 0; index < dateRanges.length; index++) {
        const range = dateRanges[index];
        segmentsToInsert.push({
          domain: {
            type:
              lastSegment.extraData.premiumTypeCode === PremiumType.MTPL
                ? TimelineType.SHARED_LICENSE_PLATE_MTPL
                : TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX,
            value:
              (lastSegment.extraData.premiumTypeCode === PremiumType.MTPL
                ? "TimelineType.SHARED_LICENSE_PLATE_MTPL"
                : "TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX") +
              "#" +
              sharedLicensePlate.licensePlate,
          },
          segmentType: TimelineSegmentType.VOID_AFTER,

          startDate: moment(range[0]).toDate(),
          endDate: moment(range[1]).toDate(),
          extraData: {
            premiumTypeCode: lastSegment.extraData.premiumTypeCode,
          },
          associatedObject: range[2].associatedObject,
        });
      }
    }
    // No premium for line - add empty segment
    for (let insuranceLine of sharedLicensePlateDomain) {
      const result = segments.find(
        (segment) => segment.extraData.premiumTypeCode === insuranceLine
      );
      if (!!!result) {
        const dateRanges = acceptableDateRanges(
          [startDate, endDate],
          mainSegments.filter(
            (seg) =>
              seg.associatedObject.licensePlate ===
              sharedLicensePlate.licensePlate
          )
        );
        for (let index = 0; index < dateRanges.length; index++) {
          const range = dateRanges[index];

          segmentsToInsert.push({
            domain: {
              type:
                insuranceLine === "PremiumType.HORSE_POWER"
                  ? TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX
                  : TimelineType.SHARED_LICENSE_PLATE_MTPL,
              value:
                (insuranceLine === "PremiumType.HORSE_POWER"
                  ? "TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX"
                  : "TimelineType.SHARED_LICENSE_PLATE_MTPL") +
                "#" +
                sharedLicensePlate.licensePlate,
            },
            segmentType: TimelineSegmentType.VOID_AFTER,

            startDate: moment(range[0]).toDate(),
            endDate: moment(range[1]).toDate(),
            extraData: {
              premiumTypeCode: insuranceLine,
            },
            associatedObject: range[2].associatedObject,
          });
        }
      }
    }
    retVal = [...retVal, ...segments, ...segmentsToInsert];
  });
  retVal = sortLicensePlates(retVal);
  retVal.forEach((segment) => {
    const lp = segment.domain.value.split("#")[1];
    const main = mainSegments.find(
      (seg) =>
        seg.associatedObject.licensePlate === lp &&
        moment(seg.startDate).isSameOrBefore(segment.startDate, "date") &&
        moment(seg.endDate).isSameOrAfter(segment.startDate, "date")
    );
    if (main) {
      segment.extraData.possibleVehicles =
        main.associatedObject.possibleVehicles;
        // add possible vehicles from other segments
        mainSegments.forEach((ms)=>{
          if (ms!==main && ms.associatedObject.licensePlate === lp) {
            ms.associatedObject.possibleVehicles.forEach((s) => {
              if (!segment.extraData.possibleVehicles.some(item=>item.vehicleId === s.vehicleId)){
                segment.extraData.possibleVehicles = segment.extraData.possibleVehicles.concat(s);
              }
            });
          }
        });
    }
  });
  return retVal;
}

const sortLicensePlates = (segments: TimelineSegment[]): TimelineSegment[] => {
  let retVal: TimelineSegment[] = [];

  const distinctPlates = [
    ...new Set(segments.map((item) => item.domain.value)),
  ];
  distinctPlates.forEach((plate) => {
    const plateSegments = segments.filter(
      (segment) => segment.domain.value === plate
    );
    plateSegments.sort(sortSegments());
    retVal.push(...plateSegments);
  });

  return retVal;
};

function sortSegments() {
  return (a, b) => {
    var aLine = a.extraData.premiumTypeCode;
    var bLine = b.extraData.premiumTypeCode;
    var aDate = a.startDate;
    var bDate = b.startDate;
    if (aLine === bLine) {
      return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
    } else {
      return aLine < bLine ? -1 : 1;
    }
  };
}

export const determineSharedLicensePlateObject = (
  prepareTimelineParams: PrepareTimelineParams
): SharedLicensePlate[] => {
  if (!prepareTimelineParams.types) return;
  if (prepareTimelineParams.types.includes(TimelineType.VEHICLE_PREMIUM)) {
    return [
      {
        historyInfo:
          prepareTimelineParams.vehicle.vehicleSharedLicensePlateHistoryData,
        licensePlate: PremiumType.SHARED_LICENSE_PLATE,
        licensePlateSharedDateRangeInfo:
          prepareTimelineParams.vehicle.vehicleLicensePlateSharedDateRangeData,
        currentVehicles: [],
      },
    ];
  } else if (
    prepareTimelineParams.types.includes(
      TimelineType.SHARED_LICENSE_PLATE_STATUS
    )
  ) {
    return prepareTimelineParams.sharedLicensePlateVehicles;
  }
};
type DateTuple = [Date, Date];
type DateTriple = [Date, Date, TimelineSegment];

const acceptableDateRanges = (
  voidSegment: DateTuple,
  segments: TimelineSegment[]
): DateTriple[] => {
  const retVal: DateTriple[] = [];
  segments
    .map((segment) => {
      let seg: DateTriple = null;
      seg = [segment.startDate, segment.endDate, segment];
      return seg;
    })
    .forEach((segmentRange) => {
      // 1
      if (
        segmentRange[0].getTime() <= voidSegment[0].getTime() &&
        segmentRange[1].getTime() >= voidSegment[1].getTime()
      ) {
        retVal.push([voidSegment[0], voidSegment[1], segmentRange[2]]);
      }
      // 2
      else if (
        segmentRange[0].getTime() < voidSegment[0].getTime() &&
        segmentRange[1].getTime() >= voidSegment[0].getTime()
      ) {
        retVal.push([voidSegment[0], segmentRange[1], segmentRange[2]]);
      }
      // 3
      else if (
        segmentRange[0].getTime() <= voidSegment[1].getTime() &&
        segmentRange[1].getTime() > voidSegment[1].getTime()
      ) {
        retVal.push([segmentRange[0], voidSegment[1], segmentRange[2]]);
      }
      // 4
      else if (
        segmentRange[0].getTime() >= voidSegment[0].getTime() &&
        segmentRange[1].getTime() <= voidSegment[1].getTime()
      ) {
        retVal.push([segmentRange[0], segmentRange[1], segmentRange[2]]);
      }
    });
  return retVal;
};

import { TooltipHost } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

type Props = {
  fleetLegalIsPolicyIssued: boolean;
  isfleetLegalClosingDate: boolean;
};

function FleetLegalLicenseCell({
  fleetLegalIsPolicyIssued,
  isfleetLegalClosingDate,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;

  return fleetLegalIsPolicyIssued ? (
    <>
      {/* <i
        className={
          isfleetLegalClosingDate ? "las la-car-alt" : "las la-car-side"
        }
        style={{
          fontSize: 18,
        }}
      ></i> */}
    </>
  ) : (
    <TooltipHost
      content={
        isfleetLegalClosingDate
          ? t("bfm.fleetLegalClosingDatePolicyIsNotIssued")
          : t("bfm.fleetLegalPolicyIsNotIssued")
      }
    >
      <i
        className={
          isfleetLegalClosingDate ? "las la-car-alt" : "las la-car-side"
        }
        style={{
          fontSize: 18,
          color: theme.palette.redDark,
        }}
      ></i>
    </TooltipHost>
  );
}

export default FleetLegalLicenseCell;

import { GroupPolicyNumberField } from "pages/vehicle/GroupPolicyNumberField";
import { HullProductField } from "pages/vehicle/HullProductField";
import { InsurerBranchOfficeTaxonomyField } from "pages/vehicle/InsurerBranchOfficeTaxonomyField";
import React, { useMemo } from "react";
import { InsurerTaxonomyField } from "../../pages/fleet-form/InsurerTaxonomyField";
import { InsurerVehicleTaxonomyField } from "../../pages/vehicle/InsurerVehicleTaxonomyField";
import { capitalize } from "../../utils/utils";
import { TimelineOperationVehicleCombobox } from "../timeline/controls/TimelineOperationVehicleCombobox";
import { CheckboxField } from "./CheckboxField";
import { ComboboxField } from "./ComboboxField";
import { DateField } from "./DateField";
import DateTimeField from "./DateTimeField";
import { DueDateField } from "./DueDateField";
import { HullTypeComboboxField } from "./HullTypeComboboxField";
import { NumberField } from "./NumberField";
import { TaxonomyField } from "./TaxonomyField";
import { TextField } from "./TextField";
import { FieldConfig } from "./types";

export const useFields = (
  config: FieldConfig[],
  applyConfig?: (fc: FieldConfig) => Partial<FieldConfig>,
  setFieldTouched?: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void
) => {
  return useMemo(() => {
    const fields = config.reduce((f, c) => {
      if (c.type === "insurerTaxonomy") {
        f[capitalize(c.name)] = (
          <InsurerTaxonomyField key={c.name} fieldConfig={c} />
        );
      }

      if (c.type === "insurerVehicleTaxonomy") {
        f[capitalize(c.name)] = (
          <InsurerVehicleTaxonomyField key={c.name} fieldConfig={c} />
        );
      }

      if (c.type === "insurerBranchOfficeTaxonomy") {
        f[capitalize(c.name)] = (
          <InsurerBranchOfficeTaxonomyField key={c.name} fieldConfig={c} />
        );
      }
      if (c.type === "hullproduct") {
        f[capitalize(c.name)] = (
          <HullProductField key={c.name} fieldConfig={c} />
        );
      }
      if (c.type === "grouppolicynumber") {
        f[capitalize(c.name)] = (
          <GroupPolicyNumberField key={c.name} fieldConfig={c} />
        );
      }
      if (c.type === "number") {
        f[capitalize(c.name)] = <NumberField key={c.name} fieldConfig={c} />;
      }
      if (c.type === "dueDate") {
        f[capitalize(c.name)] = <DueDateField key={c.name} fieldConfig={c} />;
      }
      if (c.type === "text") {
        f[capitalize(c.name)] = <TextField key={c.name} fieldConfig={c} />;
      }
      if (c.type === "taxonomy") {
        f[capitalize(c.name)] = <TaxonomyField key={c.name} fieldConfig={c} />;
      }
      if (c.type === "combobox") {
        f[capitalize(c.name)] = <ComboboxField key={c.name} fieldConfig={c} />;
      }
      if (c.type === "hulltypecombobox") {
        f[capitalize(c.name)] = (
          <HullTypeComboboxField key={c.name} fieldConfig={c} />
        );
      }
      if (c.type === "timelineoperationvehiclecombobox") {
        f[capitalize(c.name)] = (
          <TimelineOperationVehicleCombobox key={c.name} fieldConfig={c} />
        );
      }
      if (c.type === "checkbox") {
        f[capitalize(c.name)] = <CheckboxField key={c.name} fieldConfig={c} />;
      }
      if (c.type === "date") {
        f[capitalize(c.name)] = <DateField key={c.name} fieldConfig={c} />;
      }
      if (c.type === "datetime") {
        f[capitalize(c.name)] = <DateTimeField key={c.name} fieldConfig={c} />;
      }
      return f;
    }, {});
    return {
      ...fields,
      all: (
        <>
          {config.map((_c, index) => {
            let c = { ..._c };
            if (applyConfig) {
              c = {
                ...c,
                ...applyConfig(c),
              } as FieldConfig;
            }

            if (c.type === "insurerTaxonomy") {
              return <InsurerTaxonomyField key={c.name} fieldConfig={c} />;
            }
            if (c.type === "insurerVehicleTaxonomy") {
              return (
                <InsurerVehicleTaxonomyField key={c.name} fieldConfig={c} />
              );
            }
            if (c.type === "hullproduct") {
              return <HullProductField key={c.name} fieldConfig={c} />;
            }
            if (c.type === "grouppolicynumber") {
              return <GroupPolicyNumberField key={c.name} fieldConfig={c} />;
            }
            if (c.type === "insurerBranchOfficeTaxonomy") {
              return (
                <InsurerBranchOfficeTaxonomyField
                  key={c.name}
                  fieldConfig={c}
                />
              );
            }
            if (c.type === "number") {
              return <NumberField key={c.name} fieldConfig={c} />;
            }
            if (c.type === "dueDate") {
              return <DueDateField key={c.name} fieldConfig={c} />;
            }
            if (c.type === "text") {
              return <TextField key={c.name} fieldConfig={c} />;
            }
            if (c.name === "paymentMethod" && c.type === "taxonomy") {
              return (
                <TaxonomyField
                  key={c.name}
                  fieldConfig={c}
                  onSetFieldTouched={() => {
                    setFieldTouched &&
                      setFieldTouched("motorTaxPaymentMethod", true);
                  }}
                />
              );
            }
            if (c.type === "taxonomy") {
              return <TaxonomyField key={c.name} fieldConfig={c} />;
            }
            if (c.type === "combobox") {
              return <ComboboxField key={c.name} fieldConfig={c} />;
            }
            if (c.type === "hulltypecombobox") {
              return <HullTypeComboboxField key={c.name} fieldConfig={c} />;
            }
            if (c.type === "timelineoperationvehiclecombobox") {
              return (
                <TimelineOperationVehicleCombobox
                  key={c.name}
                  fieldConfig={c}
                />
              );
            }

            if (c.type === "checkbox") {
              return <CheckboxField key={c.name} fieldConfig={c} />;
            }
            if (c.type === "date") {
              return <DateField key={`${c.name} ${index}`} fieldConfig={c} />;
            }
            if (c.type === "datetime") {
              return (
                <DateTimeField key={`${c.name} ${index}`} fieldConfig={c} />
              );
            }
          })}
        </>
      ),
    };
  }, [applyConfig, config]);
};

import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { replace } from "connected-react-router";
import { call, fork, put, takeEvery } from "redux-saga/effects";
import { Fleet, FleetWithPremiumsResponse, VehicleRedux } from "../types/types";
import * as API from "./api/api";
import { LoadStatus } from "./store";
import { createAsyncRoutine, handleAxiosError } from "./util";

const vehiclesAdapter = createEntityAdapter<VehicleRedux>({
  selectId: (v) => v.vehicleId,
});

type AnalyticsPageStateWithoutEntities = {
  loadFleetStatus: LoadStatus;
  fleet: FleetWithPremiumsResponse | null;
};

const initialState = vehiclesAdapter.getInitialState({
  fleet: null,
} as AnalyticsPageStateWithoutEntities);

export type AnalyticsPageState = AnalyticsPageStateWithoutEntities &
  EntityState<VehicleRedux>;

export const loadFleetActions = createAsyncRoutine<
  Fleet["fleetId"],
  void,
  FleetWithPremiumsResponse,
  any
>("analyticsPage/loadFleet");

export const analyticsPageSlice = createSlice({
  name: "analyticsPage",
  initialState,
  reducers: {
    resetState: (s) => {
      Object.keys(s).forEach((key) => {
        s[key] = initialState[key];
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadFleetActions.loading, (s, a) => {
      s.loadFleetStatus = "loading";
    });
    builder.addCase(loadFleetActions.success, (s, a) => {
      s.loadFleetStatus = "success";
      s.fleet = a.payload;
    });
    builder.addCase(loadFleetActions.error, (s, a) => {
      s.loadFleetStatus = "error";
    });
  },
});

export const {
  reducer: analyticsPageReducer,
  actions: { resetState },
} = analyticsPageSlice;

function* loadFleetSaga() {
  yield takeEvery(
    loadFleetActions.trigger.type,
    function* (a: ReturnType<typeof loadFleetActions.trigger>) {
      const fleetId = a.payload;
      try {
        yield put(loadFleetActions.loading());
        if (!!!fleetId) {
          yield put(loadFleetActions.success(null));
        } else {
          const res = yield call(API.getFleetWithPremiums, fleetId);
          yield put(loadFleetActions.success(res.data));
        }
      } catch (err) {
        yield put(loadFleetActions.error(err));
        handleAxiosError(err);
        if (err.response.status === 403) {
          yield put(replace("/"));
        }
      }
    }
  );
}

export function* analyticsPageSaga() {
  yield fork(loadFleetSaga);
}

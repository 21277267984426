import { AppSettingsType } from "AppSettings";
import TextInputField from "components/controls/TextInputField";
import { useFormikContext } from "formik";
import React from "react";
import { PremiumType } from "types/types";
import { useFields } from "../../components/form/useFields";
import { duplicateWithPremiumsDialogConfig } from "./duplicateWithPremiumsDialogConfig";

const config = duplicateWithPremiumsDialogConfig;

interface IProps {
  isTradeLicensePlate: boolean;
  appSettings: AppSettingsType;
}

export const DuplicateWithPremiumsDialogContents = ({
  isTradeLicensePlate,
  appSettings,
}: IProps) => {
  const { values, errors, handleChange } = useFormikContext<any>();

  const { all: leftFields } = useFields([
    config.licensePlate,
    config.mtplPolicyNumber,
    config.certificateNumber,
  ]);
  const { all: rightFields } = useFields([
    config.vin,
    config.hullPolicyNumber,
    config.nationalCodeId,
  ]);

  const { all: leftFieldsTlp } = useFields([
    config.licensePlate,
    config.hullPolicyNumber,
  ]);
  const { all: rightFieldsTlp } = useFields([
    config.mtplPolicyNumber,
    config.certificateNumber,
  ]);

  let left = (
    <>
      {leftFields}
      {appSettings.VEHICLE_PREMIUM_TYPES.includes(
        PremiumType.FLEETLEGAL_PRORATA
      ) ? (
        <TextInputField
          value={values?.fleetLegalPolicyNumber}
          errors={errors}
          onChange={handleChange}
          name={"fleetLegalPolicyNumber"}
          disabled={false}
          label={"bfm.fleetForm.fleetLegalPolicyNumber.label"}
        />
      ) : null}
    </>
  );
  let right = <>{rightFields}</>;

  if (isTradeLicensePlate) {
    left = <>{leftFieldsTlp}</>;
    right = <>{rightFieldsTlp}</>;
  }

  let dialogContents = (
    <>
      <div className="row">
        <div className="column">{left}</div>
        <div className="column">{right}</div>
      </div>
    </>
  );

  return dialogContents;
};

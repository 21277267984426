import { Icon, Pivot, PivotItem } from "@fluentui/react";

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useSelector } from "../../../store/hooks";
import { setPremiumBookingTab } from "../../../store/vehicle";
import { Accident } from "./Accident";
import { Assistance } from "./Assistance";
import { EnginePowerTax } from "./EnginePowerTax";
import { FleetLegalProrata } from "./FleetLegalProrata";
import { General } from "./General";
import { Hull } from "./Hull";
import { HullBasic } from "./HullBasic";
import { Legal } from "./Legal";
import { Mtpl } from "./Mtpl";

export const Tabs = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tab = useSelector((s) => s.vehicle.premiumBookingTab);
  const isTradeLicensePlate = useSelector(
    (s) => s.vehicle.vehicle.isTradeLicensePlate
  );
  return (
    <Pivot
      selectedKey={tab}
      onLinkClick={(item) => {
        if (!item) return;
        dispatch(setPremiumBookingTab(item.props.itemKey));
      }}
    >
      <PivotItem
        headerText="General"
        itemKey="general"
        onRenderItemLink={() => (
          <>
            <StyledIcon iconName="Globe" />
            {t("bfm.vehicleForm.pivotGeneral.label")}
          </>
        )}
      >
        <General />
      </PivotItem>

      <PivotItem
        headerText="MTPL"
        itemKey="mtpl"
        onRenderItemLink={() => (
          <>
            <StyledIcon iconName="Car" />
            {t("bfm.vehicleForm.pivotMtpl.label")}
          </>
        )}
      >
        <Mtpl />
      </PivotItem>
      {!isTradeLicensePlate && (
        <PivotItem
          headerText="Engine Power Tax"
          itemKey="enginePowerTax"
          onRenderItemLink={() => (
            <>
              <StyledIcon iconName="Money" />
              {t("bfm.vehicleForm.pivotEnginePowerTax.label")}
            </>
          )}
        >
          <EnginePowerTax />
        </PivotItem>
      )}
      <PivotItem
        headerText="Accident"
        itemKey="accident"
        onRenderItemLink={() => (
          <>
            <StyledIcon iconName="Warning" />
            {t("bfm.vehicleForm.pivotAccident.label")}
          </>
        )}
      >
        <Accident />
      </PivotItem>
      <PivotItem
        headerText="Assistance"
        itemKey="assistance"
        onRenderItemLink={() => (
          <>
            <StyledLaIcon className={"las la-hands-helping"}></StyledLaIcon>
            {t("bfm.vehicleForm.pivotAssistance.label")}
          </>
        )}
      >
        <Assistance />
      </PivotItem>
      <PivotItem
        headerText="Legal"
        itemKey="legal"
        onRenderItemLink={() => (
          <>
            <StyledLaIcon className={"la la-balance-scale"}></StyledLaIcon>
            {t("bfm.vehicleForm.pivotLegal.label")}
          </>
        )}
      >
        <Legal />
      </PivotItem>
      <PivotItem
        headerText="Hull"
        itemKey="hull"
        onRenderItemLink={() => (
          <>
            <StyledLaIcon className={"las la-anchor"}></StyledLaIcon>
            {t("bfm.vehicleForm.pivotHull.label")}
          </>
        )}
      >
        <Hull />
      </PivotItem>
      <PivotItem
        headerText="Hull Basic"
        itemKey="hullBasic"
        onRenderItemLink={() => (
          <>
            <StyledLaIcon className={"las la-dharmachakra"}></StyledLaIcon>

            {t("bfm.vehicleForm.pivotHullBasic.label")}
          </>
        )}
      >
        <HullBasic />
      </PivotItem>
      <PivotItem
        headerText="Fleet Legal"
        itemKey="fleetLegalProrata"
        onRenderItemLink={() => (
          <>
            <StyledLaIcon className={"las la-car-side"}></StyledLaIcon>
            {t("PremiumType.FLEETLEGAL_PRORATA")}
          </>
        )}
      >
        <FleetLegalProrata />
      </PivotItem>
    </Pivot>
  );
};

const StyledIcon = styled(Icon)`
  color: ${(p) => p.theme.palette.themePrimary};
  margin-right: 5px;
  font-size: 16px;
`;

const StyledLaIcon = styled.i`
  font-size: 20px;
  margin-right: 5px;
  color: ${(p) => p.theme.palette.themePrimary};
`;

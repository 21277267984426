import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";

import { Insurer, InsurerSettings } from "../types/types";
import { RootState } from "./store";

type InsurersPageStateWithoutEntities = {
  search: string;
  sort: any;

  deleteDialog: {
    insurerSettings: InsurerSettings | null;
    isOpen: boolean;
    mode: "single" | "all";
    callback?: () => void;
  };
};

export type InsurersPageState = EntityState<Insurer> &
  InsurersPageStateWithoutEntities;

export const insurersAdapter = createEntityAdapter<Insurer>({
  selectId: (f) => f.insurerId,
});

const initialState: InsurersPageStateWithoutEntities = {
  search: "",
  sort: {
    key: "InsurerName",
    dir: "asc",
  },
  deleteDialog: {
    insurerSettings: null,
    isOpen: false,
    mode: "single",
  },
};

export const insurersPageSlice = createSlice({
  name: "insurersPage",
  initialState: insurersAdapter.getInitialState(initialState),
  reducers: {
    setSearch: (s, a: PayloadAction<InsurersPageState["search"]>) => {
      s.search = a.payload;
    },
    setSort: (s, a: PayloadAction<InsurersPageState["sort"]>) => {
      s.sort = a.payload;
    },

    setDeleteDialog: (
      s,
      a: PayloadAction<InsurersPageState["deleteDialog"]>
    ) => {
      s.deleteDialog = a.payload;
    },
  },
});

export const {
  actions: {
    setSearch: setSearchInsurersPage,
    setSort: setSortInsurersPage,
    setDeleteDialog,
  },
  reducer: InsurersPageReducer,
} = insurersPageSlice;

export const { selectAll: selectAllInsurers } =
  insurersAdapter.getSelectors<RootState>((s) => s.insurersPage);

export const selectSearch = (s: RootState) => s.insurersPage.search;
export const selectSortInsurersPage = (s: RootState) => s.insurersPage.sort;

import React, { PropsWithChildren } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useTranslation } from "react-i18next";
import { timeFormat } from "d3";
import { PremiumType, TimelineSegmentType } from "../../../../../types/types";
import { formatNumber } from "../../../../../utils/number";
import moment from "moment";
import { useSelector } from "../../../../../store/hooks";

const tickFormat = timeFormat("%d.%m.%Y");

export const LicensePlateSegmentTooltip = ({ children, d }) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  let period = (
    <>
      {tickFormat(d.startDate)} - {tickFormat(d.endDate)}
      <br />
    </>
  );
  if (d.segmentType === TimelineSegmentType.VOID_BEFORE) {
    period = null; // <>Before {tickFormat(d.start)}<br /></>;
  }
  if (d.segmentType === TimelineSegmentType.VOID_AFTER) {
    period = null; //<>After {tickFormat(d.start)}<br /></>;
  }

  let extraInfo = <>No license plate</>;
  if (d.associatedObject !== null) {
    extraInfo = (
      <>
        {t("bfm.setByUserName.label")}: {d.associatedObject?.setByUserName}
        <br />
        {t("bfm.setAt.label")}:{" "}
        {tickFormat(moment(d.associatedObject?.setAt).toDate())}
        <br />
        {t("bfm.changeReasonComment.label")}: {d.associatedObject?.comment}
      </>
    );
  }
  return (
    <Tippy
      delay={100}
      followCursor={true}
      disabled={false}
      content={
        <span>
          {d.associatedObject?.licensePlate}
          <br />
          {period}
          <hr />
          {extraInfo}
        </span>
      }
    >
      {children}
    </Tippy>
  );
};

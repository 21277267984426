import { useMutation } from "react-query";
import {
  RecalculateVehicleProRataAndBookingsRequestParams,
  recalculateVehicleProRataAndBookings,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

export const recalculateVehicleProRataAndBookingsFn = async (
  params: RecalculateVehicleProRataAndBookingsRequestParams
) => {
  return await recalculateVehicleProRataAndBookings(params);
};

export function useRecalculateVehicleProRataAndBookings() {
  return useMutation(recalculateVehicleProRataAndBookingsFn, {
    mutationKey: MUTATION_KEYS.recalculateVehicleProRataAndBookings,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { useMutation } from "react-query";
import { changeMultipleVehicleIssuingStatus } from "store/api/api";
import { handleAxiosError } from "store/util";
import { IUpdateMultipleVehicleIssuingStatus } from "types/types";
import { MUTATION_KEYS } from "../queryKeys";

const changeMultipleVehicleIssuingStatusFn = async (
  params: IUpdateMultipleVehicleIssuingStatus
) => {
  return await changeMultipleVehicleIssuingStatus(params);
};

export function useUpdateMultipleVehicleIssuingStatus() {
  return useMutation(changeMultipleVehicleIssuingStatusFn, {
    mutationKey: MUTATION_KEYS.changeMultipleVehicleIssuingStatus,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Client } from "../types/types";

import { LoadStatus } from "./store";

export type FilterState = {
  clientList: null | Client[];
  selectedClients: number[];
  selectedMainIds: number[];
  loadClientsStatus: LoadStatus;
  selectedCountryFilter: any | null;
  fleetCountryOptions: any[];
};

export const filterSlice = createSlice({
  name: "filter",
  initialState: {
    clientList: null,
    loadClientsStatus: "none",
    selectedClients: null,
    selectedMainIds: null,
    selectedCountryFilter: null,
    fleetCountryOptions: [],
  } as FilterState,
  reducers: {
    setSelectedClients: (
      s,
      a: PayloadAction<FilterState["selectedClients"]>
    ) => {
      s.selectedClients = a.payload;
    },
    setSelectedMainIds: (
      s,
      a: PayloadAction<FilterState["selectedMainIds"]>
    ) => {
      s.selectedMainIds = a.payload;
    },
    setSelectedCountryFilter: (
      s,
      a: PayloadAction<FilterState["selectedCountryFilter"]>
    ) => {
      s.selectedCountryFilter = a.payload;
    },
    setFleetCountryOptions: (
      s,
      a: PayloadAction<FilterState["fleetCountryOptions"]>
    ) => {
      s.fleetCountryOptions = a.payload;
    },
  },
});

export const {
  actions: {
    setSelectedClients,
    setSelectedMainIds,
    setSelectedCountryFilter,
    setFleetCountryOptions,
  },
  reducer: filterReducer,
} = filterSlice;

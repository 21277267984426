import { mergeStyleSets } from "@fluentui/react";
import FormSubmitSpinner from "components/FormSubmitSpinner";
import { useCurrentRoute } from "config/routes";
import { Formik } from "formik";
import { constants } from "library/constants";
import { delay } from "library/delay";
import {
  formatDayMonthValue,
  formatDayMonthValueForPayload,
} from "pages/vehicle/helpers";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useUnmount } from "react-use";
import {
  loadFleetUsersActions,
  loadFleetWithInsurersActions,
  resetState,
  setFleetAppSettings,
} from "store/fleetForm";
import { useSelector } from "store/hooks";
import { clearClientFromInternalNumber } from "store/slices/fleetFormikFormSlice";
import { selectShouldDisableForm } from "store/vehicle";
import { setYupLocale } from "utils/setYupLocale";
import * as yup from "yup";
import FleetFormToolbar from "../FleetFormToolbar/FleetFormToolbar";
import { useFetchInsurerSettings } from "../hooks/useFetchInsurerSettings";
import useFleetTabChange from "../hooks/useFleetTabChange";
import { Navigation } from "../navigation/Navigation";
import { Dialog } from "./Dialog";
import FleetFormSkeleton from "./FleetFormSkeleton";
import FleetSidebar from "./FleetSidebar";
import { fleetConfig } from "./fleetConfig";
import { DefaultSettings } from "./tabs/DefaultSettings/DefaultSettings";
import { FleetTab } from "./tabs/FleetTab/FleetTab";
import { History } from "./tabs/History";
import InsurerDefaultSettings from "./tabs/InsurerDefaultSettings/InsurerDefaultSettings";
import { Users } from "./tabs/UsersTab/Users";

import AppSettings from "AppSettings";
import { toast } from "components/FluentToast";
import { useCreateFleet } from "hooks/data/mutations/useCreateFleet";
import { useUpdateFleet } from "hooks/data/mutations/useUpdateFleet";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { InsurerGroupPolicies } from "./InsurerGroupPolicies";

export const fleetTabs = {
  fleetTab: "fleet-tab",
  usersTab: "users-tab",
  historyTab: "history-tab",
  insurerSettingsTab: "inusrer-default-values-tab",
  settingsTab: "default-values-tab",
};

const FleetForm = () => {
  const navigate = useNavigate();

  const theme = useTheme() as IStyledTheme;
  const { fleetId } = useParams() as any;
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();
  const route = useCurrentRoute();
  const { mutate: onCreateFleet, isLoading: createFleetInProgress } =
    useCreateFleet();

  const {
    mutate: onUpdateFleet,
    isLoading: updateFleetInProgress,
    isSuccess,
  } = useUpdateFleet();

  const isLoading =
    useSelector(selectShouldDisableForm)(route) ||
    createFleetInProgress ||
    updateFleetInProgress; // TODO

  const userRole = useSelector((s) => s.auth.userRole);

  const fleetRef = useRef(null);
  const usersRef = useRef(null);
  const settingRef = useRef(null);
  const insurerSettingsRef = useRef(null);
  const historyRef = useRef(null);

  useFleetTabChange({
    fleetRef,
    usersRef,
    settingRef,
    historyRef,
  });

  const classes = getClassNames(theme);
  const isCreateFleet = route === constants.createFleet;
  const isEditFleet = route === constants.editFleet;

  const fleet = useSelector((s) => s.fleetForm.fleetWithPremiums);

  const insurerList = useSelector((s) => s.fleetForm.insurerList);

  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const tempUsers = useSelector((s) => s.fleetForm.tempUsers);
  const fleetConf = useMemo(() => {
    return fleetConfig(fleet, t, taxonomy, insurerList);
  }, [fleet, t, taxonomy]);

  const fleetAppSettings = useSelector((s) => s.fleetForm.fleetAppSettings);

  useEffect(() => {
    if (!fleet) return;
    dispatch(
      setFleetAppSettings(
        AppSettings.getInstance().getAppSettingsTypes()[
          taxonomy.Country.byId[fleet.countryCode].code + ""
        ]
      )
    );
  }, [fleet, taxonomy]);

  const initialValues = useMemo(() => {
    const initialValuesObj = {} as any;
    if (isEditFleet) {
      fleetConf.forEach((configItem) => {
        initialValuesObj[configItem.name] = configItem.value;
      });
    }

    return {
      ...initialValuesObj,
      fiscalDate: formatDayMonthValue(
        fleet?.businessYearStartDay,
        fleet?.businessYearStartMonth
      ),
      dueDate: formatDayMonthValue(fleet?.dueDay, fleet?.dueMonth),
    };
  }, [
    fleet?.businessYearStartDay,
    fleet?.businessYearStartMonth,
    fleet?.dueDay,
    fleet?.dueMonth,
    fleetConf,
    isEditFleet,
  ]);

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    const schemaObj = {};
    fleetConf.forEach((configItem) => {
      schemaObj[configItem.name] = configItem.validation;
    });
    return yup.object(schemaObj);
  }, [fleetConf]);

  useFetchInsurerSettings();

  useEffect(() => {
    if (isEditFleet) {
      dispatch(loadFleetWithInsurersActions.trigger({ fleetId }));
      dispatch(
        loadFleetUsersActions.trigger({
          fleetId,
        })
      );
    }
  }, [route, fleetId, isEditFleet, dispatch, isSuccess]);

  const onSubmit = async (data) => {
    let payload = { ...fleet, ...data };
    //let fleetVehiclesExcel = await prepareExcelForPayload(file);

    const dayMonthDueDate = formatDayMonthValueForPayload(payload?.dueDate);
    const dayMonthFiscalDate = formatDayMonthValueForPayload(
      payload?.fiscalDate
    );
    ///
    if (route === constants.createFleet) {
      onCreateFleet(
        {
          fleet: {
            ...fleet,
            fleetId: null,
            fleetName: payload?.fleetName,
            webBasePartnerNumber: payload?.webBasePartnerNumber,
          },
          fleetUsers: tempUsers,
          fleetVehiclesExcel: file,
          businessYear: null,
        },
        {
          onSuccess: (res) => {
            toast.success(t("bfm.fleetForm.successfullyCreatedFleet"));
            const newFleetId = res?.data?.fleet?.fleetId;
            navigate(`/fleets/${newFleetId}`);
          },
        }
      );
    } else if (isEditFleet) {
      fleetConf.forEach((item) => {
        if (item.name === "countryCode") {
          payload.countryCode = fleet?.countryCode;
        }
        if (item.name === "mainMtplInsurerPartnerNumber") {
          payload.mainMtplInsurerPartnerNumber =
            (data?.mainMtplInsurerPartnerNumber as any)?.value ?? null;
        }
        if (item.name === "mainHullInsurerPartnerNumber") {
          payload.mainHullInsurerPartnerNumber =
            (data?.mainHullInsurerPartnerNumber as any)?.value ?? null;
        }
        if (item.name === "mainFleetLegalInsurerPartnerNumber") {
          payload.mainFleetLegalInsurerPartnerNumber =
            (data?.mainFleetLegalInsurerPartnerNumber as any)?.value ?? null;
        }
        payload.dueDay = dayMonthDueDate.dayField;
        payload.dueMonth = dayMonthDueDate.monthField;
        payload.businessYearStartDay = dayMonthFiscalDate.dayField;
        payload.businessYearStartMonth = dayMonthFiscalDate.monthField;
      });

      onUpdateFleet(
        {
          fleet: payload,
          fleetUsers: tempUsers,
          businessYear: null,
          fleetVehiclesExcel: {
            ...file,
            isOnlyExistingVehiclesAttributesUpdate:
              data?.isOnlyExistingVehiclesAttributesUpdate,
          },
        },
        {
          onSuccess: (res) => {
            dispatch(loadFleetWithInsurersActions.trigger({ fleetId }));
            dispatch(
              loadFleetUsersActions.trigger({
                fleetId,
              })
            );
            toast.success(t("bfm.fleetForm.successfullyUpdatedFleet"));
          },
        }
      );
    }
  };

  useUnmount(() => {
    dispatch(clearClientFromInternalNumber(null));
    dispatch(resetState());
  });

  return (
    <>
      {isLoading && <FormSubmitSpinner />}
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnChange={false}
        // validateOnBlur={false}
        onSubmit={async (values, { setSubmitting }) => {
          onSubmit(values);
          await delay(400);
          setSubmitting(false);
        }}
      >
        {(props) => {
          return (
            <>
              <div id="fleet-page-wrapper" className={classes.main}>
                <form onSubmit={props.handleSubmit}>
                  <div className={classes.navigationWrap}>
                    <Navigation />
                    {(isEditFleet || isCreateFleet) && (
                      <FleetFormToolbar setFile={setFile} />
                    )}
                  </div>
                  {isEditFleet && !fleet ? (
                    <FleetFormSkeleton />
                  ) : (
                    <div>
                      <div className={classes.wrap}>
                        <FleetSidebar />
                        <div className={classes.form}>
                          <FleetTab
                            userRole={userRole}
                            file={file}
                            setFile={setFile}
                            fleetRef={fleetRef}
                            fleetConf={fleetConf}
                            formikProps={props}
                          />
                          {fleetAppSettings?.INCLUDE_GROUP_POLICY && (
                            <InsurerGroupPolicies />
                          )}

                          <Users usersRef={usersRef} />
                          {isEditFleet && (
                            <>
                              <History historyRef={historyRef} />
                              {fleetAppSettings?.INCLUDE_INSURER_DEFAULT_SETTINGS ? (
                                <InsurerDefaultSettings
                                  insurerSettingsRef={insurerSettingsRef}
                                />
                              ) : null}
                              <DefaultSettings
                                settingRef={settingRef}
                                userRole={userRole}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </form>
                <Dialog />
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

const getClassNames = (theme) =>
  mergeStyleSets({
    wrap: {
      background: theme.palette.white,
      boxShadow:
        "0 3.2px 7.2px 0 rgba(0,0,0,.132), 0 0.6px 1.8px 0 rgba(0,0,0,.108)",
      display: "flex",
      margin: "50px auto 0",
      borderRadius: "4px",
      maxWidth: "80%",
      height: "90%", //
      position: "relative",
      marginBottom: "100px",
    },
    navigationWrap: {
      position: "sticky",
      top: 0,
      zIndex: 2,
    },
    main: {
      overflow: "auto", //
      height: "100%",
      position: "relative",
      paddingTop: 0,
      flex: 1,
    },
    form: {
      width: "calc(100% - 200px)",
      padding: "20px",
      overfow: "auto",
    },
  });

export default FleetForm;

import React from "react";
import { useTranslation } from "react-i18next";
import { TlpSelect } from "./TlpSelect";

type Props = {
  tlpValue: string | null;

  onChange: (val: { text: string; key: string | null }) => void;
};

export const TlpFilter = ({ tlpValue, onChange }: Props) => {
  const { t } = useTranslation();

  const tlpOptions = [
    {
      text: t("bfm.tradeLicensePlates.label"),
      key: "true",
    },
    {
      text: t("bfm.vehicles"),
      key: "false",
    },
    {
      text: t("bfm.vehiclesAndTradeLicensePlates"),
      key: "null",
    },
  ];

  return (
    <TlpSelect
      value={tlpOptions.find((option) => option.key === tlpValue)}
      onChange={(o: any) => {
        onChange(o);
      }}
      options={tlpOptions}
      isClearable={false}
      isLoading={false}
    />
  );
};

import { useMutation } from "react-query";

import axios from "axios";
import { handleAxiosError } from "store/util";
import { FleetWithPremiumsResponse } from "types/types";
import { MUTATION_KEYS } from "../queryKeys";

const url = process.env.REACT_APP_BASE_URL;

interface IChangeFleetPremiumDueDateRequestParams {
  fleetId: string;
  body: {
    criteria: any;
    insurerPartnerNumber: number;
    premiumType: number;
    validFromDate: string;
    reasonForModificationComment: string;
    dueDay: number;
    dueMonth: number;
    documentDate: string | null;
    isPolicyIssued: boolean;
  };
}

export const changeDueDate = async (
  params: IChangeFleetPremiumDueDateRequestParams
) => {
  const { fleetId, body } = params;
  return await axios.patch<FleetWithPremiumsResponse>(
    `${url}/ChangeFleetPremiumDueDate/${fleetId}`,
    body
  );
};

export function useChangeFleetPremiumDueDate() {
  return useMutation(changeDueDate, {
    mutationKey: MUTATION_KEYS.changeFleetPremiumDueDate,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

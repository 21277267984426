import { toast } from "@greco/components";
import { StyledPrimaryButton } from "components/styled";
import { useTransferTempVehicle } from "hooks/data/mutations/useTransferTempVehicle";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setShowTEMP } from "store/fleetPage";
import { setVehicle } from "store/vehicle";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { routes } from "../../../config/routes";

type Props = {
  disabled?: boolean;
  isTradeLicensePlate?: boolean;
};

export const TransferVehicleButton = ({
  disabled,

  isTradeLicensePlate,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fleetId, vehicleId } = useParams() as any;
  const { mutate: onTransferTempVehicle, isLoading } = useTransferTempVehicle();

  const theme = useTheme() as IStyledTheme;
  return (
    <StyledPrimaryButton
      style={{
        background: theme.palette.redDark,
        marginRight: "5px",
      }}
      onClick={() => {
        onTransferTempVehicle(
          {
            fleetId,
            vehicleId,
          },
          {
            onSuccess: (res) => {
              dispatch(setVehicle(res.data));
              toast.success(t("bfm.transferVehicleSuccess"));
              if (isTradeLicensePlate) {
                navigate(routes.tradeLicensePlates.getPath(res?.data?.fleetId));
                dispatch(setShowTEMP(false));
              } else {
                navigate(routes.fleet.getPath(res?.data?.fleetId));
              }
            },
          }
        );
      }}
      disabled={disabled || isLoading}
    >
      <i
        className={"las la-exchange-alt"}
        style={{
          marginRight: "5px",
        }}
      />
      {t("bfm.transferVehicle.label")}
    </StyledPrimaryButton>
  );
};

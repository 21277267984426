import axios from "axios";
import { useQuery } from "react-query";
import { QUERY_KEYS } from "../queryKeys";

const url = process.env.REACT_APP_BASE_URL;

type TSettings = {
  fleetId: string | number;
  fleetInsurerSettingId?: string | number;
};

const getFleetInsurerDefaultSettings = async ({ fleetId }: TSettings) => {
  const response = await axios.get(`${url}/GetFleetInsurerSettings/${fleetId}`);

  return response.data;
};

export function useGetFleetInsurerDefaultSettings(fleetId: string | number) {
  return useQuery(
    [QUERY_KEYS.fleetInsurerDefaultSettings, fleetId],
    () => getFleetInsurerDefaultSettings({ fleetId }),
    {
      enabled: !!fleetId,
    }
  );
}

import { GrecoSpinner } from "@greco/components";

import { isFullOrReadonlyAdmin } from "form/util";
import { useQuery } from "hooks/useQuery";
import React, { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "store/hooks";
import { AppDispatch } from "store/store";
import { loadFleetsActions, resetState } from "../../store/fleetsPage";
export const Homepage = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const userRole = useSelector((s) => s.auth.userRole);
  const fleetIds = useSelector((s) => s.fleetsPage.ids);

  const query = useQuery();
  const clientId = query.get("clientId");

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    dispatch(loadFleetsActions.trigger({ clientId }));
  }, [clientId]);

  if (
    userRole !== undefined &&
    userRole !== null &&
    fleetIds !== undefined &&
    fleetIds !== null
  ) {
    if (!isFullOrReadonlyAdmin(userRole) && fleetIds.length === 1) {
      navigate(`/fleets/${fleetIds[0]}`);
      return;
    } else {
      navigate(`/fleetlist`);
      return;
    }
  }
  return (
    <>
      <Suspense fallback={<GrecoSpinner />}>
        <GrecoSpinner />
      </Suspense>
    </>
  );
};

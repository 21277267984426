import {
  ContextualMenu,
  IContextualMenuItem,
  mergeStyles,
} from "@fluentui/react";

import { isExternalOrReadonlyAdmin } from "form/util";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../config/routes";
import { setDeleteDialog } from "../../../store/fleetsPage";

import { useSelector } from "../../../store/hooks";
import { Fleet } from "../../../types/types";

type Props = {
  fleet: Fleet;
  target: any;
  onDismiss: any;
};
const VehicleContextMenu = ({ fleet, target, onDismiss }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userRole = useSelector((s) => s.auth.userRole);

  const items: IContextualMenuItem[] = [
    {
      key: "open",
      text: t("bfm.openInNewTab.label"),
      onClick: () => {
        window.open(routes.fleet.getPath(fleet?.fleetId));
      },
    },
    {
      key: "edit",
      text: t("bfm.editVehicle.label"),
      onClick: () => {
        navigate(routes.editFleet.getPath(fleet?.fleetId));
      },
    },
    {
      key: "delete",
      text: t("bfm.deleteTempVehicle.label"),
      onClick: () => {
        dispatch(
          setDeleteDialog({
            isOpen: true,
            fleet,
          })
        );
      },
      className: isExternalOrReadonlyAdmin(userRole) ? displayNoneClass : "",
    },
  ];

  return (
    <ContextualMenu
      items={items}
      target={target}
      onDismiss={onDismiss}
      gapSpace={10}
    />
  );
};

export default React.memo(VehicleContextMenu);

const displayNoneClass = mergeStyles({
  display: "none",
});

import { useMutation } from "react-query";
import {
  VehiclePolicyStatusRequestParams,
  deleteVehiclePolicyStatus,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const deleteVehiclePolicyStatusFn = async ({
  rowVersion,
  params,
}: {
  rowVersion: string;
  params: VehiclePolicyStatusRequestParams;
}) => {
  return await deleteVehiclePolicyStatus(rowVersion, params);
};

export function useDeleteVehiclePolicyStatus() {
  return useMutation(deleteVehiclePolicyStatusFn, {
    mutationKey: MUTATION_KEYS.deleteVehiclePolicyStatus,
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { MessageBar, MessageBarButton, MessageBarType } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  error: string;
  onRetry: () => void;
};

export const ErrorRetry = ({ error, onRetry }: Props) => {
  const { t } = useTranslation();

  return (
    <MessageBar
      messageBarType={MessageBarType.error}
      isMultiline={false}
      actions={
        <div>
          <MessageBarButton
            text={t("Retry")}
            iconProps={{ iconName: "Refresh" }}
            onClick={onRetry}
          />
        </div>
      }
    >
      {error}
    </MessageBar>
  );
};

import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS, QUERY_KEYS } from "../queryKeys";

const url = process.env.REACT_APP_BASE_URL;

type TSettings = {
  fleetId: string | number;
  fleetInsurerSettingId?: string | number;
};

const deleteFleetInsurerDefaultSetting = async ({
  fleetId,
  fleetInsurerSettingId,
}: TSettings) => {
  const response = await axios.delete(
    `${url}/RemoveFleetInsurerSetting/${fleetId}/${fleetInsurerSettingId}`
  );

  return response;
};

export function useDeleteFleetInsurerDefaultSetting() {
  const queryClient = useQueryClient();

  return useMutation(deleteFleetInsurerDefaultSetting, {
    mutationKey: MUTATION_KEYS.deleteFleetInsurerDefaultSetting,

    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.fleetInsurerDefaultSettings);
    },
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../store/hooks";
import { useBookingMenuItems } from "./useBookingMenuItems";
import { useLicensePlateSegmentMenuItems } from "./useLicensePlateSegmentMenuItems";
import { useOneTimePremiumMenuItems } from "./useOneTimePremiumMenuItems";
import { usePolicyStatusSegmentMenuItems } from "./usePolicyStatusSegmentMenuItems";
import { usePremiumSegmentMenuItems } from "./usePremiumSegmentMenuItems";
import { useSharedLicensePlateSegmentMenuItems } from "./useSharedLicensePlateSegmentMenuItems";
import { useStatusSegmentMenuItems } from "./useStatusSegmentMenuItems";

declare global {
  interface Window {
    ContextMenuItems: any;
  }
}
window.ContextMenuItems = window.ContextMenuItems || {};

export const useContextMenuItems = () => {
  const timeline = useSelector((s) => s.timeline.timeline);
  const readOnly = useSelector((s) => s.timeline.readOnly);

  const types = useSelector((s) => s.timeline.types);
  const items = [];
  items.push(...usePremiumSegmentMenuItems(timeline));
  items.push(...useBookingMenuItems());
  items.push(...useOneTimePremiumMenuItems());
  items.push(...useStatusSegmentMenuItems());
  items.push(...usePolicyStatusSegmentMenuItems());
  items.push(...useLicensePlateSegmentMenuItems());

  const shareItems = useSharedLicensePlateSegmentMenuItems(timeline, types);
  items.push(...shareItems);

  const dispatch = useDispatch();
  const [dialogState, setDialogState] = useState(null);

  const timelineSegments = useSelector((s) => s.timeline.timeline.data);

  useEffect(() => {
    const method = (dialogType: string, date: string, domainValue: string) => {
      setDialogState({
        dialogType,
        date,
        domainValue,
      });
    };
    window.ContextMenuItems.openTimelineDialog = method;
    window.ContextMenuItems.dispatch = dispatch;
  }, []);

  useEffect(() => {
    if (!dialogState) return;
    const dateObj: Date = moment(dialogState.date).toDate();
    const filteredData = timelineSegments
      .filter((item) => {
        return (
          (item.endDate !== null &&
            moment(item.startDate).isSameOrBefore(moment(dateObj), "day") &&
            moment(item.endDate).isSameOrAfter(moment(dateObj), "day")) ||
          (item.endDate === null &&
            moment(item.startDate).isSame(moment(dateObj), "day"))
        );
      })
      .filter((item) => item.domain.value === dialogState.domainValue)
      .filter((item) => {
        const dialogType = dialogState.dialogType + "";
        if (dialogType.includes("booking")) {
          if (item.segmentType === "booking") {
            return true;
          } else {
            return false;
          }
        } else {
          if (item.segmentType !== "booking") {
            return true;
          } else {
            return false;
          }
        }
      });

    if (dialogState.dialogType === "change status") {
      window.ContextMenuItems.dispatch({
        type: "vehicle/setChangeVehicleStatusDialog",
        payload: {
          fleetId: filteredData[0].associatedObject.fleetId,
          isOpen: true,
          vehicleIds: [filteredData[0].associatedObject.vehicleId],
          date: dateObj,
          addNew: false,
        },
      });
    } else if (dialogState.dialogType === "change policy status") {
      window.ContextMenuItems.dispatch({
        type: "vehicle/setChangePolicyStatusDialog",
        payload: {
          fleetId: filteredData[0].associatedObject.fleetId,
          isOpen: true,
          vehicleIds: [filteredData[0].associatedObject.vehicleId],
          date: dateObj,
          addNew: false,
        },
      });
    } else {
      window.ContextMenuItems.dispatch({
        type: "timeline/openDialog",
        payload: {
          dialogType: dialogState.dialogType,
          contextMenuItem: filteredData[0],
          contextMenuDate: dateObj,
        },
      });
    }
  }, [dialogState, timelineSegments]);

  if (readOnly) return shareItems;
  return items;
};

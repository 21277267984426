import { RefObject, useCallback, useEffect, useState } from "react";
import { useCurrentRoute } from "config/routes";
import { useDispatch } from "react-redux";
import { useSelector } from "store/hooks";
import { setTab } from "store/fleetForm";
import { fleetTabs } from "../FleetForm/FleetForm";
import { constants } from "library/constants";
import { usePrevious } from "@uifabric/react-hooks";

type Props = {
  fleetRef: RefObject<HTMLElement>;
  usersRef: RefObject<HTMLElement>;
  settingRef: RefObject<HTMLElement>;
  historyRef: RefObject<HTMLElement>;
};

const useFleetTabChange = ({
  fleetRef,
  usersRef,
  settingRef,
  historyRef,
}: Props) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(fleetTabs.fleetTab);
  const route = useCurrentRoute();

  const wrapElement = document.querySelector("#fleet-page-wrapper");
  const changeActiveTab = useCallback(
    (wrapElement, tab) => {
      const { fleetTab, usersTab, historyTab, settingsTab } = fleetTabs;
      const mainScrollHeight = wrapElement.scrollTop;

      const usersElement = usersRef?.current?.offsetTop - 350;
      const historyElement = historyRef?.current?.offsetTop - 350;
      const settingsElement = settingRef?.current?.offsetTop - 350;

      if (selectedTab !== fleetTab && mainScrollHeight <= usersElement) {
        setSelectedTab(fleetTab);
      }

      if (route === constants.createFleet) {
        if (selectedTab !== usersTab && mainScrollHeight > usersElement) {
          setSelectedTab(usersTab);
        }
        return; // return when navigation has only two tabs (fleet and users)
      } else {
        if (
          selectedTab !== usersTab &&
          mainScrollHeight > usersElement &&
          mainScrollHeight < historyElement
        ) {
          setSelectedTab(usersTab);
        }
      }

      if (
        selectedTab !== historyTab &&
        mainScrollHeight > historyElement &&
        mainScrollHeight < settingsElement
      ) {
        setSelectedTab(historyTab);
      }

      if (selectedTab !== settingsTab && mainScrollHeight > settingsElement) {
        setSelectedTab(settingsTab);
      }
    },
    [fleetRef, usersRef, historyRef, settingRef, selectedTab]
  );

  const previousTab = usePrevious(selectedTab);
  useEffect(() => {
    if (previousTab !== selectedTab) {
      dispatch(setTab(selectedTab));
    }
  }, [selectedTab]);

  useEffect(() => {
    if (route === constants.editFleet || route === constants.createFleet) {
      wrapElement?.addEventListener("scroll", () => {
        changeActiveTab(wrapElement, selectedTab);
      });
    }
    return () => {
      wrapElement?.removeEventListener("scroll", () =>
        changeActiveTab(wrapElement, selectedTab)
      );
    };
  }, [changeActiveTab, route, selectedTab, wrapElement]);
};

export default useFleetTabChange;

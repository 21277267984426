import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { Setting } from "types/types";
import { urlFormat } from "utils/utils";
import { MUTATION_KEYS, QUERY_KEYS } from "../queryKeys";

const uadUrl = urlFormat(process.env.REACT_APP_BFF_HOST_UAD);

export const updateAppSetting = async (setting: Setting) => {
  return await axios.patch<Setting[]>(`${uadUrl}/updateappsettings`, [setting]);
};

export function useUpdateAppSettings() {
  const queryClient = useQueryClient();

  return useMutation(updateAppSetting, {
    mutationKey: MUTATION_KEYS.updateAppSetting,

    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.appSettings);
    },
    onError: (error) => {
      // handle error
    },
  });
}

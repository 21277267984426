import { useMutation } from "react-query";
import { createVehicle } from "store/api/api";
import { handleAxiosError } from "store/util";
import { Vehicle } from "types/types";
import { MUTATION_KEYS } from "../queryKeys";

const createVehicleFn = async (params: Vehicle) => {
  return await createVehicle(params);
};

export function useCreateVehicle() {
  return useMutation(createVehicleFn, {
    mutationKey: MUTATION_KEYS.createVehicle,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { ExpandedColumn } from "../types/columns";

export const hydrateColumn = ({
  column,
  defaultColumns,
}: {
  column: ExpandedColumn;
  defaultColumns: ExpandedColumn[];
}) => {
  const defaultCol = defaultColumns?.find((dc) => dc.key === column.key);
  if (!defaultCol) return null;
  const mappedCol = { ...column };
  if (mappedCol.filter && mappedCol.filter.type === "date") {
    if (mappedCol.filter.date1) {
      mappedCol.filter.date1 = new Date(mappedCol.filter.date1);
    }
    if (mappedCol.filter.date2) {
      mappedCol.filter.date2 = new Date(mappedCol.filter.date2);
    }
  } else if (mappedCol.key === "hullProduct") {
    mappedCol.filter = defaultCol.filter;
  }

  // mappedCol.detailsListColumn = defaultCol.detailsListColumn; dusanov
  mappedCol.onRender = defaultCol.onRender;

  mappedCol.excel = defaultCol.excel;
  return mappedCol;
};

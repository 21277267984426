import { HoverCard, HoverCardType, mergeStyleSets } from "@fluentui/react";
import React from "react";

const ColumnHoverCard = ({ children, vehicle }) => {
  const onRenderPlainCard = (vehicle: any): JSX.Element => {
    return (
      <div className={classes.plainCard}>
        <p>
          License Plate:{" "}
          <span className={classes.text}>{vehicle?.licensePlate}</span>
        </p>
        <p>
          User : <span className={classes.text}>{vehicle?.vehicleUser}</span>
        </p>
      </div>
    );
  };

  const plainCardProps: any = {
    onRenderPlainCard: onRenderPlainCard,
    renderData: vehicle,
  };

  return (
    <HoverCard
      type={HoverCardType.plain}
      plainCardProps={plainCardProps}
      instantOpenOnClick={true}
    >
      {children}
    </HoverCard>
  );
};

const classes = mergeStyleSets({
  plainCard: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    alignItems: "flex-start",
    justifyContent: "space-between",
    height: "60px",
  },

  text: {
    fontWeight: "bold",
  },
});

export default ColumnHoverCard;

import { useGetFleetVehicleDefaultSettings } from "hooks/data/queries/getFleetVehicleDefaultSettings";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  resetDialogDefaultValue,
  setDialogDefaultValueOperationResult,
} from "store/fleetForm";
import { FleetDefaultSetting, PremiumType } from "types/types";
import { isStringEmpty } from "utils/utils";
import { toast } from "../../../../components/FluentToast";
import { useSelector } from "../../../../store/hooks";

export const useDefaultSettings = () => {
  const { fleetId } = useParams() as any;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const taxonomy = useSelector((s) => s.taxonomy);

  const { data: defaultSettings } = useGetFleetVehicleDefaultSettings(fleetId);

  const formValues = useSelector(
    (s) => s.fleetForm.defaultValueOperation.formValues
  );

  const vehicleType = formValues?.vehicleType;

  const premiumType = formValues?.premiumType;

  const weightCapacityKg = formValues?.weightCapacityKgRange;
  const numberOfSeatsMoped = formValues?.numberOfSeatsMoped;
  const numberOfSeatsRange = formValues?.numberOfSeatsRange;

  const carUsage = formValues?.carUsage;
  const powerKwFrom = formValues?.powerKwFrom;
  const powerKwTo = formValues?.powerKwTo;

  const emptyWeightKgFrom = formValues?.emptyWeightKgFrom;
  const emptyWeightKgTo = formValues?.emptyWeightKgTo;

  const cubicCapacityCcm = formValues?.cubicCapacityCcmRange;
  const maxGrossWeightKg = formValues?.maxGrossWeightKgRange;
  const defaultValueOperation = useSelector(
    (s) => s.fleetForm.defaultValueOperation
  );
  useEffect(() => {
    if (
      defaultValueOperation.fieldNames &&
      defaultValueOperation.formValues &&
      !defaultValueOperation.results
    ) {
      const fieldNames = defaultValueOperation.fieldNames;
      const formValues = defaultValueOperation.formValues;

      let insurer = formValues.insurerPartnerNumber?.value;
      const isTradeLicensePlate = formValues.isTradeLicensePlate;

      const fieldNameMapping = {
        annualGrossPremium: "annualGrossPremium",
        deductible: "deductible",
      };
      let results: any = {};
      for (const fieldName of fieldNames) {
        const params = {
          attributeName: fieldNameMapping[fieldName],
          premiumType: premiumType ? premiumType.value : null,
          defaultSettings: defaultSettings.map((setting) => {
            return {
              ...setting,
              settingCriteria: {
                ...setting.settingCriteria,
                carUsageCodes: setting.settingCriteria.carUsageCodes || [],
                cubicCapacityCcmRangeCodes:
                  setting.settingCriteria.cubicCapacityCcmRangeCodes || [],
                emptyWeightKgs: setting.settingCriteria.emptyWeightKgs || [],
                isTradeLicensePlate:
                  setting.settingCriteria.isTradeLicensePlate || false,
                listPrices: setting.settingCriteria.listPrices || [],
                maxGrossWeightKgRangeCodes:
                  setting.settingCriteria.maxGrossWeightKgRangeCodes || [],
                numberOfSeatsMopedCodes:
                  setting.settingCriteria.numberOfSeatsMopedCodes || [],
                numberOfSeatsRangeCodes:
                  setting.settingCriteria.numberOfSeatsRangeCodes || [],
                powerKws: setting.settingCriteria.powerKws || [],
                vehicleTypeCodes:
                  setting.settingCriteria.vehicleTypeCodes || [],
                weightCapacityKgRangeCodes:
                  setting.settingCriteria.weightCapacityKgRangeCodes || [],
              },
            };
          }),
          formValues,
          insurerPartnerNumber: insurer,
          vehicleType,
          carUsage,
          weightCapacityKg,
          powerKwFrom,
          powerKwTo,
          emptyWeightKgFrom,
          emptyWeightKgTo,
          cubicCapacityCcm,
          maxGrossWeightKg,

          numberOfSeatsMoped,

          numberOfSeatsRange,
          taxonomy: taxonomy,
          isTradeLicensePlate: isTradeLicensePlate,
        };
        let premium = determineDefaultValue(params);

        // if (!premium) {
        //   premium = "";
        // }

        if (params.attributeName === "annualGrossPremium") {
          let premiumString = null;
          try {
            premiumString = premium.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            results[fieldName] = premiumString.replaceAll(".", ",");
          } catch (ex) {
            //
          }
        }

        if (params.attributeName === "deductible") {
          let premiumString = null;
          try {
            let premiumJson = JSON.parse(premium as string);
            if (premiumJson.hullDeductibleMinAmount) {
              premiumString =
                premiumJson.hullDeductibleMinAmount.toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              results["hullDeductibleMinAmount"] = premiumString;
            }
            if (premiumJson.hullDeductibleMaxAmount) {
              premiumString =
                premiumJson.hullDeductibleMaxAmount.toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              results["hullDeductibleMaxAmount"] = premiumString;
            }
            if (premiumJson.hullDeductiblePercent) {
              premiumString = premiumJson.hullDeductiblePercent.toLocaleString(
                "de-DE",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              );
              premiumString = premiumString + "";
              results["hullDeductiblePercent"] = premiumString;
            }
            if (premiumJson.hullDeductibleComment) {
              results["hullDeductibleComment"] =
                premiumJson.hullDeductibleComment;
            }
          } catch (ex) {
            //
          }
        }
      }
      if (Object.keys(results).length === 0) {
        !defaultValueOperation?.noMessage &&
          toast.info(t("bfm.fillInFleetDefaultsValueNoValue.label"));
        dispatch(resetDialogDefaultValue(null));
      } else {
        !defaultValueOperation?.noMessage &&
          toast.success(t("bfm.fillInFleetDefaultsValueSuccess.label"));
      }

      dispatch(setDialogDefaultValueOperationResult(results));
    }
  }, [defaultValueOperation]);
};

const determineDefaultValue = (params) => {
  const defaultSettings: FleetDefaultSetting[] = params.defaultSettings;
  const formValues = params.formValues;
  // Filter default settings by attribute name and premium type
  let interestingDefaultSettings = defaultSettings
    .filter((ds) => ds.attributeName === params.attributeName)
    .filter((ds) => ds.premiumType === params.premiumType)
    .filter(
      (ds) =>
        !!ds.settingCriteria?.isTradeLicensePlate ===
        !!params.isTradeLicensePlate
    );

  // Filter default settings by insurer
  if (!!!params.insurerPartnerNumber) return null;
  interestingDefaultSettings = interestingDefaultSettings.filter(
    (ds) => ds.insurerPartnerNumber === params.insurerPartnerNumber
  );

  // Filter default settings by main group
  // if (!!!params.vehicleMainGroup) return null;
  // interestingDefaultSettings = interestingDefaultSettings.filter(
  //   (ds) => ds.vehicleMainGroup === params.vehicleMainGroup
  // );
  if (
    formValues.insurerGroupPolicyNumber &&
    formValues.insurerGroupPolicyNumber !== null
  ) {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        ds.insurerGroupPolicyNumber === null ||
        ds.insurerGroupPolicyNumber ===
          formValues.insurerGroupPolicyNumber.value
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        ds.insurerGroupPolicyNumber === undefined ||
        ds.insurerGroupPolicyNumber === null
    );
  }

  interestingDefaultSettings.sort((a, b) => {
    if (
      a.insurerGroupPolicyNumber == null &&
      b.insurerGroupPolicyNumber != null
    ) {
      return 1; // a should come after b
    } else if (
      a.insurerGroupPolicyNumber != null &&
      b.insurerGroupPolicyNumber == null
    ) {
      return -1; // a should come before b
    } else {
      return 0; // keep the original order between two non-null or two null values
    }
  });

  if (params.vehicleType) {
    interestingDefaultSettings = interestingDefaultSettings.filter((ds) =>
      params.vehicleType
        .map((vt) => vt.value)
        .every((vt) => ds.settingCriteria.vehicleTypeCodes.includes(vt))
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !!!(
          ds.settingCriteria.vehicleTypeCodes &&
          ds.settingCriteria.vehicleTypeCodes.length > 0
        )
    );
  }

  if (params.carUsage) {
    interestingDefaultSettings = interestingDefaultSettings.filter((ds) =>
      params.carUsage
        .map((cu) => cu.value)
        .every((cu) => ds.settingCriteria.carUsageCodes.includes(cu))
    );
    interestingDefaultSettings.sort(
      nullsLast("settingCriteria.carUsageCodes", true)
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !!!(
          ds.settingCriteria.carUsageCodes &&
          ds.settingCriteria.carUsageCodes.length > 0
        )
    );
  }

  // If weight capacity is specified Leave only those default settings
  // where weight capacity range is not set or it matches specified value
  if (params.weightCapacityKg) {
    interestingDefaultSettings = interestingDefaultSettings.filter((ds) =>
      params.weightCapacityKg
        .map((cu) => cu.value)
        .every((cu) =>
          ds.settingCriteria.weightCapacityKgRangeCodes.includes(cu)
        )
    );
    interestingDefaultSettings.sort(
      nullsLast("settingCriteria.weightCapacityKgRangeCodes", true)
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !!!(
          ds.settingCriteria.weightCapacityKgRangeCodes &&
          ds.settingCriteria.weightCapacityKgRangeCodes.length > 0
        )
    );
  }

  // If powerKw is specified Leave only those default settings
  // where powerKw range is not set or it matches specified value

  if (!isStringEmpty(params.powerKwFrom)) {
    const formattedPowerKwFrom = Number(params.powerKwFrom);
    interestingDefaultSettings = interestingDefaultSettings.filter((ds) => {
      return (
        !ds.settingCriteria.powerKws ||
        ds.settingCriteria.powerKws.length === 0 ||
        formattedPowerKwFrom >= ds.settingCriteria.powerKws[0].from
      );
    });
    interestingDefaultSettings.sort(
      nullsLast("settingCriteria.powerKws", true)
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !ds.settingCriteria.powerKws || ds.settingCriteria.powerKws.length === 0
    );
  }
  if (!isStringEmpty(params.powerKwTo)) {
    const formattedPowerKwTo = Number(params.powerKwTo);
    interestingDefaultSettings = interestingDefaultSettings.filter((ds) => {
      return (
        !ds.settingCriteria.powerKws ||
        ds.settingCriteria.powerKws.length === 0 ||
        formattedPowerKwTo <= ds.settingCriteria.powerKws[0].to
      );
    });
    interestingDefaultSettings.sort(
      nullsLast("settingCriteria.powerKws", true)
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !ds.settingCriteria.powerKws || ds.settingCriteria.powerKws.length === 0
    );
  }

  if (!isStringEmpty(params.emptyWeightKgFrom)) {
    const formattedEmptyWeightKgFrom = Number(params.emptyWeightKgFrom);

    interestingDefaultSettings = interestingDefaultSettings.filter((ds) => {
      return (
        !ds.settingCriteria.emptyWeightKgs ||
        ds.settingCriteria.emptyWeightKgs.length === 0 ||
        formattedEmptyWeightKgFrom >= ds.settingCriteria.emptyWeightKgs[0].from
      );
    });
    interestingDefaultSettings.sort(
      nullsLast("settingCriteria.emptyWeightKgs", true)
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !ds.settingCriteria.emptyWeightKgs ||
        ds.settingCriteria.emptyWeightKgs.length === 0
    );
  }

  if (!isStringEmpty(params.emptyWeightKgTo)) {
    const formattedEmptyWeightKgTo = Number(params.emptyWeightKgTo);

    interestingDefaultSettings = interestingDefaultSettings.filter((ds) => {
      return (
        !ds.settingCriteria.emptyWeightKgs ||
        ds.settingCriteria.emptyWeightKgs.length === 0 ||
        formattedEmptyWeightKgTo <= ds.settingCriteria.emptyWeightKgs[0].to
      );
    });
    interestingDefaultSettings.sort(
      nullsLast("settingCriteria.emptyWeightKgs", true)
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !ds.settingCriteria.emptyWeightKgs ||
        ds.settingCriteria.emptyWeightKgs.length === 0
    );
  }

  // If maxGrossWeightKgRangeCodes is specified Leave only those default settings
  // where maxGrossWeightKgRangeCodes range is not set or it matches specified value
  // If weight capacity is specified Leave only those default settings
  // where weight capacity range is not set or it matches specified value
  if (params.maxGrossWeightKg) {
    interestingDefaultSettings = interestingDefaultSettings.filter((ds) =>
      params.maxGrossWeightKg
        .map((cu) => cu.value)
        .every((cu) =>
          ds.settingCriteria.maxGrossWeightKgRangeCodes.includes(cu)
        )
    );
    interestingDefaultSettings.sort(
      nullsLast("settingCriteria.maxGrossWeightKgRangeCodes", true)
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !!!(
          ds.settingCriteria.maxGrossWeightKgRangeCodes &&
          ds.settingCriteria.maxGrossWeightKgRangeCodes.length > 0
        )
    );
  }
  // If cubicCapacityCcm is specified Leave only those default settings
  // where cubicCapacityCcm range is not set or it matches specified value
  if (params.cubicCapacityCcm) {
    interestingDefaultSettings = interestingDefaultSettings.filter((ds) =>
      params.cubicCapacityCcm
        .map((cu) => cu.value)
        .every((cu) =>
          ds.settingCriteria.cubicCapacityCcmRangeCodes.includes(cu)
        )
    );
    interestingDefaultSettings.sort(
      nullsLast("settingCriteria.cubicCapacityCcmRangeCodes", true)
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !!!(
          ds.settingCriteria.cubicCapacityCcmRangeCodes &&
          ds.settingCriteria.cubicCapacityCcmRangeCodes.length > 0
        )
    );
  }

  // If numberOfSeatsRange is specified Leave only those default settings
  // where numberOfSeatsRange range is not set or it matches specified value
  if (params.numberOfSeatsRange) {
    interestingDefaultSettings = interestingDefaultSettings.filter((ds) =>
      params.numberOfSeatsRange
        .map((cu) => cu.value)
        .every((cu) => ds.settingCriteria.numberOfSeatsRangeCodes.includes(cu))
    );
    interestingDefaultSettings.sort(
      nullsLast("settingCriteria.numberOfSeatsRangeCodes", true)
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !!!(
          ds.settingCriteria.numberOfSeatsRangeCodes &&
          ds.settingCriteria.numberOfSeatsRangeCodes.length > 0
        )
    );
  }

  // If numberOfSeatsMoped is specified Leave only those default settings
  // where numberOfSeatsMoped range is not set or it matches specified value
  if (params.numberOfSeatsMoped) {
    interestingDefaultSettings = interestingDefaultSettings.filter((ds) =>
      params.numberOfSeatsMoped
        .map((cu) => cu.value)
        .every((cu) => ds.settingCriteria.numberOfSeatsMopedCodes.includes(cu))
    );
    interestingDefaultSettings.sort(
      nullsLast("settingCriteria.numberOfSeatsMopedCodes", true)
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !!!(
          ds.settingCriteria.numberOfSeatsMopedCodes &&
          ds.settingCriteria.numberOfSeatsMopedCodes.length > 0
        )
    );
  }

  if (
    (params.premiumType ===
      params.taxonomy.PremiumType.byCode[PremiumType.HULL].id ||
      params.premiumType ===
        params.taxonomy.PremiumType.byCode[PremiumType.HULL_BASIC].id) &&
    params.attributeName === "annualGrossPremium"
  ) {
    if (!!!params.listPriceType) return null;
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        ds.settingCriteria.listPrices &&
        ds.settingCriteria.listPrices.length > 0 &&
        ds.settingCriteria.listPrices[0].type === params.listPriceType
    );
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !ds.settingCriteria.listPrices ||
        ds.settingCriteria.listPrices.length === 0 ||
        ds.settingCriteria.listPrices[0].min === null ||
        ds.settingCriteria.listPrices[0].min <= params.listPrice
    );
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !ds.settingCriteria.listPrices ||
        ds.settingCriteria.listPrices.length === 0 ||
        ds.settingCriteria.listPrices[0].max === null ||
        ds.settingCriteria.listPrices[0].max >= params.listPrice
    );
  }
  if (!formValues.validFromDate) return null;

  interestingDefaultSettings.sort(
    (a, b) =>
      new Date(b.validFromDate).getTime() - new Date(a.validFromDate).getTime()
  );
  interestingDefaultSettings = interestingDefaultSettings.filter((item) => {
    return (
      new Date(item.validFromDate).getTime() <=
      new Date(formValues.validFromDate).getTime()
    );
  });

  if (interestingDefaultSettings.length > 0) {
    return interestingDefaultSettings[0].attributeValue;
  }
  return null;
};

function nullsLast(attributePath, ascending) {
  const attributes = attributePath.split(".");

  return function (a, b) {
    const aValue = attributes.reduce((obj, key) => obj?.[key], a);
    const bValue = attributes.reduce((obj, key) => obj?.[key], b);

    if (aValue === null || aValue === undefined) return 1;
    if (bValue === null || bValue === undefined) return -1;

    const aLength = aValue.length;
    const bLength = bValue.length;

    if (ascending) {
      return aLength - bLength;
    } else {
      return bLength - aLength;
    }
  };
}

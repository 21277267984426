import { useGetClientList } from "hooks/data/queries/useGetClientList";
import React, { useMemo, useState } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSelectedClients, setSelectedMainIds } from "store/filter";
import { useSelector } from "store/hooks";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import "./ClientFilter.css";

function filterTreeByCountryCode(nodes, countryCodeId) {
  const nodeMap = new Map();
  const result = new Set(); // Use Set to avoid duplicates

  // Create a map of all nodes by their id for quick lookup
  nodes.forEach((node) => {
    nodeMap.set(node.clientId, node);
  });

  // Function to add parents of a node to the result using a while loop
  function addParentsToResult(node) {
    let currentNode = node;

    // Traverse up the tree, adding parents to the result until root is reached
    while (currentNode) {
      result.add(currentNode); // Add current node to the result
      if (currentNode.parentClientId === null) {
        break; // If no parent, stop the loop (we've reached the root)
      }
      currentNode = nodeMap.get(currentNode.parentClientId); // Move to the parent node
    }
  }

  // Find all nodes that match the given countryCodeId and add their parents
  nodes.forEach((node) => {
    if (node.countryCodeId + "" === countryCodeId) {
      addParentsToResult(node); // Add the node and its parents to the result
    }
  });

  return Array.from(result); // Convert Set to array
}

export const ClientFilter = () => {
  const dispatch = useDispatch();

  const { data: clientListAll } = useGetClientList();
  const selectedCountryFilter = useSelector(
    (s) => s.filter.selectedCountryFilter
  );
  const selectedClients = useSelector((s) => s.filter.selectedClients);

  const [dataTable, setDataTable] = useState([]);

  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;

  // Handle setting selected clients with useEffect instead of inside useMemo
  React.useEffect(() => {
    if (selectedCountryFilter === null) {
      dispatch(setSelectedClients([]));
    }
  }, [selectedCountryFilter, dispatch]);

  const clientList = useMemo(() => {
    if (selectedCountryFilter === null || selectedCountryFilter === "0")
      return clientListAll;
    const retVal = filterTreeByCountryCode(
      clientListAll,
      selectedCountryFilter
    );
    return retVal;
  }, [clientListAll, selectedCountryFilter]);

  const findAllNodes = (retVal, nodes) => {
    if (nodes) {
      nodes?.forEach((element) => {
        retVal.push(element.value);
        findAllNodes(retVal, element.children);
      });
    }
  };

  const onChange = (currentNode, selectedNodes) => {
    const retVal = [];
    const mappetList = selectedNodes.map((e) => dataTable[e.value]);
    const mappedMainIds = mappetList.map((e) => e.value);
    findAllNodes(retVal, mappetList);
    dispatch(setSelectedClients(retVal));
    dispatch(setSelectedMainIds(mappedMainIds));
  };

  const clientTree = useMemo(() => {
    const createDataTree = (dataset) => {
      if (!dataset) return { dataTree: [], hashTable: {} };

      const hashTable = Object.create(null);
      const reformated = dataset.map((item) => ({
        label: item.clientName + " (" + item.clientInternalNumber + ")",
        value: item.clientInternalNumber,
        parent: item.parentClientInternalNumber,
        checked: selectedClients?.includes(item.clientInternalNumber),
        expanded: selectedClients?.includes(item.clientInternalNumber),
      }));

      reformated.forEach(
        (aData) => (hashTable[aData.value] = { ...aData, children: [] })
      );

      const dataTree = [];
      reformated.forEach((aData) => {
        if (aData.parent) {
          hashTable[aData.parent].children.push(hashTable[aData.value]);
        } else {
          dataTree.push(hashTable[aData.value]);
        }
      });

      return { dataTree, hashTable };
    };

    if (!clientList) return { dataTree: [], hashTable: {} };

    return createDataTree(clientList);
  }, [clientList, selectedClients]);

  // Set the dataTable state outside of render using useEffect
  React.useEffect(() => {
    if (clientTree.hashTable) {
      setDataTable(clientTree.hashTable);
    }
  }, [clientTree.hashTable]);

  let className = isDarkMode ? "dark" : "light";

  return (
    <DropdownTreeSelect
      className={className}
      data={clientTree.dataTree}
      onChange={onChange}
      keepTreeOnSearch={true}
      keepChildrenOnSearch={true}
      keepOpenOnSelect={true}
      texts={{
        placeholder:
          selectedClients && selectedClients.length > 0
            ? "  " +
              t("bfm.fleetForm.SearchForClient.numSelected.label", {
                numClients: selectedClients.length,
              })
            : "  " + t("bfm.fleetForm.SearchForClient.label") + "...",
        noMatches: t("greco.noResults"),
      }}
    />
  );
};

import Accordion from "components/Accordion";
import { constants } from "library/constants";
import { mergeStyles } from "office-ui-fabric-react";
import React from "react";
import { useSelector } from "store/hooks";
import { PremiumType } from "types/types";
import FinanceTempAccidentFields from "./FinanceTempAccidentFields";
import FinanceTempAssistanceFields from "./FinanceTempAssistanceFields";
import FinanceTempFleetLegalFields from "./FinanceTempFleetLegalFields";
import FinanceTempHorsePowerFields from "./FinanceTempHorsePowerFields";
import FinanceTempHull from "./FinanceTempHull";
import FinanceTempLegalFields from "./FinanceTempLegalFields";
import FinanceTempMtpl from "./FinanceTempMtpl";

const containerStyle = mergeStyles({
  display: "flex",
  flexDirection: "column",
  gap: 2,
});

const FinanceTempFields = ({
  formikProps,
  userRole,
  route,
  vehicle,
  insurerList,
}) => {
  const vehicleAppSettings = useSelector((s) => s.vehicle.vehicleAppSettings);

  const isEditTempTradeLicensePlate =
    route === constants.editTempVehicle && vehicle?.isTradeLicensePlate;
  return (
    <div className={containerStyle}>
      {vehicleAppSettings?.VEHICLE_PREMIUM_DOMAINS.includes(
        PremiumType.MTPL
      ) && (
        <Accordion label={"bfm.vehicleForm.pivotMtpl.label"}>
          <FinanceTempMtpl
            formikProps={formikProps}
            userRole={userRole}
            route={route}
            vehicle={vehicle}
            insurerList={insurerList}
          />
        </Accordion>
      )}
      {vehicleAppSettings?.VEHICLE_PREMIUM_DOMAINS.includes(
        PremiumType.HULL
      ) && (
        <Accordion label={"bfm.vehicleForm.pivotHull.label"}>
          <FinanceTempHull
            formikProps={formikProps}
            userRole={userRole}
            route={route}
            vehicle={vehicle}
            insurerList={insurerList}
          />
        </Accordion>
      )}
      {vehicleAppSettings?.VEHICLE_PREMIUM_DOMAINS.includes(
        PremiumType.ACCIDENT
      ) && (
        <Accordion label={"bfm.vehicleForm.pivotAccident.label"}>
          <FinanceTempAccidentFields
            formikProps={formikProps}
            userRole={userRole}
            route={route}
            vehicle={vehicle}
          />
        </Accordion>
      )}
      {vehicleAppSettings?.VEHICLE_PREMIUM_DOMAINS.includes(
        PremiumType.ASSISTANCE
      ) && (
        <Accordion label={"bfm.vehicleForm.pivotAssistance.label"}>
          <FinanceTempAssistanceFields
            formikProps={formikProps}
            userRole={userRole}
            route={route}
            vehicle={vehicle}
          />
        </Accordion>
      )}
      {vehicleAppSettings?.VEHICLE_PREMIUM_DOMAINS.includes(
        PremiumType.LEGAL
      ) && (
        <Accordion label={"bfm.vehicleForm.pivotLegal.label"}>
          <FinanceTempLegalFields
            formikProps={formikProps}
            userRole={userRole}
            route={route}
            vehicle={vehicle}
          />
        </Accordion>
      )}
      {vehicleAppSettings?.VEHICLE_PREMIUM_DOMAINS.includes(
        PremiumType.FLEETLEGAL_PRORATA
      ) && (
        <Accordion label={"bfm.vehicleForm.pivotFleetLegal.label"}>
          <FinanceTempFleetLegalFields
            formikProps={formikProps}
            userRole={userRole}
            route={route}
            vehicle={vehicle}
            insurerList={insurerList}
          />
        </Accordion>
      )}

      {!isEditTempTradeLicensePlate &&
        vehicleAppSettings?.VEHICLE_PREMIUM_DOMAINS.includes(
          PremiumType.HORSE_POWER
        ) && (
          <Accordion label={"bfm.vehicleForm.pivotEnginePowerTax.label"}>
            <FinanceTempHorsePowerFields
              formikProps={formikProps}
              userRole={userRole}
              route={route}
              vehicle={vehicle}
            />
          </Accordion>
        )}
    </div>
  );
};

export default FinanceTempFields;

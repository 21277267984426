import * as yup from "yup";
import { TimelineDialogType } from "../../../types/types";

export const validationObjectConfig = (
  item,
  dialogType,
  premiumTypeKey
): any => {
  const fieldSets = [];

  const premiumTypeCode = { premiumTypeCode: yup.mixed().required() };
  const licensePlate = { licensePlate: yup.string().required() };
  const reasonForModificationComment = {
    reasonForModificationComment: yup.string().trim().max(512),
  };
  const validFromDate = { validFromDate: yup.date().required().nullable() };
  //  const validToDate = { validToDate: yup.date().required().nullable() };
  const validToDate = { validToDate: yup.date().nullable() };
  const mainVehicleId = { mainVehicleId: yup.mixed().required() };

  fieldSets.push(premiumTypeCode); //0
  fieldSets.push(licensePlate); //1
  fieldSets.push(reasonForModificationComment); //2
  fieldSets.push(validFromDate); //3
  fieldSets.push(validToDate); //4
  fieldSets.push(mainVehicleId); //5

  let yupObject: any = {};
  if (dialogType === TimelineDialogType.INSERT_SHARED_LICENSE_PLATE) {
    yupObject = {
      ...fieldSets[0],
      ...fieldSets[5],
      ...fieldSets[1],
      ...fieldSets[3],
      ...fieldSets[4],
      ...fieldSets[2],
    };
  }
  if (dialogType === TimelineDialogType.CANCEL_SHARED_LICENSE_PLATE) {
    yupObject = {
      ...fieldSets[0],
      ...fieldSets[1],
      ...fieldSets[3],
      ...fieldSets[2],
    };
  }
  if (dialogType === TimelineDialogType.CORRECT_SHARED_LICENSE_PLATE) {
    yupObject = {
      ...fieldSets[0],
      ...fieldSets[5],
      ...fieldSets[1],
      ...fieldSets[3],
      ...fieldSets[4],
      ...fieldSets[2],
    };
  }
  if (dialogType === TimelineDialogType.DELETE_SHARED_LICENSE_PLATE) {
    yupObject = {
      ...fieldSets[0],
      ...fieldSets[1],
      ...fieldSets[2],
    };
  }
  if (dialogType === TimelineDialogType.CONTINUE_SHARED_LICENSE_PLATE) {
    yupObject = {
      ...fieldSets[0],
      ...fieldSets[1],
      ...fieldSets[2],
    };
  }
  if (dialogType === TimelineDialogType.ACTIVATE_SHARED_LICENSE_PLATE) {
    yupObject = {
      ...fieldSets[1],
      ...fieldSets[3],
      ...fieldSets[4],
      ...fieldSets[2],
    };
  }
  if (dialogType === TimelineDialogType.SUSPEND_SHARED_LICENSE_PLATE) {
    yupObject = {
      ...fieldSets[1],
      ...fieldSets[3],
      ...fieldSets[4],
      ...fieldSets[2],
    };
  }
  return yupObject;
};

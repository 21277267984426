import { constants } from "library/constants";
import { validateNumberFieldValueRange } from "utils/utils";
import * as yup from "yup";
import {
  adjustDateValue,
  transformInsurerBranchOptionValue,
  transformInsurerOptionValue,
  transformTaxonomyValue,
} from "../../form/util";
import { TaxonomyState } from "../../store/taxonomy";

export interface IVehicleConfig {
  key: string;
  name: string;
  value: string | number | Date;
  validation: any;
  tab: string;
  field: string;
  visibleInTradeLicensePlate: boolean;
  editedByExternalUser: boolean;
  isTEMP?: boolean;
}

export const vehicleConfig = ({
  vehicle,
  route,
  t,
  taxonomy,
  insurerList,
  fleet,
}: {
  vehicle: any;
  route: string;
  t: any;
  taxonomy: TaxonomyState;
  insurerList?: any[];
  fleet?: any;
}) => {
  const { createTradeVehicle, editTempVehicle } = constants;
  // const vehicleLastStatusDateVisible = false; //route === createVehicle || route === duplicateVehicle;
  const isTradeLicensePlate =
    route === createTradeVehicle || vehicle?.isTradeLicensePlate;
  const isTempVehicle = route === editTempVehicle;
  //General
  let retVal = [
    {
      key: "bfm.vehicleForm.clientName.label",
      name: "clientName",
      value: fleet?.webBasePartnerNumber,
      validation: yup.mixed().nullable(),
      tab: "general-vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("clientName"),
    },

    // 1
    {
      key: "bfm.vehicleForm.licensePlate.label",
      name: "licensePlate",
      value: route === "duplicateVehicle" ? "" : vehicle?.licensePlate,
      validation: yup
        .string()
        .max(12, `${t("bfm.form.error.maxLength")} 12`)
        .required(t("bfm.form.error.required"))
        .nullable(),
      // route !== "createVehicle" &&
      // !hasVehicleBeenActivated({ vehicle, route })
      //   ? yup.string().max(12).required().nullable()
      //   : yup.string().nullable(),
      tab: "general-vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("licensePlate"),
    },
    // 2
    {
      key: "bfm.vehicleForm.vehicleLastStatusDate.label",
      name: "vehicleLastStatusDate",
      value: adjustDateValue(vehicle?.vehicleFirstActivatedDate),
      // vehicle?.vehicleFirstActivatedDate &&
      // vehicle?.vehicleFirstActivatedDate?.toString(),
      /*
      validation:
        route !== createVehicle && route !== createTradeVehicle
          ? yup.mixed().test({
              name: "vehicleLastStatusDate",
              exclusive: true,
              message: t("greco.required"),
              test: (value) => {
                if (!vehicleLastStatusDateVisible) {
                  return "null";
                }

                return value;
              },
            })
          : yup.mixed().required(t("bfm.form.error.required")),
      */
      tab: "general-vehicle-form",
      field: "date",
      visibleInTradeLicensePlate: true,
      editedByExternalUser: vehicle?.fieldsUpdatedByExternalUser?.includes(
        "vehicleLastStatusDate"
      ),
    },

    // 2
    {
      key: "bfm.vehicleForm.registrationDate.label",
      name: "registrationDate",
      value: adjustDateValue(vehicle?.registrationDate),
      validation: yup.mixed().required(t("bfm.form.error.required")),
      tab: "general-vehicle-form",
      field: "date",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("registrationDate"),
    },

    // 3
    {
      key: "bfm.vehicleForm.vehicleOwner.label",
      name: "vehicleOwner",
      value: vehicle?.vehicleOwner,
      validation: yup
        .string()
        .max(128, `${t("bfm.form.error.maxLength")} 128`)
        .nullable(),
      tab: "general-vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: false,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("vehicleOwner"),
    },
    // 4
    {
      key: "bfm.vehicleForm.carUsageCode.label",
      name: "carUsageCode",
      value: transformTaxonomyValue(
        vehicle?.carUsageCode,
        taxonomy,
        "VehicleUsageType",
        t
      ),
      validation: yup.mixed().nullable(),
      // route !== "createVehicle"
      //   ? yup.mixed().required(t("bfm.form.error.required"))
      //   : yup.string().nullable(),
      tab: "vehicle-form",
      field: "option",
      visibleInTradeLicensePlate: false,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("carUsageCode"),
    },
    // 5
    {
      key: "bfm.vehicleForm.vin.label",
      name: "vin",
      value: route === "duplicateVehicle" ? "" : vehicle?.vin,
      validation: isTradeLicensePlate
        ? yup.string().nullable()
        : yup
            .string()
            .max(32, `${t("bfm.form.error.maxLength")} 32`)
            .required(t("bfm.form.error.required")),

      tab: "vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: false,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("vin"),
    },

    // 6
    {
      key: "bfm.vehicleForm.firstRegistrationDate.label",
      name: "firstRegistrationDate",
      value: adjustDateValue(vehicle?.firstRegistrationDate),
      validation: yup.mixed().nullable(),
      tab: "vehicle-form",
      field: "date",
      visibleInTradeLicensePlate: false,
      editedByExternalUser: vehicle?.fieldsUpdatedByExternalUser?.includes(
        "firstRegistrationDate"
      ),
    },
    // 7
    {
      key: "bfm.vehicleForm.yearOfConstruction.label",
      name: "yearOfConstruction",
      value: vehicle?.yearOfConstruction
        ? {
            value: vehicle?.yearOfConstruction,
            label: vehicle?.yearOfConstruction,
          }
        : null,
      validation: yup.mixed().nullable(),
      tab: "vehicle-form",
      field: "option",
      visibleInTradeLicensePlate: false,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("yearOfConstruction"),
    },

    // 8
    {
      key: "bfm.vehicleForm.nationalCodeId.label",
      name: "nationalCodeId",
      value: vehicle?.nationalCodeId,
      validation: yup
        .string()
        .max(254, `${t("bfm.form.error.maxLength")} 254`)
        .nullable(),
      tab: "vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: false,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("nationalCodeId"),
    },
    // 9
    {
      key: "bfm.vehicleForm.vehicleClassCode.label",
      name: "vehicleClassCode",
      value: transformTaxonomyValue(
        vehicle?.vehicleClassCode,
        taxonomy,
        "VehicleClass",
        t
      ),
      validation: yup.mixed().nullable(),
      tab: "vehicle-form",
      field: "option",
      visibleInTradeLicensePlate: false,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("vehicleClassCode"),
    },
    // 10
    {
      key: "bfm.vehicleForm.vehicleMainGroupCode.label",
      name: "vehicleMainGroupCode",
      value: transformTaxonomyValue(
        vehicle?.vehicleMainGroupCode,
        taxonomy,
        "VehicleMainGroup",
        t
      ),
      validation: isTradeLicensePlate
        ? yup.mixed().nullable()
        : yup.mixed().required(t("bfm.form.error.required")),
      // route !== "createVehicle"
      //   ? yup.mixed().required()
      //   : yup.string().nullable(),
      tab: "vehicle-form",
      field: "option",
      visibleInTradeLicensePlate: false,
      editedByExternalUser: vehicle?.fieldsUpdatedByExternalUser?.includes(
        "vehicleMainGroupCode"
      ),
    },
    // 11
    {
      key: "bfm.vehicleForm.vehicleTypeCode.label",
      name: "vehicleTypeCode",
      value: transformTaxonomyValue(
        vehicle?.vehicleTypeCode,
        taxonomy,
        "VehicleTypeLegal",
        t
      ),
      validation: isTradeLicensePlate
        ? yup.mixed().nullable()
        : yup.mixed().required(t("bfm.form.error.required")),
      // route !== "createVehicle"
      //   ? yup.mixed().required()
      //   : yup.string().nullable(),
      tab: "vehicle-form",
      field: "option",
      visibleInTradeLicensePlate: false,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("vehicleTypeCode"),
    },
    // 11 a
    {
      key: "bfm.vehicleForm.vehicleBodyTypeCode.label",
      name: "vehicleBodyTypeCode",
      value: transformTaxonomyValue(
        vehicle?.vehicleBodyTypeCode,
        taxonomy,
        "VehicleBodyType",
        t
      ),
      validation: yup.mixed().nullable(),
      // route !== "createVehicle"
      //   ? yup.mixed().required()
      //   : yup.string().nullable(),
      tab: "vehicle-form",
      field: "option",
      visibleInTradeLicensePlate: false,
      editedByExternalUser: vehicle?.fieldsUpdatedByExternalUser?.includes(
        "vehicleBodyTypeCode"
      ),
    },

    // 11 b
    {
      key: "bfm.vehicleForm.truckMountingCode.label",
      name: "truckMountingCode",
      value: transformTaxonomyValue(
        vehicle?.truckMountingCode,
        taxonomy,
        "TruckMounting",
        t
      ),
      validation: yup.mixed().nullable(),
      // route !== "createVehicle"
      //   ? yup.mixed().required()
      //   : yup.string().nullable(),
      tab: "vehicle-form",
      field: "option",
      visibleInTradeLicensePlate: false,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("truckMountingCode"),
    },

    // 12
    {
      key: "bfm.vehicleForm.brandCode.label",
      name: "brandCode",
      value: transformTaxonomyValue(
        vehicle?.brandCode,
        taxonomy,
        "VehicleBrand",
        t
      ),
      validation: yup.mixed().nullable(),
      tab: "vehicle-form",
      field: "option",
      visibleInTradeLicensePlate: false,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("brandCode"),
    },
    // 13
    {
      key: "bfm.vehicleForm.model.label",
      name: "model",
      value: vehicle?.model,
      validation: yup
        .string()
        .max(254, `${t("bfm.form.error.maxLength")} 254`)
        .nullable(),
      tab: "vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("model"),
    },
    // 14
    {
      key: "bfm.vehicleForm.maxGrossWeightKg.label",
      name: "maxGrossWeightKg",
      // value: adjustIntegerNumberValue(vehicle?.maxGrossWeightKg),
      value: vehicle?.maxGrossWeightKg?.toString(),
      validation: yup
        .string()
        .max(10, `${t("bfm.form.error.maxLength")} 10`)
        .nullable(),
      tab: "vehicle-form",
      field: "number",
      visibleInTradeLicensePlate: false,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("maxGrossWeightKg"),
    },
    {
      key: "bfm.vehicleForm.emptyWeightKg.label",
      name: "emptyWeightKg",
      // value: adjustIntegerNumberValue(vehicle?.weightCapacityKg),
      value: vehicle?.emptyWeightKg?.toString(),
      validation: yup
        .string()
        .max(10, `${t("bfm.form.error.maxLength")} 10`)
        .nullable(),
      tab: "vehicle-form",
      field: "number",
      visibleInTradeLicensePlate: false,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("emptyWeightKg"),
    },
    // 15
    {
      key: "bfm.vehicleForm.weightCapacityKg.label",
      name: "weightCapacityKg",
      // value: adjustIntegerNumberValue(vehicle?.weightCapacityKg),
      value: vehicle?.weightCapacityKg?.toString(),
      validation: yup
        .string()
        .max(10, `${t("bfm.form.error.maxLength")} 10`)
        .nullable(),
      tab: "vehicle-form",
      field: "number",
      visibleInTradeLicensePlate: false,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("weightCapacityKg"),
    },

    // 16
    {
      key: "bfm.vehicleForm.seats.label",
      name: "seats",
      // value: adjustIntegerNumberValue(vehicle?.seats),
      value: vehicle?.seats?.toString(),
      validation: yup.number().nullable(),
      tab: "vehicle-form",
      field: "number",
      visibleInTradeLicensePlate: false,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("seats"),
      excludeFromPreviewFormat: true,
    },
    // 16
    {
      key: "bfm.vehicleForm.vehiclePowerTypeCode.label",
      name: "vehiclePowerTypeCode",
      value: transformTaxonomyValue(
        vehicle?.vehiclePowerTypeCode,
        taxonomy,
        "VehiclePowerType",
        t
      ),
      validation: yup.mixed().nullable(),
      tab: "vehicle-form",
      field: "option",
      visibleInTradeLicensePlate: false,
      editedByExternalUser: vehicle?.fieldsUpdatedByExternalUser?.includes(
        "vehiclePowerTypeCode"
      ),
    },
    // 17
    {
      key: "bfm.vehicleForm.cubicCapacityCcm.label",
      name: "cubicCapacityCcm",
      // value: adjustIntegerNumberValue(vehicle?.cubicCapacityCcm),
      value: vehicle?.cubicCapacityCcm?.toString(),
      validation: yup
        .string()
        .max(10, `${t("bfm.form.error.maxLength")} 10`)
        .nullable(),
      tab: "vehicle-form",
      field: "number",
      visibleInTradeLicensePlate: false,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("cubicCapacityCcm"),
    },
    // 18
    {
      key: "bfm.vehicleForm.powerKw.label",
      name: "powerKw",
      // value: adjustIntegerNumberValue(vehicle?.powerKw),
      value: vehicle?.powerKw?.toString(),
      validation: yup
        .string()
        .max(10, `${t("bfm.form.error.maxLength")} 10`)
        .nullable(),
      tab: "vehicle-form",
      field: "number",
      visibleInTradeLicensePlate: false,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("powerKw"),
    },

    // 19
    {
      key: "bfm.vehicleForm.co2Emission.label",
      name: "co2Emission",
      // value: adjustIntegerNumberValue(vehicle?.co2Emission),
      value: vehicle?.co2Emission?.toString(),
      validation: validateNumberFieldValueRange({
        yup,
        fieldName: "horsePowerBasedTaxSuspensionDaysLimit",
        valueRange: -1,
        message: t("greco.form.errors.number.min", { min: -2 }),
        type: "min",
      }),
      tab: "vehicle-form",
      field: "number",
      visibleInTradeLicensePlate: false,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("co2Emission"),
    },
    //  20
    {
      key: "bfm.vehicleForm.certificateNumber.label",
      name: "certificateNumber",
      value: vehicle?.certificateNumber,
      validation: yup
        .string()
        .max(254, `${t("bfm.form.error.maxLength")} 254`)
        .nullable(),
      tab: "vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("certificateNumber"),
    },

    // 21
    {
      key: "bfm.vehicleForm.carUsageCodeAdditional.label",
      name: "carUsageCodeAdditional",
      value: vehicle?.carUsageCodeAdditional,
      validation: yup
        .string()
        .max(254, `${t("bfm.form.error.maxLength")} 254`)
        .nullable(),
      tab: "vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: false,
      editedByExternalUser: vehicle?.fieldsUpdatedByExternalUser?.includes(
        "carUsageCodeAdditional"
      ),
    },
    // 22
    {
      key: "bfm.vehicleForm.secondLicensePlate.label",
      name: "secondLicensePlate",
      value: vehicle?.secondLicensePlate,
      validation: yup
        .string()
        .max(12, `${t("bfm.form.error.maxLength")} 12`)
        .nullable(),
      tab: "vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: false,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("secondLicensePlate"),
    },
    //  23
    {
      key: "bfm.vehicleForm.costCenterClient.label",
      name: "costCenterClient",
      value: vehicle?.costCenterClient,
      validation: yup
        .string()
        .max(254, `${t("bfm.form.error.maxLength")} 254`)
        .nullable(),
      tab: "vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("costCenterClient"),
    },
    // 24
    {
      key: "bfm.vehicleForm.clientComment.label",
      name: "clientComment",
      value: vehicle?.clientComment,
      validation: yup
        .string()
        .max(10000, `${t("bfm.form.error.maxLength")} 10000`)
        .nullable(),
      tab: "vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("clientComment"),
    },
    {
      key: "bfm.vehicleForm.freeTextField1.label",
      name: "freeTextField1",
      value: vehicle?.freeTextField1,
      validation: yup
        .string()
        .max(10000, `${t("bfm.form.error.maxLength")} 10000`)
        .nullable(),
      tab: isTradeLicensePlate ? "finance-vehicle-form" : "vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("freeTextField1"),
    },
    {
      key: "bfm.vehicleForm.freeTextField2.label",
      name: "freeTextField2",
      value: vehicle?.freeTextField2,
      validation: yup
        .string()
        .max(10000, `${t("bfm.form.error.maxLength")} 10000`)
        .nullable(),
      tab: isTradeLicensePlate ? "finance-vehicle-form" : "vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("freeTextField2"),
    },
    // 25
    {
      key: "bfm.vehicleForm.comment.label",
      name: "comment",
      value: vehicle?.comment,
      validation: yup
        .string()
        .max(254, `${t("bfm.form.error.maxLength")} 254`)
        .nullable(),
      tab: "finance-vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("comment"),
    },
    {
      key: "bfm.vehicleForm.vehicleIssuingStatusCode.label",
      name: "vehicleIssuingStatusCode",
      value: transformTaxonomyValue(
        vehicle?.vehicleIssuingStatusCode,
        taxonomy,
        "VehicleIssuingStatus",
        t
      ),
      validation: yup.mixed().nullable(),
      tab: "finance-vehicle-form",
      field: "option",
      visibleInTradeLicensePlate: true,
      editedByExternalUser: vehicle?.fieldsUpdatedByExternalUser?.includes(
        "vehicleIssuingStatusCode"
      ),
    },
    {
      key: "bfm.vehicleForm.vehicleIssuingSubstatusCode.label",
      name: "vehicleIssuingSubstatusCode",
      value: transformTaxonomyValue(
        vehicle?.vehicleIssuingSubstatusCode,
        taxonomy,
        "VehicleIssuingSubstatus",
        t
      ),
      validation: yup.mixed().when("vehicleIssuingStatusCode", {
        is: (field) =>
          field && field?.value === constants.incorrectIssuingStatusValue, //
        then: yup.mixed().required(t("bfm.form.error.required")),
        otherwise: yup.mixed().nullable(),
      }),
      tab: "finance-vehicle-form",
      field: "option",
      visibleInTradeLicensePlate: true,
      editedByExternalUser: vehicle?.fieldsUpdatedByExternalUser?.includes(
        "vehicleIssuingSubstatusCode"
      ),
    },
    {
      key: "bfm.vehicleForm.vehicleIssuingComment.label",
      name: "vehicleIssuingComment",
      value: vehicle?.vehicleIssuingComment,
      validation: yup
        .string()
        .max(10000, `${t("bfm.form.error.maxLength")} 10000`)
        .nullable(),
      tab: "finance-vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: true,
      editedByExternalUser: vehicle?.fieldsUpdatedByExternalUser?.includes(
        "vehicleIssuingComment"
      ),
    },
    {
      key: "bfm.vehicleForm.isLeasing.label",
      name: "isLeasing",
      value: vehicle?.isLeasing,
      validation: yup.mixed().nullable(),
      // validation: yup
      //   .boolean()
      //   .oneOf([true, false], "Must Accept Terms and Conditions"),
      tab: "finance-vehicle-form",
      field: "radio-group",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("isLeasing"),
    },
    {
      key: "bfm.vehicleForm.leasingCompany.label",
      name: "leasingCompany",
      value: vehicle?.leasingCompany,
      validation: yup
        .string()
        .max(254, `${t("bfm.form.error.maxLength")} 254`)
        .nullable(),
      tab: "finance-vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("leasingCompany"),
    },
    {
      key: "bfm.vehicleForm.leasingContractNumber.label",
      name: "leasingContractNumber",
      value: vehicle?.leasingContractNumber,
      validation: yup
        .string()
        .max(254, `${t("bfm.form.error.maxLength")} 254`)
        .nullable(),
      tab: "finance-vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: true,
      editedByExternalUser: vehicle?.fieldsUpdatedByExternalUser?.includes(
        "leasingContractNumber"
      ),
    },
    {
      key: "bfm.vehicleForm.leasingContractStartDate.label",
      name: "leasingContractStartDate",
      value: adjustDateValue(vehicle?.leasingContractStartDate),
      validation: yup.mixed().nullable(),
      tab: "vehicle-form",
      field: "date",
      visibleInTradeLicensePlate: true,
      editedByExternalUser: vehicle?.fieldsUpdatedByExternalUser?.includes(
        "leasingContractStartDate"
      ),
    },
    {
      key: "bfm.vehicleForm.leasingContractEndDate.label",
      name: "leasingContractEndDate",
      value: adjustDateValue(vehicle?.leasingContractEndDate),
      validation: yup.mixed().nullable(),
      tab: "vehicle-form",
      field: "date",
      visibleInTradeLicensePlate: true,
      editedByExternalUser: vehicle?.fieldsUpdatedByExternalUser?.includes(
        "leasingContractEndDate"
      ),
    },
    {
      key: "bfm.vehicleForm.leasingAmountPaidByLessee.label",
      name: "leasingAmountPaidByLessee",
      value: vehicle?.leasingAmountPaidByLessee
        ?.toString()
        ?.replaceAll(".", ","),
      validation: yup.string().nullable(),
      tab: "vehicle-form",
      field: "double",
      visibleInTradeLicensePlate: true,
      editedByExternalUser: vehicle?.fieldsUpdatedByExternalUser?.includes(
        "leasingAmountPaidByLessee"
      ),
    },
    {
      key: "bfm.vehicleForm.eurotaxCode.label",
      name: "eurotaxCode",
      value: vehicle?.eurotaxCode,
      validation: yup
        .string()
        .max(254, `${t("bfm.form.error.maxLength")} 254`)
        .nullable(),
      tab: "finance-vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("eurotaxCode"),
    },
    {
      key: "bfm.vehicleForm.bonusMalusCode.label",
      name: "bonusMalusCode",
      value: transformTaxonomyValue(
        vehicle?.bonusMalusCode,
        taxonomy,
        "VehicleBonusMalusCode",
        t
      ),
      validation: yup.mixed().nullable(),
      // route !== "createVehicle"
      //   ? yup.mixed().required(t("bfm.form.error.required"))
      //   : yup.string().nullable(),
      tab: "finance-vehicle-form",
      field: "option",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("bonusMalusCode"),
    },
    {
      key: "bfm.vehicleForm.product.label",
      name: "hullProduct",
      value: vehicle?.hullProduct,
      validation: yup
        .string()
        .max(254, `${t("bfm.form.error.maxLength")} 254`)
        .nullable(),
      tab: "finance-vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("hullProduct"),
    },
    {
      key: "bfm.vehicleList.hullIsBasic.label",
      name: "hullIsBasic",
      value: vehicle?.hullIsBasic,
      validation: yup.mixed().nullable(),
      // validation: yup
      //   .boolean()
      //   .oneOf([true, false], "Must Accept Terms and Conditions"),
      tab: "finance-vehicle-form",
      field: "checkbox",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("hullIsBasic"),
      isTEMP: true,
    },
    {
      key: "bfm.vehicleForm.listPrice.label",
      name: "listPrice",
      // value: adjustFloatNumberValue(vehicle?.listPrice),
      value: vehicle?.listPrice?.toString()?.replaceAll(".", ","),
      validation: yup.string().nullable(),
      tab: "finance-vehicle-form",
      field: "double",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("listPrice"),
    },
    {
      key: "bfm.vehicleForm.isIncludingTax.label",
      name: "isIncludingTax",
      value: vehicle?.isIncludingTax,
      validation: yup.string().nullable(),
      tab: "finance-vehicle-form",
      field: "radio-group",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("isIncludingTax"),
    },
    {
      key: "bfm.vehicleForm.listPriceTypeCode.label",
      name: "listPriceTypeCode",
      value: transformTaxonomyValue(
        vehicle?.listPriceTypeCode,
        taxonomy,
        "ListPriceType",
        t
      ),
      validation: yup.mixed().nullable(),
      tab: "finance-vehicle-form",
      field: "option",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("listPriceTypeCode"),
    },
    {
      key: "bfm.vehicleForm.specialEquipment.label",
      name: "specialEquipment",
      // value: adjustFloatNumberValue(vehicle?.specialEquipment),
      value: vehicle?.specialEquipment?.toString()?.replaceAll(".", ","),
      validation: yup.string().nullable(),
      tab: "finance-vehicle-form",
      field: "double",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("specialEquipment"),
    },

    {
      key: "bfm.vehicleForm.electroPlusPackage.label",
      name: "electroPlusPackage",
      value: vehicle?.electroPlusPackage,
      validation: yup.mixed().nullable(),
      tab: "finance-vehicle-form",
      field: "checkbox",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("electroPlusPackage"),
    },

    {
      key: "bfm.vehicleForm.vatReimbursment.label",
      name: "vatReimbursment",
      value: vehicle?.vatReimbursment,
      validation: yup.mixed().nullable(),
      tab: "finance-vehicle-form",
      field: "radio-group",
      visibleInTradeLicensePlate: true,
      editedByExternalUser:
        vehicle?.fieldsUpdatedByExternalUser?.includes("vatReimbursment"),
    },

    {
      key: "bfm.vehicleForm.hullDeductiblePercent.label",
      name: "hullDeductiblePercent",
      // value: adjustIntegerNumberValue(vehicle?.hullDeductiblePercent),
      value: vehicle?.hullDeductiblePercent?.toString(),
      // validation: yup.mixed().test({
      //   name: "hullDeductiblePercent",
      //   exclusive: true,
      //   message: `${t("greco.maxAmountIs")} 100`,
      //   test: (value) => {
      //     if (value === "") {
      //       return true;
      //     }
      //     if (value) {
      //       const newValue = Number(value.toString().replaceAll(".", ""));
      //       if (newValue > 100) {
      //         return false;
      //       }
      //     }
      //     return true;
      //   },
      // }),
      validation: validateNumberFieldValueRange({
        yup,
        fieldName: "hullDeductiblePercent",
        valueRange: 100,
        message: t("greco.maxAmountIs"),
        type: "max",
      }),
      tab: "finance-vehicle-form",
      field: "number",
      visibleInTradeLicensePlate: true,
      editedByExternalUser: vehicle?.fieldsUpdatedByExternalUser?.includes(
        "hullDeductiblePercent"
      ),
    },
    {
      key: "bfm.vehicleForm.hullDeductibleMinAmount.label",
      name: "hullDeductibleMinAmount",
      // value: adjustFloatNumberValue(vehicle?.hullDeductibleMinAmount),
      value: vehicle?.hullDeductibleMinAmount?.toString()?.replaceAll(".", ","),
      validation: yup.string().nullable(),
      tab: "finance-vehicle-form",
      field: "double",
      visibleInTradeLicensePlate: true,
      editedByExternalUser: vehicle?.fieldsUpdatedByExternalUser?.includes(
        "hullDeductibleMinAmount"
      ),
    },
    {
      key: "bfm.vehicleForm.hullDeductibleMaxAmount.label",
      name: "hullDeductibleMaxAmount",
      // value: adjustFloatNumberValue(vehicle?.hullDeductibleMaxAmount),
      value: vehicle?.hullDeductibleMaxAmount?.toString()?.replaceAll(".", ","),
      validation: yup.string().nullable(),
      tab: "finance-vehicle-form",
      field: "double",
      visibleInTradeLicensePlate: true,
      editedByExternalUser: vehicle?.fieldsUpdatedByExternalUser?.includes(
        "hullDeductibleMaxAmount"
      ),
    },
    {
      key: "bfm.vehicleForm.hullDeductibleComment.label",
      name: "hullDeductibleComment",
      value: vehicle?.hullDeductibleComment,
      validation: yup
        .string()
        .max(512, `${t("bfm.form.error.maxLength")} 512`)
        .nullable(),
      tab: "finance-vehicle-form",
      field: "text",
      visibleInTradeLicensePlate: true,
      editedByExternalUser: vehicle?.fieldsUpdatedByExternalUser?.includes(
        "hullDeductibleComment"
      ),
    },
    //TEMP VEHICLE ADDITIONAL FIELDS
    ...(isTempVehicle
      ? [
          {
            key: "bfm.vehicleForm.gap.label",
            name: "gap",
            value: vehicle?.gap,
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "checkbox",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes("gap"),
          },
          {
            key: "bfm.vehicleForm.replacementValue.label",
            name: "replacementValue",
            value: vehicle?.replacementValue,
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "checkbox",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "replacementValue"
              ),
          },
          {
            key: "bfm.vehicleForm.grossNegligence.label",
            name: "grossNegligence",
            value: vehicle?.grossNegligence,
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "checkbox",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes("grossNegligence"),
          },
          //mtpl temp
          {
            key: "bfm.vehicleForm.endDate.label",
            name: "mtplImportEndDate",
            value: adjustDateValue(vehicle?.mtplImportEndDate),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "date",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "mtplImportEndDate"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.cancelationDocumentDate.label",
            name: "mtplPolicyCancelationDocumentDate",
            value: adjustDateValue(vehicle?.mtplPolicyCancelationDocumentDate),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "date",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "mtplPolicyCancelationDocumentDate"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.policyNumber.label",
            name: "mtplPolicyNumber",
            value: vehicle?.mtplPolicyNumber,
            validation: yup.string().max(34).nullable(),
            tab: "finance-vehicle-form",
            field: "text",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "mtplPolicyNumber"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.mtplPolicyStatus.label",
            name: "mtplPolicyStatus",
            value: transformTaxonomyValue(
              vehicle?.mtplPolicyStatus,
              taxonomy,
              "VehiclePolicyStatusCode",
              t
            ),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "option",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "mtplPolicyStatus"
              ),
          },
          {
            key: "bfm.mtplGroupPolicyNumber",
            name: "mtplGroupPolicyNumber",
            value: vehicle?.mtplGroupPolicyNumber,
            validation: yup.string().max(34).nullable(),
            tab: "finance-vehicle-form",
            field: "text",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "mtplGroupPolicyNumber"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.paymentMethod.label",
            name: "mtplPaymentMethod",
            value: transformTaxonomyValue(
              vehicle?.mtplPaymentMethod,
              taxonomy,
              "PaymentMethod",
              t
            ),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "option",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "mtplPaymentMethod"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.paymentMean.label",
            name: "paymentMean",
            value: transformTaxonomyValue(
              vehicle?.mtplPaymentMean,
              taxonomy,
              "PremiumPaymentMean",
              t
            ),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "option",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes("paymentMean"),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.mtplLastStartDate.label",
            name: "mtplLastStartDate",
            value: adjustDateValue(vehicle?.mtplLastStartDate),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "date",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "mtplLastStartDate"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.annualGrossPremium.label",
            name: "mtplAnnualGrossPremium",
            value: vehicle?.mtplAnnualGrossPremium
              ?.toString()
              ?.replaceAll(".", ","),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "double",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "mtplAnnualGrossPremium"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.mtplAnnualNetPremium.label",
            name: "mtplAnnualNetPremium",
            value: vehicle?.mtplAnnualNetPremium
              ?.toString()
              ?.replaceAll(".", ","),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "double",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "mtplAnnualNetPremium"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.suspensionDays.label",
            name: "mtplSuspensionDays",
            value: vehicle?.mtplSuspensionDays?.toString(),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "mtplSuspensionDays"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.activationDays.label",
            name: "mtplActivationDays",
            value: vehicle?.mtplActivationDays?.toString(),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "mtplActivationDays"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.suspensionDaysLimit.label",
            name: "mtplSuspensionDaysLimit",
            value: vehicle?.mtplSuspensionDaysLimit?.toString(),
            validation: validateNumberFieldValueRange({
              yup,
              fieldName: "mtplSuspensionDaysLimit",
              valueRange: -1,
              message: t("greco.form.errors.number.min", { min: -2 }),
              type: "min",
            }),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "mtplActivationDays"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.mtplInsurerPartnerNumber.label",
            name: "mtplInsurerPartnerNumber",
            value: transformInsurerOptionValue(
              vehicle?.mtplInsurerPartnerNumber,
              insurerList
            ),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "option",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "mtplInsurerPartnerNumber"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.insurerBranchOffice.label",
            name: "mtplInsurerBranchOffice",
            value: transformInsurerBranchOptionValue(
              vehicle?.mtplInsurerPartnerNumber,
              vehicle?.mtplInsurerBranchOffice,
              insurerList
            ),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "option",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "mtplInsurerBranchOffice"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.mtplIsPolicyIssued.label",
            name: "mtplIsPolicyIssued",
            value: vehicle?.mtplIsPolicyIssued,
            validation: yup.mixed().nullable(),
            // validation: yup
            //   .boolean()
            //   .oneOf([true, false], "Must Accept Terms and Conditions"),
            tab: "finance-vehicle-form",
            field: "checkbox",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "mtplIsPolicyIssued"
              ),
          },

          {
            key: "bfm.vehicleForm.mtplComment.label",
            name: "mtplComment",
            value: vehicle?.mtplComment,
            validation: yup
              .string()
              .max(254, `${t("bfm.form.error.maxLength")} 254`)
              .nullable(),
            tab: "finance-vehicle-form",
            field: "text",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes("mtplComment"),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.mtplDueMonth.label",
            name: "mtplDueMonth",
            value: vehicle?.mtplDueMonth?.toString(),
            validation: validateNumberFieldValueRange({
              yup,
              fieldName: "mtplDueMonth",
              valueRange: 12,
              message: t("greco.form.errors.number.max"),
              type: "max",
            }),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes("mtplDueMonth"),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.mtplDueDay.label",
            name: "mtplDueDay",
            value: vehicle?.mtplDueDay?.toString(),
            validation: validateNumberFieldValueRange({
              yup,
              fieldName: "mtplDueDay",
              valueRange: 31,
              message: t("greco.form.errors.number.max"),
              type: "max",
            }),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes("mtplDueDay"),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.documentDate.label",
            name: "mtplPolicyDocumentDate",
            value: adjustDateValue(vehicle?.mtplPolicyDocumentDate),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "date",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "mtplPolicyDocumentDate"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.fleetForm.mtplGreenCardExpiryDate.label",
            name: "mtplGreenCardExpiryDate",
            value: adjustDateValue(vehicle?.mtplGreenCardExpiryDate),
            validation: yup
              .date()
              .nullable()
              .when("mtplLastStartDate", (startDate, schema) => {
                return startDate
                  ? schema.min(
                      startDate,
                      t("bfm.form.error.greenCardDateStartDate")
                    )
                  : schema.required();
              }),

            tab: "finance-vehicle-form",
            field: "date",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "mtplGreenCardExpiryDate"
              ),
            isTEMP: true,
          },
          // hull temp
          {
            key: "bfm.vehicleForm.endDate.label",
            name: "hullImportEndDate",
            value: adjustDateValue(vehicle?.hullImportEndDate),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "date",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullImportEndDate"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.cancelationDocumentDate.label",
            name: "hullPolicyCancelationDocumentDate",
            value: adjustDateValue(vehicle?.hullPolicyCancelationDocumentDate),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "date",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullPolicyCancelationDocumentDate"
              ),
            isTEMP: true,
          },

          {
            key: "bfm.vehicleForm.hullInsurerPartnerNumber.label",
            name: "hullInsurerPartnerNumber",
            value: transformInsurerOptionValue(
              vehicle?.hullInsurerPartnerNumber,
              insurerList
            ),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "option",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullInsurerPartnerNumber"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.insurerBranchOffice.label",
            name: "hullInsurerBranchOffice",
            value: transformInsurerBranchOptionValue(
              vehicle?.hullInsurerPartnerNumber,
              vehicle?.hullInsurerBranchOffice,
              insurerList
            ),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "option",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullInsurerBranchOffice"
              ),
            isTEMP: true,
          },

          {
            key: "bfm.vehicleForm.hullPolicyNumber.label",
            name: "hullPolicyNumber",
            value: vehicle?.hullPolicyNumber,
            validation: yup
              .string()
              .max(34, `${t("bfm.form.error.maxLength")} 34`)
              .nullable(),
            tab: "finance-vehicle-form",
            field: "text",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullPolicyNumber"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.hullGroupPolicyNumber",
            name: "hullGroupPolicyNumber",
            value: vehicle?.hullGroupPolicyNumber,
            validation: yup.string().max(34).nullable(),
            tab: "finance-vehicle-form",
            field: "text",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullGroupPolicyNumber"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.hullPolicyStatus",
            name: "hullPolicyStatus",
            value: transformTaxonomyValue(
              vehicle?.hullPolicyStatus,
              taxonomy,
              "VehiclePolicyStatusCode",
              t
            ),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "option",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullPolicyStatus"
              ),
          },
          {
            key: "bfm.vehicleForm.paymentMethod.label",
            name: "hullPaymentMethod",
            value: transformTaxonomyValue(
              vehicle?.hullPaymentMethod,
              taxonomy,
              "PaymentMethod",
              t
            ),
            validation: yup.mixed().nullable(),
            tab: "vehicle-form",
            field: "option",
            visibleInTradeLicensePlate: false,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullPaymentMethod"
              ),
          },
          {
            key: "bfm.vehicleForm.paymentMean.label",
            name: "hullPaymentMean",
            value: transformTaxonomyValue(
              vehicle?.hullPaymentMean,
              taxonomy,
              "PremiumPaymentMean",
              t
            ),
            validation: yup.mixed().nullable(),
            tab: "vehicle-form",
            field: "option",
            visibleInTradeLicensePlate: false,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes("hullPaymentMean"),
          },

          {
            key: "bfm.vehicleForm.startDate.label",
            name: "hullLastStartDate",
            value: adjustDateValue(vehicle?.hullLastStartDate),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "date",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullLastStartDate"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.annualGrossPremium.label",
            name: "hullAnnualGrossPremium",
            value: vehicle?.hullAnnualGrossPremium
              ?.toString()
              ?.replaceAll(".", ","),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "double",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullAnnualGrossPremium"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.annualNetPremium.label",
            name: "hullAnnualNetPremium",
            value: vehicle?.hullAnnualNetPremium
              ?.toString()
              ?.replaceAll(".", ","),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "double",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullAnnualNetPremium"
              ),
            isTEMP: true,
          },

          {
            key: "bfm.vehicleForm.hullSuspensionDays.label",
            name: "hullSuspensionDays",
            value: vehicle?.hullSuspensionDays?.toString(),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullSuspensionDays"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.hullActivationDays.label",
            name: "hullActivationDays",
            value: vehicle?.hullActivationDays?.toString(),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullActivationDays"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.hullSuspensionDaysLimit.label",
            name: "hullSuspensionDaysLimit",
            value: vehicle?.hullSuspensionDaysLimit?.toString(),
            validation: validateNumberFieldValueRange({
              yup,
              fieldName: "hullSuspensionDaysLimit",
              valueRange: -1,
              message: t("greco.form.errors.number.min", { min: -2 }),
              type: "min",
            }),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullSuspensionDaysLimit"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.hullGarageRiskDiscountPercent.label",
            name: "hullGarageRiskDiscountPercent",
            value: vehicle?.hullGarageRiskDiscountPercent
              ?.toString()
              ?.replaceAll(".", ","),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "double",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullGarageRiskDiscountPercent"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.hullDueMonth.label",
            name: "hullDueMonth",
            value: vehicle?.hullDueMonth?.toString(),
            validation: validateNumberFieldValueRange({
              yup,
              fieldName: "hullDueMonth",
              valueRange: 12,
              message: t("greco.form.errors.number.max"),
              type: "max",
            }),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes("hullDueMonth"),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.hullDueDay.label",
            name: "hullDueDay",
            value: vehicle?.hullDueDay?.toString(),
            validation: validateNumberFieldValueRange({
              yup,
              fieldName: "hullDueDay",
              valueRange: 31,
              message: t("greco.form.errors.number.max"),
              type: "max",
            }),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes("hullDueDay"),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.hullIsPolicyIssued.label",
            name: "hullIsPolicyIssued",
            value: vehicle?.hullIsPolicyIssued,
            validation: yup.mixed().nullable(),
            // validation: yup
            //   .boolean()
            //   .oneOf([true, false], "Must Accept Terms and Conditions"),
            tab: "finance-vehicle-form",
            field: "checkbox",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullIsPolicyIssued"
              ),
          },
          {
            key: "bfm.vehicleForm.documentDate.label",
            name: "hullPolicyDocumentDate",
            value: adjustDateValue(vehicle?.hullPolicyDocumentDate),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "date",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullPolicyDocumentDate"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.hullComment.label",
            name: "hullComment",
            value: vehicle?.hullComment,
            validation: yup
              .string()
              .max(512, `${t("bfm.form.error.maxLength")} 512`)
              .nullable(),
            tab: "finance-vehicle-form",
            field: "text",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes("hullComment"),
            isTEMP: true,
          },

          //accident temp
          // {
          //   key: "bfm.vehicleForm.startDate.label",
          //   name: "accidentLastStartDate",
          //   value: adjustDateValue(vehicle?.accidentLastStartDate),
          //   validation: yup.mixed().nullable(),
          //   tab: "finance-vehicle-form",
          //   field: "date",
          //   visibleInTradeLicensePlate: true,
          //   editedByExternalUser:
          //     vehicle?.fieldsUpdatedByExternalUser?.includes(
          //       "accidentLastStartDate"
          //     ),
          //   isTEMP: true,
          // },
          {
            key: "bfm.vehicleForm.annualGrossPremium.label",
            name: "accidentAnnualGrossPremium",
            value: vehicle?.accidentAnnualGrossPremium
              ?.toString()
              ?.replaceAll(".", ","),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "double",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "accidentAnnualGrossPremium"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.annualNetPremium.label",
            name: "accidentAnnualNetPremium",
            value: vehicle?.accidentAnnualNetPremium
              ?.toString()
              ?.replaceAll(".", ","),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "double",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "accidentAnnualNetPremium"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.accidentSuspensionDaysLimit.label",
            name: "accidentSuspensionDaysLimit",
            value: vehicle?.accidentSuspensionDaysLimit?.toString(),
            validation: validateNumberFieldValueRange({
              yup,
              fieldName: "accidentSuspensionDaysLimit",
              valueRange: -1,
              message: t("greco.form.errors.number.min", { min: -2 }),
              type: "min",
            }),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "accidentSuspensionDaysLimit"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.accidentComment.label",
            name: "accidentComment",
            value: vehicle?.accidentComment,
            validation: yup
              .string()
              .max(254, `${t("bfm.form.error.maxLength")} 254`)
              .nullable(),
            tab: "finance-vehicle-form",
            field: "text",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes("accidentComment"),
            isTEMP: true,
          },

          //assistance temp
          // {
          //   key: "bfm.vehicleForm.startDate.label",
          //   name: "assistanceLastStartDate",
          //   value: adjustDateValue(vehicle?.assistanceLastStartDate),
          //   validation: yup.mixed().nullable(),
          //   tab: "finance-vehicle-form",
          //   field: "date",
          //   visibleInTradeLicensePlate: true,
          //   editedByExternalUser:
          //     vehicle?.fieldsUpdatedByExternalUser?.includes(
          //       "assistanceLastStartDate"
          //     ),
          //   isTEMP: true,
          // },
          {
            key: "bfm.vehicleForm.annualGrossPremium.label",
            name: "assistanceAnnualGrossPremium",
            value: vehicle?.assistanceAnnualGrossPremium
              ?.toString()
              ?.replaceAll(".", ","),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "double",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "assistanceAnnualGrossPremium"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.annualNetPremium.label",
            name: "assistanceAnnualNetPremium",
            value: vehicle?.assistanceAnnualNetPremium
              ?.toString()
              ?.replaceAll(".", ","),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "double",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "assistanceAnnualNetPremium"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.assistanceSuspensionDaysLimit.label",
            name: "assistanceSuspensionDaysLimit",
            value: vehicle?.assistanceSuspensionDaysLimit?.toString(),
            validation: validateNumberFieldValueRange({
              yup,
              fieldName: "assistanceSuspensionDaysLimit",
              valueRange: -1,
              message: t("greco.form.errors.number.min", { min: -2 }),
              type: "min",
            }),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "assistanceSuspensionDaysLimit"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.assistanceComment.label",
            name: "assistanceComment",
            value: vehicle?.assistanceComment,
            validation: yup
              .string()
              .max(512, `${t("bfm.form.error.maxLength")} 512`)
              .nullable(),
            tab: "finance-vehicle-form",
            field: "text",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "assistanceComment"
              ),
            isTEMP: true,
          },

          // legal temp
          // {
          //   key: "bfm.vehicleForm.startDate.label",
          //   name: "legalLastStartDate",
          //   value: adjustDateValue(vehicle?.legalLastStartDate),
          //   validation: yup.mixed().nullable(),
          //   tab: "finance-vehicle-form",
          //   field: "date",
          //   visibleInTradeLicensePlate: true,
          //   editedByExternalUser:
          //     vehicle?.fieldsUpdatedByExternalUser?.includes(
          //       "legalLastStartDate"
          //     ),
          //   isTEMP: true,
          // },
          {
            key: "bfm.vehicleForm.annualGrossPremium.label",
            name: "legalAnnualGrossPremium",
            value: vehicle?.legalAnnualGrossPremium
              ?.toString()
              ?.replaceAll(".", ","),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "double",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "legalAnnualGrossPremium"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.annualNetPremium.label",
            name: "legalAnnualNetPremium",
            value: vehicle?.legalAnnualNetPremium
              ?.toString()
              ?.replaceAll(".", ","),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "double",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "legalAnnualNetPremium"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.legalSuspensionDaysLimit.label",
            name: "legalSuspensionDaysLimit",
            value: vehicle?.legalSuspensionDaysLimit?.toString(),
            validation: validateNumberFieldValueRange({
              yup,
              fieldName: "legalSuspensionDaysLimit",
              valueRange: -1,
              message: t("greco.form.errors.number.min", { min: -2 }),
              type: "min",
            }),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "legalSuspensionDaysLimit"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.legalComment.label",
            name: "legalComment",
            value: vehicle?.legalComment,
            validation: yup
              .string()
              .max(512, `${t("bfm.form.error.maxLength")} 512`)
              .nullable(),
            tab: "finance-vehicle-form",
            field: "text",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes("legalComment"),
            isTEMP: true,
          },

          // horse power temp

          {
            key: "bfm.vehicleForm.paymentMethod.label",
            name: "horsePowerBasedTaxPaymentMethod",
            value: transformTaxonomyValue(
              vehicle?.horsePowerBasedTaxPaymentMethod,
              taxonomy,
              "PaymentMethod",
              t
            ),
            validation: yup.mixed().nullable(),
            tab: "vehicle-form",
            field: "option",
            visibleInTradeLicensePlate: false,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "horsePowerBasedTaxPaymentMethod"
              ),
          },
          // {
          //   key: "bfm.vehicleForm.paymentMean.label",
          //   name: "horsePowerBasedTaxPaymentMean",
          //   value: transformTaxonomyValue(
          //     vehicle?.horsePowerBasedTaxPaymentMean,
          //     taxonomy,
          //     "PremiumPaymentMean",
          //     t
          //   ),
          //   validation: yup.mixed().nullable(),
          //   tab: "vehicle-form",
          //   field: "option",
          //   visibleInTradeLicensePlate: false,
          //   editedByExternalUser:
          //     vehicle?.fieldsUpdatedByExternalUser?.includes(
          //       "horsePowerBasedTaxPaymentMean"
          //     ),
          // },
          {
            key: "bfm.vehicleForm.horsePowerBasedTaxExcludeReason.label",
            name: "excludeHorsePowerBasedTaxReason",
            value: transformTaxonomyValue(
              vehicle?.excludeHorsePowerBasedTaxReason,
              taxonomy,
              "ReasonForMotorTaxExclusion",
              t
            ),
            validation: yup.mixed().nullable(),
            tab: "vehicle-form",
            field: "option",
            visibleInTradeLicensePlate: false,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "excludeHorsePowerBasedTaxReason"
              ),
          },
          {
            key: "bfm.vehicleForm.horsePowerBasedTaxSuspensionDaysLimit.label",
            name: "horsePowerBasedTaxSuspensionDaysLimit",
            value: vehicle?.horsePowerBasedTaxSuspensionDaysLimit?.toString(),
            validation: validateNumberFieldValueRange({
              yup,
              fieldName: "horsePowerBasedTaxSuspensionDaysLimit",
              valueRange: -1,
              message: t("greco.form.errors.number.min", { min: -2 }),
              type: "min",
            }),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: false,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "horsePowerBasedTaxSuspensionDaysLimit"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.horsePowerBasedTaxComment.label",
            name: "horsePowerBasedTaxComment",
            value: vehicle?.horsePowerBasedTaxComment,
            validation: yup
              .string()
              .max(254, `${t("bfm.form.error.maxLength")} 254`)
              .nullable(),
            tab: "finance-vehicle-form",
            field: "text",
            visibleInTradeLicensePlate: false,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "horsePowerBasedTaxComment"
              ),
            isTEMP: true,
          },
          // fleet legal
          {
            key: "bfm.vehicleForm.startDate.label",
            name: "fleetLegalLastStartDate",
            value: adjustDateValue(vehicle?.fleetLegalLastStartDate),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "date",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalLastStartDate"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.endDate.label",
            name: "fleetLegalImportEndDate",
            value: adjustDateValue(vehicle?.fleetLegalImportEndDate),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "date",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalImportEndDate"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.cancelationDocumentDate.label",
            name: "fleetLegalPolicyCancelationDocumentDate",
            value: adjustDateValue(
              vehicle?.fleetLegalPolicyCancelationDocumentDate
            ),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "date",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalPolicyCancelationDocumentDate"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.fleetForm.fleetLegalInsurerPartnerNumber.label",
            name: "fleetLegalInsurerPartnerNumber",
            value: transformInsurerOptionValue(
              vehicle?.fleetLegalInsurerPartnerNumber,
              insurerList
            ),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "option",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalInsurerPartnerNumber"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.insurerBranchOffice.label",
            name: "fleetLegalInsurerBranchOffice",
            value: transformInsurerBranchOptionValue(
              vehicle?.fleetLegalInsurerPartnerNumber,
              vehicle?.fleetLegalInsurerBranchOffice,
              insurerList
            ),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "option",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalInsurerBranchOffice"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.policyNumber.label",
            name: "fleetLegalPolicyNumber",
            value: vehicle?.fleetLegalPolicyNumber,
            validation: yup.string().max(34).nullable(),
            tab: "finance-vehicle-form",
            field: "text",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalPolicyNumber"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.paymentMethod.label",
            name: "fleetLegalPaymentMethod",
            value: transformTaxonomyValue(
              vehicle?.fleetLegalPaymentMethod,
              taxonomy,
              "PaymentMethod",
              t
            ),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "option",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalPaymentMethod"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.paymentMean.label",
            name: "fleetLegalPaymentMean",
            value: transformTaxonomyValue(
              vehicle?.fleetLegalPaymentMean,
              taxonomy,
              "PremiumPaymentMean",
              t
            ),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "option",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalPaymentMean"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.annualGrossPremium.label",
            name: "fleetLegalAnnualGrossPremium",
            value: vehicle?.fleetLegalAnnualGrossPremium
              ?.toString()
              ?.replaceAll(".", ","),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "double",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalAnnualGrossPremium"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.annualNetPremium.label",
            name: "fleetLegalAnnualNetPremium",
            value: vehicle?.fleetLegalAnnualNetPremium
              ?.toString()
              ?.replaceAll(".", ","),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "double",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalAnnualNetPremium"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.suspensionDays",
            name: "fleetLegalSuspensionDays",
            value: vehicle?.fleetLegalSuspensionDays?.toString(),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalSuspensionDays"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.activationDays",
            name: "fleetLegalActivationDays",
            value: vehicle?.fleetLegalActivationDays?.toString(),
            validation: yup.string().nullable(),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalActivationDays"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.suspensionDaysLimit",
            name: "fleetLegalSuspensionDaysLimit",
            value: vehicle?.fleetLegalSuspensionDaysLimit?.toString(),
            validation: validateNumberFieldValueRange({
              yup,
              fieldName: "fleetLegalSuspensionDaysLimit",
              valueRange: -1,
              message: t("greco.form.errors.number.min", { min: -2 }),
              type: "min",
            }),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalSuspensionDaysLimit"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.dueMonth.label",
            name: "fleetLegalDueMonth",
            value: vehicle?.fleetLegalDueMonth?.toString(),
            validation: validateNumberFieldValueRange({
              yup,
              fieldName: "fleetLegalDueMonth",
              valueRange: 12,
              message: t("greco.form.errors.number.max"),
              type: "max",
            }),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalDueMonth"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.dueDay.label",
            name: "fleetLegalDueDay",
            value: vehicle?.fleetLegalDueDay?.toString(),
            validation: validateNumberFieldValueRange({
              yup,
              fieldName: "fleetLegalDueDay",
              valueRange: 31,
              message: t("greco.form.errors.number.max"),
              type: "max",
            }),
            tab: "finance-vehicle-form",
            field: "number",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalDueDay"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.vehicleForm.documentDate.label",
            name: "fleetLegalPolicyDocumentDate",
            value: adjustDateValue(vehicle?.fleetLegalPolicyDocumentDate),
            validation: yup.mixed().nullable(),
            tab: "finance-vehicle-form",
            field: "date",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalPolicyDocumentDate"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.isPolicyIssued",
            name: "fleetLegalIsPolicyIssued",
            value: vehicle?.fleetLegalIsPolicyIssued,
            validation: yup.mixed().nullable(),
            // validation: yup
            //   .boolean()
            //   .oneOf([true, false], "Must Accept Terms and Conditions"),
            tab: "finance-vehicle-form",
            field: "checkbox",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalIsPolicyIssued"
              ),
          },
          {
            key: "bfm.fleetForm.fleetComment.label",
            name: "fleetLegalComment",
            value: vehicle?.fleetLegalComment,
            validation: yup
              .string()
              .max(254, `${t("bfm.form.error.maxLength")} 254`)
              .nullable(),
            tab: "finance-vehicle-form",
            field: "text",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalComment"
              ),
          },
          {
            key: "bfm.fleetLegalIsClosingDate.label",
            name: "fleetLegalIsClosingDate",
            value: vehicle?.fleetLegalIsClosingDate,
            validation: yup.mixed().nullable(),
            // validation: yup
            //   .boolean()
            //   .oneOf([true, false], "Must Accept Terms and Conditions"),
            tab: "finance-vehicle-form",
            field: "checkbox",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "fleetLegalIsClosingDate"
              ),
            isTEMP: true,
          },
          {
            key: "bfm.isFirstForFleetLegalClosingDate.label",
            name: "isFirstForFleetLegalClosingDate",
            value: vehicle?.isFirstForFleetLegalClosingDate,
            validation: yup.mixed().nullable(),
            // validation: yup
            //   .boolean()
            //   .oneOf([true, false], "Must Accept Terms and Conditions"),
            tab: "finance-vehicle-form",
            field: "checkbox",
            visibleInTradeLicensePlate: true,
            editedByExternalUser:
              vehicle?.fieldsUpdatedByExternalUser?.includes(
                "isFirstForFleetLegalClosingDate"
              ),
            isTEMP: true,
          },
        ]
      : []),
  ];
  if (vehicle?.confidentialityInfo) {
    const c = vehicle?.confidentialityInfo;

    retVal = retVal.map((el) => {
      const capitalizedFieldName =
        el.name.charAt(0).toUpperCase() + el.name.slice(1);
      return { ...el, confidentiality: c[capitalizedFieldName] };
    });
  }
  return retVal;
};

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../../store/hooks";

const ExcludeHorsePowerBasedTaxReason = ({ code }) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  return <span>{t(taxonomy.ReasonForMotorTaxExclusion.byId[code]?.code)}</span>;
};

export default ExcludeHorsePowerBasedTaxReason;

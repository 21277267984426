import { useMutation } from "react-query";
import {
  SharedLicensePlateRequestParams,
  activateSharedLicensePlate,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const activateSharedLicensePlateFn = async (
  params: SharedLicensePlateRequestParams
) => {
  return await activateSharedLicensePlate(params);
};

export function useActivateSharedLicensePlate() {
  return useMutation(activateSharedLicensePlateFn, {
    mutationKey: MUTATION_KEYS.activateSharedLicensePlate,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { isExternalOrReadonlyAdmin } from "form/util";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../store/hooks";
import { openDialog } from "../../../store/timeline";
import {
  setChangeVehicleStatusDialog,
  setVehicle,
} from "../../../store/vehicle";
import {
  TimelineContextMenuType,
  TimelineDialogType,
  TimelineType,
  UserRole,
} from "../../../types/types";
import { useApproveVehiclePendingStatus } from "hooks/data/mutations/useApproveVehiclePendingStatus";

export const useStatusSegmentMenuItems = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const segmentContextMenuItem = useSelector(
    (s) => s.timeline.contextMenu.item
  );
  const userRole = useSelector((s) => s.auth.userRole);

  const {
    mutate: onApproveVehiclePendingStatus,
    isLoading: isApproveVehicleStatusLoading,
  } = useApproveVehiclePendingStatus();

  const segmentContextMenuType = useSelector(
    (s) => s.timeline.contextMenu.type
  );
  const vehicle = useSelector((s) => s.vehicle?.vehicle);

  const statusContextMenuDate = useSelector((s) => s.timeline.contextMenu.date);

  const segmentContextMenuItemDomainType = useSelector(
    (s) => s.timeline.contextMenu.item?.domain.type
  );

  const segmentContextMenuItemDomainValue = useSelector(
    (s) => s.timeline.contextMenu.item?.domain.value
  );

  return useMemo(() => {
    const items = [];
    if (segmentContextMenuItemDomainType !== TimelineType.VEHICLE_STATUS) {
      return [];
    }
    // Add and Insert
    if (segmentContextMenuType === TimelineContextMenuType.STATUS_POINT) {
      if (
        segmentContextMenuItemDomainValue ===
          "VehicleStatusCode.PENDING_FOR_ACTIVATION" ||
        segmentContextMenuItemDomainValue ===
          "VehicleStatusCode.PENDING_FOR_DEACTIVATION" ||
        segmentContextMenuItemDomainValue ===
          "VehicleStatusCode.PENDING_FOR_SUSPENSION"
      ) {
        if (!isExternalOrReadonlyAdmin(userRole)) {
          items.push({
            key: "approveStatus",
            text: t("bfm.approveStatus"),
            onClick: () => {
              onApproveVehiclePendingStatus(
                {
                  fleetId: vehicle.fleetId + "",
                  vehicleId: vehicle.vehicleId + "",
                  rowVersion: vehicle.rowVersion + "",
                },
                {
                  onSuccess: (res) => {
                    console.log(res, "res herr");
                    dispatch(setVehicle(res.data as any));
                  },
                }
              );
            },
          });

          items.push({
            key: "change status",
            text: t("bfm.changeStatus"),
            onClick: () => {
              dispatch(
                setChangeVehicleStatusDialog({
                  fleetId: "" + vehicle.fleetId,
                  isOpen: true,
                  vehicleIds: [vehicle.vehicleId],
                  date: statusContextMenuDate,
                  addNew: false,
                })
              );
            },
          });
        }
        items.push({
          key: "cancelStatus",
          text: t("bfm.cancelStatus"),
          onClick: () => {
            dispatch(
              openDialog({
                dialogType: TimelineDialogType.CANCEL_STATUS,
                contextMenuItem: segmentContextMenuItem,
                contextMenuDate: null,
                previousDialogData: null,
              })
            );
          },
        });
      } else {
        if (!isExternalOrReadonlyAdmin(userRole)) {
          items.push({
            key: "correct status date",
            text: t("bfm.correctStatusDate"),
            onClick: () => {
              dispatch(
                openDialog({
                  dialogType: TimelineDialogType.CORRECT_STATUS,
                  contextMenuItem: segmentContextMenuItem,
                  contextMenuDate: moment(
                    segmentContextMenuItem.startDate
                  ).toDate(),
                  previousDialogData: null,
                })
              );
            },
          });

          items.push({
            key: "delete status date",
            text: t("bfm.deleteStatusChange"),
            onClick: () => {
              dispatch(
                openDialog({
                  dialogType: TimelineDialogType.DELETE_STATUS,
                  contextMenuItem: segmentContextMenuItem,
                  contextMenuDate: moment(
                    segmentContextMenuItem.startDate
                  ).toDate(),
                  previousDialogData: null,
                })
              );
            },
          });
        }
      }
    }

    if (segmentContextMenuType === TimelineContextMenuType.STATUS_BAR) {
      if (segmentContextMenuItem.extraData.isLastSegmentInLine) {
        if (
          segmentContextMenuItemDomainValue !== "VehicleStatusCode.DEACTIVATED"
        ) {
          items.push({
            key: "change status",
            text: t("bfm.changeStatus"),
            onClick: () => {
              dispatch(
                setChangeVehicleStatusDialog({
                  fleetId: "" + vehicle.fleetId,
                  isOpen: true,
                  vehicleIds: [vehicle.vehicleId],
                  date: statusContextMenuDate,
                  addNew: false,
                })
              );
            },
          });
        }
      } else {
        items.push({
          key: "insert status",
          text: t("bfm.insertStatus"),
          onClick: () => {
            dispatch(
              openDialog({
                dialogType: TimelineDialogType.INSERT_STATUS,
                contextMenuItem: segmentContextMenuItem,
                contextMenuDate: moment(statusContextMenuDate).toDate(),
                previousDialogData: null,
              })
            );
          },
        });
      }
    }
    return items;
  }, [
    dispatch,
    t,
    segmentContextMenuItem,
    segmentContextMenuType,
    statusContextMenuDate,
    segmentContextMenuItemDomainType,
    segmentContextMenuItemDomainValue,
    userRole,
    vehicle,
  ]);
};

import { useMutation } from "react-query";
import {
  SharedLicensePlateRequestParams,
  continueSharedLicensePlate,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const continueSharedLicensePlateFn = async (
  params: SharedLicensePlateRequestParams
) => {
  return await continueSharedLicensePlate(params);
};

export function useContinueSharedLicensePlate() {
  return useMutation(continueSharedLicensePlateFn, {
    mutationKey: MUTATION_KEYS.continueSharedLicensePlate,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

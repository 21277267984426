import { timeFormat } from "d3";
import moment, { Moment } from "moment";
import React from "react";
import { useSelector } from "../../../../store/hooks";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

const yearTickFormat = timeFormat("%Y");
const monthTickFormat = timeFormat("%m");
export const AxisBottom = ({ xScale, innerHeight, tickOffset = 5 }) => {
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;
  const minDate = useSelector((s) => s.timeline.timeline.startDate);
  const maxDate = useSelector((s) => s.timeline.timeline.endDate);

  let startDate: Moment = moment(minDate);
  let endDate: Moment = moment(maxDate);

  const result = [];
  while (startDate.isBefore(endDate) || startDate.isSame(endDate, "day")) {
    result.push(startDate.toDate());
    startDate.set("date", 1);
    startDate.add(1, "month");
  }

  const tickElements = result
    .filter((e, index) => {
      if (result.length > 36) {
        if (index % 2 === 1) return true;
        return false;
      } else {
        return true;
      }
    })
    .map((tickValue) => (
      <g
        className={isDarkMode ? "tickdark" : "tick"}
        key={tickValue}
        transform={`translate(${xScale(tickValue)},0)`}
      >
        <line y2={innerHeight} />
        {result.length < 60 ? (
          <text
            style={{ textAnchor: "middle", fontSize: 10 }}
            dy=".71em"
            y={innerHeight + tickOffset}
          >
            {monthTickFormat(tickValue)}
          </text>
        ) : null}
        {moment(tickValue).get("month") === 0 ? (
          <text
            style={{ textAnchor: "middle", fontSize: 10 }}
            dy="1.91em"
            y={innerHeight + tickOffset}
          >
            {yearTickFormat(tickValue)}
          </text>
        ) : null}
      </g>
    ));

  return <>{tickElements}</>;
};

import { IconButton, TooltipHost } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { setStopFleetRedirect } from "store/fleetsPage";
import styled from "styled-components";
import { UserRole } from "types/types";
import { routes } from "../../config/routes";
import { useSelector } from "../../store/hooks";
import { Flex } from "../styled";

export const Links = () => {
  const userRole = useSelector((s) => s.auth.userRole);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Container>
      <ButtonNavLink
        to={routes.fleets.getPath()}
        onClick={(e) => {
          dispatch(setStopFleetRedirect(true));
        }}
      >
        <TooltipHost content={t("bfm.nav.fleets")}>
          <IconButton iconProps={{ iconName: "Car" }} />
        </TooltipHost>
      </ButtonNavLink>
      {userRole !== UserRole.external && (
        <ButtonNavLink to={routes.analytics.getPath()}>
          <TooltipHost content={t("bfm.nav.mainAnalytics")}>
            <IconButton iconProps={{ iconName: "Diagnostic" }} />
          </TooltipHost>
        </ButtonNavLink>
      )}
      {userRole !== UserRole.external && (
        <ButtonNavLink to={routes.requireActionVehicles.getPath()}>
          <TooltipHost content={t("bfm.nav.vehicleCheckList")}>
            <IconButton iconProps={{ iconName: "Info" }} />
          </TooltipHost>
        </ButtonNavLink>
      )}
      {userRole !== UserRole.external && (
        <ButtonNavLink to={routes.insurers.getPath()}>
          <TooltipHost content={t("bfm.nav.insurers")}>
            <IconButton iconProps={{ iconName: "Shield" }} />
          </TooltipHost>
        </ButtonNavLink>
      )}
    </Container>
  );
};

const Container = styled(Flex)`
& > * {
  margin-right: 3px;
  &:last-child {
  margin-right: 0;
}
`;

const ButtonNavLink = styled(NavLink)`
  width: 32px;
  height: 32px;
  display: inline-block;
  &.active .ms-Button {
    background: ${(p) => p.theme.palette?.themePrimary};
    color: ${(p) => (p.theme.isDark ? "#101010" : "white")};
    .ms-Icon {
      color: ${(p) => (p.theme.isDark ? "#101010" : "white")};
    }
  }
`;

import { Icon, TooltipHost } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Flex, Truncate } from "../../../../../components/styled";
import { routes } from "../../../../../config/routes";
import { VehicleRedux } from "../../../../../types/types";
import FleetLegalLicenseCell from "./FleetLegalLicenseCell";
import HullLicenseCell from "./HullLicenseCell";

type Props = {
  vehicle: VehicleRedux;
};

const LicensePlateCell = ({ vehicle }: Props) => {
  const { t } = useTranslation();

  const isfleetLegalClosingDate = vehicle?.fleetLegalIsClosingDate;
  const isHullBasic = vehicle?.hullIsBasic;

  return (
    <Root gap={5}>
      <StyledLink
        to={{
          pathname: routes[
            vehicle.isTEMP ? "editTempVehicle" : "readOnlyVehicle"
          ].getPath(vehicle?.fleetId, vehicle?.vehicleId),
        }}
        state={{
          fromVehicleList: true,
        }}
      >
        <Truncate>
          <span
            style={{
              fontSize: "small",
            }}
          >
            {vehicle?.licensePlate}
          </span>
        </Truncate>
      </StyledLink>

      <IconsContainer>
        {vehicle?.needsGrECoUserAttention ? (
          <TooltipHost content={t("bfm.needsAttention")}>
            <StyledIconRed iconName="Info" />
            &nbsp;
          </TooltipHost>
        ) : (
          <>
            <StyledIconRed iconName="" />
            &nbsp;
          </>
        )}
        {vehicle?.mtplIsPolicyIssued !== undefined &&
        vehicle?.mtplIsPolicyIssued === false ? (
          <TooltipHost content={t("bfm.mtplPolicyIsNotIssued")}>
            <StyledIconRed iconName="car" />
            &nbsp;
          </TooltipHost>
        ) : (
          <>
            <StyledIconRed iconName="" />
            &nbsp;
          </>
        )}
        {typeof vehicle?.hullIsPolicyIssued === "boolean" && (
          <HullLicenseCell
            hullIsPolicyIssued={vehicle?.hullIsPolicyIssued}
            isHullBasic={isHullBasic}
          />
        )}
        {typeof vehicle?.fleetLegalIsPolicyIssued === "boolean" ? (
          <FleetLegalLicenseCell
            fleetLegalIsPolicyIssued={vehicle?.fleetLegalIsPolicyIssued}
            isfleetLegalClosingDate={isfleetLegalClosingDate}
          />
        ) : null}
        {vehicle?.hasSharedLicensePlate !== undefined &&
        vehicle?.hasSharedLicensePlate === true ? (
          <TooltipHost content={t("bfm.hasSharedLicensePlate")}>
            <StyledIconNormal
              iconName="SharePointAppIcon16"
              style={{
                marginLeft: "2px",
              }}
            />
            &nbsp;
          </TooltipHost>
        ) : vehicle?.hadSharedLicensePlate !== undefined &&
          vehicle?.hadSharedLicensePlate === true ? (
          <TooltipHost content={t("bfm.hadSharedLicensePlate")}>
            <StyledIconGreyedOut iconName="SharePointAppIcon16" />
            &nbsp;
          </TooltipHost>
        ) : (
          <>
            <StyledIconRed iconName="" />
            &nbsp;
          </>
        )}
      </IconsContainer>
    </Root>
  );
};

export default React.memo(LicensePlateCell);

const Root = styled(Flex)`
  height: 100%;
  width: 100%;
  padding: 0 12px;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  flex: 1;
  text-align: left;
  min-width: 0;
  color: ${(p) => p.theme.palette.neutralPrimary};
  &:visited {
    color: ${(p) => p.theme.palette.neutralPrimary};
  }
  &:hover {
    text-decoration: underline;
    color: ${(p) => p.theme.palette.themePrimary};
  }
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIconRed = styled(Icon)`
  color: ${(p) => p.theme.palette.redDark};
`;
const StyledIconNormal = styled(Icon)`
  color: ${(p) => p.theme.palette.themePrimary};
`;
const StyledIconGreyedOut = styled(Icon)`
  color: ${(p) => p.theme.palette.neutralSecondary};
`;

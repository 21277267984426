import { usePrevious } from "@uifabric/react-hooks";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { PremiumType } from "types/types";

export const useEqualPaymentMethods = (premiumTypeKey) => {
  const formik = useFormikContext();
  const paymentMethod = formik.values["paymentMethod"];

  const canChangeMotorTaxPaymentMethod =
    formik.touched["motorTaxPaymentMethod"] !== undefined &&
    formik.touched["motorTaxPaymentMethod"];

  const prevMethod = usePrevious(paymentMethod);

  useEffect(() => {
    if (premiumTypeKey !== PremiumType.MTPL) return;

    if (
      formik.dirty &&
      !formik.isSubmitting &&
      paymentMethod?.value !== prevMethod?.value
    ) {
      if (canChangeMotorTaxPaymentMethod) {
        formik.setFieldValue("motorTaxPaymentMethod", paymentMethod);
      }
    }
  }, [
    paymentMethod,
    canChangeMotorTaxPaymentMethod,
    formik.touched["paymentMethod"],
    formik.values["paymentMethod"],
    premiumTypeKey,
    formik.touched,
    formik.isSubmitting,
  ]);
};

export const QUERY_KEYS = {
  userData: "userData",
  uadTaxonomy: "uadTaxonomy",
  appSettings: "appSettings",
  fleetInsurerDefaultSettings: "fleetInsurerDefaultSettings",
  fleetVehicleDefaultSettings: "fleetVehicleDefaultSettings",
  insurerSettings: "insurerSettings",
  insurerListSettings: "insurerListSettings",
  clientList: "clientList",
  insurerListByCountry: "insurerListByCountry",
};

export const MUTATION_KEYS = {
  addAppSettings: "addAppSettings",
  updateAppSetting: "updateAppSetting",
  addFleetPremium: "addFleetPremium",
  adjustFleetDeductible: "adjustFleetDeductible",
  adjustFleet: "adjustFleet",
  changeFleetPaymentMethod: "changeFleetPaymentMethod",
  compareInsurerBookings: "compareInsurerBookings",
  createFleet: "createFleet",
  deleteAppSetting: "deleteAppSetting",
  deleteFleet: "deleteFleet",
  deleteMultipleVehicles: "deleteMultipleVehicles",
  deleteVehicle: "deleteVehicle",
  generateFleetVehicleReport: "generateFleetVehicleReport",
  indexFleet: "indexFleet",
  recalculateFleetProRataAndBookings: "recalculateFleetProRataAndBookings",
  recalculateVehicleProRataAndBookings: "recalculateVehicleProRataAndBookings",
  cancelFleetPremium: "cancelFleetPremium",
  synchronizeFleetClient: "synchronizeFleetClient",
  updateFleet: "updateFleet",
  updateBulkFleetPremium: "updateBulkFleetPremium",
  createFleetInsurerDefaultSetting: "createFleetInsurerDefaultSetting",
  updateFleetInsurerDefaultSetting: "updateFleetInsurerDefaultSetting",
  deleteFleetInsurerDefaultSetting: "deleteFleetInsurerDefaultSetting",
  updateFleetVehicleDefaultSetting: "updateFleetVehicleDefaultSetting",
  createFleetVehicleDefaultSetting: "createFleetVehicleDefaultSetting",
  deleteFleetVehicleDefaultSetting: "deleteFleetVehicleDefaultSetting",
  duplicateVehicleWithPremiums: "duplicateVehicleWithPremiums",
  changeVehicleStatus: "changeVehicleStatus",
  changeVehiclePolicyStatus: "changeVehiclePolicyStatus",
  changeVehicleIssuingStatus: "changeVehicleIssuingStatus",
  changeMultipleVehicleIssuingStatus: "changeMultipleVehicleIssuingStatus",
  changeMultipleVehiclePendingIssuingStatus:
    "changeMultipleVehiclePendingIssuingStatus",
  searchVehiclesForExcel: "searchVehiclesForExcel",
  updateVehicle: "updateFleet",
  updateTempVehicle: "updateTempVehicle",
  createVehicle: "createVehicle",
  createTradeLicensePlate: "createTradeLicensePlate",
  importScanDocument: "importScanDocument",
  updateInsurerSettings: "updateInsurerSettings",
  createInsurerSettings: "createInsurerSettings",
  insertSharedLicensePlate: "insertSharedLicensePlate",
  cancelSharedLicensePlate: "cancelSharedLicensePlate",
  correctSharedLicensePlate: "correctSharedLicensePlate",
  deleteSharedLicensePlate: "deleteSharedLicensePlate",
  continueSharedLicensePlate: "continueSharedLicensePlate",
  activateSharedLicensePlate: "activateSharedLicensePlate",
  suspendSharedLicensePlate: "suspendSharedLicensePlate",
  insertVehiclePremium: "insertVehiclePremium",
  addVehiclePremium: "addVehiclePremium",
  updatePremiumBookingIssuingStatus: "updatePremiumBookingIssuingStatus",
  updateVehiclePremiumBookingIssuingStatus:
    "updateVehiclePremiumBookingIssuingStatus",
  correctVehicleLicensePlate: "correctVehicleLicensePlate",
  insertVehicleLicensePlate: "insertVehicleLicensePlate",
  deleteVehicleLicensePlate: "deleteVehicleLicensePlate",
  changeVehicleLicensePlate: "changeVehicleLicensePlate",
  insertVehicleOneTimePremium: "insertVehicleOneTimePremium",
  correctVehicleOneTimePremium: "correctVehicleOneTimePremium",
  deleteVehicleOneTimePremium: "deleteVehicleOneTimePremium",
  correctVehiclePremium: "correctVehiclePremium",
  correctLastVehiclePremium: "correctLastVehiclePremium",
  adjustVehiclePremium: "adjustVehiclePremium",
  cancelVehiclePremium: "cancelVehiclePremium",
  deleteVehiclePremium: "deleteVehiclePremium",
  continueVehiclePremium: "continueVehiclePremium",
  correctVehicleStatus: "correctVehicleStatus",
  correctVehiclePolicyStatus: "correctVehiclePolicyStatus",
  insertVehicleStatus: "insertVehicleStatus",
  deleteVehicleStatus: "deleteVehicleStatus",
  deleteVehiclePolicyStatus: "deleteVehiclePolicyStatus",
  cancelVehiclePendingStatus: "cancelVehiclePendingStatus",
  transferTEMPVehicle: "transferTEMPVehicle",
  deleteTEMPVehicle: "deleteTEMPVehicle",
  approveVehiclePendingStatus: "approveVehiclePendingStatus",
  correctVehicleStatusDate: "correctVehicleStatusDate",
  confirmVehicleChanges: "confirmVehicleChanges",
  deleteInsurerSettings: "deleteInsurerSettings",
  deleteInsurerSetting: "deleteInsurerSetting",
  changeFleetPremiumDueDate: "changeFleetPremiumDueDate",
};

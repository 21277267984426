import { useCurrentRoute } from "config/routes";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadInsurerSettingsActions } from "store/vehicle";
import { useSelector } from "../../../store/hooks";

export const useFetchInsurerSettings = () => {
  const insurerSettingsExist = useSelector((s) => s.vehicle.insurerSettings);
  const countryCode = useSelector((s) => s.vehicle?.fleet?.countryCode);
  const dispatch = useDispatch();
  const route = useCurrentRoute();

  useEffect(() => {
    if (
      (route === "editVehicle" || route === "editTempVehicle") &&
      !insurerSettingsExist &&
      countryCode
    ) {
      dispatch(
        loadInsurerSettingsActions.trigger({ countryCode: countryCode + "" })
      );
    }
  }, [insurerSettingsExist, route, countryCode]);
};

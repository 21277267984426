import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useTranslation } from "react-i18next";
import { timeFormat } from "d3";
import moment from "moment";

const tickFormat = timeFormat("%d.%m.%Y");

export const PendingStatusTooltip = ({ children, d }) => {
  const { t } = useTranslation();

  let period = (
    <>
      {t("bfm.from.label")}: {tickFormat(d.startDate)}
      <br />
    </>
  );
  return (
    <Tippy
      delay={100}
      followCursor={true}
      disabled={false}
      content={
        <span>
          {t(d.domain.value)}
          <br />
          {period}
          <hr />
          {t("bfm.changeReasonComment.label")}:{" "}
          {d.extraData.vehicleLastStatusComment}
        </span>
      }
    >
      {children}
    </Tippy>
  );
};

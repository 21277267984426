import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPhraseToolActivated: false,
};

const phraseToolSlice = createSlice({
  name: "phraseTool",
  initialState,
  reducers: {
    setPhraseToolActivationStatus: (state, action) => {
      state.isPhraseToolActivated = action.payload;
    },
  },
});

export const selectIsPhraseToolActivated = (state) =>
  state.phraseTool?.isPhraseToolActivated;

export const { setPhraseToolActivationStatus } = phraseToolSlice.actions;

export default phraseToolSlice;

import { useMutation } from "react-query";
import { DeleteVehicleParams, deleteVehicle } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

export const deleteVehicleFn = async (params: DeleteVehicleParams) => {
  return await deleteVehicle(params);
};

export function useDeleteVehicle() {
  return useMutation(deleteVehicleFn, {
    mutationKey: MUTATION_KEYS.deleteVehicle,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

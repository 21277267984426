import React from "react";
import { useSelector } from "../../../../../store/hooks";

import { vehiclePolicyStatus } from "config/colors";
import { TimelineSegmentType, TimelineType } from "../../../../../types/types";
import PolicyStatusPoint from "./PolicyStatusPoint";
import PolicyStatusSegment from "./PolicyStatusSegment";

function aggregateObjects(objects, xScale, range) {
  const groupedByDomain = objects.reduce((acc, obj) => {
    const domainValue = obj.domain.value;
    if (!acc[domainValue]) {
      acc[domainValue] = [];
    }
    acc[domainValue].push(obj);
    return acc;
  }, {});

  const aggregated = [];
  Object.values(groupedByDomain).forEach((group: any[]) => {
    let currentGroup = [];
    let lastScaledValue = null;
    group.forEach((obj) => {
      const scaledValue = xScale(obj.startDate);

      if (
        lastScaledValue === null ||
        Math.abs(scaledValue - lastScaledValue) <= range
      ) {
        currentGroup.push(obj);
      } else {
        aggregated.push(currentGroup);
        currentGroup = [obj];
      }

      lastScaledValue = scaledValue;
    });
    if (currentGroup.length > 0) {
      aggregated.push(currentGroup);
    }
  });

  return aggregated;
}

const returnStatusColors = (statuses: any[]): any => {
  const retVal: any = {};
  statuses.forEach((d, index) => {
    let segmentClass = "";
    let fillColor = "";
    let strokeColor = "";
    let strokeWidth = "";

    strokeColor = "#444444";
    const arrowStrokeColor = "#eeeeee";
    strokeWidth = "2";
    let pointColor = "white";
    if (
      d.segmentType === TimelineSegmentType.VOID_AFTER ||
      d.segmentType === TimelineSegmentType.VOID_BEFORE ||
      d.segmentType === TimelineSegmentType.VOID_BETWEEN
    ) {
      segmentClass = "void";
      fillColor = "white";
      strokeColor = "white";
      strokeWidth = "0";
    }

    if (d.segmentType === TimelineSegmentType.POLICY_STATUS_PENDING) {
      segmentClass = "status";
      fillColor = vehiclePolicyStatus["VehiclePolicyStatusCode.PENDING"];
      pointColor = "pending";
    }
    if (
      d.segmentType === TimelineSegmentType.POLICY_STATUS_WAITING_FOR_CHECKING
    ) {
      segmentClass = "status";
      fillColor =
        vehiclePolicyStatus["VehiclePolicyStatusCode.WAITING_FOR_CHECKING"];
      pointColor = "waiting-for-checking";
    }
    if (
      d.segmentType === TimelineSegmentType.POLICY_STATUS_WAITING_FOR_CLIENT
    ) {
      segmentClass = "status";
      fillColor =
        vehiclePolicyStatus["VehiclePolicyStatusCode.WAITING_FOR_CLIENT"];
      pointColor = "waiting-for-client";
    }
    if (
      d.segmentType === TimelineSegmentType.POLICY_STATUS_WAITING_FOR_INSURER
    ) {
      segmentClass = "status";
      fillColor =
        vehiclePolicyStatus["VehiclePolicyStatusCode.WAITING_FOR_INSURER"];
      pointColor = "waiting-for-insurer";
    }
    if (
      d.segmentType ===
      TimelineSegmentType.POLICY_STATUS_WAITING_FOR_REGISTRATION
    ) {
      segmentClass = "status";
      fillColor =
        vehiclePolicyStatus["VehiclePolicyStatusCode.WAITING_FOR_REGISTRATION"];
      pointColor = "waiting-for-registration";
    }
    if (d.segmentType === TimelineSegmentType.POLICY_STATUS_WAITING_FOR_USER) {
      segmentClass = "status";
      fillColor =
        vehiclePolicyStatus["VehiclePolicyStatusCode.WAITING_FOR_USER"];
      pointColor = "waiting-for-user";
    }

    if (d.segmentType === TimelineSegmentType.POLICY_STATUS_CLOSED) {
      segmentClass = "status";
      fillColor = vehiclePolicyStatus["VehiclePolicyStatusCode.CLOSED"];
      pointColor = "closed";
    }

    retVal[d.segmentType] = {
      segmentClass,
      fillColor,
      pointColor,
      strokeColor,
      strokeWidth,
      arrowStrokeColor,
    };
  });
  return retVal;
};

export const PolicyStatusSegments = ({
  xScale,
  yScale,
  innerWidth,
  expandablePointRefs,
  expandedPointSectionRefs,
}) => {
  const timelineTypes = useSelector((s) => s.timeline.types);
  const data = useSelector((s) => s.timeline.timeline.filteredData);
  const appSettings = useSelector((s) => s.timeline.timelineAppSettings);

  if (
    !data ||
    !timelineTypes ||
    !timelineTypes.includes(TimelineType.VEHICLE_POLICY_STATUSES)
  ) {
    return null;
  }

  let statusSegments = data.filter(
    (segment) => segment.domain.type === TimelineType.VEHICLE_POLICY_STATUSES
  );

  const aggregatedStatusSegments = aggregateObjects(
    statusSegments.filter((el) => !el.segmentType.startsWith("void")),
    xScale,
    16
  );
  let statuses: any = <></>;
  const statusColors = returnStatusColors(statusSegments);

  statuses = statusSegments.map((d, index) => {
    const bar = (
      <PolicyStatusSegment
        key={index}
        segment={d}
        xScale={xScale}
        yScale={yScale}
        statusColors={statusColors}
        index={index}
        innerWidth={innerWidth}
      />
    );

    return <g key={index}>{bar}</g>;
  });

  const circles = aggregatedStatusSegments.map((segmentList: any[], index) => {
    return (
      <PolicyStatusPoint
        key={"psp" + index}
        statusColors={statusColors}
        segmentList={segmentList}
        xScale={xScale}
        yScale={yScale}
        expandablePointRefs={expandablePointRefs}
        expandedPointSectionRefs={expandedPointSectionRefs}
      />
    );
  });
  return (
    <>
      {statuses}
      {circles}
    </>
  );
};

import { Label } from "@fluentui/react";
import Select, { mergeStyles } from "react-select";
import { useTheme } from "styled-components";

import { FieldContainer } from "components/controls/FieldContainer";
import { useField, useFormikContext } from "formik";
import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IStyledTheme } from "theme/types";
import { VSpace } from "../../components/Spacer";
import { InsurerBranchOfficeTaxonomyFieldConfig } from "../../components/form/types";
import { useDynamicConfig } from "../../components/form/useDynamicConfig";
import { ErrorMessage } from "../../components/styled";
import { RefContext } from "../../contexts/RefProvider";
import { useSelector } from "../../store/hooks";
import { getSelectStyles } from "../../utils/utils";

type Props = { fieldConfig: InsurerBranchOfficeTaxonomyFieldConfig };

export const InsurerBranchOfficeTaxonomyField = ({ fieldConfig }: Props) => {
  const config = {
    ...fieldConfig,
    ...useDynamicConfig(fieldConfig.d),
  };
  const {
    name = "",
    label,
    isVisible = true,
    isDisabled,
    isRequired,
    isSearchable,
    validate,
    include,
    renderField,
    background,
    isSortedAlphabetically,
  } = config;
  const [field, { error }, { setValue }] = useField({
    name,
    validate,
  });
  const { values, setFieldValue } = useFormikContext();
  const insurerListState = useSelector((s) => s.vehicle.insurerList);
  const [insurerList, setInsurerList] = useState(insurerListState);
  const [reportInsurerBranchOffices, setReportInsurerBranchOffices] =
    useState(null);

  useEffect(() => {
    if (values && values["insurerPartnerNumber"] !== null) {
      const insurer = insurerList.find(
        (insurer) =>
          insurer.insurerInternalNumber ===
          values["insurerPartnerNumber"]?.value
      );
      if (
        reportInsurerBranchOffices !== null &&
        !_.isEqual(
          insurer?.reportInsurerBranchOffices,
          reportInsurerBranchOffices
        )
      ) {
        setFieldValue("insurerBranchOffice", null);
      }

      setReportInsurerBranchOffices(insurer?.reportInsurerBranchOffices);
    }
    if (values["insurerPartnerNumber"] === null) {
      setReportInsurerBranchOffices(null);
      setFieldValue("insurerBranchOffice", null);
    }
  }, [
    insurerList,
    values,
    field.value,
    reportInsurerBranchOffices,
    setFieldValue,
  ]);

  const { setRef } = useContext(RefContext);
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;

  const isDarkMode = theme.isDark;

  const options = useMemo(() => {
    const options = reportInsurerBranchOffices
      ? reportInsurerBranchOffices
          .filter((tax) => {
            if (include) {
              return include.includes(
                tax.branchOfficeName +
                  " (" +
                  tax.branchOfficeInternalNumber +
                  ")"
              );
            }
            //
            else {
              return true;
            }
          })
          .map((tax) => {
            return {
              value: tax.branchOfficeInternalNumber,
              label:
                tax.branchOfficeName +
                " (" +
                tax.branchOfficeInternalNumber +
                ")",
            };
          })
      : [];

    if (isSortedAlphabetically) {
      options.sort(function (a, b) {
        var textA = a.label.toUpperCase();
        var textB = b.label.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }
    return options;
  }, [include, isSortedAlphabetically, reportInsurerBranchOffices]);

  if (!isVisible) return null;

  const fieldRender = (
    <Select
      menuPosition="fixed"
      id={name}
      options={options as any}
      isSearchable={isSearchable}
      {...field}
      onChange={(value) => {
        setValue(value);
      }}
      styles={mergeStyles(getSelectStyles(!!error, theme), {
        menuPortal: (p) => ({
          ...p,
          background: theme.palette.neutralLighter,
          zIndex: 9999999,
        }),
        menu: (p) => ({
          ...p,
          zIndex: 9999,
          background: theme.palette.neutralLighter,
        }),
        control: (p, state) => {
          return {
            ...p,

            background: state.isDisabled ? "transparent" : theme.palette.white,

            borderBottom: error
              ? `2px solid rgb(164, 38, 44) !important`
              : state.isFocused
              ? `2px solid rgb(0, 90, 161) !important`
              : `1px solid ${theme.palette.black} !important`,
            boxShadow: "none",
            ...(state.isDisabled && {
              borderBottom: `1px solid ${theme.palette.neutralLighter} !important`,
            }),
          };
        },
        option: (p, state) => ({
          ...p,
          background:
            state.isSelected || state.isFocused
              ? theme.palette.neutralLighter
              : theme.palette.white,
          "&:hover": {
            background: theme.palette.neutralLighter,
          },
          color: theme.palette.black,
          fontSize: "14px",
          fontWeight: 400,
          wordWrap: "break-word",
        }),
        input: (p) => ({
          ...p,
          color: (function () {
            if (isDisabled) {
              return theme.palette.neutralSecondaryAlt;
            }
            if (isDarkMode) {
              return "white";
            }
            return theme.palette.black;
          })(),
        }),
        singleValue: (p, state) => ({
          ...p,
          color: theme.isDark
            ? "white"
            : isDisabled
            ? theme.palette.neutralSecondaryAlt
            : "black",
          fontSize: "14px",
          fontWeight: 400,
        }),
        dropdownIndicator: (p, state) => ({
          ...p,
          ...(state.isDisabled && { visibility: "hidden" }),
        }),
        indicatorSeparator: (p, state) => ({
          ...p,
          ...(state.isDisabled && { visibility: "hidden" }),
        }),
      })}
      placeholder={isDisabled ? "" : t("greco.form.selectPlaceholder")}
      noOptionsMessage={() => t("greco.noResults")}
      isDisabled={isDisabled}
      isClearable
      ref={setRef(name) as any}
      inputId={name}
    />
  );

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <FieldContainer
        isTooltipHidden={isDisabled}
        tooltipText={label}
        isReadOnly={true}
      >
        <Label required={isRequired} disabled={isDisabled}>
          {t(label)}
        </Label>
        {renderField ? renderField({ field: fieldRender }) : fieldRender}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </FieldContainer>
      <VSpace height={10} />
    </div>
  );
};

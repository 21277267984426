import { usePrevious } from "@uifabric/react-hooks";
import { useCurrentRoute } from "config/routes";
import { RefObject, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "store/hooks";
import * as VehicleSlice from "store/vehicle";

type Props = {
  generalFormRef: RefObject<HTMLElement>;
  vehicleFormRef: RefObject<HTMLElement>;
};

const useTabChange = ({ generalFormRef, vehicleFormRef }: Props) => {
  const dispatch = useDispatch();
  const tab = useSelector((s) => s.vehicle.tab);
  const [selectedTab, setSelectedTab] = useState(tab);
  const route = useCurrentRoute();
  const wrappElement = document.querySelector("#vehicle-form-wrapper");

  const changeActiveTab = useCallback(
    (wrappElement) => {
      const mainScrollHeight = wrappElement.scrollTop;
      const generalElement = generalFormRef?.current?.offsetHeight - 100;
      const vehicleElement =
        vehicleFormRef?.current?.offsetHeight + 50 || generalElement; //general when vehicleElement does not exist (on trade license plate form)

      if (
        tab !== "general-vehicle-form" &&
        mainScrollHeight <= generalElement
      ) {
        setSelectedTab("general-vehicle-form");
      }
      if (
        tab !== "vehicle-form" &&
        mainScrollHeight > generalElement &&
        mainScrollHeight < vehicleElement
      ) {
        setSelectedTab("vehicle-form");
      }
      if (tab !== "finance-vehicle-form" && mainScrollHeight > vehicleElement) {
        setSelectedTab("finance-vehicle-form");
      }
    },
    [generalFormRef, vehicleFormRef, tab, dispatch]
  );

  const previousTab = usePrevious(selectedTab);
  useEffect(() => {
    if (previousTab !== selectedTab) {
      dispatch(VehicleSlice.setTab(selectedTab));
    }
  }, [selectedTab]);

  useEffect(() => {
    if (route !== "readOnlyVehicle") {
      wrappElement?.addEventListener("scroll", () =>
        changeActiveTab(wrappElement)
      );
    }
    return () => {
      wrappElement?.removeEventListener("scroll", () =>
        changeActiveTab(wrappElement)
      );
    };
  }, [wrappElement, tab, route]);
};

export default useTabChange;

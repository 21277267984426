import { toast } from "components/FluentToast";
import { useChangeVehicleStatus } from "hooks/data/mutations/useChangeVehicleStatus";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ChangeVehicleStatusDialog as ChangeVehicleStatusDialogGeneric } from "../../components/ChangeVehicleStatusDialog";
import {
  loadVehiclesActions,
  setChangeVehicleStatusDialog,
} from "../../store/fleetPage";
import { useSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/store";

export const ChangeVehicleStatusDialog = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const changeVehicleStatusDialog = useSelector(
    (s) => s.fleetPage.changeVehicleStatusDialog
  );

  const { mutate: onChangeVehicleStatus, isLoading: isUpdateStatusLoading } =
    useChangeVehicleStatus();

  const handleChangeVehicleStatus = useCallback((payload) => {
    onChangeVehicleStatus(payload, {
      onSuccess: () => {
        dispatch(loadVehiclesActions.trigger({ isReset: true }));
        toast.success(t("bfm.statusChanged"));
        close();
      },
    });
  }, []);

  const close = () => {
    dispatch(
      setChangeVehicleStatusDialog({
        isOpen: false,
        fleetId: null,
        vehicle: null,
        vehicleIds: [],
      })
    );
  };

  return (
    <ChangeVehicleStatusDialogGeneric
      close={close}
      changeVehicleStatusDialog={changeVehicleStatusDialog}
      isUpdateStatusLoading={isUpdateStatusLoading}
      updateVehicleStatusAction={handleChangeVehicleStatus}
      updateVehiclesStatusAction={handleChangeVehicleStatus}
    />
  );
};

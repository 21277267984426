import { ActionButton, mergeStyleSets } from "@fluentui/react";
import { isExternalOrReadonlyAdmin } from "form/util";
import { useFormikContext } from "formik";
import { MUTATION_KEYS } from "hooks/data/queryKeys";
import { constants } from "library/constants";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useIsMutating } from "react-query";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { selectShouldDisableForm, setImportScanDocument } from "store/vehicle";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { BackButton } from "../../../components/navigation/BackButton";
import { Links } from "../../../components/navigation/Links";
import { HSpace, Right } from "../../../components/styled";
import { routes, useCurrentRoute } from "../../../config/routes";
import { setShowTEMP } from "../../../store/fleetPage";
import { useSelector } from "../../../store/hooks";
import { ConfirmChangesButton } from "./ConfirmChangesButton";
import DownloadScanButton from "./DownloadScanButton";
import EditButton from "./EditButton";
import ImportScanButton from "./ImportScanButton";
import SaveButton from "./SaveButton";
import { TransferVehicleButton } from "./TransferVehicleButton";

const getClasses = (theme) =>
  mergeStyleSets({
    navigation: {
      height: "44px",
      background: theme.palette.white,
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: "0 25px",
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
      position: "sticky",
      top: 0,
      zIndex: 999,
    },
  });

type Props = {
  disabled: boolean;
  setGoToEdit: (value: boolean) => void;
};

export const Navigation = ({ disabled, setGoToEdit }: Props) => {
  const route = useCurrentRoute();

  const location = useLocation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const vehicle = useSelector((s) => s.vehicle.vehicle);
  const fleet = useSelector((s) => s.vehicle.fleet);

  const { errors } = useFormikContext();
  const {
    createVehicle,
    editVehicle,
    editTempVehicle,
    duplicateVehicle,
    createTradeVehicle,
    readOnlyVehicle,
  } = constants;
  const dispatch = useDispatch();
  const theme = useTheme() as IStyledTheme;
  const { fleetId } = useParams() as any;

  const vehicleUpdatingInProgress = !!useIsMutating({
    mutationKey: [MUTATION_KEYS.updateVehicle],
  });
  const userRole = useSelector((s) => s.auth.userRole);
  const actionInProgress = useSelector(selectShouldDisableForm)(route);
  const { t } = useTranslation();

  const classes = getClasses(theme);

  const isReadOnlyVehicle = route === readOnlyVehicle;
  const isCreateVehicle = route === createVehicle;

  const isEditVehicle = route === editVehicle;
  const isEditTempVehicle = route === editTempVehicle;
  const isDuplicateVehicle = route === duplicateVehicle;
  const isTradeLicensePlate =
    route === createTradeVehicle || vehicle?.isTradeLicensePlate;
  const isLoading = vehicleUpdatingInProgress;

  const vehicleStatusTaxonomyCode =
    taxonomy.VehicleStatusCode.byId[vehicle?.vehicleStatusCode]?.code;
  const navigatedFromVehicleList = location?.state?.fromVehicleList;
  const isCreateTradeLicensePlate = route === createTradeVehicle;
  const scannedRegistrationDocumentPath = useSelector(
    (s) => s.vehicle?.scannedFormFields?.scannedRegistrationDocumentPath
  );

  const backRoute = isTradeLicensePlate
    ? routes.tradeLicensePlates.getPath(fleetId)
    : routes.fleet.getPath(fleetId);

  useEffect(() => {
    if (errors) {
      setGoToEdit(false);
    }
  }, [errors]);

  const fieldsUpdatedByExternalUser = (
    vehicle?.fieldsUpdatedByExternalUser || ""
  )
    ?.split(",")
    ?.filter((item) => {
      return item !== "vehicleStatusCode";
    });
  return (
    <div className={classes.navigation}>
      <Links />
      <Link
        to={routes.fleet.getPath(fleetId)}
        onClick={() => {
          if (isEditTempVehicle) {
            dispatch(setShowTEMP(true));
          }
        }}
      >
        <ActionButton text={fleet?.fleetName} />
      </Link>
      <BackButton
        text={"bfm.back"}
        path={navigatedFromVehicleList ? "" : backRoute}
      />
      <HSpace width={50} />
      {isCreateVehicle ? (
        <ImportScanButton
          disabled={disabled || isLoading || actionInProgress}
          action={() => {
            dispatch(
              setImportScanDocument({
                isOpen: true,
                file: null,
              })
            );
          }}
        />
      ) : null}
      {(vehicle && vehicle?.scannedRegistrationDocumentPath) ||
      scannedRegistrationDocumentPath ? (
        <DownloadScanButton
          disabled={disabled || isLoading || actionInProgress}
          action={() => {
            openInNewTab(
              vehicle?.scannedRegistrationDocumentPath
                ? vehicle?.scannedRegistrationDocumentPath
                : scannedRegistrationDocumentPath
            );
          }}
        />
      ) : null}

      <Right>
        {isEditTempVehicle && (
          <TransferVehicleButton
            disabled={disabled}
            isTradeLicensePlate={isTradeLicensePlate}
          />
        )}
        {(route === "editVehicle" || route === "readOnlyVehicle") &&
        !isExternalOrReadonlyAdmin(userRole) &&
        vehicle?.needsGrEcoUserAttention &&
        fieldsUpdatedByExternalUser?.length ? (
          <>
            <ConfirmChangesButton />
            <HSpace />
          </>
        ) : null}
        {(isCreateVehicle ||
          isEditVehicle ||
          isDuplicateVehicle ||
          isCreateTradeLicensePlate ||
          isEditTempVehicle) && (
          <SaveButton
            text={t("greco.save")}
            iconName="Save"
            disabled={disabled || isLoading || actionInProgress}
          />
        )}
        {(isCreateVehicle ||
          isDuplicateVehicle ||
          isCreateTradeLicensePlate) && (
          <SaveButton
            text={t("bfm.saveAndEdit.label")}
            iconName="SaveAs"
            onClick={() => {
              setGoToEdit(true);
            }}
            disabled={disabled || isLoading || actionInProgress}
          />
        )}
        {/* vehicleStatusTaxonomyCode !== "VehicleStatusCode.DEACTIVATED" && */}
        {isReadOnlyVehicle && vehicleStatusTaxonomyCode && (
          <EditButton disabled={disabled} />
        )}
      </Right>
    </div>
  );
};

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

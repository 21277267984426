import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import NumberInputField from "components/controls/NumberInputField";
import TextInputField from "components/controls/TextInputField";
import { vehicleFormClasses } from "pages/vehicle/VehicleForm";
import React from "react";
import {
  isDisabledExternal,
  isDisabledExternalOrHasBeenActivated,
} from "../../../helpers";

const FinanaceTempAccidentFields = ({
  formikProps,
  userRole,
  route,
  vehicle,
}) => {
  const { setFieldValue, handleChange, values, errors } = formikProps;

  return (
    <>
      <div className={vehicleFormClasses.item}>
        <DoubleNumberInputField
          setFieldValue={setFieldValue}
          value={values?.accidentAnnualGrossPremium}
          onChange={handleChange}
          errors={errors}
          name={"accidentAnnualGrossPremium"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.annualGrossPremium.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "accidentAnnualGrossPremium"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <DoubleNumberInputField
          setFieldValue={setFieldValue}
          value={values?.accidentAnnualNetPremium}
          onChange={handleChange}
          errors={errors}
          name={"accidentAnnualNetPremium"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.annualNetPremium.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "accidentAnnualNetPremium"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.accidentSuspensionDaysLimit}
          onChange={handleChange}
          errors={errors}
          name={"accidentSuspensionDaysLimit"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          isNegativeAllowed={true}
          label={"bfm.vehicleForm.accidentSuspensionDaysLimit.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "accidentSuspensionDaysLimit"
          )}
        />
      </div>
      <div className={vehicleFormClasses.fullWidthFormItem}>
        <TextInputField
          value={values.accidentComment}
          onChange={handleChange}
          errors={errors}
          name={"accidentComment"}
          multiline
          rows={4}
          disabled={isDisabledExternal({ userRole, route })}
          label={"bfm.vehicleForm.accidentComment.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "accidentComment"
          )}
        />
      </div>
    </>
  );
};

export default FinanaceTempAccidentFields;

import { AccountInfo } from "@azure/msal-browser";
import { GrecoSpinner } from "@greco/components";
import ReactDOM from "react-dom/client";

import { initializeIcons } from "@uifabric/icons";
import Axios from "axios";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import "react-virtualized/styles.css";
// import "../node_modules/@greco/services/dist/lib/main.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import App from "./App";
import { FleetBFFParams } from "./config/MSGraphConfig";
import "./i18n";
import "./index.scss";
import "./react-select.css";
import * as serviceWorker from "./serviceWorker";
import { MSGraphAuth } from "./store/api/GraphService";
import { store } from "./store/store";

import "@greco/components/dist/index.css";

import "../node_modules/@greco/components/dist/index.css";
import "../node_modules/@greco/services/dist/lib/main.css";

import "@greco/components/dist/index.css";

// import "../node_modules/@greco/components/dist/index.css";
// import "../node_modules/@greco/services/dist/lib/main.css";

Axios.interceptors.request.use(async (config) => {
  let authResponse = await MSGraphAuth.getAccessToken(FleetBFFParams);
  if (!authResponse) return config;
  if (config.url?.indexOf("thumbnailPhoto") !== -1) {
    return config;
  }
  config.headers.Authorization = `Bearer ${authResponse.accessToken}`;
  return config;
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnMount: true,
      refetchIntervalInBackground: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      //staleTime: 3_600_000, //1 hour cache time
      staleTime: 0, //no cache time
    },
  },
});

const startApplication = () => {
  initializeIcons();
  root.render(
    <Suspense fallback={<GrecoSpinner />}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <App />
        </Provider>
        <ReactQueryDevtools initialIsOpen={true} />
      </QueryClientProvider>
    </Suspense>
  );
};

MSGraphAuth.accountObj
  .then((accountInfo: AccountInfo | null) => {
    if (accountInfo) {
      startApplication();
    } else {
      MSGraphAuth.login();
    }
  })
  .catch((error) => {
    if (error === "There isn't any account detected.") {
      MSGraphAuth.login();
    } else {
      console.log(error);
    }
  });

serviceWorker.unregister();

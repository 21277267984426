import { useQuery } from "hooks/useQuery";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserRole } from "types/types";
import { routes } from "../../config/routes";
import {
  loadFleetsActions,
  resetState,
  selectShownFleets,
  selectSortFleetsPage,
} from "../../store/fleetsPage";
import { useSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/store";
import { DeleteDialog } from "./DeleteDialog";
import FleetListTable from "./FleetList/FleetListTable";
import { defaultColumns } from "./FleetList/columns";
import { Navigation } from "./Navigation";

export const Fleets = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const fleets = useSelector(selectShownFleets);
  const sort = useSelector(selectSortFleetsPage);
  const query = useQuery();

  const userRole = useSelector((s) => s.auth.userRole);
  const stopFleetRedirect = useSelector((s) => s.fleetsPage.stopFleetRedirect);
  const clientId = query.get("clientId");

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    dispatch(loadFleetsActions.trigger({ clientId }));
  }, [clientId]);

  useEffect(() => {
    const nonRedirectUserRole = [
      UserRole.admin,
      UserRole.admininternal,
      UserRole.adminreadonly_admininternal,
    ];

    if (
      userRole != null &&
      fleets?.length === 1 &&
      !nonRedirectUserRole.includes(userRole) &&
      !stopFleetRedirect
    ) {
      navigate(routes.fleet.getPath((fleets[0] as any).fleetId));

      // window.location.href = `/fleets/${fleets[0].fleetId}`;
    } else {
      if (stopFleetRedirect) {
        // dispatch(setStopFleetRedirect(false));
      }
    }
  }, [fleets, userRole, stopFleetRedirect]);

  const detailsListColumns = useMemo(() => {
    const filteredTableColumns = defaultColumns.filter((c) => {
      if (
        userRole === undefined ||
        userRole === null ||
        userRole === UserRole.external
      ) {
        return c.key !== "options";
      }
      return true;
    });
    const withTranslatedHeader = filteredTableColumns
      .map((column: any) => {
        let iconName = column.iconName;

        if (column.key === sort.key && column.isSortable) {
          iconName =
            sort.key === column.key
              ? sort!.dir === "asc"
                ? "SortUp"
                : "SortDown"
              : "Sort";
        }
        return {
          ...column,
          name: t(column.name),
          iconName: iconName,
        };
      })
      .concat([
        {
          key: "filler",
          label: "",
          width: 0,
        },
      ]);
    return [...withTranslatedHeader];
  }, [userRole, sort, t]);
  return (
    <>
      <Navigation />
      <FleetListTable items={fleets} columns={detailsListColumns} />
      <DeleteDialog />
    </>
  );
};

import { useMutation } from "react-query";
import { UpdateFleetPremiumParams, updateFleetPremium } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const updateFleetPremiumFn = async (params: UpdateFleetPremiumParams) => {
  return await updateFleetPremium(params);
};

export function useBulkUpdateFleetPremium() {
  return useMutation(updateFleetPremiumFn, {
    mutationKey: MUTATION_KEYS.updateBulkFleetPremium,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { IContextualMenuItem, mergeStyles } from "@fluentui/react";
import AppSettings from "AppSettings";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDeleteDialog } from "store/insurersPage";
import { Insurer, InsurerSettings, UserRole } from "types/types";
import {
  CenteredCell,
  ListContextMenuIconButton,
} from "../../../components/styled";
import { routes } from "../../../config/routes";
import { useSelector } from "../../../store/hooks";

export const ContextButtonInsurers = ({
  insurer,
  insurerSettingsHistory,
}: {
  insurer: Insurer;
  insurerSettingsHistory: InsurerSettings[];
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userRole = useSelector((s) => s.auth.userRole);
  const taxonomy = useSelector((s) => s.taxonomy);

  let items: IContextualMenuItem[] = [];
  if (insurerSettingsHistory?.length > 0) {
    items.push({
      key: "view",
      text: t("bfm.viewInsurerDefaultSettings.label"),
      className: userRole === UserRole.admin ? displayNoneClass : "",
      onClick: () => {
        navigate(routes.editInsurer.getPath(insurer.insurerInternalNumber));
      },
    });
    items.push({
      key: "edit",
      text: t("bfm.editInsurerDefaultSettings.label"),
      className: userRole !== UserRole.admin ? displayNoneClass : "",
      onClick: () => {
        navigate(routes.editInsurer.getPath(insurer.insurerInternalNumber));
      },
    });
    items.push({
      key: "delete",
      text: t("bfm.deleteTempVehicle.label"),
      onClick: () => {
        dispatch(
          setDeleteDialog({
            isOpen: true,
            insurerSettings: insurerSettingsHistory[0] ?? null,
            mode: "all",
          })
        );
      },
      className: userRole !== UserRole.admin ? displayNoneClass : "",
    });
  } else {
    if (
      AppSettings.getInstance().getAppSettingsTypes()[
        taxonomy.Country.byId[insurer.countryCodeId].code
      ]
    ) {
      items.push({
        key: "create",
        text: t("bfm.createInsurerDefaultSettings.label"),
        onClick: () => {
          navigate(routes.editInsurer.getPath(insurer.insurerInternalNumber));
        },
        className: userRole !== UserRole.admin ? displayNoneClass : "",
      });
    }
  }

  return (
    <CenteredCell>
      <ListContextMenuIconButton
        iconProps={{ iconName: "MoreVertical" }}
        menuIconProps={{ style: { display: "none" } }}
        menuProps={{
          items: items,
        }}
      />
    </CenteredCell>
  );
};

const displayNoneClass = mergeStyles({
  display: "none",
});

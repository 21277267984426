import { InsurerTaxonomyField } from "pages/fleet-form/InsurerTaxonomyField";
import React from "react";
import { CheckboxField } from "./CheckboxField";
import { ComboboxField } from "./ComboboxField";
import { DateField } from "./DateField";
import DateTimeField from "./DateTimeField";
import { NumberField } from "./NumberField";
import { TaxonomyField } from "./TaxonomyField";
import { TextField } from "./TextField";
import { FieldConfig } from "./types";

export const Field = (config: FieldConfig) => {
  if (config.type === "number") {
    return <NumberField fieldConfig={config} />;
  }
  if (config.type === "text") {
    return <TextField fieldConfig={config} />;
  }
  if (config.type === "taxonomy") {
    return <TaxonomyField fieldConfig={config} />;
  }
  if (config.type === "checkbox") {
    return <CheckboxField fieldConfig={config} />;
  }
  if (config.type === "date") {
    return <DateField fieldConfig={config} />;
  }
  if (config.type === "datetime") {
    return <DateTimeField fieldConfig={config} />;
  }
  if (config.type === "combobox") {
    return <ComboboxField fieldConfig={config} />;
  }
  if (config.type === "insurerTaxonomy") {
    return <InsurerTaxonomyField fieldConfig={config} />;
  }
};

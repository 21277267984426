import { InsurerSettingsTemplate } from "AppSettings";
import moment from "moment";
import { Insurer, InsurerSettings } from "types/types";

const convertInsuranceLineDetail = (item: any, taxonomy): any => {
  return {
    premiumTypeCode: taxonomy.PremiumType.byCode[item.premiumTypeCode]?.id,
    maxValueForTaxCalculation: parseFloat(
      (item.maxValueForTaxCalculation + "").replace(",", ".")
    ),
    taxPercent: parseFloat((item.taxPercent + "").replace(",", ".")),
    taxExtraPercent1: parseFloat(
      (item.taxExtraPercent1 + "").replace(",", ".")
    ),
    // taxExtraPercent2: parseFloat(
    //   (item.taxExtraPercent2 + "").replace(",", ".")
    // ),
  };
};

export const convertInsurerToFormObject = (
  insurer: InsurerSettings,
  taxonomy,
  premiumTypes
): any => {
  let retVal = {
    ...insurer,
    garageRiskDiscountPercentHull: (
      insurer?.garageRiskDiscountPercentHull + ""
    ).replace(".", ","),
    garageRiskDiscountPercentHullBasic: (
      insurer?.garageRiskDiscountPercentHullBasic + ""
    ).replace(".", ","),
    suspensionDays: insurer?.suspensionDays + "",
    activationDays: insurer?.activationDays + "",
    suspensionDaysLimit: [],
    insurerSettingPerPremiumTypeDetailsHU: [],
    validFromDate:
      insurer?.validFromDate === "0001-01-01T00:00:00Z"
        ? null
        : new Date(insurer?.validFromDate),
  };

  taxonomy?.VehicleMainGroup?.items.forEach((item1) => {
    premiumTypes.forEach((item2) => {
      const premiumType = taxonomy?.PremiumType?.items.find(
        (item3) => item2 === item3.code
      );
      const item =
        insurer.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails.find(
          (setting) =>
            setting.premiumTypeCode === premiumType.id &&
            setting.vehicleMainGroupCode === item1.id
        );
      retVal.suspensionDaysLimit.push(item?.suspensionDaysLimit + "");
    });
  });

  retVal.insurerSettingPerPremiumTypeDetailsHU =
    insurer.insurerSettingPerPremiumTypeDetailsHU.map((item) => {
      return {
        premiumTypeCode: taxonomy.PremiumType.byId[item.premiumTypeCode].code,
        maxValueForTaxCalculation: (
          item.maxValueForTaxCalculation + ""
        ).replace(".", ","),
        taxPercent: (item.taxPercent + "").replace(".", ","),
        taxExtraPercent1: (item.taxExtraPercent1 + "").replace(".", ","),
        // taxExtraPercent2: (item.taxExtraPercent2 + "").replace(".", ","),
      };
    });
  return retVal;
};

export const convertFormToRequestObject = (
  data: any,
  selectedInsurer: Insurer,
  taxonomy,
  premiumTypes,
  insurerAppSettings
): any => {
  let retVal: any = {
    ...data,
    insurerPartnerNumber: parseInt(selectedInsurer.insurerInternalNumber + ""),
    suspensionDays: parseInt(data.suspensionDays),
    activationDays: parseInt(data.activationDays),
    garageRiskDiscountPercentHull: parseFloat(
      (data.garageRiskDiscountPercentHull + "").replace(",", ".")
    ),
    garageRiskDiscountPercentHullBasic: parseFloat(
      (data.garageRiskDiscountPercentHullBasic + "").replace(",", ".")
    ),
    insurerSettingPerPremiumTypeAndVehicleMainGroupDetails: [],
    insurerSettingPerPremiumTypeDetailsHU: [],
    validFromDate:
      data.validFromDate === null
        ? "0001-01-01T00:00:00Z"
        : moment(data.validFromDate).format("YYYY-MM-DD"),
  };
  // if (insurerSettings.length > 0) {
  //   retVal = {
  //     ...retVal,
  //     id: insurerSettings[selectedInsurerSettingsIndex].id,
  //     rowVersion: insurerSettings[selectedInsurerSettingsIndex].rowVersion,
  //   };
  // }
  if (
    !insurerAppSettings.INSURER_SETTINGS_TEMPLATES.includes(
      InsurerSettingsTemplate.INSURANCE_LINE_DETAILS
    )
  ) {
    let index = 0;
    taxonomy?.VehicleMainGroup?.items.forEach((item1, index1) => {
      premiumTypes.forEach((item2, index2) => {
        const premiumType = taxonomy?.PremiumType?.items.find(
          (item3) => item2 === item3.code
        );
        let itemElement: any = {
          premiumTypeCode: premiumType.id,
          vehicleMainGroupCode: item1.id,
          suspensionDaysLimit: parseInt(data.suspensionDaysLimit[index]),
        };
        if (data.id) {
          itemElement = {
            ...itemElement,
            id: data.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails[
              index
            ].id,
          };
        }
        retVal.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails.push(
          itemElement
        );
        index++;
      });
    });
    retVal.insurerSettingPerPremiumTypeDetailsHU = null;
  }
  // if (data.suspensionDaysLimit && data.suspensionDaysLimit.length > 0) {
  //   retVal.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails =
  //     data.suspensionDaysLimit.map((item) => {
  //       return Number.parseInt(item);
  //     });
  //   delete retVal.suspensionDaysLimit;
  // } else {
  //   retVal.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails = null;
  //   delete retVal.suspensionDaysLimit;
  // }

  if (
    insurerAppSettings.INSURER_SETTINGS_TEMPLATES.includes(
      InsurerSettingsTemplate.INSURANCE_LINE_DETAILS
    )
  ) {
    retVal.insurerSettingPerPremiumTypeDetailsHU =
      data.insurerSettingPerPremiumTypeDetailsHU.map((item) => {
        return {
          ...item,
          id: item.id,
          premiumTypeCode: taxonomy.PremiumType.byCode[item.premiumTypeCode].id,
          maxValueForTaxCalculation:
            parseFloat(
              (item.maxValueForTaxCalculation + "").replace(",", ".")
            ) + "",
          taxPercent: parseFloat((item.taxPercent + "").replace(",", ".")) + "",
          taxExtraPercent1:
            parseFloat((item.taxExtraPercent1 + "").replace(",", ".")) + "",
          // taxExtraPercent2:
          //   parseFloat((item.taxExtraPercent2 + "").replace(",", ".")) + "",
        };
      });
    retVal.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails = null;
  }
  return retVal;
};

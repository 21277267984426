import { useMutation, useQueryClient } from "react-query";
import { deleteInsurerSettings } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS, QUERY_KEYS } from "../queryKeys";

export const deleteInsurerSettingsFn = async ({
  insurerPartnerNumber,
}: {
  insurerPartnerNumber: string;
}) => {
  return await deleteInsurerSettings(insurerPartnerNumber);
};

export function useDeleteInsurerSettings() {
  const queryClient = useQueryClient();
  return useMutation(deleteInsurerSettingsFn, {
    mutationKey: MUTATION_KEYS.deleteInsurerSettings,
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.insurerListSettings);
    },
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import * as yup from "yup";
import { TimelineDialogType } from "../../../types/types";

export const validationObjectConfig = (item, dialogType): any => {
  const fieldSets = [];
  const licensePlate = {
    licensePlate: yup.string().required(),
  };
  const validFromDate = {
    validFromDate: yup.date().required().nullable(),
  };
  const validToDate = {
    validToDate: yup.date().required().nullable(),
  };
  const comment = {
    comment: yup.string().trim().max(512),
  };

  fieldSets.push(licensePlate); //0
  fieldSets.push(validFromDate); //1
  fieldSets.push(validToDate); //2
  fieldSets.push(comment); //3

  let yupObject: any = {};
  if (dialogType === TimelineDialogType.CORRECT_LICENSE_PLATE) {
    yupObject = {
      ...fieldSets[0],
      ...fieldSets[1],
      ...fieldSets[3],
    };
  }
  if (dialogType === TimelineDialogType.INSERT_LICENSE_PLATE) {
    yupObject = {
      ...fieldSets[0],
      ...fieldSets[1],
      ...fieldSets[2],
      ...fieldSets[3],
    };
  }
  if (dialogType === TimelineDialogType.DELETE_LICENSE_PLATE) {
    yupObject = {
      ...fieldSets[3],
    };
  }
  if (dialogType === TimelineDialogType.CHANGE_LICENSE_PLATE) {
    yupObject = {
      ...fieldSets[0],
      ...fieldSets[1],
      ...fieldSets[3],
    };
  }
  return yupObject;
};

import { ContextualMenuItemType } from "@fluentui/react";
import _ from "lodash";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../store/hooks";
import { openDialog } from "../../../store/timeline";
import {
  PremiumType,
  Timeline,
  TimelineDialogType,
  TimelineSegment,
  TimelineSegmentType,
  TimelineType,
  UserRole,
} from "../../../types/types";

export const usePremiumSegmentMenuItems = (timelineData: Timeline) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const taxonomy = useSelector((s) => s.taxonomy);
  const segmentContextMenuItem = useSelector(
    (s) => s.timeline.contextMenu.item
  );
  const segmentContextMenuDate = useSelector(
    (s) => s.timeline.contextMenu.date
  );
  const insurerList = useSelector((s) => s.timeline.timeline.insurerList);
  const userRole = useSelector((s) => s.auth.userRole);

  const segmentContextMenuItemDomainType = useSelector(
    (s) => s.timeline.contextMenu.item?.domain.type
  );
  const premiumTypeKey = segmentContextMenuItem?.domain?.value;

  const isFleetLegalPremiumType =
    premiumTypeKey === PremiumType.FLEETLEGAL_PRORATA;

  return useMemo(() => {
    const items = [];
    // Add and Insert
    if (segmentContextMenuItemDomainType !== TimelineType.VEHICLE_PREMIUM) {
      return [];
    }
    if (userRole === UserRole.external) return [];
    if (segmentContextMenuItem.segmentType === TimelineSegmentType.VOID_AFTER) {
      const isInsertEmptyOperation =
        segmentContextMenuItem.startDate === timelineData.startDate &&
        segmentContextMenuItem.endDate === timelineData.endDate;

      const mtplStartDate = moment(
        _.min(
          timelineData.data
            .filter((v: TimelineSegment) => {
              return (
                +v.domain.value ===
                taxonomy.PremiumType.byCode[PremiumType.MTPL].id
              );
            })
            .map((v: TimelineSegment) => {
              return v.startDate;
            })
        )
      ).toDate();
      let date = segmentContextMenuDate;
      if (
        mtplStartDate &&
        isInsertEmptyOperation &&
        (premiumTypeKey === PremiumType.HORSE_POWER ||
          premiumTypeKey === PremiumType.ACCIDENT ||
          premiumTypeKey === PremiumType.ASSISTANCE ||
          premiumTypeKey === PremiumType.LEGAL)
      ) {
        date = mtplStartDate;
      }

      if (premiumTypeKey !== PremiumType.HORSE_POWER) {
        items.push({
          key: "correct",
          text: t("bfm.addPremium"),
          onClick: () => {
            dispatch(
              openDialog({
                dialogType: TimelineDialogType.ADD_PREMIUM,
                contextMenuItem: segmentContextMenuItem,
                contextMenuDate: date,
                previousDialogData: null,
              })
            );
          },
        });
      }
    }
    if (
      segmentContextMenuItem?.segmentType === TimelineSegmentType.VOID_BEFORE ||
      segmentContextMenuItem?.segmentType === TimelineSegmentType.VOID_BETWEEN
    ) {
      const isInsertBeforeOperation =
        segmentContextMenuItem.startDate === timelineData.startDate;

      const mtplStartDate = moment(
        _.min(
          timelineData.data
            .filter((v: TimelineSegment) => {
              return (
                +v.domain.value ===
                taxonomy.PremiumType.byCode[PremiumType.MTPL].id
              );
            })
            .map((v: TimelineSegment) => {
              return v.startDate;
            })
        )
      ).toDate();
      let date = segmentContextMenuDate;
      if (
        mtplStartDate &&
        isInsertBeforeOperation &&
        (premiumTypeKey === PremiumType.HORSE_POWER ||
          premiumTypeKey === PremiumType.ACCIDENT ||
          premiumTypeKey === PremiumType.ASSISTANCE ||
          premiumTypeKey === PremiumType.LEGAL)
      ) {
        date = mtplStartDate;
      }
      if (premiumTypeKey !== PremiumType.HORSE_POWER) {
        items.push({
          key: "correct",
          text: t("bfm.insertPremium"),
          onClick: () => {
            dispatch(
              openDialog({
                dialogType: TimelineDialogType.INSERT_PREMIUM,
                contextMenuItem: segmentContextMenuItem,
                contextMenuDate: date,
              })
            );
          },
        });
      }
    }
    // Correct, Adjust, and Update
    if (
      (segmentContextMenuItem?.segmentType === TimelineSegmentType.PREMIUM ||
        segmentContextMenuItem?.segmentType ===
          TimelineSegmentType.SUSPENSION) &&
      segmentContextMenuItem?.extraData.isInfinitePremium
    ) {
      const insurerInternalNumber =
        segmentContextMenuItem.associatedObject?.insurerPartnerNumber;
      const insurer = insurerList.find(
        (insurer) => insurer.insurerInternalNumber === insurerInternalNumber
      );
      let branchOffices = [];
      if (insurer) {
        branchOffices = insurer.reportInsurerBranchOffices;
      }
      const branchOffice = branchOffices.find(
        (bo) =>
          bo.branchOfficeInternalNumber ===
          segmentContextMenuItem.associatedObject?.insurerBranchOffice
      );

      items.push({
        key: "correct",
        text: t("bfm.correctPremium"),
        onClick: () => {
          dispatch(
            openDialog({
              dialogType: TimelineDialogType.CORRECT_PREMIUM,
              contextMenuItem: segmentContextMenuItem,
              contextMenuDate: segmentContextMenuDate,
            })
          );
        },
      });
      items.push({
        key: "adjust",
        text: t("bfm.adjustPremium"),
        onClick: () => {
          dispatch(
            openDialog({
              dialogType: TimelineDialogType.ADJUST_PREMIUM,
              contextMenuItem: segmentContextMenuItem,
              contextMenuDate: segmentContextMenuDate,
            })
          );
        },
      });
      !isFleetLegalPremiumType &&
        items.push({
          key: "add indemity premium",
          text: t("bfm.addIndemnityPremium.label"),
          onClick: () => {
            dispatch(
              openDialog({
                dialogType: TimelineDialogType.ADD_ONETIME_PREMIUM,
                contextMenuItem: segmentContextMenuItem,
                contextMenuDate: segmentContextMenuDate,
                previousDialogData: "OneTimePremiumType.INDEMNITY",
              })
            );
          },
        });
      items.push({
        key: "add bonus/malus premium",
        text: t("bfm.addBonusMalus.label"),
        onClick: () => {
          dispatch(
            openDialog({
              dialogType: TimelineDialogType.ADD_ONETIME_PREMIUM,
              contextMenuItem: segmentContextMenuItem,
              contextMenuDate: segmentContextMenuDate,
              previousDialogData: "OneTimePremiumType.BONUS_MALUS",
            })
          );
        },
      });
    }

    if (
      (segmentContextMenuItem?.segmentType === TimelineSegmentType.PREMIUM ||
        segmentContextMenuItem?.segmentType ===
          TimelineSegmentType.SUSPENSION) &&
      !segmentContextMenuItem?.extraData.isInfinitePremium
    ) {
      const insurerInternalNumber =
        segmentContextMenuItem.associatedObject?.insurerPartnerNumber;
      const insurer = insurerList.find(
        (insurer) => insurer.insurerInternalNumber === insurerInternalNumber
      );
      let branchOffices = [];
      if (insurer) {
        branchOffices = insurer.reportInsurerBranchOffices;
      }
      const branchOffice = branchOffices.find(
        (bo) =>
          bo.branchOfficeInternalNumber ===
          segmentContextMenuItem.associatedObject?.insurerBranchOffice
      );
      items.push({
        key: "correct",
        text: t("bfm.correctPremium"),
        onClick: () => {
          dispatch(
            openDialog({
              dialogType: TimelineDialogType.CORRECT_PREMIUM,
              contextMenuItem: segmentContextMenuItem,
              contextMenuDate: segmentContextMenuDate,
            })
          );
        },
      });
      items.push({
        key: "adjust",
        text: t("bfm.adjustPremium"),
        onClick: () => {
          dispatch(
            openDialog({
              dialogType: TimelineDialogType.ADJUST_PREMIUM,
              contextMenuItem: segmentContextMenuItem,
              contextMenuDate: segmentContextMenuDate,
            })
          );
        },
      });
      !isFleetLegalPremiumType &&
        items.push({
          key: "add indemity premium",
          text: t("bfm.addIndemnityPremium.label"),
          onClick: () => {
            dispatch(
              openDialog({
                dialogType: TimelineDialogType.ADD_ONETIME_PREMIUM,
                contextMenuItem: segmentContextMenuItem,
                contextMenuDate: segmentContextMenuDate,
                previousDialogData: "OneTimePremiumType.INDEMNITY",
              })
            );
          },
        });
      items.push({
        key: "add bonus/malus premium",
        text: t("bfm.addBonusMalus.label"),
        onClick: () => {
          dispatch(
            openDialog({
              dialogType: TimelineDialogType.ADD_ONETIME_PREMIUM,
              contextMenuItem: segmentContextMenuItem,
              contextMenuDate: segmentContextMenuDate,
              previousDialogData: "OneTimePremiumType.BONUS_MALUS",
            })
          );
        },
      });
    }
    // Continue
    if (
      (segmentContextMenuItem?.segmentType === TimelineSegmentType.PREMIUM ||
        segmentContextMenuItem?.segmentType ===
          TimelineSegmentType.SUSPENSION) &&
      !segmentContextMenuItem?.extraData.isInfinitePremium
    ) {
      const listOfDates = timelineData.data
        .filter((v: TimelineSegment) => {
          const retVal =
            v.domain.value === premiumTypeKey &&
            (v.segmentType === TimelineSegmentType.PREMIUM ||
              v.segmentType === TimelineSegmentType.SUSPENSION);

          return retVal;
        })
        .map((v: TimelineSegment) => {
          return v.endDate;
        });
      const lastSegmentEndDateFromTheSameLine = moment(
        _.max(listOfDates)
      ).toDate();
      let isLastSegment = false;
      if (
        moment(lastSegmentEndDateFromTheSameLine).isSame(
          moment(segmentContextMenuItem.endDate),
          "day"
        )
      ) {
        isLastSegment = true;
      }
      if (isLastSegment && premiumTypeKey !== PremiumType.HORSE_POWER) {
        items.push({
          key: "cancel",
          text: t("bfm.continuePremium"),
          onClick: () => {
            dispatch(
              openDialog({
                dialogType: TimelineDialogType.CONTINUE_PREMIUM,
                contextMenuItem: segmentContextMenuItem,
                contextMenuDate: segmentContextMenuDate,
              })
            );
          },
        });
      }
    }
    // Cancel
    if (
      premiumTypeKey !== PremiumType.HORSE_POWER &&
      (segmentContextMenuItem?.segmentType === TimelineSegmentType.PREMIUM ||
        segmentContextMenuItem?.segmentType ===
          TimelineSegmentType.SUSPENSION) &&
      segmentContextMenuItem?.extraData.isInfinitePremium
    ) {
      items.push({
        key: "divider_1",
        itemType: ContextualMenuItemType.Divider,
      });
      items.push({
        key: "cancel",
        text: t("bfm.cancelPremium"),
        onClick: () => {
          dispatch(
            openDialog({
              dialogType: TimelineDialogType.CANCEL_PREMIUM,
              contextMenuItem: segmentContextMenuItem,
              contextMenuDate: segmentContextMenuDate,
            })
          );
        },
      });
    }
    // Delete
    if (
      segmentContextMenuItem?.segmentType === TimelineSegmentType.PREMIUM ||
      segmentContextMenuItem?.segmentType === TimelineSegmentType.SUSPENSION
    ) {
      items.push({
        key: "delete",
        text: t("bfm.deletePremium"),
        onClick: () => {
          dispatch(
            openDialog({
              dialogType: TimelineDialogType.DELETE_PREMIUM,
              contextMenuItem: segmentContextMenuItem,
              contextMenuDate: segmentContextMenuDate,
            })
          );
        },
      });
    }
    return items;
  }, [
    dispatch,
    premiumTypeKey,
    segmentContextMenuDate,
    segmentContextMenuItem,
    segmentContextMenuItemDomainType,
    t,
    userRole,
    taxonomy,
    timelineData,
    insurerList,
  ]);
};

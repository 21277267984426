import { TFunction } from "i18next";
import * as yup from "yup";

export const setYupLocale = (t: TFunction) => {
  yup.setLocale({
    mixed: {
      required: t("greco.required"),
      notType: function notType(_ref) {
        switch (_ref.type) {
          case "number":
            return t("greco.form.errors.number");
          case "string":
            return t("greco.required");
          default:
            return t("greco.error");
        }
      },
    },
    number: {
      integer: t("greco.form.errors.integer"),
      min: ({ min }) => t("greco.form.errors.number.min", { min }),
      max: ({ max }) => t("greco.form.errors.number.max", { max }),
      positive: t("greco.form.errors.positiveNumber"),
    },
    string: {
      max: ({ max }) => t("greco.form.errors.string.max", { max }),
    },
    array: {
      min: ({ min }) => t("greco.form.errors.array.min", { min }),
    },
  });
};

export const setYupLocale2 = (t: TFunction) => {
  yup.setLocale({
    mixed: {
      required: t("greco.required"),
      notType: function notType(_ref) {
        switch (_ref.type) {
          case "number":
            return t("greco.form.errors.number");
          case "string":
            return t("greco.required");
          default:
            return t("greco.error");
        }
      },
    },
    number: {
      integer: t("greco.form.errors.integer"),
      min: ({ min }) => t("greco.form.errors.number.greaterorequal", { min }),
      max: ({ max }) => t("greco.form.errors.number.lessorequal", { max }),
      positive: t("greco.form.errors.positiveNumber"),
    },
    string: {
      max: ({ max }) => t("greco.form.errors.string.max", { max }),
    },
    array: {
      min: ({ min }) => t("greco.form.errors.array.min", { min }),
    },
  });
};

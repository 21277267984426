import { mergeStyleSets } from "@fluentui/react";
import { constants } from "library/constants";
import React, { useContext } from "react";
import { useUnmount } from "react-use";
import { TimelineType } from "types/types";
import { AppContext } from "../../AppContext";
import { useCurrentRoute } from "../../config/routes";
import { useSelector } from "../../store/hooks";
import FinanceSection from "./FinanceTab/FinanceSection";
import GeneralSection from "./GeneralAndVehicleTab/GeneralSection/GeneralSection";
import VehicleInfoSection from "./GeneralAndVehicleTab/VehicleInfoSection/VehicleInfoSection";
import PremiumBooking from "./PremiumBooking/PremiumBooking";

const VehicleForm = ({
  vehicle,
  props,
  initialValues,
  vehicleFormRef,
  generalFormRef,
}) => {
  const route = useCurrentRoute();
  const defaultSettings = useSelector((s) => s.vehicle.fleetDefaultSettings);
  const appContext = useContext(AppContext);
  const tab = useSelector((s) => s.vehicle.tab);
  const vehicleAppSettings = useSelector((s) => s.vehicle.vehicleAppSettings);

  const isTradeLicensePlate =
    route === "createTradeVehicle" || vehicle?.isTradeLicensePlate;

  const isReadOnlyMode = route === constants.readOnlyVehicle;

  useUnmount(() => appContext.clearFormNotifications());

  const isBookingTab = tab === "booking-vehicle-form";

  return (
    <>
      {!isBookingTab && (
        <div id="general-vehicle-form" ref={generalFormRef}>
          <GeneralSection
            formikProps={props}
            defaultSettings={defaultSettings}
            vehicle={vehicle}
            initialValues={initialValues}
            route={route}
          />
        </div>
      )}
      {!isBookingTab && !isTradeLicensePlate && (
        <div id="vehicle-form" ref={vehicleFormRef}>
          <VehicleInfoSection
            defaultSettings={defaultSettings}
            formikProps={props}
            vehicle={vehicle}
            initialValues={initialValues}
            route={route}
          />
        </div>
      )}
      {!isBookingTab && (
        <div id="finance-vehicle-form">
          <FinanceSection
            formikProps={props}
            defaultSettings={defaultSettings}
            vehicle={vehicle}
            initialValues={initialValues}
            route={route}
          />
        </div>
      )}
      {(isBookingTab || isReadOnlyMode) &&
        vehicleAppSettings?.VEHICLE_TIMELINE_TYPES.includes(
          TimelineType.VEHICLE_PREMIUM_BOOKINGS
        ) && (
          <div
            id="booking-vehicle-form"
            className={vehicleFormClasses.bookingContainer}
          >
            <PremiumBooking />
          </div>
        )}
    </>
  );
};

export const vehicleFormClasses = mergeStyleSets({
  container: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px",
  },
  item: {
    padding: "20px",
    width: "49%",
    "@media(min-width: 1500px)": {
      width: "33%",
    },
  },
  commentItem: {
    padding: "10px",
    margin: "0 auto",
    width: "98%",
  },
  commentItemGrid: {
    padding: "20px",
    margin: "0 auto",
    width: "49%",
  },
  fullWidthFormItem: {
    width: "100%",
    padding: "20px",
  },
  bookingContainer: {
    width: "100%",
  },
});

export default VehicleForm;

import { useMutation } from "react-query";
import { VehicleStatusRequestParams, insertVehicleStatus } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const insertVehicleStatusFn = async ({
  rowVersion,
  params,
}: {
  rowVersion: string;
  params: VehicleStatusRequestParams;
}) => {
  return await insertVehicleStatus(rowVersion, params);
};

export function useInsertVehicleStatus() {
  return useMutation(insertVehicleStatusFn, {
    mutationKey: MUTATION_KEYS.insertVehicleStatus,
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import { Form, Formik } from "formik";

import { useCompareInsurerBookings } from "hooks/data/mutations/useCompareInsurerBookings";
import Dropzone from "pages/fleet-form/Dropzone/Dropzone";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { loadVehiclesActions } from "store/fleetPage";
import { useSelector } from "store/hooks";
import { setYupLocale } from "utils/setYupLocale";
import * as yup from "yup";
import { FormSpinner } from "./FormSpinner";
import CheckboxField from "./controls/CheckboxField";
import ComboBoxField from "./controls/ComboBoxField";
import { StyledPrimaryButton } from "./styled";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const bookingCompareInsurers = [
  109985, 109923, 109925, 109931, 109933, 109926, 109956, 109959, 109964,
  109968, 109958,
];

const donauVersicherungAGViennaInsuranceGroupInternalNumber = 109923;

export const BookingComparisonDialog = ({ isOpen, onClose }: Props) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const { fleetId } = useParams() as any;

  const insurerList = useSelector((s) => s.fleetForm.insurerList);

  const handleSetFile = useCallback((value) => {
    setFile(value);
  }, []);

  const {
    mutate: onCompareInsurerBookings,
    isLoading,
    data: excelReportResponse,
  } = useCompareInsurerBookings();

  const excelReportData = excelReportResponse?.data;

  const initialValues = useMemo(() => {
    return {
      validFromDate: null,
      validToDate: null,
      insurerPartnerNumber: null,
      compareCorrectionBookings: null,
    };
  }, []);

  const insurerOptions = insurerList
    .filter((item) => {
      return bookingCompareInsurers.includes(item.insurerInternalNumber);
    })
    .map((item: any) => {
      return {
        value: item.insurerInternalNumber,
        label: item.insurerName,
      };
    });

  const handleSubmit = async (data) => {
    const compareCorrectionBookings = data?.compareCorrectionBookings;
    const payload = {
      BookingFiles: data?.file,
      ...(data.insurerPartnerNumber?.value ===
        donauVersicherungAGViennaInsuranceGroupInternalNumber && {
        compareCorrectionBookings,
      }),
    };

    onCompareInsurerBookings(
      {
        fleetId,
        insurerPartnerNumber: data?.insurerPartnerNumber?.value,
        body: payload,
      },
      {
        onSuccess: (res) => {
          dispatch(
            loadVehiclesActions.trigger({
              isReset: true,
              shouldDelay: false,
            })
          );
        },
      }
    );
  };

  const schema = useMemo(() => {
    setYupLocale(t);
    return yup.object({
      insurerPartnerNumber: yup.mixed().required(),
      file: yup.mixed().required(),
      compareCorrectionBookings: yup.mixed().nullable(),
    });
  }, [t]);

  return (
    <Dialog
      hidden={!isOpen}
      onDismiss={onClose}
      dialogContentProps={{
        type: DialogType.normal,
        title: t("bfm.bookingComparison.label"),

        showCloseButton: true,
      }}
      minWidth={600}
      modalProps={{
        isBlocking: true,
      }}
    >
      {excelReportData ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <PrimaryButton
            title={excelReportData.name}
            onClick={() => {
              window.open(excelReportData.url);
              onClose();
            }}
            text={t("bfm.downloadReport.label")}
          />
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnChange={!!excelReportData}
          validateOnBlur={!!excelReportData}
          enableReinitialize
          validationSchema={schema}
        >
          {(props) => {
            const { errors, values, setFieldValue, handleSubmit } = props;
            return (
              <>
                <Form>
                  <ComboBoxField
                    id="insurerPartnerNumber"
                    disabled={false}
                    errors={errors}
                    required
                    name="insurerPartnerNumber"
                    label={"bfm.insurer.label"}
                    setFieldValue={setFieldValue}
                    options={insurerOptions || []}
                    defaultValue={values?.insurerPartnerNumber}
                    confidentiality={null}
                  />
                  {values.insurerPartnerNumber?.value ===
                    donauVersicherungAGViennaInsuranceGroupInternalNumber && (
                    <CheckboxField
                      checked={values?.compareCorrectionBookings}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      name={"compareCorrectionBookings"}
                      disabled={false}
                      label={"bfm.correctionBooking.label"}
                      isMarked={false}
                    />
                  )}
                  <Dropzone
                    file={file}
                    setFile={handleSetFile}
                    formikProps={props}
                    acceptCsvFiles
                    multiple
                  />

                  <DialogFooter>
                    <StyledPrimaryButton
                      onClick={() => {
                        handleSubmit();
                      }}
                      text={t("bfm.compareBooking.label")}
                    />
                    <DefaultButton
                      onClick={onClose}
                      text={t("bfm.close.label")}
                    />
                  </DialogFooter>
                </Form>

                {isLoading && <FormSpinner />}
              </>
            );
          }}
        </Formik>
      )}
    </Dialog>
  );
};

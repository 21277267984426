import FleetNavigation from "components/FleetNavigation";
import React from "react";
import { NavigationBar } from "../../../components/styled";

export const Navigation = () => {
  return (
    <NavigationBar>
      <FleetNavigation />
    </NavigationBar>
  );
};

import { IFieldConfig } from "../../form/types";

export const modifyPolicyStatusPointDialogConfig: IFieldConfig = {
  vehicleLastPolicyStatusDate: {
    name: "vehicleLastPolicyStatusDate",
    timeName: "vehicleLastPolicyStatusTime",
    type: "datetime",
    isRequired: true,
    d: ({ t, values, errors, setFieldValue }) => ({
      label: "bfm.statusDate.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.vehicleLastPolicyStatusDate,
      defaultTimeValue: values?.vehicleLastPolicyStatusDate,
    }),
  },
  vehiclePolicyStatusCode: {
    name: "vehiclePolicyStatusCode",
    type: "taxonomy",
    taxonomyKey: "VehiclePolicyStatusCode",
    isRequired: true,
    d: ({ t }) => ({
      label: "bfm.changePolicyStatus",
    }),
  },
  comment: {
    name: "comment",
    type: "text",
    isMultiline: true,
    rows: 6,
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.comment.label",
    }),
  },
};

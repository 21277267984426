import React from "react";

export const SvgPatternDefinitions = () => {
  return (
    <defs>
      <pattern
        id="pattern_stripes"
        patternUnits="userSpaceOnUse"
        width="10"
        height="10"
        patternTransform="rotate(45)"
      >
        <line x1="0" y="0" x2="0" y2="10" stroke="#FFFFFF" strokeWidth="2" />
      </pattern>
      <pattern
        id="pattern_dashes"
        patternUnits="userSpaceOnUse"
        width="10.5"
        height="10.5"
        patternTransform="rotate(113)"
      >
        <line
          x1="4.2"
          y="4.2"
          x2="6"
          y2="4.2"
          stroke="#FFFFFF"
          strokeWidth="2"
        />
      </pattern>
    </defs>
  );
};

import { Label, mergeStyleSets } from "@fluentui/react";
import { currencyFormat } from "components/timeline/visual-control/main-timeline/segments/OneTimePremiumSegmentTooltip";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";

const classes = mergeStyleSets({
  mt: {
    marginTop: 20,
  },
  mr: {
    marginRight: 5,
  },
  amount: {
    fontWeight: "600",
    marginLeft: 10,
    fontStyle: "italic",
  },
});

const PeriodSection = ({ vehiclePremiumBookingData }) => {
  const { t } = useTranslation();
  const totals = currencyFormat(vehiclePremiumBookingData.totalBookedAmount);
  const totalsPerCoverPeriod = currencyFormat(
    vehiclePremiumBookingData.totalBookedAmountPerCurrentPolicyCoverPeriod
  );
  const vehicleAppSettings = useSelector((s) => s.vehicle.vehicleAppSettings);

  return (
    <div className={classes.mt}>
      <div>
        <Label>
          <span>
            {t("bfm.vehicleForm.tabPremiumBooking.currentPeriod.label")}:
          </span>
          <span className={classes.amount}>
            <span className={classes.mr}>{totalsPerCoverPeriod}</span>
            {vehicleAppSettings?.CURRENCY_SYMBOL}
          </span>
        </Label>
      </div>
      <div>
        <Label>
          <span>
            {t("bfm.vehicleForm.tabPremiumBooking.totalPeriod.label")}:
          </span>
          <span className={classes.amount}>
            <span className={classes.mr}>{totals}</span>&nbsp;
            {vehicleAppSettings?.CURRENCY_SYMBOL}
          </span>
        </Label>
      </div>
    </div>
  );
};

export default PeriodSection;

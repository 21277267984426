import Tippy from "@tippyjs/react";
import { timeFormat } from "d3";
import React from "react";
import { useTranslation } from "react-i18next";
import "tippy.js/dist/tippy.css";

import moment from "moment";
import { TimelineSegmentType } from "types/types";

const tickFormat = timeFormat("%d.%m.%Y");

const PolicyStatusSegmentTypeList = {};

export const PolicyStatusSegmentTooltip = ({
  children,
  aggregatedStatusSegmentList,
  isDisplayed,
}) => {
  const typeToStatusMap = new Map();
  typeToStatusMap.set(
    TimelineSegmentType.POLICY_STATUS_PENDING,
    "VehiclePolicyStatusCode.PENDING"
  );

  typeToStatusMap.set(
    TimelineSegmentType.POLICY_STATUS_CLOSED,
    "VehiclePolicyStatusCode.CLOSED"
  );
  typeToStatusMap.set(
    TimelineSegmentType.POLICY_STATUS_WAITING_FOR_INSURER,
    "VehiclePolicyStatusCode.WAITING_FOR_INSURER"
  );
  typeToStatusMap.set(
    TimelineSegmentType.POLICY_STATUS_WAITING_FOR_CLIENT,
    "VehiclePolicyStatusCode.WAITING_FOR_CLIENT"
  );
  typeToStatusMap.set(
    TimelineSegmentType.POLICY_STATUS_WAITING_FOR_REGISTRATION,
    "VehiclePolicyStatusCode.WAITING_FOR_REGISTRATION"
  );
  typeToStatusMap.set(
    TimelineSegmentType.POLICY_STATUS_WAITING_FOR_CHECKING,
    "VehiclePolicyStatusCode.WAITING_FOR_CHECKING"
  );
  typeToStatusMap.set(
    TimelineSegmentType.POLICY_STATUS_WAITING_FOR_USER,
    "VehiclePolicyStatusCode.WAITING_FOR_USER"
  );

  const firstSegment = aggregatedStatusSegmentList[0];

  const lastSegment =
    aggregatedStatusSegmentList[aggregatedStatusSegmentList.length - 1];
  const { t } = useTranslation();
  let period = (
    <>
      {tickFormat(firstSegment.startDate)} - {tickFormat(lastSegment.endDate)}
      <br /> <hr />
    </>
  );
  let extraInfo = <></>;
  extraInfo = aggregatedStatusSegmentList.map((d, index) => {
    return (
      <span key={"ttt" + index}>
        {index !== 0 ? (
          <>
            <hr />
          </>
        ) : null}
        {t(typeToStatusMap.get(d.segmentType))}
        <br />
        {moment(d.associatedObject?.statusDate).format("DD-MM-YYYY HH:mm")}
        <br />
        {t("bfm.setByUserName.label")}:{" "}
        {d.associatedObject?.setByUserName ?? d.associatedObject?.setByUserId}
        <br />
        {t("bfm.changeReasonComment.label")}:{" "}
        {d.associatedObject?.statusComment}
        <br />
      </span>
    );
  });

  return isDisplayed ? (
    <Tippy
      delay={100}
      followCursor={true}
      disabled={false}
      content={
        <span>
          {t(typeToStatusMap.get(lastSegment.segmentType))}
          <br />
          {period}
          <hr />
          {extraInfo}
        </span>
      }
    >
      {children}
    </Tippy>
  ) : (
    <>{children}</>
  );
};

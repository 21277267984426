import Tippy from "@tippyjs/react";
import { timeFormat } from "d3";
import React from "react";
import { useTranslation } from "react-i18next";
import "tippy.js/dist/tippy.css";

const tickFormat = timeFormat("%d.%m.%Y");

export const PremiumSegmentPolicyNameTooltip = ({ children, d }) => {
  const { t } = useTranslation();
  let period = (
    <>
      {tickFormat(d.startDate)} - {tickFormat(d.endDate)}
      <br />
    </>
  );

  //}
  return (
    <Tippy
      delay={100}
      followCursor={true}
      disabled={false}
      content={
        <span>
          {period}
          <hr />
          {t("bfm.policyNumber")}: {d.policyNumber}
          <br />
          {t("bfm.isPolicyIssued")}:{" "}
          {d.isPolicyIssued ? t("greco.yes") : t("greco.no")}
          <hr />
          {t("bfm.insurer.label")}: {d.insurer} ({d.insurerInternalNumber})
          <br />
          {t("bfm.vehicleForm.insurerBranchOffice.label")}: {d.branchOffice} (
          {d.branchOfficeInternalNumber})
        </span>
      }
    >
      {children}
    </Tippy>
  );
};

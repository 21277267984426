import { ContextualMenu, IContextualMenuItem } from "@fluentui/react";
import React from "react";
import useChangeIssuingStatusMenuItem from "./hooks/useContextualMenuItems";

type Props = {
  selectedBookings: any;
  target: any;
  onDismiss: any;
  setChangeIssuingStatusOpen: any;
};
const BookingContextMenu = ({
  selectedBookings,
  target,
  onDismiss,
  setChangeIssuingStatusOpen,
}: Props) => {
  const items: IContextualMenuItem[] = useChangeIssuingStatusMenuItem({
    selectedBookings,
    setChangeIssuingStatusOpen,
  });
  return (
    <ContextualMenu
      items={items}
      target={target}
      onDismiss={onDismiss}
      gapSpace={10}
    />
  );
};

export default BookingContextMenu;

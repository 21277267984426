import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
} from "@fluentui/react";
import { formatISO } from "date-fns/esm";
import { Formik } from "formik";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { FleetPageState } from "../store/fleetPage";
import { useSelector } from "../store/hooks";

import { FormSpinner } from "./FormSpinner";
import { Field } from "./form/Field";
import { combine, required } from "./form/validation";
import { StyledPrimaryButton } from "./styled";

type Props = {
  correctStatusDateAction: any;
  isLoading: boolean;
  correctStatusDateDialog: FleetPageState["correctStatusDateDialog"];
  close: () => void;
};

export const CorrectStatusDateDialog = ({
  correctStatusDateDialog,
  correctStatusDateAction,
  isLoading,
  close,
}: Props) => {
  const { t } = useTranslation();

  const theme = useTheme() as IStyledTheme;
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const { vehicle } = correctStatusDateDialog;
  const taxonomy = useSelector((s) => s.taxonomy);
  const phraseActivated = usePhraseActivationStatus().activated;
  const vehicleStatusTaxonomyCode =
    taxonomy.VehicleStatusCode.byId[vehicle?.vehicleStatusCode]?.code;

  const onSubmit = (data) => {
    correctStatusDateAction({
      comment: data.comment,
      fleetId: vehicle.fleetId,
      vehicleId: vehicle.vehicleId,
      vehicleLastStatusDate: formatISO(data.vehicleLastStatusDate, {
        representation: "date",
      }),
    });
  };

  const title = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Icon
        iconName="EventDate"
        style={{
          color: theme.palette.themePrimary,
          fontSize: "25px",
          marginRight: "5px",
        }}
      />
      {t("bfm.vehicle.correctStatusDate")}
    </div>
  );

  const initialValues = useMemo(() => {
    let vehicleLastStatusDate = new Date();
    if (
      vehicleStatusTaxonomyCode === "VehicleStatusCode.ACTIVE" &&
      vehicle?.vehicleLastActivatedDate
    ) {
      vehicleLastStatusDate = new Date(vehicle?.vehicleLastActivatedDate);
    }
    //
    else if (
      vehicleStatusTaxonomyCode === "VehicleStatusCode.SUSPENDED" &&
      vehicle?.vehicleLastSuspendedDate
    ) {
      vehicleLastStatusDate = new Date(vehicle?.vehicleLastSuspendedDate);
    }
    //
    else if (
      vehicleStatusTaxonomyCode === "VehicleStatusCode.DEACTIVATED" &&
      vehicle?.vehicleDeactivatedDate
    ) {
      vehicleLastStatusDate = new Date(vehicle?.vehicleDeactivatedDate);
    }
    return {
      vehicleLastStatusDate,
      comment: "",
    };
  }, [vehicle]);

  return (
    <Dialog
      hidden={false}
      onDismiss={close}
      dialogContentProps={{
        type: DialogType.normal,
        title,

        showCloseButton: true,
      }}
      minWidth={400}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={didTrySubmit}
        validateOnBlur={didTrySubmit}
        enableReinitialize
      >
        {({ submitForm }) => {
          return (
            <>
              <Field
                name="vehicleLastStatusDate"
                type="date"
                label={"bfm.date.label"}
                validate={(value) => combine(required(value))}
                isRequired
                d={({ t, values, errors, setFieldValue }) => ({
                  label: "bfm.date.label",
                  values,
                  errors,
                  setFieldValue,
                  defaultValue: values?.vehicleLastStatusDate,
                })}
              />
              <Field
                name="comment"
                type="text"
                label={"bfm.comment.label"}
                isMultiline
                rows={4}
                validate={(value) => combine(required(value))}
                isRequired={false}
              />
              <DialogFooter>
                <SaveButton
                  onClick={() => {
                    setDidTrySubmit(true);
                    submitForm();
                  }}
                />
                <DefaultButton onClick={close} text={t("bfm.close.label")} />
              </DialogFooter>
              {isLoading && <FormSpinner />}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const SaveButton = ({
  onClick,
  isLoading,
  isDisabled,
}: {
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <StyledPrimaryButton
      onClick={onClick}
      text={t("bfm.saveStatusChange.label")}
      disabled={isDisabled}
      style={{
        marginRight: "5px",
      }}
      iconProps={{
        iconName: isLoading ? "ProgressRingDots" : "",
      }}
    />
  );
};

import { useEffect, useMemo } from "react";
import { usePrevious } from "react-use";

const useInsurerList = ({
  insurerList,
  values,
  name,
  subName,
  setFieldValue,
}) => {
  const insurerOptions = useMemo(() => {
    return insurerList.map((item) => ({
      value: item.insurerInternalNumber,
      label: item.insurerName,
    }));
  }, [insurerList]);

  const insurerBranchOfficeOptions = useMemo(() => {
    const selectedInsurer = values[name];
    const optionsByInsurer = insurerList.find(
      (item) => item.insurerInternalNumber === selectedInsurer?.value
    )?.reportInsurerBranchOffices;

    return optionsByInsurer?.map((item) => ({
      value: item.branchOfficeInternalNumber,
      label: item.branchOfficeName,
    }));
  }, [insurerList, name, values]);
  const previousInsurer = usePrevious(values[name]);
  useEffect(() => {
    if (previousInsurer?.value || previousInsurer?.value === null) {
      if (previousInsurer.value !== values[name]?.value) {
        setFieldValue(subName, null);
      }
    }
  }, [name, previousInsurer, setFieldValue, subName, values]);
  return {
    insurerOptions,
    insurerBranchOfficeOptions,
  };
};

export default useInsurerList;

import { IconButton, Persona, PersonaSize } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setTempUsers } from "store/fleetForm";
import { useSelector } from "store/hooks";
import styled from "styled-components";

export const Table = () => {
  const { t } = useTranslation();
  const users = useSelector((s) => s.fleetForm.tempUsers);
  const dispatch = useDispatch();

  return (
    <Root>
      <colgroup>
        <col style={{ width: 32 }} />
        <col style={{ width: "99%" }} />
        <col style={{ width: 32 }} />
      </colgroup>
      <thead>
        <tr
          style={{
            height: "32px",
          }}
        >
          <th></th>
          <th
            style={{
              textAlign: "left",
              fontSize: "14px",
              fontWeight: 600,
              padding: "5px",
            }}
          >
            {t("bfm.user")}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => {
          return (
            <tr key={user.userId}>
              <td>
                <Persona
                  text={user.userName}
                  hidePersonaDetails
                  size={PersonaSize.size32}
                />
              </td>
              <td>
                <Username>{user.userName}</Username>
                <Email>{user.userEmailAddress}</Email>
              </td>
              <td>
                <IconButton
                  iconProps={{ iconName: "Cancel" }}
                  onClick={() => {
                    dispatch(
                      setTempUsers(
                        users.filter((u) => u.userId !== user.userId)
                      )
                    );
                  }}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Root>
  );
};

const Username = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;

const Email = styled.div`
  font-size: 12px;
  color: ${(p) => p.theme.palette.neutralSecondary};
`;

export const Root = styled.table`
  border-collapse: collapse;
  border-top: 1px solid ${(p) => p.theme.palette.neutralLighterAlt};
  width: 100%;
  td {
    padding: 5px 8px;
  }
  th {
    padding: 5px;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid ${(p) => p.theme.palette.neutralLighterAlt};
    height: 42px;
    color: ${(p) => p.theme.palette.neutralPrimary};
  }
`;

import moment from "moment";
import * as yup from "yup";
import { PremiumType, TimelineDialogType } from "../../../types/types";

export const validationObjectConfig = (
  item,
  dialogType,
  premiumTypeKey,
  t
): any => {
  const fieldSets = [];
  const insurerPartnerNumber = {
    insurerPartnerNumber: yup.mixed().required(),
  };
  const insurerBranchOffice = {
    insurerBranchOffice: yup.mixed(),
  };
  const insurerPolicyNumber = {
    insurerPolicyNumber: yup.string().required(),
  };
  const premiums = {
    annualGrossPremium: yup.string().required(),
    annualNetPremium: yup.string().required(),
  };
  const validFromDate = { validFromDate: yup.date().required().nullable() };
  const validToDate = { validToDate: yup.date().required().nullable() };
  const expiryDate = { expiryDate: yup.date().required().nullable() };
  const suspLimit = {
    suspensionDaysLimit: yup.number().required().min(-1),
  };
  const payments = {
    paymentMethod: yup.mixed().required(),
    paymentMean: yup.mixed().required(),
  };

  const paymentMethod = {
    paymentMethod: yup.mixed().required(),
  };

  const actSuspDays = {
    suspensionDays: yup.string().required(),
    activationDays: yup.string().required(),
  };
  const garageRiskDiscountPercent = {
    garageRiskDiscountPercent: yup.string().required(),
  };
  const reasonForModificationComment = {
    reasonForModificationComment: yup.string().trim().max(512),
  };

  const motorTaxExclusionReason = {
    motorTaxExclusionReason: yup.mixed().nullable(),
  };

  const carUsageCode = {
    carUsageCode: yup.mixed().nullable(),
  };
  const co2Emission = {
    co2Emission: yup.number().min(-1),
  };
  const cubicCapacityCcm = {
    cubicCapacityCcm: yup.number().nullable(),
  };
  const firstRegistrationDate = {
    firstRegistrationDate: yup.date().nullable(),
  };
  const maxGrossWeightKg = {
    maxGrossWeightKg: yup.number().nullable(),
  };
  const powerKw = {
    powerKw: yup.number().nullable(),
  };
  const vehiclePowerTypeCode = {
    vehiclePowerTypeCode: yup.mixed().nullable(),
  };
  const vehicleTypeCode = {
    vehicleTypeCode: yup.mixed().nullable(),
  };

  const dueDate = {
    dueDate: yup
      .string()
      .required()
      .test("dueDate", t("bfm.fms.InvalidData.txt"), (value) => {
        const day = (value + "").substring(0, 2);
        const month = (value + "").substring(2, 4);
        return moment("2020-" + month + "-" + day).isValid();
      }),
  };

  const hullDeductiblePercent = {
    hullDeductiblePercent: yup.number().max(100),
  };

  fieldSets.push(insurerPartnerNumber); //0
  fieldSets.push(insurerBranchOffice); //1
  fieldSets.push(insurerPolicyNumber); //2
  fieldSets.push(premiums); //3
  fieldSets.push(validFromDate); //4
  fieldSets.push(item.validToDate ? validToDate : null); //5
  fieldSets.push(suspLimit); //6
  fieldSets.push(garageRiskDiscountPercent); //7
  fieldSets.push(reasonForModificationComment); //8
  fieldSets.push(motorTaxExclusionReason); //9

  fieldSets.push(carUsageCode); //10
  fieldSets.push(co2Emission); //11
  fieldSets.push(cubicCapacityCcm); //12
  fieldSets.push(firstRegistrationDate); //13
  fieldSets.push(maxGrossWeightKg); //14
  fieldSets.push(powerKw); //15
  fieldSets.push(vehiclePowerTypeCode); //16
  fieldSets.push(vehicleTypeCode); //17
  fieldSets.push(actSuspDays); //18
  fieldSets.push(payments); //19
  fieldSets.push(expiryDate); //20
  fieldSets.push(dueDate); //21
  fieldSets.push(paymentMethod); // 22

  fieldSets.push(hullDeductiblePercent); // 23
  let yupObject: any = {};
  if (dialogType === TimelineDialogType.CORRECT_PREMIUM) {
    if (premiumTypeKey === PremiumType.MTPL) {
      yupObject = {
        ...fieldSets[0],
        ...fieldSets[1],
        ...fieldSets[2],
        ...fieldSets[3],
        ...fieldSets[4],
        ...fieldSets[5],
        ...fieldSets[18],
        ...fieldSets[6],
        ...fieldSets[8],
        ...fieldSets[19],
        ...fieldSets[20],
        ...fieldSets[21],
      };
    }
    // const isComplexEnginePowerDialog =
    //   premiumTypeKey === PremiumType.HORSE_POWER &&
    //   !item.extraData.isInfinitePremium;

    if (premiumTypeKey === PremiumType.HORSE_POWER) {
      yupObject = {
        ...fieldSets[0],
        ...fieldSets[4],
        ...fieldSets[5],
        ...fieldSets[6],
        ...fieldSets[8],
        ...fieldSets[9],
        ...fieldSets[11],
        ...fieldSets[22],
      };
    }
    if (
      premiumTypeKey === PremiumType.ACCIDENT ||
      premiumTypeKey === PremiumType.ASSISTANCE ||
      premiumTypeKey === PremiumType.LEGAL
    ) {
      yupObject = {
        ...fieldSets[0],
        ...fieldSets[2],
        ...fieldSets[3],
        ...fieldSets[4],
        ...fieldSets[5],
        ...fieldSets[6],
        ...fieldSets[8],
      };
    }
    if (
      premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC
    ) {
      yupObject = {
        ...fieldSets[0],
        ...fieldSets[1],
        ...fieldSets[2],
        ...fieldSets[3],
        ...fieldSets[4],
        ...fieldSets[5],
        ...fieldSets[18],
        ...fieldSets[6],
        ...fieldSets[7],
        ...fieldSets[8],
        ...fieldSets[19],
        ...fieldSets[20],
        ...fieldSets[21],
        ...fieldSets[23],
      };
    }
  }
  if (dialogType === TimelineDialogType.ADJUST_PREMIUM) {
    if (premiumTypeKey === PremiumType.MTPL) {
      yupObject = {
        ...fieldSets[3],
        ...fieldSets[4],
        ...fieldSets[18],
        ...fieldSets[6],
        ...fieldSets[8],
        ...fieldSets[19],
        ...fieldSets[21],
      };
    }
    if (premiumTypeKey === PremiumType.HORSE_POWER) {
      yupObject = {
        ...fieldSets[4],
        ...fieldSets[6],
        ...fieldSets[8],
        ...fieldSets[9],
        ...fieldSets[22],
      };
    }
    if (
      premiumTypeKey === PremiumType.ACCIDENT ||
      premiumTypeKey === PremiumType.ASSISTANCE ||
      premiumTypeKey === PremiumType.LEGAL
    ) {
      yupObject = {
        ...fieldSets[3],
        ...fieldSets[4],
        ...fieldSets[6],
        ...fieldSets[8],
      };
    }
    if (
      premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC
    ) {
      yupObject = {
        ...fieldSets[3],
        ...fieldSets[4],
        ...fieldSets[18],
        ...fieldSets[6],
        ...fieldSets[7],
        ...fieldSets[8],
        ...fieldSets[19],
        ...fieldSets[21],
      };
    }
  }

  if (
    premiumTypeKey === PremiumType.FLEETLEGAL_PRORATA ||
    premiumTypeKey === PremiumType.FLEETLEGAL_CLOSINGDATE
  ) {
    yupObject = {
      validFromDate: yup.date().required(),
      dueDate: yup
        .string()
        .required()
        .test("dueDate", t("bfm.fms.InvalidData.txt"), (value) => {
          const day = (value + "").substring(0, 2);
          const month = (value + "").substring(2, 4);
          return moment("2020-" + month + "-" + day).isValid();
        }),
      expiryDate: yup.string().required(),

      insurerPartnerNumber: yup.mixed().required(),
      paymentMethod: yup.mixed().required(),
      paymentMean: yup.mixed().required(),

      insurerPolicyNumber: yup.string().required(),
      suspensionDays: yup.string().required(),
      suspensionDaysLimit: yup.number().required().min(-1),
      activationDays: yup.string().required(),

      annualGrossPremium: yup.string().required(),
      annualNetPremium: yup.string().required(),
    };
  }
  // if (dialogType === TimelineDialogType.Update) {
  //   if (premiumTypeKey === PremiumType.MTPL) {
  //     yupObject = {
  //       ...fieldSets[3],
  //       ...fieldSets[8],
  //     };
  //   }
  //   if (premiumTypeKey === PremiumType.HORSE_POWER) {
  //     yupObject = {
  //       ...fieldSets[8],
  //     };
  //   }
  //   if (
  //     premiumTypeKey === PremiumType.ACCIDENT ||
  //     premiumTypeKey === PremiumType.ASSISTANCE ||
  //     premiumTypeKey === PremiumType.LEGAL
  //   ) {
  //     yupObject = {
  //       ...fieldSets[3],
  //       ...fieldSets[8],
  //     };
  //   }
  //   if (
  //     premiumTypeKey === PremiumType.HULL ||
  //     premiumTypeKey === PremiumType.HULL_BASIC
  //   ) {
  //     yupObject = {
  //       ...fieldSets[3],
  //       ...fieldSets[8],
  //     };
  //   }
  // }
  if (dialogType === TimelineDialogType.CONTINUE_PREMIUM) {
    yupObject = {
      ...fieldSets[8],
    };
  }
  if (dialogType === TimelineDialogType.CANCEL_PREMIUM) {
    if (premiumTypeKey === PremiumType.MTPL) {
      yupObject = {
        ...fieldSets[4],
        ...fieldSets[8],
      };
    }
    if (premiumTypeKey === PremiumType.HORSE_POWER) {
      yupObject = {
        ...fieldSets[4],
        ...fieldSets[8],
        ...fieldSets[9],
      };
    }
    if (
      premiumTypeKey === PremiumType.ACCIDENT ||
      premiumTypeKey === PremiumType.ASSISTANCE ||
      premiumTypeKey === PremiumType.LEGAL
    ) {
      yupObject = {
        ...fieldSets[4],
        ...fieldSets[8],
      };
    }
    if (
      premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC ||
      premiumTypeKey === PremiumType.FLEETLEGAL_PRORATA ||
      premiumTypeKey === PremiumType.FLEETLEGAL_CLOSINGDATE
    ) {
      yupObject = {
        ...fieldSets[4],
        ...fieldSets[8],
      };
    }
  }
  if (dialogType === TimelineDialogType.DELETE_PREMIUM) {
    if (premiumTypeKey === PremiumType.MTPL) {
      yupObject = {
        ...fieldSets[8],
      };
    }
    if (premiumTypeKey === PremiumType.HORSE_POWER) {
      yupObject = {
        ...fieldSets[8],
      };
    }
    if (
      premiumTypeKey === PremiumType.ACCIDENT ||
      premiumTypeKey === PremiumType.ASSISTANCE ||
      premiumTypeKey === PremiumType.LEGAL
    ) {
      yupObject = {
        ...fieldSets[8],
      };
    }
    if (
      premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC ||
      premiumTypeKey === PremiumType.FLEETLEGAL_PRORATA ||
      premiumTypeKey === PremiumType.FLEETLEGAL_CLOSINGDATE
    ) {
      yupObject = {
        ...fieldSets[8],
      };
    }
  }
  return yupObject;
};

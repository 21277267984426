import { useMutation } from "react-query";
import { VehicleStatusRequestParams, deleteVehicleStatus } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const deleteVehicleStatusFn = async ({
  rowVersion,
  params,
}: {
  rowVersion: string;
  params: VehicleStatusRequestParams;
}) => {
  return await deleteVehicleStatus(rowVersion, params);
};

export function useDeleteVehicleStatus() {
  return useMutation(deleteVehicleStatusFn, {
    mutationKey: MUTATION_KEYS.deleteVehicleStatus,
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { useRifm } from "rifm";
import * as Float from "./float";
import * as Integer from "./integer";

type Props = {
  value: string | null;
  setValue: (string) => void;
  isNegativeAllowed?: boolean;
  max?: number;
  format?: "float" | "integer";
  includeThousandSeparators?: boolean;
};

export const useNumberFormat = ({
  value,
  setValue,
  isNegativeAllowed,
  max,
  format = "float",
  includeThousandSeparators,
}: Props) => {
  const accept = format === "float" ? Float.accept : Integer.accept;

  const rifm = useRifm({
    value: typeof Number(value) === "number" ? value : "",
    onChange: (value) => setValue(value),
    format: (v) =>
      format === "integer"
        ? Integer.format({
            value: v,
            isNegativeAllowed,
            max,
            includeThousandSeparators,
          })
        : Float.format({ value: v, isNegativeAllowed, max }),
    accept: accept,
  });
  return rifm;
};

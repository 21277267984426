import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../../store/hooks";

const CodeCell = ({ property, propertyCode }) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  return <span>{t(taxonomy[property].byId[propertyCode]?.code)}</span>;
};

export default CodeCell;

import React from "react";
import { NavigationBar } from "../../components/styled";

import FleetNavigation from "components/FleetNavigation";

const AnalyticsNavigation = () => {
  return (
    <NavigationBar>
      <FleetNavigation />
    </NavigationBar>
  );
};
export default AnalyticsNavigation;

import { FormSpinner } from "components/FormSpinner";

import {
  DefaultButton,
  Dialog,
  DialogFooter,
  PrimaryButton,
} from "@fluentui/react";
import { toast } from "components/FluentToast";
import { useDeleteFleetVehicleDefaultSetting } from "hooks/data/mutations/useDeleteFleetVehicleDefaultSetting";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React from "react";
import { useTranslation } from "react-i18next";
import { FleetDefaultSetting } from "types/types";

export const DeleteDialog = ({
  deleteDialog,
  close,
}: {
  deleteDialog: {
    isOpen: boolean;
    setting: FleetDefaultSetting;
  };
  close: () => void;
}) => {
  const { t } = useTranslation();
  const { mutate: onDeleteFleetVehicleDefaultSetting, isLoading } =
    useDeleteFleetVehicleDefaultSetting();
  const phraseActivated = usePhraseActivationStatus().activated;

  return (
    <Dialog
      hidden={!deleteDialog.isOpen}
      onDismiss={close}
      dialogContentProps={{
        title: t("greco.dialog.deleteTitle", {
          object: t("bfm.defaultSettings.label"),
        }),
      }}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      {t("bfm.fleet.defaultSettings.delete.message")}
      {isLoading && <FormSpinner />}
      <DialogFooter>
        <DefaultButton text={t("greco.cancel")} onClick={close} />
        <PrimaryButton
          disabled={isLoading}
          text={t("greco.delete")}
          onClick={() => {
            onDeleteFleetVehicleDefaultSetting(
              {
                fleetDefaultSettingId:
                  deleteDialog.setting.fleetVehicleDefaultSettingId,
                fleetId: deleteDialog.setting.fleetId,
              },
              {
                onSuccess: () => {
                  toast.success(t("bfm.success"));
                  close();
                },
              }
            );
          }}
        />
      </DialogFooter>
    </Dialog>
  );
};

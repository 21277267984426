import { formatISO } from "date-fns";
import { TFunction } from "i18next";
import moment from "moment";
import { TaxonomyState } from "../../../store/taxonomy";
import {
  Insurer,
  TimelineSegment,
  TimelineSegmentType,
  TimelineType,
  VehicleShortInfo,
} from "../../../types/types";

export type SignificantValues = {
  vehiclePolicyStatusId: string;
  vehiclePremiumId: string;
  vehiclePremiumVersionId: string;
  validFromDateTime: string;
};

export function findSignificantValuesOnGivenDateAndInsuranceLine(
  addNew: boolean,
  item: any,
  timelineData: TimelineSegment[],
  date: Date,
  time: Date
): SignificantValues {
  let retVal: SignificantValues = {
    vehiclePolicyStatusId: "",
    vehiclePremiumId: "",
    vehiclePremiumVersionId: "",
    validFromDateTime: "", //done
  };

  const domainValue = item.domain.value;
  let validFromDateTime = null;
  if (date) {
    //const utcTime = moment(time).utcOffset(0, true).toDate();
    validFromDateTime = moment(date)
      .set("hours", moment(time).get("hours"))
      .set("minutes", moment(time).get("minutes"))
      .set("seconds", moment(time).get("seconds"))
      .toDate();
    retVal.validFromDateTime = formatISO(validFromDateTime, {
      representation: "complete",
    });
  }
  if (addNew) {
    retVal.vehiclePolicyStatusId = "0";
  } else {
    const policyStatusSegment = timelineData.find(
      (segment) =>
        segment.domain.type === TimelineType.VEHICLE_POLICY_STATUSES &&
        segment.domain.value === domainValue &&
        segment.startDate <= validFromDateTime &&
        segment.endDate >= validFromDateTime
    );
    if (policyStatusSegment && policyStatusSegment.associatedObject) {
      retVal.vehiclePolicyStatusId =
        policyStatusSegment.associatedObject.statusId;
    }
  }

  const premiumSegment = timelineData.find(
    (segment) =>
      segment.domain.type === TimelineType.VEHICLE_PREMIUM &&
      segment.segmentType === TimelineSegmentType.PREMIUM &&
      segment.domain.value === domainValue &&
      segment.startDate <= validFromDateTime &&
      segment.endDate >= validFromDateTime
  );

  if (premiumSegment) {
    retVal.vehiclePremiumId = premiumSegment.associatedObject.vehiclePremiumId;
    retVal.vehiclePremiumVersionId =
      premiumSegment.associatedObject.vehiclePremiumVersionId;
  } else {
    return null;
  }

  return retVal;
}

export const transformForForm = ({
  obj,
  config,
  t,
  taxonomy,
  insurerList,
  possibleMainVehicles,
}: {
  obj: { [key: string]: any };
  config: any;
  t: TFunction;
  taxonomy: TaxonomyState;
  insurerList: Insurer[];
  possibleMainVehicles: VehicleShortInfo[];
}) => {
  return Object.entries(obj).reduce((result, [key, value]) => {
    const fieldConfig = config[key];
    if (!fieldConfig) {
      result[key] = value;
      return result;
    }
    if (fieldConfig.type === "date") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      result[key] = new Date(value);
    }
    if (fieldConfig.type === "datetime") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      result[key] = new Date(value);
    }
    //
    else if (fieldConfig.type === "number") {
      if (value === null || value === undefined) {
        result[key] = "";
        return result;
      }
      if (fieldConfig.format === "integer") {
        result[key] = value.toString();
      }
      //
      else {
        result[key] = value.toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    }
    //
    else if (fieldConfig.type === "taxonomy") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      //
      const taxonomyObject = taxonomy[fieldConfig.taxonomyKey]?.byId[value];
      if (!taxonomyObject) {
        result[key] = null;
        return result;
      }
      result[key] = {
        label: t(taxonomyObject.code),
        value,
      };
    } else if (fieldConfig.type === "combobox") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      result[key] = { label: value, value: value };
    } else if (fieldConfig.type === "hullproduct") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      result[key] = { label: value, value: value };
    } else if (fieldConfig.type === "hulltypecombobox") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      //
      value === true
        ? (result[key] = {
            label: t("bfm.vehicleForm.hullType.basic.label"),
            value: "basic",
          })
        : (result[key] = {
            label: t("bfm.vehicleForm.hullType.full.label"),
            value: "full",
          });
    } else if (fieldConfig.type === "grouppolicynumber") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      //
      result[key] = {
        label: value,
        value: value,
      };
    } else if (fieldConfig.type === "timelineoperationvehiclecombobox") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }

      const vehicleObject = possibleMainVehicles?.find(
        (mv) => mv.vehicleId === value
      );
      if (!vehicleObject) {
        result[key] = null;
        return result;
      }
      result[key] = {
        value: vehicleObject.vehicleId,
        label:
          (vehicleObject.brandCode
            ? t(taxonomy.VehicleBrand.byId[vehicleObject.brandCode].code)
            : "") +
          " " +
          (vehicleObject.model ? vehicleObject.model : "") +
          (vehicleObject.vin ? " (" + vehicleObject.vin + ") - " : "") +
          vehicleObject.horsePowerBasedTaxAnnualy +
          " \u20AC",
      };
    }
    //
    else if (fieldConfig.type === "text") {
      result[key] = value === null || value === undefined ? "" : value;
    }
    //
    else if (fieldConfig.type === "checkbox") {
      result[key] = value;
    } else if (fieldConfig.type === "insurerVehicleTaxonomy") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      //
      const insurer = insurerList?.find(
        (insurer) => insurer.insurerInternalNumber === value
      );
      if (!insurer) {
        result[key] = null;
        return result;
      }
      result[key] = {
        label: insurer.insurerName + " (" + insurer.insurerInternalNumber + ")",
        value: insurer.insurerInternalNumber,
      };
    } else if (fieldConfig.type === "insurerBranchOfficeTaxonomy") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      //
      let branchOffice = null;
      insurerList.forEach((insurer) => {
        const foundBranchOffice = insurer.reportInsurerBranchOffices.find(
          (branchOffice) => branchOffice.branchOfficeInternalNumber === value
        );
        if (foundBranchOffice) {
          branchOffice = foundBranchOffice;
          return;
        }
      });
      //   (branchOffice) => branchOffice.branchOfficeInternalNumber === value
      // );
      if (!branchOffice) {
        result[key] = null;
        return result;
      }
      result[key] = {
        label:
          branchOffice.branchOfficeName +
          " (" +
          branchOffice.branchOfficeInternalNumber +
          ")",
        value: branchOffice.branchOfficeInternalNumber,
      };
    } else if (fieldConfig.type === "dueDate") {
      result[key] = value;
    }
    return result;
  }, {});
};

import { useMutation } from "react-query";
import {
  ExcelReportRequestParams,
  generateFleetVehicleReport,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

export const generateFleetVehicleReportFn = async (
  params: ExcelReportRequestParams
) => {
  return await generateFleetVehicleReport(params);
};

export function useFleetVehicleReport() {
  return useMutation(generateFleetVehicleReportFn, {
    mutationKey: MUTATION_KEYS.generateFleetVehicleReport,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { call, fork, put, select, takeEvery } from "redux-saga/effects";
import { Fleet, UserRole } from "../types/types";
import * as API from "./api/api";
import { logout } from "./api/GraphService";
import { AuthState, setUserCountryCode, setUserRole } from "./auth";
import { LoadStatus, RootState } from "./store";
import { createAsyncRoutine, handleAxiosError, sort } from "./util";

type FleetsPageStateWithoutEntities = {
  search: string;
  shownFleetIds: Fleet["fleetId"][];
  sort: any;
  loadFleetsStatus: LoadStatus;
  deleteDialog: {
    fleet: Fleet | null;
    isOpen: boolean;
  };
  stopFleetRedirect: boolean;
};

export type FleetsPageState = EntityState<Fleet> &
  FleetsPageStateWithoutEntities;

export const fleetsAdapter = createEntityAdapter<Fleet>({
  selectId: (f) => f.fleetId,
});

export const loadFleetsActions = createAsyncRoutine<
  { clientId: string },
  void,
  Fleet[],
  any
>("fleetsPage/loadFleets");

const initialState: FleetsPageStateWithoutEntities = {
  search: "",
  shownFleetIds: [],
  stopFleetRedirect: false,
  sort: {
    key: "fleetName",
    dir: "asc",
  },
  loadFleetsStatus: "none",
  deleteDialog: {
    fleet: null,
    isOpen: false,
  },
};

const applyFiltersSaga = function* () {
  const search = selectSearch(yield select());
  const fleets = selectAllFleets(yield select());
  const sortState = selectSortFleetsPage(yield select());

  const ids = fleets
    .filter((f) => {
      return f.fleetName.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    })
    .sort(sort(sortState))
    .map((f) => f.fleetId);

  yield put(setShownFleetIdsFleetsPage(ids));
};

const loadFleetsTriggerSaga = function* (
  a: ReturnType<typeof loadFleetsActions.trigger>
) {
  try {
    const { clientId } = a.payload;
    yield put(loadFleetsActions.loading());
    let res: any = null;
    if (clientId) {
      res = yield call(API.getFleetsByClientId, clientId);
    } else {
      res = yield call(API.getFleets);
    }
    yield put(loadFleetsActions.success(res.data));
    let userRole: AuthState["userRole"] = UserRole.external;
    if (
      res.headers.isadminuser === "true" ||
      res.headers.isglobaladminuser === "true"
    ) {
      userRole = UserRole.admin;
    } else if (
      (res.headers.isreadonlyadminuser === "true" ||
        res.headers.isglobalreadonlyadminuser === "true") &&
      res.headers.isinternaladminuser === "true"
    ) {
      userRole = UserRole.adminreadonly_admininternal;
    } else if (
      (res.headers.isreadonlyadminuser === "true" ||
        res.headers.isglobalreadonlyadminuser === "true") &&
      res.headers.isinternaluser === "true"
    ) {
      userRole = UserRole.adminreadonly_internal;
    } else if (
      res.headers.isreadonlyadminuser === "true" ||
      res.headers.isglobalreadonlyadminuser === "true"
    ) {
      userRole = UserRole.adminreadonly;
    } else if (res.headers.isinternaladminuser === "true") {
      userRole = UserRole.admininternal;
    } else if (res.headers.isinternaluser === "true") {
      userRole = UserRole.internal;
    }
    yield put(setUserRole(userRole));
    const usercountrycode = res.headers?.usercountrycode;
    yield put(setUserCountryCode(usercountrycode ?? null));

    // AppSettings.getInstance().setUserCountryCode(usercountrycode ?? null);
  } catch (err) {
    handleAxiosError(err);
    if (err?.response?.status === 403) {
      setTimeout(() => {
        logout();
      }, 5000);
    }
    yield put(loadFleetsActions.error(err));
  }
};

export const fleetsPageSaga = function* () {
  yield fork(function* () {
    yield takeEvery(setSearchFleetsPage.type, applyFiltersSaga);
  });
  yield fork(function* () {
    yield takeEvery(setSortFleetsPage.type, applyFiltersSaga);
  });
  yield fork(function* () {
    yield takeEvery(loadFleetsActions.trigger.type, loadFleetsTriggerSaga);
  });
  yield fork(function* () {
    yield takeEvery(loadFleetsActions.success.type, applyFiltersSaga);
  });
};

export const fleetsPageSlice = createSlice({
  name: "fleetsPage",
  initialState: fleetsAdapter.getInitialState(initialState),
  reducers: {
    setSearch: (s, a: PayloadAction<FleetsPageState["search"]>) => {
      s.search = a.payload;
    },
    setSort: (s, a: PayloadAction<FleetsPageState["sort"]>) => {
      s.sort = a.payload;
    },
    setStopFleetRedirect: (
      s,
      a: PayloadAction<FleetsPageState["stopFleetRedirect"]>
    ) => {
      s.stopFleetRedirect = a.payload;
    },
    setShownFleetIds: (
      s,
      a: PayloadAction<FleetsPageState["shownFleetIds"]>
    ) => {
      s.shownFleetIds = a.payload;
    },
    resetState: (s) => {
      const exclude: (keyof FleetsPageState)[] = ["sort", "search"];
      Object.keys(s).forEach((key) => {
        if (!exclude.includes(key as any)) {
          s[key] = initialState[key];
        }
      });
      fleetsAdapter.removeAll(s);
    },
    setDeleteDialog: (s, a: PayloadAction<FleetsPageState["deleteDialog"]>) => {
      s.deleteDialog = a.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadFleetsActions.loading, (s, a) => {
      s.loadFleetsStatus = "loading";
    });
    builder.addCase(loadFleetsActions.success, (s, a) => {
      s.loadFleetsStatus = "success";
      fleetsAdapter.setAll(s, a.payload);
      s.shownFleetIds = fleetsAdapter.getSelectors().selectIds(s) as number[];
    });
    builder.addCase(loadFleetsActions.error, (s, a) => {
      s.loadFleetsStatus = "error";
    });
  },
});

export const {
  actions: {
    setSearch: setSearchFleetsPage,
    setSort: setSortFleetsPage,
    setShownFleetIds: setShownFleetIdsFleetsPage,
    setStopFleetRedirect,
    resetState,
    setDeleteDialog,
  },
  reducer: fleetsPageReducer,
} = fleetsPageSlice;

export const { selectAll: selectAllFleets } =
  fleetsAdapter.getSelectors<RootState>((s) => s.fleetsPage);

export const selectShownFleets = createSelector(
  (s) => s.fleetsPage.shownFleetIds,
  (s) => s.fleetsPage.entities,
  (ids: any, byId) => ids.map((id) => byId[id]!)
);

export const selectSearch = (s: RootState) => s.fleetsPage.search;
export const selectSortFleetsPage = (s: RootState) => s.fleetsPage.sort;

import { IFieldConfig } from "../../form/types";

export const modifyBookingDialogConfig: IFieldConfig = {
  vehicleIssuingStatusCode: {
    name: "vehicleIssuingStatusCode",
    type: "taxonomy",
    taxonomyKey: "VehicleIssuingStatus",
    isRequired: true,
    d: ({ t }) => ({
      label: "bfm.vehicleForm.vehicleIssuingStatusCode.label",
    }),
  },
  vehicleIssuingSubstatusCode: {
    name: "vehicleIssuingSubstatusCode",
    type: "taxonomy",
    taxonomyKey: "VehicleIssuingSubstatus",
    isRequired: true,
    d: ({ t, values }) => ({
      label: "bfm.vehicleForm.vehicleIssuingSubstatusCode.label",
      isVisible:
        values.vehicleIssuingStatusCode &&
        values.vehicleIssuingStatusCode.value === 2,
    }),
  },
  vehicleIssuingComment: {
    name: "vehicleIssuingComment",
    type: "text",
    isMultiline: true,
    rows: 3,
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.comment.label",
    }),
  },
};

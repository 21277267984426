import {
  IIconProps,
  IconButton,
  Spinner,
  SpinnerSize,
  mergeStyles,
} from "@fluentui/react";
import React, { useState } from "react";

type Props = {
  disabled: boolean;
  handleClick: () => void;
  iconProps: IIconProps;
  spinnerSize?: SpinnerSize;
};
function LoadingButton({
  disabled,
  handleClick,
  iconProps,
  spinnerSize = SpinnerSize.medium,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={containerClass}>
      {isLoading ? (
        <Spinner size={spinnerSize} />
      ) : (
        <IconButton
          iconProps={iconProps}
          disabled={disabled}
          onClick={async () => {
            setIsLoading(true);
            await handleClick();
            setIsLoading(false);
          }}
        />
      )}
    </div>
  );
}

export default LoadingButton;

const containerClass = mergeStyles({
  width: "30px",
});

import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IContextualMenuProps,
  IIconProps,
  mergeStyleSets,
  PrimaryButton,
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { useBoolean } from "@uifabric/react-hooks";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import styled, { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

type Props = {
  captureBookmark: () => {};
  setReportType: Function;
};

const buttonIcon: IIconProps = { iconName: "CloudImportExport" };

const AnalyticsToolbar = ({ captureBookmark, setReportType }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;

  const phraseActivated = usePhraseActivationStatus().activated;
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

  const isDarkMode = theme.isDark;

  const classes = mergeStyleSets({
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      background: isDarkMode
        ? theme.palette.blackTranslucent40
        : theme.palette.white,
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
      height: "50px",
      padding: "0 10px",
    },
    toolbarRight: {
      marginLeft: "auto",
      paddingRight: "10px",
      display: "flex",
      alignItems: "center",
    },
  });

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: "exportPdf",
        text: t("bfm.analyticsPage.export.button.pdf.label"),
        iconProps: { iconName: "PDF" },
        onClick: async () => exportAnalytics("PDF"),
      },
      {
        key: "exportPptx",
        text: t("bfm.analyticsPage.export.button.pptx.label"),
        iconProps: { iconName: "PowerPointDocument" },
        onClick: () => exportAnalytics("PPTX"),
      },
      {
        key: "exportPng",
        text: t("bfm.analyticsPage.export.button.png.label"),
        iconProps: { iconName: "PhotoCollection" },
        onClick: () => exportAnalytics("PNG"),
      },
    ],
  };

  const exportAnalytics = (extension: string) => {
    setReportType(extension);
    toggleHideDialog();
  };

  const dialogContentProps = {
    type: DialogType.normal,
    title: "Export",
    closeButtonAriaLabel: "Close",
    subText: t("bfm.analyticsPage.export.confirm.question"),
  };

  return (
    <div className={classes.container}>
      <div className={classes.toolbarRight}>
        <StyledDefaultButton
          text={t("bfm.analyticsPage.export.button.label")}
          iconProps={buttonIcon}
          primary
          split
          menuProps={menuProps}
        />
        <Dialog
          hidden={hideDialog}
          onDismiss={toggleHideDialog}
          dialogContentProps={dialogContentProps}
          modalProps={{
            isBlocking: phraseActivated ? false : true,
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                captureBookmark();
                toggleHideDialog();
              }}
              text={t("greco.yes")}
            />
            <DefaultButton onClick={toggleHideDialog} text={t("greco.no")} />
          </DialogFooter>
        </Dialog>
      </div>
    </div>
  );
};
const StyledDefaultButton = styled(DefaultButton)``;

export default AnalyticsToolbar;

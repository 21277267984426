import { Icon, IIconProps } from "@fluentui/react";
import React from "react";

type Props = {
  color: string;
  iconProps?: IIconProps;
  className?: string;
} & {
  [key: string]: any;
};
const StatusSquare = ({
  color,
  iconProps,
  className,
  ...otherProps
}: Props) => {
  return (
    <Icon
      iconName="SquareShapeSolid"
      style={{ color, fontSize: "8px" }}
      className={className}
      {...iconProps}
      {...otherProps}
    />
  );
};

export default React.memo(StatusSquare);

import { ActionButton, SearchBox, mergeStyleSets } from "@fluentui/react";
import { isFullOrInternalAdmin } from "form/util";

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ResultsScore } from "../../components/ResultsScore";
import { Links } from "../../components/navigation/Links";
import { HSpace, NavigationBar } from "../../components/styled";
import { routes } from "../../config/routes";
import { selectShownFleets, setSearchFleetsPage } from "../../store/fleetsPage";
import { useSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/store";

export const Navigation = () => {
  const { t } = useTranslation();
  const userRole = useSelector((s) => s.auth.userRole);
  const search = useSelector((s) => s.fleetsPage.search);
  const dispatch: AppDispatch = useDispatch();
  const fleets = useSelector(selectShownFleets);

  const classes = getClassNames();
  return (
    <NavigationBar>
      <Links />
      <HSpace />

      <SearchBoxContainer>
        <StyledSearchBox
          placeholder={t("bfm.searchFleets")}
          value={search}
          onChange={(_, value) => {
            dispatch(setSearchFleetsPage(value || ""));
          }}
        />
      </SearchBoxContainer>
      {isFullOrInternalAdmin(userRole) && (
        <Link
          to={routes.createFleet.getPath()}
          className={classes.addFleetLink}
        >
          <ActionButton
            className={classes.addFleetBtn}
            iconProps={{ iconName: "Add" }}
            text={t("bfm.addFleet")}
          />
        </Link>
      )}
      <span className={classes.resultScoreContainer}>
        <ResultsScore score={fleets.length} />
      </span>
    </NavigationBar>
  );
};

const getClassNames = () =>
  mergeStyleSets({
    resultScoreContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
    },
    addFleetBtn: {
      height: "100%",
      i: {
        marginRight: 0,
      },
    },
    addFleetLink: {
      height: "100%",
    },
  });

const SearchBoxContainer = styled.div`
  .ms-SearchBox {
    background-color: ${(p) => p.theme.palette.white};
    border: 1px solid ${(p) => p.theme.palette.neutralTertiaryAlt};
    border-radius: 2px;
  }
  width: 300px;
`;

const StyledSearchBox = styled(SearchBox)`
  border: 0;
`;

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import BookingField from "./BookingField";

const StatusField = ({ row }) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);

  const vehicleIssuingStatusCode =
    taxonomy.VehicleIssuingStatus.byId[row.vehicleIssuingStatusCode]?.code;
  const vehicleIssuingSubstatusCode =
    taxonomy.VehicleIssuingSubstatus.byId[row.vehicleIssuingSubstatusCode]
      ?.code;
  let vehicleIssuingStatusCodeText = t(vehicleIssuingStatusCode);
  let vehicleIssuingSubstatusCodeText = t(vehicleIssuingSubstatusCode);

  const status = `${vehicleIssuingStatusCodeText}
  ${
    vehicleIssuingSubstatusCodeText
      ? ` - ${vehicleIssuingSubstatusCodeText}`
      : ""
  }`;
  return <BookingField value={status} />;
};

export default StatusField;

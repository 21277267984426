import {
  DefaultButton,
  Dialog,
  DialogFooter,
  mergeStyleSets,
} from "@fluentui/react";
import { toast } from "components/FluentToast";
import { FormSpinner } from "components/FormSpinner";
import { useImportScanDocument } from "hooks/data/mutations/useImportScanDocument";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setImportScanDocument, setScannedFormFields } from "store/vehicle";
import { useSelector } from "../../../store/hooks";
import Dropzone from "./Dropzone";

const ImportScanDialog = () => {
  const { t } = useTranslation();

  const file = useSelector((s) => s.vehicle?.importScanDocumentDialog?.file);
  const fleetId = useSelector((s) => s.vehicle?.fleet?.fleetId);

  const {
    mutate: onImportScanDocument,
    isLoading: importScanDocumentInProgress,
  } = useImportScanDocument();

  const dispatch = useDispatch();
  const phraseActivated = usePhraseActivationStatus().activated;

  const close = () => {
    dispatch(setImportScanDocument({ isOpen: false, file: null }));
  };

  const classes = getClassNames();

  const onSubmit = () => {
    onImportScanDocument(
      {
        fleetId,
        body: {
          ...file,
        },
      },
      {
        onSuccess: (res) => {
          dispatch(setScannedFormFields(res.data));
          toast.success(t("bfm.success"));
          close();
        },
      }
    );
  };

  return (
    <Dialog
      hidden={false}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
      dialogContentProps={{
        title: t("bfm.scanDocument.label"),
        showCloseButton: true,
      }}
      minWidth={400}
      onDismiss={close}
      // isBlocking
    >
      <div className={classes.main}>
        <div className={classes.fieldItem}>
          <Dropzone />
        </div>

        <DialogFooter className={classes.footer}>
          <DefaultButton
            primary
            text={t("bfm.upload.label")}
            iconProps={{
              iconName: "Upload",
            }}
            disabled={!file || importScanDocumentInProgress}
            onClick={() => onSubmit()}
          />
        </DialogFooter>
        {importScanDocumentInProgress && <FormSpinner />}
      </div>
    </Dialog>
  );
};

const getClassNames = () =>
  mergeStyleSets({
    main: {},
    fieldItem: {
      width: "100%",
      marginTop: 20,
    },
    footer: {
      marginTop: 20,
    },
  });

export default ImportScanDialog;

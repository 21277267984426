import { routes, useCurrentRoute } from "config/routes";
import { MUTATION_KEYS } from "hooks/data/queryKeys";
import { useIsMutating } from "react-query";
import { useSelector } from "store/hooks";

function useIsUpdateVehicleStatusLoading(vehicleId: string | number) {
  const route = useCurrentRoute();

  const isRequireActionRoute = route === routes.requireActionVehicles.key;

  const selectLocation = isRequireActionRoute
    ? (s) => s.requireActionVehicles.changeVehicleStatusDialog.vehicleIds
    : (s) => s.fleetPage.changeVehicleStatusDialog.vehicleIds;

  const updateVehicleStatusStatus = !!useIsMutating({
    mutationKey: [MUTATION_KEYS.changeVehicleStatus],
  });

  const selectedIds = useSelector(selectLocation);

  const isUpdateVehicleStatusLoading =
    updateVehicleStatusStatus && selectedIds?.includes(Number(vehicleId));

  return isUpdateVehicleStatusLoading;
}

export default useIsUpdateVehicleStatusLoading;

import { useMutation } from "react-query";
import { IInsurerBooking, compareInsurerBookings } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

export const compareInsurerBookingsFn = async (params: IInsurerBooking) => {
  return await compareInsurerBookings(params);
};

export function useCompareInsurerBookings() {
  return useMutation(compareInsurerBookingsFn, {
    mutationKey: MUTATION_KEYS.compareInsurerBookings,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

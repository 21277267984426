import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { call, fork, put, select, takeEvery } from "redux-saga/effects";
import { Setting, SettingsResponse } from "../types/types";
import * as API from "./api/api";
import { RootState } from "./store";
import { loadTaxonomyUADActions } from "./taxonomy";
import { createAsyncRoutine, handleAxiosError } from "./util";

export type SettingsState = {
  isLoading: boolean;
  isLoadingDelete: boolean;
  isLoaded: boolean;
};

export const loadSettingsActions = createAsyncRoutine<
  void,
  void,
  SettingsResponse,
  any
>("settings/load");

function* loadSettingsSaga() {
  const tax = yield select(
    (s) => s.taxonomy.ApplicationCode.byCode["Application.BFM"]
  );
  try {
    yield put(loadSettingsActions.loading());
    const res = yield call(API.getAppSettings, tax.id);
    yield put(loadSettingsActions.success(res.data));
  } catch (err) {
    yield put(loadSettingsActions.error(err));
    handleAxiosError(err);
  }
}

export function* loadSettingsSuccessSaga() {
  yield takeEvery(loadSettingsActions.success.type, function* () {});
}

export function* settingsSaga() {
  yield fork(function* () {
    yield takeEvery(loadTaxonomyUADActions.success.type, loadSettingsSaga);
  });
  yield fork(loadSettingsSuccessSaga);
}

export const settingsAdapter = createEntityAdapter<Setting>({
  selectId: (s) => s.userAppSettingId,
});

export const settingsSlice = createSlice({
  name: "settings",
  initialState: settingsAdapter.getInitialState({
    isLoading: false,
    isLoadingDelete: false,
    isLoaded: false,
  } as SettingsState),
  reducers: {
    setAllSettings: (s, a: PayloadAction<Setting[]>) => {
      settingsAdapter.setAll(s, a.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSettingsActions.loading, (s, a) => {
      s.isLoading = true;
    });
    builder.addCase(loadSettingsActions.success, (s, a) => {
      s.isLoading = false;
      s.isLoaded = true;
      settingsAdapter.setAll(s, a.payload);
    });
    builder.addCase(loadSettingsActions.error, (s, a) => {
      s.isLoading = false;
    });
  },
});

export const settingsReducer = settingsSlice.reducer;
export const { setAllSettings } = settingsSlice.actions;

export const {
  selectAll: selectAllSettings,
  selectEntities: selectEntitiesSettings,
  selectById: selectSettingById,
} = settingsAdapter.getSelectors<RootState>((s) => s.settings);

export const selectColumnOptionsSettings = createSelector(
  selectAllSettings,
  (s) => s.taxonomy,
  (settings: any, taxonomy: any) => {
    const ramTaxonomy = taxonomy.ApplicationCode.byCode["Application.BFM"];
    const columnOptionsSettingTaxonomy =
      taxonomy.ApplicationSetting.byCode["AppSetting.ColumnOptions"];

    if (!columnOptionsSettingTaxonomy || !ramTaxonomy) return [];
    return settings.filter((s) => {
      return (
        s.appSettingCodeId === columnOptionsSettingTaxonomy.id &&
        s.applicationCodeId === ramTaxonomy.id
      );
    });
  }
);

import { getAppSettings } from "hooks/data/queries/useGetAppSettings";
import i18n from "i18next";
import PhraseInContextEditorPostProcessor from "i18next-phrase-in-context-editor-post-processor";
import i18nextXHRBackend from "i18next-xhr-backend";
import queryString from "query-string";
import { initReactI18next } from "react-i18next";

import { getUserDetails } from "store/api/GraphService";

import { ViewOptions } from "types/types";

export const applicationCodeId = 900000000010205;
export const appSettingUserSettingsCodeId = 900000000010304;

const defaultLanguage = "en-GB";

const languageDetector = {
  type: "languageDetector" as any, // Exact type is 'backend' | 'logger' | 'languageDetector' | 'postProcessor' | 'i18nFormat' | '3rdParty'
  async: true, // flags below detection to be async
  detect: (callback: Function) =>
    getAppSettings(Number(applicationCodeId))
      .then((userSettings: any) => {
        const userSettingsForViewOptions: ViewOptions[] = userSettings.filter(
          (item: any) =>
            item.appSettingCodeId.toString() ===
            appSettingUserSettingsCodeId.toString()
        );
        if (userSettingsForViewOptions.length) {
          callback(
            userSettingsForViewOptions.find(
              (userSetting: any) => userSetting.isDefault
            )?.userAppSettingValue || defaultLanguage
          );
        } else {
          getUserDetails()
            .then((userData) =>
              callback(userData.preferredLanguage || defaultLanguage)
            )
            .catch(() => callback(defaultLanguage));
        }
      })
      .catch((e) => {
        console.log(e);
        getUserDetails()
          .then((userData) =>
            callback(userData.preferredLanguage || defaultLanguage)
          )
          .catch(() => callback(defaultLanguage));
      }),
  init: () => {},
  cacheUserLanguage: () => {},
};

export const inContextEditorPostProcessor =
  new PhraseInContextEditorPostProcessor({
    phraseEnabled: !!queryString.parse(window.location.search).phrase_context,
    // @ts-ignore
    projectId: process.env.REACT_APP_PHRASE_ID,
    useOldICE: true,
    autoLowercase: false,
  });

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(i18nextXHRBackend)
  .use(languageDetector)
  .use(inContextEditorPostProcessor)
  .init({
    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_PHRASE_BASE_URL}/{{lng}}.json`,
    },

    fallbackLng: (code) => {
      if (!code || code === "en-US") return ["en-GB", "de-AT", "hu-HU"];

      if (code === "de-DE") return ["de-AT", "en-GB"];
      if (code === "de-CH") return ["de-AT", "en-GB"];
      if (code === "de-AT") return ["de-AT", "en-GB"];
      if (code === "hu-HU") return ["hu-HU", "en-GB"];

      return ["en-GB", "de-AT", "hu-HU"];
    },
    saveMissing: true,
    missingKeyHandler: (lngs, ns, key, fallbackValue) => {
      // Check if the key is available in 'en-GB', and if so, return its value

      if (lngs[1] === "en-GB") {
        return i18n.t(key, { lng: "en-GB" });
      }

      // If not found in 'en-GB', return the fallback value
      // console.warn(`Missing translation key: ${key}`);
      return fallbackValue;
    },
    supportedLngs: [
      "en-GB",
      "de-AT",
      "bg-BG",
      "cs-CZ",
      "et-EE",
      "hr-HR",
      "hu-HU",
      "lt-LT",
      "pl-PL",
      "ro-RO",
      "ru-RU",
      "sr-Latn-RS",
      "sk-SK",
      "sl-SI",
      "tr-TR",
      "uk-UA",
    ],
    postProcess: ["phraseInContextEditor"],
  });

export default i18n;

(window as any).i18n = i18n;

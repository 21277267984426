import { CommandBarButton } from "@fluentui/react";
import { usePrevious } from "@uifabric/react-hooks";
import { isExternalOrReadonlyAdmin } from "form/util";
import { useFormikContext } from "formik";
import { MUTATION_KEYS } from "hooks/data/queryKeys";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useIsMutating } from "react-query";
import { useDispatch } from "react-redux";
import { setDidTrySubmit } from "../../../store/fleetForm";
import { useSelector } from "../../../store/hooks";

const backBtnStyle = {
  root: {
    height: "100%",
  },
};

type Props = {
  setFile: (file: File | null) => void;
};

const FleetSaveBtn = ({ setFile }: Props) => {
  const { t } = useTranslation();
  const { submitForm } = useFormikContext();
  const dispatch = useDispatch();

  const loadFleetLoadStatus = useSelector(
    (s) => s.fleetForm.loadFleetLoadStatus
  );
  const loadUsersStatus = useSelector(
    (s) => s.fleetForm.loadFleetUsersLoadStatus
  );

  const createFleetInProgress = !!useIsMutating({
    mutationKey: [MUTATION_KEYS.createFleet],
  });
  const updateFleetInProgress = !!useIsMutating({
    mutationKey: [MUTATION_KEYS.updateFleet],
  });

  const isLoading =
    loadFleetLoadStatus === "loading" ||
    updateFleetInProgress ||
    createFleetInProgress ||
    loadUsersStatus === "loading";

  const userRole = useSelector((s) => s.auth.userRole);

  const isDisabled = isLoading || isExternalOrReadonlyAdmin(userRole);

  const prevLoading = usePrevious(isLoading);
  useEffect(() => {
    if (prevLoading && !isLoading) {
      setFile(null);
    }
  }, [isLoading, prevLoading, setFile]);

  return (
    <CommandBarButton
      text={t("greco.save")}
      iconProps={{ iconName: "Save" }}
      onClick={() => {
        dispatch(setDidTrySubmit(true));
        submitForm();
      }}
      styles={backBtnStyle}
      disabled={isDisabled}
    />
  );
};

export default FleetSaveBtn;

import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  mergeStyleSets,
} from "@fluentui/react";
import { toast } from "components/FluentToast";
import ComboBoxField from "components/controls/ComboBoxField";
import TextInputField from "components/controls/TextInputField";
import { Formik } from "formik";
import { useUpdateBookingIssuingStatus } from "hooks/data/mutations/useUpdateBookingIssuingStatus";
import { delay } from "library/delay";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "store/hooks";
import { setVehicle } from "store/vehicle";
import { setYupLocale } from "utils/setYupLocale";
import * as yup from "yup";

const ChangeBookingIssuingStatusDialog = ({
  changeIssuingStatusOpen,
  setChangeIssuingStatusOpen,
  selectedBookings,
  selection,
}) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const rowVersion = useSelector((s) => s.vehicle.vehicle.rowVersion);

  const { mutate: onUpdateBookingIssuingStatus, isLoading } =
    useUpdateBookingIssuingStatus();

  const dispatch = useDispatch();
  const { fleetId, vehicleId } = useParams() as any;

  const vehiclePremiumBookingIds = useMemo(() => {
    return selectedBookings.map((booking) => booking?.vehiclePremiumBookingId);
  }, [selectedBookings]);

  const vehicleIssuingStatusCodeOptions = useMemo(() => {
    return taxonomy?.VehicleIssuingStatus?.items.map((item) => ({
      value: item.id,
      label: t(item.code),
      code: item.code.split(".")[1],
    }));
  }, [t, taxonomy?.VehicleIssuingStatus?.items]);

  const vehicleIssuingSubstatusCodeOptions = useMemo(() => {
    return taxonomy?.VehicleIssuingSubstatus?.items.map((item) => ({
      value: item.id,
      label: t(item.code),
      code: item.code.split(".")[1],
    }));
  }, [t, taxonomy?.VehicleIssuingSubstatus?.items]);

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    return yup.object().shape({
      vehicleIssuingStatusCode: yup.mixed().required(),
      vehicleIssuingSubstatusCode: yup
        .mixed()
        .when("vehicleIssuingStatusCode", {
          is: (s) => s && s.code === "INCORRECT",
          then: yup.mixed().required(),
          otherwise: yup.mixed().nullable(),
        }),
      vehicleIssuingComment: yup.string().max(512).nullable(),
    });
  }, [t]);
  const classes = getClassNames();

  const initialValues = {
    vehicleIssuingStatusCode: null,
    vehicleIssuingSubstatusCode: null,
    vehicleIssuingComment: null,
  };

  const onSubmit = async (values) => {
    const body = {
      vehicleIssuingStatusCode: values?.vehicleIssuingStatusCode?.value,
      vehicleIssuingSubstatusCode: values?.vehicleIssuingSubstatusCode?.value,
      vehicleIssuingComment: values?.vehicleIssuingComment,
      vehiclePremiumBookingIds,
      rowVersion,
    };

    onUpdateBookingIssuingStatus(
      {
        fleetId,
        vehicleId,
        body,
      },
      {
        onSuccess: (data) => {
          toast.success(t("bfm.statusChanged"));
          setChangeIssuingStatusOpen(false);
          dispatch(setVehicle(data));

          selection.setAllSelected(false);
        },
      }
    );
  };

  return (
    <Dialog
      hidden={!changeIssuingStatusOpen}
      onDismiss={() => {
        setChangeIssuingStatusOpen(false);
      }}
      dialogContentProps={{
        type: DialogType.normal,
        title: t("bfm.changeIssuingStatus.label"),
        showCloseButton: true,
      }}
      minWidth={400}
      modalProps={{
        isBlocking: false,
      }}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setSubmitting }) => {
          onSubmit(values);
          await delay(400);
          setSubmitting(false);
        }}
      >
        {(props) => {
          const { values, handleSubmit, setFieldValue, handleChange, errors } =
            props;
          const isIncorrectStatusSelected =
            values?.vehicleIssuingStatusCode?.code === "INCORRECT";
          return (
            <div className={classes.main}>
              <form onSubmit={handleSubmit}>
                <div className={classes.fieldItem}>
                  <ComboBoxField
                    disabled={isLoading}
                    errors={errors}
                    name="vehicleIssuingStatusCode"
                    required
                    label={t("bfm.vehicleForm.vehicleIssuingStatusCode.label")}
                    setFieldValue={setFieldValue}
                    options={vehicleIssuingStatusCodeOptions || []}
                    defaultValue={null}
                    confidentiality={null}
                  />
                </div>
                {isIncorrectStatusSelected && (
                  <div className={classes.fieldItem}>
                    <ComboBoxField
                      disabled={isLoading}
                      errors={errors}
                      required
                      name="vehicleIssuingSubstatusCode"
                      label={t(
                        "bfm.vehicleForm.vehicleIssuingSubstatusCode.label"
                      )}
                      setFieldValue={setFieldValue}
                      options={vehicleIssuingSubstatusCodeOptions || []}
                      defaultValue={null}
                      confidentiality={null}
                    />
                  </div>
                )}
                <div className={classes.fieldItem}>
                  <TextInputField
                    value={values.vehicleIssuingComment}
                    onChange={handleChange}
                    errors={errors}
                    name={"vehicleIssuingComment"}
                    multiline
                    rows={4}
                    disabled={isLoading}
                    label={t("bfm.vehicleForm.vehicleIssuingComment.label")}
                  />
                </div>
                <DialogFooter className={classes.footer}>
                  <DefaultButton
                    primary
                    text={t("greco.save")}
                    type="submit"
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </DialogFooter>
              </form>
            </div>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const getClassNames = () =>
  mergeStyleSets({
    main: {},
    fieldItem: {
      width: "100%",
      marginTop: 20,
    },
    footer: {
      marginTop: 50,
    },
  });

export default ChangeBookingIssuingStatusDialog;

import React from "react";
import BookingDetails from "./BookingDetails/BookingDetails";

export const Mtpl = () => {
  return (
    <>
      <BookingDetails premiumType="MTPL" />
    </>
  );
};

import { useMutation } from "react-query";
import {
  VehicleOneTimePremiumRequestParams,
  correctVehicleOneTimePremium,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const correctVehicleOneTimePremiumFn = async ({
  rowVersion,
  params,
}: {
  rowVersion: string;
  params: VehicleOneTimePremiumRequestParams;
}) => {
  return await correctVehicleOneTimePremium(rowVersion, params);
};

export function useCorrectVehicleOneTimePremium() {
  return useMutation(correctVehicleOneTimePremiumFn, {
    mutationKey: MUTATION_KEYS.correctVehicleOneTimePremium,
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import Axios from "axios";
import { routes } from "config/routes";
import { replace } from "connected-react-router";
import {
  call,
  cancelled,
  delay,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { ravDefaultColumns } from "pages/require-action-vehicles/RequireActionVehicleListTable/columns";
import { ExpandedColumn } from "../types/columns";
import {
  Client,
  Fleet,
  FleetWithPremiumsResponse,
  Insurer,
  Setting,
  VehicleRedux,
  VehiclesAzureResponse,
} from "../types/types";
import { hydrateColumn } from "../utils/columns";
import * as API from "./api/api";
import { logout } from "./api/GraphService";
import { setSelectedClients } from "./filter";
import {
  selectAllRavPageColumns,
  setRavPageTempColumns,
} from "./requireActionVehiclesColumnOptions";
import { LoadStatus, RootState } from "./store";
import { createAsyncRoutine, handleAxiosError } from "./util";

const initCancelVehicleStatusDialog = {
  fleetId: null,
  vehicleId: null,
  isOpen: false,
};

const initCorrectStatusDateDialog = {
  isOpen: false,
  vehicle: null,
};

const vehiclesAdapter = createEntityAdapter<VehicleRedux>({
  selectId: (v) => v.vehicleId,
});

type RequireActionVehiclesStateWithoutEntities = {
  loadVehiclesStatus: LoadStatus;
  loadFleetStatus: LoadStatus;
  loadClientsStatus: LoadStatus;
  loadInsurersStatus: LoadStatus;

  search: string;
  sort: any;
  vehicleStatusFilter: number | null;
  vehicleAttentionFilter: boolean;
  loadFleetsStatus: LoadStatus;

  tlpFilter: any | null;
  clientList: Client[];
  fleet: FleetWithPremiumsResponse | null;
  vehiclesTotalCount: number;
  insurerList: Insurer[];
  changeVehicleStatusDialog: {
    isOpen: boolean;
    fleetId: VehicleRedux["fleetId"] | null;
    vehicle?: VehicleRedux | null;
    vehicleIds?: number[] | null;
  };
  changeVehicleIssuingStatusDialog: {
    isOpen: boolean;
    fleetId: VehicleRedux["fleetId"] | null;
    vehicle?: VehicleRedux | null;
    vehicleIds?: number[] | null;
  };
  selectedViewId: Setting["userAppSettingId"] | null;

  selectedVehicleIds: Array<number> | null;

  cancelVehicleStatusDialog: {
    isOpen: boolean;
    fleetId: VehicleRedux["fleetId"] | null;
    vehicleId: VehicleRedux["vehicleId"] | null;
  };
  correctStatusDateDialog: {
    isOpen: boolean;
    vehicle: VehicleRedux | null;
  };

  isColumnOptionsPanelOpen: boolean;
  fleets: Fleet[];
};

export const loadRavFleetsActions = createAsyncRoutine<
  { clientId: string },
  void,
  Fleet[],
  any
>("rav/loadFleets");

const initialState = vehiclesAdapter.getInitialState({
  loadVehiclesStatus: "none",
  loadFleetStatus: "none",
  loadClientsStatus: "none",
  loadInsurersStatus: "none",
  loadFleetsStatus: "none",

  search: "",
  vehicleAttentionFilter: false,
  tlpFilter: null,
  fleetCountryFilter: null,

  sort: {
    key: "vehicleLastModifiedAt",
    dir: "desc",
  },
  insurerList: [],
  clientList: [],
  vehicleStatusFilter: null,
  fleet: null,
  vehiclesTotalCount: 0,
  changeVehicleStatusDialog: {
    isOpen: false,
    fleetId: null,
    vehicle: null,
    vehicleIds: [],
  },
  changeVehicleIssuingStatusDialog: {
    isOpen: false,
    fleetId: null,
    vehicle: null,
    vehicleIds: [],
  },

  selectedViewId: null,
  selectedVehicleIds: [],

  correctStatusDateDialog: initCorrectStatusDateDialog,
  cancelVehicleStatusDialog: initCancelVehicleStatusDialog,

  isColumnOptionsPanelOpen: false,
  fleets: [],
} as RequireActionVehiclesStateWithoutEntities);

export type RequireActionVehiclesState =
  RequireActionVehiclesStateWithoutEntities & EntityState<VehicleRedux>;

export const loadVehiclesActions = createAsyncRoutine<
  {
    isReset: boolean;
    top?: number;
    skip?: number;
    shouldDelay?: boolean;
    shouldEmptyVehicles?: boolean;
  },
  void,
  {
    response: VehiclesAzureResponse;
    isReset: boolean;
    isTEMP: boolean;
  },
  any
>("rav/loadVehicles");

export const loadFleetActions = createAsyncRoutine<
  Fleet["fleetId"],
  void,
  FleetWithPremiumsResponse,
  any
>("rav/loadFleet");

export const loadInsurersActions = createAsyncRoutine<
  number,
  void,
  Insurer[],
  any
>("rav/loadInsurers");

const loadRavFleetsTriggerSaga = function* (
  a: ReturnType<typeof loadRavFleetsActions.trigger>
) {
  try {
    // const { clientId } = a.payload;
    yield put(loadRavFleetsActions.loading());
    let res: any = null;
    // if (clientId) {
    //   res = yield call(API.getFleetsByClientId, clientId);
    // } else {
    res = yield call(API.getFleets);
    // }
    yield put(loadRavFleetsActions.success(res.data));
    // let userRole: AuthState["userRole"] = UserRole.external;
    // if (
    //   res.headers.isadminuser === "true" ||
    //   res.headers.isglobaladminuser === "true"
    // ) {
    //   userRole = UserRole.admin;
    // } else if (
    //   (res.headers.isreadonlyadminuser === "true" ||
    //     res.headers.isglobalreadonlyadminuser === "true") &&
    //   res.headers.isinternaladminuser === "true"
    // ) {
    //   userRole = UserRole.adminreadonly_admininternal;
    // } else if (
    //   (res.headers.isreadonlyadminuser === "true" ||
    //     res.headers.isglobalreadonlyadminuser === "true") &&
    //   res.headers.isinternaluser === "true"
    // ) {
    //   userRole = UserRole.adminreadonly_internal;
    // } else if (
    //   res.headers.isreadonlyadminuser === "true" ||
    //   res.headers.isglobalreadonlyadminuser === "true"
    // ) {
    //   userRole = UserRole.adminreadonly;
    // } else if (res.headers.isinternaladminuser === "true") {
    //   userRole = UserRole.admininternal;
    // } else if (res.headers.isinternaluser === "true") {
    //   userRole = UserRole.internal;
    // }
    // yield put(setUserRole(userRole));
    // const usercountrycode = res.headers?.usercountrycode;
    // yield put(setUserCountryCode(usercountrycode ?? null));

    // AppSettings.getInstance().setUserCountryCode(usercountrycode ?? null);
  } catch (err) {
    handleAxiosError(err);
    if (err?.response?.status === 403) {
      setTimeout(() => {
        logout();
      }, 5000);
    }
    yield put(loadRavFleetsActions.error(err));
  }
};

export const requireActionVehiclesSlice = createSlice({
  name: "rav",
  initialState,
  reducers: {
    setSearch: (s, a: PayloadAction<RequireActionVehiclesState["search"]>) => {
      s.search = a.payload;
    },

    setVehicleStatusFilter: (
      s,
      a: PayloadAction<RequireActionVehiclesState["vehicleStatusFilter"]>
    ) => {
      s.vehicleStatusFilter = a.payload;
    },
    setVehicleAttentionFilter: (
      s,
      a: PayloadAction<RequireActionVehiclesState["vehicleAttentionFilter"]>
    ) => {
      s.vehicleAttentionFilter = a.payload;
    },
    setTlpFilter: (
      s,
      a: PayloadAction<RequireActionVehiclesState["tlpFilter"]>
    ) => {
      s.tlpFilter = a.payload;
    },

    setSort: (s, a: PayloadAction<RequireActionVehiclesState["sort"]>) => {
      s.sort = a.payload;
    },

    setChangeVehicleStatusDialog: (
      s,
      a: PayloadAction<RequireActionVehiclesState["changeVehicleStatusDialog"]>
    ) => {
      s.changeVehicleStatusDialog = a.payload;
    },
    setChangeVehicleIssuingStatusDialog: (
      s,
      a: PayloadAction<
        RequireActionVehiclesState["changeVehicleIssuingStatusDialog"]
      >
    ) => {
      s.changeVehicleIssuingStatusDialog = a.payload;
    },
    setSelectedViewId: (
      s,
      a: PayloadAction<RequireActionVehiclesState["selectedViewId"]>
    ) => {
      s.selectedViewId = a.payload;
    },
    setCancelVehicleStatusDialog: (
      s,
      a: PayloadAction<RequireActionVehiclesState["cancelVehicleStatusDialog"]>
    ) => {
      s.cancelVehicleStatusDialog = a.payload;
    },
    closeCancelVehicleStatusDialog: (s) => {
      s.cancelVehicleStatusDialog = initCancelVehicleStatusDialog;
    },
    setCorrectStatusDateDialog: (
      s,
      a: PayloadAction<RequireActionVehiclesState["correctStatusDateDialog"]>
    ) => {
      s.correctStatusDateDialog = a.payload;
    },
    closeCorrectStatusDateDialog: (s) => {
      s.correctStatusDateDialog = initCorrectStatusDateDialog;
    },
    resetState: (s) => {
      const exclude: (keyof RequireActionVehiclesState)[] = [
        "sort",
        "search",
        "selectedViewId",
        "vehicleAttentionFilter",
        "tlpFilter",
      ];
      Object.keys(s).forEach((key) => {
        if (!exclude.includes(key as any)) {
          s[key] = initialState[key];
        }
      });
      vehiclesAdapter.removeAll(s);
    },
    setIsColumnOptionsPanelOpen: (
      s,
      a: PayloadAction<RequireActionVehiclesState["isColumnOptionsPanelOpen"]>
    ) => {
      s.isColumnOptionsPanelOpen = a.payload;
    },
    removeAllVehicles: vehiclesAdapter.removeAll,
    setSelectedVehiclesIds: (state, action) => {
      state.selectedVehicleIds = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadVehiclesActions.loading, (s, a) => {
      s.loadVehiclesStatus = "loading";
    });
    builder.addCase(loadVehiclesActions.success, (s, a) => {
      s.loadVehiclesStatus = "success";
      const { isReset, isTEMP, response } = a.payload;
      const mappedVehicles = response.value.map((v) => ({
        ...v,
        isTEMP,
        vehicleStatusCode:
          v.vehicleStatusCode !== null
            ? Number(v.vehicleStatusCode)
            : v.vehicleStatusCode,
      }));
      if (isReset) {
        vehiclesAdapter.setAll(s, mappedVehicles as any);
      }
      //
      else {
        vehiclesAdapter.addMany(s, mappedVehicles as any);
      }
      s.vehiclesTotalCount = response["@odata.count"];
    });
    builder.addCase(loadVehiclesActions.error, (s, a) => {
      s.loadVehiclesStatus = "error";
    });
    builder.addCase(loadFleetActions.loading, (s, a) => {
      s.loadFleetStatus = "loading";
    });
    builder.addCase(loadFleetActions.success, (s, a) => {
      s.loadFleetStatus = "success";
      s.fleet = a.payload;
    });
    builder.addCase(loadFleetActions.error, (s, a) => {
      s.loadFleetStatus = "error";
    });

    builder.addCase(loadInsurersActions.loading, (s, a) => {
      s.loadInsurersStatus = "loading";
    });
    builder.addCase(loadInsurersActions.success, (s, a) => {
      s.loadInsurersStatus = "success";
      s.insurerList = a.payload;
    });
    builder.addCase(loadInsurersActions.error, (s, a) => {
      s.loadInsurersStatus = "error";
    });
    builder.addCase(loadRavFleetsActions.loading, (s, a) => {
      s.loadFleetsStatus = "loading";
    });
    builder.addCase(loadRavFleetsActions.success, (s, a) => {
      s.loadFleetsStatus = "success";
      s.fleets = a.payload;
    });
    builder.addCase(loadRavFleetsActions.error, (s, a) => {
      s.loadFleetsStatus = "error";
    });
  },
});

export const {
  reducer: requireActionVehiclesReducer,
  actions: {
    setSearch,
    setVehicleStatusFilter,
    setVehicleAttentionFilter,
    setTlpFilter,
    setSort,
    setChangeVehicleStatusDialog,
    setChangeVehicleIssuingStatusDialog,
    setSelectedViewId: setSelectedViewIdRav,
    setCancelVehicleStatusDialog,
    removeAllVehicles,
    closeCancelVehicleStatusDialog,
    setCorrectStatusDateDialog,
    closeCorrectStatusDateDialog,
    resetState,
    setIsColumnOptionsPanelOpen,
    setSelectedVehiclesIds,
  },
} = requireActionVehiclesSlice;

export const { selectAll: selectAllVehicles, selectById: selectByIdVehicles } =
  vehiclesAdapter.getSelectors<RootState>((s) => s.requireActionVehicles);

export function* loadVehiclesSaga() {
  yield takeLatest(
    loadVehiclesActions.trigger.type,
    function* (a: ReturnType<typeof loadVehiclesActions.trigger>) {
      if (a.payload.shouldDelay !== false) {
        yield delay(300);
      }
      if (a.payload.shouldEmptyVehicles) {
        yield put(removeAllVehicles());
      }
      const cancelSource = Axios.CancelToken.source();
      const { isReset, top, skip } = a.payload;
      let actualTop = top;
      let actualSkip = skip;
      if (isReset) {
        actualTop = 45;
        actualSkip = 0;
      }
      const search = yield select((s) => s.requireActionVehicles.search);
      const sort = yield select((s) => s.requireActionVehicles.sort);
      const columns = yield select(selectAllRavPageColumns);
      const vehicleAttentionFilter = yield select(
        (s) => s.requireActionVehicles.vehicleAttentionFilter
      );
      const tlpFilter = yield select((s) => s.requireActionVehicles.tlpFilter);
      const clientFilter = yield select((s) => s.filter.selectedClients);
      const selectedCountryFilter = yield select(
        (s) => s.filter.selectedCountryFilter
      );

      try {
        yield put(loadVehiclesActions.loading());
        const res = yield call(API.searchVehicles, {
          skip: actualSkip!,
          top: actualTop!,
          isTEMP: false,
          search,
          sort,
          columns,
          clientIds: clientFilter || undefined,
          cancelToken: cancelSource.token,
          isNeedsAttention: vehicleAttentionFilter,
          isTradeLicensePlate: tlpFilter,
          countryCode:
            selectedCountryFilter === "0" ? null : selectedCountryFilter,
        });
        yield put(
          loadVehiclesActions.success({
            response: res.data,
            isReset: a.payload.isReset,
            isTEMP: false,
          })
        );
      } catch (err: any) {
        handleAxiosError(err);
        yield put(loadVehiclesActions.error(err));
      } finally {
        if (yield cancelled()) {
          cancelSource.cancel();
        }
      }
    }
  );
}

function* setSearchSaga() {
  yield takeEvery(setSearch.type, function* () {
    yield put(
      loadVehiclesActions.trigger({
        isReset: true,
      })
    );
  });
}

function* setVehicleStatusFilterSaga() {
  yield takeEvery(setVehicleStatusFilter.type, function* () {
    yield put(
      loadVehiclesActions.trigger({
        isReset: true,
      })
    );
  });
}

function* setVehicleAttentionFilterSaga() {
  yield takeEvery(setVehicleAttentionFilter.type, function* () {
    yield put(
      loadVehiclesActions.trigger({
        isReset: true,
      })
    );
  });
}

function* setTlpFilterSaga() {
  yield takeEvery(setTlpFilter.type, function* () {
    yield put(
      loadVehiclesActions.trigger({
        isReset: true,
      })
    );
  });
}

function* setSelectedClientsSaga() {
  yield takeEvery(setSelectedClients.type, function* () {
    yield put(
      loadVehiclesActions.trigger({
        isReset: true,
      })
    );
  });
}

function* setSortSaga() {
  yield takeEvery(setSort.type, function* () {
    yield put(
      loadVehiclesActions.trigger({
        isReset: true,
      })
    );
  });
}

function* loadFleetSaga() {
  yield takeEvery(
    loadFleetActions.trigger.type,
    function* (a: ReturnType<typeof loadFleetActions.trigger>) {
      const fleetId = a.payload;
      try {
        yield put(loadFleetActions.loading());
        const res = yield call(API.getFleetWithPremiums, fleetId);
        yield put(loadFleetActions.success(res.data));
      } catch (err: any) {
        yield put(loadFleetActions.error(err));
        handleAxiosError(err);
        if (err.response.status === 403) {
          yield put(replace("/"));
        }
      }
    }
  );
}

function* setSelectedViewIdFleetSaga() {
  yield takeEvery(setSelectedViewIdRav.type, function* () {
    const state = yield select();
    const columns = selectColumnsSelectedView(state);
    yield put(setRavPageTempColumns(columns));
  });
}

export function* loadInsurersSaga() {
  yield takeEvery(
    loadInsurersActions.trigger,
    function* (a: ReturnType<typeof loadInsurersActions.trigger>) {
      const countryId = a.payload;
      try {
        yield put(loadInsurersActions.loading());
        const insurers = yield call(API.getInsurersByCountryId, countryId);
        yield put(loadInsurersActions.success(insurers.data));
      } catch (err: any) {
        if (err.response.status === 404) {
          const insurers = [];
          yield put(loadInsurersActions.success(insurers));
          return;
        }
        yield put(loadInsurersActions.error(err));
        handleAxiosError(err);
        if (err.response.status === 403) {
          yield put(replace(routes.fleets.getPath()));
        }
      }
    }
  );
}

export function* requireActionVehiclesSaga() {
  yield fork(loadVehiclesSaga);
  yield fork(setSearchSaga);
  yield fork(setVehicleStatusFilterSaga);
  yield fork(setVehicleAttentionFilterSaga);
  yield fork(setTlpFilterSaga);
  yield fork(setSelectedClientsSaga);
  yield fork(setSortSaga);
  yield fork(loadFleetSaga);
  yield fork(setSelectedViewIdFleetSaga);
  yield fork(loadInsurersSaga);
  yield fork(function* () {
    yield takeEvery(
      loadRavFleetsActions.trigger.type,
      loadRavFleetsTriggerSaga
    );
  });
}

// export const selectColumnsTable = createSelector(
//   (s) => s.requireActionVehiclesColumnOptions.columns,
//   (s) => s.taxonomy,
//   (s) => s.requireActionVehicles.sort,
//   (columns, taxonomy, sort: any) => (t) => {
//     const filteredTableColumns = (columns as any)?.filter(
//       (item) => item.isVisible && item.key !== "needsGrECoUserAttention"
//     );
//     const withTranslatedHeader = filteredTableColumns?.map((column: any) => {
//       let iconName = column.iconName;
//       if (column.key === sort.key && column.isSortable) {
//         iconName =
//           sort.key === column.key
//             ? sort!.dir === "asc"
//               ? "SortUp"
//               : "SortDown"
//             : "Sort";
//       }
//       return {
//         ...column,
//         name: t(column.name),
//         iconName: iconName,
//       };
//     });
//     return withTranslatedHeader;

//     // return columns
//     // .filter((c) => c.isVisible && c.key !== "needsGrECoUserAttention")
//     // .map((c) => {
//     //   const dc = c.detailsListColumn
//     //     ? c.detailsListColumn({ t, taxonomy, width: c.width })
//     //     : null;
//     //   return {
//     //     key: c.key,
//     //     width: c.width,
//     //     label: t(c.labelKey),
//     //     isSortable: c.isSortable ?? true,
//     //     ...dc,
//     //   } as DetailsListColumn;
//     // });
//   }
// );

export const selectIsAnyFiltered = createSelector(
  (s) => s.requireActionVehicles.columns,
  (columns: any) => {
    return columns.some((c) => {
      if (c.filter) {
        return c.filter.isFiltered(c.filter as any);
      }
      return false;
    });
  }
);

export const selectSelectedView = createSelector(
  (s) => s.requireActionVehicles.selectedViewId,
  (s) => s.settings.entities,
  (id: any, entities) => {
    if (id === null) return null;
    const view = entities[id];
    if (!view) return null;
    return entities[id];
  }
);

export const selectIsViewSelected = (s: RootState) => {
  return s.requireActionVehicles.selectedViewId !== null;
};

export const selectDefaultColumns = (s: RootState) => {
  const defaultColumns =
    s.requireActionVehiclesColumnOptions.defaultColumnsCountryMap[
      s.requireActionVehiclesColumnOptions.country
    ];
  // const countryCode = appSettings?.COUNTRY_CODE.replace("Country.", "");
  if (defaultColumns === undefined) return ravDefaultColumns;
  let retVal = defaultColumns?.ids.map((c) => {
    return defaultColumns.entities[c];
  });

  return retVal;
};

export const selectColumnsSelectedView = createSelector(
  [selectSelectedView, selectDefaultColumns],
  (view, defaultColumns) => {
    if (view === null) return defaultColumns;
    const columns = JSON.parse(view.userAppSettingValue) as ExpandedColumn[];
    const mappedColumns = columns?.reduce((arr, c) => {
      const hydratedColumn = hydrateColumn({
        column: c,
        defaultColumns: defaultColumns,
      });
      if (!hydratedColumn) return arr;
      arr.push(hydratedColumn);
      return arr;
    }, []);

    return mappedColumns;
  }
);

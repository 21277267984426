import {
  ChoiceGroup,
  IChoiceGroupOption,
  mergeStyleSets,
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorMsg from "./ErrorMsg";
import { FieldContainer } from "./FieldContainer";

enum EChoiceKey {
  true = "true",
  false = "false",
  null = "null",
}

const RadioGroupField = ({
  disabled,
  label,
  name,
  setFieldValue,
  verticalView = false,
  checked,
  errors,
  ...props
}) => {
  const { t } = useTranslation();

  const options: IChoiceGroupOption[] = [
    { key: EChoiceKey.true, text: t("greco.yes") },
    { key: EChoiceKey.false, text: t("greco.no") },
    { key: EChoiceKey.null, text: t("greco.notDefined") },
  ];

  const defaultSelectedKey =
    options.find((item) => {
      if (checked === null) {
        return item.key === EChoiceKey.null;
      }
      return item.key === checked?.toString();
    })?.key || EChoiceKey.null;

  return (
    <div className={classes.wrap}>
      <FieldContainer isTooltipHidden={disabled} tooltipText={label}>
        <ChoiceGroup
          id={name + "-choicegroup"}
          defaultSelectedKey={defaultSelectedKey}
          styles={
            verticalView
              ? verticalChoiceGroupStyles
              : horizontalChoiceGroupStyles
          }
          options={options}
          onChange={(e, value) => {
            setFieldValue(name, value.key);
          }}
          label={t(label)}
          {...props}
        />
        {errors[name] && <ErrorMsg>{errors[name]}</ErrorMsg>}
      </FieldContainer>
    </div>
  );
};

export default React.memo(RadioGroupField);

const horizontalChoiceGroupStyles = {
  flexContainer: { display: "flex", flexDirection: "row", gap: 15 }, // Set the flexDirection to 'row' for horizontal layout
};
const verticalChoiceGroupStyles = {
  flexContainer: { display: "flex", flexDirection: "column" }, // Set the flexDirection to 'row' for horizontal layout
};

const classes = mergeStyleSets({
  wrap: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
});

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedBookings: null,

  withDocumentDate: true,
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setSelectedBookings: (state, action) => {
      state.selectedBookings = action.payload;
    },

    setWithDocumentDate: (state, action) => {
      state.withDocumentDate = action.payload;
    },
  },
});

export const selectSelectedBookings = (state) =>
  state.booking?.selectedBookings;

export const selectWithDocumentDate = (state) =>
  state.booking?.withDocumentDate;

export const { setSelectedBookings, setWithDocumentDate } =
  bookingSlice.actions;

export default bookingSlice;

import { IContextualMenuItem, mergeStyles } from "@fluentui/react";
import { toast } from "components/FluentToast";

import { isExternalOrReadonlyAdmin } from "form/util";
import { useTransferTempVehicle } from "hooks/data/mutations/useTransferTempVehicle";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setRemoveTEMPVehicle } from "store/fleetPage";
import { UserRole } from "types/types";
import { ListContextMenuIconButton } from "../../../components/styled";
import { routes } from "../../../config/routes";
import { useSelector } from "../../../store/hooks";
import { AppDispatch } from "../../../store/store";

type Props = {
  vehicle: any;
  fleetId: number | string;
  onClickChangeStatus: () => void;
  onClickChangeIssuingStatus: () => void;
  onClickApproveStatus: () => void;
  onClickCancelStatus: () => void;
  isUpdateVehicleStatusLoading: boolean;
  isApproveVehicleStatusLoading: boolean;
};

export const RequiredVehicleContextButton = ({
  vehicle,
  fleetId,
  onClickChangeStatus,
  onClickChangeIssuingStatus,
  onClickApproveStatus,
  onClickCancelStatus,
  isUpdateVehicleStatusLoading,
  isApproveVehicleStatusLoading,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const taxonomy = useSelector((s) => s.taxonomy);

  const { mutate: onTransferTempVehicle, isLoading } = useTransferTempVehicle();

  const userRole = useSelector((s) => s.auth.userRole);

  const isMultipleExternalSelection = userRole === UserRole.external;
  const vehicleStatusTaxCode =
    taxonomy.VehicleStatusCode.byId[vehicle.vehicleStatusCode]?.code;

  let items: IContextualMenuItem[] = [
    {
      key: "open",
      text: t("bfm.openInNewTab.label"),
      onClick: () => {
        window.open(
          routes[
            vehicle.isTEMP ? "editTempVehicle" : "readOnlyVehicle"
          ].getPath(vehicle?.fleetId, vehicle?.vehicleId)
        );
      },
      disabled: isUpdateVehicleStatusLoading,
    },
    {
      key: "edit",
      text: t("bfm.editVehicle.label"),
      onClick: () => {
        if (vehicle.isTEMP) {
          navigate(
            routes.editTempVehicle.getPath(vehicle.fleetId, vehicle.vehicleId)
          );
        }
        //
        else {
          navigate(
            routes.editVehicle.getPath(vehicle.fleetId, vehicle.vehicleId)
          );
        }
      },
      disabled:
        isExternalOrReadonlyAdmin(userRole) ||
        isUpdateVehicleStatusLoading ||
        isMultipleExternalSelection,
    },
    {
      key: "duplicate",
      text: t("bfm.duplicateVehicle.label"),
      onClick: () => {
        navigate(
          routes.duplicateVehicle.getPath(vehicle.fleetId, vehicle.vehicleId)
        );
      },
      className: vehicle.isTEMP ? displayNoneClass : "",
      disabled:
        isExternalOrReadonlyAdmin(userRole) ||
        isUpdateVehicleStatusLoading ||
        isMultipleExternalSelection,
    },
    {
      key: "changeStatus",
      text: t("bfm.changeStatus.label"),
      onClick: onClickChangeStatus,
      className: vehicle.isTEMP ? displayNoneClass : "",
      disabled:
        (userRole === UserRole.external &&
          [
            "VehicleStatusCode.PENDING_FOR_ACTIVATION",
            "VehicleStatusCode.PENDING_FOR_SUSPENSION",
            "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
          ].includes(vehicleStatusTaxCode)) ||
        userRole === UserRole.adminreadonly ||
        ["VehicleStatusCode.DEACTIVATED"].includes(vehicleStatusTaxCode) ||
        isUpdateVehicleStatusLoading,
    },

    {
      key: "transfer",
      text: t("bfm.transferVehicle.label"),
      onClick: (e) => {
        e.preventDefault();
        onTransferTempVehicle(
          {
            fleetId: vehicle.fleetId,
            vehicleId: vehicle.vehicleId,
          },
          {
            onSuccess: (res) => {
              dispatch(setRemoveTEMPVehicle(res.data));
              toast.success(t("bfm.notification.transfer.success"));
            },
          }
        );
      },
      // disabled: isMultipleAdminSelection,
      className:
        !vehicle.isTEMP || isExternalOrReadonlyAdmin(userRole)
          ? displayNoneClass
          : "",
    },

    {
      key: "approveStatus",
      text: t("bfm.approveStatus"),
      onClick: onClickApproveStatus,
      disabled: isApproveVehicleStatusLoading,
      className:
        isExternalOrReadonlyAdmin(userRole) ||
        vehicle.isTEMP ||
        ![
          "VehicleStatusCode.PENDING_FOR_ACTIVATION",
          "VehicleStatusCode.PENDING_FOR_SUSPENSION",
          "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
        ].includes(vehicleStatusTaxCode)
          ? displayNoneClass
          : "",
    },
    {
      key: "cancelStatus",
      text: t("bfm.cancelStatus"),
      onClick: onClickCancelStatus,
      disabled: isApproveVehicleStatusLoading,
      className:
        userRole === UserRole.adminreadonly ||
        vehicle.isTEMP ||
        ![
          "VehicleStatusCode.PENDING_FOR_ACTIVATION",
          "VehicleStatusCode.PENDING_FOR_SUSPENSION",
          "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
        ].includes(vehicleStatusTaxCode)
          ? displayNoneClass
          : "",
    },
  ];

  return (
    <ListContextMenuIconButton
      iconProps={{ iconName: "MoreVertical" }}
      menuIconProps={{ style: { display: "none" } }}
      menuProps={{
        items: items,
      }}
    />
  );
};

const displayNoneClass = mergeStyles({
  display: "none",
});

import { IContextualMenuItem, mergeStyles } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  CenteredCell,
  ListContextMenuIconButton,
} from "../../../components/styled";
import { routes } from "../../../config/routes";
import { setDeleteDialog } from "../../../store/fleetsPage";
import { useSelector } from "../../../store/hooks";
import { Fleet, UserRole } from "../../../types/types";

export const ContextButtonFleets = ({ fleet }: { fleet: Fleet }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userRole = useSelector((s) => s.auth.userRole);

  const items: IContextualMenuItem[] = [
    {
      key: "open",
      text: t("bfm.openInNewTab.label"),
      onClick: () => {
        window.open(routes.fleet.getPath(fleet?.fleetId));
      },
    },
    {
      key: "edit",
      text: t("bfm.editVehicle.label"),
      onClick: () => {
        navigate(routes.editFleet.getPath(fleet.fleetId));
      },
    },
    {
      key: "delete",
      text: t("bfm.deleteTempVehicle.label"),
      onClick: () => {
        dispatch(
          setDeleteDialog({
            isOpen: true,
            fleet,
          })
        );
      },
      className: userRole !== UserRole.admin ? displayNoneClass : "",
    },
  ];

  return (
    <CenteredCell>
      <ListContextMenuIconButton
        iconProps={{ iconName: "MoreVertical" }}
        menuIconProps={{ style: { display: "none" } }}
        menuProps={{
          items: items,
        }}
      />
    </CenteredCell>
  );
};

const displayNoneClass = mergeStyles({
  display: "none",
});

import { useMutation } from "react-query";
import { DeleteVehiclesParams, deleteVehicles } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

export const deleteVehiclesFn = async (params: DeleteVehiclesParams) => {
  return await deleteVehicles(params);
};

export function useDeleteMultipleVehicles() {
  return useMutation(deleteVehiclesFn, {
    mutationKey: MUTATION_KEYS.deleteMultipleVehicles,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { TooltipHost } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavSearch } from "../../../components/navigation/NavSearch";
import { useSelector } from "../../../store/hooks";
import { setSearch } from "../../../store/requireActionVehicles";
import { AppDispatch } from "../../../store/store";

export const Search = () => {
  const search = useSelector((s) => s.requireActionVehicles.search);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <TooltipHost content={t("bfm.vehicleList.searchTooltip")}>
      <NavSearch
        value={search}
        placeholder={t("bfm.vehicleList.search")}
        onChange={(value) => {
          dispatch(setSearch(value));
        }}
      />
    </TooltipHost>
  );
};

import _ from "lodash";
import moment from "moment";
import { TaxonomyState } from "../../../store/taxonomy";
import {
  Timeline,
  TimelineSegment,
  TimelineSegmentType,
  TimelineType,
  Vehicle,
} from "../../../types/types";

export const prepareLicensePlateSegments = (
  timeline: Timeline,
  vehicle: Vehicle
): TimelineSegment[] => {
  let segments: TimelineSegment[] = [];
  const vehicleLicensePlateSegments = vehicle.vehicleLicensePlateHistoryData;

  vehicleLicensePlateSegments.forEach((licensePlateSegment) => {
    // const isInfiniteLicensePlate = moment(timeline.endDate).isSame(
    //   moment(licensePlateSegment.validToDate),
    //   "day"
    // );
    segments.push({
      domain: {
        type: TimelineType.VEHICLE_LICENSE_PLATE,
        value: licensePlateSegment.licensePlate,
      },
      segmentType: TimelineSegmentType.LICENSE_PLATE,
      startDate: moment(licensePlateSegment.validFromDate).toDate(),
      endDate: moment(licensePlateSegment.validToDate).toDate(),
      extraData: {
        isInfinitePremium: licensePlateSegment.isContinuing,
        isLastSegmentInLine: false,
        vehicleLicensePlateId: licensePlateSegment.vehicleLicensePlateId,
      },
      associatedObject: licensePlateSegment,
    });

    segments.sort(sortLicensePlateSegments());

    let currentId = null;
    for (let index = 0; index < segments.length; index++) {
      const currentSegment = segments[index];
      if (
        !currentId ||
        currentId !== currentSegment.extraData.vehicleLicensePlateId
      ) {
        if (index > 0) {
          segments[index - 1].extraData.rightBorder = true;
        }
        segments[index].extraData.leftBorder = true;
        currentId = currentSegment.extraData.vehicleLicensePlateId;
      } else {
        if (index > 0) {
          segments[index - 1].extraData.rightBorder = false;
        }
        segments[index].extraData.leftBorder = false;
      }
    }
    if (segments.length > 0) {
      segments[segments.length - 1].extraData.rightBorder = true;
    }
  });

  segments.sort(sortLicensePlateSegments());

  return segments;
};

function sortLicensePlateSegments() {
  return (a, b) => {
    var aDate = a.startDate;
    var bDate = b.startDate;
    return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
  };
}

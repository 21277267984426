import { CommandBarButton } from "@fluentui/react";
import React from "react";

const saveBtnStyle = {
  root: {
    height: "100%",
  },
};

type Props = {
  disabled: boolean;
  text: string;
  iconName: string;
  onClick?: () => void;
};

const SaveButton = ({
  disabled,
  text,
  iconName,
  onClick = () => {},
}: Props) => {
  return (
    <CommandBarButton
      onClick={onClick}
      styles={saveBtnStyle}
      type="submit"
      iconProps={{ iconName }}
      text={text}
      disabled={disabled}
    />
  );
};

export default SaveButton;

import {
  ConstrainMode,
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  IDetailsColumnRenderTooltipProps,
  IDetailsHeaderProps,
  IRenderFunction,
  Modal,
  ScrollablePane,
  SelectAllVisibility,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Spinner,
  Sticky,
  StickyPositionType,
  TooltipHost,
  mergeStyleSets,
  mergeStyles,
} from "@fluentui/react";
import { useConst } from "@uifabric/react-hooks";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePrevious } from "react-use";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { messaging } from "../../../config/colors";
import { routes } from "../../../config/routes";
import { useSelector } from "../../../store/hooks";
import {
  loadVehiclesActions,
  setSelectedVehiclesIds,
  setSort,
} from "../../../store/requireActionVehicles";
import { VehicleRedux } from "../../../types/types";
import { ContextButton } from "./ContextButton";
import RequiredVehicleContextMenu from "./RequiredVehicleContextMenu/RequiredVehicleContextMenu";

type TContextualMenyProps = {
  onDismiss: Function;
  target: any;
  item: VehicleRedux;
};

const RequireActionVehicleListTable = ({ items, columns }) => {
  const theme = useTheme() as IStyledTheme;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loadVehiclesStatus = useSelector(
    (s) => s.requireActionVehicles.loadVehiclesStatus
  );
  const vehiclesTotalCount = useSelector(
    (s) => s.requireActionVehicles.vehiclesTotalCount
  );
  const sort = useSelector((s) => s.requireActionVehicles.sort);
  const taxonomy = useSelector((s) => s.taxonomy);

  const [isLoadingNewFiles, setIsLoadingNewFiles] = useState(false);
  const [contextualMenuProps, setContextualMenuProps] = useState<
    TContextualMenyProps | undefined
  >(undefined);

  const selection = useConst(
    () =>
      new Selection({
        onSelectionChanged: async () => {
          const selected = selection.getSelection();

          const selectedVehiclesIds = selected.map(
            (vehicle: any) => vehicle.vehicleId
          );

          await dispatch(setSelectedVehiclesIds(selectedVehiclesIds));
        },
      })
  );

  const isLoading = loadVehiclesStatus === "loading";

  const classes = getClassNames(theme);

  const onClickHeaderCell = (column) => {
    if (!column.isSortable) return;
    const isSorted = sort.key === column.key;
    if (isSorted) {
      if (sort.dir === "asc") {
        dispatch(
          setSort({
            key: column.key,
            dir: "desc",
          })
        );
      } else {
        if (sort.key === "vehicleLastModifiedAt" && sort.dir === "desc") {
          dispatch(
            setSort({
              key: "vehicleLastModifiedAt",
              dir: "asc",
            })
          );
        } else {
          dispatch(
            setSort({
              key: "vehicleLastModifiedAt",
              dir: "desc",
            })
          );
        }
      }
    } else {
      dispatch(
        setSort({
          key: column.key,
          dir: "asc",
        })
      );
    }
  };

  const onItemInvoked = (vehicle) => {
    const link = routes[
      vehicle.isTEMP ? "editTempVehicle" : "readOnlyVehicle"
    ].getPath(vehicle?.fleetId, vehicle?.vehicleId);
    navigate(link);
  };

  const onItemContextMenu = (
    item?: any,
    index?: number | undefined,
    ev?: Event | undefined
  ): boolean => {
    rightClickHandler(item, ev, columns[index || 0]);
    return false;
  };

  const rightClickHandler = (item: any, ev: any, column: IColumn): void => {
    setContextualMenuProps({
      target: column ? ev : { x: ev.pageX, y: ev.pageY },
      item,
      onDismiss: () => {
        setContextualMenuProps(undefined);
      },
    });
  };

  const _getKey = (item: any, index?: number): string => {
    return item && item.vehicleId;
  };

  const onRenderColumnHeaderTooltip: IRenderFunction<
    IDetailsColumnRenderTooltipProps
  > = (tooltipHostProps) => {
    return (
      <TooltipHost
        {...tooltipHostProps}
        content={tooltipHostProps?.column?.name}
      />
    );
  };

  const onRenderDetailsHeader = (
    props: IDetailsHeaderProps | undefined,
    defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
  ) => (
    <Sticky
      stickyPosition={StickyPositionType.Header}
      isScrollSynced
      stickyClassName={classes.table}
    >
      <>
        {defaultRender!({
          ...props,
          selectAllVisibility: SelectAllVisibility.hidden,
          onRenderColumnHeaderTooltip,
        })}
      </>
    </Sticky>
  );

  const fetchMoreData = async () => {
    if (isLoading || isLoadingNewFiles) return;
    setIsLoadingNewFiles(true);
    await dispatch(
      loadVehiclesActions.trigger({
        skip: items.length,
        top: 40,
        isReset: false,
      })
    );
  };

  const endReached = items.length >= vehiclesTotalCount;
  const fetchNewFiles = (ev) => {
    const element = ev.target;
    if (element && !isLoading) {
      let remaningPixels =
        element.scrollHeight - element.scrollTop - element.clientHeight <= 150;
      if (remaningPixels && !endReached) {
        fetchMoreData();
      }
    }
  };

  const prevItemsLength = usePrevious(items.length);
  useEffect(() => {
    if (prevItemsLength && prevItemsLength !== items.length) {
      setIsLoadingNewFiles(false);
    }
  }, [items]);

  const onRenderRow = (props) => {
    let customRootClass = mergeStyles({});

    if (props) {
      const row = props.item;

      if (!row) return undefined;
      const issuingStatusTaxonomyCode =
        taxonomy.VehicleIssuingStatus.byId[row.vehicleIssuingStatusCode]?.code;

      if (issuingStatusTaxonomyCode === "VehicleIssuingStatus.INCORRECT") {
        customRootClass = mergeStyles({
          // Add your custom styles here
          backgroundColor: theme.isDark
            ? theme.palette.redDark + " !important"
            : messaging.severeWarning + " !important",
          // ... other styles
        });
      }
      if (issuingStatusTaxonomyCode === "VehicleIssuingStatus.NOT_YET_BOOKED") {
        customRootClass = mergeStyles({
          // Add your custom styles here
          backgroundColor: theme.isDark
            ? theme.palette.neutralLighter + " !important"
            : theme.palette.neutralQuaternary + " !important",
          // ... other styles
        });
      }

      return <DetailsRow {...props} className={customRootClass} />;
    }
    return null;
  };

  return (
    <>
      <div className={classes.container} id="scrollableDiv">
        {contextualMenuProps && (
          <RequiredVehicleContextMenu
            vehicle={contextualMenuProps.item}
            target={contextualMenuProps.target}
            onDismiss={contextualMenuProps.onDismiss}
          />
        )}
        <ScrollablePane
          onScroll={(ev) => {
            if (!endReached && !isLoading) {
              fetchNewFiles(ev);
            }
          }}
        >
          <ShimmeredDetailsList
            items={items || []}
            compact={false}
            selection={selection}
            columns={columns || []}
            selectionMode={SelectionMode.single}
            setKey="multiple"
            getKey={_getKey}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onRenderItemColumn={(item, index, column) => {
              if (column.key === "options") {
                return (
                  <span className="action-icon">
                    <ContextButton vehicle={item} />
                  </span>
                );
              }
              return item[column.key];
            }}
            enableShimmer={isLoading && !isLoadingNewFiles}
            shimmerLines={20}
            selectionPreservedOnEmptyClick={true}
            enterModalSelectionOnTouch={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            constrainMode={ConstrainMode.unconstrained}
            className={classes.table}
            onRenderRow={onRenderRow}
            onItemInvoked={onItemInvoked}
            onItemContextMenu={onItemContextMenu}
            onColumnHeaderClick={(ev, column) => {
              onClickHeaderCell(column);
            }}
            onRenderDetailsHeader={(
              props: IDetailsHeaderProps | undefined,
              defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
            ) => onRenderDetailsHeader(props, defaultRender)}
          />
        </ScrollablePane>
      </div>
      <Modal //prevent scrolling on loading data
        styles={{
          root: {
            opacity: 0,
          },
        }}
        isOpen={isLoading}
        isBlocking={isLoading}
      ></Modal>
      {isLoading && (
        <Spinner
        // label={t('Loading More Items')}
        />
      )}
      {/* {endReached && <p>All Loaded</p>} */}
    </>
  );
};

const getClassNames = (theme) =>
  mergeStyleSets({
    container: {
      // height: "80vh",
      height: "calc(100vh - 50px - 100px)",
      marginBottom: "20px",
      position: "relative",
      overflow: "auto",
      width: "100%",
    },
    listContainer: {
      display: "flex",
    },

    fixedTable: {
      overflow: "auto",
      width: "30%",
    },
    scrollableTable: {
      overflowY: "auto",
      width: "70%",
      ".ms-DetailsRow-check": {
        display: "none",
      },
    },
    table: {
      ".ms-List-cell:nth-child(odd) .ms-DetailsRow": {
        background: theme.isDark
          ? "rgb(2, 7, 10)"
          : theme.palette.themeLighterAlt,
      },
      ".ms-DetailsRow ": {
        height: "30px !important",
      },

      ".ms-List-cell:hover, .ms-List-cell .is-selected": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(208, 231, 248) !important",
      },
      ".ms-List-cell .ms-DetailsRow:hover": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(208, 231, 248) !important",
      },

      ".ms-DetailsHeader-cellTitle": {
        justifyContent: "flex-start",
      },

      ".ms-DetailsRow-cell": {
        display: "flex",
        justifyContent: "flex-start",
      },

      ".ms-DetailsHeader-cellName i": {
        marginRight: "5px",
        fontSize: "smaller",
        fontWeight: "400",
      },
    },
  });

export default RequireActionVehicleListTable;

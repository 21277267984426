import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  resetDialogInsurerSettings,
  setDialogInsurerSettingsOperationResult,
} from "store/timeline";
import {
  ETimelineExtraDataLineSpecial,
  PremiumType,
  TimelineDialogType,
  TimelineSegment,
  TimelineSegmentType,
} from "types/types";
import { calculateAnnualNetPremium } from "utils/utils";
import { toast } from "../../../components/FluentToast";
import { useSelector } from "../../../store/hooks";

export const useInsurerSettings = () => {
  const dialogDate = useSelector((s) => s.timeline.dialog.date);
  const dialogType = useSelector((s) => s.timeline.dialog.type);
  const timelineSegments = useSelector((s) => s.timeline.timeline.data);
  const hullIsBasicItem = useSelector(
    (s) => s.timeline.dialog.item?.extraData?.lineSpecial
  );

  const { t } = useTranslation();

  const vehicleMainGroupCode = useSelector(
    (s) => s.vehicle.vehicle.vehicleMainGroupCode
  );
  const dispatch = useDispatch();

  const insurerSettings = useSelector((s) => s.vehicle.insurerSettings);

  const premiumTypeCode = useSelector(
    (s) => s.timeline.dialog.item.domain.value
  );
  const taxonomy = useSelector((s) => s.taxonomy);
  const premiumTypeId = taxonomy.PremiumType.byCode[premiumTypeCode]?.id;

  const defaultValueOperation = useSelector(
    (s) => s.timeline.dialog.insurerSettingsOperation
  );
  useEffect(() => {
    if (
      defaultValueOperation.fieldNames &&
      defaultValueOperation.formValues &&
      !defaultValueOperation.results
    ) {
      const fieldNames = defaultValueOperation.fieldNames;
      const formValues = defaultValueOperation.formValues;

      let insurer = formValues.insurerPartnerNumber?.value;

      const premiumTypeHorsePower =
        taxonomy.PremiumType.byCode["PremiumType.HORSE_POWER"].id;

      if (
        premiumTypeCode !== "PremiumType.MTPL" &&
        premiumTypeCode !== "PremiumType.HULL" &&
        premiumTypeCode !== "PremiumType.HULL_BASIC" &&
        premiumTypeCode !== PremiumType.FLEETLEGAL_PRORATA &&
        premiumTypeCode !== PremiumType.FLEETLEGAL_CLOSINGDATE &&
        premiumTypeCode !== "PremiumType.HORSE_POWER"
      ) {
        const segment: TimelineSegment = timelineSegments
          ?.filter(
            (seg) =>
              seg.domain.value === "PremiumType.MTPL" &&
              (seg.segmentType === TimelineSegmentType.PREMIUM ||
                seg.segmentType === TimelineSegmentType.SUSPENSION)
          )
          ?.find(
            (seg) =>
              moment(seg.startDate).toDate() <= dialogDate &&
              moment(seg.endDate).toDate() > dialogDate
          );
        if (segment) {
          insurer = segment.associatedObject.insurerPartnerNumber;
        }
      }

      let hullIsBasic = null;
      let premiumType = premiumTypeId;
      if (
        dialogType === TimelineDialogType.ADD_PREMIUM ||
        dialogType === TimelineDialogType.INSERT_PREMIUM
      ) {
        let hullIsBasicForm = formValues.hullIsBasic?.value;
        if (hullIsBasicForm) {
          hullIsBasic = hullIsBasicForm === "true" ? true : false;
        }
      }
      if (dialogType === TimelineDialogType.CORRECT_PREMIUM) {
        hullIsBasic =
          hullIsBasicItem &&
          hullIsBasicItem === ETimelineExtraDataLineSpecial.BASIC;
      }
      if (premiumTypeCode === "PremiumType.HULL" && hullIsBasic) {
        premiumType = taxonomy.PremiumType.byCode["PremiumType.HULL_BASIC"].id;
      }

      const insurerSettingsForInsurer = insurerSettings?.filter(
        (is) => is.insurerPartnerNumber === insurer
      );

      let insurerSetting = null;
      if (insurerSettingsForInsurer.length === 1) {
        insurerSetting = insurerSettingsForInsurer?.find((is) => {
          const a = moment(
            moment(is.validFromDate).format("YYYY-MM-DD")
          ).toDate();
          const b = moment(
            moment(formValues.validFromDate).format("YYYY-MM-DD")
          ).toDate();
          if (
            is.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails &&
            is.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails.length > 0
          ) {
            return true;
          }
          return a <= b;
        });
      } else if (insurerSettingsForInsurer.length > 1) {
        // Find latest insurer setting with valid from date before dialog date. Sording the array by validFromDate descending
        insurerSetting = insurerSettingsForInsurer
          ?.sort((a, b) => {
            return (
              moment(b.validFromDate).toDate().getTime() -
              moment(a.validFromDate).toDate().getTime()
            );
          })
          ?.find((is) => {
            const a = moment(
              moment(is.validFromDate).format("YYYY-MM-DD")
            ).toDate();
            const b = moment(
              moment(formValues.validFromDate).format("YYYY-MM-DD")
            ).toDate();
            if (
              is.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails &&
              is.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails.length >
                0
            ) {
              return true;
            }
            return a <= b;
          });
      }

      if (insurerSetting) {
        let valueString = null;

        let results = {};
        for (const fieldName of fieldNames) {
          switch (fieldName) {
            case "suspensionDays":
              valueString = insurerSetting.suspensionDays + "";
              results[fieldName] = valueString;
              //dispatch(setDialogInsurerSettingsOperationResult(valueString));
              break;
            case "activationDays":
              valueString = insurerSetting.activationDays + "";

              results[fieldName] = valueString;
              // dispatch(setDialogInsurerSettingsOperationResult(valueString));
              break;
            case "garageRiskDiscountPercent":
              if (hullIsBasic) {
                valueString = insurerSetting.garageRiskDiscountPercentHullBasic;
              } else {
                valueString = insurerSetting.garageRiskDiscountPercentHull;
              }
              valueString = valueString.toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              valueString = valueString + "";
              results[fieldName] = valueString;
              //dispatch(setDialogInsurerSettingsOperationResult(valueString));
              break;
            case "suspensionDaysLimit":
              const setting1 =
                insurerSetting?.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails?.find(
                  (el) =>
                    el.premiumTypeCode === premiumType &&
                    el.vehicleMainGroupCode === vehicleMainGroupCode
                );
              valueString = setting1?.suspensionDaysLimit + "";
              results[fieldName] = valueString;
              break;
            case "motorTaxSuspensionDaysLimit":
              const setting2 =
                insurerSetting?.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails.find(
                  (el) =>
                    el.premiumTypeCode === premiumTypeHorsePower &&
                    el.vehicleMainGroupCode === vehicleMainGroupCode
                );
              valueString = setting2?.suspensionDaysLimit + "";
              results[fieldName] = valueString;
              break;
            case "annualNetPremium":
              if (
                insurerSetting.insurerSettingPerPremiumTypeDetailsHU &&
                insurerSetting.insurerSettingPerPremiumTypeDetailsHU.length > 0
              ) {
                const setting3 =
                  insurerSetting.insurerSettingPerPremiumTypeDetailsHU.find(
                    (el) => el.premiumTypeCode === premiumType
                  );

                const annualGrossPremium = parseFloat(
                  (formValues.annualGrossPremium as string)
                    ?.replaceAll(" ", "")
                    ?.replaceAll(".", "")
                    .replaceAll(",", ".")
                );
                const maxValueForTaxCalculation =
                  setting3?.maxValueForTaxCalculation;
                const taxPercent = setting3?.taxPercent;
                const taxExtraPercent1 = setting3?.taxExtraPercent1;
                let calculatedPremium = calculateAnnualNetPremium(
                  premiumTypeCode,
                  taxPercent,
                  taxExtraPercent1,
                  annualGrossPremium,
                  maxValueForTaxCalculation
                );

                //   annualGrossPremium > maxValueForTaxCalculation
                //     ? maxValueForTaxCalculation
                //     : annualGrossPremium;
                // calculatedPremium = calculatedPremium * (1 + taxPercent / 100);
                // calculatedPremium =
                //   calculatedPremium * (1 + taxExtraPercent1 / 100);
                // calculatedPremium =
                //   calculatedPremium * (1 + taxExtraPercent2 / 100);

                const premiumString = calculatedPremium.toLocaleString(
                  "de-DE",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                );
                results["annualNetPremium"] = premiumString;
              }
              break;
          }

          //toast.success(t("bfm.fillInInsurerSettingsSuccess.label"));
        }
        // if results attribute value is "undefined" then remove it from the object
        for (const key in results) {
          if (results[key] === "undefined") {
            delete results[key];
          }
        }

        dispatch(setDialogInsurerSettingsOperationResult(results));
        !defaultValueOperation?.noMessage &&
          toast.success(t("bfm.fillInInsurerSettingsSuccess.label"));
      } else {
        !defaultValueOperation?.noMessage &&
          toast.info(t("bfm.fillInInsurerSettingsNoValue.label"));
        dispatch(resetDialogInsurerSettings(null));
      }
    }
  }, [defaultValueOperation]);
};

import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { BooleanFilter, ExpandedColumn, NumberFilter } from "../types/columns";
import { RootState } from "./store";

export type FleetPageColumnOptionsState = {
  columns: EntityState<ExpandedColumn>;
  tempColumns: EntityState<ExpandedColumn>;
  columnsCountryMap: Record<string, ExpandedColumn[]>;
  defaultColumnsCountryMap: Record<string, ExpandedColumn[]>;
  country: string;
};

const columnsAdapter = createEntityAdapter<ExpandedColumn>({
  selectId: (c) => c.key,
});

export const fleetPageColumnOptionsSlice = createSlice({
  name: "fleetPageColumnOptions",
  initialState: {
    columns: columnsAdapter.getInitialState(),
    tempColumns: columnsAdapter.getInitialState(),
    columnsCountryMap: {},
    defaultColumnsCountryMap: {},
    country: "",
  },
  reducers: {
    setColumns: (
      s,
      a: PayloadAction<{
        columns: ExpandedColumn[];
        country: string;
      }>
    ) => {
      if (!s.columnsCountryMap[a.payload.country]) {
        columnsAdapter.setAll(s.columns, a.payload.columns);
        s.columnsCountryMap[a.payload.country] = s.columns;
        s.defaultColumnsCountryMap[a.payload.country] = s.columns;
      } else {
        s.columns = s.columnsCountryMap[a.payload.country];
      }
      s.tempColumns = s.columns;
      s.country = a.payload.country;
    },
    updateColumns: (s, a: PayloadAction<ExpandedColumn[]>) => {
      columnsAdapter.setAll(s.columns, a.payload);
      s.columnsCountryMap[s.country] = s.columns;
    },
    setCountry: (s, a: PayloadAction<string>) => {
      s.country = a.payload;
      s.columns =
        s.columnsCountryMap[a.payload] || columnsAdapter.getInitialState();
    },
    setTempColumns: (s, a: PayloadAction<ExpandedColumn[]>) => {
      if (typeof a.payload !== "undefined") {
        columnsAdapter.setAll(s.tempColumns, a.payload);
      }
    },
    toggleColumnVisibility: (s, a: PayloadAction<ExpandedColumn["key"]>) => {
      const col = s.tempColumns.entities[a.payload];
      if (!col) return;
      columnsAdapter.updateOne(s.tempColumns, {
        id: a.payload,
        changes: {
          isVisible: !col.isVisible,
        },
      });
      s.columnsCountryMap[s.country] = s.columns;
    },
    showAllColumns: (s) => {
      s.tempColumns.ids.forEach((id) => {
        s.tempColumns.entities[id].isVisible = true;
      });
      s.columnsCountryMap[s.country] = s.columns;
    },
    hideAllColumns: (s) => {
      s.tempColumns.ids.forEach((id) => {
        const col = s.tempColumns.entities[id];
        if (!col.isFixed) {
          col.isVisible = false;
        }
      });
      s.columnsCountryMap[s.country] = s.columns;
    },
    reorderColumn: (
      s,
      a: PayloadAction<{ index1: number; index2: number }>
    ) => {
      const { index1, index2 } = a.payload;
      s.tempColumns.ids.splice(
        index2,
        0,
        s.tempColumns.ids.splice(index1, 1)[0]
      );
    },
    updateNumberFilter: (
      s,
      a: PayloadAction<{ key: ExpandedColumn["key"]; filter: NumberFilter }>
    ) => {
      const { key, filter } = a.payload;
      const col = s.tempColumns.entities[key];
      if (col.filter && col.filter.type === "number") {
        col.filter = filter;
      }
    },
    setFilter: (s, a: PayloadAction<{ columnKey: string; filter: any }>) => {
      s.tempColumns.entities[a.payload.columnKey].filter = a.payload.filter;
    },
    clearAllFilters: (
      s,
      a: PayloadAction<{
        key: ExpandedColumn["key"];
        value: BooleanFilter["value"];
      }>
    ) => {
      s.tempColumns.ids.forEach((id) => {
        const col = s.tempColumns.entities[id];
        const defaultCol = s.defaultColumnsCountryMap[s.country].find(
          (c) => c.key === col.key
        );
        col.filter = defaultCol?.filter;
      });
    },
  },
});

export const {
  actions: {
    setColumns: setFleetPageColumns,
    setTempColumns: setFleetPageTempColumns,
    updateColumns: updateFleetPageColumns,
    setCountry: setFleetPageColumnOptionsCountry,
    toggleColumnVisibility,
    showAllColumns,
    hideAllColumns,
    reorderColumn,
    updateNumberFilter,
    clearAllFilters,
    setFilter,
  },
  reducer: fleetPageColumnOptionsReducer,
} = fleetPageColumnOptionsSlice;

export const { selectAll: selectAllFleetPageColumns } =
  columnsAdapter.getSelectors<RootState>(
    (s) => s.fleetPageColumnOptions.columns
  );

export const { selectAll: selectAllFleetPageTempColumns } =
  columnsAdapter.getSelectors<RootState>(
    (s) => s.fleetPageColumnOptions.tempColumns
  );

const localSelectors = columnsAdapter.getSelectors();

export const selectAreAllVisible = createSelector(
  (s) => localSelectors.selectAll(s.fleetPageColumnOptions.columns),
  (columns: any) => {
    return !columns.some((c) => !c.isVisible);
  }
);

export const selectAreColumnsDirty = createSelector(
  (s) => localSelectors.selectAll(s.fleetPageColumnOptions.columns),
  (localColumns) => (reduxColumns) => {
    return JSON.stringify(localColumns) !== JSON.stringify(reduxColumns);
  }
);

export const selectAreAnyFiltered = createSelector(
  (s) => localSelectors.selectAll(s.fleetPageColumnOptions.columns),
  (columns: any) => {
    return columns.some(
      (c) => c.filter && c.filter.isFiltered(c.filter as any)
    );
  }
);

export const selectColumnsTable = createSelector(
  (s) => s.fleetPageColumnOptions.columns,
  (s) => s.taxonomy,
  (s) => s.fleetPage.sort,
  (columns, taxonomy, sort: any) => (t) => {
    const filteredTableColumns = (columns as any).ids
      ?.map((id) => (columns as any)?.entities[id])
      ?.filter((item) => item.isVisible && item.key !== "fleetName");
    const withTranslatedHeader = filteredTableColumns?.map((column: any) => {
      let iconName = column.iconName;
      if (column.key === sort.key && column.isSortable) {
        iconName =
          sort.key === column.key
            ? sort!.dir === "asc"
              ? "SortUp"
              : "SortDown"
            : "Sort";
      }
      return {
        ...column,
        name: t(column.name),
        iconName: iconName,
      };
    });
    return withTranslatedHeader;
    // return columns
    //   .filter((c) => c.isVisible && c.key !== "fleetName")
    //   .map((c) => {
    //     const dc = c.detailsListColumn
    //       ? c.detailsListColumn({ t, taxonomy, width: c.width })
    //       : null;
    //     return {
    //       key: c.key,
    //       width: c.width,
    //       label: t(c.labelKey),
    //       isSortable: c.isSortable ?? true,
    //       ...dc,
    //     } as DetailsListColumn;
    //   });
  }
);

import { useMutation } from "react-query";
import { transferTEMPVehicle } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const transferTEMPVehicleFn = async ({
  fleetId,
  vehicleId,
}: {
  fleetId: string;
  vehicleId: string;
}) => {
  return await transferTEMPVehicle({
    fleetId,
    vehicleId,
  });
};

export function useTransferTempVehicle() {
  return useMutation(transferTEMPVehicleFn, {
    mutationKey: MUTATION_KEYS.cancelVehiclePendingStatus,
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { useMutation } from "react-query";
import { CancelFleetPremiumParams, cancelFleetPremium } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const cancelFleetPremiumFn = async (params: CancelFleetPremiumParams) => {
  return await cancelFleetPremium(params);
};

export function useRemoveFleetPremium() {
  return useMutation(cancelFleetPremiumFn, {
    mutationKey: MUTATION_KEYS.cancelFleetPremium,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import React from "react";
import { TimelineDialogType } from "../../../types/types";
import { useFields } from "../../form/useFields";
import { useOneTimeAnnualNetPremium } from "../hooks/useOneTimeAnnualNetPremium";
import { modifyOneTimePremiumDialogConfig } from "./modifyOneTimePremiumDialogConfig";

const config = modifyOneTimePremiumDialogConfig;

interface IProps {
  premiumTypeKey: string;
  dialogType: TimelineDialogType;
}

export const ModifyOneTimePremiumDialogContents = ({
  premiumTypeKey,
  dialogType,
}: IProps) => {
  useOneTimeAnnualNetPremium(premiumTypeKey);

  const { all: oneTimePremiumDate } = useFields([config.oneTimePremiumDate]);
  const { all: oneTimePremiumGrossValue } = useFields([
    config.oneTimePremiumGrossValue,
  ]);
  const { all: oneTimePremiumNetValue } = useFields([
    config.oneTimePremiumNetValue,
  ]);
  const { all: oneTimePremiumComment } = useFields([
    config.oneTimePremiumComment,
  ]);
  const { all: oneTimePremiumType } = useFields([config.oneTimePremiumType]);

  let dialogContents = null;
  if (dialogType === TimelineDialogType.ADD_ONETIME_PREMIUM) {
    dialogContents = (
      <>
        <div className="row">{oneTimePremiumDate}</div>
        <div className="row">{oneTimePremiumGrossValue}</div>
        <div className="row">{oneTimePremiumNetValue}</div>
        <div className="row">{oneTimePremiumComment}</div>
        <div className="row">{oneTimePremiumType}</div>
      </>
    );
  }
  if (dialogType === TimelineDialogType.CHANGE_ONETIME_PREMIUM) {
    dialogContents = (
      <>
        <div className="row">{oneTimePremiumDate}</div>
        <div className="row">{oneTimePremiumGrossValue}</div>
        <div className="row">{oneTimePremiumNetValue}</div>
        <div className="row">{oneTimePremiumComment}</div>
        <div className="row">{oneTimePremiumType}</div>
      </>
    );
  }
  if (dialogType === TimelineDialogType.DELETE_ONETIME_PREMIUM) {
    dialogContents = (
      <>
        <div className="row">{oneTimePremiumComment}</div>
      </>
    );
  }

  return dialogContents;
};

import { Dialog, Icon, TooltipHost } from "@fluentui/react";
import { Form, Formik } from "formik";
import { useAdjustVehiclePremium } from "hooks/data/mutations/useAdjustVehiclePremium";
import { useCancelVehiclePremium } from "hooks/data/mutations/useCancelVehiclePremium";
import { useContinueVehiclePremium } from "hooks/data/mutations/useContinueVehiclePremium";
import { useCorrectLastVehiclePremium } from "hooks/data/mutations/useCorrectLastVehiclePremium";
import { useCorrectVehiclePremium } from "hooks/data/mutations/useCorrectVehiclePremium";
import { useDeleteVehiclePremium } from "hooks/data/mutations/useDeleteVehiclePremium";
import { useGetFleetInsurerDefaultSettings } from "hooks/data/queries/getFleetInsurerDefaultSettings";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import moment from "moment";
import { getInsurerBranchOffice } from "pages/fleet-form/FleetForm/tabs/InsurerDefaultSettings/utils";
import useActionConfig from "pages/fleet-form/hooks/useActionConfg";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import * as yup from "yup";
import { toast } from "../../../components/FluentToast";
import { FormSpinner } from "../../../components/FormSpinner";
import { transformForServer } from "../../../components/form/transformForServer";
import { Flex, HSpace, StyledPrimaryButton } from "../../../components/styled";
import { RefProvider } from "../../../contexts/RefProvider";
import { useSelector } from "../../../store/hooks";
import {
  closeDialog,
  setDialogDefaultValueOperation,
  setDialogInsurerSettingsOperation,
  setOperationRetVal,
} from "../../../store/timeline";
import {
  ETimelineExtraDataLineSpecial,
  IFleetInsurerDefaultSetting,
  PremiumType,
  TimelineDialogType,
} from "../../../types/types";
import { setYupLocale } from "../../../utils/setYupLocale";
import { removeEmpty } from "../../../utils/utils";
import { TSetDefaultSettingValues } from "./AddPremiumDialog/AddPremiumDialog";
import { ModifyPremiumDialogContents } from "./ModifyPremiumDialogContents";
import { modifyPremiumDialogConfig } from "./modifyPremiumDialogConfig";
import { validationObjectConfig } from "./modifyPremiumDialogValidation";
import { transformForForm } from "./transformForForm";

export enum ModifyPremiumDialogType {
  Correct,
  Adjust,
  Update,
  Cancel,
  Delete,
  Continue,
}

export const ModifyPremiumDialog = () => {
  const theme = useTheme() as IStyledTheme;
  const { fleetId } = useParams() as any;

  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const phraseActivated = usePhraseActivationStatus().activated;

  const item = useSelector((s) => s.timeline.dialog.item);
  const dialogType = useSelector((s) => s.timeline.dialog.type);
  const premiumTypeKey = useSelector(
    (s) => s.timeline.dialog.item.domain.value
  );
  const rowVersion = useSelector((s) => s.vehicle.vehicle?.rowVersion);
  const timelineAppSettings = useSelector(
    (s) => s.timeline.timelineAppSettings
  );
  const { paymentMeanOptions, paymentMethodOptions } =
    useActionConfig(timelineAppSettings);

  const date = useSelector((s) => s.timeline.dialog.date);

  const insurerList = useSelector((s) => s.vehicle.insurerList);

  const mainMtplInsurerPartnerNumber = useSelector(
    (s) => s.vehicle.fleet.mainMtplInsurerPartnerNumber
  );

  const mainHullInsurerPartnerNumber = useSelector(
    (s) => s.vehicle.fleet.mainHullInsurerPartnerNumber
  );
  const mainFleetLegalInsurerPartnerNumber = useSelector(
    (s) => s.vehicle.fleet.mainFleetLegalInsurerPartnerNumber
  );

  const isMtplPremiumType = premiumTypeKey === PremiumType.MTPL;
  const isHullPremiumType = premiumTypeKey === PremiumType.HULL;

  const isFleetLegalProRataPremiumType =
    premiumTypeKey === PremiumType.FLEETLEGAL_PRORATA;

  const insurerOptions = insurerList
    ? insurerList.map((tax) => {
        return {
          value: tax.insurerInternalNumber,
          label: tax.insurerName,
        };
      })
    : [];

  const { data: fleetInsurerDefaultSettings } =
    useGetFleetInsurerDefaultSettings(fleetId);

  const {
    mutate: onAdjustVehiclePremium,
    isLoading: adjustVehiclePremiumInProgress,
  } = useAdjustVehiclePremium();

  const {
    mutate: onCancelVehiclePremium,
    isLoading: cancelVehiclePremiumInProgress,
  } = useCancelVehiclePremium();

  const {
    mutate: onContinueVehiclePremium,
    isLoading: continueVehiclePremiumInProgress,
  } = useContinueVehiclePremium();

  const {
    mutate: onCorrectLastVehiclePremium,
    isLoading: correctLastVehiclePremiumInProgress,
  } = useCorrectLastVehiclePremium();

  const {
    mutate: onCorrectVehiclePremium,
    isLoading: correctVehiclePremiumInProgress,
  } = useCorrectVehiclePremium();

  const {
    mutate: onDeleteVehiclePremium,
    isLoading: deleteVehiclePremiumInProgress,
  } = useDeleteVehiclePremium();

  const handleSuccess = (res) => {
    dispatch(setOperationRetVal(res?.data));
    toast.success(t("bfm.success"));
    dispatch(closeDialog());
  };

  const isLoading =
    adjustVehiclePremiumInProgress ||
    cancelVehiclePremiumInProgress ||
    continueVehiclePremiumInProgress ||
    correctLastVehiclePremiumInProgress ||
    correctVehiclePremiumInProgress ||
    deleteVehiclePremiumInProgress;

  const isComplexEnginePowerDialog =
    premiumTypeKey === PremiumType.HORSE_POWER &&
    dialogType === TimelineDialogType.CORRECT_PREMIUM &&
    !item.extraData.isInfinitePremium;
  const schema = useMemo(() => {
    setYupLocale(t);
    let yupObject = validationObjectConfig(item, dialogType, premiumTypeKey, t);
    return yup.object(yupObject);
  }, [t, premiumTypeKey, dialogType, item]);

  const setDefaultSettingValues = async ({
    setFieldValue,
    values,
    insurer,
    noToastMsg = true,
    mustHaveInsurer = false,
    isHullPremium = true,
    setFieldTouched,
  }: TSetDefaultSettingValues) => {
    // if (!isMtplPremiumType && !isHullBasicType && !isHullPremiumType) return;
    if (mustHaveInsurer && !insurer) return;

    const insurerInternalNumberByPremiumType = (() => {
      if (insurer) {
        return Number(insurer.value);
      }
      if (isMtplPremiumType) {
        return Number(mainMtplInsurerPartnerNumber);
      }
      if (isHullPremiumType) {
        return Number(mainHullInsurerPartnerNumber);
      }
      if (isFleetLegalProRataPremiumType) {
        return Number(mainFleetLegalInsurerPartnerNumber);
      }

      return null;
    })();

    const insurerPartnerNumberByPremiumType = insurerList.find(
      (insurer) =>
        insurer.insurerInternalNumber === insurerInternalNumberByPremiumType
    )?.insurerInternalNumber;

    const selectedFleetInsurerDefaultSetting: IFleetInsurerDefaultSetting =
      (() => {
        if (
          (isMtplPremiumType ||
            isHullPremiumType ||
            isFleetLegalProRataPremiumType) &&
          insurerInternalNumberByPremiumType
        ) {
          return fleetInsurerDefaultSettings.find(
            (setting) =>
              setting.insurerPartnerNumber ===
              insurerInternalNumberByPremiumType
          );
        }
        return null;
      })();

    const selectedInsurer = insurerOptions.find(
      (option) => option.value === insurerPartnerNumberByPremiumType
    );

    const insurerBranchOffice = getInsurerBranchOffice({
      formInsurer: insurerPartnerNumberByPremiumType,
      insurerList,
      formBranchOffice: selectedFleetInsurerDefaultSetting?.insurerBranchOffice,
    });

    let fieldNames2 = [];

    if (selectedFleetInsurerDefaultSetting) {
      const paymentMean = paymentMeanOptions.find(
        (item) => item.value === selectedFleetInsurerDefaultSetting?.paymentMean
      );

      const paymentMethod = paymentMethodOptions.find(
        (item) =>
          item.value === selectedFleetInsurerDefaultSetting?.paymentMethod
      );

      const paymentMeanFleetLegal = paymentMeanOptions.find(
        (item) =>
          item.value ===
          selectedFleetInsurerDefaultSetting?.paymentMeanFleetLegal
      );

      const paymentMethodFleetLegal = paymentMethodOptions.find(
        (item) =>
          item.value ===
          selectedFleetInsurerDefaultSetting?.paymentMethodFleetLegal
      );

      const paymentMethodEngineTax = paymentMethodOptions.find(
        (item) =>
          item.value ===
          selectedFleetInsurerDefaultSetting?.paymentMethodEngineTax
      );

      if (isFleetLegalProRataPremiumType) {
        if (paymentMeanFleetLegal) {
          setFieldValue("paymentMean", paymentMeanFleetLegal);
        } else {
          setFieldValue("paymentMean", paymentMean);
        }
        if (paymentMethodFleetLegal) {
          setFieldValue("paymentMethod", paymentMethodFleetLegal);
        } else {
          setFieldValue("paymentMethod", paymentMethod);
        }
      } else {
        setFieldValue("paymentMean", paymentMean);
        setFieldValue("paymentMethod", paymentMethod);
      }

      setFieldTouched("motorTaxPaymentMethod", false);

      if (paymentMethodEngineTax) {
        setFieldValue("motorTaxPaymentMethod", paymentMethodEngineTax);
      } else {
        setFieldValue("motorTaxPaymentMethod", paymentMethod);
      }

      if (selectedFleetInsurerDefaultSetting?.activationDays?.toString()) {
        setFieldValue(
          "activationDays",
          selectedFleetInsurerDefaultSetting?.activationDays
        );
      } else {
        fieldNames2.push("activationDays");
      }

      if (selectedFleetInsurerDefaultSetting?.suspensionDays?.toString()) {
        setFieldValue(
          "suspensionDays",
          selectedFleetInsurerDefaultSetting?.suspensionDays
        );
      } else {
        fieldNames2.push("suspensionDays");
      }

      if (isHullPremium) {
        if (
          selectedFleetInsurerDefaultSetting?.garageRiskDiscountPercentHull?.toString()
        ) {
          setFieldValue(
            "garageRiskDiscountPercent",
            selectedFleetInsurerDefaultSetting?.garageRiskDiscountPercentHull
              ?.toString()
              .replace(".", ",")
          );
        } else {
          fieldNames2.push("garageRiskDiscountPercent");
        }
      } else {
        if (
          selectedFleetInsurerDefaultSetting?.garageRiskDiscountPercentHullBasic?.toString()
        ) {
          setFieldValue(
            "garageRiskDiscountPercent",
            selectedFleetInsurerDefaultSetting?.garageRiskDiscountPercentHullBasic
              ?.toString()
              .replace(".", ",")
          );
        } else {
          fieldNames2.push("garageRiskDiscountPercent");
        }
      }
    }
    setFieldValue("insurerPartnerNumber", selectedInsurer);
    if (selectedFleetInsurerDefaultSetting) {
      setFieldValue("insurerBranchOffice", insurerBranchOffice);
    }

    let fieldNames1 = ["annualGrossPremium", "deductible", "calculation"];

    dispatch(
      setDialogDefaultValueOperation({
        fieldNames: fieldNames1,
        formValues: values,
        noMessage: noToastMsg,
      })
    );

    fieldNames2.push("suspensionDaysLimit");
    fieldNames2.push("motorTaxSuspensionDaysLimit");

    fieldNames2.push("annualNetPremium");

    dispatch(
      setDialogInsurerSettingsOperation({
        fieldNames: fieldNames2,
        formValues: values,
        noMessage: noToastMsg,
      })
    );
  };

  const vehicle = useSelector((s) => s.vehicle.vehicle);

  const taxonomy = useSelector((s) => s.taxonomy);

  const associatedObject = useSelector(
    (s) => s.timeline.dialog.item.associatedObject
  );
  const dueDay = useSelector(
    (s) => s.timeline.dialog.item.associatedObject?.dueDay
  );
  const dueMonth = useSelector(
    (s) => s.timeline.dialog.item.associatedObject?.dueMonth
  );

  const onSubmit = (data) => {
    let result = transformForServer({
      obj: data,
      config: modifyPremiumDialogConfig,
    }) as any;
    result = removeEmpty(result);
    let premiumTypeKeyFinal = premiumTypeKey;

    const isFleetLegalClosingDate =
      item.extraData.lineSpecial === ETimelineExtraDataLineSpecial.CLOSING_DATE;

    if (
      premiumTypeKeyFinal === PremiumType.HULL &&
      item.extraData.lineSpecial === ETimelineExtraDataLineSpecial.BASIC
    ) {
      premiumTypeKeyFinal = PremiumType.HULL_BASIC;
    }

    if (
      premiumTypeKeyFinal === PremiumType.FLEETLEGAL_PRORATA &&
      isFleetLegalClosingDate
    ) {
      premiumTypeKeyFinal = PremiumType.FLEETLEGAL_CLOSINGDATE;
    }

    let objectToSend: any = null;
    if (
      dialogType === TimelineDialogType.CORRECT_PREMIUM &&
      isComplexEnginePowerDialog &&
      premiumTypeKey === PremiumType.HORSE_POWER
    ) {
      objectToSend = {
        vehicleId: vehicle.vehicleId as any,
        vehiclePremiumVersionId: item.associatedObject
          .vehiclePremiumVersionId as any,
        fleetId: vehicle.fleetId as any,
        body: {
          ...result,
          premiumType: taxonomy.PremiumType.byCode[`${premiumTypeKey}`]?.id,
          engineTaxRelatedVehicleInfo: { ...result },
        },
      };
    } else if (
      (dialogType === TimelineDialogType.CORRECT_PREMIUM ||
        dialogType === TimelineDialogType.ADJUST_PREMIUM) &&
      (premiumTypeKey === PremiumType.HULL ||
        premiumTypeKey === PremiumType.HULL_BASIC)
    ) {
      objectToSend = {
        vehicleId: vehicle.vehicleId as any,
        vehiclePremiumVersionId: item.associatedObject
          .vehiclePremiumVersionId as any,
        fleetId: vehicle.fleetId as any,
        body: {
          ...result,
          premiumType:
            taxonomy.PremiumType.byCode[`${premiumTypeKeyFinal}`]?.id,
          hullPremiumRelatedInfo: {
            hullProduct: result.hullProduct,
            hullDeductiblePercent: result.hullDeductiblePercent,
            hullDeductibleMaxAmount: result.hullDeductibleMaxAmount,
            hullDeductibleMinAmount: result.hullDeductibleMinAmount,
            hullDeductibleComment: result.hullDeductibleComment,
            gap: result.gap,
            replacementValue: result.replacementValue,
            grossNegligence: result.grossNegligence,
            electroPlusPackage: result.electroPlusPackage,
          },
        },
      };
      delete objectToSend.body.hullProduct;
      delete objectToSend.body.hullDeductiblePercent;
      delete objectToSend.body.hullDeductibleMaxAmount;
      delete objectToSend.body.hullDeductibleMinAmount;
      delete objectToSend.body.hullDeductibleComment;
      delete objectToSend.body.gap;
      delete objectToSend.body.replacementValue;
      delete objectToSend.body.grossNegligence;
      delete objectToSend.body.electroPlusPackage;
    } else {
      objectToSend = {
        vehicleId: vehicle.vehicleId as any,
        vehiclePremiumVersionId: item.associatedObject
          .vehiclePremiumVersionId as any,
        fleetId: vehicle.fleetId as any,
        body: {
          ...result,
          premiumType:
            taxonomy.PremiumType.byCode[`${premiumTypeKeyFinal}`]?.id,
        },
      };
    }

    if (
      dialogType === TimelineDialogType.CORRECT_PREMIUM &&
      isFleetLegalClosingDate
    ) {
      objectToSend.body.isFirstForFleetLegalClosingDate =
        data.isFirstForFleetLegalClosingDate;
    }

    if (dialogType === TimelineDialogType.CORRECT_PREMIUM) {
      if (item.associatedObject.validToDate) {
        onCorrectVehiclePremium(
          {
            rowVersion,
            params: objectToSend,
          },
          {
            onSuccess: handleSuccess,
          }
        );
      } else {
        onCorrectLastVehiclePremium(
          {
            rowVersion,
            params: objectToSend,
          },
          {
            onSuccess: handleSuccess,
          }
        );
      }
    }
    if (dialogType === TimelineDialogType.ADJUST_PREMIUM) {
      onAdjustVehiclePremium(
        {
          rowVersion,
          params: objectToSend,
        },
        {
          onSuccess: handleSuccess,
        }
      );
    }
    if (dialogType === TimelineDialogType.CANCEL_PREMIUM) {
      onCancelVehiclePremium(
        {
          rowVersion,
          params: objectToSend,
        },
        {
          onSuccess: handleSuccess,
        }
      );
    }
    if (dialogType === TimelineDialogType.DELETE_PREMIUM) {
      onDeleteVehiclePremium(
        {
          rowVersion,
          params: objectToSend,
        },
        {
          onSuccess: handleSuccess,
        }
      );
    }
    if (dialogType === TimelineDialogType.CONTINUE_PREMIUM) {
      onContinueVehiclePremium(
        {
          rowVersion,
          params: objectToSend,
        },
        {
          onSuccess: handleSuccess,
        }
      );
    }
  };
  const Title = (
    <Flex>
      <Icon
        iconName="CircleCorrectition"
        style={{
          color: theme.palette.green,
        }}
      />
      <HSpace />
      <div>
        {dialogType === TimelineDialogType.CORRECT_PREMIUM
          ? t("bfm.correctPremium")
          : null}
        {dialogType === TimelineDialogType.ADJUST_PREMIUM
          ? t("bfm.adjustPremium")
          : null}
        {dialogType === TimelineDialogType.CANCEL_PREMIUM
          ? t("bfm.cancelPremium")
          : null}{" "}
        {dialogType === TimelineDialogType.CONTINUE_PREMIUM
          ? t("bfm.continuePremium")
          : null}{" "}
        {dialogType === TimelineDialogType.DELETE_PREMIUM
          ? t("bfm.deletePremium")
          : null}{" "}
        {item.extraData?.lineSpecial === ETimelineExtraDataLineSpecial.BASIC
          ? t(PremiumType.HULL_BASIC)
          : item.extraData?.lineSpecial ===
            ETimelineExtraDataLineSpecial.CLOSING_DATE
          ? t(PremiumType.FLEETLEGAL_CLOSINGDATE)
          : t(`${premiumTypeKey}`)}
      </div>
    </Flex>
  );

  const initialValues = useMemo(() => {
    if (!item.associatedObject) return null;

    const isFleetLegalClosingDate =
      item.extraData.lineSpecial === ETimelineExtraDataLineSpecial.CLOSING_DATE;

    let obj: any = {
      insurerPartnerNumber: item.associatedObject.insurerPartnerNumber,
      insurerPolicyNumber: item.associatedObject.insurerPolicyNumber,
      insurerGroupPolicyNumber: item.associatedObject.insurerGroupPolicyNumber,
      insurerBranchOffice: item.associatedObject.insurerBranchOffice,
      validToDate: item.associatedObject.validToDate,
      cancelationDueDate: item.associatedObject.cancelationDueDate,
      expiryDate: item.associatedObject.expiryDate,
      greenCardExpiryDate: item.associatedObject.greenCardExpiryDate,
      annualGrossPremium: item.associatedObject.annualGrossPremium,
      annualNetPremium: item.associatedObject.annualNetPremium,
      paymentMethod: item.associatedObject.paymentMethod,
      paymentMean: item.associatedObject.paymentMean,
      motorTaxExclusionReason:
        item.associatedObject.excludeHorsePowerBasedTaxReason,
      suspensionDays: item.associatedObject.suspensionDays,
      activationDays: item.associatedObject.activationDays,
      suspensionDaysLimit: item.associatedObject.suspensionDaysLimit,
      garageRiskDiscountPercent:
        item.associatedObject.garageRiskDiscountPercent,
      validFromDate:
        dialogType === TimelineDialogType.ADJUST_PREMIUM ||
        dialogType === TimelineDialogType.CANCEL_PREMIUM
          ? date
          : moment(item.associatedObject.validFromDate).toDate(),
      ...item.associatedObject.engineTaxRelatedVehicleInfo,
      ...item.associatedObject.hullPremiumRelatedInfo,
    };
    if (
      (premiumTypeKey === PremiumType.MTPL ||
        premiumTypeKey === PremiumType.HULL ||
        premiumTypeKey === PremiumType.HULL_BASIC ||
        //debugger
        PremiumType.FLEETLEGAL_CLOSINGDATE ||
        PremiumType.FLEETLEGAL_PRORATA) &&
      (dialogType === TimelineDialogType.CORRECT_PREMIUM ||
        dialogType === TimelineDialogType.ADJUST_PREMIUM)
    ) {
      obj = {
        ...obj,
        dueDate:
          ((dueDay + "").length === 1 ? "0" + dueDay : dueDay) +
          "" +
          ((dueMonth + "").length === 1 ? "0" + dueMonth : dueMonth),
      };
    }
    if (
      (premiumTypeKey === PremiumType.MTPL ||
        premiumTypeKey === PremiumType.HULL ||
        premiumTypeKey === PremiumType.HULL_BASIC) &&
      dialogType === TimelineDialogType.ADJUST_PREMIUM
    ) {
      obj = {
        ...obj,
        isPolicyIssued: false,
        documentDate: null,
        cancelationDocumentDate: null,
      };
    } else {
      obj = {
        ...obj,
        isPolicyIssued: item.associatedObject.isPolicyIssued,
        documentDate: item.associatedObject.documentDate,
        cancelationDocumentDate: item.associatedObject.cancelationDocumentDate,
      };
    }
    let initObj: any = transformForForm({
      obj: obj,
      config: modifyPremiumDialogConfig,
      t,
      taxonomy,
      insurerList,
      possibleMainVehicles: null,
    });

    initObj = {
      ...initObj,
      validFromDate:
        dialogType === TimelineDialogType.ADJUST_PREMIUM ||
        dialogType === TimelineDialogType.CANCEL_PREMIUM
          ? date
          : moment(item.associatedObject.validFromDate).toDate(),
    };

    if (
      dialogType === TimelineDialogType.CORRECT_PREMIUM &&
      isFleetLegalClosingDate
    ) {
      initObj = {
        ...initObj,
        isFirstForFleetLegalClosingDate:
          item.associatedObject?.isFirstForFleetLegalClosingDate,
      };
    }

    return initObj;
  }, [
    t,
    taxonomy,
    insurerList,
    date,
    item,
    dialogType,
    dueDay,
    dueMonth,
    premiumTypeKey,
  ]);

  if (!associatedObject) {
    return null;
  }

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: Title,
        showCloseButton: true,
      }}
      minWidth={
        premiumTypeKey === PremiumType.HULL &&
        (dialogType === TimelineDialogType.ADJUST_PREMIUM ||
          dialogType === TimelineDialogType.CORRECT_PREMIUM)
          ? 1050
          : 700
      }
      onDismiss={() => {
        dispatch(closeDialog());
      }}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <RefProvider>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={didTrySubmit}
          validateOnBlur={didTrySubmit}
          validationSchema={schema}
        >
          {({ submitForm, values, setFieldValue, setFieldTouched }) => {
            return (
              <Form>
                <ModifyPremiumDialogContents
                  premiumTypeKey={premiumTypeKey}
                  dialogType={dialogType}
                />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {dialogType === TimelineDialogType.CANCEL_PREMIUM ||
                  dialogType === TimelineDialogType.CONTINUE_PREMIUM ||
                  dialogType === TimelineDialogType.DELETE_PREMIUM ? (
                    <div></div>
                  ) : (
                    <TooltipHost
                      content={t("bfm.fillInAllFleetDefaults.label")}
                    >
                      <StyledPrimaryButton
                        text={t("bfm.defaultSettings.label")}
                        onClick={() => {
                          const isHullPremium =
                            values["hullIsBasic"]?.value === "full" ||
                            values["hullIsBasic"]?.value === "false";

                          setDefaultSettingValues({
                            setFieldValue,
                            values,
                            insurer: values["insurerPartnerNumber"],
                            mustHaveInsurer: true,
                            noToastMsg: false,
                            isHullPremium,
                            setFieldTouched,
                          });
                        }}
                        iconProps={{
                          iconName: "Shield",
                        }}
                        disabled={isLoading}
                      />
                    </TooltipHost>
                  )}
                  <StyledPrimaryButton
                    text={t("greco.save")}
                    onClick={() => {
                      setDidTrySubmit(true);
                      submitForm();
                    }}
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </div>
                {isLoading && <FormSpinner />}
              </Form>
            );
          }}
        </Formik>
      </RefProvider>
    </Dialog>
  );
};

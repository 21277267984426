import { UseQueryResult, useQuery } from "react-query";
import { getInsurers } from "store/api/api";
import { InsurerSettings } from "types/types";
import { QUERY_KEYS } from "../queryKeys";

const getInsurerList = async (countryCode: string) => {
  const res = await getInsurers(countryCode);
  return res?.data;
};

export function useGetInsurerList(
  countryCode: string
): UseQueryResult<InsurerSettings[]> {
  return useQuery(
    [QUERY_KEYS.insurerListSettings],
    getInsurerList.bind("countryCode", countryCode),
    {
      staleTime: 3_600_000, //1 hour
      enabled: true,
    }
  );
}

import { errorHandler } from "library/errorHandler";
import { ToastOptions } from "react-toastify";

export const createToastFn =
  (type: ToastOptions["type"]) => (message: string, options?: ToastOptions) => {
    return errorHandler({
      value: message,
      type: type,
      label: message,
      fieldName: null,
      errors: null,
    });
  };

export const toast = {
  success: createToastFn("success"),
  error: createToastFn("error"),
  info: createToastFn("info"),
};

import ComboBoxField from "components/controls/ComboBoxField";
import DatePickerField from "components/controls/DatePickerField";
import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import RadioGroupField from "components/controls/RadioGroupField";
import TextInputField from "components/controls/TextInputField";
import { constants } from "library/constants";
import {
  isDisabledExternal,
  isDisabledExternalOrHasBeenActivated,
} from "pages/vehicle/helpers";
import { vehicleFormClasses } from "pages/vehicle/VehicleForm";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";

const fullRowStyle = {
  width: "100%",
};

const FinanceGeneralFields = ({ formikProps, vehicle, route }) => {
  const { createTradeVehicle } = constants;

  const { t } = useTranslation();
  const userRole = useSelector((s) => s.auth.userRole);
  const { values, errors, handleChange, setFieldValue } = formikProps;
  const taxonomy = useSelector((s) => s.taxonomy);
  const fleet = useSelector((s) => s.vehicle.fleet);
  const countryCode = fleet?.countryCode;

  const incorrectVehicleIssuingCodeId =
    taxonomy.VehicleIssuingStatus.byCode["VehicleIssuingStatus.INCORRECT"]?.id;

  const isTradeLicensePlate =
    route === createTradeVehicle || vehicle?.isTradeLicensePlate;

  const vehicleIssuingSubstatusOptions = useMemo(() => {
    return taxonomy.VehicleIssuingSubstatus.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy.VehicleIssuingSubstatus.items]);
  const vehicleAppSettings = useSelector((s) => s.vehicle.vehicleAppSettings);

  const listPriceTypeCodeOptions = useMemo(() => {
    return taxonomy.ListPriceType.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy.ListPriceType.items]);

  const vehicleBonusMalusCodeOptions = useMemo(() => {
    const countryObj = taxonomy.Country.byId[countryCode];
    if (!countryObj) {
      return [];
    }
    const country = countryObj.code.split(".")[1];

    const itemsByCountry = taxonomy.VehicleBonusMalusCode.items.filter(
      (item) => {
        return item.code.includes(country);
      }
    );

    return itemsByCountry.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [
    taxonomy.Country.byId,
    taxonomy.VehicleBonusMalusCode.items,
    countryCode,
    t,
  ]);

  return (
    <>
      <div className={vehicleFormClasses.item} style={fullRowStyle}>
        {/* 25 */}
        <TextInputField
          value={values.comment}
          onChange={handleChange}
          errors={errors}
          name={"comment"}
          multiline
          rows={7}
          disabled={isDisabledExternal({ userRole, route })}
          label={t("bfm.vehicleForm.comment.label")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes("comment")}
        />
      </div>
      {isTradeLicensePlate && (
        <>
          <div className={vehicleFormClasses.item}>
            <TextInputField
              errors={errors}
              value={values.costCenterClient}
              onChange={handleChange}
              maxLength={254}
              name={"costCenterClient"}
              disabled={route === "readOnlyVehicle"}
              label={"bfm.vehicleForm.costCenterClient.label"}
              isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
                "costCenterClient"
              )}
            />
          </div>

          {/* 24 */}
          <div className={vehicleFormClasses.commentItem}>
            <TextInputField
              value={values.clientComment}
              onChange={handleChange}
              errors={errors}
              name={"clientComment"}
              multiline
              rows={7}
              disabled={route === "readOnlyVehicle"}
              label={"bfm.vehicleForm.clientComment.label"}
              isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
                "clientComment"
              )}
            />
          </div>
          <div className={vehicleFormClasses.commentItemGrid}>
            <TextInputField
              value={values.freeTextField1}
              onChange={handleChange}
              errors={errors}
              name={"freeTextField1"}
              multiline
              rows={7}
              disabled={route === "readOnlyVehicle"}
              label={"bfm.vehicleForm.freeTextField1.label"}
              isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
                "freeTextField1"
              )}
            />
          </div>
          <div className={vehicleFormClasses.commentItemGrid}>
            <TextInputField
              value={values.freeTextField2}
              onChange={handleChange}
              errors={errors}
              name={"freeTextField2"}
              multiline
              rows={7}
              disabled={route === "readOnlyVehicle"}
              label={"bfm.vehicleForm.freeTextField2.label"}
              isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
                "freeTextField2"
              )}
            />
          </div>
        </>
      )}

      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={true}
          errors={errors}
          name="vehicleIssuingStatusCode"
          label={t("bfm.vehicleForm.vehicleIssuingStatusCode.label")}
          setFieldValue={setFieldValue}
          options={[]}
          defaultValue={values.vehicleIssuingStatusCode}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "vehicleIssuingStatusCode"
          )}
          confidentiality={null}
        />
      </div>
      {!!values.vehicleIssuingStatusCode &&
        values.vehicleIssuingStatusCode.value ===
          incorrectVehicleIssuingCodeId && (
          <div className={vehicleFormClasses.item}>
            <ComboBoxField
              disabled={isDisabledExternalOrHasBeenActivated({
                route,
                vehicle,
                userRole,
              })}
              errors={errors}
              required
              name="vehicleIssuingSubstatusCode"
              label={t("bfm.vehicleForm.vehicleIssuingSubstatusCode.label")}
              setFieldValue={setFieldValue}
              options={vehicleIssuingSubstatusOptions}
              defaultValue={values.vehicleIssuingSubstatusCode}
              isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
                "vehicleIssuingSubstatusCode"
              )}
              confidentiality={null}
            />
          </div>
        )}
      <div className={vehicleFormClasses.item} style={fullRowStyle}>
        <TextInputField
          value={values.vehicleIssuingComment}
          onChange={handleChange}
          errors={errors}
          name={"vehicleIssuingComment"}
          multiline
          rows={7}
          disabled={true}
          label={t("bfm.vehicleForm.vehicleIssuingComment.label")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "vehicleIssuingComment"
          )}
        />
      </div>

      <div className={vehicleFormClasses.item}>
        <TextInputField
          value={values.leasingCompany}
          onChange={handleChange}
          errors={errors}
          name={"leasingCompany"}
          disabled={isDisabledExternal({ userRole, route })}
          label={t("bfm.vehicleForm.leasingCompany.label")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "leasingCompany"
          )}
        />
      </div>
      {vehicleAppSettings?.SHOW_LEASING_EXTRA_FIELDS && (
        <>
          <div className={vehicleFormClasses.item}>
            <TextInputField
              value={values.leasingContractNumber}
              onChange={handleChange}
              errors={errors}
              name={"leasingContractNumber"}
              disabled={isDisabledExternal({ userRole, route })}
              label={t("bfm.vehicleForm.leasingContractNumber.label")}
              isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
                "leasingContractNumber"
              )}
            />
          </div>
          <div className={vehicleFormClasses.item}></div>
          <div className={vehicleFormClasses.item}>
            <DatePickerField
              errors={errors}
              setFieldValue={setFieldValue}
              defaultValue={values.leasingContractStartDate}
              name={"leasingContractStartDate"}
              disabled={isDisabledExternal({ userRole, route })}
              label={"bfm.vehicleForm.leasingContractStartDate.label"}
              placeholder={t("bfm.selectDate")}
              isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
                "leasingContractStartDate"
              )}
              confidentiality={null}
            />
          </div>
          <div className={vehicleFormClasses.item}>
            <DatePickerField
              errors={errors}
              setFieldValue={setFieldValue}
              defaultValue={values.leasingContractEndDate}
              name={"leasingContractEndDate"}
              disabled={isDisabledExternal({ userRole, route })}
              label={"bfm.vehicleForm.leasingContractEndDate.label"}
              placeholder={t("bfm.selectDate")}
              isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
                "leasingContractEndDate"
              )}
              confidentiality={null}
            />
          </div>
          <div className={vehicleFormClasses.item}>
            <DoubleNumberInputField
              setFieldValue={setFieldValue}
              value={values.leasingAmountPaidByLessee}
              onChange={handleChange}
              errors={errors}
              name={"leasingAmountPaidByLessee"}
              disabled={isDisabledExternal({ userRole, route })}
              label={t("bfm.vehicleForm.leasingAmountPaidByLessee.label")}
              isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
                "leasingAmountPaidByLessee"
              )}
            />
          </div>
        </>
      )}
      {vehicleAppSettings?.SHOW_EUROTAX_CODE && (
        <>
          <div className={vehicleFormClasses.item}>
            <TextInputField
              value={values.eurotaxCode}
              onChange={handleChange}
              errors={errors}
              name={"eurotaxCode"}
              disabled={isDisabledExternal({ userRole, route })}
              label={t("bfm.vehicleForm.eurotaxCode.label")}
              isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
                "eurotaxCode"
              )}
            />
          </div>
          <div className={vehicleFormClasses.item}></div>
          <div className={vehicleFormClasses.item}></div>
        </>
      )}
      {vehicleAppSettings?.SHOW_BONUS_MALUS_CODE && (
        <>
          <div className={vehicleFormClasses.item}>
            <ComboBoxField
              disabled={isDisabledExternal({ userRole, route })}
              errors={errors}
              name="bonusMalusCode"
              label={t("bfm.vehicleForm.bonusMalusCode.label")}
              setFieldValue={setFieldValue}
              options={vehicleBonusMalusCodeOptions || []}
              defaultValue={values.bonusMalusCode}
              isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
                "bonusMalusCode"
              )}
              confidentiality={null}
            />
          </div>
          <div className={vehicleFormClasses.item}></div>
          <div className={vehicleFormClasses.item}></div>
        </>
      )}
      <div className={vehicleFormClasses.item}>
        <DoubleNumberInputField
          setFieldValue={setFieldValue}
          value={values.listPrice}
          onChange={handleChange}
          errors={errors}
          name={"listPrice"}
          disabled={isDisabledExternal({ userRole, route })}
          label={t("bfm.vehicleForm.listPrice.label")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes("listPrice")}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternal({ userRole, route })}
          errors={errors}
          name="listPriceTypeCode"
          label={t("bfm.vehicleForm.listPriceTypeCode.label")}
          setFieldValue={setFieldValue}
          options={listPriceTypeCodeOptions}
          defaultValue={values.listPriceTypeCode}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "listPriceTypeCode"
          )}
          confidentiality={null}
        />
      </div>

      <div className={vehicleFormClasses.item} style={fullRowStyle}>
        <DoubleNumberInputField
          setFieldValue={setFieldValue}
          value={values.specialEquipment}
          onChange={handleChange}
          errors={errors}
          name={"specialEquipment"}
          disabled={isDisabledExternal({ userRole, route })}
          label={t("bfm.vehicleForm.specialEquipment.label")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "specialEquipment"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <RadioGroupField
          checked={values?.isIncludingTax}
          errors={errors}
          setFieldValue={setFieldValue}
          name={"isIncludingTax"}
          disabled={isDisabledExternal({ userRole, route })}
          label={t("bfm.vehicleForm.isIncludingTax.label")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "isIncludingTax"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <RadioGroupField
          checked={values?.vatReimbursment}
          errors={errors}
          setFieldValue={setFieldValue}
          name={"vatReimbursment"}
          disabled={isDisabledExternal({ userRole, route })}
          label={t("bfm.vehicleForm.vatReimbursment.label")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "vatReimbursment"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <RadioGroupField
          checked={values?.isLeasing}
          errors={errors}
          setFieldValue={setFieldValue}
          name={"isLeasing"}
          disabled={isDisabledExternal({ userRole, route })}
          label={t("bfm.vehicleForm.isLeasing.label")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes("isLeasing")}
        />
      </div>
    </>
  );
};

export default FinanceGeneralFields;

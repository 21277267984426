import { vehicleFormClasses } from "pages/vehicle/VehicleForm";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ComboBoxField from "../../../../components/controls/ComboBoxField";
import DatePickerField from "../../../../components/controls/DatePickerField";
import NumberInputField from "../../../../components/controls/NumberInputField";
import TextInputField from "../../../../components/controls/TextInputField";
import { useSelector } from "../../../../store/hooks";
import {
  hasVehicleBeenActivated,
  isDisabledExternalAndHasBeenActivated,
} from "../../helpers";

function range(size: number, startAt: number = 0) {
  return [...Array(size).keys()].map((i) => i + startAt);
}

const VehicleFormItems = ({ initialValues, vehicle, route, formikProps }) => {
  const { t } = useTranslation();
  const userRole = useSelector((s) => s.auth.userRole);
  const fleet = useSelector((s) => s.vehicle.fleet);
  const countryCode = fleet?.countryCode;
  const { values, errors, handleChange, setFieldValue } = formikProps;
  const taxonomy = useSelector((s) => s.taxonomy);
  const confidentiality = useSelector(
    (s) => s.vehicle?.scannedFormFields?.confidentialityInfo
  );
  const isLicensePlateDisabled = ({ vehicle, route }: any) => {
    return (
      hasVehicleBeenActivated({ vehicle, route }) || route === "readOnlyVehicle"
    );
  };

  const vehicleBrandOptions = useMemo(() => {
    return taxonomy.VehicleBrand.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy.VehicleBrand.items]);

  const vehicleMainGroupOptions = useMemo(() => {
    return taxonomy.VehicleMainGroup?.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy.VehicleMainGroup?.items]);

  const vehicleTypeCodeOptions = useMemo(() => {
    return taxonomy.VehicleTypeLegal.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy.VehicleTypeLegal.items]);

  const vehicleBodyTypeCodeOptions = useMemo(() => {
    return taxonomy.VehicleBodyType.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy.VehicleBodyType.items]);

  const truckMountingCodeOptions = useMemo(() => {
    return taxonomy.TruckMounting.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy.TruckMounting.items]);

  const vehicleCarUsageCodeOptions = useMemo(() => {
    const countryObj = taxonomy.Country.byId[countryCode];
    if (!countryObj) {
      return [];
    }
    const country = countryObj.code.split(".")[1];

    const itemsByCountry = taxonomy.VehicleUsageType.items.filter((item) => {
      return item.code.includes(country);
    });

    return itemsByCountry.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [taxonomy.Country.byId, taxonomy.VehicleUsageType.items, countryCode, t]);

  const vehicleClassCodeOptions = useMemo(() => {
    return taxonomy.VehicleClass.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy.VehicleClass.items]);

  const vehiclePowerTypeCodeOptions = useMemo(() => {
    return taxonomy.VehiclePowerType.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy.VehiclePowerType.items]);

  const yearOfConstructionOptions = useMemo(() => {
    const options = range(100, new Date().getFullYear() - 100 + 1)
      .reverse()
      .map((year) => ({
        value: year,
        label: year.toString(),
      }));
    return options;
  }, []);

  return (
    <div className={vehicleFormClasses.container}>
      {/* 4 */}
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternalAndHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          errors={errors}
          name="carUsageCode"
          label={"bfm.vehicleForm.carUsageCode.label"}
          setFieldValue={setFieldValue}
          options={vehicleCarUsageCodeOptions || []}
          defaultValue={values.carUsageCode}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "carUsageCode"
          )}
          confidentiality={confidentiality?.CarUsageCode}
        />
      </div>
      {/* 5 */}
      <div className={vehicleFormClasses.item}>
        <TextInputField
          value={values.vin}
          errors={errors}
          maxLength={32}
          onChange={handleChange}
          name={"vin"}
          required
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.vin.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes("vin")}
          confidentiality={confidentiality?.Vin}
        />
      </div>
      {/* 6 */}
      <div className={vehicleFormClasses.item}>
        <DatePickerField
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={initialValues.firstRegistrationDate}
          name="firstRegistrationDate"
          disabled={isDisabledExternalAndHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.firstRegistrationDate.label"}
          placeholder={t("bfm.selectDate")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "firstRegistrationDate"
          )}
          confidentiality={confidentiality?.FirstRegistrationDate}
        />
      </div>

      {/* 7 */}
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={route === "readOnlyVehicle"}
          errors={errors}
          name="yearOfConstruction"
          label={"bfm.vehicleForm.yearOfConstruction.label"}
          setFieldValue={setFieldValue}
          options={yearOfConstructionOptions || []}
          defaultValue={values.yearOfConstruction}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "yearOfConstruction"
          )}
          confidentiality={confidentiality?.YearOfConstruction}
        />
      </div>

      {/* 8 */}
      <div className={vehicleFormClasses.item}>
        <TextInputField
          value={values.nationalCodeId}
          errors={errors}
          maxLength={254}
          onChange={handleChange}
          name={"nationalCodeId"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.nationalCodeId.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "nationalCodeId"
          )}
          confidentiality={confidentiality?.NationalCodeId}
        />
      </div>
      {/* 9 */}
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={route === "readOnlyVehicle"}
          errors={errors}
          name="vehicleClassCode"
          label={"bfm.vehicleForm.vehicleClassCode.label"}
          setFieldValue={setFieldValue}
          options={vehicleClassCodeOptions || []}
          defaultValue={values.vehicleClassCode}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "vehicleClassCode"
          )}
          confidentiality={confidentiality?.VehicleClassCode}
        />
      </div>

      {/* 10 */}
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternalAndHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          errors={errors}
          name="vehicleMainGroupCode"
          label={"bfm.vehicleForm.vehicleMainGroupCode.label"}
          setFieldValue={setFieldValue}
          options={vehicleMainGroupOptions || []}
          defaultValue={values.vehicleMainGroupCode}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "vehicleMainGroupCode"
          )}
          required
          confidentiality={confidentiality?.VehicleMainGroupCode}
        />
      </div>
      {/* 11 */}
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternalAndHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          errors={errors}
          name="vehicleTypeCode"
          label={"bfm.vehicleForm.vehicleTypeCode.label"}
          setFieldValue={setFieldValue}
          options={vehicleTypeCodeOptions || []}
          defaultValue={values.vehicleTypeCode}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "vehicleTypeCode"
          )}
          required
          confidentiality={confidentiality?.VehicleTypeCode}
        />
      </div>
      {/* 11 a */}
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternalAndHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          errors={errors}
          name="vehicleBodyTypeCode"
          label={"bfm.vehicleForm.vehicleBodyTypeCode.label"}
          setFieldValue={setFieldValue}
          options={vehicleBodyTypeCodeOptions || []}
          defaultValue={values.vehicleBodyTypeCode}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "vehicleBodyTypeCode"
          )}
          confidentiality={confidentiality?.VehicleBodyTypeCode}
        />
      </div>
      {/* 11 b */}
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternalAndHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          errors={errors}
          name="truckMountingCode"
          label={"bfm.vehicleForm.truckMountingCode.label"}
          setFieldValue={setFieldValue}
          options={truckMountingCodeOptions || []}
          defaultValue={values.truckMountingCode}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "truckMountingCode"
          )}
          confidentiality={confidentiality?.TruckMountingCode}
        />
      </div>

      {/* 12 */}
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={route === "readOnlyVehicle"}
          errors={errors}
          name="brandCode"
          label={"bfm.vehicleForm.brandCode.label"}
          setFieldValue={setFieldValue}
          options={vehicleBrandOptions || []}
          defaultValue={values.brandCode}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes("brandCode")}
          confidentiality={confidentiality?.BrandCode}
        />
      </div>
      {/* 13 */}
      <div className={vehicleFormClasses.item}>
        <TextInputField
          value={values.model}
          errors={errors}
          maxLength={254}
          onChange={handleChange}
          name={"model"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.model.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes("model")}
          confidentiality={confidentiality?.Model}
        />
      </div>

      {/* 14 */}
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.maxGrossWeightKg}
          errors={errors}
          maxLength={10}
          onChange={handleChange}
          name={"maxGrossWeightKg"}
          disabled={isDisabledExternalAndHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.maxGrossWeightKg.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "maxGrossWeightKg"
          )}
          confidentiality={confidentiality?.MaxGrossWeightKg}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.emptyWeightKg}
          errors={errors}
          maxLength={10}
          onChange={handleChange}
          name={"emptyWeightKg"}
          disabled={isDisabledExternalAndHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.emptyWeightKg.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "emptyWeightKg"
          )}
          confidentiality={confidentiality?.EmptyWeightKg}
        />
      </div>
      {/* 15 */}
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.weightCapacityKg}
          errors={errors}
          maxLength={10}
          onChange={handleChange}
          name={"weightCapacityKg"}
          disabled={isDisabledExternalAndHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.weightCapacityKg.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "weightCapacityKg"
          )}
          confidentiality={confidentiality?.WeightCapacityKg}
        />
      </div>

      {/* 16 */}
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.seats}
          errors={errors}
          maxLength={254}
          onChange={handleChange}
          name={"seats"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.seats.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes("seats")}
          confidentiality={confidentiality?.Seats}
        />
      </div>
      {/* 16 */}
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternalAndHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          errors={errors}
          name="vehiclePowerTypeCode"
          label={"bfm.vehicleForm.vehiclePowerTypeCode.label"}
          setFieldValue={setFieldValue}
          options={vehiclePowerTypeCodeOptions || []}
          defaultValue={values.vehiclePowerTypeCode}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "vehiclePowerTypeCode"
          )}
          confidentiality={confidentiality?.VehiclePowerTypeCode}
        />
      </div>
      {/* 17 */}
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.cubicCapacityCcm}
          errors={errors}
          maxLength={10}
          onChange={handleChange}
          name={"cubicCapacityCcm"}
          disabled={isDisabledExternalAndHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.cubicCapacityCcm.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "cubicCapacityCcm"
          )}
          confidentiality={confidentiality?.CubicCapacityCcm}
        />
      </div>
      {/* 18 */}
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.powerKw}
          errors={errors}
          maxLength={10}
          onChange={handleChange}
          name={"powerKw"}
          disabled={isDisabledExternalAndHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.powerKw.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes("powerKw")}
          confidentiality={confidentiality?.PowerKw}
        />
      </div>
      {/* 19 */}
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          isNegativeAllowed={true}
          setFieldValue={setFieldValue}
          value={values.co2Emission}
          errors={errors}
          maxLength={10}
          onChange={handleChange}
          name={"co2Emission"}
          disabled={isDisabledExternalAndHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.co2Emission.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "co2Emission"
          )}
          confidentiality={confidentiality?.Co2Emission}
        />
      </div>
      {/* 20 */}
      <div className={vehicleFormClasses.item}>
        <TextInputField
          value={values.certificateNumber}
          onChange={handleChange}
          errors={errors}
          name={"certificateNumber"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.certificateNumber.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "certificateNumber"
          )}
          confidentiality={confidentiality?.CertificateNumber}
        />
      </div>
      {/* 21 */}
      <div className={vehicleFormClasses.item}>
        <TextInputField
          value={values.carUsageCodeAdditional}
          errors={errors}
          maxLength={254}
          onChange={handleChange}
          name={"carUsageCodeAdditional"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.carUsageCodeAdditional.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "carUsageCodeAdditional"
          )}
          confidentiality={confidentiality?.CarUsageCodeAdditional}
        />
      </div>
      {/* 22 */}
      <div className={vehicleFormClasses.item}>
        <TextInputField
          value={values.secondLicensePlate}
          errors={errors}
          maxLength={12}
          onChange={handleChange}
          name={"secondLicensePlate"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.secondLicensePlate.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "secondLicensePlate"
          )}
          confidentiality={confidentiality?.SecondLicensePlate}
        />
      </div>

      {/* 23 */}
      <div className={vehicleFormClasses.item}>
        <TextInputField
          errors={errors}
          value={values.costCenterClient}
          onChange={handleChange}
          maxLength={254}
          name={"costCenterClient"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.costCenterClient.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "costCenterClient"
          )}
          confidentiality={confidentiality?.CostCenterClient}
        />
      </div>

      {/* 24 */}
      <div className={vehicleFormClasses.commentItem}>
        <TextInputField
          value={values.clientComment}
          onChange={handleChange}
          errors={errors}
          name={"clientComment"}
          multiline
          rows={7}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.clientComment.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "clientComment"
          )}
          confidentiality={confidentiality?.ClientComment}
        />
      </div>
      <div className={vehicleFormClasses.commentItemGrid}>
        <TextInputField
          value={values.freeTextField1}
          onChange={handleChange}
          errors={errors}
          name={"freeTextField1"}
          multiline
          rows={7}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.freeTextField1.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "freeTextField1"
          )}
          confidentiality={confidentiality?.FreeTextField1}
        />
      </div>
      <div className={vehicleFormClasses.commentItemGrid}>
        <TextInputField
          value={values.freeTextField2}
          onChange={handleChange}
          errors={errors}
          name={"freeTextField2"}
          multiline
          rows={7}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.freeTextField2.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "freeTextField2"
          )}
          confidentiality={confidentiality?.FreeTextField2}
        />
      </div>
    </div>
  );
};

export default VehicleFormItems;

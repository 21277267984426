import {
  Checkbox,
  DefaultButton,
  DialogFooter,
  mergeStyleSets,
} from "@fluentui/react";
import { toast } from "components/FluentToast";
import { FormSpinner } from "components/FormSpinner";
import ComboBoxField from "components/controls/ComboBoxField";
import DatePickerField from "components/controls/DatePickerField";
import GroupPolicyNumberField from "components/controls/GroupPolicyNumberField";
import TextInputField from "components/controls/TextInputField";
import { Formik } from "formik";
import { useChangePaymentMethod } from "hooks/data/mutations/useChangePaymentMethod";
import { delay } from "library/delay";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "store/hooks";
import { createFieldPairValidation } from "store/util";
import { setYupLocale } from "utils/setYupLocale";
import { formatDoubleFieldValue } from "utils/utils";
import * as yup from "yup";
import { setDialog, setFleetWithPremiums } from "../../../store/fleetForm";
import useActionConfig from "../hooks/useActionConfg";
import PremiumTypeDependencyField from "./components/DocumentDateDependencyField";
import FormActionInsurerField from "./components/FormActionInsurerField";
import IsPolicyIssuedDependencyField from "./components/IsPolicyIssuedDependencyField";

const ChangePaymentMethodFormDialog = () => {
  const { t } = useTranslation();
  const [isTradeLicensePlate, setTradeLicensePlate] = useState(false);

  const { mutate: onChangePaymentMethod, isLoading } = useChangePaymentMethod();
  const fleetAppSettings = useSelector((s) => s.fleetForm.fleetAppSettings);
  const actionsConfig = useActionConfig(fleetAppSettings);
  const dispatch = useDispatch();
  const { fleetId } = useParams() as any;
  const close = () => {
    dispatch(setDialog({ type: "", isOpen: false }));
  };
  const mtplGroupPolicyInfo = useSelector(
    (s) => s.fleetForm.fleetWithPremiums.mtplGroupPolicyInfo
  );
  const hullGroupPolicyInfo = useSelector(
    (s) => s.fleetForm.fleetWithPremiums.hullGroupPolicyInfo
  );
  const vehiclePolicyStatusOptions = actionsConfig.vehiclePolicyStatusOptions;
  const changePaymentMethodPremiumTypeOptions =
    actionsConfig.changePaymentMethodPremiumTypeOptions;
  const paymentMethodOptions = actionsConfig.paymentMethodOptions;
  const insurerOptions = actionsConfig.insurerOptions;
  const initialValues = {
    premiumType: null,
    insurerPartnerNumber: null,
    paymentMethod: null,
    validFromDate: new Date(new Date().getFullYear(), 0, 1),
    documentDate: null,
    isPolicyIssued: false,
    reasonForModificationComment: null,
    insurerGroupPolicyNumber: null,
    policyStatus: null,
  };

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    return yup
      .object()
      .shape({
        ...(fleetAppSettings.INCLUDE_GROUP_POLICY && {
          insurerGroupPolicyNumber: yup.mixed().nullable(),
        }),
        premiumType: yup.mixed().required(),
        insurerPartnerNumber: yup.mixed().required(),
        paymentMethod: yup.mixed().required(),
        validFromDate: yup.mixed().required(),
        documentDate: yup.mixed().nullable(),
        isPolicyIssued: yup.mixed().nullable(),
        reasonForModificationComment: yup.string().max(512).nullable(),
        emptyWeightKgFrom: yup.number().nullable(),
        emptyWeightKgTo: yup.number().nullable(),
        powerKwFrom: yup.number().nullable(),
        powerKwTo: yup.number().nullable(),
      })
      .test(
        "emptyWeightKg",
        createFieldPairValidation("emptyWeightKgFrom", "emptyWeightKgTo")
      )
      .test("powerKw", createFieldPairValidation("powerKwFrom", "powerKwTo"))
      .test(
        "listPrice",
        createFieldPairValidation("listPriceMin", "listPriceMax")
      );
  }, [t, fleetAppSettings]);
  const classes = getClassNames();

  const onSubmit = (values) => {
    let body: any = {
      criteria: {
        vehicleTypeCodes: isTradeLicensePlate
          ? null
          : values?.vehicleType?.map((item) => item.value),

        carUsageCodes: values?.carUsage
          ? values?.carUsage?.map((item) => item.value)
          : null,
        weightCapacityKgRangeCodes: values?.weightCapacityKgRange
          ? values?.weightCapacityKgRange?.map((item) => item.value)
          : null,
        maxGrossWeightKgRangeCodes: values?.maxGrossWeightKgRange
          ? values?.maxGrossWeightKgRange?.map((item) => item.value)
          : null,
        powerKws:
          values?.powerKwFrom && values?.powerKwTo
            ? [
                {
                  from: values?.powerKwFrom,
                  to: values?.powerKwTo,
                },
              ]
            : null,
        emptyWeightKgs:
          values?.emptyWeightKgFrom && values?.emptyWeightKgTo
            ? [
                {
                  from: values?.emptyWeightKgFrom,
                  to: values?.emptyWeightKgTo,
                },
              ]
            : null,
        cubicCapacityCcmRangeCodes: values?.cubicCapacityCcmRange
          ? values?.cubicCapacityCcmRange?.map((item) => item.value)
          : null,
        numberOfSeatsRangeCodes: values?.numberOfSeatsRange
          ? values?.numberOfSeatsRange?.map((item) => item.value)
          : null,
        numberOfSeatsMopedCodes: values?.numberOfSeatsMoped
          ? values?.numberOfSeatsMoped?.map((item) => item.value)
          : null,
        listPrices: values?.listPriceType
          ? [
              {
                type: values?.listPriceType?.value
                  ? values?.listPriceType?.value
                  : null,
                min: values?.listPriceMin
                  ? Number(formatDoubleFieldValue(values?.listPriceMin))
                  : undefined,
                max: values?.listPriceMax
                  ? Number(formatDoubleFieldValue(values?.listPriceMax))
                  : undefined,
              },
            ]
          : null,
        isTradeLicensePlate: isTradeLicensePlate,
      },
      premiumType: values?.premiumType.value,
      insurerPartnerNumber: values?.insurerPartnerNumber?.value,
      paymentMethod: values?.paymentMethod.value,
      validFromDate: moment(values?.validFromDate).format("YYYY-MM-DD"),
      documentDate: values?.documentDate
        ? moment(values?.documentDate).format("YYYY-MM-DD")
        : null,
      reasonForModificationComment: values?.reasonForModificationComment,
      isPolicyIssued: values?.isPolicyIssued,
      ...((fleetAppSettings.DEFAULT_COLUMNS.includes("mtplPolicyStatus") ||
        fleetAppSettings.DEFAULT_COLUMNS.includes("hullPolicyStatus")) && {
        policyStatus: values?.policyStatus?.value,
      }),
    };
    if (fleetAppSettings?.INCLUDE_GROUP_POLICY) {
      body = {
        ...body,
        insurerGroupPolicyNumber: values?.insurerGroupPolicyNumber
          ? values?.insurerGroupPolicyNumber.value
          : null,
      };
    }
    onChangePaymentMethod(
      {
        fleetId,
        body: {
          ...body,
        },
      },
      {
        onSuccess: (res) => {
          dispatch(setFleetWithPremiums(res?.data));
          toast.success(t("bfm.success"));
          close();
        },
      }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit(values);
        await delay(400);
        setSubmitting(false);
      }}
    >
      {(props) => {
        const { values, handleSubmit, setFieldValue, handleChange, errors } =
          props;
        const insurerGroupPolicyNumberOptions = () => {
          if (
            values?.premiumType?.code === "HULL" ||
            values?.premiumType?.code === "HULL_BASIC"
          ) {
            return hullGroupPolicyInfo?.map((policyInfo) => ({
              label: policyInfo.groupPolicyNumber,
              value: policyInfo.groupPolicyNumber,
            }));
          }
          if (values?.premiumType?.code === "MTPL") {
            return mtplGroupPolicyInfo?.map((policyInfo) => ({
              label: policyInfo.groupPolicyNumber,
              value: policyInfo.groupPolicyNumber,
            }));
          }
        };
        return (
          <>
            <div className={classes.main}>
              <form onSubmit={handleSubmit}>
                {fleetAppSettings.SHOW_TRADE_LICENSE_PLATES && (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className={classes.fieldItem}>
                      <Checkbox
                        label={t("bfm.tradeLicensePlates.label")}
                        styles={{
                          root: {
                            fontWeight: 600,
                          },
                        }}
                        disabled={false}
                        checked={isTradeLicensePlate}
                        onChange={(ev) => {
                          setFieldValue("vehicleType", null);
                          setFieldValue("carUsage", null);
                          setTradeLicensePlate(!isTradeLicensePlate);
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className={classes.fieldItem}>
                  <ComboBoxField
                    disabled={false}
                    errors={errors}
                    name="premiumType"
                    required
                    label={"bfm.fleet.premiumType.label"}
                    setFieldValue={setFieldValue}
                    options={changePaymentMethodPremiumTypeOptions || []}
                    defaultValue={null}
                    confidentiality={null}
                  />
                </div>
                {fleetAppSettings.INCLUDE_GROUP_POLICY && (
                  <div className={classes.fieldItem}>
                    <GroupPolicyNumberField
                      id="insurerGroupPolicyNumber"
                      setFieldValue={setFieldValue}
                      options={insurerGroupPolicyNumberOptions() || []}
                      defaultValue={values?.insurerGroupPolicyNumber}
                      errors={errors}
                      name={"insurerGroupPolicyNumber"}
                      disabled={false}
                      label={"bfm.fleet.setting.insurerGroupPolicyNumber.label"}
                      confidentiality={null}
                      isClearable={false}
                    />
                  </div>
                )}
                {fleetAppSettings.DEFAULT_COLUMNS.includes(
                  "mtplPolicyStatus"
                ) ||
                fleetAppSettings.DEFAULT_COLUMNS.includes(
                  "hullPolicyStatus"
                ) ? (
                  <div className={classes.fieldItem}>
                    <ComboBoxField
                      disabled={false}
                      errors={errors}
                      name="policyStatus"
                      label={"bfm.vehicleForm.policyStatus.label"}
                      setFieldValue={setFieldValue}
                      options={vehiclePolicyStatusOptions || []}
                      defaultValue={null}
                      confidentiality={null}
                    />
                  </div>
                ) : null}
                <div className={classes.fieldItem}>
                  <FormActionInsurerField
                    actionFormType={"paymentMethodChange"}
                    allInsurerOptions={insurerOptions}
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <ComboBoxField
                  disabled={false}
                  errors={errors}
                  name="paymentMethod"
                  required
                  label={"bfm.fleet.paymentMethod.label"}
                  setFieldValue={setFieldValue}
                  options={paymentMethodOptions || []}
                  defaultValue={null}
                  confidentiality={null}
                />
                <div className={classes.fieldItem}>
                  <DatePickerField
                    errors={errors}
                    setFieldValue={setFieldValue}
                    defaultValue={values.validFromDate}
                    name={"validFromDate"}
                    disabled={false}
                    label={"bfm.vehicleForm.validFromDate.label"}
                    placeholder={t("bfm.selectDate")}
                    required
                    confidentiality={null}
                  />
                </div>
                <PremiumTypeDependencyField
                  premiumTypeSelected={values?.premiumType}
                  classesItem={classes.fieldItem}
                  errors={errors}
                  fieldName={"documentDate"}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <IsPolicyIssuedDependencyField
                  premiumTypeSelected={values?.premiumType}
                  classesItem={classes.fieldItem}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <div className={classes.fieldItem}>
                  <TextInputField
                    value={values.reasonForModificationComment}
                    onChange={handleChange}
                    errors={errors}
                    name={"reasonForModificationComment"}
                    multiline
                    rows={4}
                    disabled={false}
                    label={"bfm.comment.label"}
                  />
                </div>
                <DialogFooter className={classes.footer}>
                  <DefaultButton
                    primary
                    text={t("greco.save")}
                    type="submit"
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </DialogFooter>
                {isLoading && <FormSpinner />}
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const getClassNames = () =>
  mergeStyleSets({
    main: {},
    fieldItem: {
      width: "100%",
      marginTop: 20,
    },
    footer: {
      marginTop: 50,
    },
  });

export default ChangePaymentMethodFormDialog;

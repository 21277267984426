import { transformTaxonomyValue } from "form/util";
import { TaxonomyState } from "store/taxonomy";
import { FleetWithPremiumsResponse } from "types/types";
import * as yup from "yup";

export const fleetFormTabs = {
  fleetTab: "fleet-tab",
  usersTab: "users-tab",
  historyTab: "history-tab",
  insurerDefaultValuesTab: "inusrer-default-values-tab",
  defautlValuesTab: "default-values-tab",
};

export const fieldType = {
  option: "option",
  text: "text",
  number: "number",
  dayMonth: "day-month",
};

export const fleetConfig = (
  fleet: FleetWithPremiumsResponse,
  t: any,
  taxonomy: TaxonomyState,
  insurerList?: any[]
) => {
  //General
  const { fleetTab } = fleetFormTabs;
  const { option, text, number, dayMonth } = fieldType;
  return [
    {
      key: "bfm.fleetForm.fleetName.label",
      name: "fleetName",
      value: fleet?.fleetName,
      validation: yup.string().required(t("greco.required")),
      tab: fleetTab,
      field: text,
      onlyPreview: false,
      hideInPreview: false,
    },
    {
      key: "bfm.fleetForm.fleetPolicyNumber.label",
      name: "fleetPolicyNumber",
      value: fleet?.fleetPolicyNumber,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: text,
      onlyPreview: false,
      hideInPreview: true,
    },
    {
      key: "bfm.fleetForm.fleetLegalPolicyNumber.label",
      name: "fleetLegalPolicyNumber",
      value: fleet?.fleetLegalPolicyNumber,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: text,
      onlyPreview: false,
      hideInPreview: true,
    },
    {
      key: "bfm.fleetForm.fleetComment.label",
      name: "fleetComment",
      value: fleet?.fleetComment,
      validation: yup
        .string()
        .max(2048, `${t("bfm.form.error.maxLength")} 2048`)
        .nullable(),
      tab: fleetTab,
      field: text,
      onlyPreview: false,
      hideInPreview: true,
    },
    {
      key: "bfm.fleetForm.mainMtplInsurerPartnerName.label",
      name: "mainMtplInsurerPartnerNumber",
      value: insurerList
        .filter(
          (e) => e.insurerInternalNumber === fleet?.mainMtplInsurerPartnerNumber
        )
        ?.map((e) => {
          return {
            label: e.insurerName + " (" + e.insurerInternalNumber + ")",
            value: e.insurerInternalNumber,
          };
        })[0],
      validation: yup.mixed().nullable(),
      tab: fleetTab,
      field: option,
      onlyPreview: false,
      hideInPreview: true,
    },
    {
      key: "bfm.fleetForm.mainHullInsurerPartnerName.label",
      name: "mainHullInsurerPartnerNumber",
      value: insurerList
        .filter(
          (e) => e.insurerInternalNumber === fleet?.mainHullInsurerPartnerNumber
        )
        ?.map((e) => {
          return {
            label: e.insurerName + " (" + e.insurerInternalNumber + ")",
            value: e.insurerInternalNumber,
          };
        })[0],
      validation: yup.mixed().nullable(),
      tab: fleetTab,
      field: option,
      onlyPreview: false,
      hideInPreview: true,
    },
    {
      key: "bfm.fleetForm.mainFleetLegalInsurerPartnerName.label",
      name: "mainFleetLegalInsurerPartnerNumber",
      value: insurerList
        .filter(
          (e) =>
            e.insurerInternalNumber ===
            fleet?.mainFleetLegalInsurerPartnerNumber
        )
        ?.map((e) => {
          return {
            label: e.insurerName + " (" + e.insurerInternalNumber + ")",
            value: e.insurerInternalNumber,
          };
        })[0],
      validation: yup.mixed().nullable(),
      tab: fleetTab,
      field: option,
      onlyPreview: false,
      hideInPreview: true,
    },

    {
      key: "bfm.country.label",
      name: "countryCode",
      value: transformTaxonomyValue(fleet?.countryCode, taxonomy, "Country", t)
        ?.label,
      validation: yup.mixed().nullable(),
      tab: fleetTab,
      field: option,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.fleetForm.client.label",
      name: "clientName",
      value: fleet?.clientName,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: text,
      onlyPreview: true,
      hideInPreview: true,
    },
    {
      key: "bfm.fleetForm.webBasePartnerNumber.label",
      name: "webBasePartnerNumber",
      value: fleet?.webBasePartnerNumber.toString(),
      validation: yup.string().required(),
      tab: fleetTab,
      field: number,
      onlyPreview: false,
      hideInPreview: true,
    },

    {
      key: "bfm.fleetForm.client.label",
      name: "clientName",
      value: `${fleet?.clientName} (${fleet?.webBasePartnerNumber})`,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: text,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.fleetForm.fiscalDate.label",
      name: "fiscalDate",
      value: `${fleet?.businessYearStartDay}.${fleet?.businessYearStartMonth}`,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: dayMonth,
      onlyPreview: false,
      hideInPreview: false,
    },
    {
      key: "bfm.fleetForm.dueDate.label",
      name: "dueDate",
      value: `${fleet?.dueDay}.${fleet?.dueMonth}`,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: dayMonth,
      onlyPreview: false,
      hideInPreview: false,
    },
    {
      key: "bfm.fleetForm.businessYearStartMonth.label",
      name: "businessYearStartMonth",
      value: fleet?.businessYearStartMonth,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: text,
      onlyPreview: true,
      hideInPreview: false, // will be hidden by the code
    },
    {
      key: "bfm.fleetForm.businessYearStartDay.label",
      name: "businessYearStartDay",
      value: fleet?.businessYearStartDay,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: text,
      onlyPreview: true,
      hideInPreview: true,
    },
    {
      key: "bfm.vehicleForm.annualPremiumSum.label",
      name: "annualPremiumSum",
      value: fleet?.annualPremiumSum,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: number,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.vehicleForm.proRataPremiumSum.label",
      name: "proRataPremiumSum",
      value: fleet?.proRataPremiumSum,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: number,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.vehicleForm.horsePowerBasedTaxAnnualy.label",
      name: "horsePowerBasedTaxAnnualy",
      value: fleet?.horsePowerBasedTaxAnnualy,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: number,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.vehicleForm.horsePowerBasedTaxProRata.label",
      name: "horsePowerBasedTaxProRata",
      value: fleet?.horsePowerBasedTaxProRata,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: number,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.vehicleForm.mtplAnnualGrossPremium.label",
      name: "mtplAnnualGrossPremium",
      value: fleet?.mtplAnnualGrossPremium,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: number,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.mtplProRataGrossPremium.label",
      name: "mtplProRataGrossPremium",
      value: fleet?.mtplProRataGrossPremium,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: number,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.hullAnnualGrossPremium.label",
      name: "hullAnnualGrossPremium",
      value: fleet?.hullAnnualGrossPremium,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: number,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.hullProRataGrossPremium.label",
      name: "hullProRataGrossPremium",
      value: fleet?.hullProRataGrossPremium,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: number,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.accidentAnnualGrossPremium.label",
      name: "accidentAnnualGrossPremium",
      value: fleet?.accidentAnnualGrossPremium,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: number,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.accidentProRataGrossPremium.label",
      name: "accidentProRataGrossPremium",
      value: fleet?.accidentProRataGrossPremium,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: number,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.assistanceAnnualGrossPremium.label",
      name: "assistanceAnnualGrossPremium",
      value: fleet?.assistanceAnnualGrossPremium,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: number,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.assistanceProRataGrossPremium.label",
      name: "assistanceProRataGrossPremium",
      value: fleet?.assistanceProRataGrossPremium,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: number,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.legalAnnualGrossPremium.label",
      name: "legalAnnualGrossPremium",
      value: fleet?.legalAnnualGrossPremium,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: number,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.legalProRataGrossPremium.label",
      name: "legalProRataGrossPremium",
      value: fleet?.legalProRataGrossPremium,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: number,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.fleetLegalProRataAnnualGrossPremium.label",
      name: "fleetLegalProRataAnnualGrossPremium",
      value: fleet?.fleetLegalProRataAnnualGrossPremium,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: number,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.fleetLegalProRataProRataGrossPremium.label",
      name: "fleetLegalProRataProRataGrossPremium",
      value: fleet?.fleetLegalProRataProRataGrossPremium,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: number,
      onlyPreview: true,
      hideInPreview: false,
    },
    {
      key: "bfm.fleetLegalClosingDateAnnualGrossPremium.label",
      name: "fleetLegalClosingDateAnnualGrossPremium",
      value: fleet?.fleetLegalClosingDateAnnualGrossPremium,
      validation: yup.string().nullable(),
      tab: fleetTab,
      field: number,
      onlyPreview: true,
      hideInPreview: false,
    },
  ];
};

import { DefaultButton, Icon, mergeStyles } from "@fluentui/react";
import { Buffer } from "buffer/";
import { ClearButton, Flex } from "components/styled";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "store/hooks";
import { setImportScanDocumentFile } from "store/vehicle";
import styled from "styled-components";

function Dropzone() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const file = useSelector((s) => s.vehicle?.importScanDocumentDialog?.file);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        const encode = Buffer.from(binaryStr as string).toString("base64");

        dispatch(
          setImportScanDocumentFile({
            base64str: encode,
            name: file.name,
          } as any)
        );
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      noDragEventsBubbling: true,
      noClick: true,
      noKeyboard: true,

      multiple: false,
      onDrop,
    });

  return (
    <Root
      className={mergeStyles({
        accept: isDragAccept,
        reject: isDragReject,
      })}
      {...getRootProps()}
    >
      <input {...(getInputProps() as any)} />
      {file ? (
        <Flex
          direction="column"
          gap={10}
          style={{
            width: "100%",
            overflow: "hidden",
            flexGrow: 1,
          }}
        >
          <Flex
            style={{
              padding: "0 20px",
              width: "100%",
              overflow: "hidden",
              flexGrow: 1,
            }}
            gap={5}
            justify="center"
          >
            <ExcelIcon iconName="Document" />
            <FileName title={file.name}>{file.name}</FileName>
            <ClearButton
              iconProps={{ iconName: "Cancel" }}
              onClick={() => {
                dispatch(setImportScanDocumentFile(null as any));
                // dispatch(setFile(null))}
              }}
            />
          </Flex>
          <DefaultButton
            text={t("bfm.chooseDifferentFile")}
            onClick={() => open()}
          />
        </Flex>
      ) : (
        <Flex direction="column" gap={5}>
          <UploadIcon iconName="CloudUpload" />
          <TitleContainer>
            <Title>{t("bfm.dragFileHere")}</Title>
            <Title>{t("or")}</Title>
          </TitleContainer>
          <DefaultButton text={t("bfm.browseFiles")} onClick={open} />
        </Flex>
      )}
    </Root>
  );
}
const Root = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed ${(p) => p.theme.palette.neutralTertiaryAlt};
  height: auto;
  padding: 20px;
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  flex-direction: column;
  user-select: none;
  &:focus {
    outline: none;
  }
  &.accept {
    background-color: ${(p) => p.theme.palette.themeLighterAlt};
  }
  &.reject {
    background-color: ${(p) => p.theme.palette.redDark};
  }
`;

const TitleContainer = styled.div`
  text-align: center;
`;

const FileName = styled.div`
  font-size: 20px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.div`
  font-size: 20px;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;

const UploadIcon = styled(Icon)`
  margin-left: 10px;
  vertical-align: bottom;
  cursor: pointer;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;
const ExcelIcon = styled(Icon)`
  font-size: 25px;
  color: ${(p) => p.theme.palette.greenDark};
`;

export default React.memo(Dropzone);

function isObjectEqual(obj1, obj2) {
    return (
      Object.keys(obj1).length === Object.keys(obj2).length &&
      Object.keys(obj1).every((key) => obj1[key] === obj2[key])
    );
  }
  
  function isSubset(subset, superset) {
    if (typeof subset === "boolean") {
      return subset === superset;
    }
    if (superset.length === 0) {
      return subset;
    }
    if (subset.length === 0) {
      return superset;
    }
  
    const set2 = new Set(subset);
    const intersection = superset.filter(value => set2.has(value));
    return intersection.length>0;

    // return subset.every((val) => {
    //   if (typeof val === "object") {
    //     return superset.some((supVal) => isObjectEqual(val, supVal));
    //   }
    //   return superset.includes(val);
    // });
  }

function populateOverwrittenCriteria(objects) {
    for (let i = 0; i < objects.length; i++) {
      const current = objects[i];
  
      for (let j = i + 1; j < objects.length; j++) {
        const older = objects[j];
  
        if (
          !(
            current.insurerPartnerNumber === older.insurerPartnerNumber &&
            current.insurerGroupPolicyNumber === older.insurerGroupPolicyNumber &&
            current.premiumType === older.premiumType &&
            current.attributeName === older.attributeName &&
            current.settingCriteria.isTradeLicensePlate === older.settingCriteria.isTradeLicensePlate &&
            new Date(older.validFromDate) <= new Date(current.validFromDate)
          )
        ) {
          continue;
        }
        console.log('current', current.attributeValue, current);
        console.log('older', older.attributeValue, older);
        let allCriteriaMatch = true;
  
        for (const key in current.settingCriteria) {
          if (
            current.settingCriteria.hasOwnProperty(key) &&
            older.settingCriteria.hasOwnProperty(key)
          ) {
            const currentCriteria = current.settingCriteria[key];
            const olderCriteria = older.settingCriteria[key];
  
            if (Array.isArray(currentCriteria) && Array.isArray(olderCriteria)) {
              const isSubsetVal = isSubset(currentCriteria, olderCriteria);
              console.log('currentCriteria', currentCriteria);
              console.log('olderCriteria', olderCriteria);
              console.log('isSubset', isSubsetVal);
              if (!isSubsetVal) {
                allCriteriaMatch = false;
                break;
              }
            } else if (
              typeof currentCriteria === "boolean" &&
              typeof olderCriteria === "boolean"
            ) {
                continue;              
            }
          } else {
            allCriteriaMatch = false;
            break;
          }
        }
        console.log("--------------------")

        if (allCriteriaMatch) {
          for (const key in current.settingCriteria) {
            if (
              current.settingCriteria.hasOwnProperty(key) &&
              older.settingCriteria.hasOwnProperty(key)
            ) {
              const currentCriteria = current.settingCriteria[key];
              const olderCriteria = older.settingCriteria[key];
  
              if (
                Array.isArray(currentCriteria) &&
                Array.isArray(olderCriteria)
              ) {
                older.overwrittenCriteria[key] =
                  older.overwrittenCriteria[key] || [];
                const newOverwrittenCriteria = olderCriteria.filter((val) => {
                  if (typeof val === "object") {
                    return currentCriteria.some((curVal) =>
                      isObjectEqual(val, curVal)
                    );
                  }
                  return currentCriteria.includes(val);
                });
                older.overwrittenCriteria[key] = [
                  ...new Set([
                    ...older.overwrittenCriteria[key],
                    ...newOverwrittenCriteria,
                  ]),
                ];
              } else if (
                typeof currentCriteria === "boolean" &&
                typeof olderCriteria === "boolean"
              ) {
                older.overwrittenCriteria[key] = olderCriteria;
              }
            }
          }
        }
      }
    }
  }
  
  export function markOverwrittenCriteria(objects) {
    const markedObjects = objects.map((obj) => {
      return { ...obj, overwrittenCriteria: {} };
    });
    populateOverwrittenCriteria(markedObjects);
    return markedObjects;
  }
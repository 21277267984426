import { useSelector } from "react-redux";
import { selectIsPhraseToolActivated } from "store/slices/phraseToolSlice";

const usePhraseActivationStatus = () => {
  const isPhraseActivated = useSelector(selectIsPhraseToolActivated);
  if (isPhraseActivated) {
    return { activated: true };
  } else {
    return { activated: false };
  }
};

export default usePhraseActivationStatus;

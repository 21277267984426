import { Icon, TooltipHost } from "@fluentui/react";
import React from "react";

const BookingComment = ({ vehicleIssuingStatusComment }) => {
  return (
    <TooltipHost content={vehicleIssuingStatusComment}>
      <Icon
        iconName="Comment"
        styles={{
          root: {
            cursor: "pointer",
          },
        }}
      />
    </TooltipHost>
  );
};

export default BookingComment;

import AppSettings from "AppSettings";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "store/hooks";
import { useCurrentRoute } from "../../../config/routes";
import * as VehicleSlice from "../../../store/vehicle";

export const useFetchVehicle = () => {
  const dispatch = useDispatch();
  const route = useCurrentRoute();
  const { fleetId, vehicleId } = useParams() as any;
  const vehicleFleetCountryCode = useSelector(
    (s) => s.vehicle?.fleet?.countryCode
  );
  const taxonomy = useSelector((s) => s.taxonomy);

  useEffect(() => {
    if (route === "editTempVehicle") {
      dispatch(
        VehicleSlice.loadTEMPVehicleActions.trigger({
          fleetId,
          vehicleId,
        })
      );
    }
    //
    else if (
      ["editVehicle", "duplicateVehicle", "readOnlyVehicle"].includes(route)
    ) {
      (async () => {
        await dispatch(
          VehicleSlice.loadVehicleWithInsurersActions.trigger({
            fleetId,
            vehicleId,
            isVehiclePage: true,
          })
        );
        // await dispatch(
        //   VehicleSlice.loadFleetWithInsurersActions.trigger({
        //     fleetId,
        //     isVehiclePage: true,
        //   })
        // );
      })();
    }
  }, [route, dispatch, fleetId, vehicleId]);

  useEffect(() => {
    if (!vehicleFleetCountryCode) return;
    const vehicleAppSettingsCountryCode =
      AppSettings.getInstance().getAppSettingsTypes()[
        taxonomy.Country.byId[vehicleFleetCountryCode]?.code
      ];
    dispatch(VehicleSlice.setVehicleAppSettings(vehicleAppSettingsCountryCode));
  }, [taxonomy, vehicleFleetCountryCode]);
};

import React from "react";
import { useFields } from "../../../components/form/useFields";
import { TimelineDialogType } from "../../../types/types";
import { modifyStatusPointDialogConfig } from "./modifyStatusPointDialogConfig";

const config = modifyStatusPointDialogConfig;

interface IProps {
  dialogType: TimelineDialogType;
}

export const ModifyStatusPointDialogContents = ({ dialogType }: IProps) => {
  const { all: vehicleLastStatusDate } = useFields([
    config.vehicleLastStatusDate,
  ]);

  const { all: comment } = useFields([config.comment]);
  const { all: validFromDate } = useFields([config.validFromDate]);
  const { all: validToDate } = useFields([config.validToDate]);
  const { all: reasonForModificationComment } = useFields([
    config.reasonForModificationComment,
  ]);

  let left = <></>;
  let bottom = <></>;
  let right = null;
  if (dialogType === TimelineDialogType.CORRECT_STATUS) {
    left = <>{vehicleLastStatusDate}</>;
    bottom = <>{comment}</>;
  }
  if (dialogType === TimelineDialogType.INSERT_STATUS) {
    left = <>{validFromDate}</>;
    right = <>{validToDate}</>;
    bottom = <>{reasonForModificationComment}</>;
  }
  if (dialogType === TimelineDialogType.DELETE_STATUS) {
    left = <></>;
    bottom = <>{comment}</>;
  }

  if (dialogType === TimelineDialogType.CANCEL_STATUS) {
    left = <></>;
    bottom = <>{comment}</>;
  }

  let dialogContents = (
    <>
      <div className="row">{left}</div>
      <div className="row">{bottom}</div>
    </>
  );
  if (right) {
    dialogContents = (
      <>
        <div className="row">{left}</div>
        <div className="row">{right}</div>
        <div className="row">{bottom}</div>
      </>
    );
  }

  return dialogContents;
};

import { CommandBarButton } from "@fluentui/react";
import { useFormikContext } from "formik";
import { MUTATION_KEYS, QUERY_KEYS } from "hooks/data/queryKeys";
import React from "react";
import { useTranslation } from "react-i18next";
import { useIsFetching, useIsMutating } from "react-query";
import { UserRole } from "types/types";
import { useSelector } from "../../../store/hooks";

const backBtnStyle = {
  root: {
    height: "100%",
  },
};

const InsurerSaveBtn = () => {
  const { t } = useTranslation();
  const { submitForm } = useFormikContext();

  const updateInProgress = !!useIsMutating({
    mutationKey: [MUTATION_KEYS.updateInsurerSettings],
  });

  const createInProgress = !!useIsMutating({
    mutationKey: [MUTATION_KEYS.createInsurerSettings],
  });

  const loadInsurerInProgress = !!useIsFetching({
    queryKey: [QUERY_KEYS.insurerSettings],
  });
  const userRole = useSelector((s) => s.auth.userRole);

  const isLoading =
    loadInsurerInProgress || updateInProgress || createInProgress;

  return (
    <CommandBarButton
      text={t("greco.save")}
      iconProps={{ iconName: "Save" }}
      onClick={() => {
        submitForm();
      }}
      styles={backBtnStyle}
      disabled={isLoading || userRole !== UserRole.admin}
    />
  );
};

export default InsurerSaveBtn;

import {
  mergeStyleSets,
  TooltipHost,
  TooltipOverflowMode,
} from "@fluentui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../config/routes";

const classes = mergeStyleSets({
  fleetName: {
    cursor: "pointer",
    fontWeight: "bold",
  },
});

const FleetName = ({ fleetName, fleetId }) => {
  const navigate = useNavigate();

  const goToFleet = () => {
    navigate(routes.fleet.getPath(fleetId));
  };
  return (
    <TooltipHost content={fleetName} overflowMode={TooltipOverflowMode.Parent}>
      <span className={classes.fleetName} onClick={goToFleet}>
        {fleetName}
      </span>
    </TooltipHost>
  );
};

export default FleetName;

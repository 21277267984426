import { toast } from "components/FluentToast";
import { models, Report } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fleetFormSlice, loadFleetWithInsurersActions } from "store/fleetForm";
import { fleetPageSlice } from "store/fleetPage";
import { useSelector } from "store/hooks";
import { handleAxiosError } from "store/util";
import * as API from "../../store/api/api";
import { getPowerBIEmbedMeta } from "../../store/api/api";
import { AppDispatch } from "../../store/store";
import { PowerBIEmbedMeta } from "../../types/types";
import AnalyticsNavigation from "./AnalyticsNavigation";
import AnalyticsToolbar from "./AnalyticsToolbar";

export const Analytics = () => {
  const [
    embedMetadata = { reportId: null, accessToken: null, embedUrl: "" },
    setEmbedMetadata,
  ] = useState<PowerBIEmbedMeta>();
  const [report, setReport] = useState<Report>();
  const { fleetId } = useParams() as any;
  const dispatch: AppDispatch = useDispatch();
  const [reportType, setReportType] = useState<string>();
  const { t } = useTranslation();

  const fleetName = useSelector((s) => s.fleetPage.fleet?.fleetName);
  const fleetNameFromForm = useSelector(
    (s) => s.fleetForm.fleetWithPremiums?.fleetName
  );

  useEffect(() => {
    return () => {
      dispatch(fleetPageSlice.actions.resetState());
      dispatch(fleetFormSlice.actions.resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!fleetName && !fleetNameFromForm && fleetId) {
      dispatch(
        loadFleetWithInsurersActions.trigger({ fleetId, getById: true })
      );
    }
  }, [fleetId, fleetName, fleetNameFromForm]);

  const refreshToken = useCallback(() => {
    (async () => {
      //let tmid = clientsFilter.join(",");
      let tmid = fleetId;
      const refreshEmbedObj: any = await getPowerBIEmbedMeta(tmid);
      if (report) {
        report.setAccessToken(refreshEmbedObj.data.accessToken);
      }
      setTimeout(refreshToken, 55 * 60 * 1000); // wait 55 minutes before refreshing token
    })();
  }, []);

  useEffect(() => {
    (async () => {
      //let tmid = clientsFilter.join(",");

      let tmid = fleetId;
      const embedObj: any = await getPowerBIEmbedMeta(tmid);
      setEmbedMetadata(embedObj.data);
    })();
    setTimeout(refreshToken, 55 * 60 * 1000); // wait 55 minutes before refreshing token
  }, [fleetId, refreshToken]);

  const captureBookmark = async () => {
    if (report) {
      const reportBookmark = await report.bookmarksManager.capture();
      const pages = await report.getPages();
      let data = null;
      pages.forEach((page) => {
        if (page.isActive) {
          data = {
            extension: reportType,
            state: reportBookmark.state,
            page: page.name,
            pageDisplayName: page.displayName,
          };
        }
      });
      try {
        const res = await API.exportPowerBIReport(data);
        toast.success(t("bfm.analyticsPage.export.confirm.success"));
        return res.data;
      } catch (err) {
        handleAxiosError(err);
      }
    }
  };

  return (
    <>
      <AnalyticsNavigation />
      <AnalyticsToolbar
        captureBookmark={captureBookmark}
        setReportType={setReportType}
      />
      <PowerBIEmbed
        embedConfig={{
          type: "report",
          id: embedMetadata.reportId,
          embedUrl: embedMetadata.embedUrl,
          accessToken: embedMetadata.accessToken,
          tokenType: models.TokenType.Embed,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: true,
          },
        }}
        cssClassName={"analytics-report-class"}
        getEmbeddedComponent={(embeddedReport) => {
          setReport(embeddedReport as Report);
        }}
      />
    </>
  );
};

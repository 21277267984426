export const constants = {
  DEV_BASE_URL: "https://shared-d-we-api.azure-api.net/bfm/bff/v1",
  TEST_BASE_URL: "https://shared-t-we-api.azure-api.net/bfm/bff/v1",
  incorrectIssuingStatusValue: 2,

  editFleet: "editFleet",
  fleet: "fleet",
  createFleet: "createFleet",
  analytics: "analytics",

  editInsurer: "editInsurer",
  insurer: "insurer",
  createInsurer: "createInsurer",

  tradeLicensePlates: "tradeLicensePlates",
  sharedLicensePlate: "sharedLicensePlate",
  fleetAnalytics: "fleetAnalytics",

  createVehicle: "createVehicle",
  editVehicle: "editVehicle",
  createTradeVehicle: "createTradeVehicle",
  duplicateVehicle: "duplicateVehicle",
  editTempVehicle: "editTempVehicle",
  readOnlyVehicle: "readOnlyVehicle",
};

import React from "react";

import FleetNavigation from "components/FleetNavigation";
import styled from "styled-components";

const TradeLicensePlatesNavigation = ({ refExcelExport }) => {
  return (
    <NavigationBar>
      <FleetNavigation refExcelExport={refExcelExport} />
    </NavigationBar>
  );
};

export default TradeLicensePlatesNavigation;

const NavigationBar = styled.div`
  height: 44px;
  background: ${(p) => p.theme.palette.white};
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralLight};
`;

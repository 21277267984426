import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import * as yup from "yup";
import TextInputField from "./controls/TextInputField";

import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import { Formik } from "formik";
import { StyledPrimaryButton } from "./styled";

import useActionConfig from "pages/fleet-form/hooks/useActionConfg";
import { toast } from "react-toastify";
import { setYupLocale } from "utils/setYupLocale";
import { FormSpinner } from "./FormSpinner";
import ComboBoxField from "./controls/ComboBoxField";

import { generateFleetVehicleReport } from "store/api/api";
import { handleAxiosError } from "store/util";
import { PremiumType } from "types/types";

type Props = {
  open: boolean;
  vehicleIds: string[] | number[];
  fleetId: string;
  onClose: () => void;
};

interface IExcelReportResponse {
  name: string;
  url: string;
}

function AppReportFleetLegalClosingDateDialog({
  open,
  vehicleIds,
  fleetId,
  onClose,
}: Props) {
  const taxonomy = useSelector((s) => s.taxonomy);

  const { t } = useTranslation();
  const fleetAppSettings = useSelector((s) => s.fleetForm.fleetAppSettings);
  const { premiumTypeOptions } = useActionConfig(fleetAppSettings);

  const [isLoading, setIsLoading] = useState(false);
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const [excelReportResponse, setExcelReportResponse] =
    useState<IExcelReportResponse | null>(null);

  const vehicles = useSelector((s) => s.fleetPage.entities);
  const insurerList = useSelector((s) => s.fleetForm.insurerList);

  const closingDatePremiumTypeOptions = useMemo(() => {
    return premiumTypeOptions.filter(
      (option) =>
        option.value ===
        taxonomy.PremiumType.byCode[PremiumType.FLEETLEGAL_CLOSINGDATE]?.id
    );
  }, [premiumTypeOptions, taxonomy.PremiumType.byCode]);

  const fleetLegalClosingDateInsurer = useMemo(() => {
    const retVal = [];
    vehicleIds.forEach((vehicleId) => {
      const fleetLegalInsurerPartnerNumber =
        vehicles[vehicleId].fleetLegalInsurerPartnerNumber;
      const isClosingDate = vehicles[vehicleId]?.fleetLegalIsClosingDate;

      if (
        isClosingDate &&
        fleetLegalInsurerPartnerNumber &&
        !retVal.includes(fleetLegalInsurerPartnerNumber)
      )
        retVal.push(fleetLegalInsurerPartnerNumber);
    });

    return retVal.map((el) => {
      const insurer = insurerList.find(
        (ins) => ins.insurerInternalNumber === parseInt(el)
      );
      return {
        value: insurer.insurerInternalNumber,
        label: insurer.insurerName,
      };
    });
  }, [vehicleIds, vehicles, insurerList]);

  const initialValues = useMemo(() => {
    return {
      insurerPartnerNumber: fleetLegalClosingDateInsurer,
      premiumType: closingDatePremiumTypeOptions,
      comment: "",
    };
  }, [closingDatePremiumTypeOptions, fleetLegalClosingDateInsurer]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const payload: any = {
      fleetId: fleetId,
      body: {
        comment: data.comment,
        insurerPartnerNumber: data.insurerPartnerNumber.value,
        reportType: "fleetLegalClosingDate",
        premiumType: data.premiumType?.value,
        vehicleIds: vehicleIds
          .map((vid) => {
            return { fleetId: parseInt(fleetId), vehicleId: parseInt(vid) };
          })
          .filter((item) => {
            return vehicles[item.vehicleId]?.fleetLegalIsClosingDate;
          }),
      },
      onSuccess: () => {
        toast.success(t("bfm.reportGeneratedSuccessfully.label"));
      },
    };
    try {
      const res = await generateFleetVehicleReport(payload);
      setExcelReportResponse(res?.data);
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const schema = useMemo(() => {
    setYupLocale(t);
    return yup.object({
      insurerPartnerNumber: yup.mixed().required(),
      premiumType: yup.mixed().nullable(),
      comment: yup.string().trim().max(512).required(),
    });
  }, [t]);

  return (
    <Dialog
      hidden={!open}
      onDismiss={onClose}
      dialogContentProps={{
        type: DialogType.normal,
        title: t("bfm.fleetLegalApplicationReport.label"),
        showCloseButton: true,
      }}
      minWidth={400}
      modalProps={{
        isBlocking: true,
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={didTrySubmit}
        validateOnBlur={didTrySubmit}
        enableReinitialize
        validationSchema={schema}
      >
        {({ errors, values, handleChange, setFieldValue, handleSubmit }) => {
          return (
            <>
              {excelReportResponse ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "25px",
                  }}
                >
                  <PrimaryButton
                    title={excelReportResponse.name}
                    onClick={() => {
                      window.open(excelReportResponse.url);
                      setExcelReportResponse(null);
                      onClose();
                    }}
                    text={t("bfm.downloadReport.label")}
                  />
                </div>
              ) : (
                <>
                  <ComboBoxField
                    required
                    disabled={true}
                    errors={errors}
                    name="insurerPartnerNumber"
                    label={"bfm.insurer.label"}
                    setFieldValue={setFieldValue}
                    options={fleetLegalClosingDateInsurer}
                    defaultValue={values?.insurerPartnerNumber}
                    confidentiality={null}
                  />
                  <ComboBoxField
                    disabled={true}
                    errors={errors}
                    name="premiumType"
                    label={"bfm.fleet.premiumType.label"}
                    setFieldValue={setFieldValue}
                    options={closingDatePremiumTypeOptions || []}
                    defaultValue={values?.premiumType}
                    confidentiality={null}
                  />

                  <TextInputField
                    required
                    errors={errors}
                    disabled={false}
                    value={values?.comment}
                    onChange={handleChange}
                    name="comment"
                    label={"bfm.comment.label"}
                    multiline
                    rows={4}
                    isRequired
                  />
                  <DialogFooter>
                    <StyledPrimaryButton
                      //   type="submit"
                      onClick={() => {
                        setDidTrySubmit(true);

                        handleSubmit();
                      }}
                      text={t("bfm.generateReport.label")}
                      disabled={isLoading}
                      iconProps={{
                        iconName: isLoading ? "ProgressRingDots" : "",
                      }}
                    />
                    <DefaultButton
                      onClick={onClose}
                      text={t("bfm.close.label")}
                    />
                  </DialogFooter>
                </>
              )}
              {isLoading && <FormSpinner />}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
}

export default AppReportFleetLegalClosingDateDialog;

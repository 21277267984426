import { IFieldConfig } from "../../components/form/types";

export const duplicateWithPremiumsDialogConfig: IFieldConfig = {
  licensePlate: {
    name: "licensePlate",
    type: "text",
    isRequired: true,
    d: ({ t }) => ({
      label: "bfm.duplicateVehicleWithPremiums.licensePlate.label",
    }),
  },
  vin: {
    name: "vin",
    type: "text",
    isRequired: true,
    d: ({ t }) => ({
      label: "bfm.duplicateVehicleWithPremiums.vin.label",
    }),
  },
  mtplPolicyNumber: {
    name: "mtplPolicyNumber",
    type: "text",
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.duplicateVehicleWithPremiums.mtplPolicyNumber.label",
    }),
  },
  hullPolicyNumber: {
    name: "hullPolicyNumber",
    type: "text",
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.duplicateVehicleWithPremiums.hullPolicyNumber.label",
    }),
  },
  certificateNumber: {
    name: "certificateNumber",
    type: "text",
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.duplicateVehicleWithPremiums.certificateNumber.label",
    }),
  },
  nationalCodeId: {
    name: "nationalCodeId",
    type: "text",
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.duplicateVehicleWithPremiums.nationalCodeId.label",
    }),
  },
};

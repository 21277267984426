import { Dialog, DialogFooter, Icon } from "@fluentui/react";
import { Form, Formik } from "formik";
import { useUpdateMultipleVehiclePendingIssuingStatus } from "hooks/data/mutations/useUpdateMultipleVehiclePendingIssuingStatus";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  loadVehiclesActions,
  setChangeVehiclePendingIssuingStatusDialog,
} from "store/fleetPage";
import { useTheme } from "styled-components";
import * as yup from "yup";
import { RefProvider } from "../../contexts/RefProvider";
import { useSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/store";
import { setYupLocale } from "../../utils/setYupLocale";
import { toast } from "../FluentToast";
import { FormSpinner } from "../FormSpinner";
import { useFields } from "../form/useFields";
import { StyledPrimaryButton } from "../styled";
import { config } from "./config";

export const ChangeVehiclePendingIssuingStatusDialogComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const taxonomy = useSelector((s) => s.taxonomy);
  const [didTrySubmit, setDidTrySubmit] = useState(false);

  const { t } = useTranslation();
  const theme = useTheme();
  const phraseActivated = usePhraseActivationStatus().activated;

  const changeVehiclePendingIssuingStatusDialog = useSelector(
    (s) => s.fleetPage.changeVehiclePendingIssuingStatusDialog
  );

  const { vehicle, vehicleIds, fleetId } =
    changeVehiclePendingIssuingStatusDialog;

  const { mutate: onUpdateVehiclePendingIssuingStatus, isLoading } =
    useUpdateMultipleVehiclePendingIssuingStatus();

  const close = () => {
    dispatch(
      setChangeVehiclePendingIssuingStatusDialog({
        isOpen: false,
        fleetId: null,
        vehicle: null,
        vehicleIds: [],
      })
    );
  };

  const schema = useMemo(() => {
    setYupLocale(t);
    return yup.object({
      vehicleIssuingStatusCode: yup.mixed().required(),
      vehicleIssuingSubstatusCode: yup
        .mixed()
        .when("vehicleIssuingStatusCode", {
          is: (s) => s && s.value === 2,
          then: yup.mixed().required(),
          otherwise: yup.mixed(),
        }),
      vehicleIssuingComment: yup.string().max(512).nullable(),
    });
  }, [t]);

  const onSubmit = (data) => {
    if (!vehicle && vehicleIds.length === 0) return;
    if (data.vehicleIssuingStatusCode.value !== 2) {
      data.vehicleIssuingSubstatusCode = undefined;
    }
    if (vehicle) {
      onUpdateVehiclePendingIssuingStatus(
        {
          fleetId: fleetId,
          vehicleIds: [vehicle.vehicleId],
          vehicleIssuingStatusCode: data.vehicleIssuingStatusCode.value,
          vehicleIssuingSubstatusCode: parseInt(
            data?.vehicleIssuingSubstatusCode?.value
          ),
          vehicleIssuingComment: data.vehicleIssuingComment,
        },
        {
          onSuccess: () => {
            dispatch(loadVehiclesActions.trigger({ isReset: true }));
            toast.success(t("bfm.statusChanged"));
            close();
          },
        }
      );
    } else if (vehicleIds.length > 0) {
      onUpdateVehiclePendingIssuingStatus(
        {
          fleetId: fleetId,
          vehicleIds: vehicleIds.map((item) => item.toString()),
          vehicleIssuingStatusCode: data.vehicleIssuingStatusCode.value,
          vehicleIssuingSubstatusCode: parseInt(
            data?.vehicleIssuingSubstatusCode?.value
          ),
          vehicleIssuingComment: data.vehicleIssuingComment,
        },
        {
          onSuccess: () => {
            dispatch(loadVehiclesActions.trigger({ isReset: true }));
            toast.success(t("bfm.statusChanged"));
            close();
          },
        }
      );
    }
  };

  const initialValues = useMemo(() => {
    if (!vehicle) return {};
    let issuingStatusCode =
      taxonomy.VehicleIssuingStatus.byId[vehicle.vehicleIssuingStatusCode];
    let vehicleIssuingStatusCode = issuingStatusCode?.code;
    if (vehicleIssuingStatusCode) {
      vehicleIssuingStatusCode = {
        label: t(issuingStatusCode?.code),
        value: vehicle.vehicleIssuingStatusCode,
      } as any;
    }

    let issuingSubstatusCode =
      taxonomy.VehicleIssuingSubstatus.byId[
        vehicle?.vehicleIssuingSubstatusCode
      ];
    let vehicleIssuingSubstatusCode = issuingSubstatusCode?.code;
    if (vehicleIssuingSubstatusCode) {
      vehicleIssuingSubstatusCode = {
        label: t(issuingSubstatusCode?.code),
        value: vehicle.vehicleIssuingSubstatusCode,
      } as any;
    }
    let vehicleIssuingComment = vehicle?.vehicleIssuingComment;

    return {
      vehicleIssuingStatusCode,
      vehicleIssuingSubstatusCode,
      vehicleIssuingComment,
    };
  }, [vehicle, taxonomy, t]);

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              style={{
                color: theme.palette.themePrimary,
                fontSize: "25px",
                marginRight: "5px",
              }}
            />
            {vehicle
              ? t("bfm.changePendingIssuingStatus.label")
              : t("bfm.changePendingIssuingStatusVehicles.label", {
                  num: vehicleIds.length,
                })}
          </div>
        ),
        showCloseButton: true,
      }}
      minWidth={500}
      onDismiss={close}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <RefProvider>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={didTrySubmit}
          validateOnBlur={didTrySubmit}
          validationSchema={schema}
        >
          {({ submitForm }) => {
            return (
              <Form>
                <Fields />
                <DialogFooter>
                  <StyledPrimaryButton
                    text={t("greco.save")}
                    onClick={() => {
                      setDidTrySubmit(true);
                      submitForm();
                    }}
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </DialogFooter>
                {isLoading && <FormSpinner />}
              </Form>
            );
          }}
        </Formik>
      </RefProvider>
    </Dialog>
  );
};

const Fields = () => {
  const { all } = useFields(config);
  return all;
};

import React from "react";
import { useFields } from "../../form/useFields";
import { modifyBookingDialogConfig } from "./modifyBookingDialogConfig";

const config = modifyBookingDialogConfig;

interface IProps {}

export const ModifyBookingDialogContents = ({}: IProps) => {
  const { all: vehicleIssuingStatusCode } = useFields([
    config.vehicleIssuingStatusCode,
  ]);

  const { all: vehicleIssuingSubstatusCode } = useFields([
    config.vehicleIssuingSubstatusCode,
  ]);
  const { all: vehicleIssuingComment } = useFields([
    config.vehicleIssuingComment,
  ]);

  let left = <></>;
  let right = <></>;
  let bottom = <></>;

  left = <>{vehicleIssuingStatusCode}</>;
  right = <>{vehicleIssuingSubstatusCode}</>;
  bottom = <>{vehicleIssuingComment}</>;

  let dialogContents = (
    <>
      <div className="row">
        <div className="column">{left}</div>
        <div className="column">{right}</div>
      </div>
      <div className="row">{bottom}</div>
    </>
  );

  return dialogContents;
};

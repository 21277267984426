import {
  Checkbox,
  CommandBarButton,
  IconButton,
  IIconProps,
  mergeStyleSets,
  TooltipHost,
} from "@fluentui/react";
import { ColumnOptionsButton } from "@greco/components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { UserRole } from "types/types";
import { ReactComponent as FleetIcon } from "../../assets/Groupfleet.svg";
import { ResultsScore } from "../../components/ResultsScore";
import { HSpace } from "../../components/styled";
import {
  loadVehiclesActions,
  selectIsAnyFiltered,
  setShowTEMP,
  unselectAllVehicles,
} from "../../store/fleetPage";
import { useSelector } from "../../store/hooks";
import { ColumnOptions } from "./ColumnOptions";
import { AddVehicleButton } from "./navigation/AddVehicleButton";
import { Search } from "./Search";

type Props = {
  setClearAllSelectedVehicles: Function;
};

const FleetListToolbar = ({ setClearAllSelectedVehicles }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;
  const loadVehiclesStatus = useSelector((s) => s.fleetPage.loadVehiclesStatus);
  const isLoading = loadVehiclesStatus === "loading";

  const fleet = useSelector((s) => s.fleetForm.fleetWithPremiums);
  const showTEMP = useSelector((s) => s.fleetPage.showTEMP);
  const userRole = useSelector((s) => s.auth.userRole);
  const selectedVehicleIds = useSelector((s) => s.fleetPage.selectedVehicleIds);
  const isAnyColumnFiltered = useSelector(selectIsAnyFiltered);
  const vehiclesTotalCount = useSelector((s) => s.fleetPage.vehiclesTotalCount);

  const [isColumnOptionsOpen, setIsColumnOptionsOpen] = useState(false);

  const vehicleStatusFilter = useSelector(
    (s) => s.fleetPage.vehicleStatusFilter
  );
  const cancelIcon: IIconProps = { iconName: "Cancel" };
  const classes = getClassNames(theme);

  return (
    <div className={classes.container}>
      <FleetIcon />
      <HSpace width={10} />
      <TooltipHost content={t("Refresh")}>
        <IconButton
          iconProps={{ iconName: "Refresh" }}
          onClick={() => {
            dispatch(
              loadVehiclesActions.trigger({
                isReset: true,
                shouldDelay: false,
                shouldEmptyVehicles: true,
              })
            );
          }}
          disabled={isLoading}
        />
      </TooltipHost>
      <HSpace width={20} />
      {userRole !== UserRole.external && (
        <>
          <Checkbox
            label={t("bfm.showTempVehicleList.label")}
            checked={showTEMP}
            onChange={() => dispatch(setShowTEMP(!showTEMP))}
          />
          <HSpace width={20} />
        </>
      )}
      <HSpace width={20} />

      <Search />
      <HSpace width={10} />

      {userRole !== UserRole.adminreadonly && <AddVehicleButton />}

      <div className={classes.columnOptionsAndResultsWrap}>
        {selectedVehicleIds.length > 0 && (
          <>
            <CommandBarButton
              onClick={() => {
                setClearAllSelectedVehicles(true);
                dispatch(unselectAllVehicles());
              }}
              className={classes.cancelBtn}
              iconProps={cancelIcon}
              text={`${selectedVehicleIds.length} selected`}
            />
            <HSpace width={10} />
          </>
        )}
        <ColumnOptionsButton
          isActive={isAnyColumnFiltered}
          onClick={() => {
            setIsColumnOptionsOpen(true);
          }}
          t={t}
        />
        <HSpace width={10} />

        {isColumnOptionsOpen && (
          <ColumnOptions
            countryCode={fleet?.countryCode}
            closePanel={() => setIsColumnOptionsOpen(false)}
          />
        )}
        <ResultsScore score={vehiclesTotalCount} />
      </div>
    </div>
  );
};

const getClassNames = (theme) =>
  mergeStyleSets({
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      background: theme.palette.white,
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
      height: "50px",
      paddingLeft: "10px",
    },
    search: {
      marginLeft: "10px",
    },
    fleetName: {
      fontWeight: "bold",
      color: theme?.isDark ? theme.palette.white : "inherit",
      maxWidth: "100px",
      display: "inline-block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    cancelBtn: {
      display: "flex",
      alignItems: "center",
      height: 32,
      ".ms-Button-icon": {
        height: "inherit",
        display: "flex",
        alignItems: "center",
      },
    },
    columnOptionsAndResultsWrap: {
      marginLeft: "auto",
      paddingRight: "10px",
      display: "flex",
      alignItems: "center",
    },
  });

export default FleetListToolbar;

import { Label } from "@fluentui/react";
import { FieldContainer } from "components/controls/FieldContainer";
import { useField } from "formik";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select, { mergeStyles } from "react-select";

import { useSelector } from "store/hooks";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { formatNumber } from "utils/number";
import { RefContext } from "../../../contexts/RefProvider";
import { getSelectStyles } from "../../../utils/utils";
import { VSpace } from "../../Spacer";
import { TimelineOperationVehicleComboboxFieldConfig } from "../../form/types";
import { useDynamicConfig } from "../../form/useDynamicConfig";
import { ErrorMessage } from "../../styled";

type Props = { fieldConfig: TimelineOperationVehicleComboboxFieldConfig };

export const TimelineOperationVehicleCombobox = ({ fieldConfig }: Props) => {
  const config = {
    ...fieldConfig,
    ...useDynamicConfig(fieldConfig.d),
  };
  const {
    name = "",
    label,
    isVisible = true,
    isDisabled,
    isRequired,
    isSearchable,
    validate,
    renderField,
    background,
  } = config;
  const [field, { error }, { setValue }] = useField({
    name,
    validate,
  });
  const { setRef } = useContext(RefContext);
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;

  const taxonomy = useSelector((s) => s.taxonomy);

  const possibleVehicles = useSelector(
    (s) => s.timeline.dialog.item.extraData.possibleVehicles
  );
  const options = useMemo(() => {
    const options = possibleVehicles?.map((tax) => {
      return {
        value: tax.vehicleId,
        label:
          (tax.brandCode
            ? t(taxonomy.VehicleBrand.byId[tax.brandCode].code) + " "
            : "") +
          (tax.model ? tax.model : "") +
          (tax.vin ? " (" + tax.vin + ") - " : " - ") +
          (tax.horsePowerBasedTaxAnnualy
            ? formatNumber(tax.horsePowerBasedTaxAnnualy) + " \u20AC"
            : "0 \u20AC"),
      };
    });
    return options;
  }, [t, possibleVehicles, taxonomy.VehicleBrand.byId]);

  if (!isVisible) return null;

  const fieldRender = (
    <Select
      menuPosition="fixed"
      id={name}
      options={options as any}
      isSearchable={isSearchable}
      {...field}
      onChange={(value) => setValue(value)}
      styles={mergeStyles(getSelectStyles(!!error, theme), {
        menuPortal: (p) => ({
          ...p,
          background: theme.palette.neutralLighter,
          zIndex: 9999999,
        }),
        menu: (p) => ({
          ...p,
          zIndex: 9999,
          background: theme.palette.neutralLighter,
        }),
        control: (p, state) => {
          return {
            ...p,

            background: state.isDisabled ? "transparent" : theme.palette.white,

            borderBottom: error
              ? `2px solid rgb(164, 38, 44) !important`
              : state.isFocused
              ? `2px solid rgb(0, 90, 161) !important`
              : `1px solid ${theme.palette.black} !important`,
            boxShadow: "none",
            ...(state.isDisabled && {
              borderBottom: `1px solid ${theme.palette.neutralLighter} !important`,
            }),
          };
        },
        option: (p, state) => ({
          ...p,
          background:
            state.isSelected || state.isFocused
              ? theme.palette.neutralLighter
              : theme.palette.white,
          "&:hover": {
            background: theme.palette.neutralLighter,
          },
          color: theme.palette.black,
          fontSize: "14px",
          fontWeight: 400,
          wordWrap: "break-word",
        }),
        input: (p) => ({
          ...p,
          color: (function () {
            if (isDisabled) {
              return theme.palette.neutralSecondaryAlt;
            }
            if (isDarkMode) {
              return "white";
            }
            return theme.palette.black;
          })(),
        }),
        singleValue: (p, state) => ({
          ...p,
          color: theme.isDark
            ? "white"
            : isDisabled
            ? theme.palette.neutralSecondaryAlt
            : "black",
          fontSize: "14px",
          fontWeight: 400,
        }),
        dropdownIndicator: (p, state) => ({
          ...p,
          ...(state.isDisabled && { visibility: "hidden" }),
        }),
        indicatorSeparator: (p, state) => ({
          ...p,
          ...(state.isDisabled && { visibility: "hidden" }),
        }),
      })}
      placeholder={
        isDisabled
          ? ""
          : isSearchable
          ? t("greco.form.searchPlaceholder")
          : t("greco.form.selectPlaceholder")
      }
      noOptionsMessage={() => t("greco.noResults")}
      isDisabled={isDisabled}
      isClearable
      ref={setRef(name) as any}
      inputId={name}
    />
  );

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      {" "}
      <FieldContainer
        isTooltipHidden={isDisabled}
        tooltipText={label}
        isReadOnly={true}
      >
        <Label required={isRequired} disabled={isDisabled}>
          {t(label)}
        </Label>
        {renderField ? renderField({ field: fieldRender }) : fieldRender}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </FieldContainer>
      <VSpace height={10} />
    </div>
  );
};

import { useMutation } from "react-query";
import { DeleteFleetParams, deleteFleet } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

export const deleteFleetFn = async (params: DeleteFleetParams) => {
  return await deleteFleet(params);
};

export function useDeleteFleet() {
  return useMutation(deleteFleet, {
    mutationKey: MUTATION_KEYS.deleteFleet,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { constants } from "library/constants";
import { useAnnualNetPremium } from "pages/vehicle/hooks/useAnnualNetPremium";
import { vehicleFormClasses } from "pages/vehicle/VehicleForm";
import React from "react";
import { useSelector } from "store/hooks";
import { PremiumType } from "types/types";
import FinanceGeneralFields from "./FinanceGenaralFields";
import FinanceTempFields from "./FinanceTempFields/FinanceTempFields";

const FinanceForm = ({ formikProps, vehicle, route }) => {
  const userRole = useSelector((s) => s.auth.userRole);
  const insurerList = useSelector((s) => s.vehicle.insurerList);

  const isTempVehicle = route === constants.editTempVehicle;

  useAnnualNetPremium(
    "PremiumType.MTPL",
    "mtplAnnualGrossPremium",
    "mtplAnnualNetPremium",
    "mtplInsurerPartnerNumber",
    isTempVehicle
  );
  useAnnualNetPremium(
    "PremiumType.HULL",
    "hullAnnualGrossPremium",
    "hullAnnualNetPremium",
    "hullInsurerPartnerNumber",
    isTempVehicle
  );
  useAnnualNetPremium(
    "PremiumType.ACCIDENT",
    "accidentAnnualGrossPremium",
    "accidentAnnualNetPremium",
    "accidentInsurerPartnerNumber",
    isTempVehicle
  );
  useAnnualNetPremium(
    "PremiumType.ASSISTANCE",
    "assistanceAnnualGrossPremium",
    "assistanceAnnualNetPremium",
    "assistanceInsurerPartnerNumber",
    isTempVehicle
  );
  useAnnualNetPremium(
    "PremiumType.LEGAL",
    "legalAnnualGrossPremium",
    "legalAnnualNetPremium",
    "legalInsurerPartnerNumber",
    isTempVehicle
  );
  useAnnualNetPremium(
    PremiumType.FLEETLEGAL_PRORATA,
    "fleetLegalAnnualGrossPremium",
    "fleetLegalAnnualNetPremium",
    "fleetLegalInsurerPartnerNumber",
    isTempVehicle
  );

  return (
    <div className={vehicleFormClasses.container}>
      <FinanceGeneralFields
        formikProps={formikProps}
        route={route}
        vehicle={vehicle}
      />
      {isTempVehicle && (
        <FinanceTempFields
          insurerList={insurerList}
          formikProps={formikProps}
          userRole={userRole}
          route={route}
          vehicle={vehicle}
        />
      )}
    </div>
  );
};

export default FinanceForm;

import { isExternalOrReadonlyAdmin } from "form/util";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../store/hooks";
import { openDialog } from "../../../store/timeline";
import {
  TimelineDialogType,
  TimelineSegmentType,
  TimelineType,
} from "../../../types/types";

export const useOneTimePremiumMenuItems = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const segmentContextMenuItem = useSelector(
    (s) => s.timeline.contextMenu.item
  );
  const segmentContextMenuDate = useSelector(
    (s) => s.timeline.contextMenu.date
  );
  const userRole = useSelector((s) => s.auth.userRole);
  const taxonomy = useSelector((s) => s.taxonomy);
  const appSettings = useSelector((s) => s.timeline.timelineAppSettings);

  const segmentContextMenuItemDomainType = useSelector(
    (s) => s.timeline.contextMenu.item?.domain.type
  );

  const showBookingsWithDocumentDate = useSelector(
    (s) => s.timeline.timeline.showBookingsWithDocumentDate
  );

  return useMemo(() => {
    const items = [];
    const isBookingsVisible = appSettings?.VEHICLE_TIMELINE_TYPES.includes(
      TimelineType.VEHICLE_PREMIUM_BOOKINGS
    );

    if (!showBookingsWithDocumentDate && isBookingsVisible) {
      return [];
    }
    if (
      segmentContextMenuItemDomainType !== TimelineType.VEHICLE_ONE_TIME_PREMIUM
    ) {
      return [];
    }
    if (isExternalOrReadonlyAdmin(userRole)) return [];
    if (
      segmentContextMenuItem.segmentType ===
      TimelineSegmentType.ONE_TIME_PREMIUM
    ) {
      segmentContextMenuItem.extraData.associatedObjects.forEach(
        (associatedObject) => {
          if (
            associatedObject.oneTimePremiumTypeCode ===
            taxonomy.OneTimePremiumType.byCode["OneTimePremiumType.INDEMNITY"]
              .id
          ) {
            items.push({
              key: "change indemnity premium",
              text: t("bfm.changeIndemnityPremium.label"),
              onClick: () => {
                dispatch(
                  openDialog({
                    dialogType: TimelineDialogType.CHANGE_ONETIME_PREMIUM,
                    contextMenuItem: {
                      ...segmentContextMenuItem,
                      associatedObject,
                    },
                    contextMenuDate: segmentContextMenuDate,
                    previousDialogData: "OneTimePremiumType.INDEMNITY",
                  })
                );
              },
            });
            items.push({
              key: "delete indemnity premium",
              text: t("bfm.deleteIndemnityPremium.label"),
              onClick: () => {
                dispatch(
                  openDialog({
                    dialogType: TimelineDialogType.DELETE_ONETIME_PREMIUM,
                    contextMenuItem: {
                      ...segmentContextMenuItem,
                      associatedObject,
                    },
                    contextMenuDate: segmentContextMenuDate,
                    previousDialogData: "OneTimePremiumType.INDEMNITY",
                  })
                );
              },
            });
          } else {
            items.push({
              key: "change bonusmalus",
              text: t("bfm.changeBonusMalus.label"),
              onClick: () => {
                dispatch(
                  openDialog({
                    dialogType: TimelineDialogType.CHANGE_ONETIME_PREMIUM,
                    contextMenuItem: {
                      ...segmentContextMenuItem,
                      associatedObject,
                    },
                    contextMenuDate: segmentContextMenuDate,
                    previousDialogData: "OneTimePremiumType.BONUS_MALUS",
                  })
                );
              },
            });

            items.push({
              key: "delete bonusmalus",
              text: t("bfm.deleteBonusMalus.label"),
              onClick: () => {
                dispatch(
                  openDialog({
                    dialogType: TimelineDialogType.DELETE_ONETIME_PREMIUM,
                    contextMenuItem: {
                      ...segmentContextMenuItem,
                      associatedObject,
                    },
                    contextMenuDate: segmentContextMenuDate,
                    previousDialogData: "OneTimePremiumType.BONUS_MALUS",
                  })
                );
              },
            });
          }
        }
      );
    }
    return items;
  }, [
    dispatch,
    t,
    taxonomy,
    showBookingsWithDocumentDate,
    segmentContextMenuDate,
    segmentContextMenuItem,
    segmentContextMenuItemDomainType,
    userRole,
  ]);
};

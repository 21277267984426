export const required = (value) => {
  if (value === "" || value === null || value === undefined) {
    return "Required";
  }
};

export const maxLength = (value, max: number, t: any) => {
  if (typeof value === "string" && value.length > max) {
    return t("greco.form.errors.string.max", { max });
  }
};

export const exactLength = (value, length: number) => {
  if (typeof value === "string" && value.length !== length) {
    return `Must be exactly ${length} characters`;
  }
};

export const combine = (...arr: any[]) => {
  return arr.find((r) => r !== undefined);
};

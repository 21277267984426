import { useMutation } from "react-query";
import {
  ChangeFleetPaymentMethodRequestParams,
  changeFleetPaymentMethod,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const changePaymentMethod = async (
  params: ChangeFleetPaymentMethodRequestParams
) => {
  return await changeFleetPaymentMethod(params);
};

export function useChangePaymentMethod() {
  return useMutation(changePaymentMethod, {
    mutationKey: MUTATION_KEYS.changeFleetPaymentMethod,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { IconButton, TooltipHost, mergeStyleSets } from "@fluentui/react";
import LoadingButton from "components/LoadingButton";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setDeleteDialog } from "store/insurersPage";
import styled, { useTheme } from "styled-components";
import { formatDateWithMoment } from "utils/utils";

const InsuranceLineHistoryItem = ({
  item,
  props,
  setShowForm,
  refetchInsurerSettings,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const theme = useTheme();
  const classes = mergeStyleSets({
    label: {
      fontSize: "14px",
      fontWeight: "600",
      padding: "5px",
      display: "block",

      color: (function () {
        return theme.palette.neutralSecondaryAlt;
      })(),
    },
    labelAsterix: {
      color: theme.palette.redDark,
    },
  });
  return (
    <Table>
      <thead>
        <tr>
          <th colSpan={5} style={{ textAlign: "left" }}>
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  gap: "3px",
                }}
              >
                <div>
                  {t("bfm.insurerSettings.validFrom.label") +
                    " " +
                    formatDateWithMoment(item.validFromDate)}
                </div>
                <TooltipHost
                  content={t("bfm.InsurerDefaultSettings.edit.label")}
                >
                  <LoadingButton
                    iconProps={{ iconName: "Edit" }}
                    disabled={false}
                    handleClick={async () => {
                      props.setValues({
                        ...item,
                        insurerSettingPerPremiumTypeDetailsHU:
                          item.insurerSettingPerPremiumTypeDetailsHU.map(
                            (item2) => {
                              return {
                                ...item2,
                                maxValueForTaxCalculation: (
                                  item2.maxValueForTaxCalculation + ""
                                ).replace(".", ","),
                                taxPercent: (item2.taxPercent + "").replace(
                                  ".",
                                  ","
                                ),
                                taxExtraPercent1: (
                                  item2.taxExtraPercent1 + ""
                                ).replace(".", ","),
                                // taxExtraPercent2: (
                                //   item2.taxExtraPercent2 + ""
                                // ).replace(".", ","),
                              };
                            }
                          ),

                        validFromDate: moment(item.validFromDate).toDate(),
                      });
                      setShowForm(true);
                    }}
                  />
                </TooltipHost>
                <TooltipHost
                  content={t("bfm.InsurerDefaultSettings.copy.label")}
                >
                  <LoadingButton
                    iconProps={{ iconName: "Copy" }}
                    disabled={false}
                    handleClick={async () => {
                      let obj = {
                        ...item,
                        insurerSettingPerPremiumTypeDetailsHU:
                          item.insurerSettingPerPremiumTypeDetailsHU.map(
                            (el) => {
                              let el2 = {
                                ...el,
                                maxValueForTaxCalculation: (
                                  el.maxValueForTaxCalculation + ""
                                ).replace(".", ","),
                                taxPercent: (el.taxPercent + "").replace(
                                  ".",
                                  ","
                                ),
                                taxExtraPercent1: (
                                  el.taxExtraPercent1 + ""
                                ).replace(".", ","),
                                // taxExtraPercent2: (
                                //   el.taxExtraPercent2 + ""
                                // ).replace(".", ","),
                              };
                              delete el2.id;
                              return el2;
                            }
                          ),
                        validFromDate: null,
                      };
                      delete obj.id;
                      delete obj.isDeleted;
                      delete obj.lastModifiedAt;
                      delete obj.lastModifiedByUserEmail;
                      delete obj.lastModifiedByUserId;
                      delete obj.lastModifiedByUserName;
                      delete obj.rowVersion;
                      delete obj.createdAt;
                      delete obj.createdByUserEmail;
                      delete obj.createdByUserId;
                      delete obj.createdByUserName;
                      props.setValues(obj);

                      setShowForm(true);
                    }}
                  />
                </TooltipHost>
                <TooltipHost
                  content={t("bfm.InsurerDefaultSettings.delete.label")}
                >
                  <IconButton
                    disabled={false}
                    iconProps={{ iconName: "Cancel" }}
                    onClick={() => {
                      dispatch(
                        setDeleteDialog({
                          isOpen: true,
                          insurerSettings: item ?? null,
                          mode: "single",
                          callback: () => {
                            refetchInsurerSettings();
                          },
                        })
                      );
                    }}
                  />
                </TooltipHost>
              </div>
            </>
          </th>
        </tr>

        <tr>
          <th>{t("bfm.insuranceLineDetails.premiumTypeCode.label")}</th>
          <th>{t("bfm.insuranceLineDetails.taxPercent.label")}</th>
          <th>{t("bfm.insuranceLineDetails.taxExtraPercent1.label")}</th>
          {/* <th>{t("bfm.insuranceLineDetails.taxExtraPercent2.label")}</th> */}
          <th>
            {t("bfm.insuranceLineDetails.maxValueForTaxCalculation.label")}
          </th>
        </tr>
      </thead>
      <tbody>
        {item.insurerSettingPerPremiumTypeDetailsHU.map(
          (insuranceLineDetail, index) => (
            <tr key={index}>
              <td>
                <div
                  style={{
                    fontSize: "12px",
                    fontStyle: "italic",
                    color: theme.palette.neutralSecondaryAlt,
                    textAlign: "center",
                  }}
                >
                  {t(insuranceLineDetail.premiumTypeCode)}
                </div>
              </td>
              <td>
                <div className={classes.label} style={{ textAlign: "center" }}>
                  {(insuranceLineDetail.taxPercent + "").replace(".", ",")}
                </div>
              </td>
              <td>
                <div className={classes.label} style={{ textAlign: "center" }}>
                  {(insuranceLineDetail.taxExtraPercent1 + "").replace(
                    ".",
                    ","
                  )}
                </div>
              </td>
              {/* <td>
                {" "}
                {insuranceLineDetail.premiumTypeCode === "PremiumType.MTPL" ? (
                  <div
                    className={classes.label}
                    style={{ textAlign: "center" }}
                  >
                    {(insuranceLineDetail.taxExtraPercent2 + "").replace(
                      ".",
                      ","
                    )}
                  </div>
                ) : null}
              </td> */}
              <td>
                {insuranceLineDetail.premiumTypeCode === "PremiumType.MTPL" ? (
                  <div
                    className={classes.label}
                    style={{ textAlign: "center" }}
                  >
                    {(
                      insuranceLineDetail.maxValueForTaxCalculation + ""
                    ).replace(".", ",")}
                  </div>
                ) : null}
              </td>
            </tr>
          )
        )}
      </tbody>
    </Table>
  );
};

export default InsuranceLineHistoryItem;

export const Table = styled.table`
  border-collapse: collapse;
  // border-top: 1px solid ${(p) => p.theme.palette.neutralLighterAlt};
  width: 100%;
  margin-bottom: 20px;
  td {
    padding: 5px 8px;
  }
  th {
    padding: 5px;
    font-size: 12px;
    font-weight: 650;
    // border-bottom: 1px solid ${(p) => p.theme.palette.neutralLighterAlt};
    height: 42px;
    color: ${(p) => p.theme.palette.neutralPrimary};
  }
  tr {
    border-bottom: 1px solid rgb(237, 235, 233);
  }
`;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isTradeLicensePlate: false,
  loading: false,
};

const tradeLicensePlateSlice = createSlice({
  name: "tradeLicensePlates",
  initialState,
  reducers: {
    setIsTradeLicensePlate: (state, action) => {
      state.isTradeLicensePlate = action.payload;
    },
  },
});

export const selectIsTradeLicensePlate = (state) =>
  state.tradeLicensePlates?.isTradeLicensePlate;

export const { setIsTradeLicensePlate } = tradeLicensePlateSlice.actions;

export default tradeLicensePlateSlice;

import { pointer } from "d3";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "store/hooks";
import { openContextMenu, toggleExpandedPoint } from "store/timeline";
import { TimelineContextMenuType } from "types/types";
import PolicyStatusExpandedPoints from "./PolicyStatusExpandedPoints";
import { PolicyStatusSegmentTooltip } from "./PolicyStatusSegmentTooltip";

const PolicyStatusPoint = ({
  segmentList,
  statusColors,
  xScale,
  yScale,
  expandablePointRefs,
  expandedPointSectionRefs,
}) => {
  const appSettings = useSelector((s) => s.timeline.timelineAppSettings);

  const dispatch = useDispatch();
  const displayedExpandedPoints = useSelector(
    (s) => s.timeline.displayedExpandedPoints
  );

  const lastSegment = segmentList[segmentList.length - 1];
  let x = xScale(lastSegment.startDate);
  let y =
    yScale(lastSegment.domain.value) +
    yScale.bandwidth() / 2 +
    appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2;
  let numSegmentsString = segmentList.length > 1 ? segmentList.length + "" : "";
  if (x < 0) x = 0;

  const deltaY =
    appSettings?.PREMIUM_BAR_HEIGHT / 2 -
    appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2;

  const handleExpandedPointClick = (id) => {
    dispatch(toggleExpandedPoint(id));
  };

  return (
    <g
      key={
        lastSegment.associatedObject && lastSegment.associatedObject?.statusId
          ? lastSegment.associatedObject.statusId
          : new Date().getTime()
      }
    >
      <PolicyStatusSegmentTooltip
        isDisplayed={segmentList.length === 1}
        aggregatedStatusSegmentList={segmentList}
      >
        <g
          id={
            "status4_" +
            (lastSegment.associatedObject &&
            lastSegment.associatedObject?.statusId
              ? lastSegment.associatedObject.statusId
              : new Date().getTime())
          }
          ref={(el) =>
            (expandablePointRefs.current[
              "policystatus_" + lastSegment.associatedObject.statusId
            ] = el)
          }
          onClick={(e) => {
            if (segmentList.length === 1) {
              //openStatusContextMenu
              return dispatch(
                openContextMenu({
                  type: TimelineContextMenuType.POLICY_STATUS_POINT,
                  item: lastSegment,
                  ref: { x: e.pageX, y: e.pageY },
                  date: xScale.invert(pointer(e)[0]),
                })
              );
            } else {
              handleExpandedPointClick(
                "policystatus_" + lastSegment.associatedObject.statusId
              );
            }
          }}
        >
          <rect
            className={"status-point"}
            r="10"
            x={x - appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2}
            y={y - appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2 + deltaY}
            width={appSettings?.VEHICLE_STATUS_BAR_HEIGHT}
            height={appSettings?.VEHICLE_STATUS_BAR_HEIGHT}
            fill={"white"}
            stroke={statusColors[lastSegment.segmentType].strokeColor}
            strokeWidth={statusColors[lastSegment.segmentType].strokeWidth}
          />
          <rect
            className={
              segmentList.length === 1
                ? `policy-status-point-${
                    statusColors[lastSegment.segmentType].pointColor
                  }`
                : "status-point"
            }
            r="5"
            x={x - appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 4}
            y={y - appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 4 + deltaY}
            width={appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2}
            height={appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2}
            fill={segmentList.length === 1 ? "" : "white"}
            stroke={statusColors[lastSegment.segmentType].strokeColor}
            strokeWidth={0}
          />
          <text
            fill={statusColors[lastSegment.segmentType].strokeColor}
            style={{
              textAnchor: "middle",
              fontSize: "0.80em",
              fontWeight: "bold",
              cursor: "pointer",
              pointerEvents: "none",
            }}
            className={`policy-status-point-${
              statusColors[lastSegment.segmentType].pointColor
            }`}
            stroke={"darkslategrey"}
            strokeWidth={0.5}
            x={x}
            dy=".32em"
            y={y + deltaY + 1}
          >
            {numSegmentsString}
          </text>
        </g>
      </PolicyStatusSegmentTooltip>
      {displayedExpandedPoints.includes(
        "policystatus_" + lastSegment.associatedObject.statusId
      ) ? (
        <g
          ref={(el) =>
            (expandedPointSectionRefs.current[
              "policystatus_" + lastSegment.associatedObject.statusId
            ] = el)
          }
        >
          <PolicyStatusExpandedPoints
            segmentList={segmentList}
            statusColors={statusColors}
            x={x}
            y={y + deltaY}
            xScale={xScale}
            yScale={yScale}
          />
        </g>
      ) : null}
    </g>
  );
};

export default PolicyStatusPoint;

import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AppContext, IAppContext } from "../../AppContext";

import { errorHandler } from "../../library/errorHandler";

type Props = {
  errors: any;
  configVehicle: any[];
  isSubmitting: boolean;
};

const VehicleFormErrors = ({ errors, configVehicle, isSubmitting }: Props) => {
  const { t } = useTranslation();

  const appContext = useContext<IAppContext>(AppContext);
  useEffect(() => {
    if (isSubmitting) {
      appContext.filterDuplicates(errors);

      //show error message only on submit (formik is set to validate on blur and change)
      Object.keys(errors).forEach((error) => {
        errorHandler({
          value: `${t(`bfm.vehicleForm.${error}.label`)}: ${errors[error]}`,
          type: "error",
          label: t(`bfm.vehicleForm.${error}.label`),
          fieldName: configVehicle.find((item) => item.name === error).name,
          errors,
        });
      });
    }
  }, [errors, isSubmitting]);

  return null;
};

export default VehicleFormErrors;

import { mergeStyleSets, Spinner, TooltipHost } from "@fluentui/react";
import StatusCircle from "components/StatusCircle";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import { VehicleRedux } from "types/types";
import * as Colors from "../../../config/colors";
import useIsApproveVehiclePendingStatusLoading from "./useIsApproveVehiclePendingStatusLoading";
import useIsCancelVehiclePendingStatusLoading from "./useIsCancelVehiclePendingStatusLoading";
import useIsUpdateVehicleIssuingStatusLoading from "./useIsUpdateVehicleIssuingStatusLoading";
import useIsUpdateVehiclePendingIssuingStatusLoading from "./useIsUpdateVehiclePendingIssuingStatusLoading";
import useIsUpdateVehicleStatusLoading from "./useIsUpdateVehicleStatusLoading";

const StatusCell = ({ vehicle }: { vehicle: VehicleRedux }) => {
  const isUpdateVehicleStatusLoading = useIsUpdateVehicleStatusLoading(
    vehicle?.vehicleId
  );

  const isUpdateVehicleIssuingStatusLoading =
    useIsUpdateVehicleIssuingStatusLoading(vehicle?.vehicleId);

  const isUpdateVehiclePendingIssuingStatusLoading =
    useIsUpdateVehiclePendingIssuingStatusLoading(vehicle?.vehicleId);

  const isApproveVehicleStatusLoading = useIsApproveVehiclePendingStatusLoading(
    vehicle?.vehicleId
  );

  const taxonomy = useSelector((s) => s.taxonomy);
  const { t } = useTranslation();

  const isCancelVehicleStatusLoading = useIsCancelVehiclePendingStatusLoading(
    vehicle?.vehicleId
  );

  const vehicleStatusCodeTax =
    taxonomy.VehicleStatusCode.byId[vehicle.vehicleStatusCode];
  if (!vehicleStatusCodeTax) return null;

  if (
    isUpdateVehicleStatusLoading ||
    isUpdateVehicleIssuingStatusLoading ||
    isApproveVehicleStatusLoading ||
    isUpdateVehiclePendingIssuingStatusLoading ||
    isCancelVehicleStatusLoading
  ) {
    return <Spinner />;
  }

  const isPending = [
    "VehicleStatusCode.PENDING_FOR_ACTIVATION",
    "VehicleStatusCode.PENDING_FOR_SUSPENSION",
    "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
  ].includes(vehicleStatusCodeTax.code);
  const classes = getClassNames(isPending);
  return (
    <div className={classes.container}>
      <TooltipHost content={t(vehicleStatusCodeTax.code)}>
        <StatusCircle color={Colors.vehicleStatus[vehicleStatusCodeTax.code]} />
      </TooltipHost>
    </div>
  );
};

const getClassNames = (isPending) =>
  mergeStyleSets({
    container: {
      border: `1px dashed ${isPending ? "black" : "transparent"}`,
      borderRadius: "16px",
      width: "16px",
      height: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });

export default React.memo(StatusCell);

import React from "react";
import InsurerSettingsCell from "./InsurerSettingsCell";
import Address from "./columnCells/Address";
import InsurerName from "./columnCells/InsurerName";

export const defaultColumns: any[] = [
  {
    key: "insurerName",
    fieldName: "insurerName",
    labelKey: "bfm.insurer.label",
    name: "bfm.insurer.label",
    maxWidth: 270,
    minWidth: 270,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      if (!row) return null;
      return (
        <InsurerName
          insurerName={row?.insurerName}
          insurerInternalNumber={row?.insurerInternalNumber}
        />
      );
    },
  },
  {
    key: "options",
    fieldName: "options",
    labelKey: "",
    name: "",
    maxWidth: 60,
    minWidth: 60,
    isVisible: true,
  },
  {
    key: "street",
    fieldName: "street",
    labelKey: "bfm.address",
    name: "bfm.address",
    maxWidth: 270,
    minWidth: 270,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      if (!row) return null;
      return <Address insurer={row} />;
    },
  },
  {
    key: "insurerSettings",
    fieldName: "insurerSettings",
    labelKey: "bfm.insurerSettings.label",
    name: "bfm.insurerSettings.label",
    maxWidth: 1300,
    minWidth: 500,
    isVisible: true,
    isSortable: false,
    onRender: (row) => {
      if (!row) return null;
      return <InsurerSettingsCell row={row} />;
    },
  },
];

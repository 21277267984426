import { IContextualMenuItem } from "@fluentui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const useChangeIssuingStatusMenuItem = ({
  selectedBookings,
  setChangeIssuingStatusOpen,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { fleetId } = useParams() as any;
  const selectedVehiclesIds = useMemo(() => {
    return selectedBookings?.map((vehicle) => vehicle.vehicleId);
  }, [selectedBookings]);

  const items: IContextualMenuItem[] = [
    {
      key: "changeIssuingStatus",
      text: t("bfm.changeIssuingStatus.label"),
      onClick: () => {
        setChangeIssuingStatusOpen(true);
      },
      disabled: false,
    },
  ];
  return items;
};

export default useChangeIssuingStatusMenuItem;

import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppSettingsType } from "AppSettings";
import { replace } from "connected-react-router";
import { call, fork, put, takeEvery } from "redux-saga/effects";
import { routes } from "../config/routes";
import {
  CreateUpdateFleetData,
  FleetDefaultSetting,
  FleetUserResponse,
  FleetWithPremiumsAndInsurersResponse,
  FleetWithPremiumsResponse,
  Insurer,
  InsurerSettings,
  SharedLicensePlate,
} from "../types/types";
import * as API from "./api/api";
import { LoadStatus } from "./store";
import { createAsyncRoutine, handleAxiosError } from "./util";

const defaultSettingsAdapter = createEntityAdapter<FleetDefaultSetting>({
  selectId: (s) => s.fleetVehicleDefaultSettingId,
});

type FleetFormState = {
  file: File | null;
  users: FleetUserResponse[];
  didTrySubmit: boolean;
  loadFleetLoadStatus: LoadStatus;
  loadFleetUsersLoadStatus: LoadStatus;
  loadInsurerSettingsStatus: LoadStatus;
  loadAllSharedLicesePlates: LoadStatus;
  fleetWithPremiums: FleetWithPremiumsResponse | null;
  fleetAppSettings: AppSettingsType;
  sharedLicensePlateVehicles: SharedLicensePlate[] | null;
  sharedLicensePlateDialog: {
    name: string;
    isOpen: boolean;
    title: string;
    value: any;
    indexOfPlate: number;
    licensePlate: string;
    mainVehicleId: number;
    icon: string;
    color: string;
    reasonForModification: number;
  };
  loadFleetUsersErrorMessage: string | null;
  loadFleetErrorMessage: string | null;

  tempUsers: FleetUserResponse[];
  // tab:
  //   | "fleet-tab"
  //   | "users-tab"
  //   | "history-tab"
  //   | "default-values-tab"
  //   | "sharedLicensePlate";
  tab: string;

  dialog: {
    type: string;
    isOpen: boolean;
    icon?: string;
    title: string;
    color: string;
    data?: {
      [key: string]: any;
    };
  };
  defaultSettings: EntityState<FleetDefaultSetting>;
  loadDefaultSettingsLoadStatus: LoadStatus;

  insurerList: Insurer[];
  insurerSettings: InsurerSettings[] | null;
  loadInsurersLoadStatus: LoadStatus;
  loadInsurersErrorMessage: string | null;

  defaultValueOperation: {
    fieldNames: string[];
    formValues: any;
    results: any;
    noMessage: boolean;
  };
  insurerSettingsOperation: {
    fieldNames: string[];
    formValues: any;
    results: any;
    noMessage: boolean;
  };
  validFromDate: Date;
};

const initSharedLicensePlateDialog = {
  name: "",
  isOpen: false,
  title: "",
  value: null,
  indexOfPlate: null,
  licensePlate: "",
  mainVehicleId: null,
  color: "",
  icon: "",
  reasonForModification: null,
};

const initialState: FleetFormState = {
  file: null,
  users: [],
  didTrySubmit: false,
  loadFleetLoadStatus: "none",
  loadFleetUsersLoadStatus: "none",
  fleetWithPremiums: null,
  fleetAppSettings: null,
  sharedLicensePlateVehicles: null,
  sharedLicensePlateDialog: initSharedLicensePlateDialog,
  loadFleetUsersErrorMessage: null,
  loadFleetErrorMessage: null,

  tempUsers: [],
  tab: "fleet-tab",
  dialog: {
    type: "",
    isOpen: false,
    icon: "",
    title: "",
    color: "",
  },

  defaultSettings: defaultSettingsAdapter.getInitialState(),
  loadDefaultSettingsLoadStatus: "none",
  loadInsurerSettingsStatus: "none",

  insurerList: [],
  insurerSettings: null,
  loadInsurersLoadStatus: "none",
  loadInsurersErrorMessage: null,
  loadAllSharedLicesePlates: "none",
  defaultValueOperation: {
    fieldNames: null,
    formValues: null,
    results: null,
    noMessage: false,
  },
  insurerSettingsOperation: {
    fieldNames: null,
    formValues: null,
    results: null,
    noMessage: false,
  },
  validFromDate: new Date(),
};

export const loadInsurerSettingsActions = createAsyncRoutine<
  { countryCode: string },
  void,
  InsurerSettings[],
  any
>("fleetForm/loadInsurerSettings");

export const loadFleetActions = createAsyncRoutine<
  { fleetId: any },
  void,
  FleetWithPremiumsResponse,
  any
>("fleetForm/load");

export const loadFleetWithInsurersActions = createAsyncRoutine<
  { fleetId: any; getById?: boolean },
  void,
  FleetWithPremiumsAndInsurersResponse,
  any
>("fleetForm/loadFleetWithInsurers");

export const loadFleetUsersActions = createAsyncRoutine<
  { fleetId: any },
  void,
  FleetUserResponse[],
  any
>("fleetForm/loadUsers");

export const loadInsurersActions = createAsyncRoutine<
  number,
  void,
  Insurer[],
  any
>("fleetForm/loadInsurers");

export const createFleetActions = createAsyncRoutine<
  CreateUpdateFleetData,
  void,
  void,
  any
>("fleetForm/create");

export const loadAllSharedLicensePlatesRoutine = createAsyncRoutine<
  { fleetId: any; onSuccess?: () => void; onError?: () => void },
  void,
  SharedLicensePlate[],
  any
>("fleetForm/loadAllSharedLicensePlate");

export const loadSharedLicensePlateVehiclesRoutine = createAsyncRoutine<
  { fleetId: any; onSuccess?: () => void; onError?: () => void },
  void,
  SharedLicensePlate[],
  any
>("fleetForm/loadSharedLicensePlateVehicles");

export const fleetFormSlice = createSlice({
  name: "fleetForm",
  initialState,
  reducers: {
    setDialogDefaultValueOperation: (s, a: PayloadAction<any>) => {
      s.defaultValueOperation.fieldNames = a.payload.fieldNames;
      s.defaultValueOperation.formValues = a.payload.formValues;
      s.defaultValueOperation.results = null;
      s.defaultValueOperation.noMessage = a.payload.noMessage || false;
    },

    setDialogInsurerSettingsOperation: (s, a: PayloadAction<any>) => {
      s.insurerSettingsOperation.fieldNames = a.payload.fieldNames;
      s.insurerSettingsOperation.formValues = a.payload.formValues;
      s.insurerSettingsOperation.results = null;
      s.insurerSettingsOperation.noMessage = a.payload.noMessage || false;
    },
    setDialogDefaultValueOperationResult: (s, a: PayloadAction<any>) => {
      s.defaultValueOperation.results = a.payload;
    },
    setDialogInsurerSettingsOperationResult: (s, a: PayloadAction<any>) => {
      s.insurerSettingsOperation.results = [];
      for (const fieldName in a.payload) {
        s.insurerSettingsOperation.results[fieldName] = a.payload[fieldName];
      }
    },
    setValidFromDate: (s, a: PayloadAction<Date>) => {
      s.validFromDate = a.payload;
    },
    resetDialogDefaultValueOperation: (s, a: PayloadAction<any>) => {
      const fieldName = a.payload;
      const fieldNames = s.defaultValueOperation.fieldNames.filter(
        (a) => a !== fieldName
      );
      let results = { ...s.defaultValueOperation.results };
      delete results[fieldName];

      s.defaultValueOperation = {
        fieldNames: fieldNames,
        formValues: s.defaultValueOperation.formValues,
        results: results,
        noMessage: false,
      };
    },
    resetDialogDefaultValue: (s, a: PayloadAction<any>) => {
      s.defaultValueOperation = {
        fieldNames: null,
        formValues: null,
        results: null,
        noMessage: false,
      };
    },
    resetDialogInsurerSettingsOperation: (s, a: PayloadAction<any>) => {
      const fieldName = a.payload;
      const fieldNames = s.insurerSettingsOperation.fieldNames.filter(
        (a) => a !== fieldName
      );
      let results = { ...s.insurerSettingsOperation.results };
      delete results[fieldName];

      s.insurerSettingsOperation = {
        fieldNames: fieldNames,
        formValues: s.insurerSettingsOperation.formValues,
        results: results,
        noMessage: false,
      };
    },

    resetDialogInsurerSettings: (s, a: PayloadAction<any>) => {
      s.insurerSettingsOperation = {
        fieldNames: null,
        formValues: null,
        results: null,
        noMessage: false,
      };
    },

    setFile: (s, a: PayloadAction<FleetFormState["file"]>) => {
      s.file = a.payload;
    },
    setUsers: (s, a: PayloadAction<FleetFormState["users"]>) => {
      s.users = a.payload;
    },
    setInsurerList: (s, a: PayloadAction<FleetFormState["insurerList"]>) => {
      s.insurerList = a.payload;
    },
    setDidTrySubmit: (s, a: PayloadAction<FleetFormState["didTrySubmit"]>) => {
      s.didTrySubmit = a.payload;
    },

    setSharedLicensePlateVehicles: (
      s,
      a: PayloadAction<FleetFormState["sharedLicensePlateVehicles"]>
    ) => {
      s.sharedLicensePlateVehicles = a.payload;
    },
    setSharedLicensePlateDialog: (
      s,
      a: PayloadAction<FleetFormState["sharedLicensePlateDialog"]>
    ) => {
      s.sharedLicensePlateDialog = a.payload;
    },
    closeSharedLicensePlateDialog: (s) => {
      s.sharedLicensePlateDialog = initSharedLicensePlateDialog;
    },
    setTab: (s, a: PayloadAction<FleetFormState["tab"]>) => {
      s.tab = a.payload;
    },
    setFleetWithPremiums: (
      s,
      a: PayloadAction<FleetFormState["fleetWithPremiums"]>
    ) => {
      s.fleetWithPremiums = a.payload;
    },
    setFleetAppSettings: (
      s,
      a: PayloadAction<FleetFormState["fleetAppSettings"]>
    ) => {
      s.fleetAppSettings = a.payload;
    },
    updateFleetWithPremiums: (s, a: PayloadAction<any>) => {
      s.fleetWithPremiums = {
        ...s.fleetWithPremiums,
        ...a.payload.fleet,
      };
    },
    setFleetTimelineOperationRetVal: (s, a: PayloadAction<any>) => {
      s.sharedLicensePlateVehicles = s.sharedLicensePlateVehicles?.map((item) =>
        item.licensePlate === a.payload.licensePlate ? a.payload : item
      );
    },
    setLoadFleetErrorMessage: (
      s,
      a: PayloadAction<FleetFormState["loadFleetErrorMessage"]>
    ) => {
      s.loadFleetErrorMessage = a.payload;
    },
    setLoadFleetUsersErrorMessage: (
      s,
      a: PayloadAction<FleetFormState["loadFleetUsersErrorMessage"]>
    ) => {
      s.loadFleetUsersErrorMessage = a.payload;
    },
    setTempUsers: (s, a: PayloadAction<FleetFormState["tempUsers"]>) => {
      s.tempUsers = a.payload;
    },
    resetState: (s) => {
      Object.keys(s).forEach((key) => {
        s[key] = initialState[key];
      });
    },
    setDialog: (s, a: PayloadAction<Partial<FleetFormState["dialog"]>>) => {
      s.dialog = { ...s.dialog, ...a.payload };
    },
    resetInsurers: (
      s,
      a: PayloadAction<Partial<FleetFormState["insurerList"]>>
    ) => {
      s.insurerList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadFleetActions.loading, (s) => {
      s.loadFleetLoadStatus = "loading";
    });
    builder.addCase(loadFleetActions.success, (s, a) => {
      s.loadFleetLoadStatus = "success";
      s.fleetWithPremiums = a.payload;
      s.loadFleetErrorMessage = null;
    });
    builder.addCase(loadFleetActions.error, (s, a) => {
      s.loadFleetLoadStatus = "error";
      s.loadFleetErrorMessage = a.payload.toString();
    });

    builder.addCase(loadFleetWithInsurersActions.loading, (s) => {
      s.loadFleetLoadStatus = "loading";
    });
    builder.addCase(loadFleetWithInsurersActions.success, (s, a) => {
      s.loadFleetLoadStatus = "success";
      s.fleetWithPremiums = a.payload.fleet;
      s.insurerList = a.payload.insurers;
      s.loadFleetErrorMessage = null;
    });
    builder.addCase(loadFleetWithInsurersActions.error, (s, a) => {
      s.loadFleetLoadStatus = "error";
      s.loadFleetErrorMessage = a.payload.toString();
    });

    builder.addCase(loadFleetUsersActions.loading, (s) => {
      s.loadFleetUsersLoadStatus = "loading";
    });
    builder.addCase(loadFleetUsersActions.success, (s, a) => {
      s.loadFleetUsersLoadStatus = "success";
      s.users = a.payload;
      s.tempUsers = a.payload;
      s.loadFleetUsersErrorMessage = null;
    });
    builder.addCase(loadFleetUsersActions.error, (s, a) => {
      s.loadFleetUsersLoadStatus = "error";
      s.loadFleetUsersErrorMessage = a.payload.toString();
    });
    builder.addCase(loadInsurersActions.loading, (s) => {
      s.loadInsurersLoadStatus = "loading";
    });
    builder.addCase(loadInsurersActions.success, (s, a) => {
      s.loadInsurersLoadStatus = "success";
      s.insurerList = a.payload;
    });
    builder.addCase(loadInsurersActions.error, (s, a) => {
      s.loadInsurersLoadStatus = "error";
      s.loadInsurersErrorMessage = a.payload.toString();
    });

    builder.addCase(loadAllSharedLicensePlatesRoutine.error, (s, a) => {
      s.loadAllSharedLicesePlates = "error";
    });
    builder.addCase(loadAllSharedLicensePlatesRoutine.loading, (s) => {
      s.loadAllSharedLicesePlates = "loading";
    });
    builder.addCase(loadAllSharedLicensePlatesRoutine.success, (s, a) => {
      s.loadAllSharedLicesePlates = "success";
      s.sharedLicensePlateVehicles = a.payload;
    });

    builder.addCase(loadInsurerSettingsActions.loading, (s, a) => {
      s.loadInsurerSettingsStatus = "loading";
    });
    builder.addCase(loadInsurerSettingsActions.success, (s, a) => {
      s.loadInsurerSettingsStatus = "success";
      s.insurerSettings = a.payload;
    });
    builder.addCase(loadInsurerSettingsActions.error, (s, a) => {
      s.loadInsurerSettingsStatus = "error";
    });
  },
});

export const {
  actions: {
    setFile,
    setUsers,
    setInsurerList,
    setDidTrySubmit,
    setSharedLicensePlateDialog,
    setTab,
    setFleetWithPremiums,
    updateFleetWithPremiums,
    setLoadFleetErrorMessage,
    setLoadFleetUsersErrorMessage,
    setTempUsers,
    resetState,
    setDialog,
    setFleetAppSettings,
    resetInsurers,
    setSharedLicensePlateVehicles,
    setFleetTimelineOperationRetVal,
    setDialogDefaultValueOperation,
    setDialogDefaultValueOperationResult,
    setDialogInsurerSettingsOperation,
    setDialogInsurerSettingsOperationResult,
    resetDialogDefaultValueOperation,
    resetDialogInsurerSettingsOperation,
    resetDialogDefaultValue,
    resetDialogInsurerSettings,
    setValidFromDate,
  },
  reducer: fleetFormReducer,
} = fleetFormSlice;

export function* loadFleetSaga() {
  yield takeEvery(
    loadFleetActions.trigger,
    function* (a: ReturnType<typeof loadFleetActions.trigger>) {
      const { fleetId } = a.payload;
      try {
        yield put(loadFleetActions.loading());
        const res = yield call(API.getFleetWithPremiums, fleetId);
        yield put(loadFleetActions.success(res.data));
      } catch (err: any) {
        yield put(loadFleetActions.error(err));
        handleAxiosError(err);
        if (err.response.status === 403) {
          yield put(replace(routes.fleets.getPath()));
        }
      }
    }
  );
}

export function* loadFleetWithInsurersSaga() {
  yield takeEvery(
    loadFleetWithInsurersActions.trigger,
    function* (a: ReturnType<typeof loadFleetWithInsurersActions.trigger>) {
      const { fleetId, getById } = a.payload;
      try {
        yield put(loadFleetWithInsurersActions.loading());
        let res;
        if (getById) {
          res = yield call(API.GetFleetById, fleetId);
        } else {
          res = yield call(API.getFleetWithPremiums, fleetId);
        }
        const insurers = yield call(
          API.getInsurersByCountryId,
          res.data.countryCode
        );
        yield put(
          loadFleetWithInsurersActions.success({
            fleet: res.data,
            insurers: insurers.data,
          })
        );
      } catch (err: any) {
        yield put(loadFleetWithInsurersActions.error(err));
        handleAxiosError(err);
        if (err.response.status === 403) {
          yield put(replace(routes.fleets.getPath()));
        }
      }
    }
  );
}

export function* loadFleetUsersSaga() {
  yield takeEvery(
    loadFleetUsersActions.trigger,
    function* (a: ReturnType<typeof loadFleetUsersActions.trigger>) {
      const { fleetId } = a.payload;
      try {
        yield put(loadFleetUsersActions.loading());
        const res = yield call(API.getFleetUsers, fleetId);
        yield put(loadFleetUsersActions.success(res.data));
      } catch (err: any) {
        yield put(loadFleetUsersActions.error(err));
        handleAxiosError(err);
        if (err.response.status === 403) {
          yield put(replace(routes.fleets.getPath()));
        }
      }
    }
  );
}

export function* loadInsurersSaga() {
  yield takeEvery(
    loadInsurersActions.trigger,
    function* (a: ReturnType<typeof loadInsurersActions.trigger>) {
      const countryId = a.payload;
      try {
        yield put(loadInsurersActions.loading());
        const insurers = yield call(API.getInsurersByCountryId, countryId);
        yield put(loadInsurersActions.success(insurers.data));
      } catch (err: any) {
        if (err.response.status === 404) {
          const insurers = [];
          yield put(loadInsurersActions.success(insurers));
          return;
        }
        yield put(loadInsurersActions.error(err));
        handleAxiosError(err);
        if (err.response.status === 403) {
          yield put(replace(routes.fleets.getPath()));
        }
      }
    }
  );
}

export function* loadAllSharedLicensePlatesSaga() {
  yield takeEvery(
    loadAllSharedLicensePlatesRoutine.trigger,
    function* (
      a: ReturnType<typeof loadAllSharedLicensePlatesRoutine.trigger>
    ) {
      const { fleetId, onSuccess, onError } = a.payload;
      try {
        yield put(loadAllSharedLicensePlatesRoutine.loading());
        const res = yield call(API.getAllSharedLicensePlates, fleetId);
        yield put(loadAllSharedLicensePlatesRoutine.success(res.data));
        onSuccess && onSuccess();
      } catch (err) {
        yield put(loadAllSharedLicensePlatesRoutine.error(err));
        handleAxiosError(err);
        onError && onError();
      }
    }
  );
}

export function* loadInsurerSettingsSaga() {
  yield takeEvery(
    loadInsurerSettingsActions.trigger,
    function* (a: ReturnType<typeof loadInsurerSettingsActions.trigger>) {
      const { countryCode } = a.payload;
      try {
        yield put(loadInsurerSettingsActions.loading());
        const insurers = yield call(API.getInsurers, countryCode);
        yield put(loadInsurerSettingsActions.success(insurers.data));
      } catch (err: any) {
        if (err.response.status === 404) {
          const insurers = [];
          yield put(loadInsurerSettingsActions.success(insurers));
          return;
        }
        yield put(loadInsurerSettingsActions.error(err));
        handleAxiosError(err);
        if (err.response.status === 403) {
          yield put(replace(routes.insurers.getPath()));
        }
      }
    }
  );
}

export function* fleetFormSaga() {
  yield fork(loadFleetSaga);
  yield fork(loadInsurersSaga);
  yield fork(loadFleetWithInsurersSaga);
  yield fork(loadFleetUsersSaga);
  yield fork(loadAllSharedLicensePlatesSaga);
  yield fork(loadInsurerSettingsSaga);
}

import CheckboxField from "components/controls/CheckboxField";
import ComboBoxField from "components/controls/ComboBoxField";
import DatePickerField from "components/controls/DatePickerField";
import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import NumberInputField from "components/controls/NumberInputField";
import TextInputField from "components/controls/TextInputField";
import { vehicleFormClasses } from "pages/vehicle/VehicleForm";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import {
  isDisabledExternal,
  isDisabledExternalOrHasBeenActivated,
} from "../../../helpers";
import useInsurerList from "./hooks/useInsurerList";

const FinanceTempHull = ({
  formikProps,
  userRole,
  route,
  vehicle,
  insurerList,
}) => {
  const { t } = useTranslation();
  const { setFieldValue, handleChange, values, errors } = formikProps;
  const taxonomy = useSelector((s) => s.taxonomy);
  const vehicleAppSettings = useSelector((s) => s.vehicle.vehicleAppSettings);

  const paymentMethodOptions = useMemo(() => {
    return taxonomy?.PaymentMethod?.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy?.PaymentMethod?.items]);

  const policyStatusOptions = useMemo(() => {
    return taxonomy?.VehiclePolicyStatusCode?.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy?.VehiclePolicyStatusCode?.items]);

  const paymentMeanOptions = useMemo(() => {
    return taxonomy?.PremiumPaymentMean?.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy?.PremiumPaymentMean?.items]);

  const insurerData = useInsurerList({
    insurerList,
    values,
    name: "hullInsurerPartnerNumber",
    subName: "hullInsurerBranchOffice",
    setFieldValue,
  });

  return (
    <>
      <div className={vehicleFormClasses.item}>
        <CheckboxField
          checked={values?.hullIsBasic}
          errors={errors}
          setFieldValue={setFieldValue}
          name={"hullIsBasic"}
          disabled={false}
          label={"bfm.vehicleList.hullIsBasic.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "hullIsBasic"
          )}
        />
      </div>

      <div className={vehicleFormClasses.item}>
        <DatePickerField
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values.hullLastStartDate}
          name={"hullLastStartDate"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.startDate.label"}
          placeholder={t("bfm.selectDate")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "hullLastStartDate"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <DatePickerField
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values.hullImportEndDate}
          name={"hullImportEndDate"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.endDate.label"}
          placeholder={t("bfm.selectDate")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "hullImportEndDate"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <DatePickerField
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values.hullPolicyCancelationDocumentDate}
          name={"hullPolicyCancelationDocumentDate"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.cancelationDocumentDate.label"}
          placeholder={t("bfm.selectDate")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "hullPolicyCancelationDocumentDate"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          errors={errors}
          name="hullInsurerPartnerNumber"
          label={"bfm.vehicleForm.hullInsurerPartnerNumber.label"}
          setFieldValue={setFieldValue}
          options={insurerData.insurerOptions}
          defaultValue={values?.hullInsurerPartnerNumber}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "hullInsurerPartnerNumber"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          errors={errors}
          name="hullInsurerBranchOffice"
          label={"bfm.vehicleForm.insurerBranchOffice.label"}
          setFieldValue={setFieldValue}
          options={insurerData.insurerBranchOfficeOptions}
          defaultValue={values?.hullInsurerBranchOffice}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "hullInsurerBranchOffice"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <TextInputField
          value={values.hullPolicyNumber}
          onChange={handleChange}
          errors={errors}
          name={"hullPolicyNumber"}
          disabled={isDisabledExternal({ userRole, route })}
          label={"bfm.vehicleForm.hullPolicyNumber.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "hullPolicyNumber"
          )}
        />
      </div>
      {vehicleAppSettings.DEFAULT_COLUMNS.includes("hullGroupPolicyNumber") ? (
        <div className={vehicleFormClasses.item}>
          <TextInputField
            value={values.hullGroupPolicyNumber}
            onChange={handleChange}
            errors={errors}
            name={"hullGroupPolicyNumber"}
            // multiline
            // rows={4}
            disabled={isDisabledExternal({ userRole, route })}
            label={"bfm.hullGroupPolicyNumber"}
            isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
              "hullGroupPolicyNumber"
            )}
          />
        </div>
      ) : null}
      {vehicleAppSettings.DEFAULT_COLUMNS.includes("hullPolicyStatus") ? (
        <div className={vehicleFormClasses.item}>
          <ComboBoxField
            disabled={isDisabledExternal({ userRole, route })}
            errors={errors}
            name="hullPolicyStatus"
            label={"bfm.vehicleForm.hullPolicyStatus.label"}
            setFieldValue={setFieldValue}
            options={policyStatusOptions}
            defaultValue={values.hullPolicyStatus}
            isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
              "hullPolicyStatus"
            )}
            confidentiality={null}
          />
        </div>
      ) : null}
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          errors={errors}
          name="hullPaymentMethod"
          label={"bfm.vehicleForm.paymentMethod.label"}
          setFieldValue={setFieldValue}
          options={paymentMethodOptions}
          defaultValue={values.hullPaymentMethod}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "hullPaymentMethod"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          errors={errors}
          name="hullPaymentMean"
          label={"bfm.vehicleForm.paymentMean.label"}
          setFieldValue={setFieldValue}
          options={paymentMeanOptions}
          defaultValue={values.hullPaymentMean}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "hullPaymentMean"
          )}
          confidentiality={null}
        />
      </div>

      <div className={vehicleFormClasses.item}>
        <DoubleNumberInputField
          setFieldValue={setFieldValue}
          value={values?.hullAnnualGrossPremium}
          onChange={handleChange}
          errors={errors}
          name={"hullAnnualGrossPremium"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.annualGrossPremium.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "hullAnnualGrossPremium"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <DoubleNumberInputField
          setFieldValue={setFieldValue}
          value={values?.hullAnnualNetPremium}
          onChange={handleChange}
          errors={errors}
          name={"hullAnnualNetPremium"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.annualNetPremium.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "hullAnnualNetPremium"
          )}
        />
      </div>
      {vehicleAppSettings.SUSPENSION_DAYS_INCLUDED ? (
        <div className={vehicleFormClasses.item}>
          <NumberInputField
            setFieldValue={setFieldValue}
            value={values.hullSuspensionDays}
            onChange={handleChange}
            errors={errors}
            name={"hullSuspensionDays"}
            disabled={isDisabledExternalOrHasBeenActivated({
              route,
              vehicle,
              userRole,
            })}
            label={"bfm.vehicleForm.hullSuspensionDays.label"}
            isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
              "hullSuspensionDays"
            )}
          />
        </div>
      ) : null}
      {vehicleAppSettings.ACTIVATION_DAYS_INCLUDED ? (
        <div className={vehicleFormClasses.item}>
          <NumberInputField
            setFieldValue={setFieldValue}
            value={values.hullActivationDays}
            onChange={handleChange}
            errors={errors}
            name={"hullActivationDays"}
            disabled={isDisabledExternalOrHasBeenActivated({
              route,
              vehicle,
              userRole,
            })}
            label={"bfm.vehicleForm.hullActivationDays.label"}
            isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
              "hullActivationDays"
            )}
          />
        </div>
      ) : null}
      {vehicleAppSettings.SUSPENSION_DAYS_INCLUDED ? (
        <div className={vehicleFormClasses.item}>
          <NumberInputField
            setFieldValue={setFieldValue}
            value={values.hullSuspensionDaysLimit}
            onChange={handleChange}
            errors={errors}
            isNegativeAllowed={true}
            name={"hullSuspensionDaysLimit"}
            disabled={isDisabledExternalOrHasBeenActivated({
              route,
              vehicle,
              userRole,
            })}
            label={"bfm.vehicleForm.hullSuspensionDaysLimit.label"}
            isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
              "hullSuspensionDaysLimit"
            )}
          />
        </div>
      ) : null}
      {vehicleAppSettings.GARAGE_RISK_DISCOUNT_PERCENT_INCLUDED ? (
        <div className={vehicleFormClasses.item}>
          <DoubleNumberInputField
            setFieldValue={setFieldValue}
            value={values?.hullGarageRiskDiscountPercent}
            onChange={handleChange}
            errors={errors}
            name={"hullGarageRiskDiscountPercent"}
            disabled={isDisabledExternalOrHasBeenActivated({
              route,
              vehicle,
              userRole,
            })}
            label={"bfm.vehicleForm.hullGarageRiskDiscountPercent.label"}
            isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
              "hullGarageRiskDiscountPercent"
            )}
          />
        </div>
      ) : null}
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.hullDueMonth}
          onChange={handleChange}
          errors={errors}
          name={"hullDueMonth"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.hullDueMonth.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "hullDueMonth"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.hullDueDay}
          onChange={handleChange}
          errors={errors}
          name={"hullDueDay"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.hullDueDay.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "hullDueDay"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <DatePickerField
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values.hullPolicyDocumentDate}
          name={"hullPolicyDocumentDate"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.documentDate.label"}
          placeholder={t("bfm.selectDate")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "hullPolicyDocumentDate"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.fullWidthFormItem}>
        <CheckboxField
          checked={values?.hullIsPolicyIssued}
          errors={errors}
          setFieldValue={setFieldValue}
          name={"hullIsPolicyIssued"}
          disabled={isDisabledExternalOrHasBeenActivated({
            userRole,
            route,
            vehicle,
          })}
          label={"bfm.vehicleForm.hullIsPolicyIssued.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "hullIsPolicyIssued"
          )}
        />
      </div>
      <div className={vehicleFormClasses.fullWidthFormItem}>
        <TextInputField
          value={values.hullComment}
          onChange={handleChange}
          errors={errors}
          name={"hullComment"}
          multiline
          rows={4}
          disabled={isDisabledExternal({ userRole, route })}
          label={"bfm.vehicleForm.hullComment.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "hullComment"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <CheckboxField
          style={{ width: "100%" }}
          checked={values?.grossNegligence}
          errors={errors}
          setFieldValue={setFieldValue}
          name={"grossNegligence"}
          disabled={isDisabledExternal({ userRole, route })}
          label={"bfm.vehicleForm.grossNegligence.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "grossNegligence"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <CheckboxField
          style={{ width: "100%" }}
          checked={values?.electroPlusPackage}
          errors={errors}
          setFieldValue={setFieldValue}
          name={"electroPlusPackage"}
          disabled={isDisabledExternal({ userRole, route })}
          label={"bfm.vehicleForm.electroPlusPackage.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "electroPlusPackage"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <CheckboxField
          checked={values?.replacementValue}
          errors={errors}
          setFieldValue={setFieldValue}
          name={"replacementValue"}
          disabled={isDisabledExternal({ userRole, route })}
          label={"bfm.vehicleForm.replacementValue.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "replacementValue"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <CheckboxField
          checked={values?.gap}
          errors={errors}
          setFieldValue={setFieldValue}
          name={"gap"}
          disabled={isDisabledExternal({ userRole, route })}
          label={"bfm.vehicleForm.gap.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes("gap")}
        />
      </div>
      {vehicleAppSettings.INCLUDE_HULL_PRODUCT ? (
        <div className={vehicleFormClasses.item}>
          <TextInputField
            value={values.hullProduct}
            onChange={handleChange}
            errors={errors}
            name={"hullProduct"}
            disabled={isDisabledExternal({ userRole, route })}
            label={"bfm.vehicleForm.hullProduct.label"}
            isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
              "hullProduct"
            )}
          />
        </div>
      ) : null}
      {vehicleAppSettings.INCLUDE_HULL_DEDUCTIBLE ? (
        <>
          <div className={vehicleFormClasses.item}>
            <NumberInputField
              setFieldValue={setFieldValue}
              value={values.hullDeductiblePercent}
              onChange={handleChange}
              errors={errors}
              name={"hullDeductiblePercent"}
              disabled={isDisabledExternal({ userRole, route })}
              label={"bfm.vehicleForm.hullDeductiblePercent.label"}
              isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullDeductiblePercent"
              )}
            />
          </div>
          <div className={vehicleFormClasses.item}>
            <DoubleNumberInputField
              setFieldValue={setFieldValue}
              value={values.hullDeductibleMinAmount}
              onChange={handleChange}
              errors={errors}
              name={"hullDeductibleMinAmount"}
              disabled={isDisabledExternal({ userRole, route })}
              label={"bfm.vehicleForm.hullDeductibleMinAmount.label"}
              isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullDeductibleMinAmount"
              )}
            />
          </div>
          <div className={vehicleFormClasses.item}>
            <DoubleNumberInputField
              value={values.hullDeductibleMaxAmount}
              setFieldValue={setFieldValue}
              onChange={handleChange}
              errors={errors}
              name={"hullDeductibleMaxAmount"}
              disabled={isDisabledExternal({ userRole, route })}
              label={"bfm.vehicleForm.hullDeductibleMaxAmount.label"}
              isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullDeductibleMaxAmount"
              )}
            />
          </div>
          <div className={vehicleFormClasses.fullWidthFormItem}>
            <TextInputField
              value={values.hullDeductibleComment}
              onChange={handleChange}
              errors={errors}
              name={"hullDeductibleComment"}
              multiline
              rows={4}
              disabled={isDisabledExternal({ userRole, route })}
              label={"bfm.vehicleForm.hullDeductibleComment.label"}
              isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
                "hullDeductibleComment"
              )}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default FinanceTempHull;

import { useApproveVehiclePendingStatus } from "hooks/data/mutations/useApproveVehiclePendingStatus";
import { MUTATION_KEYS } from "hooks/data/queryKeys";
import React from "react";
import { useTranslation } from "react-i18next";
import { useIsMutating } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "../../../../components/FluentToast";
import {
  loadVehiclesActions,
  setCancelVehicleStatusDialog,
  setChangeVehicleIssuingStatusDialog,
  setChangeVehicleStatusDialog,
} from "../../../../store/requireActionVehicles";
import { AppDispatch } from "../../../../store/store";
import ContextualMenuItem from "./ContextualMenuItem";

type Props = {
  vehicle: any;
  target: any;
  onDismiss: Function;
};

const RequiredVehicleContextMenu = ({ vehicle, target, onDismiss }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const isUpdateVehicleStatusLoading = !!useIsMutating({
    mutationKey: [MUTATION_KEYS.changeVehicleStatus],
  });

  const {
    mutate: onApproveVehiclePendingStatus,
    isLoading: isApproveVehicleStatusLoading,
  } = useApproveVehiclePendingStatus();

  const onClickChangeStatus = () => {
    dispatch(
      setChangeVehicleStatusDialog({
        isOpen: true,
        fleetId: vehicle.fleetId,
        vehicleIds: [Number(vehicle.vehicleId)],
        vehicle,
      })
    );
  };

  const onClickChangeIssuingStatus = () => {
    dispatch(
      setChangeVehicleIssuingStatusDialog({
        isOpen: true,
        fleetId: vehicle.fleetId,
        vehicle,
      })
    );
  };

  // const onClickCorrectStatusDate = () => {
  //   dispatch(
  //     setCorrectStatusDateDialog({
  //       isOpen: true,
  //       vehicle,
  //     })
  //   );
  // };

  const onClickApproveStatus = () => {
    console.log("click 2");
    return;
    onApproveVehiclePendingStatus(
      {
        fleetId: vehicle.fleetId,
        vehicleId: vehicle.vehicleId,
      },
      {
        onSuccess: () => {
          dispatch(loadVehiclesActions.trigger({ isReset: true }));

          toast.success(t("bfm.success"));
        },
      }
    );
  };

  const onClickCancelStatus = () => {
    dispatch(
      setCancelVehicleStatusDialog({
        fleetId: vehicle.fleetId,
        vehicleId: vehicle.vehicleId,
        isOpen: true,
      })
    );
  };

  return (
    <ContextualMenuItem
      onDismiss={onDismiss}
      fleetId={vehicle.fleetId}
      target={target}
      vehicle={vehicle}
      onClickChangeStatus={onClickChangeStatus}
      onClickChangeIssuingStatus={onClickChangeIssuingStatus}
      // onClickCorrectStatusDate={onClickCorrectStatusDate}
      onClickApproveStatus={onClickApproveStatus}
      onClickCancelStatus={onClickCancelStatus}
      isApproveVehicleStatusLoading={isApproveVehicleStatusLoading}
      isUpdateVehicleStatusLoading={isUpdateVehicleStatusLoading}
    />
  );
};

export default React.memo(RequiredVehicleContextMenu);

import React, { useState } from "react";
import { AppContext } from "./AppContext";

const AppProvider = (props) => {
  const [notifications, setNotifications] = useState<any>([]);

  const setNotificationMessage = (notificationMessage) => {
    setNotifications((prev) => {
      const alreadyExist = prev.some(
        (item) => item.key === notificationMessage.key
      );

      if (alreadyExist) {
        return prev;
      }

      return [
        ...prev,
        {
          errors: notificationMessage.errors,
          key: notificationMessage.key,
          fieldName: notificationMessage.fieldName,
          handleNotificationClick: notificationMessage.handleNotificationClick,
          messageBarType: notificationMessage.messageBarType,
          notify: notificationMessage.notify,
        },
      ];
    });
  };

  const filterDuplicates = (errors) => {
    setNotifications((prev) => {
      return prev.filter((item) =>
        Object.keys(errors).includes(item.fieldName)
      );
    });
  };

  const removeNotification = (notificationKey) => {
    setNotifications((prev) => {
      return prev.filter((item) => item.key !== notificationKey);
    });
  };
  const clearAllNotifications = () => {
    setNotifications([]);
  };

  const clearFormNotifications = () => {
    setNotifications((prev) => {
      return prev.filter((item) => !item.fieldName);
    });
  };

  const providerValue: any = {
    tooltipsStatus: props.tooltipsStatus,
    setNotificationMessage,
    removeNotification,
    filterDuplicates,
    notifications,
    clearAllNotifications,
    clearFormNotifications,
  };

  return (
    <AppContext.Provider value={providerValue}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;

import { Label } from "@fluentui/react";
import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { selectWithDocumentDate } from "store/slices/bookingSlice";
import { useCurrentRoute } from "../../../../config/routes";
import { useSelector } from "../../../../store/hooks";
import { PremiumTypeKey } from "../../../../store/vehicle";
import {
  VehiclePremiumBookingInfo,
  VehiclePremiumBookingPerTypeData,
} from "../../../../types/types";
import { capitalize } from "../../../../utils/utils";
import PeriodSection from "./PeriodSection";
import PolicyTables from "./PolicyTables";

type Props = {
  premiumType: PremiumTypeKey;
};

const BookingDetails = ({ premiumType }: Props): any => {
  const { t } = useTranslation();
  const vehicle = useSelector((s) => s.vehicle.vehicle);
  const withDocumentDate = useSelector(selectWithDocumentDate);

  const route = useCurrentRoute();

  const sortSegments = (a, b) => {
    const aDate = moment(a.premiumBookingDate);
    const bDate = moment(b.premiumBookingDate);

    const aDate2 = moment(a.premiumBookingFromDate);
    const bDate2 = moment(b.premiumBookingFromDate);

    const aDate3 = moment(a.premiumBookingToDate);
    const bDate3 = moment(b.premiumBookingToDate);

    if (aDate.isSame(bDate, "day")) {
      if (aDate2.isSame(bDate2, "day")) {
        return aDate3.isAfter(bDate3, "day")
          ? -1
          : aDate3.isBefore(bDate3, "day")
          ? 1
          : 0;
      } else {
        return aDate2.isAfter(bDate2, "day")
          ? -1
          : aDate2.isBefore(bDate2, "day")
          ? 1
          : 0;
      }
    } else {
      return aDate.isAfter(bDate, "day")
        ? -1
        : aDate.isBefore(bDate, "day")
        ? 1
        : 0;
    }
  };

  const vehiclePremiumBookingData = useMemo(() => {
    const premiumTypes = [];
    let retVal: VehiclePremiumBookingPerTypeData = {
      totalBookedAmount: 0,
      totalBookedAmountPerCurrentPolicyCoverPeriod: 0,
      totalPaidAmount: 0,
      totalPaidAmountPerCurrentPolicyCoverPeriod: 0,
      totalSaldo: 0,
      totalSaldoPerCurrentPolicyCoverPeriod: 0,
      vehiclePremiumBooking: [],
    };
    if (premiumType === "GENERAL") {
      premiumTypes.push("MTPL");
      premiumTypes.push("HULL");
      premiumTypes.push("HULL_BASIC");
      premiumTypes.push("ACCIDENT");
      premiumTypes.push("ASSISTANCE");
      premiumTypes.push("LEGAL");
      premiumTypes.push("HORSE_POWER");
      premiumTypes.push("FLEETLEGAL_PRORATA");
    } else {
      premiumTypes.push(premiumType);
    }

    premiumTypes.forEach((premiumTypesItem) => {
      var pt =
        premiumTypesItem === "HORSE_POWER"
          ? "EngineTax"
          : capitalize(premiumTypesItem.toLowerCase());
      // totalBookedAmount: 0
      // totalBookedAmountPerCurrentPolicyCoverPeriod: 0
      // vehiclePremiumBooking: [,…]

      if (premiumTypesItem === "HULL_BASIC") pt = "HullBasic";
      if (premiumTypesItem === "FLEETLEGAL_PRORATA") pt = "FleetLegalProRata";

      var pte = `vehicle${pt}PremiumBookingData`;

      let vehiclePremiumBookingData: VehiclePremiumBookingPerTypeData = null;
      if (withDocumentDate) {
        vehiclePremiumBookingData =
          vehicle?.vehiclePremiumBookingWithDocumentDateData[pte];
      } else {
        vehiclePremiumBookingData = vehicle?.vehiclePremiumBookingData[pte];
      }
      retVal.totalBookedAmount += vehiclePremiumBookingData.totalBookedAmount;
      retVal.totalBookedAmountPerCurrentPolicyCoverPeriod +=
        vehiclePremiumBookingData.totalBookedAmountPerCurrentPolicyCoverPeriod;
      retVal.totalPaidAmount += vehiclePremiumBookingData.totalPaidAmount;
      retVal.totalPaidAmountPerCurrentPolicyCoverPeriod +=
        vehiclePremiumBookingData.totalPaidAmountPerCurrentPolicyCoverPeriod;
      retVal.totalSaldo += vehiclePremiumBookingData.totalSaldo;
      retVal.totalSaldoPerCurrentPolicyCoverPeriod +=
        vehiclePremiumBookingData.totalSaldoPerCurrentPolicyCoverPeriod;
      retVal.vehiclePremiumBooking.push(
        ...vehiclePremiumBookingData.vehiclePremiumBooking
      );
    });
    retVal.vehiclePremiumBooking =
      retVal.vehiclePremiumBooking.sort(sortSegments);
    return retVal;
  }, [
    premiumType,
    vehicle?.vehiclePremiumBookingData,
    vehicle?.vehiclePremiumBookingWithDocumentDateData,
    withDocumentDate,
  ]);

  let bookingData = useMemo(() => {
    let array =
      vehiclePremiumBookingData?.vehiclePremiumBooking as VehiclePremiumBookingInfo[];
    let initialValue = {};
    const dateSums = array.reduce((previousValue, currentValue) => {
      const date = moment(currentValue.premiumBookingDate).format("YYYY-MM-DD");
      let sum = previousValue[date];
      if (!sum) {
        sum = 0;
      }
      sum += currentValue.bookedAmount;
      let retVal = { ...previousValue };
      retVal[date] = sum;
      return retVal;
    }, initialValue);
    return array.map((el) => {
      const date = moment(el.premiumBookingDate).format("YYYY-MM-DD");
      const dateSum = dateSums[date];
      return { ...el, dateSum };
    });
  }, [vehiclePremiumBookingData?.vehiclePremiumBooking]);

  if (route === "createVehicle" || route === "duplicateVehicle") return null;
  if (!vehicle) return null;
  if (!vehicle.vehiclePremiumBookingData) return null; // ??

  if (bookingData.length === 0)
    return (
      <Label style={{ marginTop: "20px" }}>
        {t("bfm.vehicleForm.tabPremiumBooking.label")}
        {" - "}
        {t("greco.noData")}
      </Label>
    );

  return (
    <>
      <PeriodSection vehiclePremiumBookingData={vehiclePremiumBookingData} />
      <PolicyTables
        withDocumentDate={withDocumentDate}
        bookingData={bookingData}
        premiumType={premiumType}
        vehicle={vehicle}
      />
    </>
  );
};

export default BookingDetails;

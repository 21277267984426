import { mergeStyleSets } from "@uifabric/merge-styles";
import { useCurrentRoute } from "config/routes";
import { constants } from "library/constants";
import React from "react";

import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import FleetFormToolbarLeft from "./FleetFormToolbarLeft";
import FleetMassActionsContextMenu from "./FleetMassActionsContextMenu";
import FleetSaveBtn from "./FleetSaveBtn";

type Props = {
  setFile: (file: File | null) => void;
};

const FleetFormToolbar = ({ setFile }: Props) => {
  const theme = useTheme() as IStyledTheme;
  const route = useCurrentRoute();

  const isDarkMode = theme.isDark;
  const isCreateFleet = route === constants.createFleet;

  const classes = mergeStyleSets({
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      background: isDarkMode
        ? theme.palette.blackTranslucent40
        : theme.palette.white,
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
      height: "50px",
      padding: "0 10px",
      marginBottom: "10px",
      justifyContent: "space-between",
    },
    toolbarLeft: {
      display: "flex",
      alignItems: "center",
      gap: 10,
    },
    toolbarRight: {
      height: "100%",
      paddingRight: "10px",
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "flex-end",
    },
  });

  return (
    <div className={classes.container}>
      {!isCreateFleet && (
        <div className={classes.toolbarLeft}>
          <FleetFormToolbarLeft />
        </div>
      )}

      <div className={classes.toolbarRight}>
        {!isCreateFleet && <FleetMassActionsContextMenu />}
        <FleetSaveBtn setFile={setFile} />
      </div>
    </div>
  );
};

export default FleetFormToolbar;

import { useMutation } from "react-query";
import {
  SharedLicensePlateRequestParams,
  suspendSharedLicensePlate,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const suspendSharedLicensePlateFn = async (
  params: SharedLicensePlateRequestParams
) => {
  return await suspendSharedLicensePlate(params);
};

export function useSuspendSharedLicensePlate() {
  return useMutation(suspendSharedLicensePlateFn, {
    mutationKey: MUTATION_KEYS.suspendSharedLicensePlate,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

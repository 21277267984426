import React from "react";
import GeneralFormItems from "./GeneralFormItems";
import GeneralPreview from "./GeneralPreview/GeneralPreview";

const GeneralSection = ({
  vehicle,
  route,
  defaultSettings,
  initialValues,
  formikProps,
}) => {
  const isReadOnlyMode = route === "readOnlyVehicle";

  return isReadOnlyMode ? (
    <GeneralPreview vehicle={vehicle} route={route} />
  ) : (
    <GeneralFormItems
      formikProps={formikProps}
      vehicle={vehicle}
      route={route}
      initialValues={initialValues}
    />
  );
};

export default GeneralSection;

import { isExternalOrReadonlyAdmin } from "form/util";
import { CommandBarButton } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setDialog } from "store/fleetForm";
import { useSelector } from "store/hooks";

import { TimelineType } from "types/types";
import FleetReportsContextMenu from "./FleetReportsContextMenu";

function FleetFormToolbarLeft() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userRole = useSelector((s) => s.auth.userRole);

  const loadFleetLoadStatus = useSelector(
    (s) => s.fleetForm.loadFleetLoadStatus
  );

  const isDisabledLoading = loadFleetLoadStatus === "loading";

  const isDisabled = isDisabledLoading || isExternalOrReadonlyAdmin(userRole);

  const fleetAppSettings = useSelector((s) => s.fleetForm.fleetAppSettings);

  let title = t("bfm.fleet.action.recalculateProRataAndBookings");
  if (
    !fleetAppSettings?.VEHICLE_TIMELINE_TYPES.includes(
      TimelineType.VEHICLE_PREMIUM_BOOKINGS
    )
  ) {
    title = t("bfm.fleet.action.recalculateProRata");
  }
  return (
    <>
      <CommandBarButton
        disabled={isDisabled}
        text={title}
        styles={{
          root: {
            whiteSpace: "nowrap",
            minHeight: 44,
          },
        }}
        iconProps={{
          iconName: "Calculator",
        }}
        onClick={() => {
          dispatch(
            setDialog({
              type: "recalculateProRataAndBookings",
              isOpen: true,
              title: title,
              icon: "Calculator",
            })
          );
        }}
      />

      <CommandBarButton
        disabled={isDisabled}
        text={t("bfm.fleet.action.synchronizeFleetClient")}
        styles={{
          root: {
            whiteSpace: "nowrap",
            minHeight: 44,
          },
        }}
        iconProps={{
          iconName: "Sync",
        }}
        onClick={() => {
          dispatch(
            setDialog({
              type: "synchronizeFleetClient",
              isOpen: true,
              title: t("bfm.fleet.action.synchronizeFleetClient"),
              icon: "Sync",
            })
          );
        }}
      />
      <FleetReportsContextMenu fleetAppSettings={fleetAppSettings} />
    </>
  );
}

export default FleetFormToolbarLeft;

import { useNavigate } from "react-router-dom";

import { mergeStyleSets } from "@fluentui/react";
import AppSettings, { InsurerSettingsTemplate } from "AppSettings";
import { toast } from "components/FluentToast";
import FormSubmitSpinner from "components/FormSubmitSpinner";
import { useCurrentRoute } from "config/routes";
import { Formik } from "formik";
import { useCreateInsurerSettings } from "hooks/data/mutations/useCreateInsurerSettings";
import { useUpdateInsurerSettings } from "hooks/data/mutations/useUpdateInsurerSettings";
import { useGetInsurerListByCountry } from "hooks/data/queries/useGetInsurerListByCountry";
import { useGetInsurerSettings } from "hooks/data/queries/useGetInsurerSettings";
import { delay } from "library/delay";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useSelector } from "store/hooks";
import { selectShouldDisableForm } from "store/vehicle";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { Country_ALL, PremiumType } from "types/types";
import { setYupLocale2 } from "utils/setYupLocale";
import * as yup from "yup";
import FormItemTitle from "./FormItemTitle";
import InsurerFormSkeleton from "./InsurerFormSkeleton";
import InsurerFormToolbar from "./InsurerFormToolbar";
import {
  convertFormToRequestObject,
  convertInsurerToFormObject,
} from "./insurerFormUtils";
import GarageRiskDiscount from "./sections/GarageRiskDiscount";
import InsuranceLineDetails from "./sections/InsuranceLineDetails";
import SuspendedDaysLimits from "./sections/SuspendedDaysLimits";
import SuspensionAndActivationDays from "./sections/SuspensionAndActivationDays";

const suspensionDaysPremiumTypes = [
  PremiumType.MTPL,
  PremiumType.HULL,
  PremiumType.HULL_BASIC,
  PremiumType.LEGAL,
  PremiumType.ACCIDENT,
  PremiumType.ASSISTANCE,
  PremiumType.HORSE_POWER,
];

const InsurerForm = () => {
  const theme = useTheme() as IStyledTheme;
  const navigate = useNavigate();
  const route = useCurrentRoute();
  const {
    mutate: onUpdateInsurerSettings,
    isLoading: updateSettingsInProgress,
  } = useUpdateInsurerSettings();

  const {
    mutate: onCreateInsurerSettings,
    isLoading: createInsurerSettingsInProgress,
  } = useCreateInsurerSettings();
  const classes = getClassNames(theme);
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const [showForm, setShowForm] = useState(false);

  // All insurers
  const { data: insurerList } = useGetInsurerListByCountry(Country_ALL);

  // Insurer based on id
  const { insurerId } = useParams() as any;
  const [selectedInsurer, setSelectedInsurer] = useState(null);

  useEffect(() => {
    if (insurerId && insurerList) {
      setSelectedInsurer(
        insurerList.find((el) => el.insurerInternalNumber + "" === insurerId)
      );
    }
  }, [insurerId, insurerList]);

  // Insurer settings based on insurer id
  const {
    data: insurerSettings,
    isLoading: insurerLoading,
    refetch: refetchInsurerSettings,
  } = useGetInsurerSettings(insurerId);

  const isLoading =
    useSelector(selectShouldDisableForm)(route) ||
    updateSettingsInProgress ||
    createInsurerSettingsInProgress ||
    insurerLoading;

  const insurerAppSettings = useMemo(() => {
    return AppSettings.getInstance().getAppSettingsTypes()[
      taxonomy.Country.byId[selectedInsurer?.countryCodeId]?.code
    ];
  }, [selectedInsurer, taxonomy]);

  const initialValues = useMemo(() => {
    if (
      insurerSettings &&
      insurerSettings.length > 0 &&
      insurerAppSettings &&
      !insurerAppSettings.INSURER_SETTINGS_TEMPLATES.includes(
        InsurerSettingsTemplate.INSURANCE_LINE_DETAILS
      )
    ) {
      return convertInsurerToFormObject(
        insurerSettings[0],
        taxonomy,
        suspensionDaysPremiumTypes
      );
    } else {
      if (!selectedInsurer) return null;
      return {
        garageRiskDiscountPercentHull: "0,00",
        garageRiskDiscountPercentHullBasic: "0,00",
        suspensionDays: "0",
        activationDays: "0",
        suspensionDaysLimit: Array.apply(null, Array(91)).map((e, index) => {
          if ((index + 1) % 7 === 0) return "45";
          return "0";
        }),
        insurerSettingPerPremiumTypeDetailsHU:
          insurerAppSettings.INSURER_SETTINGS_TEMPLATES.includes(
            InsurerSettingsTemplate.INSURANCE_LINE_DETAILS
          )
            ? insurerAppSettings.VEHICLE_PREMIUM_TYPES.filter(
                (el) => el === PremiumType.MTPL || el === PremiumType.HULL
              ).map((el) => ({
                premiumTypeCode: el,
                maxValueForTaxCalculation: "0,00",
                taxPercent: "0,00",
                taxExtraPercent1: "0,00",
              }))
            : [],
        validFromDate: new Date(),
      };
    }
  }, [insurerAppSettings, insurerSettings, taxonomy, selectedInsurer]);

  const validationSchema = useMemo(() => {
    setYupLocale2(t);

    let schemaObj: any = {
      garageRiskDiscountPercentHull: yup
        .number()
        .required()
        .min(0)
        .max(100)
        .transform((_, value) => {
          if (typeof value === "string" && value.includes(".")) {
            return null;
          }
          return typeof value === "string" ? +value.replace(/,/, ".") : value;
        }),
      garageRiskDiscountPercentHullBasic: yup
        .number()
        .required()
        .min(0.0)
        .max(100.0)
        .transform((_, value) => {
          if (typeof value === "string" && value.includes(".")) {
            return null;
          }
          return typeof value === "string" ? +value.replace(/,/, ".") : value;
        }),
      suspensionDays: yup.number().integer().required().min(0).max(2),
      activationDays: yup.number().integer().required().min(0).max(2),
      suspensionDaysLimit: yup
        .array()
        .of(yup.number().integer().required().min(-1)),
    };
    if (
      insurerAppSettings?.INSURER_SETTINGS_TEMPLATES.includes(
        InsurerSettingsTemplate.INSURANCE_LINE_DETAILS
      )
    ) {
      const itemSchema = yup.object().shape({
        premiumTypeCode: yup.string().required(),
        maxValueForTaxCalculation: yup
          .number()
          .required()
          .min(0)
          .transform((_, value) => {
            if (typeof value === "string" && value.includes(".")) {
              return null;
            }
            return typeof value === "string" ? +value.replace(/,/, ".") : value;
          }),
        taxPercent: yup
          .number()
          .required()
          .min(0)
          .transform((_, value) => {
            if (typeof value === "string" && value.includes(".")) {
              return null;
            }
            return typeof value === "string" ? +value.replace(/,/, ".") : value;
          }),
        taxExtraPercent1: yup
          .number()
          .required()
          .min(0)
          .transform((_, value) => {
            if (typeof value === "string" && value.includes(".")) {
              return null;
            }
            return typeof value === "string" ? +value.replace(/,/, ".") : value;
          }),
        // taxExtraPercent2: yup
        //   .number()
        //   .required()
        //   .min(0)
        //   .transform((_, value) => {
        //     if (typeof value === "string" && value.includes(".")) {
        //       return null;
        //     }
        //     return typeof value === "string" ? +value.replace(/,/, ".") : value;
        //   }),
      });

      schemaObj = {
        validFromDate: yup.date().nullable().required(),
        insurerSettingPerPremiumTypeDetailsHU: yup
          .array()
          .of(itemSchema)
          .required(),
      };
    }
    return yup.object().shape(schemaObj);
  }, [insurerAppSettings]);

  const onSubmit = async (data) => {
    const returnObject = convertFormToRequestObject(
      data,
      selectedInsurer,
      taxonomy,
      suspensionDaysPremiumTypes,
      insurerAppSettings
    );

    if (typeof returnObject.id === "undefined") {
      onCreateInsurerSettings(returnObject, {
        onSuccess: (res) => {
          toast.success(t("bfm.insurerForm.successfullyCreatedInsurer"));
          setShowForm(false);
          //navigate(`/insurers`, { state: res.data });
          refetchInsurerSettings();
        },
      });
    } else {
      onUpdateInsurerSettings(returnObject, {
        onSuccess: (res) => {
          toast.success(t("bfm.insurerForm.successfullyUpdatedInsurer"));
          setShowForm(false);
          //navigate(`/insurers`, { state: res.data });
          refetchInsurerSettings();
        },
      });
    }
  };

  if (!selectedInsurer || typeof insurerSettings === "undefined") {
    return null;
  }

  return (
    <>
      {isLoading && <FormSubmitSpinner />}
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnChange={false}
        // validateOnBlur={false}
        onSubmit={async (values, { setSubmitting }) => {
          onSubmit(values);
          await delay(400);
          setSubmitting(false);
        }}
      >
        {(props) => {
          return (
            <>
              <div id="insurer-page-wrapper" className={classes.main}>
                <form onSubmit={props.handleSubmit}>
                  <div className={classes.navigationWrap}>
                    <InsurerFormToolbar insurer={selectedInsurer} />
                  </div>
                  {!selectedInsurer ? (
                    <InsurerFormSkeleton />
                  ) : (
                    <div>
                      <div className={classes.wrap}>
                        <div className={classes.form}>
                          <FormItemTitle
                            title={selectedInsurer?.insurerName}
                            iconName={"Shield"}
                          />
                          <div
                            className={classesTab.root}
                            id="defaultSettings-tab"
                          >
                            <InsuranceLineDetails
                              props={props}
                              selectedInsurer={selectedInsurer}
                              insurerSettings={insurerSettings}
                              classes={classes}
                              showForm={showForm}
                              setShowForm={setShowForm}
                              refetchInsurerSettings={refetchInsurerSettings}
                            />
                            <SuspendedDaysLimits
                              props={props}
                              classesTab={classesTab}
                              selectedInsurer={selectedInsurer}
                              classes={classes}
                            />
                            <GarageRiskDiscount
                              props={props}
                              classesTab={classesTab}
                              selectedInsurer={selectedInsurer}
                              classes={classes}
                            />
                            <SuspensionAndActivationDays
                              props={props}
                              classesTab={classesTab}
                              selectedInsurer={selectedInsurer}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

const classesTab = mergeStyleSets({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px",
  },
  item: {
    padding: "20px",
    width: "49%",
    "@media(min-width: 1500px)": {
      width: "25%",
    },
  },
  days_left: {
    padding: "14px 24px 0px",
    width: "8%",
    "@media(min-width: 1500px)": {
      width: "8%",
    },
  },
  days: {
    padding: "0px 10px",
    width: "13%",
    "@media(min-width: 1500px)": {
      width: "13%",
    },
  },
  uploadFile: {
    width: "100%",
  },
});

const getClassNames = (theme) =>
  mergeStyleSets({
    textColor: {
      color: theme.palette.black,
    },
    textColorBold: {
      color: theme.palette.black,
      fontWeight: "600",
      fontSize: 14,
    },
    wrap: {
      background: theme.palette.white,
      boxShadow:
        "0 3.2px 7.2px 0 rgba(0,0,0,.132), 0 0.6px 1.8px 0 rgba(0,0,0,.108)",
      display: "flex",
      margin: "50px auto 0",
      borderRadius: "4px",
      maxWidth: "80%",
      height: "90%", //
      position: "relative",
      marginBottom: "100px",
    },
    navigationWrap: {
      position: "sticky",
      top: 0,
      zIndex: 2,
    },
    main: {
      overflow: "auto", //
      height: "100%",
      position: "relative",
      paddingTop: 0,
      flex: 1,
    },
    form: {
      width: "100%",
      padding: "20px",
      overfow: "auto",
    },
  });

export default InsurerForm;

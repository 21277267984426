import { ActionButton } from "@fluentui/react";
import { routes } from "config/routes";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

const AddTradeVehicleButton = () => {
  const { fleetId } = useParams() as any;
  const { t } = useTranslation();

  return (
    <Link to={routes.createTradeVehicle.getPath(fleetId)}>
      <ActionButton
        iconProps={{ iconName: "Add" }}
        text={t("bfm.addTradeLicensePlate")}
      />
    </Link>
  );
};

export default AddTradeVehicleButton;

import { Checkbox, DefaultButton, Icon, mergeStyles } from "@fluentui/react";
import { Buffer } from "buffer/";
import ErrorMsg from "components/controls/ErrorMsg";
import { FormikProps } from "formik";
import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Flex } from "../../../components/styled";
import MultipleUploadPreview from "./MultipleUploadPreview";
import SingleUploadPreview from "./SingleUploadPreview";

export interface IDropFile {
  base64str: string;
  name: string;
  tempId?: number;
}

type Props = {
  formikProps: FormikProps<any>;
  file: any | null;
  setFile: (file: IDropFile | IDropFile[]) => void;
  name?: string;
  acceptCsvFiles?: boolean;
  multiple?: boolean;
  isOnlyExistingVehiclesAttributesUpdate?: boolean;
};

const Dropzone = ({
  formikProps,
  file,
  setFile,
  isOnlyExistingVehiclesAttributesUpdate = false,
  name = "file",
  acceptCsvFiles = false,
  multiple = false,
}: Props) => {
  const { t } = useTranslation();

  const formik = formikProps;

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (multiple) {
        // Convert each file to base64
        const fileListPromisses = acceptedFiles.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
              const binaryStr = reader.result as string;
              const encode = Buffer.from(binaryStr).toString("base64");

              resolve({
                base64str: encode,
                name: file.name,
                tempId: Math.floor(Math.random() * Date.now()),
              });
            };

            reader.onerror = (error) => {
              reject(error);
            };

            reader.readAsArrayBuffer(file);
          });
        });

        Promise.all(fileListPromisses)
          .then((result) => {
            const prevFiles = file ? file : [];

            const uniqueNamesSet = [];

            const uniqueFilesByName = [...prevFiles, ...result].filter(
              (obj) => {
                if (!uniqueNamesSet.includes(obj.name)) {
                  uniqueNamesSet.push(obj.name);
                  return true;
                }
                return false;
              }
            );

            const slicedFiles = uniqueFilesByName.slice(0, 5);
            setFile(slicedFiles);
          })
          .catch((error) => {
            console.error("Error reading files:", error);
          });
        return;
      }
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result as string;
          const encode = Buffer.from(binaryStr).toString("base64");

          setFile({ base64str: encode, name: file.name });
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [file]
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      noDragEventsBubbling: true,
      noClick: true,
      noKeyboard: true,
      accept: {
        "application/vnd.ms-excel": [".xls", ".xlsx", ".xlsm"],
        ...(acceptCsvFiles && { "text/csv": [".csv"] }),
      },
      multiple,
      // maxFiles: 5,
      onDrop,
    });
  useEffect(() => {
    formik.setFieldValue("file", file);
  }, [file, formik.values]);

  const maxFilesReached = multiple ? file?.length === 5 : false;
  return (
    <div>
      <Root
        className={mergeStyles({
          accept: isDragAccept,
          reject: isDragReject,
        })}
        {...getRootProps()}
      >
        <input {...(getInputProps() as any)} />
        {file && !multiple ? (
          <SingleUploadPreview file={file} open={open} setFile={setFile} />
        ) : (
          <Flex direction="column" gap={5}>
            <UploadIcon iconName="CloudUpload" />
            <TitleContainer>
              <Title>
                {" "}
                {maxFilesReached
                  ? t("bfm.multipleUploadMaxReached.label")
                  : t(
                      acceptCsvFiles
                        ? "bfm.dragExcelOrCsvFileHere"
                        : "bfm.dragExcelFileHere"
                    )}
              </Title>
              {!maxFilesReached && <Title>{t("or")}</Title>}
            </TitleContainer>

            <DefaultButton
              disabled={file?.length >= 5}
              text={t("bfm.browseFiles")}
              onClick={open}
            />
          </Flex>
        )}
        {isOnlyExistingVehiclesAttributesUpdate && (
          <Checkbox
            styles={{
              root: {
                marginTop: 15,
              },
            }}
            label={t("bfm.isOnlyExistingVehiclesAttributesUpdate.label")}
            onChange={(_, checked) => {
              formik.setFieldValue(
                "isOnlyExistingVehiclesAttributesUpdate",
                checked
              );
            }}
          />
        )}

        {formikProps.errors[name] && (
          <ErrorMsg>{formikProps.errors[name]}</ErrorMsg>
        )}
      </Root>

      {file && multiple && (
        <MultipleUploadPreview files={file} setFiles={setFile} />
      )}
    </div>
  );
};
export default React.memo(Dropzone);

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed ${(p) => p.theme.palette.neutralTertiaryAlt};
  height: auto;
  padding: 10px;
  margin: 20px;
  font-size: 18px;
  font-weight: 600;
  flex-direction: column;
  user-select: none;
  &:focus {
    outline: none;
  }
  &.accept {
    background-color: ${(p) => p.theme.palette.themeLighterAlt};
  }
  &.reject {
    background-color: ${(p) => p.theme.palette.redDark};
  }
`;

const TitleContainer = styled.div`
  text-align: center;
`;

const Title = styled.div`
  font-size: 20px;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;

const UploadIcon = styled(Icon)`
  font-size: 35px;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;

import { CommandBarButton } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const saveBtnStyle = {
  root: {
    height: "100%",
  },
};

const DownloadScanButton = ({ disabled, action }: any) => {
  const { t } = useTranslation();

  return (
    <CommandBarButton
      styles={saveBtnStyle}
      type="button"
      iconProps={{ iconName: "RedEye" }}
      text={t("bfm.previewScannedVehicleDocument.label")}
      disabled={disabled}
      onClick={() => {
        action();
      }}
    />
  );
};

export default DownloadScanButton;

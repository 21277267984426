import { mergeStyleSets } from "@uifabric/merge-styles";
import React from "react";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

const ErrorMsg = ({ children }) => {
  const theme = useTheme() as IStyledTheme;
  const classes = mergeStyleSets({
    errorMsg: {
      color: theme.palette.redDark,
      fontSize: 12,
      paddingTop: 5,
      height: 21,
    },
  });
  return <p className={classes.errorMsg}>{children}</p>;
};

export default ErrorMsg;

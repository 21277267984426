import AppSettings, { InsurerSettingsTemplate } from "AppSettings";
import React from "react";
import { useSelector } from "store/hooks";
import { UserRole } from "types/types";
import NumberInputField from "../NumberInputField";

const GarageRiskDiscount = ({
  props,
  classesTab,
  selectedInsurer,
  classes,
}: any) => {
  const userRole = useSelector((s) => s.auth.userRole);
  const taxonomy = useSelector((s) => s.taxonomy);

  const insurerCountrySettings =
    AppSettings.getInstance().getAppSettingsTypes()[
      taxonomy.Country.byId[selectedInsurer?.countryCodeId]?.code
    ];
  if (!props.values || !insurerCountrySettings) {
    return null;
  }
  return (
    <>
      {insurerCountrySettings.INSURER_SETTINGS_TEMPLATES.includes(
        InsurerSettingsTemplate.GARAGE_RISK_DISCOUNT
      ) ? (
        <>
          <div className={classesTab.item}></div>
          <div className={classesTab.item}></div>
          <div className={classesTab.item}></div>
          <div className={classesTab.item}></div>

          {insurerCountrySettings.GARAGE_RISK_DISCOUNT_PERCENT_INCLUDED ? (
            <>
              <div className={classesTab.item}>
                <NumberInputField
                  setFieldValue={props.setFieldValue}
                  value={props.values?.garageRiskDiscountPercentHull}
                  errors={props.errors}
                  onChange={props.handleChange}
                  required
                  name={"garageRiskDiscountPercentHull"}
                  disabled={userRole !== UserRole.admin}
                  label={"bfm.garageRiskDiscountPercentHull.label"}
                />
                {/* {garageRiskDiscountPercentHull} */}
              </div>
              <div className={classesTab.item}>
                <NumberInputField
                  setFieldValue={props.setFieldValue}
                  value={props.values?.garageRiskDiscountPercentHullBasic}
                  errors={props.errors}
                  onChange={props.handleChange}
                  required
                  name={"garageRiskDiscountPercentHullBasic"}
                  disabled={userRole !== UserRole.admin}
                  label={"bfm.garageRiskDiscountPercentHullBasic.label"}
                />
                {/* {garageRiskDiscountPercentHullBasic} */}
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default GarageRiskDiscount;

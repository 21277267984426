import React, { useMemo } from "react";
import { PremiumTypeKey } from "store/vehicle";
import { Vehicle, VehiclePremiumBookingInfo } from "types/types";
import BookingTable from "./BookingTable/BookingTable";
import PolicyItemDetails from "./PolicyItemDetails";

type Props = {
  bookingData: VehiclePremiumBookingInfo[];
  premiumType: PremiumTypeKey;
  vehicle: Vehicle;
  withDocumentDate: boolean;
};

const PolicyTables = ({
  vehicle,
  premiumType,
  bookingData,
  withDocumentDate,
}: Props) => {
  const policyNumbers = useMemo(() => {
    return [...new Set(bookingData.map((item) => item.insurerPolicyNumber))];
  }, [bookingData]);

  const insurerNameMap = useMemo(() => {
    const insurerNames = [];
    policyNumbers.forEach(
      (policyNumber: any) =>
        (insurerNames[policyNumber] = bookingData.find(
          (booking) => booking.insurerPolicyNumber === policyNumber
        ).insurerPartnerNumber)
    );

    policyNumbers.forEach(
      (policyNumber: any) =>
        (insurerNames[policyNumber] = bookingData.find(
          (booking) => booking.insurerPolicyNumber === policyNumber
        ).insurerPartnerNumber)
    );
    return insurerNames;
  }, [bookingData, policyNumbers]);

  const totalVehiclePremiumBookingData = useMemo(() => {
    const vehicleDataType = withDocumentDate
      ? "vehiclePremiumBookingWithDocumentDateData"
      : "vehiclePremiumBookingData";

    return [
      ...vehicle[vehicleDataType].vehicleMtplPremiumBookingData
        .vehiclePremiumBooking,
      ...vehicle[vehicleDataType].vehicleEngineTaxPremiumBookingData
        .vehiclePremiumBooking,
      ...vehicle[vehicleDataType].vehicleAccidentPremiumBookingData
        .vehiclePremiumBooking,
      ...vehicle[vehicleDataType].vehicleAssistancePremiumBookingData
        .vehiclePremiumBooking,
      ...vehicle[vehicleDataType].vehicleLegalPremiumBookingData
        .vehiclePremiumBooking,
      ...vehicle[vehicleDataType].vehicleHullPremiumBookingData
        .vehiclePremiumBooking,
      ...vehicle[vehicleDataType].vehicleHullBasicPremiumBookingData
        .vehiclePremiumBooking,
      ...vehicle[vehicleDataType].vehicleFleetLegalProRataPremiumBookingData
        .vehiclePremiumBooking,
    ];
  }, [vehicle, withDocumentDate]);

  return (
    <>
      {policyNumbers.map((policyNumber, index) => {
        const totalPerPolicy = totalVehiclePremiumBookingData
          .filter((d) => d.insurerPolicyNumber === policyNumber)
          .map((d) => d.bookedAmount)
          .reduce((prev, next) => prev + next, 0);
        const policyBookingData = bookingData.filter(
          (d) => d.insurerPolicyNumber === policyNumber
        );
        return (
          <div key={`${premiumType}${index}`}>
            <PolicyItemDetails
              policyNumber={policyNumber}
              insurerNameMap={insurerNameMap}
              totalPerPolicy={totalPerPolicy}
              bookingData={bookingData}
              premiumType={premiumType}
            />
            <BookingTable
              bookingData={policyBookingData}
              premiumType={premiumType}
            />
          </div>
        );
      })}
    </>
  );
};

export default PolicyTables;

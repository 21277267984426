import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
} from "@fluentui/react";
import { Formik } from "formik";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { FormSpinner } from "./FormSpinner";
import { Field } from "./form/Field";
import { StyledPrimaryButton } from "./styled";

type Props = {
  cancelStatusAction: any;
  isLoading: boolean;
  close: () => void;
};

export const CancelStatusDialog = ({
  cancelStatusAction,
  isLoading,
  close,
}: Props) => {
  const theme = useTheme() as IStyledTheme;
  const { t } = useTranslation();
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const phraseActivated = usePhraseActivationStatus().activated;

  const title = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Icon
        iconName="Cancel"
        style={{
          color: theme.palette.redDark,
          fontSize: "20px",
          marginRight: "5px",
        }}
      />
      {t("bfm.cancelVehicleStatus")}
    </div>
  );

  const onSubmit = (data) => {
    cancelStatusAction(data);
  };

  return (
    <Dialog
      hidden={false}
      onDismiss={close}
      dialogContentProps={{
        type: DialogType.normal,
        title,

        showCloseButton: true,
      }}
      minWidth={400}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <Formik
        initialValues={{ comment: "" }}
        onSubmit={onSubmit}
        validateOnChange={didTrySubmit}
        validateOnBlur={didTrySubmit}
      >
        {({ submitForm }) => {
          return (
            <>
              <Field
                name="comment"
                type="text"
                label={"bfm.comment.label"}
                isMultiline
                rows={4}
                isRequired={false}
              />
              <DialogFooter>
                <SaveButton
                  onClick={() => {
                    setDidTrySubmit(true);
                    submitForm();
                  }}
                />
                <DefaultButton onClick={close} text={t("bfm.close.label")} />
              </DialogFooter>
              {isLoading && <FormSpinner />}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const SaveButton = ({
  onClick,
  isLoading,
  isDisabled,
}: {
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <StyledPrimaryButton
      onClick={onClick}
      text={t("bfm.saveStatusChange.label")}
      disabled={isDisabled}
      style={{
        marginRight: "5px",
      }}
      iconProps={{
        iconName: isLoading ? "ProgressRingDots" : "",
      }}
    />
  );
};

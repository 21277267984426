import { Label } from "@fluentui/react";
import { FieldContainer } from "components/controls/FieldContainer";
import { useField, useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "store/hooks";
import {
  resetDialogDefaultValueOperation,
  setDialogDefaultValueOperation,
} from "store/timeline";
import { VSpace } from "../Spacer";
import {
  ErrorMessage,
  Flex,
  StyledIconButton,
  StyledTextField,
} from "../styled";
import { TextFieldConfig } from "./types";
import { useDynamicConfig } from "./useDynamicConfig";

type Props = { fieldConfig: TextFieldConfig };

export const TextField = ({ fieldConfig }: Props) => {
  const { values } = useFormikContext();
  const dispatch = useDispatch();
  const config = {
    ...fieldConfig,
    ...useDynamicConfig(fieldConfig.d),
  };
  const { t } = useTranslation();
  const {
    name,
    label,
    isVisible = true,
    isDisabled,
    isResizable,
    isMultiline,
    rows,
    isRequired,
    defaultValueFunction,
    validate,
    background,
  } = config;
  const [field, { error }, { setValue }] = useField({ name, validate });
  const defaultValueOperation = useSelector(
    (s) => s.timeline.dialog.defaultValueOperation
  );

  const fieldNames = useMemo(() => {
    const retVal = [];
    defaultValueOperation?.fieldNames?.forEach((fieldName) => {
      if (fieldName === "deductible") {
        retVal.push("hullDeductibleMinAmount");
        retVal.push("hullDeductibleMaxAmount");
        retVal.push("hullDeductiblePercent");
        retVal.push("hullDeductibleComment");
      } else if (fieldName === "calculation") {
        retVal.push("annualGrossPremium");
      } else {
        retVal.push(fieldName);
      }
    });
    return retVal;
  }, [defaultValueOperation.fieldNames]);

  useEffect(() => {
    if (
      fieldNames?.includes(name) &&
      defaultValueOperation.results &&
      defaultValueOperation.results[name]
    ) {
      setValue(defaultValueFunction(defaultValueOperation.results[name]));
      dispatch(resetDialogDefaultValueOperation(name));
    }
  }, [defaultValueOperation, name, defaultValueFunction]);

  if (!isVisible) return null;

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <FieldContainer
        isTooltipHidden={isDisabled}
        tooltipText={label}
        isReadOnly={true}
      >
        <Label required={isRequired} disabled={isDisabled}>
          {t(label)}
        </Label>
        {false && defaultValueFunction ? (
          <Flex
            gap={5}
            direction="row"
            style={{ width: "100%", flex: 1, alignItems: "flex-start" }}
          >
            <StyledTextField
              id={name}
              style={{
                flex: 1,
              }}
              borderless
              disabled={isDisabled}
              multiline={isMultiline}
              resizable={isResizable}
              rows={rows}
              hasError={!!error}
              background={background}
              {...field}
            />

            <StyledIconButton
              iconProps={{ iconName: "DoubleChevronLeft8" }}
              title={t("bfm.fillInFleetDefaultsValue.label")}
              ariaLabel={t("bfm.fillInFleetDefaultsValue.label")}
              onClick={(e) => {
                dispatch(
                  setDialogDefaultValueOperation({
                    fieldNames: [name],
                    formValues: values,
                  })
                );
              }}
            />
          </Flex>
        ) : (
          <StyledTextField
            style={{ flex: 1 }}
            id={name}
            borderless
            disabled={isDisabled}
            multiline={isMultiline}
            resizable={isResizable}
            rows={rows}
            hasError={!!error}
            background={background}
            {...field}
          />
        )}
        {!!error && (
          <ErrorMessage id={field.name + "_error"}>{error}</ErrorMessage>
        )}
        <VSpace height={10} />
      </FieldContainer>
    </div>
  );
};

import React from "react";
import VehicleInfoFormItems from "./VehicleInfoFormItems";
import VehicleInfoPreview from "./VehicleInfoPreview";

const VehicleInfoSection = ({
  vehicle,
  route,
  initialValues,
  formikProps,
  defaultSettings,
}) => {
  const isReadOnlyMode = route === "readOnlyVehicle";

  return isReadOnlyMode ? (
    <VehicleInfoPreview vehicle={vehicle} route={route} />
  ) : (
    <VehicleInfoFormItems
      formikProps={formikProps}
      vehicle={vehicle}
      route={route}
      initialValues={initialValues}
    />
  );
};

export default VehicleInfoSection;

import { Dialog, DialogFooter, Icon } from "@fluentui/react";
import { Form, Formik } from "formik";
import { useCorrectVehicleOneTimePremium } from "hooks/data/mutations/useCorrectVehicleOneTimePremium";
import { useDeleteVehicleOneTimePremium } from "hooks/data/mutations/useDeleteVehicleOneTimePremium";
import { useInsertVehicleOneTimePremium } from "hooks/data/mutations/useInsertVehicleOneTimePremium";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import * as yup from "yup";
import { RefProvider } from "../../../contexts/RefProvider";
import { useSelector } from "../../../store/hooks";
import { closeDialog, setOperationRetVal } from "../../../store/timeline";
import { TimelineDialogType } from "../../../types/types";
import { setYupLocale } from "../../../utils/setYupLocale";
import { removeEmpty } from "../../../utils/utils";
import { toast } from "../../FluentToast";
import { FormSpinner } from "../../FormSpinner";
import { transformForServer } from "../../form/transformForServer";
import { Flex, HSpace, StyledPrimaryButton } from "../../styled";
import { ModifyOneTimePremiumDialogContents } from "./ModifyOneTimePremiumDialogContents";
import { modifyOneTimePremiumDialogConfig } from "./modifyOneTimePremiumDialogConfig";
import { validationObjectConfig } from "./modifyOneTimePremiumDialogValidation";
import { transformForForm } from "./transformForForm";

export enum ModifyOneTimePremiumDialogType {
  Insert,
  Correct,
  Delete,
  Cancel,
}

export const ModifyOneTimePremiumDialog = () => {
  const theme = useTheme() as IStyledTheme;

  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const phraseActivated = usePhraseActivationStatus().activated;

  const premiumTypeKeyItem = useSelector(
    (s) => s.timeline.dialog.item.domain.value
  );
  const premiumTypeKey = premiumTypeKeyItem;

  const item = useSelector((s) => s.timeline.dialog.item);
  const extraDialogData = useSelector(
    (s) => s.timeline.dialog.previousDialogData
  );
  const validFrom = useSelector((s) => s.timeline.dialog.date);
  const dialogType = useSelector((s) => s.timeline.dialog.type);
  const rowVersion = useSelector((s) => s.vehicle.vehicle?.rowVersion);

  const {
    mutate: onInsertVehicleOneTimePremium,
    isLoading: insertVehicleOneTimePremiumInProgress,
  } = useInsertVehicleOneTimePremium();

  const {
    mutate: onCorrectVehicleOneTimePremium,
    isLoading: correctVehicleOneTimePremiumInProgress,
  } = useCorrectVehicleOneTimePremium();
  const {
    mutate: onDeleteVehicleOneTimePremium,
    isLoading: deleteVehicleOneTimePremiumInProgress,
  } = useDeleteVehicleOneTimePremium();

  const handleSuccess = (res) => {
    dispatch(setOperationRetVal(res?.data));
    toast.success(t("bfm.success"));
    dispatch(closeDialog());
  };

  const schema = useMemo(() => {
    setYupLocale(t);
    let yupObject = validationObjectConfig(item, dialogType);
    return yup.object(yupObject);
  }, [t, dialogType, item]);

  const isLoading =
    insertVehicleOneTimePremiumInProgress ||
    correctVehicleOneTimePremiumInProgress ||
    deleteVehicleOneTimePremiumInProgress;

  const vehicle = useSelector((s) => s.vehicle.vehicle);

  const taxonomy = useSelector((s) => s.taxonomy);

  const onSubmit = (data) => {
    let result = transformForServer({
      obj: data,
      config: modifyOneTimePremiumDialogConfig,
    }) as any;
    result = removeEmpty(result);

    if (dialogType === TimelineDialogType.ADD_ONETIME_PREMIUM) {
      let objectToSend: any = {
        fleetId: vehicle.fleetId as any,
        vehicleId: vehicle.vehicleId as any,
        vehiclePremiumVersionId: item?.associatedObject
          .vehiclePremiumVersionId as any,
        vehicleOneTimePremiumId: item?.associatedObject
          ?.vehicleOneTimePremiumId as any,

        body: {
          ...result,
          oneTimePremiumType:
            taxonomy.OneTimePremiumType.byCode[extraDialogData].id,
        },
      };

      onInsertVehicleOneTimePremium(
        {
          rowVersion,
          params: objectToSend,
        },
        {
          onSuccess: handleSuccess,
        }
      );
    }
    if (dialogType === TimelineDialogType.CHANGE_ONETIME_PREMIUM) {
      let objectToSend: any = {
        fleetId: vehicle.fleetId as any,
        vehicleId: vehicle.vehicleId as any,
        vehiclePremiumVersionId: item?.associatedObject
          .vehiclePremiumVersionId as any,
        vehicleOneTimePremiumId: item?.associatedObject
          ?.vehicleOneTimePremiumId as any,

        body: {
          ...result,
          oneTimePremiumType:
            taxonomy.OneTimePremiumType.byCode[extraDialogData].id,
        },
      };
      onCorrectVehicleOneTimePremium(
        {
          rowVersion,
          params: objectToSend,
        },
        {
          onSuccess: handleSuccess,
        }
      );
    }
    if (dialogType === TimelineDialogType.DELETE_ONETIME_PREMIUM) {
      let objectToSend: any = {
        fleetId: vehicle.fleetId as any,
        vehicleId: vehicle.vehicleId as any,
        vehiclePremiumVersionId: item?.associatedObject
          .vehiclePremiumVersionId as any,
        vehicleOneTimePremiumId: item?.associatedObject
          ?.vehicleOneTimePremiumId as any,

        body: {
          oneTimePremiumComment: result.oneTimePremiumComment,
        },
      };

      onDeleteVehicleOneTimePremium(
        {
          rowVersion,
          params: objectToSend,
        },
        {
          onSuccess: handleSuccess,
        }
      );
    }
  };
  const Title = (
    <Flex>
      <Icon
        iconName="CircleCorrectition"
        style={{
          color: theme.palette.green,
        }}
      />
      <HSpace />
      <div>
        {dialogType === TimelineDialogType.ADD_ONETIME_PREMIUM
          ? extraDialogData === "OneTimePremiumType.INDEMNITY"
            ? t("bfm.addIndemnityPremium.label")
            : t("bfm.addBonusMalus.label")
          : null}
        {dialogType === TimelineDialogType.DELETE_ONETIME_PREMIUM
          ? extraDialogData === "OneTimePremiumType.INDEMNITY"
            ? t("bfm.deleteIndemnityPremium.label")
            : t("bfm.deleteBonusMalus.label")
          : null}
        {dialogType === TimelineDialogType.CHANGE_ONETIME_PREMIUM
          ? extraDialogData === "OneTimePremiumType.INDEMNITY"
            ? t("bfm.changeIndemnityPremium.label")
            : t("bfm.changeBonusMalus.label")
          : null}
      </div>
    </Flex>
  );

  const initialValues = useMemo(() => {
    let items: any = {};
    if (dialogType === TimelineDialogType.CHANGE_ONETIME_PREMIUM) {
      items = {
        oneTimePremiumType: item.associatedObject.oneTimePremiumTypeCode,
        oneTimePremiumGrossValue:
          item.associatedObject.oneTimePremiumGrossValue,
        oneTimePremiumNetValue: item.associatedObject.oneTimePremiumNetValue,
        oneTimePremiumDate: item.associatedObject.oneTimePremiumDate,
      };
    } else {
      items = {
        oneTimePremiumDate: validFrom,
      };
    }
    let initObj = transformForForm({
      obj: { ...items },
      config: modifyOneTimePremiumDialogConfig,
      t,
      taxonomy,
      insurerList: null,
      possibleMainVehicles: null,
    });
    initObj = {
      ...initObj,
    };
    return initObj;
  }, [t, taxonomy, item, validFrom, dialogType]);

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: Title,
        showCloseButton: true,
      }}
      minWidth={400}
      onDismiss={() => {
        dispatch(closeDialog());
      }}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <RefProvider>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={didTrySubmit}
          validateOnBlur={didTrySubmit}
          validationSchema={schema}
        >
          {({ submitForm }) => {
            return (
              <Form>
                <ModifyOneTimePremiumDialogContents
                  premiumTypeKey={premiumTypeKey}
                  dialogType={dialogType}
                />
                <DialogFooter>
                  <StyledPrimaryButton
                    text={t("greco.save")}
                    onClick={() => {
                      setDidTrySubmit(true);
                      submitForm();
                    }}
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </DialogFooter>
                {isLoading && <FormSpinner />}
              </Form>
            );
          }}
        </Formik>
      </RefProvider>
    </Dialog>
  );
};

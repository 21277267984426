import { useCurrentRoute } from "config/routes";
import { useGetFleetInsurerDefaultSettings } from "hooks/data/queries/getFleetInsurerDefaultSettings";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const useFetchFleetInsurerSettings = () => {
  const { fleetId } = useParams() as any;
  const [routeItem, setRouteItem] = useState(null);
  const [fleetIdItem, setFleetIdItem] = useState(null);

  const route = useCurrentRoute();

  useGetFleetInsurerDefaultSettings(
    routeItem === "editVehicle" ? fleetIdItem : null
  );

  useEffect(() => {
    if (route === "editVehicle") {
      setRouteItem(route);
      setFleetIdItem(fleetId);
    }
  }, [fleetId, route]);
};

export default useFetchFleetInsurerSettings;

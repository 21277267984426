import { Label } from "@fluentui/react";
import Select, { mergeStyles } from "react-select";
import { useTheme } from "styled-components";

import { FieldContainer } from "components/controls/FieldContainer";
import { HullProductFieldConfig } from "components/form/types";
import { useField, useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "store/hooks";
import { IStyledTheme } from "theme/types";
import { ETimelineExtraDataLineSpecial } from "types/types";
import { VSpace } from "../../components/Spacer";
import { useDynamicConfig } from "../../components/form/useDynamicConfig";
import { ErrorMessage } from "../../components/styled";
import { RefContext } from "../../contexts/RefProvider";
import { getSelectStyles } from "../../utils/utils";

type Props = { fieldConfig: HullProductFieldConfig };

const hullProductListState = [
  { label: "Vollkasko", value: "Vollkasko" },
  { label: "Parkschadenkasko", value: "Parkschadenkasko" },
  { label: "Teilkasko", value: "Teilkasko" },
];

export const HullProductField = ({ fieldConfig }: Props) => {
  const config = {
    ...fieldConfig,
    ...useDynamicConfig(fieldConfig.d),
  };
  const {
    name = "",
    label,
    isVisible = true,
    isDisabled,
    isRequired,
    isSearchable,
    validate,
    renderField,
    background,
  } = config;
  const [field, { error }, { setValue }] = useField({
    name,
    validate,
  });

  const { setRef } = useContext(RefContext);
  const { t } = useTranslation();

  const theme = useTheme() as IStyledTheme;

  const { values, setFieldValue } = useFormikContext();
  const [hullProductList, setHullProductList] = useState(hullProductListState);
  const dialogItem = useSelector((s) => s.timeline.dialog.item);
  const associatedObject = useSelector(
    (s) => s.timeline.dialog.item.associatedObject
  );
  const isDarkMode = theme.isDark;

  useEffect(() => {
    if (
      values["hullIsBasic"]?.value === "full" ||
      values["hullIsBasic"]?.value === "false"
    ) {
      setFieldValue("hullProduct", { label: "Vollkasko", value: "Vollkasko" });
    }
  }, [values["hullIsBasic"]]);

  useEffect(() => {
    if (
      associatedObject ||
      (values && values["hullIsBasic"] && values["hullIsBasic"] !== null)
    ) {
      const isBasic = values["hullIsBasic"]?.value;
      const isExistingBasic =
        dialogItem.extraData.lineSpecial ===
        ETimelineExtraDataLineSpecial.BASIC;
      if (isBasic === "true" || (associatedObject && isExistingBasic)) {
        setHullProductList(
          hullProductListState.filter((listItem, index) => index > 0)
        );
      } else {
        setHullProductList(
          hullProductListState.filter((listItem, index) => index === 0)
        );
      }
      if (values["hullIsBasic"]?.value === "true") {
        if (values["hullProduct"]?.value === "Vollkasko") {
          setFieldValue("hullProduct", null);
        }
      }
      if (values["hullIsBasic"]?.value === "false") {
        if (
          values["hullProduct"]?.value === "Parkschadenkasko" ||
          values["hullProduct"]?.value === "Teilkasko"
        ) {
          setFieldValue("hullProduct", null);
        }
      }
      // const insurer = hullProductListState.find(
      //   (insurer) =>
      //     insurer.insurerInternalNumber === values["hullIsBasic"].value
      // );
      // if (
      //   reportInsurerBranchOffices !== null &&
      //   !_.isEqual(
      //     insurer.reportInsurerBranchOffices,
      //     reportInsurerBranchOffices
      //   )
      // ) {
      //   setFieldValue("hullProduct", null);
      // }

      // setReportInsurerBranchOffices(insurer.reportInsurerBranchOffices);
    } else {
      setHullProductList([]);
      setFieldValue("hullProduct", null);
    }
    if (values["hullIsBasic"] === null) {
      setHullProductList([]);
      setFieldValue("hullProduct", null);
    }
  }, [
    values,
    field.value,
    associatedObject,
    setFieldValue,
    dialogItem.extraData.lineSpecial,
  ]);

  if (!isVisible) return null;

  const fieldRender = (
    <Select
      menuPosition="fixed"
      id={name}
      options={hullProductList}
      isSearchable={isSearchable}
      {...field}
      onChange={(value) => {
        setValue(value);
      }}
      styles={mergeStyles(getSelectStyles(!!error, theme), {
        menuPortal: (p) => ({
          ...p,
          background: theme.palette.neutralLighter,
          zIndex: 9999999,
        }),
        menu: (p) => ({
          ...p,
          zIndex: 9999,
          background: theme.palette.neutralLighter,
        }),
        control: (p, state) => {
          return {
            ...p,

            background: state.isDisabled ? "transparent" : theme.palette.white,

            borderBottom: error
              ? `2px solid rgb(164, 38, 44) !important`
              : state.isFocused
              ? `2px solid rgb(0, 90, 161) !important`
              : `1px solid ${theme.palette.black} !important`,
            boxShadow: "none",
            ...(state.isDisabled && {
              borderBottom: `1px solid ${theme.palette.neutralLighter} !important`,
            }),
          };
        },
        option: (p, state) => ({
          ...p,
          background:
            state.isSelected || state.isFocused
              ? theme.palette.neutralLighter
              : theme.palette.white,
          "&:hover": {
            background: theme.palette.neutralLighter,
          },
          color: theme.palette.black,
          fontSize: "14px",
          fontWeight: 400,
          wordWrap: "break-word",
        }),
        input: (p) => ({
          ...p,
          color: (function () {
            if (isDisabled) {
              return theme.palette.neutralSecondaryAlt;
            }
            if (isDarkMode) {
              return "white";
            }
            return theme.palette.black;
          })(),
        }),
        singleValue: (p, state) => ({
          ...p,
          color: theme.isDark
            ? "white"
            : isDisabled
            ? theme.palette.neutralSecondaryAlt
            : "black",
          fontSize: "14px",
          fontWeight: 400,
        }),
        dropdownIndicator: (p, state) => ({
          ...p,
          ...(state.isDisabled && { visibility: "hidden" }),
        }),
        indicatorSeparator: (p, state) => ({
          ...p,
          ...(state.isDisabled && { visibility: "hidden" }),
        }),
      })}
      placeholder={isDisabled ? "" : t("greco.form.selectPlaceholder")}
      noOptionsMessage={() => t("greco.noResults")}
      isDisabled={isDisabled}
      isClearable
      ref={setRef(name) as any}
      inputId={name}
    />
  );

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <FieldContainer
        isTooltipHidden={isDisabled}
        tooltipText={label}
        isReadOnly={true}
      >
        <Label required={isRequired} disabled={isDisabled}>
          {t(label)}
        </Label>
        {renderField ? renderField({ field: fieldRender }) : fieldRender}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </FieldContainer>
      <VSpace height={10} />
    </div>
  );
};

import { useMutation, useQueryClient } from "react-query";
import {
  UpdateFleetDefaultSettingParams,
  updateFleetDefaultSetting,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS, QUERY_KEYS } from "../queryKeys";

const updateFleetVehicleDefaultSetting = async (
  params: UpdateFleetDefaultSettingParams
) => {
  return await updateFleetDefaultSetting(params);
};

export function useUpdateFleetVehicleDefaultSetting() {
  const queryClient = useQueryClient();

  return useMutation(updateFleetVehicleDefaultSetting, {
    mutationKey: MUTATION_KEYS.updateFleetVehicleDefaultSetting,
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.fleetVehicleDefaultSettings);
    },
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

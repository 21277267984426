import { Overlay, Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";
import styled from "styled-components";

export const FormSpinner = () => {
  return (
    <StyledOverlay>
      <Spinner
        size={SpinnerSize.large}
        styles={{
          root: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 3,
          },
        }}
      />
    </StyledOverlay>
  );
};

const StyledOverlay = styled(Overlay)`
  z-index: 3;
  background: ${(p) =>
    p.theme.isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(255, 255, 255, 0.5)"};
`;

import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useCurrentRoute } from "../../config/routes";
import { useSelector } from "../../store/hooks";

export const useDynamicConfig = (d: any) => {
  const route = useCurrentRoute();
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext();
  const vehicle = useSelector((s) => s.vehicle.vehicle);
  const userRole = useSelector((s) => s.auth.userRole);
  const taxonomy = useSelector((s) => s.taxonomy);
  const dispatch = useDispatch();

  if (!d) return {};
  return d({
    route,
    t,
    values,
    vehicle,
    userRole,
    taxonomy,
    setFieldValue,
    errors,
    dispatch,
  });
};

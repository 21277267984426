import styled from "styled-components";

import { Flex, VSpace } from "components/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";

export const InsurerGroupPolicies = () => {
  const { t } = useTranslation();
  const fleet = useSelector((s) => s.fleetForm.fleetWithPremiums);

  return (
    <>
      <div
        id="group-policies-tab-mtpl"
        style={{
          marginTop: "20px",
        }}
      >
        <Table>
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>
                {t("bfm.mtplGroupPolicyNumber")}
              </th>
              <th style={{ textAlign: "left", width: "80px" }}>#</th>
            </tr>
          </thead>
          <tbody>
            <>
              {fleet?.mtplGroupPolicyInfo?.map((policy, index) => (
                <tr
                  key={"mtplGroupPolicyInfo" + index}
                  style={{ borderBottom: "1px solid rgb(237, 235, 233)" }}
                >
                  <td>{policy.groupPolicyNumber}</td>
                  <td>{policy.count}</td>
                </tr>
              ))}
              {fleet?.mtplGroupPolicyInfo?.length === 0 && (
                <tr
                  key={"mtplGroupPolicyInfo" + 0}
                  style={{ borderBottom: "1px solid rgb(237, 235, 233)" }}
                >
                  <td>{t("greco.noData")}</td>
                  <td>-</td>
                </tr>
              )}
            </>
          </tbody>
        </Table>
        <VSpace height={20} />
      </div>
      <div
        id="group-policies-tab-hull"
        style={{
          marginTop: "20px",
        }}
      >
        <Table>
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>
                {t("bfm.hullGroupPolicyNumber")}
              </th>
              <th style={{ textAlign: "left", width: "80px" }}>#</th>
            </tr>
          </thead>
          <tbody>
            <>
              {fleet?.hullGroupPolicyInfo?.map((policy, index) => (
                <tr
                  key={"hullGroupPolicyInfo" + index}
                  style={{ borderBottom: "1px solid rgb(237, 235, 233)" }}
                >
                  <td>{policy.groupPolicyNumber}</td>
                  <td>{policy.count}</td>
                </tr>
              ))}
              {fleet?.hullGroupPolicyInfo?.length === 0 && (
                <tr
                  key={"mtplGroupPolicyInfo" + 0}
                  style={{ borderBottom: "1px solid rgb(237, 235, 233)" }}
                >
                  <td>{t("greco.noData")}</td>
                  <td>-</td>
                </tr>
              )}
            </>
          </tbody>
        </Table>
        <VSpace height={20} />
      </div>
    </>
  );
};

export const Table = styled.table`
  border-collapse: collapse;
  border-top: 1px solid ${(p) => p.theme.palette.neutralLighterAlt};
  width: 100%;
  td {
    padding: 5px 8px;
  }
  th {
    padding: 5px;
    font-size: 12px;
    font-weight: 650;
    border-bottom: 1px solid ${(p) => p.theme.palette.neutralLighterAlt};
    height: 42px;
    color: ${(p) => p.theme.palette.neutralPrimary};
  }
`;

export const CellStyled = styled(Flex)`
  font-size: 12px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  text-align: left; /* Align text to the left */
  max-width: 100%;
  display: block; /* Use block instead of flex */
  padding: 10px; /* Add padding if necessary */
`;

const Cell = ({ children }) => {
  return (
    // <FluentTooltip content={children}>
    <CellStyled>{children}</CellStyled>
    // </FluentTooltip>
  );
};

import { Icon } from "office-ui-fabric-react";
import React, { FC, ReactNode, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

type TAccordion = {
  children: ReactNode;
  label: string;
};
const Accordion: FC<TAccordion> = ({ children, label }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const panelRef = useRef() as any;

  const toggleOpen = (e) => {
    e.preventDefault();
    setIsOpen((prev) => !prev);
  };
  return (
    <>
      <Headline onClick={toggleOpen} isOpen={isOpen}>
        <span>{t(label)}</span>
        <IconWrap isOpen={isOpen}>
          <Icon iconName="ChevronDown" />
        </IconWrap>
      </Headline>
      <Panel
        isOpen={isOpen}
        ref={panelRef}
        height={
          panelRef?.current ? panelRef?.current?.scrollHeight + "px" : "auto"
        }
      >
        {children}
      </Panel>
    </>
  );
};

export default Accordion;

const Headline = styled.div<{ isOpen: boolean }>`
  box-sizing: border-box;
  display: flex;
  padding: 15px 20px;
  width: 100%;
  gap: 5px;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.palette.neutralLight};
  border-radius: ${({ isOpen }) => (isOpen ? "3px 3px 0 0 " : "3px")};
  cursor: pointer;
`;

const IconWrap = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  i {
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "none")};
    transition: all 0.2s ease-in-out;
  }
`;

const Panel = styled.div<{ isOpen: boolean; height: string }>`
  max-height: ${({ isOpen, height }) => (isOpen ? height : "0px")};
  margin-bottom: ${({ isOpen }) => (isOpen ? "20px" : "0px")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.palette.neutralLight};
  border-top: none;
`;

import { useMutation } from "react-query";
import { duplicateVehicleWithPremiums } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const duplicateVehicleWithPremiumsFn = async (params: {
  vehicleId: string | number;
  fleetId: string | number;
  body: any;
}) => {
  return await duplicateVehicleWithPremiums(params);
};

export function useDuplicateVehicleWithPremiums() {
  //   const queryClient = useQueryClient();

  return useMutation(duplicateVehicleWithPremiumsFn, {
    mutationKey: MUTATION_KEYS.duplicateVehicleWithPremiums,
    onSuccess: () => {
      //   queryClient.invalidateQueries(QUERY_KEYS. );
    },
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

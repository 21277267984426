import { ActionButton } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { routes } from "../../../config/routes";

export const AddVehicleButton = () => {
  const { fleetId } = useParams() as any;
  const { t } = useTranslation();

  return (
    <Link to={routes.createVehicle.getPath(fleetId)}>
      <ActionButton
        iconProps={{ iconName: "Add" }}
        text={t("bfm.addVehicle")}
      />
    </Link>
  );
};

import React from "react";
import BookingDetails from "./BookingDetails/BookingDetails";
import { BookingSummary } from "./BookingSummary";

export const HullBasic = () => {
  return (
    <>
      <BookingSummary premiumType="HULL_BASIC" />
      <BookingDetails premiumType="HULL_BASIC" />
    </>
  );
};

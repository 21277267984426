import { useMutation } from "react-query";
import {
  ImportScanDocumentRequestParams,
  getScannedVehicleInfo,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const importScanDocument = async (params: ImportScanDocumentRequestParams) => {
  return await getScannedVehicleInfo(params);
};

export function useImportScanDocument() {
  return useMutation(importScanDocument, {
    mutationKey: MUTATION_KEYS.importScanDocument,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import { pointer } from "d3";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../../../store/hooks";
import { openContextMenu } from "../../../../../store/timeline";
import {
  PremiumType,
  TimelineContextMenuType,
  TimelineType,
} from "../../../../../types/types";
import { InfinityArrow } from "./InfinityArrow";
import { PendingStatusTooltip } from "./PendingStatusTooltip";
import { StatusSegmentTooltip } from "./StatusSegmentTooltip";
export enum StatusTimelineElementType {
  BAR,
  CIRCLE,
}
export const StatusSegments = ({ xScale, yScale, innerWidth }) => {
  const dispatch = useDispatch();

  const dateFilter = useSelector((s) => s.timeline.timeline.dateFilter);

  const timelineTypes = useSelector((s) => s.timeline.types);

  const appSettings = useSelector((s) => s.timeline.timelineAppSettings);

  const data = useSelector((s) => s.timeline.timeline.filteredData);
  if (
    !data ||
    !timelineTypes ||
    !timelineTypes.includes(TimelineType.VEHICLE_STATUS)
  )
    return null;

  const startDate = dateFilter[0];
  const endDate = dateFilter[1];

  let statusSegments = data.filter(
    (segment) => segment.domain.type === TimelineType.VEHICLE_STATUS
  );

  const pendingStatuses = [
    "VehicleStatusCode.PENDING_FOR_ACTIVATION",
    "VehicleStatusCode.PENDING_FOR_SUSPENSION",
    "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
  ];

  let statuses: any = <></>;
  // When there are no statuses draw gray bar
  if (statusSegments.length === 0) {
    let segmentClass = "status";
    let fillColor = "lightgray";
    const rectRef = React.createRef<SVGRectElement>();
    let x1 = xScale(startDate);
    let x2 = xScale(endDate);
    if (x1 < -10) x1 = -10;
    if (x2 > innerWidth + 10) x2 = innerWidth + 10;
    let width = x2 - x1;
    statuses = (
      <rect
        ref={rectRef}
        className={`${segmentClass} clickable`}
        x={x1}
        width={width}
        y={
          yScale(PremiumType.STATUS) +
          yScale.bandwidth() / 2 -
          appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2
        }
        height={appSettings?.VEHICLE_STATUS_BAR_HEIGHT}
        fill={fillColor}
        onClick={(e) => {
          return dispatch(
            openContextMenu({
              type: TimelineContextMenuType.STATUS_BAR,
              item: null,
              ref: { x: e.pageX, y: e.pageY },
              date: xScale.invert(pointer(e)[0]),
            })
          );
        }}
      ></rect>
    );
  } else {
    // Draw statuses
    statuses = statusSegments.map((d, index) => {
      let segmentClass = "";
      let fillColor = "";
      let strokeColor = "";
      let strokeWidth = "";

      strokeColor = "#444444";
      const arrowStrokeColor = "#eeeeee";
      strokeWidth = "2";
      let pointColor = "white";
      if (d.domain.value === "VehicleStatusCode.ACTIVE") {
        segmentClass = "status";
        fillColor = "#0b6a0b";
        pointColor = "green";
      }
      if (d.domain.value === "VehicleStatusCode.SUSPENDED") {
        segmentClass = "status";
        fillColor = "#a4262c";
        pointColor = "red";
      }
      if (d.domain.value === "VehicleStatusCode.DEACTIVATED") {
        segmentClass = "status";
        fillColor = "lightgray";
        pointColor = "gray";
      }
      const rectRef = React.createRef<SVGRectElement>();
      let x1 = xScale(d.startDate);
      let x2 = xScale(d.endDate);
      if (x1 < 0) x1 = 0;
      if (x2 > innerWidth) x2 = innerWidth;
      let width = x2 - x1;

      const vehicleStatus = d.domain.value;
      if (pendingStatuses.includes(vehicleStatus)) {
        // const pendingStatusDate = moment(vehicleLastStatusDate).toDate();
        //const pendingStatusComment = vehicleLastStatusComment;
        if (vehicleStatus === "VehicleStatusCode.PENDING_FOR_ACTIVATION") {
          pointColor = "green";
        } else if (
          vehicleStatus === "VehicleStatusCode.PENDING_FOR_SUSPENSION"
        ) {
          pointColor = "red";
        } else if (
          vehicleStatus === "VehicleStatusCode.PENDING_FOR_DEACTIVATION"
        ) {
          pointColor = "gray";
        } else {
          pointColor = "white";
        }
      }
      const bar = (
        <>
          {d.domain.value !== "VehicleStatusCode.DEACTIVATED" ? (
            <StatusSegmentTooltip d={d}>
              <rect
                id={
                  "status1_" +
                  (d.associatedObject && d.associatedObject?.statusId
                    ? d.associatedObject.statusId
                    : index)
                }
                ref={rectRef}
                className={`${segmentClass} clickable`}
                x={x1}
                width={width}
                y={
                  yScale(PremiumType.STATUS) +
                  yScale.bandwidth() / 2 -
                  appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2
                }
                height={appSettings?.VEHICLE_STATUS_BAR_HEIGHT}
                fill={fillColor}
                onClick={(e) => {
                  return dispatch(
                    openContextMenu({
                      type: TimelineContextMenuType.STATUS_BAR,
                      item: d,
                      ref: { x: e.pageX, y: e.pageY },
                      date: xScale.invert(pointer(e)[0]),
                    })
                  );
                }}
              ></rect>
            </StatusSegmentTooltip>
          ) : null}{" "}
          {d.extraData.isLastSegmentInLine &&
          d.domain.value !== "VehicleStatusCode.DEACTIVATED" ? (
            <InfinityArrow
              yScale={yScale}
              strokeColor={arrowStrokeColor}
              d={d}
              x2={x2}
              domainValueFunc={(d) => PremiumType.STATUS}
            />
          ) : (
            <StatusSegmentTooltip d={d}>
              <rect
                id={
                  "status2_" +
                  (d.associatedObject && d.associatedObject?.statusId
                    ? d.associatedObject.statusId
                    : index)
                }
                ref={rectRef}
                className={`${segmentClass} clickable`}
                x={x1}
                width={width}
                y={
                  yScale(PremiumType.STATUS) +
                  yScale.bandwidth() / 2 -
                  appSettings?.VEHICLE_STATUS_BAR_HEIGHT / 2
                }
                height={appSettings?.VEHICLE_STATUS_BAR_HEIGHT}
                fill={fillColor}
                onClick={(e) => {
                  return dispatch(
                    openContextMenu({
                      type: TimelineContextMenuType.STATUS_BAR,
                      item: d,
                      ref: { x: e.pageX, y: e.pageY },
                      date: xScale.invert(pointer(e)[0]),
                    })
                  );
                }}
              ></rect>
            </StatusSegmentTooltip>
          )}
        </>
      );

      return (
        <g key={index}>
          {bar}
          {pendingStatuses.includes(vehicleStatus) ? (
            <PendingStatusTooltip d={d}>
              <g
                id={
                  "status3_" +
                  (d.associatedObject && d.associatedObject?.statusId
                    ? d.associatedObject.statusId
                    : index)
                }
                onClick={(e) => {
                  return dispatch(
                    openContextMenu({
                      type: TimelineContextMenuType.STATUS_POINT,
                      item: d,
                      ref: { x: e.pageX, y: e.pageY },
                      date: null,
                    })
                  );
                }}
              >
                <circle
                  className={"status-point"}
                  r="10"
                  cx={x1}
                  cy={yScale(PremiumType.STATUS) + yScale.bandwidth() / 2}
                  fill={"white"}
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                  strokeDasharray={"5, 2"}
                />
                <circle
                  className={`status-point-${pointColor}`}
                  r="5"
                  cx={x1}
                  cy={yScale(PremiumType.STATUS) + yScale.bandwidth() / 2}
                  stroke={strokeColor}
                  strokeWidth={0}
                />
              </g>
            </PendingStatusTooltip>
          ) : (
            <StatusSegmentTooltip d={d}>
              <g
                id={
                  "status4_" +
                  (d.associatedObject && d.associatedObject?.statusId
                    ? d.associatedObject.statusId
                    : index)
                }
                onClick={(e) => {
                  //openStatusContextMenu
                  return dispatch(
                    openContextMenu({
                      type: TimelineContextMenuType.STATUS_POINT,
                      item: d,
                      ref: { x: e.pageX, y: e.pageY },
                      date: xScale.invert(pointer(e)[0]),
                    })
                  );
                }}
              >
                <circle
                  className={"status-point"}
                  r="10"
                  cx={x1}
                  cy={yScale(PremiumType.STATUS) + yScale.bandwidth() / 2}
                  fill={"white"}
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                />
                <circle
                  className={`status-point-${pointColor}`}
                  r="5"
                  cx={x1}
                  cy={yScale(PremiumType.STATUS) + yScale.bandwidth() / 2}
                  stroke={strokeColor}
                  strokeWidth={0}
                />
              </g>
            </StatusSegmentTooltip>
          )}
        </g>
      );
    });
  }
  // Pending

  return <>{statuses}</>;
};

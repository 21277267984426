import { useMutation } from "react-query";
import { AdjustFleetParams, adjustFleet } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const adjustFleetFn = async (params: AdjustFleetParams) => {
  return await adjustFleet(params);
};

export function useAdjustFleet() {
  return useMutation(adjustFleetFn, {
    mutationKey: MUTATION_KEYS.adjustFleet,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import FluentTooltip from "components/FluentTooltip";
import React from "react";

type Props = {
  hasMultipleInsurers: boolean;
  mainPartnerName: string;
  mainPartnerNumber: number;
};

function MainInsurerCell({
  hasMultipleInsurers,
  mainPartnerName,
  mainPartnerNumber,
}: Props) {
  return (
    <FluentTooltip
      content={
        (hasMultipleInsurers ? "* " : "") +
        (mainPartnerName
          ? mainPartnerName + " (" + mainPartnerNumber + ")"
          : "-")
      }
    >
      {(hasMultipleInsurers ? "* " : "") +
        (mainPartnerNumber
          ? mainPartnerName + " (" + mainPartnerNumber + ")"
          : " -")}
    </FluentTooltip>
  );
}

export default MainInsurerCell;

import AppSettings, { InsurerSettingsTemplate } from "AppSettings";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import { PremiumType, UserRole } from "types/types";
import NumberInputField from "../NumberInputField";
const suspensionDaysPremiumTypes = [
  PremiumType.MTPL,
  PremiumType.HULL,
  PremiumType.HULL_BASIC,
  PremiumType.LEGAL,
  PremiumType.ACCIDENT,
  PremiumType.ASSISTANCE,
  PremiumType.HORSE_POWER,
];
const SuspendedDaysLimits = ({
  props,
  classesTab,
  selectedInsurer,
  classes,
}: any) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const userRole = useSelector((s) => s.auth.userRole);

  const insurerCountrySettings =
    AppSettings.getInstance().getAppSettingsTypes()[
      taxonomy.Country.byId[selectedInsurer?.countryCodeId]?.code
    ];
  if (!props.values || !insurerCountrySettings) {
    return null;
  }
  let daysTableHeader = null;
  let daysTable = null;
  if (
    insurerCountrySettings.INSURER_SETTINGS_TEMPLATES.includes(
      InsurerSettingsTemplate.SUSPENDED_DAYS_LIMITS
    )
  ) {
    let i = 0;
    daysTableHeader = suspensionDaysPremiumTypes.map((item, index) => {
      return (
        <div key={`e${index}`} className={classesTab.days}>
          <div className={classes.textColor}>{t(item)}</div>
        </div>
      );
    });
    daysTable = taxonomy?.VehicleMainGroup?.items.map((item, index1) => {
      return (
        <React.Fragment key={`eee${index1}`}>
          <div className={classesTab.days_left}>
            <div className={classes.textColor}>
              {item.code.replaceAll("VehicleMainGroup.", "")}
            </div>
          </div>
          {suspensionDaysPremiumTypes.map((item2, index2) => {
            const retVal = (
              <div key={`d_${index1}_${index2}`} className={classesTab.days}>
                <NumberInputField
                  setFieldValue={props.setFieldValue}
                  value={props.values?.suspensionDaysLimit[i]}
                  errors={props.errors}
                  onChange={props.handleChange}
                  name={`suspensionDaysLimit[${i}]`}
                  disabled={userRole !== UserRole.admin}
                  label={""}
                  tooltip={t("bfm.suspensionDaysLimitMatrix.label", [
                    item.code.replaceAll("VehicleMainGroup.", ""),
                    t(item2),
                  ])}
                />
              </div>
            );
            i = i + 1;
            return retVal;
          })}
        </React.Fragment>
      );
    });
  }

  return (
    <>
      {insurerCountrySettings.INSURER_SETTINGS_TEMPLATES.includes(
        InsurerSettingsTemplate.SUSPENDED_DAYS_LIMITS
      ) ? (
        <>
          <div className={classesTab.root}>
            <div className={classes.textColorBold}>
              {t("bfm.vehicleForm.suspensionDaysLimit.label")}
            </div>
          </div>
          <div className={classesTab.root} id="days-tab">
            <div className={classesTab.days_left}></div>
            {daysTableHeader}
            {daysTable}
          </div>
        </>
      ) : null}
    </>
  );
};
export default SuspendedDaysLimits;

import { allowedVehicleStatuses } from "../config/businessLogic";
import { useSelector } from "../store/hooks";
import { Vehicle, VehicleRedux } from "../types/types";

export const useIsVehicleStatusOptionDisabled = (
  vehicle?: VehicleRedux | Vehicle
) => {
  const taxonomy = useSelector((s) => s.taxonomy);
  const userRole = useSelector((s) => s.auth.userRole);

  if (!vehicle) return () => true;
  if (userRole === undefined || userRole === null) return () => true;

  const vehicleStatus = vehicle.vehicleStatusCode;
  const vehicleStatusTaxCode =
    taxonomy.VehicleStatusCode.byId[vehicleStatus]?.code;

  if (!vehicleStatusTaxCode) return () => true;

  return (optionVehicleStatusTaxId: number) => {
    const optionVehicleStatusTaxCode =
      taxonomy.VehicleStatusCode.byId[optionVehicleStatusTaxId]?.code;
    const allowedStatusCodes = allowedVehicleStatuses[userRole](
      vehicle as any,
      vehicleStatusTaxCode
    );
    return !allowedStatusCodes.includes(optionVehicleStatusTaxCode);
  };
};

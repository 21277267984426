import { useMutation } from "react-query";
import {
  VehicleOneTimePremiumRequestParams,
  insertVehicleOneTimePremium,
} from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const insertVehicleOneTimePremiumFn = async ({
  rowVersion,
  params,
}: {
  rowVersion: string;
  params: VehicleOneTimePremiumRequestParams;
}) => {
  return await insertVehicleOneTimePremium(rowVersion, params);
};

export function useInsertVehicleOneTimePremium() {
  return useMutation(insertVehicleOneTimePremiumFn, {
    mutationKey: MUTATION_KEYS.insertVehicleOneTimePremium,
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Label,
} from "@fluentui/react";
import { formatISO } from "date-fns";
import { Formik, Field as FormikField } from "formik";
import { useChangeVehicleStatus } from "hooks/data/mutations/useChangeVehicleStatus";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { toast } from "../../../components/FluentToast";
import { FormSpinner } from "../../../components/FormSpinner";
import { VSpace } from "../../../components/Spacer";
import { VehicleStatusSelect } from "../../../components/VehicleStatusSelect";
import { Field } from "../../../components/form/Field";
import { combine, required } from "../../../components/form/validation";
import { ErrorMessage, StyledPrimaryButton } from "../../../components/styled";
import { useIsVehicleStatusOptionDisabled } from "../../../components/useIsVehicleStatusOptionDisabled";
import { allowedVehicleStatuses } from "../../../config/businessLogic";
import * as Colors from "../../../config/colors";
import { useSelector } from "../../../store/hooks";
import { AppDispatch } from "../../../store/store";
import {
  setChangeVehicleStatusDialog,
  setVehicle,
} from "../../../store/vehicle";
import { getSelectStyles } from "../../../utils/utils";

export const ChangeVehicleStatusDialog = () => {
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;
  const vehicle = useSelector((s) => s.vehicle.vehicle);

  const { mutate: onChangeVehicleStatus, isLoading: changeStatusInProgress } =
    useChangeVehicleStatus();

  const date = useSelector((s) => s.vehicle.changeVehicleStatusDialog.date);
  const addNew = useSelector((s) => s.vehicle.changeVehicleStatusDialog.addNew);
  const phraseActivated = usePhraseActivationStatus().activated;
  const dispatch: AppDispatch = useDispatch();
  const taxonomy = useSelector((s) => s.taxonomy);

  const userRole = useSelector((s) => s.auth.userRole);

  const isOptionDisabled = useIsVehicleStatusOptionDisabled(
    vehicle || undefined
  );
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const close = () => {
    dispatch(
      setChangeVehicleStatusDialog({
        isOpen: false,
        vehicleIds: null,
        date: null,
        fleetId: null,
        vehicle: null,
        addNew: null,
      })
    );
  };

  const vehicleStatusTaxCode =
    taxonomy.VehicleStatusCode.byId[vehicle?.vehicleStatusCode]?.code;
  let allowedStatusCodes = useMemo(() => {
    let allowedStatusCodes = [];

    if (!addNew && vehicleStatusTaxCode) {
      allowedStatusCodes = allowedVehicleStatuses[userRole](
        vehicle as any,
        vehicleStatusTaxCode
      );
    } else if (addNew) {
      allowedStatusCodes = allowedVehicleStatuses[userRole](
        vehicle as any,
        null
      );
    }
    return allowedStatusCodes;
  }, [addNew, userRole, vehicle, vehicleStatusTaxCode]);

  const options = useMemo(() => {
    return taxonomy.VehicleStatusCode.items
      .filter((tax) => allowedStatusCodes.includes(tax.code))
      .map((tax) => ({
        label: t(tax.code),
        value: tax.id,
        color: Colors.vehicleStatus[tax.code],
        code: tax.code,
      }));
  }, [taxonomy.VehicleStatusCode.items, allowedStatusCodes, t]);

  const onSubmit = (data) => {
    if (vehicle) {
      onChangeVehicleStatus(
        {
          rowVersion: vehicle.rowVersion,
          fleetId: "" + vehicle.fleetId,
          vehicleId: "" + vehicle.vehicleId,
          vehicleStatusCode: data.vehicleStatusCode.value,
          vehicleLastStatusComment: data.vehicleLastStatusComment,
          vehicleLastStatusDate: formatISO(data.vehicleLastStatusDate, {
            representation: "date",
          }),
        },
        {
          onSuccess: (res) => {
            dispatch(
              setVehicle(Array.isArray(res.data) ? res.data[0] : res.data)
            );
            dispatch(
              setChangeVehicleStatusDialog({
                isOpen: false,
                vehicle: null,
                fleetId: null,
                vehicleIds: [],
                date: null,
                addNew: null,
              })
            );
            toast.success(t("bfm.statusChanged"));
          },
        }
      );
    }
  };

  const title = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {vehicle ? t("bfm.changeStatus.label") : null}
    </div>
  );

  const initialValues = useMemo(() => {
    let currentOption = null;
    if (!vehicle) return {};
    const statusTax =
      taxonomy.VehicleStatusCode.byId[vehicle.vehicleStatusCode];
    const statusTaxCode = statusTax?.code;
    if (statusTax) {
      currentOption = {
        label: t(statusTax?.code),
        value: vehicle.vehicleStatusCode,
        color: Colors.vehicleStatus[statusTax?.code],
      } as any;
    }
    let vehicleLastStatusDate = date;
    let pendingComment = "";
    if (statusTaxCode === "VehicleStatusCode.PENDING_FOR_ACTIVATION") {
      vehicleLastStatusDate = new Date(vehicle.vehicleLastStatusDate);
      pendingComment = vehicle.vehicleLastStatusComment;
    }
    //
    else if (statusTaxCode === "VehicleStatusCode.PENDING_FOR_SUSPENSION") {
      vehicleLastStatusDate = new Date(vehicle.vehicleLastStatusDate);
      pendingComment = vehicle.vehicleLastStatusComment;
    }
    //
    else if (statusTaxCode === "VehicleStatusCode.PENDING_FOR_DEACTIVATION") {
      vehicleLastStatusDate = new Date(vehicle.vehicleLastStatusDate);
      pendingComment = vehicle.vehicleLastStatusComment;
    }
    return {
      from: currentOption,
      vehicleLastStatusDate,
      pendingComment,
    };
  }, [vehicle, taxonomy, t, date]);

  return (
    <Dialog
      hidden={false}
      onDismiss={close}
      dialogContentProps={{
        type: DialogType.normal,
        title,
        showCloseButton: true,
      }}
      minWidth={400}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={didTrySubmit}
        validateOnBlur={didTrySubmit}
        enableReinitialize
      >
        {({ submitForm }) => {
          return (
            <>
              {!addNew ? (
                <>
                  <Label>{t("bfm.statusChangeFrom.label")}</Label>
                  <FormikField
                    name="from"
                    render={({ field }) => {
                      return (
                        <VehicleStatusSelect
                          {...field}
                          options={[field.value]}
                          isDisabled
                          styles={getSelectStyles(false, theme)}
                        />
                      );
                    }}
                  />

                  <VSpace />
                </>
              ) : null}
              {[
                "VehicleStatusCode.PENDING_FOR_ACTIVATION",
                "VehicleStatusCode.PENDING_FOR_SUSPENSION",
                "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
              ].includes(vehicleStatusTaxCode) && (
                <>
                  <Field
                    name="pendingComment"
                    type="text"
                    isMultiline
                    rows={4}
                    label={t("bfm.pendingComment.label")}
                    isDisabled
                  />
                  <VSpace />
                </>
              )}
              <Label required>{t("bfm.statusChangeTo.label")}</Label>
              <FormikField
                name="vehicleStatusCode"
                validate={(value) => combine(required(value))}
                render={({
                  field,
                  form: { setFieldValue },
                  meta: { error },
                }) => {
                  return (
                    <>
                      <VehicleStatusSelect
                        {...field}
                        onChange={(option) => {
                          if (!option) return;
                          setFieldValue("vehicleStatusCode", option);
                        }}
                        options={options}
                        isOptionDisabled={(o) =>
                          !addNew ? isOptionDisabled(o.value) : false
                        }
                        styles={getSelectStyles(!!error, theme)}
                        portalTarget={undefined}
                      />
                      {!!error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  );
                }}
              />
              <VSpace />
              <Field
                name="vehicleLastStatusDate"
                type="date"
                label={t("bfm.date.label")}
                validate={(value) => combine(required(value))}
                isRequired
                d={({ t, values, errors, setFieldValue }) => ({
                  label: t("bfm.date.label"),
                  values,
                  errors,
                  setFieldValue,
                  defaultValue: values?.vehicleLastStatusDate,
                })}
              />
              <Field
                name="vehicleLastStatusComment"
                type="text"
                label={t("bfm.comment.label")}
                isMultiline
                rows={4}
              />
              <VSpace height={25} />
              <DialogFooter>
                <SaveButton
                  onClick={() => {
                    setDidTrySubmit(true);
                    submitForm();
                  }}
                />
                <DefaultButton onClick={close} text={t("bfm.close.label")} />
              </DialogFooter>
              {changeStatusInProgress && <FormSpinner />}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const SaveButton = ({
  onClick,
  isLoading,
  isDisabled,
}: {
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <StyledPrimaryButton
      onClick={onClick}
      text={t("bfm.saveStatusChange.label")}
      disabled={isDisabled}
      style={{
        marginRight: "5px",
      }}
      iconProps={{
        iconName: isLoading ? "ProgressRingDots" : "",
      }}
    />
  );
};

import { useMutation } from "react-query";
import { correctVehicleStatusDateForNonPendingStatuses } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

export const correctVehicleStatusDate = async (params: {
  vehicleId: any;
  fleetId: any;
  comment: string;
  vehicleStatusCode: number;
  vehicleLastStatusDate: string;
}) => {
  return await correctVehicleStatusDateForNonPendingStatuses(params);
};

export function useCorrectVehicleStatusDate() {
  return useMutation(correctVehicleStatusDate, {
    mutationKey: MUTATION_KEYS.correctVehicleStatusDate,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

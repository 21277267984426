import { useMutation, useQueryClient } from "react-query";
import { updateInsurerSettings } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS, QUERY_KEYS } from "../queryKeys";

const updateInsurerSettingsFn = async (params) => {
  return await updateInsurerSettings(params);
};

export function useUpdateInsurerSettings() {
  const queryClient = useQueryClient();
  return useMutation(updateInsurerSettingsFn, {
    mutationKey: MUTATION_KEYS.updateInsurerSettings,
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.insurerSettings);
    },
    onError: (err) => {
      handleAxiosError(err);
    },
  });
}

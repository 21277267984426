import { Icon } from "@fluentui/react";
import React from "react";

import styled, { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { IDropFile } from "./Dropzone";

type Props = {
  setFiles: (file: IDropFile[]) => void;
  files: IDropFile[];
};

function MultipleUploadPreview({ files, setFiles }: Props) {
  const theme = useTheme() as IStyledTheme;

  return (
    <PreviewContainer>
      {files?.map((item) => {
        return (
          <PreviewItem
            key={item.tempId}
            onClick={() => {
              const filteredFiles = files.filter(
                (file) => file.tempId !== item.tempId
              );
              setFiles(filteredFiles);
            }}
          >
            <div>
              <StyledIcon small iconName="ExcelDocument" hasMr />
              {item.name}
            </div>
            <StyledIcon
              small
              iconName="Clear"
              styles={{
                root: {
                  color: theme.palette.redDark + " !important",
                  fontSize: "12px !important",
                  cursor: "pointer",
                },
              }}
            />
          </PreviewItem>
        );
      })}
    </PreviewContainer>
  );
}

export default MultipleUploadPreview;

const StyledIcon = styled(Icon)<{ small?: boolean; hasMr?: boolean }>`
  font-size: ${({ small }) => (small ? "16px" : "25px")};
  margin-right: ${({ hasMr }) => (hasMr ? "10px" : "0")};
  color: ${(p) => p.theme.palette.greenDark};
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PreviewItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 7px;
`;

import {
  Checkbox,
  DefaultButton,
  DialogFooter,
  mergeStyleSets,
} from "@fluentui/react";
import ComboBoxField from "components/controls/ComboBoxField";
import DatePickerField from "components/controls/DatePickerField";
import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import GroupPolicyNumberField from "components/controls/GroupPolicyNumberField";
import NumberInputField from "components/controls/NumberInputField";
import TextInputField from "components/controls/TextInputField";
import { EActionDialog } from "pages/fleet-form/FleetForm/constants";
import VehicleTypeDependencyField from "pages/fleet-form/actionDialogs/VehicleTypeDependencyField";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";

const HullDeductibleFormFields = ({
  settingId,
  fieldProps,
  defaultData,
  insurerOptions,
  carUsageOptions,
  vehicleTypeCodeOptions,
  isLoading,
  premiumTypeOptions,
  isTradeLicensePlate,
  setTradeLicensePlate,
  setIsDirty,
  listPriceTypeOptions,
}) => {
  const classes = getClassNames();
  const { setFieldValue, values, errors, handleChange, dirty } = fieldProps;
  const { t } = useTranslation();
  const isReadOnly =
    useSelector((s) => s.fleetForm.dialog.type) === EActionDialog.ReadOnly;
  const fleetAppSettings = useSelector((s) => s.fleetForm.fleetAppSettings);
  const mtplGroupPolicyInfo = useSelector(
    (s) => s.fleetForm.fleetWithPremiums.mtplGroupPolicyInfo
  );
  const hullGroupPolicyInfo = useSelector(
    (s) => s.fleetForm.fleetWithPremiums.hullGroupPolicyInfo
  );
  const insurerGroupPolicyNumberOptions = useMemo(() => {
    if (
      values?.premiumType?.code === "HULL" ||
      values?.premiumType?.code === "HULL_BASIC"
    ) {
      return hullGroupPolicyInfo?.map((policyInfo) => ({
        label: policyInfo.groupPolicyNumber,
        value: policyInfo.groupPolicyNumber,
      }));
    }
    if (values?.premiumType?.code === "MTPL") {
      return mtplGroupPolicyInfo?.map((policyInfo) => ({
        label: policyInfo.groupPolicyNumber,
        value: policyInfo.groupPolicyNumber,
      }));
    }
  }, [values?.premiumType, hullGroupPolicyInfo, mtplGroupPolicyInfo]);

  useEffect(() => {
    setIsDirty(dirty);
  }, [dirty, setIsDirty]);
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem} style={{ marginRight: "10px" }}>
          <Checkbox
            id="isTradeLicensePlate"
            label={t("bfm.tradeLicensePlates.label")}
            styles={{
              root: {
                fontWeight: 600,
              },
            }}
            disabled={isReadOnly}
            checked={isTradeLicensePlate}
            onChange={(ev) => {
              setFieldValue("vehicleType", null);
              setFieldValue("carUsage", null);
              setTradeLicensePlate(!isTradeLicensePlate);
            }}
          />
        </div>
        <div className={classes.fieldItem} style={{ marginLeft: "10px" }}></div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem} style={{ marginRight: "10px" }}>
          <ComboBoxField
            id="premiumType"
            disabled={isReadOnly}
            errors={errors}
            name="premiumType"
            required
            label={"bfm.fleet.premiumType.label"}
            setFieldValue={setFieldValue}
            options={premiumTypeOptions || []}
            defaultValue={values?.premiumType}
            confidentiality={null}
          />
        </div>

        <div className={classes.fieldItem} style={{ marginLeft: "10px" }}>
          <DatePickerField
            errors={errors}
            setFieldValue={setFieldValue}
            defaultValue={values.validFromDate}
            name={"validFromDate"}
            disabled={false}
            label={"bfm.vehicleForm.validFromDate.label"}
            placeholder={t("bfm.selectDate")}
            required
            confidentiality={null}
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem} style={{ marginRight: "10px" }}>
          <DoubleNumberInputField
            id="attributeValue"
            setFieldValue={setFieldValue}
            value={values.hullDeductibleMinAmount}
            onChange={handleChange}
            errors={errors}
            name={"hullDeductibleMinAmount"}
            disabled={isReadOnly}
            label={"bfm.vehicleForm.hullDeductibleMinAmount.label"}
          />
        </div>
        <div className={classes.fieldItem} style={{ marginLeft: "10px" }}>
          <DoubleNumberInputField
            id="hullDeductibleMaxAmount"
            setFieldValue={setFieldValue}
            value={values.hullDeductibleMaxAmount}
            onChange={handleChange}
            errors={errors}
            name={"hullDeductibleMaxAmount"}
            disabled={isReadOnly}
            label={"bfm.vehicleForm.hullDeductibleMaxAmount.label"}
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem} style={{ marginRight: "10px" }}>
          <NumberInputField
            id="hullDeductiblePercent"
            setFieldValue={setFieldValue}
            value={values.hullDeductiblePercent}
            onChange={handleChange}
            errors={errors}
            name={"hullDeductiblePercent"}
            disabled={isReadOnly}
            label={"bfm.vehicleForm.hullDeductiblePercent.label"}
          />
        </div>
        <div className={classes.fieldItem} style={{ marginLeft: "10px" }}>
          <TextInputField
            id="hullDeductibleComment"
            multiline={true}
            rows={2}
            setFieldValue={setFieldValue}
            value={values.hullDeductibleComment}
            onChange={handleChange}
            errors={errors}
            name={"hullDeductibleComment"}
            disabled={isReadOnly}
            label={"bfm.vehicleForm.hullDeductibleComment.label"}
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {fleetAppSettings?.INCLUDE_GROUP_POLICY ? (
          <div className={classes.fieldItem} style={{ marginRight: "10px" }}>
            <GroupPolicyNumberField
              id="insurerGroupPolicyNumber"
              setFieldValue={setFieldValue}
              options={insurerGroupPolicyNumberOptions || []}
              defaultValue={values?.insurerGroupPolicyNumber}
              errors={errors}
              name={"insurerGroupPolicyNumber"}
              disabled={isReadOnly}
              label={"bfm.fleet.setting.insurerGroupPolicyNumber.label"}
              confidentiality={null}
              isClearable={false}
            />
          </div>
        ) : (
          <div
            className={classes.fieldItem}
            style={{ marginRight: "10px" }}
          ></div>
        )}
        <div className={classes.fieldItem} style={{ marginLeft: "10px" }}>
          <ComboBoxField
            id="listPriceType"
            disabled={isReadOnly}
            errors={errors}
            name="listPriceType"
            required
            label={"bfm.vehicleForm.listPriceTypeCode.label"}
            setFieldValue={setFieldValue}
            options={listPriceTypeOptions || []}
            defaultValue={values?.listPriceType}
            confidentiality={null}
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem} style={{ marginRight: "10px" }}>
          <ComboBoxField
            id="insurerPartnerNumber"
            disabled={isReadOnly}
            errors={errors}
            required
            name="insurerPartnerNumber"
            label={"bfm.insurer.label"}
            setFieldValue={setFieldValue}
            options={insurerOptions || []}
            defaultValue={values?.insurerPartnerNumber}
            confidentiality={null}
          />
        </div>
        <div className={classes.fieldItem} style={{ marginLeft: "10px" }}>
          <div
            className={classes.fieldItem}
            style={{ display: "flex", gap: "15px" }}
          >
            <div style={{ width: "50%" }}>
              <DoubleNumberInputField
                id="listPriceMin"
                setFieldValue={setFieldValue}
                value={values.listPriceMin}
                onChange={handleChange}
                errors={errors}
                required={
                  values.listPriceMin !== "" || values.listPriceMax !== ""
                }
                name={"listPriceMin"}
                disabled={isReadOnly}
                label={"bfm.listPriceMin.label"}
              />
            </div>
            <div style={{ width: "50%" }}>
              <DoubleNumberInputField
                id="listPriceMax"
                setFieldValue={setFieldValue}
                value={values.listPriceMax}
                onChange={handleChange}
                errors={errors}
                required={
                  values.listPriceMin !== "" || values.listPriceMax !== ""
                }
                name={"listPriceMax"}
                disabled={isReadOnly}
                label={"bfm.listPriceMax.label"}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem} style={{ marginRight: "10px" }}>
          {!isTradeLicensePlate ? (
            <ComboBoxField
              id="carUsage"
              disabled={isReadOnly}
              errors={errors}
              name="carUsage"
              label={"bfm.vehicleForm.carUsageCode.label"}
              setFieldValue={setFieldValue}
              options={carUsageOptions || []}
              defaultValue={values?.carUsage}
              confidentiality={null}
              isMulti={true}
            />
          ) : null}
        </div>
        <div className={classes.fieldItem} style={{ marginLeft: "10px" }}>
          {!isTradeLicensePlate ? (
            <ComboBoxField
              id="vehicleType"
              disabled={isTradeLicensePlate || isReadOnly}
              errors={errors}
              name="vehicleType"
              required={!isTradeLicensePlate}
              label={"bfm.vehicleForm.vehicleTypeCode.label"}
              setFieldValue={setFieldValue}
              options={vehicleTypeCodeOptions || []}
              defaultValue={values?.vehicleType}
              confidentiality={null}
              isMulti={true}
            />
          ) : null}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <VehicleTypeDependencyField
          values={values}
          vehicleTypeSelected={values?.vehicleType}
          fieldItemStyle={classes.fieldItem}
          errors={errors}
          setFieldValue={setFieldValue}
          selectedDependency={defaultData}
          disabled={isReadOnly}
        />
      </div>
      <div className={classes.placeholder}></div>
      {!isReadOnly && (
        <DialogFooter className={classes.footer}>
          <DefaultButton
            id="saveButton"
            primary
            text={t("greco.save")}
            type="submit"
            iconProps={{
              iconName: "Save",
            }}
            disabled={isLoading}
          />
        </DialogFooter>
      )}
    </>
  );
};

const getClassNames = () =>
  mergeStyleSets({
    placeholder: {
      height: 200,
    },
    fieldItem: {
      width: "100%",
      marginTop: 10,
    },
    footer: {
      marginTop: 50,
    },
  });

export default HullDeductibleFormFields;

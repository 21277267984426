import * as yup from "yup";

export const validationObjectConfig = (): any => {
  const fieldSets = [];

  {
    let yupObject: any = {};
    yupObject = {
      vehicleIssuingStatusCode: yup.mixed().required(),

      vehicleIssuingSubstatusCode: yup
        .mixed()
        .when("vehicleIssuingStatusCode", {
          is: (field) => field && field?.label === "Incorrect", //
          then: yup.mixed().required(),
          otherwise: yup.mixed().nullable(),
        }),

      vehicleIssuingComment: yup.string().trim().max(512),
    };
    return yupObject;
  }
};

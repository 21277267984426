import axios, { CancelToken } from "axios";
import { handleAxiosError } from "store/util";
import { ExpandedColumn } from "../../types/columns";
import {
  CreateUpdateFleetData,
  CreateUpdateFleetResponse,
  ExcelReportResponse,
  Fleet,
  FleetDefaultSetting,
  FleetUser,
  FleetUserResponse,
  FleetWithPremiumsResponse,
  IUpdateMultipleVehicleIssuingStatus,
  IUpdateMultipleVehiclePendingIssuingStatus,
  IUpdateVehicleIssuingStatus,
  Insurer,
  InsurerSettings,
  PowerBIEmbedMeta,
  Setting,
  SettingsResponse,
  SharedLicensePlate,
  TaxonomyResponse,
  TaxonomyUADResponse,
  Vehicle,
  VehicleAzure,
  VehiclesAzureResponse,
} from "../../types/types";
import { MSGraphAuth } from "./GraphService";
import { createAzureFilterParam } from "./azureSearchFilterParam/azureSearchFilterParam";

const url = process.env.REACT_APP_BASE_URL;
const uadUrl = process.env.REACT_APP_BFF_HOST_UAD;

export const getFleets = async () => {
  const res = await axios.get<Fleet>(`${url}/GetMyFleetList`);
  return res;
};

export const getFleetsByClientId = async (clientId: number | string) => {
  const res = await axios.get<Fleet>(`${url}/fleets/client/${clientId}`);
  return res;
};

export const getClients = async () => {
  const res = await axios.get<Fleet>(`${url}/GetMyClientList`);
  return res;
};

export const GetFleetById = async (fleetId: number | string) => {
  const res = await axios.get<Fleet>(`${url}/GetFleetById/${fleetId}`);
  return res;
};

export const getFleetWithPremiums = async (fleetId: number | string) => {
  const res = await axios.get<FleetWithPremiumsResponse>(
    `${url}/GetFleetWithPremiums/${fleetId}`
  );
  return res;
};
export const getInsurers = async (countryId: string) => {
  let baseUrl = `${url}/insurers`;
  if (countryId !== null) {
    baseUrl += `/country/${countryId}`;
  }
  const res = await axios.get<InsurerSettings>(baseUrl);
  return res;
};

export const createInsurerSettings = async (insurerSettings: any) => {
  const res = await axios.post(`${url}/insurers`, insurerSettings);
  return res.data;
};

export const updateInsurerSettings = async (insurer) => {
  const res = await axios.patch(
    `${url}/insurers/${insurer.insurerPartnerNumber}`,
    insurer
  );
  return res;
};

export const deleteInsurerSettings = async (insurerPartnerNumber: string) => {
  const res = await axios.delete(`${url}/insurers/${insurerPartnerNumber}`);
  return res.data;
};

export const deleteInsurerSetting = async (
  insurerPartnerNumber: string,
  insurerSettingId: string
) => {
  const res = await axios.delete(
    `${url}/insurers/${insurerPartnerNumber}/${insurerSettingId}`
  );
  return res.data;
};

export const getInsurer = async (insurerId: number | string) => {
  const res = await axios.get(`${url}/insurers/${insurerId}`);
  return res;
};

export type SearchVehiclesArgs = {
  fleetId?: number;
  search: string;
  vehicleStatusCode?: number;
  top: number;
  skip: number;
  sort: any;
  isTEMP: boolean;
  columns: ExpandedColumn[];
  cancelToken: CancelToken;
  isNeedsAttention?: boolean;
  isTradeLicensePlate?: boolean;
  clientIds?: number[];
  countryCode?: string;
};

export const searchVehicles = async ({
  fleetId,
  vehicleStatusCode,
  search,
  sort,
  top,
  skip,
  isTEMP,
  columns,
  cancelToken,
  clientIds,
  isTradeLicensePlate,
  isNeedsAttention,
  countryCode,
}: SearchVehiclesArgs) => {
  let searchUrl = `${url}/GetFleetVehiclesList`;
  if (isTEMP) {
    searchUrl = `${url}/GetFleetTEMPVehiclesList`;
  }
  if (fleetId) {
    searchUrl += `?fleetId=${fleetId}`;
  } else {
    searchUrl += "?a=1";
  }
  if (!isTEMP && vehicleStatusCode !== undefined) {
    searchUrl += `&vehicleStatusCode=${vehicleStatusCode}`;
  }
  searchUrl += `&searchCriteria=${search.replace(
    /&/g,
    encodeURIComponent("&")
  )}*`;
  if (sort.key !== "" && sort.dir !== "") {
    searchUrl += `&$orderby=${sort.key} ${sort.dir}`;
  }
  searchUrl += `&form=json`;
  searchUrl += `&$count=true`;
  searchUrl += `&$top=${top}&$skip=${skip}`;
  if (isTradeLicensePlate !== null) {
    searchUrl += `&isTradeLicensePlate=${isTradeLicensePlate}`;
  }

  let filterStr = createAzureFilterParam(columns as any);
  // let filterStr = filterStrRaw.replaceAll(".", "").replaceAll(",", ".");

  if (isNeedsAttention && isNeedsAttention !== null) {
    if (filterStr.length > 0) {
      filterStr += " and needsGrECoUserAttention eq " + isNeedsAttention;
    }
    //
    else {
      filterStr += "needsGrECoUserAttention eq " + isNeedsAttention;
    }
  }
  if (countryCode && countryCode !== null) {
    if (filterStr.length > 0) {
      filterStr += " and countryCode eq '" + countryCode + "'";
    }
    //
    else {
      filterStr += "countryCode eq '" + countryCode + "'";
    }
  }
  if (clientIds && clientIds.length > 0) {
    if (filterStr.length > 0) {
      filterStr +=
        " and search.in(webBasePartnerNumber,'" + clientIds.join(",") + "')";
    }
    //
    else {
      filterStr +=
        "search.in(webBasePartnerNumber,'" + clientIds.join(",") + "')";
    }
  }
  if (filterStr.length > 0) {
    searchUrl += `&$filter=${filterStr}`;
  }

  const res = await axios.get<VehiclesAzureResponse>(searchUrl, {
    cancelToken,
  });
  return res;
};

export const searchUsers = async (firstLetters: string) => {
  const res = await axios.get<FleetUser[]>(
    `${url}/SearchUsers/${firstLetters}`
  );
  return res.data;
};

export const getTaxonomy = async () => {
  const res = await axios.get<TaxonomyResponse>(`${url}/GetTaxonomyData`);
  return res;
};

export const importOrUpdateFleet = async (data: CreateUpdateFleetData) => {
  const res = await axios.post<CreateUpdateFleetResponse>(
    `${url}/ImportOrUpdateFleet`,
    data
  );
  return res;
};

export const deleteTEMPVehicle = async (fleetId: string, vehicleId: string) => {
  const res = await axios.delete(
    `${url}/DeleteTEMPVehicle/${fleetId}/${vehicleId}`
  );
  return res.data;
};

export const getVehicleById = async (fleetId: string, vehicleId: string) => {
  const res = await axios.get<Vehicle>(
    `${url}/GetVehicleByVehicleId/${fleetId}/${vehicleId}`
  );
  return res;
};

export const getInsurersByCountryId = async (countryId: number) => {
  const res = await axios.get<Insurer>(`${url}/insurer/country/${countryId}`);
  return res;
};

export const updateVehicle = async (payload: Vehicle) => {
  const res = await axios.post(`${url}/UpdateVehicle`, payload);
  return res;
};

export const createVehicle = async (vehicle: Vehicle) => {
  const res = await axios.post(`${url}/CreateVehicle`, vehicle);
  return res.data;
};

export const createTradeLicensePlate = async (vehicle: Vehicle) => {
  const res = await axios.post(
    `${url}/CreateTradeLicensePlate/${vehicle?.fleetId}`,
    vehicle
  );
  return res.data;
};

export const getTEMPVehicleById = async (
  fleetId: string,
  vehicleId: string
) => {
  const res = await axios.get<Vehicle>(
    `${url}/GetTEMPVehicleByVehicleId/${fleetId}/${vehicleId}`
  );
  return res;
};

export const updateTEMPVehicle = async (vehicle: {}) => {
  const res = await axios.post(`${url}/UpdateTEMPVehicle`, vehicle);
  return res;
};

export const transferTEMPVehicle = async (args: {
  fleetId: string;
  vehicleId: string;
}) => {
  const res = await axios.post<Vehicle>(`${url}/TransferTEMPVehicle`, args);
  return res;
};

export const getFleetUsers = async (fleetId: string) => {
  const res = await axios.get<FleetUserResponse[]>(
    `${url}/GetFleetUsers/${fleetId}`
  );
  return res;
};

export const changeVehicleStatus = async (args: {
  rowVersion?: string;
  vehicleId: string;
  fleetId: string;
  vehicleStatusCode: number;
  vehicleLastStatusDate: string;
  vehicleLastStatusComment: string;
}) => {
  const res = await axios.post<VehicleAzure>(
    `${url}/ChangeVehicleStatus`,
    args
  );
  return res;
};

export const changeVehiclePolicyStatus = async (args: {
  fleetId: string;
  vehicleId: string;
  vehiclePolicyStatusId: string;
  body: ChangeOrCorrectVehiclePolicyStatusData;
}) => {
  const { fleetId, vehicleId, vehiclePolicyStatusId, body } = args;
  return await axios.post<VehicleAzure>(
    `${url}/ChangeVehiclePolicyStatus/${fleetId}/${vehicleId}/${vehiclePolicyStatusId}`,
    { ...body }
  );
};

export const correctVehiclePolicyStatus = async (
  rowVersion: string,
  args: {
    fleetId: string;
    vehicleId: string;
    vehiclePolicyStatusId: string;
    body: ChangeOrCorrectVehiclePolicyStatusData;
  }
) => {
  const { fleetId, vehicleId, vehiclePolicyStatusId, body } = args;
  return await axios.patch<VehicleAzure>(
    `${url}/CorrectVehiclePolicyStatus/${fleetId}/${vehicleId}/${vehiclePolicyStatusId}`,
    { ...body, rowVersion }
  );
};

export const changeVehiclesStatus = async (args: {
  vehicleIds: number[];
  fleetId: string;
  vehicleStatusCode: number;
  vehicleLastStatusDate: string;
  vehicleLastStatusComment: string;
}) => {
  const res = await axios.post<VehicleAzure>(
    `${url}/ChangeVehicleStatus`,
    args
  );
  return res;
};

export const changeVehicleIssuingStatus = async ({
  vehicleId,
  fleetId,
  vehicleIssuingStatusCode,
  vehicleFirstIssuingStatusDate,
  vehicleLastIssuingStatusDate,
  vehicleIssuingSubstatusCode,
  vehicleIssuingComment,
}: IUpdateVehicleIssuingStatus) => {
  const res = await axios.patch<VehicleAzure>(
    `${url}/UpdateVehicleIssuingStatusInfo/${fleetId}/${vehicleId}`,
    {
      vehicleIssuingStatusCode,
      vehicleFirstIssuingStatusDate,
      vehicleLastIssuingStatusDate,
      vehicleIssuingSubstatusCode,
      vehicleIssuingComment,
    }
  );
  return res;
};

export const changeMultipleVehicleIssuingStatus = async ({
  vehicleIds,
  fleetId,
  vehicleIssuingStatusCode,
  vehicleFirstIssuingStatusDate,
  vehicleLastIssuingStatusDate,
  vehicleIssuingSubstatusCode,
  vehicleIssuingComment,
}: IUpdateMultipleVehicleIssuingStatus) => {
  const res = await axios.patch<VehicleAzure>(
    `${url}/UpdateMultipleVehiclesIssuingStatusInfo/${fleetId}`,
    {
      vehicleIssuingStatusCode,
      vehicleFirstIssuingStatusDate,
      vehicleLastIssuingStatusDate,
      vehicleIssuingSubstatusCode,
      vehicleIssuingComment,
      vehicleIds,
    }
  );
  return res;
};

export const updateSharedLicensePlate = async (
  fleetId: any,
  licensePlate: any,
  body: UpdateSharedLicensePlateBody
) => {
  const res = await axios.patch<SharedLicensePlate>(
    `${url}/UpdateSharedLicensePlateVehiclesInfo/${fleetId}/${licensePlate}`,
    body
  );
  return res;
};

export const changeMultipleVehiclePendingIssuingStatus = async ({
  vehicleIds,
  fleetId,
  vehicleIssuingStatusCode,
  vehicleFirstIssuingStatusDate,
  vehicleLastIssuingStatusDate,
  vehicleIssuingSubstatusCode,
  vehicleIssuingComment,
}: IUpdateMultipleVehiclePendingIssuingStatus) => {
  const res = await axios.patch<VehicleAzure>(
    `${url}/UpdateMultipleVehiclesPendingIssuingStatusInfo/${fleetId}`,
    {
      vehicleIssuingStatusCode,
      vehicleFirstIssuingStatusDate,
      vehicleLastIssuingStatusDate,
      vehicleIssuingSubstatusCode,
      vehicleIssuingComment,
      vehicleIds,
    }
  );
  return res;
};

export const approveVehiclePendingStatus = async (
  fleetId,
  vehicleId,
  rowVersion?
) => {
  const res = await axios.patch<VehicleAzure>(
    `${url}/ApproveVehiclePendingStatus/${fleetId}/${vehicleId}`,
    { rowVersion }
  );
  return res;
};

export const cancelVehiclePendingStatus = async (
  rowVersion,
  args: CancelVehiclePendingStatusParams
) => {
  const { fleetId, vehicleId, comment } = args;
  const res = await axios.patch<VehicleAzure>(
    `${url}/CancelVehiclePendingStatus/${fleetId}/${vehicleId}`,
    { comment, rowVersion }
  );
  return res;
};

export const correctVehicleStatusDateForNonPendingStatuses = async ({
  vehicleId,
  fleetId,
  comment,
  vehicleLastStatusDate,
  vehicleStatusCode,
}: {
  vehicleId: any;
  fleetId: any;
  comment: string;
  vehicleStatusCode: number;
  vehicleLastStatusDate: string;
}) => {
  const res = await axios.patch<VehicleAzure>(
    `${url}/CorrectVehicleStatusDateForNonPendingStatuses/${fleetId}/${vehicleId}`,
    {
      vehicleStatusCode,
      vehicleLastStatusDate,
      comment,
    }
  );
  return res;
};

export const duplicateVehicleWithPremiums = async ({
  vehicleId,
  fleetId,
  body,
}: {
  vehicleId: any;
  fleetId: any;
  body: any;
}) => {
  const res = await axios.post<VehicleAzure>(
    `${url}/DuplicateVehicleWithPremia/${fleetId}/${vehicleId}`,
    {
      ...body,
    }
  );
  return res;
};

export const getAppSettings = async (appID: number) => {
  return await axios.get<SettingsResponse>(`${uadUrl}/appsettings/${appID}`);
};

export const getUADTaxonomy = async () => {
  return await axios.get<TaxonomyUADResponse>(`${uadUrl}/taxonomy`);
};

export const addAppSetting = async (setting: Partial<Setting>) => {
  return await axios.post<Setting[]>(`${uadUrl}/addappsettings`, [setting]);
};

export const getUserRoles = async (): Promise<string[]> => {
  const account = await MSGraphAuth.accountObj;
  if (!(account && account.idTokenClaims)) return [];

  const { roles } = account.idTokenClaims as { roles: string[] };
  return roles;
};

export const deleteAppSetting = async (
  userAppSettingId: Setting["userAppSettingId"]
) => {
  return await axios.delete<Setting[]>(`${uadUrl}/deleteappsettings`, {
    data: [userAppSettingId],
  });
};

export const updateAppSetting = async (setting: Setting) => {
  return await axios.patch<Setting[]>(`${uadUrl}/updateappsettings`, [setting]);
};

export type UpdateIssuingStatusBody = {
  vehicleIssuingStatusCode: number;
  vehicleFirstIssuingStatusDate: string;
  vehicleLastIssuingStatusDate: string;
  vehicleIssuingSubstatusCode: number;
  vehicleIssuingComment: string;
};

export type UpdateIssuingStatusRequestParams = {
  fleetId: string;
  vehicleId: string;
  body: UpdateIssuingStatusBody;
};

export const updateIssuingStatus = async (
  args: UpdateIssuingStatusRequestParams
) => {
  const { fleetId, vehicleId, body } = args;
  return await axios.patch<Vehicle>(
    `${url}/UpdateVehicleIssuingStatusInfo/${fleetId}/${vehicleId}`,
    body
  );
};

export type UpdateVehiclePremiumBody = {
  insurerPartnerNumber: number;
  premiumType: number;
  premiumValue: number | null;
  validFromDate: string;
  reasonForModification: number;
  reasonForModificationComment: string;
  suspensionDays: number;
  activationDays: number;
  suspensionDaysLimit: number;
  garageRiskDiscountPercent: number | null;
};

export type VehiclePremiumBody = {
  premiumType: number;
  validFromDate: string;
  reasonForModificationComment: string;
  annualGrossPremium: number;
  annualNetPremium: number;
  suspensionDays: number;
  activationDays: number;
  suspensionDaysLimit: number;
  garageRiskDiscountPercent: number | null;
  insurerBranchOffice: number;
  insurerPolicyNumber: string;
  paymentMethod: number;
  paymentMean: number;
  insurerPartnerNumber: number;
};

export type VehicleStatusBody = {
  vehicleStatusCode: number;
  vehicleLastStatusDate: Date;
  comment: string;
};

export type UpdateVehicleEnginePowerTaxBody = {
  excludeMotorTax: boolean;
  excludeMotorTaxReason: number;
  validFromDate: string;
  reasonForMotorTaxExclusion: number;
  reasonForMotorTaxExclusionComment: string;
};

export type UpdateVehiclePremiumRequestParams = {
  fleetId: string;
  vehicleId: string;
  body: UpdateVehiclePremiumBody;
};

export type TimelineRequestParams = {
  fleetId: string;
  vehicleId?: string;
  body: any;
};

export type CancelVehiclePendingStatusParams = {
  fleetId: string;
  vehicleId?: string;
  comment: string;
};

export type VehiclePremiumRequestParams = {
  fleetId: string;
  vehicleId: string;
  body: VehiclePremiumBody;
};

export type SharedLicensePlateRequestParams = {
  fleetId: string;
  licensePlate: string;
  mainVehicleForSharedLicensePlateId: string;
  body: VehiclePremiumBody;
};

export type SlpRequestParams = {
  fleetId: string;
  vehicleId: string;
  body: VehiclePremiumBody;
};

export type ClosedVehiclePremiumRequestParams = {
  fleetId: string;
  vehicleId: string;
  vehiclePremiumVersionId: string;
  body: VehiclePremiumBody;
};

export type IssuingStatusRequestParams = {
  fleetId: string;
  vehicleId: string;
  vehiclePremiumBookingId: string;
  body: VehiclePremiumBody;
};

export type VehicleStatusRequestParams = {
  fleetId: string;
  vehicleId: string;
  vehicleStatusId: string;
  body: VehicleStatusBody;
};

export type VehiclePolicyStatusRequestParams = {
  fleetId: string;
  vehicleId: string;
  vehiclePolicyStatusId: string;
  body: RequestWithCommentOnly;
};

export type VehiclePolicyStatusChangeRequestParams = {
  fleetId: string;
  vehicleId: string;
  vehiclePolicyStatusId: string;
  body: ChangeOrCorrectVehiclePolicyStatusData;
};

export type ChangeOrCorrectVehiclePolicyStatusData = {
  vehiclePolicyStatusCode: string;
  validFromDateTime: string;
  comment: string;
  vehiclePremiumVersionId: string;
  vehiclePremiumId: string;
};
export type RequestWithCommentOnly = {
  comment: string;
};

export type VehicleLicensePlateRequestParams = {
  fleetId: string;
  vehicleId: string;
  vehicleLicensePlateId: string;
  body: VehicleStatusBody;
};

export type VehicleOneTimePremiumRequestParams = {
  fleetId: string;
  vehicleId: string;
  vehiclePremiumVersionId: string;
  vehicleOneTimePremiumId?: string;
  body: VehicleStatusBody;
};

export type VehicleApprovePendingStatusRequestParams = {
  fleetId: string;
  vehicleId: string;
};
export type VehicleCancelPendingStatusRequestParams = {
  fleetId: string;
  vehicleId: string;
  comment: string;
};
export type VehicleChangeStatusRequestParams = {
  fleetId: string;
  vehicleId: string;
  vehicleStatusCode: number;
  vehicleLastStatusDate: string;
  vehicleLastStatusComment: string;
  body: VehicleStatusBody;
};
export type AdjustVehiclePremiumRequestParams = {
  fleetId: string;
  vehicleId: string;
  body: VehiclePremiumBody;
};

export type UpdateVehiclePremiumBookingRequestParams = {
  fleetId: string;
  vehicleId: string;
  vehiclePremiumBookingId: string;
  body: UpdateVehiclePremiumBody;
};

export type UpdateVehicleEnginePowerTaxRequestParams = {
  fleetId: string;
  vehicleId: string;
  body: UpdateVehicleEnginePowerTaxBody;
};

export const updateVehiclePremium = async (
  args: UpdateVehiclePremiumRequestParams
) => {
  const { fleetId, vehicleId, body } = args;
  return await axios.patch<Vehicle>(
    `${url}/UpdateVehiclePremium/${fleetId}/${vehicleId}`,
    body
  );
};

export const addVehiclePremium = async (
  rowVersion: string,
  args: VehiclePremiumRequestParams
) => {
  const { fleetId, vehicleId, body } = args;
  return await axios.post<Vehicle>(
    `${url}/AddVehiclePremium/${fleetId}/${vehicleId}`,
    { ...body, rowVersion }
  );
};

export const insertVehiclePremium = async (
  rowVersion: string,
  args: VehiclePremiumRequestParams
) => {
  const { fleetId, vehicleId, body } = args;
  return await axios.post<Vehicle>(
    `${url}/InsertVehiclePremium/${fleetId}/${vehicleId}`,
    { ...body, rowVersion }
  );
};

export const insertSharedLicensePlate = async (
  args: SharedLicensePlateRequestParams
) => {
  const { fleetId, licensePlate, body } = args;
  return await axios.post<SharedLicensePlate>(
    `${url}/InsertSharedLicensePlateVehiclesInfo/${fleetId}/${licensePlate}`,
    body
  );
};

export const cancelSharedLicensePlate = async (
  args: SharedLicensePlateRequestParams
) => {
  const { fleetId, licensePlate, mainVehicleForSharedLicensePlateId, body } =
    args;
  return await axios.patch<SharedLicensePlate>(
    `${url}/CancelSharedLicensePlateVehiclesInfo/${fleetId}/${licensePlate}/${mainVehicleForSharedLicensePlateId}`,
    body
  );
};

export const correctSharedLicensePlate = async (
  args: SharedLicensePlateRequestParams
) => {
  const { fleetId, licensePlate, mainVehicleForSharedLicensePlateId, body } =
    args;
  return await axios.patch<SharedLicensePlate>(
    `${url}/CorrectSharedLicensePlateVehiclesInfo/${fleetId}/${licensePlate}/${mainVehicleForSharedLicensePlateId}`,
    body
  );
};

export const deleteSharedLicensePlate = async (
  args: SharedLicensePlateRequestParams
) => {
  const { fleetId, licensePlate, mainVehicleForSharedLicensePlateId, body } =
    args;
  return await axios.patch<SharedLicensePlate>(
    `${url}/DeleteSharedLicensePlateVehiclesInfo/${fleetId}/${licensePlate}/${mainVehicleForSharedLicensePlateId}`,
    body
  );
};

export const continueSharedLicensePlate = async (
  args: SharedLicensePlateRequestParams
) => {
  const { fleetId, licensePlate, mainVehicleForSharedLicensePlateId, body } =
    args;
  return await axios.patch<SharedLicensePlate>(
    `${url}/ContinueSharedLicensePlateVehiclesInfo/${fleetId}/${licensePlate}/${mainVehicleForSharedLicensePlateId}`,
    body
  );
};

export const activateSharedLicensePlate = async (
  args: SharedLicensePlateRequestParams
) => {
  const { fleetId, licensePlate, body } = args;
  return await axios.patch<SharedLicensePlate>(
    `${url}/ActivateSharedLicensePlateInPeriod/${fleetId}/${licensePlate}`,
    body
  );
};

export const suspendSharedLicensePlate = async (
  args: SharedLicensePlateRequestParams
) => {
  const { fleetId, licensePlate, body } = args;
  return await axios.patch<SharedLicensePlate>(
    `${url}/SuspendSharedLicensePlateInPeriod/${fleetId}/${licensePlate}`,
    body
  );
};

export const correctLastVehiclePremium = async (
  rowVersion: string,
  args: VehiclePremiumRequestParams
) => {
  const { fleetId, vehicleId, body } = args;
  return await axios.patch<Vehicle>(
    `${url}/CorrectLastVehiclePremium/${fleetId}/${vehicleId}`,
    { ...body, rowVersion }
  );
};

export const correctVehiclePremium = async (
  rowVersion: string,
  args: ClosedVehiclePremiumRequestParams
) => {
  const { fleetId, vehicleId, vehiclePremiumVersionId, body } = args;
  return await axios.patch<Vehicle>(
    `${url}/CorrectVehiclePremium/${fleetId}/${vehicleId}/${vehiclePremiumVersionId}`,
    { ...body, rowVersion }
  );
};

export const cancelVehiclePremium = async (
  rowVersion: string,
  args: VehiclePremiumRequestParams
) => {
  const { fleetId, vehicleId, body } = args;
  return await axios.patch<Vehicle>(
    `${url}/CancelVehiclePremium/${fleetId}/${vehicleId}`,
    { ...body, rowVersion }
  );
};

export const deleteVehiclePremium = async (
  rowVersion: string,
  args: ClosedVehiclePremiumRequestParams
) => {
  const { fleetId, vehicleId, vehiclePremiumVersionId, body } = args;
  return await axios.patch<Vehicle>(
    `${url}/DeleteVehiclePremium/${fleetId}/${vehicleId}/${vehiclePremiumVersionId}`,
    { ...body, rowVersion }
  );
};

export const continueVehiclePremium = async (
  rowVersion: string,
  args: ClosedVehiclePremiumRequestParams
) => {
  const { fleetId, vehicleId, vehiclePremiumVersionId, body } = args;
  return await axios.patch<Vehicle>(
    `${url}/ContinueVehiclePremium/${fleetId}/${vehicleId}/${vehiclePremiumVersionId}`,
    { ...body, rowVersion }
  );
};

export const updateVehiclePremiumBookingIssuingStatusInfo = async (
  rowVersion: string,
  args: IssuingStatusRequestParams
) => {
  const { fleetId, vehicleId, vehiclePremiumBookingId, body } = args;
  return await axios.patch<Vehicle>(
    `${url}/UpdateVehiclePremiumBookingIssuingStatusInfo/${fleetId}/${vehicleId}/${vehiclePremiumBookingId}`,
    { ...body, rowVersion }
  );
};

export const adjustVehiclePremium = async (
  rowVersion: string,
  args: ClosedVehiclePremiumRequestParams
) => {
  const { fleetId, vehicleId, vehiclePremiumVersionId, body } = args;
  return await axios.patch<Vehicle>(
    `${url}/AdjustVehiclePremium/${fleetId}/${vehicleId}/${vehiclePremiumVersionId}`,
    { ...body, rowVersion }
  );
};

export const updateVehiclePremiumBooking = async (
  args: UpdateVehiclePremiumBookingRequestParams
) => {
  const { fleetId, vehicleId, vehiclePremiumBookingId, body } = args;
  return await axios.patch<Vehicle>(
    `${url}/UpdateVehiclePremiumBooking/${fleetId}/${vehicleId}/${vehiclePremiumBookingId}`,
    body
  );
};

export const updateEnginePowerTax = async (
  args: UpdateVehicleEnginePowerTaxRequestParams
) => {
  const { fleetId, vehicleId, body } = args;
  return await axios.patch<Vehicle>(
    `${url}/UpdateMotorTaxExclusion/${fleetId}/${vehicleId}`,
    body
  );
};

export type RecalculateProRataAndBookingsRequestBody = {
  comment: string;
};

export type ImportScanDocumentRequestBody = {
  base64str: string;
  name: string;
};

export type RecalculateFleetProRataAndBookingsRequestParams = {
  fleetId: string;
  body: RecalculateProRataAndBookingsRequestBody;
};

export type ImportScanDocumentRequestParams = {
  fleetId: string | number;
  body: ImportScanDocumentRequestBody;
};

export type RecalculateVehicleProRataAndBookingsRequestParams = {
  fleetId: string | number;
  vehicleId: string | number;
  body: RecalculateProRataAndBookingsRequestBody;
};

export const recalculateFleetProRataAndBookings = async (
  args: RecalculateFleetProRataAndBookingsRequestParams
) => {
  const { fleetId, body } = args;
  return await axios.patch(`${url}/RecalculateFleetPremiums/${fleetId}`, body);
};

export const getScannedVehicleInfo = async (
  args: ImportScanDocumentRequestParams
) => {
  const { fleetId, body } = args;
  return await axios.post(`${url}/GetScannedVehicleInfo/${fleetId}`, body);
};

export const recalculateVehicleProRataAndBookings = async (
  args: RecalculateVehicleProRataAndBookingsRequestParams
) => {
  const { fleetId, vehicleId, body } = args;
  return await axios.patch(
    `${url}/RecalculateVehiclePremiums/${fleetId}/${vehicleId}`,
    body
  );
};

export type ExcelReportRequestBody = {
  reportType: any;
  comment: any | null;
  insurerPartnerNumber: any | null;
  premiumType: any | null;
  validFromDate: any | null;
  validToDate: any | null;
  vehicleIds: ConfirmVehicleChangesParams | null;
};

export type ExcelReportRequestParams = {
  fleetId: string;
  body: ExcelReportRequestBody;
};

export const generateFleetVehicleReport = async (
  args: ExcelReportRequestParams
) => {
  const { fleetId, body } = args;
  return await axios.post<ExcelReportResponse>(
    `${url}/GenerateFleetVehicleReport/${fleetId}`,
    body
  );
};

export type SynchronizeFleetClientBody = {
  comment: string;
};

export type SynchronizeFleetClientRequestParams = {
  fleetId: string;
  body: SynchronizeFleetClientBody;
};

export const synchronizeFleetClient = async (
  args: SynchronizeFleetClientRequestParams
) => {
  const { fleetId, body } = args;
  return await axios.patch<FleetWithPremiumsResponse>(
    `${url}/SynchronizeFleetClient/${fleetId}`,
    body
  );
};

export type IndexFleetBody = {
  insurerPartnerNumber: number;
  premiumType: number;
  validFromDate: string;
  reasonForModificationComment: string;
  premiumValueChangePercent: number;
};

export type IndexRequestParams = {
  fleetId: string;
  body: IndexFleetBody;
};

export const indexFleet = async (args: IndexRequestParams) => {
  const { fleetId, body } = args;
  return await axios.patch<FleetWithPremiumsResponse>(
    `${url}/IndexFleetPremium/${fleetId}`,
    body
  );
};

export type AdjustFleetBody = {
  insurerPartnerNumber: number;
  premiumType: number;
  validFromDate: string;
  reasonForModificationComment: string;
  premiumValueChangePercent: number;
  // vehicleType: number;
  // cubicCapacityCcmRange?: number;
  // weightCapacityKgRange?: number;
  // powerKw?: number;
  criteria: any;
};

export type AdjustFleetParams = {
  fleetId: string | number;
  body: AdjustFleetBody;
};

export const adjustFleet = async (args: AdjustFleetParams) => {
  const { fleetId, body } = args;
  return await axios.patch<FleetWithPremiumsResponse>(
    `${url}/AdjustFleetPremium/${fleetId}`,
    body
  );
};

export type ChangeFleetPaymentMethodBody = {
  insurerPartnerNumber: number;
  premiumType: number;
  validFromDate: string;
  reasonForModificationComment: string;
  paymentMethod: number;
};

export type ChangeFleetPaymentMethodRequestParams = {
  fleetId: string;
  body: ChangeFleetPaymentMethodBody;
};

export const changeFleetPaymentMethod = async (
  args: ChangeFleetPaymentMethodRequestParams
) => {
  const { fleetId, body } = args;
  return await axios.patch<FleetWithPremiumsResponse>(
    `${url}/ChangeFleetPremiumPaymentMethod/${fleetId}`,
    body
  );
};

export type ConfirmVehicleChangesParams = {
  fleetId: any;
  vehicleId: any;
};

export const confirmVehicleChanges = async (
  args: ConfirmVehicleChangesParams
) => {
  const { fleetId, vehicleId } = args;
  return await axios.patch<Vehicle>(
    `${url}/ConfirmVehicleChanges/${fleetId}/${vehicleId}`
  );
};

export type CancelFleetPremiumBody = {
  insurerPartnerNumber: number;
  premiumType: number;
  validFromDate: string;
  reasonForModificationComment: string;
  // vehicleType: number;
  // weightCapacityKgRange: number;
  criteria: any;
};

export type CancelFleetPremiumParams = {
  fleetId: string | number;
  body: CancelFleetPremiumBody;
};

export const cancelFleetPremium = async (args: CancelFleetPremiumParams) => {
  const { fleetId, body } = args;
  return await axios.patch<FleetWithPremiumsResponse>(
    `${url}/CancelFleetPremium/${fleetId}`,
    body
  );
};

export type AddFleetPremiumBody = {
  insurerPartnerNumber: number;
  premiumType: number;
  validFromDate: string;
  reasonForModificationComment: string;
  annualGrossPremium: number;
  annualNetPremium: number;
  vehicleType: number;
  weightCapacityKgRange: number;
};

export type AddFleetPremiumParams = {
  fleetId: string | number;
  body: AddFleetPremiumBody;
};

export const addFleetPremium = async (args: AddFleetPremiumParams) => {
  const { fleetId, body } = args;
  return await axios.post<FleetWithPremiumsResponse>(
    `${url}/AddFleetPremium/${fleetId}`,
    body
  );
};

export type UpdateFleetPremiumBody = {
  insurerPartnerNumber: number;
  premiumType: number;
  validFromDate: string;
  reasonForModificationComment: string;
  annualGrossPremium: number;
  annualNetPremium: number;
  vehicleType?: number;
  weightCapacityKgRange?: number;
  cubicCapacityCcmRange?: number;
  powerKwRange?: number;
};

export type UpdateFleetPremiumParams = {
  fleetId: string | number;
  body: UpdateFleetPremiumBody;
};

export const updateFleetPremium = async (args: UpdateFleetPremiumParams) => {
  const { fleetId, body } = args;
  return await axios.patch<FleetWithPremiumsResponse>(
    `${url}/UpdateFleetPremium/${fleetId}`,
    body
  );
};

export type AdjustDeductibleBody = {
  premiumType: number;
  deductibleChangePercent: number;
  reasonForModificationComment: string;
  // vehicleType: number;
  // weightCapacityKgRange: number;
  criteria: any;
};

export type AdjustDeductibleParams = {
  fleetId: string | number;
  body: AdjustDeductibleBody;
};

export const adjustFleetDeductible = async (args: AdjustDeductibleParams) => {
  const { fleetId, body } = args;
  return await axios.patch<FleetWithPremiumsResponse>(
    `${url}/AdjustFleetDeductible/${fleetId}`,
    body
  );
};

export const getFleetDefaultSettings = async (fleetId: any) => {
  return await axios.get<FleetDefaultSetting[]>(
    `${url}/GetFleetVehicleDefaultSettings/${fleetId}`
  );
};

export const getAllSharedLicensePlates = async (fleetId: any) => {
  return await axios.get<SharedLicensePlate[]>(
    `${url}/GetSharedLicensePlateFleetVehiclesInfo/${fleetId}`
  );
};

export const getFleetGroupPolicyNumbers = async (fleetId: any) => {
  return await axios.get<any>(`${url}/GetFleetGroupPolicyNumbers/${fleetId}`);
};

export type CreateFleetDefaultSettingBody = {
  fleetId: string | number;
  // attributeName: "vehicleUser";
  attributeName: string;
  attributeValue: string | number;
};

export type CreateFleetDefaultSettingParams = {
  fleetId: string | number;
  body: CreateFleetDefaultSettingBody;
};

export const createFleetDefaultSetting = async (
  args: CreateFleetDefaultSettingParams
) => {
  const { fleetId, body } = args;
  return await axios.post<FleetDefaultSetting>(
    `${url}/CreateFleetVehicleDefaultSetting/${fleetId}`,
    body
  );
};

export const insertVehicleStatus = async (
  rowVersion: string,
  args: VehicleStatusRequestParams
) => {
  const { fleetId, vehicleId, vehicleStatusId, body } = args;
  return await axios.post<Vehicle>(
    `${url}/InsertVehicleStatus/${fleetId}/${vehicleId}/${vehicleStatusId}`,
    { ...body, rowVersion }
  );
};

export const correctVehicleStatus = async (
  rowVersion: string,
  args: VehicleStatusRequestParams
) => {
  const { fleetId, vehicleId, vehicleStatusId, body } = args;
  return await axios.patch<Vehicle>(
    `${url}/CorrectVehicleStatusDate/${fleetId}/${vehicleId}/${vehicleStatusId}`,
    { ...body, rowVersion }
  );
};

export const deleteVehicleStatus = async (
  rowVersion: string,
  args: VehicleStatusRequestParams
) => {
  const { fleetId, vehicleId, vehicleStatusId, body } = args;
  return await axios.patch<Vehicle>(
    `${url}/DeleteVehicleStatus/${fleetId}/${vehicleId}/${vehicleStatusId}`,
    { ...body, rowVersion }
  );
};

export const deleteVehiclePolicyStatus = async (
  rowVersion: string,
  args: VehiclePolicyStatusRequestParams
) => {
  const { fleetId, vehicleId, vehiclePolicyStatusId, body } = args;
  return await axios.patch<Vehicle>(
    `${url}/DeleteVehiclePolicyStatus/${fleetId}/${vehicleId}/${vehiclePolicyStatusId}`,
    { ...body, rowVersion }
  );
};

export const correctVehicleLicensePlate = async (
  rowVersion: string,
  args: VehicleLicensePlateRequestParams
) => {
  const { fleetId, vehicleId, vehicleLicensePlateId, body } = args;
  return await axios.patch<Vehicle>(
    `${url}/CorrectVehicleLicensePlate/${fleetId}/${vehicleId}/${vehicleLicensePlateId}`,
    { ...body, rowVersion }
  );
};

export const deleteVehicleLicensePlate = async (
  rowVersion: string,
  args: VehicleLicensePlateRequestParams
) => {
  const { fleetId, vehicleId, vehicleLicensePlateId, body } = args;
  return await axios.patch<Vehicle>(
    `${url}/DeleteVehicleLicensePlate/${fleetId}/${vehicleId}/${vehicleLicensePlateId}`,
    { ...body, rowVersion }
  );
};

export const insertVehicleLicensePlate = async (
  rowVersion: string,
  args: VehicleLicensePlateRequestParams
) => {
  const { fleetId, vehicleId, vehicleLicensePlateId, body } = args;
  return await axios.post<Vehicle>(
    `${url}/InsertVehicleLicensePlate/${fleetId}/${vehicleId}/${vehicleLicensePlateId}`,
    { ...body, rowVersion }
  );
};

export const correctVehicleOneTimePremium = async (
  rowVersion: string,
  args: VehicleOneTimePremiumRequestParams
) => {
  const {
    fleetId,
    vehicleId,
    vehiclePremiumVersionId,
    vehicleOneTimePremiumId,
    body,
  } = args;
  return await axios.patch<Vehicle>(
    `${url}/CorrectVehicleOneTimePremium/${fleetId}/${vehicleId}/${vehiclePremiumVersionId}/${vehicleOneTimePremiumId}`,
    { ...body, rowVersion }
  );
};

export const deleteVehicleOneTimePremium = async (
  rowVersion: string,
  args: VehicleOneTimePremiumRequestParams
) => {
  const {
    fleetId,
    vehicleId,
    vehiclePremiumVersionId,
    vehicleOneTimePremiumId,
    body,
  } = args;
  return await axios.patch<Vehicle>(
    `${url}/DeleteVehicleOneTimePremium/${fleetId}/${vehicleId}/${vehiclePremiumVersionId}/${vehicleOneTimePremiumId}`,
    { ...body, rowVersion }
  );
};

export const insertVehicleOneTimePremium = async (
  rowVersion: string,
  args: VehicleOneTimePremiumRequestParams
) => {
  const { fleetId, vehicleId, vehiclePremiumVersionId, body } = args;
  return await axios.post<Vehicle>(
    `${url}/AddVehicleOneTimePremium/${fleetId}/${vehicleId}/${vehiclePremiumVersionId}`,
    { ...body, rowVersion }
  );
};

export const changeVehicleLicensePlate = async (
  rowVersion: string,
  args: VehicleLicensePlateRequestParams
) => {
  const { fleetId, vehicleId, body } = args;
  return await axios.post<Vehicle>(
    `${url}/ChangeVehicleLicensePlate/${fleetId}/${vehicleId}`,
    { ...body, rowVersion }
  );
};

export type UpdateFleetDefaultSettingBody = {
  fleetId: string | number;
  attributeName: string;
  attributeValue: string | number;
  rowVersion: string;
};

export type UpdateSharedLicensePlateBody = {
  licensePlate: string;
  mainVehicleId: number;
  validFromDate: string;
  reasonForModification: number;
  reasonForModificationComment: string;
};

export type UpdateFleetDefaultSettingParams = {
  fleetId: string | number;
  fleetDefaultSettingId: any;
  body: UpdateFleetDefaultSettingBody;
};

export const updateFleetDefaultSetting = async (
  args: UpdateFleetDefaultSettingParams
) => {
  const { fleetId, fleetDefaultSettingId, body } = args;
  return await axios.patch<FleetDefaultSetting>(
    `${url}/UpdateFleetVehicleDefaultSetting/${fleetId}/${fleetDefaultSettingId}`,
    body
  );
};

export const deleteFleetDefaultSetting = async (
  fleetId: any,
  fleetDefaultSettingId: any
) => {
  return await axios.delete<FleetDefaultSetting>(
    `${url}/RemoveFleetVehicleDefaultSetting/${fleetId}/${fleetDefaultSettingId}`
  );
};

export type DeleteFleetBody = {
  comment: string;
};

export type DeleteFleetParams = {
  fleetId: any;
  body: DeleteFleetBody;
};

export const deleteFleet = async ({ fleetId, body }: DeleteFleetParams) => {
  return await axios.patch<FleetDefaultSetting>(
    `${url}/DeleteFleet/${fleetId}`,
    body
  );
};

export type DeleteVehicleBody = {
  comment: string;
};

export type DeleteVehiclesBody = {
  comment: string;
  vehicleIds: any;
};

export type DeleteVehicleParams = {
  fleetId: any;
  vehicleId: any;
  body: any;
};

export interface IInsurerBooking {
  fleetId: string | number;
  insurerPartnerNumber: string | number;
  body: {
    BookingFiles: {
      base64str: string;
      name: string;
    }[];
  };
}

export type DeleteVehiclesParams = {
  fleetId: any;
  vehicleIds: any;
  body: any;
};

export const deleteVehicle = async ({
  fleetId,
  vehicleId,
  body,
}: DeleteVehicleParams) => {
  return await axios.patch<FleetDefaultSetting>(
    `${url}/DeleteVehicle/${fleetId}/${vehicleId}`,
    body
  );
};

export const deleteVehicles = async ({
  fleetId,
  vehicleIds,
  body,
}: DeleteVehiclesParams) => {
  return await axios.patch<FleetDefaultSetting>(
    `${url}/DeleteMultipleVehicles/${fleetId}`,
    body
  );
};

export const getPowerBIEmbedMeta = async (fleetIds: string) => {
  var allOrSelectedFleetIds = fleetIds ? fleetIds : "all";
  const res = await axios.get<PowerBIEmbedMeta>(
    `${url}/powerbi/${allOrSelectedFleetIds}`
  );
  return res;
};

export const exportPowerBIReport = async (data: any) => {
  const res = await axios.post(`${url}/powerbi/export`, data);
  return res;
};

export const clientSearchByText = async (payload) => {
  const res = await axios.get<any>(`${url}/clientssearch/${payload}`);
  return res.data.map((item) => ({
    ...item,
    label: item?.clientName,
    value: item?.clientId,
  }));
};

export const clientSearchByInternalNumber = async (clientInternalNumber) => {
  const res = await axios.get<any>(`${url}/client/${clientInternalNumber}`);
  return res.data;
};

export const updatePremiumBookingIssuingStatus = async ({
  fleetId,
  vehicleId,
  body,
}) => {
  try {
    const res = await axios.patch<any>(
      `${url}/UpdateMultipleVehiclePremiumBookingIssuingStatusInfo/${fleetId}/${vehicleId}`,
      body
    );
    return res.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const compareInsurerBookings = async ({
  fleetId,
  insurerPartnerNumber,
  body,
}: IInsurerBooking) => {
  return await axios.patch(
    `${url}/CompareInsurerBookings/${fleetId}/${insurerPartnerNumber}`,
    body
  );
};

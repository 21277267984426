import { DefaultButton, DialogFooter, mergeStyleSets } from "@fluentui/react";
import { toast } from "components/FluentToast";
import { FormSpinner } from "components/FormSpinner";
import TextInputField from "components/controls/TextInputField";
import { useCurrentRoute } from "config/routes";
import { Formik } from "formik";
import { useRecalculateFleetProRataAndBookings } from "hooks/data/mutations/useRecalculateFleetProRataAndBookings";
import { useRecalculateVehicleProRataAndBookings } from "hooks/data/mutations/useRecalculateVehicleProRataAndBookings";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { delay } from "redux-saga/effects";
import { setVehicle } from "store/vehicle";
import { setYupLocale } from "utils/setYupLocale";
import * as yup from "yup";
import { setDialog } from "../../../store/fleetForm";
import { useSelector } from "../../../store/hooks";

const RecalculateProRataAndBookingsFormDialog = () => {
  const { t } = useTranslation();

  const vehicleId = useSelector((s) => s.vehicle?.vehicle?.vehicleId);
  const dispatch = useDispatch();
  const { fleetId } = useParams() as any;
  const route = useCurrentRoute();

  const {
    mutate: onRecalculateFleetProRataAndBookings,
    isLoading: recalculateFleetProRataAndBookingInProgress,
  } = useRecalculateFleetProRataAndBookings();
  const {
    mutate: onRecalculateVehicleProRataAndBookings,
    isLoading: recalculateVehicleProRataAndBookingInProgress,
  } = useRecalculateVehicleProRataAndBookings();

  const isLoading =
    recalculateFleetProRataAndBookingInProgress ||
    recalculateVehicleProRataAndBookingInProgress;
  const close = () => {
    dispatch(setDialog({ type: "", isOpen: false }));
  };

  const initialValues = {
    comment: null,
  };

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    return yup.object().shape({
      comment: yup.string().max(512),
    });
  }, [t]);

  const classes = getClassNames();

  const onSubmit = (values) => {
    const payload = {
      comment: values?.comment,
    };
    if (route === "editVehicle" && vehicleId) {
      onRecalculateVehicleProRataAndBookings(
        {
          vehicleId,
          fleetId,
          body: {
            ...payload,
          },
        },
        {
          onSuccess: (res) => {
            const newData = res?.data;
            dispatch(setVehicle(newData));

            toast.success(
              t("bfm.proRataAndBookingsRecalculatedSuccessfully.label")
            );
            close();
          },
        }
      );
    } else {
      onRecalculateFleetProRataAndBookings(
        {
          fleetId,
          body: {
            ...payload,
          },
        },
        {
          onSuccess: () => {
            toast.success(
              t("bfm.proRataAndBookingsRecalculatedSuccessfully.label")
            );
            close();
          },
        }
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit(values);
        await delay(400);
        setSubmitting(false);
      }}
    >
      {(props) => {
        const { values, handleSubmit, handleChange, errors } = props;

        return (
          <>
            <div className={classes.main}>
              <form onSubmit={handleSubmit}>
                <div className={classes.fieldItem}>
                  <TextInputField
                    value={values.comment}
                    required
                    onChange={handleChange}
                    errors={errors}
                    name={"comment"}
                    multiline
                    rows={4}
                    disabled={false}
                    label={"bfm.comment.label"}
                  />
                </div>
                <DialogFooter className={classes.footer}>
                  <DefaultButton
                    primary
                    text={t("bfm.recalculateProRataAndBookings.label")}
                    type="submit"
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </DialogFooter>
                {isLoading && <FormSpinner />}
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const getClassNames = () =>
  mergeStyleSets({
    main: {},
    fieldItem: {
      width: "100%",
      marginTop: 20,
    },
    footer: {
      marginTop: 50,
    },
  });

export default RecalculateProRataAndBookingsFormDialog;

import { mergeStyleSets } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { mergeStyles } from "react-select";
import CreatableSelect from "react-select/creatable";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { getSelectStyles } from "../../utils/utils";
import ErrorMsg from "./ErrorMsg";
import { FieldContainer } from "./FieldContainer";

const getClassNames = (theme, required, disabled) =>
  mergeStyleSets({
    root: {
      width: "100%",
      marginTop: "2px",
    },
    label: {
      fontSize: "14px",
      fontWeight: "600",
      color: disabled ? theme.palette.neutralSecondaryAlt : theme.palette.black,
      padding: "5px",
    },
    required: {
      color: theme.palette.redDark,
      fontSize: 14,
      marginLeft: 3,
    },
  });

const GroupPolicyNumberField = ({
  setFieldValue,
  options,
  defaultValue,
  name = "",
  label,
  errors,
  transparent = false,
  disabled,
  required = false,
  isMarked = false,
  onSetFieldTouched = null,
  isMulti = false,
  confidentiality,
  isClearable = true,
  ...props
}) => {
  const theme = useTheme() as IStyledTheme;
  const { t } = useTranslation();
  const isDarkMode = theme.isDark;
  const classes = getClassNames(theme, required, disabled);
  const [value, setValue] = useState(defaultValue);
  const hasError = errors[name];
  const [groupPolicyNumbersList, setGroupPolicyNumbersList] = useState([]);

  useEffect(() => {
    setValue(defaultValue);
    setFieldValue(name, defaultValue);
    onSetFieldTouched && onSetFieldTouched();
  }, [defaultValue]);

  useEffect(() => {
    // make axios ajax call to get group policy numbers
    if (!options) return;

    setGroupPolicyNumbersList(options);
  }, [options]);

  return (
    <div className={classes.root}>
      <FieldContainer isTooltipHidden={disabled} tooltipText={label}>
        <label
          id="aria-label"
          htmlFor="aria-example-input"
          className={classes.label}
        >
          {t(label)}
          {required && <span className={classes.required}>*</span>}
        </label>
        {isClearable ? (
          <CreatableSelect
            id={name + "-select"}
            inputId={name + "-select-input"}
            menuPosition="fixed"
            data-testid={name}
            menuShouldScrollIntoView
            styles={mergeStyles(getSelectStyles(!!errors, theme), {
              container: (p) => ({
                ...p,
                width: "100%",
              }),
              menuPortal: (p) => ({
                ...p,
                background: theme.palette.neutralLighter,
                zIndex: 9999999,
              }),
              menu: (p) => ({
                ...p,
                zIndex: 9999,
                background: theme.palette.neutralLighter,
              }),
              control: (p, state) => {
                return {
                  ...p,

                  background: state.isDisabled
                    ? "transparent"
                    : theme.palette.white,
                  ...(isMarked &&
                    !confidentiality && {
                      background: theme.palette.yellowLight,
                    }),
                  ...(confidentiality && {
                    background: `rgb(${confidentiality < 0.9 ? 255 : 200},${
                      confidentiality >= 0.8 ? 255 : 200
                    },200)`,
                  }),
                  borderBottom: hasError
                    ? `2px solid rgb(164, 38, 44) !important`
                    : state.isFocused
                    ? `2px solid rgb(0, 90, 161) !important`
                    : `1px solid ${theme.palette.black} !important`,
                  boxShadow: "none",
                  ...(state.isDisabled && {
                    borderBottom: `1px solid ${theme.palette.neutralLighter} !important`,
                  }),
                };
              },
              option: (p, state) => ({
                ...p,
                background:
                  state.isSelected || state.isFocused
                    ? theme.palette.neutralLighter
                    : theme.palette.white,
                "&:hover": {
                  background: theme.palette.neutralLighter,
                },
                color: theme.palette.black,
                fontSize: "14px",
                fontWeight: 400,
                wordWrap: "break-word",
              }),
              input: (p) => ({
                ...p,
                color: (function () {
                  if (disabled) {
                    return theme.palette.neutralSecondaryAlt;
                  }
                  if (isDarkMode) {
                    if (isMarked) {
                      return "black";
                    } else {
                      return "white";
                    }
                  }
                  return theme.palette.black;
                })(),
              }),
              singleValue: (p, state) => ({
                ...p,
                color: isMarked
                  ? "black"
                  : theme.isDark
                  ? confidentiality
                    ? "black"
                    : "white"
                  : disabled
                  ? theme.palette.neutralSecondaryAlt
                  : "black",
                fontSize: "14px",
                fontWeight: 400,
              }),
              dropdownIndicator: (p, state) => ({
                ...p,
                ...(state.isDisabled && { visibility: "hidden" }),
              }),
              indicatorSeparator: (p, state) => ({
                ...p,
                ...(state.isDisabled && { visibility: "hidden" }),
              }),
            })}
            // className="basic-single"
            classNamePrefix="select"
            value={value}
            isDisabled={disabled}
            onChange={(option) => {
              setValue(option);
              setFieldValue(name, option);
            }}
            // isLoading={isLoading}
            isClearable={true}
            isSearchable={true}
            name={name}
            options={groupPolicyNumbersList}
            isMulti={isMulti}
          />
        ) : (
          <Select
            id={name + "-select"}
            inputId={name + "-select-input"}
            menuPosition="fixed"
            data-testid={name}
            menuShouldScrollIntoView
            styles={mergeStyles(getSelectStyles(!!errors, theme), {
              container: (p) => ({
                ...p,
                width: "100%",
              }),
              menuPortal: (p) => ({
                ...p,
                background: theme.palette.neutralLighter,
                zIndex: 9999999,
              }),
              menu: (p) => ({
                ...p,
                zIndex: 9999,
                background: theme.palette.neutralLighter,
              }),
              control: (p, state) => {
                return {
                  ...p,

                  background: state.isDisabled
                    ? "transparent"
                    : theme.palette.white,
                  ...(isMarked &&
                    !confidentiality && {
                      background: theme.palette.yellowLight,
                    }),
                  ...(confidentiality && {
                    background: `rgb(${confidentiality < 0.9 ? 255 : 200},${
                      confidentiality >= 0.8 ? 255 : 200
                    },200)`,
                  }),
                  borderBottom: hasError
                    ? `2px solid rgb(164, 38, 44) !important`
                    : state.isFocused
                    ? `2px solid rgb(0, 90, 161) !important`
                    : `1px solid ${theme.palette.black} !important`,
                  boxShadow: "none",
                  ...(state.isDisabled && {
                    borderBottom: `1px solid ${theme.palette.neutralLighter} !important`,
                  }),
                };
              },
              option: (p, state) => ({
                ...p,
                background:
                  state.isSelected || state.isFocused
                    ? theme.palette.neutralLighter
                    : theme.palette.white,
                "&:hover": {
                  background: theme.palette.neutralLighter,
                },
                color: theme.palette.black,
                fontSize: "14px",
                fontWeight: 400,
                wordWrap: "break-word",
              }),
              input: (p) => ({
                ...p,
                color: (function () {
                  if (disabled) {
                    return theme.palette.neutralSecondaryAlt;
                  }
                  if (isDarkMode) {
                    if (isMarked) {
                      return "black";
                    } else {
                      return "white";
                    }
                  }
                  return theme.palette.black;
                })(),
              }),
              singleValue: (p, state) => ({
                ...p,
                color: isMarked
                  ? "black"
                  : theme.isDark
                  ? confidentiality
                    ? "black"
                    : "white"
                  : disabled
                  ? theme.palette.neutralSecondaryAlt
                  : "black",
                fontSize: "14px",
                fontWeight: 400,
              }),
              dropdownIndicator: (p, state) => ({
                ...p,
                ...(state.isDisabled && { visibility: "hidden" }),
              }),
              indicatorSeparator: (p, state) => ({
                ...p,
                ...(state.isDisabled && { visibility: "hidden" }),
              }),
            })}
            // className="basic-single"
            classNamePrefix="select"
            value={value}
            isDisabled={disabled}
            onChange={(option) => {
              setValue(option);
              setFieldValue(name, option);
            }}
            // isLoading={isLoading}
            isClearable={true}
            isSearchable={true}
            name={name}
            options={options}
            isMulti={isMulti}
          />
        )}

        {errors[name] && <ErrorMsg>{errors[name]}</ErrorMsg>}
      </FieldContainer>
    </div>
  );
};

export default React.memo(GroupPolicyNumberField);

import { MUTATION_KEYS } from "hooks/data/queryKeys";
import React from "react";
import { useIsMutating } from "react-query";
import { useSelector } from "store/hooks";
import { EDialogAttributeName } from "../../constants";
import AnnualGrossPremiumForm from "./AnnualGrossPremiumForm/AnnualGrossPremiumForm";
import HullDeductibleForm from "./HullDeductibleForm/HullDeductibleForm";
import HullPremiumCalculationForm from "./HullPremiumCalculationForm/HullPremiumCalculationForm";

export const SettingForm = () => {
  const dialog = useSelector((s) => s.fleetForm.dialog);

  const isCreateLoading = !!useIsMutating({
    mutationKey: [MUTATION_KEYS.createFleetVehicleDefaultSetting],
  });
  const isUpdateLoading = !!useIsMutating({
    mutationKey: [MUTATION_KEYS.updateFleetVehicleDefaultSetting],
  });

  const settingId = dialog.data.settingId;
  const isLoading = isCreateLoading || isUpdateLoading;

  const attributeName = dialog.data.attributeName;

  const renderSettingsDialog = (attributeName) => {
    switch (attributeName) {
      case EDialogAttributeName.AnnualGrossPremium:
        return (
          <AnnualGrossPremiumForm
            settingId={settingId}
            isLoading={isLoading}
            attributeName={EDialogAttributeName.AnnualGrossPremium}
          />
        );
      case EDialogAttributeName.Deductible:
        return (
          <HullDeductibleForm
            settingId={settingId}
            isLoading={isLoading}
            attributeName={EDialogAttributeName.Deductible}
          />
        );
      case EDialogAttributeName.Calculation:
        return (
          <HullPremiumCalculationForm
            settingId={settingId}
            isLoading={isLoading}
            attributeName={EDialogAttributeName.Calculation}
          />
        );
    }
  };

  return <div>{renderSettingsDialog(attributeName)}</div>;
};

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as Colors from "../config/colors";
import { useSelector } from "../store/hooks";
import { VehicleStatusSelect } from "./VehicleStatusSelect";

type Props = {
  vehicleStatusId: number;
  onChange: (id: number) => void;
};

export const VehicleStatusFilter = ({ vehicleStatusId, onChange }: Props) => {
  const taxonomy = useSelector((s) => s.taxonomy);
  const { t } = useTranslation();
  const loadTaxonomyStatus = useSelector((s) => s.taxonomy.loadTaxonomyStatus);

  const vehicleStatusOptions = useMemo(() => {
    const options = taxonomy.VehicleStatusCode.items.map((tax) => {
      const color = Colors.vehicleStatus[tax.code];
      return {
        label: t(tax.code),
        value: tax.id,
        color: color === "transparent" ? undefined : color,
        code: tax.code,
      };
    });
    return options;
  }, [t, taxonomy.VehicleStatusCode.items, taxonomy.VehicleStatusCode]);

  const selectedOption = useMemo(() => {
    const vehicleStatusAllTax =
      taxonomy.VehicleStatusCode.byCode["VehicleStatusCode.ALL"];
    if (vehicleStatusId === null)
      return {
        label: t(vehicleStatusAllTax?.code),
        value: vehicleStatusAllTax?.id,
        color: undefined,
        code: vehicleStatusAllTax?.code,
      };
    const tax = taxonomy.VehicleStatusCode.byId[vehicleStatusId];
    const color = Colors.vehicleStatus[tax?.code];
    return {
      label: t(tax?.code),
      value: vehicleStatusId,
      color: color === "transparent" ? undefined : color,
      code: tax?.code,
    };
  }, [vehicleStatusId, taxonomy.VehicleStatusCode]);

  const isLoading = loadTaxonomyStatus === "loading";

  return (
    <Container>
      <VehicleStatusSelect
        value={selectedOption}
        onChange={(o: any) => {
          const vehicleStatusAllTax =
            taxonomy.VehicleStatusCode.byCode["VehicleStatusCode.ALL"];
          let vehicleStatusId;
          if (o) {
            if (vehicleStatusAllTax?.id === o.value) {
              vehicleStatusId = null;
            }
            //
            else {
              vehicleStatusId = o.value;
            }
          }
          //
          else {
            vehicleStatusId = null;
          }
          onChange(vehicleStatusId);
        }}
        options={vehicleStatusOptions}
        isClearable={vehicleStatusId !== null}
        isLoading={isLoading}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 220px;
`;
